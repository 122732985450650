import React, { useState, useEffect } from "react";
import { USER_ID } from "../config/be_api_constants";
import { getLocalStorage } from "../utils/storage";
import { organizationSummary } from "../_services/view.service";

const SwitchButton = ({
  data,
  value,
  setValue,
  theme,
  height,
  width,
  switcherHeight,
  switcherWidth,
}) => {
  const [orgSummary, setOrgSummary] = useState({});

  const [userId, setuserId] = useState("");

  const userid = getLocalStorage(USER_ID);

  useEffect(() => {
    setuserId(userid);
  }, []);

  const onSwitchClick = (name, userId) => {
    // if (name === data[0]) {
    //   setOptionOneSelected(true);
    //   setOptionTwoSelected(false);
    // } else {
    //   setOptionOneSelected(false);
    //   setOptionTwoSelected(true);
    // }
    setValue(name);
    // organizationSummary(1, 10, userId)?.then((res) => {
    //   setOrgSummary(res?.data?.data);
    // });
  };

 
  return (
    <React.Fragment>
      {data && data.length === 2 ? (
        <div
          className={`switch-widget d-inline-block ${
            theme && theme === "black" ? "black" : ""
          }`}
          // style={{
          //   height: `${height ? height : ""}`,
          //   width: `${width ? width : ""}`,
          //   // minWidth: "350px",
          // }}
        >
          <button
            className={`option d-inline-block align-items-center ${
              value === "Members"  ? "active" : ""  ||
              value=== data[0]  ? "active" : ""
            }`}
            // style={{
            //   height: `${switcherHeight ? switcherHeight : ""}`,
            //   width: `${switcherWidth ? switcherWidth : ""}`,
            // }}
            onClick={() => {
              onSwitchClick(data[0], null);
            }}
          >
            {data[0]}
          </button>
          <button
            className={`option d-inline-block ${
              value === "Teams"  ? "active" : ""  ||
              value === data[1] ? "active" : ""
            }`}
            // style={{
            //   height: `${switcherHeight ? switcherHeight : ""}`,
            //   width: `${switcherWidth ? switcherWidth : ""}`,
            // }}
            onClick={() => {
              onSwitchClick(data[1], userId);
            }}
          >
            {data[1]}
          </button>
        </div>
      ) : (
        "Switcher accept two values"
      )}
    </React.Fragment>
  );
};
export default SwitchButton;
