import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import Loader from "../../components/common/loader";
import RefereeBarChart from "../../components/referee_bar_chart";
import RefereeProfessionalExperience from "../../pages/auth_protected/job_application_view_page/referral-profile-view/professional_experience";
import RefereeAboutMe from "../../pages/auth_protected/job_application_view_page/referral-profile-view/referee_about_me";
import RefereeEducationDetails from "../../pages/auth_protected/job_application_view_page/referral-profile-view/referee_education";
import RefereeLocation from "../../pages/auth_protected/job_application_view_page/referral-profile-view/referee_location";
import "../../pages/auth_protected/job_application_view_page/viewmessages.scss";
import { isEmpty } from "../../utils/form_validators";
import toaster from "../../utils/toaster";
import { refereeSummary } from "../../_services/member-profile.service";
import { getViewCandidateProfile } from "../../_services/serikoradmin_view.service";
import { getRefereeProfile } from "../../_services/view.service";

const RefereeProfile = (props) => {
  const [apiRefresh, setapiRefresh] = useState(false);
  const [summary, setSummary] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [refereeProfile, setRefereeProfile] = useState([]);

  const referralId = props?.referralId;
  const userId = props?.userId;

  const ViewCandidateProfile = () => {
    //  setapiRefresh(true);

    //   if (props?.showRefereeProfile === true) {
    refereeSummary(props?.userId).then(
      (res) => {
        if (!isEmpty(res)) {
          setSummary(res?.data?.data);
        } else {
          setSummary([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
    // }
  };

  // const RefereeSummary = () => {
  //   // if (props?.showRefereeProfile === true) {
  //   refereeSummary(userId).then(
  //     (res) => {
  //       if (!isEmpty(res)) {
  //         setSummary(res?.data?.data);
  //       } else {
  //         setSummary([]);
  //       }
  //     },
  //     (error) => {
  //       toaster("error", error);
  //     }
  //   );
  //   // }
  // };

  useEffect(() => {
    ViewCandidateProfile();

    // if (apiRefresh === true) {
    //   ViewCandidateProfile();
    // }
  }, [props?.refereeProfile]);

  const refereeBarChartData = {
    referrals: summary?.referrals,
    applications: summary?.applications,
    joinees: summary?.joinees,
    joineesPercentage: summary?.joineesPercentage,
  };

  return (
    <div>
      {/* {showLoader && <Loader />} */}
      <Modal
        show={props.show}
        onHide={() => props.onRefereeProfileClose()}
        backdrop="static"
        fullscreen="lg-down"
        size="lg"
        scrollable={true}
        centered
        className="lg-dialog-modal"
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100">
            <div className="d-flex justify-content-between align-items-center">
              <div className="fs-24 color-primary fw-700 ps-3 pt-2">
                Referee profile
              </div>
              <div className="pe-3 pt-2">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onRefereeProfileClose()}
                  className="pointer"
                  width="30px"
                  height="30px"
                />
              </div>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="mt-0 overflow-x-hidden">
          <div className="p-3 pt-0">
            <RefereeAboutMe refereeProfile={props?.refereeProfile} />
            <div className="referee-bar-chart-width my-4">
              <RefereeBarChart refereeBarChartData={refereeBarChartData} />
            </div>
            <RefereeLocation refereeProfile={props?.refereeProfile} />
            <RefereeProfessionalExperience
              refereeProfile={props?.refereeProfile}
            />
            <RefereeEducationDetails refereeProfile={props?.refereeProfile} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RefereeProfile;
