import React from "react";
import { Link } from "react-router-dom";
import { APPLICATIONS_MAIN_PAGE_ROUTE } from "../config/page_routes_constants";
import { JOB_POST_CREATE_PAGE_ROUTE } from "./../config/page_routes_constants";
import pinUnfilled from "../assests/icons/ic_pin.svg";
import pinFilled from "../assests/icons/ic_pin_filled.svg";

const JobPostsCard = (props) => {
  // console.log("post",props?.setPinJobs);
  return (
    <Link
      // to={"/" + APPLICATIONS_MAIN_PAGE_ROUTE}
      className=" d-flex  flex-wrap justify-content-between my-sm-3 my-md-5 card-main my-2 mb-5 text-decoration-none text-dark"
    >
      {props?.jobslist?.length > 0
        ? props?.jobslist?.map((item, key) => {
            return (
              <div className="  col-12 col-sm-6 p-2">
                <div className="card-body card small-referee-cards rounded-4">
                  <div className="card-title fs-14 fw-400 ">
                    {item?.jobTitle}
                  </div>
                  <div className=" fs-11 fw-300 text-muted d-flex ">
                    <div>
                      {item.status === "LIVE" ? (
                        <>
                          <span className="dot green"></span> {item.status}
                        </>
                      ) : item.status === "PAUSED" ? (
                        <>
                          <span className="dot yellow"></span> {item.status}
                        </>
                      ) : item.status === "FILLED" ? (
                        <>
                          <span className="dot gray"></span> {item.status}
                        </>
                      ) : item.status === "CLOSED" ? (
                        <>
                          <span className="dot gray"></span> {item.status}
                        </>
                      ) : (
                        ""
                      )}
                      {item.jobStatus} | {item.jobView} | {item.jobPostingDate}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="card-title fs-12 fw-400 py-0">
                      Applications
                    </div>
                    <div className="fs-14 fw-400">
                      {item?.applicationCount}
                      {item.newApplicationCount > 0 ? (
                      <span className="px-1 fs-11 text-danger fw-600 m-0">
                      {item.newApplicationCount} New
                      </span>
                      ):(
                        ""
                      )}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="card-title fs-12 fw-400 py-0">
                      Referrals
                    </div>
                    <div className="fs-14 fw-400">
                      {item?.referralCount}
                      {item.referralCount > 0 ? (
                      <span className="px-1 fs-11 text-danger fw-600 m-0">
                      {item.referralCount} New
                      </span>
                    ) : (
                      ""
                    )} 
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="card-title fs-12 fw-400 py-0">
                      Shortlisted
                    </div>
                    <div className="fs-14 fw-400">{item?.referrals}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="card-title fs-12 fw-400 py-0">
                      Assessment
                    </div>
                    <div className="fs-14 fw-400">{item?.shorlisted}</div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="card-title fs-12 fw-400 py-0">
                      Pending Screening
                    </div>
                    <div className="fs-14 fw-400">{item?.pendingScreening}</div>
                  </div>
                  <div
                    className="referee-small-card-btn pb-2 ms-4"
                    style={{ right: 0, left: 0, bottom: 0 }}
                  >
                    <div className="row gy-3 hover-bg w-100">
                      <div className="d-flex justify-content-between">
                        <div className=" pin-bg d-flex justify-content-center align-items-center pointer me-3">
                        <img
                                      style={{ height: "14px", width: "10px" }}
                                      src={
                                        item?.isPinned ? pinFilled : pinUnfilled
                                      }
                                      onClick={() => {
                                        if (item?.isPinned == true) {
                                          // setPinSrc(pinFilled);
                                          // setUnpinStatus("UnPin");
                                          props.setPinJobs(false);
                                          props.pinJobIcon(item?.jobId, false);
                                        } else {
                                          // setPinSrc(pinIcon);
                                          // setPinUnpinStatus("Pin");
                                          props.setPinJobs(true);
                                          props.pinJobIcon(item?.jobId, true);
                                        }
                                      }}
                                      // use "pinFilled" in src for filled icon

                                      // onClick={() => {pinJobIcon(item?.jobId,true)
                                      //   getjobSummary(item?.isPinned)}}
                                    />
                        </div>

                        <Link
                          to={"/" + JOB_POST_CREATE_PAGE_ROUTE +  `/${item?.jobId}`}
                          id={key}
                          type="button"
                          className={`w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-light bg-dark`}
                          style={{ minWidth: "100px" }}
                        >
                          Edit Job
                        </Link>
                      </div>
                      <div className="row-12">
                        <Link
                          id={key}
                          to={"/" + APPLICATIONS_MAIN_PAGE_ROUTE +`/${item?.jobId}`}
                          className="w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                          style={{ height: "40px" }}
                        >
                          Applications
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
      {props?.jobslist?.length === 0 ? (
        <div className="row">
          <center className="m-4">No Data Found</center>
        </div>
      ) : (
        ""
      )}
    </Link>
  );
};

export default JobPostsCard;
