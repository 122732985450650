import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import RightArrow from "../../../assests/Vector.svg";
import Loader from "../../../components/common/loader";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import { STATUS_SUCCESS } from "../../../config/localstorage_keys";
import { GENERAL_ERROR_MESSAGE } from "../../../config/messages";
import { JOB_POST_CREATE_PAGE_ROUTE } from "../../../config/page_routes_constants";
import { isEmpty } from "../../../utils/form_validators";
import toaster from "../../../utils/toaster";
import { getAllJobDraft } from "../../../_services/view.service";
import InfiniteScroll from "react-infinite-scroll-component";

const DraftJobPostPage = () => {
  let isInfinite = false;

  const [showLoader, setShowLoader] = useState(false);
  const [list, setList] = useState([]);

  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const [allDraftFilterDataParams, setAllDraftFilterDataParams] =
    useSearchParams({
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });

  const [allDraftFilterData, setAllDraftFilterData] =
    useState(initialFilterData);
  const [allSaveDraftList, setAllSaveDraftList] = useState([]);
  const [currentPageset, setCurrentPageSet] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [allJobMessage, setAllJobMessage] = useState("");
  const [tempJobFilter, setTempJobFilter] = useState({});

  const fetchAllDraft = (data) => {
    //console.log("search---",searchString);
    setShowLoader(true);

    getAllJobDraft(data).then(
      (res) => {
        console.log("res---", res?.data?.totalRecord);
        if (res && !isEmpty(res?.data?.data)) {
          setDataCount(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...allSaveDraftList, ...res?.data?.data];
          }
          setCurrentPageSet(data?.length);
          setAllSaveDraftList(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setList(res?.data?.data[0]);

          // setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };
  console.log(
    "check",
    dataCount,
    currentPageset,
    dataCount > currentPageset ? true : false
  );
  const fetchMoreDraftJob = () => {
    // if (jobList.length < dataCount) {
    setCurrentPageSet(currentPageset + 10);
    // }
    isInfinite = true;

    fetchAllDraft({
      ...allDraftFilterData,
      pageNo: allDraftFilterData?.pageNo + 1,
    });
    setAllDraftFilterData({
      ...allDraftFilterData,
      pageNo: allDraftFilterData?.pageNo + 1,
    });
  };

  useEffect(() => {
    // setcurrentPageset(allJobFilterData?.pageNo);
    // fetchMoreData();
    let temp = { ...initialFilterData };
    for (let entry of allDraftFilterDataParams.entries()) {
      if (entry[0] === "searchString") {
        temp = {
          ...temp,
          searchString: entry[1],
        };
      }
    }
    setAllDraftFilterData(temp);
    fetchAllDraft(temp);
  }, []);

  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      setAllDraftFilterDataParams(allDraftFilterData);
      fetchAllDraft(allDraftFilterData);
    }
  };

  return (
    <div className="container">
      {showLoader && <Loader />}

      <div className="content-margin mt-4">
        <div className="fs-20 fw-700 pb-2 border-bottom">
          Posts ({dataCount})
        </div>
        <div className="mt-3 d-flex justify-content-end  h-32 ">
          <div className="page-search">
            <input
              type="text"
              placeholder="Search"
              defaultValue={allDraftFilterData?.searchString}
              onChange={(event) => {
                setAllDraftFilterData({
                  ...allDraftFilterData,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
              className="h-32 page-search-inner"
            />
          </div>
        </div>
        <div className="table-responsive ">
          <InfiniteScroll
            dataLength={currentPageset}
            next={() => fetchMoreDraftJob()}
            hasMore={dataCount > currentPageset}
            className="container-fluid"
          >
            <Table className="mt-2 justify-content-between border-0">
              <thead className="w-100 border-bottom">
                <tr>
                  <th className="gap-2 text-left col-3">
                    <div className="fs-12 fw-700 text-start">Title</div>
                  </th>
                </tr>
              </thead>
              <tbody className="border-0">
                {allSaveDraftList && allSaveDraftList?.length > 0
                  ? allSaveDraftList?.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            className="card-parent fs-14 position-relative border-bottom border-top"
                          >
                            <td className="text-start position-relative align-middle py-3 align-items-center">
                              <td className="">
                                <div className="fs-14 fw-400">
                                  {" "}
                                  {item?.jobTitle}
                                </div>
                              </td>

                              <div
                                style={{
                                  right: "10px",
                                }}
                                className="onhover-show "
                              >
                                <div className="link ms-2">
                                  <Link
                                    to={
                                      "/" +
                                      JOB_POST_CREATE_PAGE_ROUTE +
                                      "/" +
                                      item?.id
                                    }
                                    className=" text-decoration-none py-2 px-3 rounded btn-outline-dark fs-12 fw-700 text-white bg-dark "
                                  >
                                    Edit
                                    <img
                                      alt=""
                                      style={{
                                        height: "1rem",
                                        width: "1rem",
                                        marginLeft: "60px",
                                      }}
                                      src={RightArrow}
                                    />
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : "No Data Found"}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
};

export default DraftJobPostPage;
