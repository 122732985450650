import { useEffect ,useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../../components/common/header";
import { useStoreActions, useStoreState } from "easy-peasy";
import { getLocalStorage } from "../../utils/storage";
import { USER_ID } from "../../config/localstorage_keys";
import Loader from "../../components/common/loader";
import NavigationSidebar from "../../components/common/navigation_sidebar";
import { getAllJobDraft, getjobSummary } from "../../_services/view.service";
import { getAllJobPost } from "../../_services/serikoradmin_view.service";

const NavigationSidebarModule = () => {
  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const isLoading = useStoreState((state) => state.candidate.isLoading);

  const [total_Jobs, setTotal_Jobs] = useState("");
  const [total_DraftJobs, setTotal_DraftJobs] = useState("");
  const [total_SeikorAdminJobs, setTotal_SeikorAdminJobs] = useState("");
  const [apiRefresh, setApiRefresh] = useState(false);
  
  // const allCounts = (searchString) => {
  //   // setApiRefresh(true);
  //   getjobSummary({ pageNo: 0, pageSize: 0, searchString: searchString }).then((res) => {
  //     setTotal_Jobs(res?.totalRecord);
      
  //     // setApiRefresh(false);
      
  //   });
  //   // setApiRefresh(true);
  // getAllJobDraft({ pageNo: 0, pageSize: 0, searchString: searchString }).then((res) => {
  //   setTotal_DraftJobs(res?.totalRecord);
  //   // setApiRefresh(false);
  // });
  // // setApiRefresh(true);
  // getAllJobPost({ pageNo: 0, pageSize: 0, searchString: searchString }).then((res) => {
  //   setTotal_SeikorAdminJobs(res?.totalCount);
  //   // setApiRefresh(false);
  // });
  // };

  const role = localStorage.getItem("role");
 

  const allCounts = () => {
    getjobSummary({pageNo :1 , pageSize:10}).then((res) => {
      setTotal_Jobs(res?.data?.totalRecord);
    });
  };
  getAllJobDraft({pageNo :1 , pageSize:10}).then((res) => {
    setTotal_DraftJobs(res?.data?.totalRecord);
  });
  getAllJobPost({pageNo :1 , pageSize:10}).then((res) => {
    setTotal_SeikorAdminJobs(res?.totalCount);
  });

  useEffect(() => {
    allCounts();
  }, [role]);
  useEffect(() => {
    const userId = getLocalStorage(USER_ID);
    if (userId) {
      saveCandidateDetails(userId);
    }
  }, []);
  return (
    <div className="container d-flex h-100">
      <NavigationSidebar
        total_Jobs={total_Jobs}
        total_DraftJobs={total_DraftJobs}
        total_SeikorAdminJobs={total_SeikorAdminJobs}
      />
      <div className="flex-fill navigation-content">
        <Outlet />
      </div>
    </div>
  );
};

export default NavigationSidebarModule;
