import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../pages/auth_protected/jobs_post_page/job-post-style.scss";
import icCloseRounded from "../assests/closebtn.svg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { checkedItemsHandler } from "../utils/utils";

const CheckBoxFilterModal = (props) => {
  const [arrayIndex, setArrayIndex] = useState([]);

  const dropdownAllStatusHandler = (index) => {
    checkedItemsHandler(index, arrayIndex, setArrayIndex);
  };
  const handleResetBtn = () => {
    setArrayIndex([]);
  };
  const handleApply = () => {
    props?.setArray(arrayIndex);
    props?.close();
  };
  return (
    <div className="radius19">
      <Modal
        animation={false}
        dialogClassName="sort-modal"
        contentClassName="sort-modal-content"
        show={props?.show}
        scrollable={true}
        onHide={() => {
          props?.close();
        }}
      >
        <Modal.Header className="dialog-header border-0 ms-2 me-3 pt-3 pb-0">
          <div className="fs-12 color- fw-700 small-text-gray">
            {props?.filterName ? props?.filterName : "Filters"}
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            height="14px"
            width="14px"
            onClick={() => {
              props?.close();
            }}
            className="pointer"
          />
        </Modal.Header>

        <Modal.Body className="dialog-body d-flex ">
          <ul
            style={{ minHeight: "250px", marginRight: "-1rem" }}
            className="w-100 pt-4"
          >
            {props?.Array.map((sortItem, index) => {
              return (
                <li
                  key={index}
                  className="d-flex pb-3 fs-12 fw-400"
                  onClick={() => {
                    dropdownAllStatusHandler(index);
                  }}
                >
                  <input
                    type="checkbox"
                    className="me-2"
                    checked={arrayIndex.includes(index) ? true : false}
                    onChange={() => null}
                  />{" "}
                  <label>{sortItem}</label>
                </li>
              );
            })}
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{ backgroundColor: "#F4F7FB", left: "0px" }}
            className="position-absolute bottom-0 d-sm-block d-md-none w-100 pb-1 "
          >
            <Link
              className="btn down-arrow col-6 gap-2 btn-outline-none  fs-14 fw-700"
              to=""
              onClick={handleResetBtn}
            >
              Reset
            </Link>
            <Link
              className="btn down-arrow col-5 gap-2 btn-outline-none fs-14 fw-700 border-start"
              to=""
              onClick={handleApply}
            >
              Apply
            </Link>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CheckBoxFilterModal;
