import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import icAddBlue from "../../assests/icons/ic-add-blue.svg";
import icDeleteRed from "../../assests/icons/ic-delete-red.svg";
import useWindowDimensions from "../../utils/use_window_dimension";
import { useStoreActions, useStoreState } from "easy-peasy";
import { downloadFile, uploadFile } from "../../_services/view.service";
import { updateProfilePicture } from "../../_services/candidate.service";
import { getLocalStorage } from "../../utils/storage";
import { USER_ID } from "../../config/localstorage_keys";
import toaster from "../../utils/toaster";

import { GENERAL_ERROR_MESSAGE, IMAGE_DIMENSION, IMAGE_SIZE_EXCEEDING, INVALID_IMAGE_TYPE } from "../../config/messages";
import ProfileImage from "../../components/profile_image";
import Dropzone from "react-dropzone";
import { create } from "../../_services/company.service";
import CompanyImage from "../../components/company_image";
import Loader from "../../components/common/loader";
import { STATUS_SUCCESS } from "../../config/localstorage_keys";
import { SOMETHING_WENT_WRONG } from "../../config/be_api_constants";

const CompanyImages = (props) => {
  const [selected, setSelected] = useState(false);
  const [usernameModalShow, setUsernameModalShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [smartViewScreenDisplay, setSmartViewScreenDisplay] = useState(false);
  const [initials, setInitials] = useState("");
  const [potentialImages, setpotentialImages] = useState([]);
  const [profileSrc, setProfileSrc] = useState(null);
  const [profileModalPhoto, setProfileModalPhoto] = useState(null);
  // const [profilePhotoBackup, setProfilePhotoBackup] = useState(null);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [previewRequested, setPreviewRequested] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [usernameData, setUsernameData] = useState();
  const [url, setUrl] = useState();

  const [pictureData, setPictureData] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });
  const [files, setFiles] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });

  const BREAKPOINT_TABLET_VIEW = 900;
  const { width } = useWindowDimensions();

  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );

  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  // const adminUserResponse = useStoreState(
  //   (state) => state?.candidate?.candidateDetails?.adminUserResponse
  // );
  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        setProfileSrc(res);
      } else {
        setProfileSrc(null);
      }
    });
    // alert(downloadedUrl);
  };
  // const getInitialsLetter = async () => {
  //   const name = candidateDetails?.adminUserResponse?.name;

  //   if (name?.split(" ").length > 1) {
  //     let InitName =
  //       name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
  //       name?.split(" ")[1]?.charAt(0)?.toUpperCase();
  //     setInitials(InitName);
  //   } else {
  //     let InitName = name?.split(" ")[0]?.charAt(0)?.toUpperCase();
  //     setInitials(InitName);
  //   }
  // };

  const getProfilePicture = () => {
    if (props.potentialImages !== undefined || null) {
      downloadPicture(props.potentialImages);
      // candidateDetails?.adminUserResponse?.profilePicDownloadUrl
      // adminUserResponse?.profilePicDownloadUrl
    } else {
      setProfileSrc(null);
    }
  };

  useEffect(() => {
    getProfilePicture();
    // getInitialsLetter();
  }, [candidateDetails]);

  const updateProfilePictureApiCall = (response) => {
    setpotentialImages(response?.id);
    const profilePicUpdateObject = {
      potentialImages: [response?.id],
      companyId: props.companyId,
    };
    //use compnay image api call here
    create(profilePicUpdateObject)
      .then((data) => {
        props?.setApiRefresh(true);
        // const userId = getLocalStorage(USER_ID);
        // if (userId) {
        //   saveCandidateDetails(userId);
        // }
        // props?.setProfileModalShow(false);
        // setTimeout(() => {
        setShowLoader(false);
        toaster("success", "Profile picture updated successfully!");
        // }, 1000);
      })
      .catch((err) => {
        props?.setApiRefresh(false);
        setShowLoader(false);
        // toaster(
        //   "error",
        //   "Couldn't update profile picture, please try again later"
        // );
      });
  };

  const onSavePicture = () => {
    setPreviewRequested(false);
    if (deleteFlag) {
      let response = {
        id: "",
      };
      updateProfilePictureApiCall(response);
    } else {
      let formData = new FormData();
      const fileObjects = pictureData?.acceptedFiles.map((file) => {
        formData.append("contentData", file);
        formData.append(
          "contentMetaData",
          JSON.stringify({
            contentType: "PROFILE",
            contentName: `${pictureData?.fileName}`,
            contentExtention: `${pictureData?.extension}`,
          })
        );
      });

      setShowLoader(true);
      uploadFile(formData)
        .then((response) => {
          if (response?.id) {
            updateProfilePictureApiCall(response);
          } else {
            setShowLoader(false);
            toaster("error", SOMETHING_WENT_WRONG);
          }
        })
        .catch((error) => {
          toaster(
            "error",
            error?.message ? error?.message : SOMETHING_WENT_WRONG
          );
          setShowLoader(false);
        });
    }
  };

  const onUploadPicture = (acceptedFiles) => {
    setFiles({
      ...files,
      acceptedFiles,
      // fileName,
      // extension,
    });
    if (acceptedFiles && acceptedFiles?.length >= 1) {
      var image = new Image();
      let isValid = true;

      const fileSize = acceptedFiles[0]?.size;
      const fileName = acceptedFiles[0]?.name;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension = fileName.slice(extensionIndex + 1);
      const isFileExtValid =
        fileName.includes(".png") ||
        fileName.includes(".jpg") ||
        fileName.includes(".jpeg");
    // const fileSize = acceptedFiles[0]?.size;
    // const fileName = acceptedFiles[0]?.name;
    // const extensionIndex = fileName.lastIndexOf(".");
    // const extension = fileName.slice(extensionIndex + 1);
    // const isFileExtValid =
    //   fileName.includes(".png") ||
    //   fileName.includes(".jpg") ||
    //   fileName.includes(".jpeg");
    if (!isFileExtValid) {
        toaster("error", INVALID_IMAGE_TYPE);
        setPreviewRequested(false);
        return;
      }

      const isFileSizeValid = fileSize < 1e6;

      if (!isFileSizeValid) {
        toaster("error", IMAGE_SIZE_EXCEEDING);
        setPreviewRequested(false);
        return;
      }


    if (isFileExtValid && isFileSizeValid) {
      setPictureData({ ...pictureData, acceptedFiles, fileName, extension });
      let previewImage = Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      });
      setPreviewRequested(true);
      setPreviewSrc(previewImage?.preview);
      setDeleteFlag(false);
    } else {
      toaster("error", INVALID_IMAGE_TYPE);
      setPreviewRequested(false);
    }

  //   if (isFileExtValid && isFileSizeValid) {
  //     isValid = true;
  //   } else {
  //     isValid = false;
  //   }

  //   if (isValid) {
  //     let dimensionValid = false;

  //     setPictureData({
  //       ...pictureData,
  //       acceptedFiles,
  //       fileName,
  //       extension,
  //     });
  //     let previewImage = Object.assign(acceptedFiles[0], {
  //       preview: URL.createObjectURL(acceptedFiles[0]),
  //     });

  //     image.src = previewImage?.preview;
  //     image.onload = function () {
  //       if (image.width <= 500 && image.height <= 500) {
  //         dimensionValid = true;
  //       }
  //       if (isValid && dimensionValid) {
  //         setPreviewRequested(true);
  //     setPreviewSrc(previewImage?.preview);
  //     setDeleteFlag(false);
  //       } else {
  //         toaster("error", IMAGE_DIMENSION);
  //         setPreviewRequested(false);
  //       }
  //     };
  //   }
  } else {
    toaster("error", GENERAL_ERROR_MESSAGE);
    setPreviewRequested(false);
  }
  };




  const handleDeletePicture = () => {
    setPreviewRequested(true);
    setPreviewSrc(null);
    setDeleteFlag(true);
  };

  const populatedUsername = () => {
    const candidateName = candidateDetails?.adminUserResponse?.name;
    let firstName = candidateName?.split(" ")[0];
    let lastName = candidateName?.split(" ")[1];
    let copyUsernameData = { ...usernameData };
    copyUsernameData.firstName.valueText = firstName;
    copyUsernameData.lastName.valueText = lastName;
    setUsernameData(copyUsernameData);
  };

  // const downloadPhoto = async (id) => {
  //   let formData = new FormData();
  //   formData.append("fileId", id);
  //   return downloadFile(formData);
  // };

  useEffect(() => {
    if (candidateDetails) {
      setUserDetails(candidateDetails);
    }
  }, [candidateDetails , onUploadPicture]);

  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        onHide={() => props.onImagesClose()}
        backdrop="static"
        fullscreen="lg-down"
        centered
        keyboard={false}
        className="lg-dialog-modal"
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100">
            <div className="d-flex justify-content-end">
              <img
                src={icCloseRounded}
                alt="close-icon"
                onClick={() => props.onImagesClose()}
                className="pointer"
              />
            </div>
            <div className="fs-24 color-primary fw-700 ps-3">
              Company Images
            </div>
          </div>
        </Modal.Header>

        <Modal.Body className="dialog-body">
          <div className="p-3 pt-0">
            <div
              className={
                selected === false
                  ? "d-flex gap-2 text-primary align-items-center  pb-5 d-block"
                  : "d-flex gap-2 text-primary align-items-center "
              }
              onClick={() => setSelected(true)}
            >
              {/* <img
                src={icAddBlue}
                alt="blue-add-icon"
                width="16.67px"
                height="16.67px"
              /> */}
              {/* <div className="fs-14 fw-600">Select & Upload</div> */}
              <div className={selected === false ? "pt-4 d-block" : "none"}>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    onUploadPicture(acceptedFiles);
                  }}
                  multiple={true}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <>
                        <div className="label d-flex align-items-center justify-content-center">
                          <Button
                            variant="secondary"
                            style={{
                              background: "transparent",
                              color: "black",
                            }}
                          >
                            <img
                              src={icAddBlue}
                              alt="blue-add-icon"
                              width="16.67px"
                              height="16.67px"
                            />
                            Upload
                          </Button>
                        </div>
                      </>
                    </div>
                  )}
                </Dropzone>
              </div>
            </div>
            {/* <div className={selected === true ? "pt-4 d-block" : "d-none"}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="fs-16 color-secondary"> {initials} </div>
                <img src={icDeleteRed} alt="delete-pink-icon" />
              </div>
              <div>
                <div className="fs-11 color-tertiary pt-2 pb-2 fw-300">
                  578 MB
                </div>
                <p className="fs-11 color-tertiary fw-300">
                  You can save the selection.
                  <br />
                  The video will be updated in the background.
                </p>
              </div>
            </div> */}
            {profileSrc || previewSrc ? (
              <div>
                {" "}
                {/* <Dropzone
                  onDrop={(acceptedFiles) => {
                    onUploadPicture(acceptedFiles);
                  }}
                  multiple={true}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <>
                        <div className="label d-flex align-items-center justify-content-center">
                          <Button
                            variant="secondary"
                            style={{
                              background: "transparent",
                              color: "black",
                            }}
                          >
                            <img
                              src={icAddBlue}
                              alt="blue-add-icon"
                              width="16.67px"
                              height="16.67px"
                            />
                            Upload
                          </Button>
                        </div>
                      </>
                    </div>
                  )}
                </Dropzone> */}
                <div
                  className="position-absolute deleteIconStyle pointer"
                  onClick={handleDeletePicture}
                >
                  {/* <img src={del eteIcon} alt="delete icon" /> */}
                </div>
                <div className="position-relative d-flex justify-content-center profileImageStyle border rounded-3 overflow-hidden">
                  {previewRequested ? (
                    <ProfileImage
                      src={previewSrc}
                      // name={candidateDetails?.adminUserResponse?.name}
                      initialsContainerClass=" initialsStyle2-xxxl"
                    />
                  ) : (
                    <ProfileImage
                      src={profileSrc}
                      // name={candidateDetails?.adminUserResponse?.name}
                      initialsContainerClass=" initialsStyle2-xxxl"
                    />
                  )}
                </div>
              </div>
            ) : (
              <p className="modal-default-profile-picture d-flex justify-content-center align-items-center">
                {initials}
              </p>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="dialog-footer">
          <button
            className="btn-dialog-cancel pointer"
            onClick={() => props.onImagesClose()}
          >
            Cancel
          </button>
          <button
            className="btn-dialog-save pointer"
            onClick={() => {
              onSavePicture();
              props.onImagesClose();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
//   return (
//     <>
//       {showLoader && <Loader />}
//       <div>
//         {" "}
//         <Modal
//           show={props.show}
//           onHide={() => props.onImagesClose()}
//           backdrop="static"
//           fullscreen="lg-down"
//           centered
//           keyboard={false}
//           className="lg-dialog-modal"
//         >
//           <Modal.Header className="dialog-header border-0">
//             <div className="w-100">
//               <div className="d-flex justify-content-end">
//                 <img
//                   src={icCloseRounded}
//                   alt="close-icon"
//                   onClick={() => props.onImagesClose()}
//                   className="pointer"
//                 />
//               </div>
//               <div className="fs-24 color-primary fw-700 ps-3">
//                 Company Images
//               </div>
//             </div>
//           </Modal.Header>
//           <Modal.Body className="dialog-body">
//             <div className="p-3 pt-0">
//               <div
//                 className={
//                   selected === false
//                     ? "d-flex gap-2 text-primary align-items-center  pb-5 d-block"
//                     : "d-none"
//                 }
//                 onClick={() => setSelected(true)}
//               >
//                 <img
//                   src={icAddBlue}
//                   alt="blue-add-icon"
//                   width="16.67px"
//                   height="16.67px"
//                 />
//                 {/* <CompanyImage
//                   src={props.potentialImages}
//                   width="50px"
//                   initialsContainerClass="initialsStyle2-xl"
//                 /> */}
//                 {/* <Dropzone
//                   onDrop={onUploadPicture}
//                   id="upload-image"
//                   accept="image/*"

//                   // minSize={1024}
//                   // maxSize={3072000}
//                 >
//                   {({
//                     getRootProps,
//                     getInputProps,
//                     isDragActive,
//                     isDragAccept,
//                     isDragReject,
//                   }) => {
//                     // additional CSS depends on dragging status
//                     const additionalClass = isDragAccept
//                       ? "accept"
//                       : isDragReject
//                       ? "reject"
//                       : "";

//                     return (
//                       <div
//                         {...getRootProps({
//                           className: `dropzone ${additionalClass}`,
//                         })}
//                       >
//                         <input {...getInputProps()} />
//                         <span>{isDragActive}</span>
//                         <div className="fs-14 fw-600">Select & Upload</div>
//                       </div>
//                     );
//                   }}
//                 </Dropzone> */}

//                 <Dropzone
//                   onDrop={(acceptedFiles) => {
//                     onUploadPicture(acceptedFiles);
//                   }}
//                   multiple={true}
//                 >
//                   {({ getRootProps, getInputProps }) => (
//                     <div {...getRootProps()}>
//                       <input {...getInputProps()} />
//                       <>
//                         <div className="label d-flex align-items-center justify-content-center">
//                           <Button
//                             variant="secondary"
//                             style={{
//                               background: "transparent",
//                               color: "black",
//                             }}
//                           >
//                             Upload
//                           </Button>
//                         </div>
//                       </>
//                     </div>
//                   )}
//                 </Dropzone>
//               </div>
//               <div className={selected === true ? "pt-4 d-block" : "d-none"}>
//                 <div className="d-flex justify-content-between align-items-center">
//                   {/* <div className="fs-16 color-secondary"> Airbnbabout.mp4 </div> */}
//                   <img src={icDeleteRed} alt="delete-pink-icon" />
//                 </div>
//                 <div>
//                   <div className="fs-11 color-tertiary pt-2 pb-2 fw-300">
//                     578 MB
//                   </div>
//                   <p className="fs-11 color-tertiary fw-300">
//                     You can save the selection.
//                     <br />
//                     The video will be updated in the background.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </Modal.Body>
//           <Modal.Footer className="dialog-footer">
//             <button
//               className="btn-dialog-cancel pointer"
//               onClick={() => props.onImagesClose()}
//             >
//               Cancel
//             </button>
//             <button
//               className="btn-dialog-save pointer"
//               onClick={() => {
//                 onSavePicture();
//                 props.onImagesClose();
//               }}
//             >
//               Save
//             </button>
//           </Modal.Footer>
//         </Modal>
//       </div>
//     </>
//   );
// };

export default CompanyImages;
