import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { APPLICATION_PAGE_ROUTE } from "../../../../config/page_routes_constants";
import { getApplicationDetails } from "../../../../_services/view.service";

const ApplicationHoverCard = ({applicationId}) => {
  // console.log("first", applicationId)

  const [applicationDetails, setApplicationDetails] = useState([]);


  const viewApplicationDetails = () => {
    getApplicationDetails(applicationId) && getApplicationDetails(applicationId).then((res) => {
      setApplicationDetails(res?.data?.data);
    })
  }

  // const getId = applicationDetails && applicationDetails?.jobQuestionAndAnswers[0]?.[1]?.jobApplicationId;
  console.log("getAppDetails---------->", applicationDetails)

  useEffect(() => {
    viewApplicationDetails()
  }, [])

  const {id} = useParams();
  // id= applicationId.filter((e) => e === getId)

  return (
    <div className="w-100">
      <div className="d-flex justify-content-center align-items-center">
        <Link
          to={"/" + APPLICATION_PAGE_ROUTE + `/${applicationId}`}
          className="btn btn-dark fs-12 fw-700 w-100 btn-view-application"
        >
          View Application
        </Link>
      </div>
    </div>
  );
};

export default ApplicationHoverCard;
