import React from "react";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router";
import icThreeDots from "../../../assests/icons/ic_retract.svg";
import { APPLICATION_PAGE_ROUTE } from "../../../config/page_routes_constants";

const DropdownCardDropout = ({applicationId}) => {
  const navigate = useNavigate();

  return (
    <div>
      <Dropdown
        className="down-btn-remove"
        align="end"
        title="Dropdown end"
        id="dropdown-menu-align-end"
      >
        <Dropdown.Toggle
          variant="none"
          className="d-flex p-2 m-0 border-0 bg-transparent "
        >
          <img src={icThreeDots} alt="retract-icon" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="fs-12 text-secondary mt-2 border-box ps-3  pt-0">
          <Dropdown.Item className="d-flex pt-2 ps-0"
          onClick={() =>
            navigate("/" + APPLICATION_PAGE_ROUTE + `/${applicationId}`)
          }>
            <div className="color-primary fw-700 fs-12">View Application</div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownCardDropout;
