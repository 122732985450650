import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import icSort from "../../assests/icons/ic_sort.svg";
import icClose from "../../assests/icons/ic-close.svg";
import blackdone from "./../../assests/icons/ic_blackdone.svg";
import { getApplicationJobListing } from "../../_services/view.service";

const SortDialog = (props) => {
  return (
    <div>
      <Modal
        fullscreen="md-down"
        show={props.show}
        onHide={() => () => props.onSortClose()}
        aria-labelledby="example-custom-modal-styling-title "
        size="md"
        scrollable={true}
        className="mt-5 application-modal rounded-4"
        animation={false}
      >
        <Modal.Header className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex gap-2 fs-16 fw-600 color-primary">
              <div>
                <img src={icSort} alt="" />
              </div>
              <div> Sort By </div>
            </div>
            <div
              className="d-flex gap-3 pointer"
              onClick={() => props.onSortClose()}
            >
              <img src={icClose} alt="close-icon" className="" width="20px" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ps-4 pb-5">
          <div>
            {props?.dropdownSortArray?.map((sort, index) => {
              return (
                <div
                  href="#/action-2"
                  key={index}
                  className="d-flex pb-4 pt-2"
                  // onClick={() => {
                  //   if (props?.arrayIndex == index) {
                  //     props?.setArrayIndex(null);
                  //   } else {
                  //     props?.setArrayIndex(index);
                  //   }
                  // }}
                  onClick={() => {
                    if (props?.arrayIndex == index) {
                          props?.setArrayIndex(null);
                        } else {
                          props?.setArrayIndex(index);
                        }
                    if (sort === "New Applications First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "NEW_APP_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "New Referrals First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "NEW_REFERRAL_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Flagged First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "FLAGGED_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Not Flagged First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "NOT_FLAGGED_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Downloaded First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "DOWNLOADED_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Not Downloaded First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "NOT_DOWNLOADED_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Latest First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "LATEST_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Oldest First") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "OLDEST_FIRST"
                      );
                      props?.onSortClose();
                    }
                    if (
                      sort === "Experience - More to Less"
                    ) {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "EXP_DESC"
                      );
                      props?.onSortClose();
                    }
                    if (
                      sort === "Experience - Less to More"
                    ) {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "EXP_ASC"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "A-z") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "ATOZ"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Offer Date") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "OFFER_DATE"
                      );
                      props?.onSortClose();
                    }
                    if (sort === "Joining Date") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "JOINING_DATE"
                      );
                       props?.onSortClose();
                    }
                    if (sort === "Dropout Date") {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "DROPOUT_DATE"
                      );
                       props?.onSortClose();
                    }
                    if (
                      sort === "Rejection Date - Latest First"
                    ) {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "REJ_LATEST_FIERST"
                      );
                       props?.onSortClose();
                    }
                    if (
                      sort ===
                      "Rejection Date - Earliest First"
                    ) {
                       props.getApplicationCardDetails(
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        "REJ_EARLIEST_FIERST"
                      );
                       props?.onSortClose();
                    }
                  }}
                >
                  <img
                    src={blackdone}
                    alt="blackdone-icon"
                    className={
                      props?.arrayIndex === index
                        ? "pe-3 d-block"
                        : "d-none pe-1"
                    }
                  />{" "}
                  <span
                    className={
                      props?.arrayIndex === index
                        ? "ps-0 color-primary fw-700 fs-12"
                        : "ps-4 fs-12"
                    }
                  >
                    {sort}
                  </span>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SortDialog;
