import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getCountryMaster } from "../../../../_services/candidate.service";
import close from "../../../../assests/icons/ic-invite-pg-close.svg";
import { USER_ID } from "../../../../config/localstorage_keys";
import {
  FORM_VALIDATION_MESSAGE,
  GENERAL_ERROR_MESSAGE,
} from "../../../../config/messages";
import {
  onFormFeildsChange,
  validateField,
} from "../../../../utils/form_validators";
import { checkPhoneNumberValid } from "../../../../utils/utils";
import {
  updateMobile,
  updateNotificationPreference,
} from "../../../../_services/member-profile.service";
import Loader from "../../../../components/common/loader";
import toaster from "./../../../../utils/toaster";
import { getLocalStorage } from "../../../../utils/storage";
import SearchComboBox from "../../../../components/SearchComboBox";

function AddNumberModal(props) {
  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );

  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const NotificationPreferenceSettings = useStoreState(
    (state) =>
      state?.candidate?.candidateDetails?.NotificationPreferenceSettings
  );
  const [countryCode, setCountryCode] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [editMobile, setEditMobile] = useState();
  const [UpdateWhatsApp, setUpdateWhatsApp] = useState();
  const [UpdateSms, setUpdateSms] = useState();
  const [apiRefresh, setApiRefresh] = useState(false);
  const [phonenumber, setPhoneNumber] = useState();
  const [isError, setIsError] = useState({ phoneNumberError: "" });
  const [formData, setFormData] = useState({
    phonenumber: {
      valueText: "",
      errorText: "",
      check: ["required"],
    },
    countrycode: {
      valueText: "+91",
      errorText: "",
      check: ["required"],
    },
  });

  const accountNotificationPreferencesResponse = useStoreState(
    (state) =>
      state?.candidate?.candidateDetails?.accountNotificationPreferencesResponse
  );
  const adminUserResponse = useStoreState(
    (state) => state?.candidate?.candidateDetails?.adminUserResponse
  );

  const updateNotificationPreferenceSettings = (changefeild, changeValue) => {
    const temp = { ...accountNotificationPreferencesResponse };
    temp[changefeild] = changeValue;
    setShowLoader(true);
    updateNotificationPreference(
      temp?.updateOnEmail,
      temp?.updateOnWhatsApp,
      temp?.updateOnSms
    )
      .then((res) => {
        const userId = getLocalStorage(USER_ID);
        if (userId) {
          saveCandidateDetails(userId);
        }
        toaster("success", "Saved successfully!");
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };

  const checkPhoneNumberValid = () => {
    let isValid = true;
    if (phonenumber.length > 10) {
      setIsError({ phoneNumberError: "Enter validate mobile number" });

      //   if (formData?.mobileNumber?.valueText !== 10) {
      //     setFormError({
      //       mobileNumber: "Enter validate mobile number",
      //     });
      isValid = false;
    } else {
      setIsError({
        phoneNumberError: "",
      });
      isValid = true;
    }
    //   setFormData(formData);
    return isValid;
  };
  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      if (formData?.phonenumber?.errorText == "") {
        updateNumber();
      }
    }
  };

  const updateNumber = () => {
    // let isValid = true;

    // Object.keys(formData)?.forEach((key) => {
    //   if (!validateField(key, formData, setFormData)) {
    //     isValid = false;
    //   }
    // });

    // if (isValid) {
    //   const mobileNumber =
    //     formData?.countrycode?.valueText + formData?.phonenumber?.valueText;
    if (phonenumber === "") {
      toaster("error", "Please Add Mobile number");
    } else if (checkPhoneNumberValid()) {
      setShowLoader(true);
      updateMobile(phonenumber)
        .then((res) => {
          setShowLoader(false);
          if (res?.data?.status == "SUCCESS") {
            props?.setApiRefresh(true);
            props?.setShowAddNumberModal(false);

            toaster("success", res?.data?.message);
          }
          //  else {
          //   toaster(
          //     "error",
          //     res?.message ? res?.message : GENERAL_ERROR_MESSAGE
          //   );
          // }
        })
        .catch((err) => {
          props?.setApiRefresh(false);
          setShowLoader(false);
          toaster("error", err?.message ? err?.message : GENERAL_ERROR_MESSAGE);
        });
    }
  };

  useEffect(() => {
    if (apiRefresh === true) {
      updateNumber();
      setApiRefresh(false);
    }
  }, [apiRefresh]);
  useEffect(() => {
    const getCountryCodeCall = async () => {
      const countryCodeResponse = await getCountryMaster();
      let countryCodeObj = [];
      countryCodeResponse?.data?.map((data) => {
        countryCodeObj.push({
          name: data?.name,
          dialingCode: data?.dialingCode,
        });
      });
      setCountryCode(countryCodeObj);
    };
    getCountryCodeCall();

    // if (props?.Mobile) {
    //   setEditMobile(props?.Mobile);
    // } else {
    //   setEditMobile("");
    // }
    // if (props?.WhatsApp) {
    //   setUpdateWhatsApp(props?.WhatsApp);
    // } else {
    //   setUpdateWhatsApp("");
    // }
    // if (props?.SMS) {
    //   setUpdateSms(props?.SMS);
    // } else {
    //   setUpdateSms("");
    // }
  }, []);

  return (
    <>
      {showLoader && <Loader />}

      <Modal
        show={props?.showAddNumberModal}
        onHide={() => {
          props?.setShowAddNumberModal(false);
        }}
        backdrop="static"
        className=" p-0"
        fullscreen="md-down"
        keyboard={false}
        centered
      >
        <Modal.Header
          style={{ border: "none" }}
          className="dialog-header mt-0 mt-sm-3 align-items-start"
        >
          <div className="p-3">
            <div className="fs-24 fw-700">
              Stay updated even when you are not logged in
            </div>
            <div className="small-text-gray mt-2 fs-14 fw-400">
              Receive updates on SMS & WhatsApp
            </div>
          </div>
          <div className="modal-close-wrapper">
            <img
              src={close}
              alt="close-icon"
              className="p-1 pt-1 pb-1 pointer"
              onClick={() => {
                props?.setShowAddNumberModal(false);
              }}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body pb-4">
          <div className="fw-600 fs-14 p-3 py-0">Phone Number</div>
          <div className="d-flex mt-2 p-3 py-0">
            <select
              className="bg-white form-control form-select pointer rounded outline-none color-primary fs-12 p-2 account-country-code"
              value={formData?.countrycode?.valueText}
              name="countrycode"
              id="countryCode"
              aria-label="Select country"
              onChange={($event) => {
                onFormFeildsChange($event, formData, setFormData);
                if (formData?.phonenumber?.valueText) {
                  const isPhoneNumberValid = checkPhoneNumberValid(
                    formData?.phonenumber?.valueText,
                    $event?.target?.value
                  );
                  let copyFormData = { ...formData };

                  if (!isPhoneNumberValid) {
                    copyFormData.phonenumber.errorText =
                      FORM_VALIDATION_MESSAGE.PHONE;
                  } else {
                    copyFormData.phonenumber.errorText = "";
                    setFormData(copyFormData);
                  }
                  // setFormData(copyFormData);
                }
              }}
            >
              {countryCode?.map((codeData, index) => {
                return (
                  <option
                    key={index}
                    value={codeData?.dialingCode}
                    name={codeData?.dialingCode}
                  >
                    {codeData?.dialingCode}
                  </option>
                );
              })}
            </select>
            {/* <input
            className="form-control ms-2 fs-12 modal-form-input"
            placeholder="Enter Number"
          /> */}
            {/* <div className="col ps-md-3 ps-xl-0"> */}
            <input
              onKeyDown={(e) => {
                submitFormOnEnter(e);
              }}
              // value={formData?.phonenumber?.valueText}
              defaultValue={props?.Mobile}
              // defaultValue={adminUserResponse?.mobile}
              // defaultValue={
              //   props?.setShowAddNumberModal
              //     ? adminUserResponse?.mobile
              //     : props?.Mobile
              // }
              type="number"
              name="phonenumber"
              // onChange={($event) => {
              //   onFormFeildsChange($event, formData, setFormData);
              //   const isPhoneNumberValid = checkPhoneNumberValid(
              //     $event?.target?.value,
              //     formData?.countrycode?.valueText
              //   );
              //   let copyFormData = { ...formData };

              //   if (!isPhoneNumberValid) {
              //     copyFormData.phonenumber.errorText =
              //       FORM_VALIDATION_MESSAGE.PHONE;
              //     setFormData(copyFormData);
              //   } else {
              //     copyFormData.phonenumber.errorText = "";
              //     setFormData(copyFormData);
              //   }
              // }}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
              className={
                (formData?.phonenumber?.errorText ? "error" : "") +
                " form-control ms-2 fs-12 modal-form-input"
              }
            />
            {/* <div className="field-error mt-1 ">
              {formData?.phonenumber?.errorText}
            </div> */}
            <p className="field-error mt-1">{isError.phoneNumberError}</p>
            {/* </div> */}
          </div>
          <div className="p-3 py-0">
            <div className="fw-600 fs-14 mt-5 pb-2">
              Notification preferences
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <span className="small-text-gray">
                Receive updates on WhatsApp
              </span>
              <span className="form-switch">
                <input
                  type="checkbox"
                  className="form-check-input switch-btn-small pointer"
                  checked={
                    accountNotificationPreferencesResponse?.updateOnWhatsApp
                  }
                  onChange={(e) => {
                    updateNotificationPreferenceSettings(
                      "updateOnWhatsApp",
                      e.target.checked
                    );
                  }}
                />
              </span>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <span className="small-text-gray">Receive updates on SMS</span>
              <span className="form-switch">
                <input
                  type="checkbox"
                  className="form-check-input switch-btn-small pointer"
                  checked={accountNotificationPreferencesResponse?.updateOnSms}
                  onChange={(e) => {
                    updateNotificationPreferenceSettings(
                      "updateOnSms",
                      e.target.checked
                    );
                  }}
                />
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ border: "none" }} className="dialog-footer ">
          <button
            className="btn fw-700 fs-12 btn-add-number mt-3 "
            onClick={() => {
              props?.setShowAddNumberModal(false);
            }}
          >
            Skip
          </button>
          <button
            className="btn fw-700 fs-12 btn-save-number mt-3 "
            onClick={() => {
              if (formData?.phonenumber?.errorText == "") {
                updateNumber();
              }
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddNumberModal;
