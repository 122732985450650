import React from "react";
import educationLogo from "../../../assests/icons/ic_education.svg";
import NoDataFoundCard from "../../../components/common/no_data_found_card";
import { NO_EDUCATION_ADDED } from "../../../config/messages";

const ApplicationEducationAndAcademics = ({ applicationDetails }) => {
  const educationAndAcademicsDetails = [
    {
      courseStartDate: "Jun 2020",
      courseEndDate: "Aug 2022",
      qualification: "Doctorate",
      institute: "Kings University, Sydney",
      degreeName: "Doctor of Science (DSc) ",
      specialization: "Sales forecasting techniques",
      outcome: "Distinction",
    },
    {
      courseStartDate: "Apr 2012",
      courseEndDate: "Jul 2014",
      qualification: "Post-Graduation",
      institute: "ISB Hyderabad",
      degreeName: "Master of Business Administration (MBA)",
      specialization: "Applied Statistics",
      outcome: "Grade 1",
    },
    {
      courseStartDate: "Apr 2006",
      courseEndDate: "Jul 2011",
      qualification: "Graduation",
      institute: "NIT Hamirpur",
      degreeName: "Bachelor of technology (Btech)",
      specialization: "Computer Science",
      outcome: "GPA 6.2 / 10",
    },
  ];

  return (
    <div>
      <div className="mt-2 mb-4">
        <span className="fw-bold" style={{ fontSize: "24px" }}>
          <img
            style={{ marginRight: "14px" }}
            src={educationLogo}
            alt={educationLogo}
          />
          Education and Academics
        </span>
      </div>
      {applicationDetails?.viewProfileResponse?.educationalExperienceResponse
        ?.length !== null ||
      applicationDetails?.viewProfileResponse?.educationalExperienceResponse
        ?.length !== undefined ||
      applicationDetails?.educationalExperienceResponse?.length !== null ||
      applicationDetails?.educationalExperienceResponse?.length !==
        undefined ? (
        <div className="card-parent-container mb-5">
          {(applicationDetails?.viewProfileResponse
            ?.educationalExperienceResponse
            ? applicationDetails?.viewProfileResponse
                ?.educationalExperienceResponse
            : applicationDetails?.educationalExperienceResponse
          )?.map((edu) => {
            return (
              <>
                <div className="card-container p-3">
                  <div className="pt-3 ps-2">
                    <div>
                      <span className="gray-color-badge">
                        {edu?.courseStartDate} - {edu?.courseEndDate}
                      </span>
                    </div>
                    <div className="title-card py-3">{edu?.qualification} </div>
                    <div className="card-subtitle">{`${edu?.institute}`}</div>
                    <div className="card-content">{`${edu?.degreeName}, ${edu?.specialization}`}</div>
                    <div className="fs-11 fw-600 color-secondary">
                      {edu?.outcome}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <NoDataFoundCard text={NO_EDUCATION_ADDED} />
      )}
    </div>
  );
};

export default ApplicationEducationAndAcademics;
