import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { isEmpty } from "../../utils/form_validators";
import checkedLogo from "../../assests/ic_selected16.svg";
import { deletemember, getmember } from "../../_services/view.service";
import { useNavigate, useParams } from "react-router";
import toaster from "../../utils/toaster";
import { DEFAULT_PAGE_SIZE } from "../../config/be_seikorAdmin_api_constants";
import { TEAMS_MEMBERS_ROUTE } from "../../config/page_routes_constants";

const DeleteMemberDialog = (props) => {
  const [removemember, setRemoveMember] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();
  // const initialFilterData = {
  //   pageNo: 1,
  //   pageSize: DEFAULT_PAGE_SIZE,
  // };
  const navigate = useNavigate();
  // Response
  const onDeleteMember = () => {
    setShowLoader(true);
    deletemember(id)
      .then((res) => {
        props.close();
        setRemoveMember(res?.data?.data);
        navigate(-1)
        // getmember(initialFilterData);
        
        toaster("success", "Member deleted successfully");
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };

  // // useEffect(() => {
  // //   onDeleteMember(id);
  // });
  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        onHide={() => props.close()}
        centered
        fullscreen="lg-down"
        backdrop="static"
        keyboard={false}
        className=""
        contentClassName=""
      >
        <Modal.Header className=" border-0" bsPrefix="modal-header-custom">
          <div className="fs-20 color-primary fw-700 px-2 pt-2">
            Delete Member
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            onClick={() => props.close()}
            className="pointer"
            height="30px"
            width="30px"
          />
        </Modal.Header>
        <Modal.Body>
          <p className="color-tertiary">
            Are you sure you want to delete this member?
          </p>
        </Modal.Body>
        <Modal.Footer className="dialog-footer border-0">
          <button
            className="btn pointer text-dark fs-12 fw-700  border-dark border-1"
            onClick={() => props.close()}
          >
            Cancel
          </button>
          <button
            className="btn pointer fs-12 fw-700 yes-delete border-0"
            onClick={() => {
              onDeleteMember(id);
              // navigate(-1)
            }}
          >
            Yes Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteMemberDialog;
