import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackButton from "../../../assests/icons/back-icon.svg";
import RefereeBarChart from "../../../components/referee_bar_chart";
import { isEmpty } from "../../../utils/form_validators";
import toaster from "../../../utils/toaster";
import { refereeSummary } from "../../../_services/member-profile.service";
import { getViewCandidateProfile } from "../../../_services/serikoradmin_view.service";
import { getApplicationDetails } from "../../../_services/view.service";
import ApplicationAboutMe from "../job_application_view_page/application_about_me";
import ApplicationContactOnlinePresence from "../job_application_view_page/application_contact_online_presence";
import ApplicationEducationAndAcademics from "../job_application_view_page/application_education_and_academics";
import ApplicationExperience from "../job_application_view_page/application_experience";
import ApplicationResume from "../job_application_view_page/application_resume";
import ApplicationSkills from "../job_application_view_page/application_skills";
import WhyShouldWeHire from "../job_application_view_page/why_should_we_hire";

const ProfilePage = () => {
  let { userId ,applicationId} = useParams();
  console.log(userId,"useriattttttt");
  const [applicationDetails, setApplicationDetails] = useState([]);
  const [summary, setSummary] = useState([]);

  const viewApplicationDetails = () => {
    getViewCandidateProfile(userId)?.then((res) => {
      setApplicationDetails(res?.data?.data);
    });
  };

  console.log("applicationDetails==>", applicationDetails);

  const RefereeSummary = () => {
    refereeSummary(userId).then(
      (res) => {
        if (!isEmpty(res)) {
          setSummary(res?.data?.data);
        } else {
          setSummary([]);
        }
      },
      (error) => {
        toaster("error", error);
      }
    );
  };


  console.log("summary" , summary);
  const refereeBarChartData = {
    referrals: summary?.referrals ,
    applications: summary?.applications,
    joinees: summary?.joinees,
    joineesPercentage: summary?.joineesPercentage,
  };

  useEffect(() => {
    RefereeSummary();

    viewApplicationDetails();
  }, []);

  // const refereeBarChartData = {
  //   referrals: 332,
  //   applications: 538,
  //   joinees: 94,
  // };
  const contactDetails = {
    email: "kartikabhilashi@hey.com",
    phonenumber: "+91 88002 78211",
    location: "+91 88002 78211",
    linkedInLink: "linkedin.com/userprofile/234904924",
    link1name: "My Stack Profile",
    link1link: "behance.net/user849y92",
    link2name: "My Stack Profile",
    link2link: "behance.net/user849y92",
    link3name: "My Stack Profile",
    link3link: "behance.net/user849y92",
  };

  const skills = [
    "Critical Thinking ",
    "User Interviews",
    "Usability Study",
    "Prototyping",
  ];
  const industries = [
    "Healthcare",
    "Information Technology",
    "Edtech",
    "Healthcare",
  ];

  const functions = ["Design", "Marketing"];

  const education = [
    {
      startMonth: "Jun",
      startYear: "2020",
      endMonth: "Present",
      endYear: "",
      degreeName: "UX Designer II",
      collegeName: "Glowhomes Technologies | New Delhi | Full Time | Design",
      subjectName:
        "Worked on design explorations and execution for e-commerce flatform for construction industry. Worked on design explorations and execution for e-commerce flatform for construction industry.”",
    },
    {
      startMonth: "Apr",
      startYear: "2018",
      endMonth: "Mar",
      endYear: "2020",
      degreeName: "UX Designer",
      collegeName: "Microsoft | Gurgaon | Full Time | Design",
      subjectName:
        "Worked on design explorations and execution for e-commerce flatform for construction industry. Worked on design explorations and execution for e-commerce flatform for construction industry.”",
    },
  ];

  const educationAndAcademicsDetails = [
    {
      courseStartDate: "Jun 2020",
      courseEndDate: "Aug 2022",
      qualification: "Doctorate",
      institute: "Kings University, Sydney",
      degreeName: "Doctor of Science (DSc) ",
      specialization: "Sales forecasting techniques",
      outcome: "Distinction",
    },
    {
      courseStartDate: "Apr 2012",
      courseEndDate: "Jul 2014",
      qualification: "Post-Graduation",
      institute: "ISB Hyderabad",
      degreeName: "Master of Business Administration (MBA)",
      specialization: "Applied Statistics",
      outcome: "Grade 1",
    },
    {
      courseStartDate: "Apr 2006",
      courseEndDate: "Jul 2011",
      qualification: "Graduation",
      institute: "NIT Hamirpur",
      degreeName: "Bachelor of technology (Btech)",
      specialization: "Computer Science",
      outcome: "GPA 6.2 / 10",
    },
  ];

  const profileDetails = {
    name: "Amon Soon",
    designation: "Senior Visual Designer",
    companyName: "Microsoft",
    role: "Actively Looking",
  };

  return (
    <div>
      <div className=" containerbg-white mt-2 w-100 pt-1 pt-lg-4">
        <div className="content-margin mt-5 p-4 p-lg-0 pb-3 pb-lg-3">
          <div className="d-flex justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="d-flex mb-4 w-100">
                <Link to={-1} className="color-primary d-flex gap-2">
                  <img src={BackButton} alt={BackButton} />
                  <div className="color-primary fs-12 fw-400 back-btn-width">
                    Go Back
                  </div>
                </Link>
              </div>
              <div>
                <ApplicationAboutMe
                  profilePage={true}
                  applicationDetails={applicationDetails}
                  profileDetails={profileDetails}
                />
              </div>
              <div className="shadow-box mt-3 ">
                <div className="fs-16 black-text fw-600 px-3 pt-2">
                  Referral Stats
                </div>
                <div className="align-middle">
                  <RefereeBarChart refereeBarChartData={refereeBarChartData} />
                </div>
              </div>
              <div>
                <WhyShouldWeHire
                  summery={`I am a person who is positive about every aspect of life. There are
          many things I like to do, to see, and to experience. I like to read, I
          like to write; I like to think, I like to dream; I like to talk, I
          like to listen. I like to see the sunrise in the morning, I like to
          see the moonlight at night; I like to feel the music flowing on my
          face, I like to smell the wind coming from the ocean.`}
                  applicationDetails={applicationDetails}
                />
              </div>
              <ApplicationResume
                name={"Kartik_Resume2020.pdf"}
                applicationDetails={applicationDetails}
              />
              <ApplicationContactOnlinePresence
                contactDetails={contactDetails}
                profilePage={true}
                applicationDetails={applicationDetails}
              />
              <ApplicationSkills
                skills={skills}
                industries={industries}
                functions={functions}
                applicationDetails={applicationDetails}
              />
              <ApplicationExperience
                education={education}
                applicationDetails={applicationDetails}
              />
              <ApplicationEducationAndAcademics
                educationAndAcademicsDetails={educationAndAcademicsDetails}
                applicationDetails={applicationDetails}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
