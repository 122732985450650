import React from "react";

const JobCardIndustry = ({jobViewDetails}) => {
  const industries = [
    "Finance",
    "Payments",
    "Loans",
    "Digital Payments",
    "eWallets",
    "Digital Payments",
  ];

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex align-items-center">
          <div className="fs-14 fw-600 color-primary">Industry</div>
        </div>

        {jobViewDetails.referralCriteriaIndustry && jobViewDetails.referralCriteriaIndustry?.length > 0 && (
          <div className="d-flex flex-wrap mt-2 gap-2">
            {jobViewDetails.referralCriteriaIndustry && jobViewDetails.referralCriteriaIndustry.map((industry, index) => (
              <span className="gray-color-badge" key={index}>
                {industry}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCardIndustry;
