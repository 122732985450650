import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useParams } from "react-router";
import closeIcon from "../../assests/icons/ic-invite-pg-close.svg";
import addIcon from "../../assests/icons/ic_add.svg";
import { EMAIL_PATTERN } from "../../config/validation_patterns_constants";
import { onFormFeildsChange } from "../../utils/form_validators";
import toaster from "../../utils/toaster";
import { saveMember, viewOraganization } from "../../_services/company.service";

function AdminEditPocDialog(props) {
  const [showLoader, setShowLoader] = useState(false);
  const { orgId } = useParams();

  const [formData, setFormData] = useState({
    name: {
      valueText: "",
      errorText: "",
      check: ["required"],
      shouldShowError: false,
    },
    phone: {
      valueText: "",
      errorText: "",
      check: ["required"],
      shouldShowError: false,
    },
    other: {
      valueText: "",
      errorText: "",
      check: ["required"],
      shouldShowError: false,
    },
    email: {
      valueText: "",
      errorText: "",
      check: ["required", EMAIL_PATTERN],
      shouldShowError: false,
    },
  });
  //   const role = useStoreState((state) => state?.candidate?.role);
  // console.log(role,"role")
  const role = "BIZ_ADMIN";
  const saveValue = {
    // workEmail : formData?.email?.valueText,
    // role:"BIZ_ADMIN",
    // allOrgsAccess: false,
    // listOfOrgs: [
    //   parseInt(orgId)
    // ],
    // businessId: parseInt(orgId),
    // listOfTeams: [],
    // isPoc: true,
    // name :formData?.name?.valueText,
    // mobile: parseInt(formData?.phone?.valueText),
    // other:formData?.other?.valueText,
    companyId: parseInt(orgId),
    pocName: formData?.name?.valueText,
    pocEmail: formData?.email?.valueText,
    pocOther: formData?.other?.valueText,
    pocNumber: parseInt(formData?.phone?.valueText),
  };
  const businessId = parseInt(orgId);
  const allOrgsAccess = false;
  const listOfOrgs = [parseInt(orgId)];
  const listOfTeams = [];
  const isPoc = true;

  const onSave = () => {
    setShowLoader(true);
    saveMember(
      parseInt(orgId),
      formData?.name?.valueText,
      formData?.email?.valueText,
      formData?.other?.valueText,
      parseInt(formData?.phone?.valueText)
    )
      // formData?.email?.valueText ||  props?.viewOrg?.pocUserEmail,role,allOrgsAccess,listOfOrgs,businessId,listOfTeams,isPoc,formData?.name?.valueText,parseInt(formData?.phone?.valueText),formData?.other?.valueText)
      .then((res) => {
        setShowLoader(false);

        props?.setEditPocModalShow(false);
        props?.setApiRefresh(true);
      })
      .catch((err) => {
        setShowLoader(false);
        props?.setApiRefresh(false);
        toaster("error", err);
      });
  };
  //   const fetchDetails=()=>{

  //     setShowLoader(true);
  //     viewOraganization(parseInt(orgId))
  //     .then((res) => {
  //       // setViewOrg(res?.data?.data);
  //       setShowLoader(false);

  //     })
  //     .catch((err) => {
  //       setShowLoader(false);
  //       // props?.setApiRefresh(false);
  //       toaster("error", err);
  //     });
  //   }

  // useEffect(() => {
  //   fetchDetails();
  // }, []);
  return (
    <Modal
      show={props?.editPocModalShow}
      onHide={() => {
        props?.setEditPocModalShow(false);
      }}
      fullscreen="lg-down"
    >
      <Modal.Header className="dialog-header border-0 px-4 ">
        <Modal.Title className="dialog-title  fw-700 fs-20">
          Add POC
        </Modal.Title>
        <img
          className="pointer"
          onClick={() => {
            props?.setEditPocModalShow(false);
          }}
          alt=""
          style={{ widht: "30px" }}
          src={closeIcon}
        />
      </Modal.Header>
      <Modal.Body className=" px-4">
        <div className="">
          <label className="modalLabel mt-3 fs-14 fw-600">Name</label>
          <input
            // className=" form-control modal-input"
            required
            name="name"
            defaultValue={props?.viewOrg?.pocUserName}
            // value={formData?.name?.valueText}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
            }}
            className={
              (formData?.name?.errorText && formData?.name?.shouldShowError
                ? "error"
                : "") + " form-control"
            }
          />
          <div className="field-error mt-1">
            {formData?.code?.shouldShowError ? formData?.code?.errorText : ""}
          </div>
          <label className="modalLabel mt-4 fs-14 fw-600">Email</label>

          <input
            // className=" form-control modal-input"
            required
            type="email"
            name="email"
            // disabled={
            //   props?.viewOrg?.pocUserEmail
            // }
            defaultValue={props?.viewOrg?.pocUserEmail}
            // value={formData?.email?.valueText}
            maxLength={50}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
            }}
            className={
              (formData?.email?.errorText && formData?.email?.shouldShowError
                ? "error"
                : "") + " form-control"
            }
          />
          <div className="field-error mt-1">
            {formData?.email?.shouldShowError ? formData?.email?.errorText : ""}
          </div>

          <label className="modalLabel mt-4 fs-14 fw-600">Phone</label>
          <input
            // className=" form-control modal-input"
            required
            name="phone"
            type="number"
            defaultValue={props?.viewOrg?.pocUserMobile}
            //value={formData?.phone?.valueText}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
            }}
            className={
              (formData?.phone?.errorText && formData?.phone?.shouldShowError
                ? "error"
                : "") + " form-control"
            }
          />
          <div className="field-error mt-1">
            {formData?.phone?.shouldShowError ? formData?.phone?.errorText : ""}
          </div>

          <label className="modalLabel mt-4 fs-14 fw-600">Other</label>
          <input
            // className=" form-control modal-input"
            required
            name="other"
            defaultValue={props?.viewOrg?.other}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
            }}
            value={formData?.other?.valueText}
            className={
              (formData?.other?.errorText && formData?.other?.shouldShowError
                ? "error"
                : "") + " form-control"
            }
          />
          <div className="field-error mt-1">
            {formData?.other?.shouldShowError ? formData?.other?.errorText : ""}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ background: "#f4f7fb" }}
        className="username-buttons p-3 mt-3"
      >
        <Button
          className="btn btn-dark btn-save px-5"
          onClick={() => {
            onSave();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AdminEditPocDialog;
