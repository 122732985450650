export const EMAIL_PATTERN =
  /^([^@\s\."'\(\)\[\]\{\}\\/,:;]+\.)*[^@\s\."'\(\)\[\]\{\}\\/,:;]+@[^@\s\."'\(\)\[\]\{\}\\/,:;]+(\.[^@\s\."'\(\)\[\]\{\}\\/,:;]+)+$/;
export const PHONE_NUMBER_PATTERN = /^[0-9+]{10,15}$/;
export const OTP_PATTERN = /^[0-9]{4}$/;
// export const PASSWROD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
export const pattern = (regExp, errorMsg = "Pattern not matched") => {
  return { pattern: regExp, errorMsg };
};
export const PASSWORD_PATTERN =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8}$/;
export const NAME_PATTERN = /^[a-zA-Z0-9-]+[a-z A-Z 0-9 -]*$/;
export const YEAR_PATTERN = /^(19|20)\d{2}$/;
export const PHONE_NUMBER_PATTERN_REGISTRATION = /^[0-9+]{5,13}$/;
export const PHONE_NUMBER_PATTERN_LOGIN = /^[0-9+]{9,19}$/;
export const PHONE_CHECK = "International Phone Check";
export const ALPHABET_CHECK = /[a-zA-Z]/;

export const REQUIRED = "required";
export const PASSWORD = "password";

export const INVALID_YEAR = "Invalid Year";
export const INVALID_MONTH = "Invalid Month";
export const INVALID_VALUE = "Invalid Value";
export const URL_PATTERN =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
