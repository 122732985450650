export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_CARD_SIZE = 10;

export const GET_JOB_TAG_MASTER = "/api/common/getMaster";
export const ADD_JOB_TAG_TITLE = "/api/admin/jobs/jobTagTitle";

export const VIEW_CANDIDATE_PROFILE = "/api/candidate/viewCandidate";
export const DELETE_APPROVE = "/api/dataField/deleteForApprove";
export const GET_DATA_FIELDS_APPROVED = "/api/dataField/getAll";
export const GET_MASTER_DETAILS_DATA_FIELDS = "/api/dataField/getMasterDetails";
export const DELETE__DATA_FIELDS = "/api/dataField/delete";
export const DATA_APPROVE_ENDPOINT = "/api/dataField/approve";
export const SEIKOR_ADMIN_ALLDATA_ENDPOINT = "/api/seikorAdmin/getAll";
export const SEIKOR_ADMIN_APPROVAL_ENDPOINT =
  "/api/dataField/getAllForApproval";
export const ADDMASTER_ENDPOINT = "/api/common/addMaster";
export const ALL_ORGS_PIN_UNPIN = "api/org/pinnedOrUnpinned";
export const GET_ALL_USERS = "/api/users/getAll";
export const PINNED_ORGS = "api/org/allOrganization";
export const PINNED_JOBS = "api/admin/jobs/getAll";
export const OVERVIEW_UNPIN_JOBS = "api/jobs/pinJob";
export const OVERVIEW_MORE_INSIGHTS = "api/admin_overview/admin_moreInsights";
export const OVERVIEW_ORG_TOP_REFEREES =
  "api/admin_overview/admin_orgTopReferees";
export const OVERVIEW_ORG_SUMMARY =
  "api/admin_overview/admin_platformAnalytics";
export const NEW_REQ_GET_FEEDBACK = "api/admin/getFeedback";
export const NEW_REQ_DELETE_FEEDBACK = "api/admin/feedbackDelete";
export const SAVEMEMBER_ENDPOINT = "/api/admin/saveMember";
export const ALl_ORGANIZATION = "/api/seikorAdmin/getAllOrganization";
export const ALl_ORGANIZATION_LIST_ENDPOINT =
  "/api/seikorAdmin/getAllOrganizationByUser";
export const GET_ALL_JOB_POST = "/api/admin/jobs/getAll";
export const JOB_PROMOTION_ENDPOINT = "/api/job/promotion";
export const GET_JOB_PROMOTION_ENDPOINT="/api/job/getPromotion"
export const ADD_DATA_FIELDS_ENDPOINT = "/api/common/addMaster";
export const DELETE_ADMIN_ENDPOINT = "/api/seikorAdmin/deleteAdmin/";
export const GET_APPROVE_ENDPOINT = "/api/dataField/getMasterDetailsNotApprove";
export const JOB_POSTS_PIN_UNPIN = "api/jobs/pinJob";
export const FETCH_MESSAGES = "api/messages/fetchMessages";
export const SEND_MESSAGES_TO_RECRUITER = "api/messages/sendMessages";
export const GET_MASTER = "/api/common/getMaster";
export const GET_REPORT = "/api/admin/report/getReport";
export const NOTIFY_FOR_RECOMMENDATION = "api/referrer/recommendationNotify";
export const JOB_DELETE_PROMOTINAL_ENDPOINT = "/api/job/deletePromotionalImage/";
