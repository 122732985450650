import React, { useEffect, useState } from "react";
import { EMAIL_PATTERN } from "../config/validation_patterns_constants";

import Dialog from "./common/dialog";
import ReferForJob from "./ReferForJob";

function ReferAJobDialog(props) {
  const [formData, setFormData] = useState({
    email: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required", EMAIL_PATTERN],
    },
    name: { valueText: "", initial: "", errorText: "", check: ["required"] },
    contactNumber: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    countryCode: {
      valueText: "+91",
      initial: "+91",
      errorText: "",
      check: ["required"],
    },
    commentsCandidate: {
      valueText: "",
      initial: "",
      check: [""],
    },
    commentsBusiness: {
      valueText: "",
      initial: "",
      check: [""],
    },
    candidateRating: {
      valueText: "3",
      initial: "3",
      check: [""],
    },
    topReasonsToRefer: {
      valueText: [],
      initial: [],
      check: [""],
    },
  });

  return (
    <Dialog
      onClosedButtonClick={props?.onClosedButtonClick}
      isShow={props?.referJobShow}
      referButtonClicked={props?.setReferButtonClicked}
      disableReferralBtn={props?.disableReferralBtn}
      editReferral={props?.editReferral}
    >
      <ReferForJob
        referButtonClicked={props?.referButtonClicked}
        disableReferralBtn={props?.disableReferralBtn}
        setDisableReferralBtn={props?.setDisableReferralBtn}
        referJobId={props?.referJobId}
        refereeId={props?.refereeId}
        formData={formData}
        referrerId={props?.referrerId}
        setFormData={setFormData}
        Feedback={props?.Feedback}
        ratingStar={props?.ratingStar}
        reason={props?.reason}
        setReferButtonClicked={props?.setReferButtonClicked}
        referJobShow={props?.referJobShow}
        setReferJobShow={props?.setReferJobShow}
        editReferral={props?.editReferral}
        newJobReferred={props?.newJobReferred}
        setNewJobReferred={props?.setNewJobReferred}
        onJobReferred={() => {
          if (props?.onJobReferred) {
            props?.onJobReferred();
          }
        }}
        jobDetailsProps={props?.jobDetailsProps}
        setApiRefresh={props?.setApiRefresh}
      />
    </Dialog>
  );
}

export default ReferAJobDialog;
