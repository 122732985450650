import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Loader from "../../../components/common/loader";
import { STATUS_SUCCESS } from "../../../config/localstorage_keys";
import {
  FORM_VALIDATION_MESSAGE,
  GENERAL_ERROR_MESSAGE,
} from "../../../config/messages";
import { EMAIL_PATTERN } from "../../../config/validation_patterns_constants";
import {
  disableShouldErrorShow,
  enableShouldErrorShow,
  onFormFeildsChange,
  validateField,
} from "../../../utils/form_validators";
import toaster from "../../../utils/toaster";
import { checkPhoneNumberValid } from "../../../utils/utils";
import {
  emailValidation,
  mobileValidation,
} from "../../../_services/candidate.service";
import { feedback } from "../../../_services/member-profile.service";
import "../support_and_feedback/styles.scss";

function SupportAndFeedback({ verificationNeeded }) {
  const [showLoader, setShowLoader] = useState(false);
  const [phonenumber, setPhoneNumber] = useState();
  const [isError, setIsError] = useState({ phonenumberError: "" });

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: {
      valueText: "",
      errorText: "",
      check: ["required"],
    },
    email: { valueText: "", errorText: "", check: ["required", EMAIL_PATTERN] },
    phonenumber: {
      valueText: "",
      errorText: "",
      check: ["required"],
    },
    text: {
      valueText: "",
      errorText: "",
      check: ["required"],
    },
  });
  const [apiRefresh, setApiRefresh] = useState(false);
  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      if (formData?.phonenumber?.errorText == "") {
        saveFeedback();
      }
    }
  };

  const checkMobileNumberValid = () => {
    let isValid = true;
    if (phonenumber.length > 10) {
      setIsError({ phonenumberError: "Enter validate mobile number" });

      //   if (formData?.mobileNumber?.valueText !== 10) {
      //     setFormError({
      //       mobileNumber: "Enter validate mobile number",
      //     });
      isValid = false;
    } else {
      setIsError({
        phonenumberError: "",
      });
      isValid = true;
    }
    //   setFormData(formData);
    return isValid;
  };
  // const clear=()=>{
  //   setShowLoader(true);
  //   setFormData({
  //     firstname: {
  //       valueText: "",
  //     },
  //     email: {
  //       valueText: "",
  //     },
  //     phonenumber: {
  //       valueText: "",
  //     },
  //     text: {
  //       valueText: "",
  //     },
  //   });
  //   setShowLoader(false);
  // }
  const saveFeedback = () => {
    

    // let isValid = true;

    // Object.keys(formData)?.forEach((key) => {
    //   if (!validateField(key, formData, setFormData)) {
    //     isValid = false;
    //   }
    // });
    if (phonenumber === "") {
      toaster("error", "Please Add Contact number");
    } else if (checkMobileNumberValid()) {
      // if (isValid) {
        const name = formData?.firstname?.valueText;
        const email = formData?.email?.valueText;
        const phone = formData?.phonenumber?.valueText;
        const text = formData?.text?.valueText;
        
        feedback(name, email, phone, text)
          .then((res) => {
            if (res?.data?.status === STATUS_SUCCESS) {
              toaster("success", res?.data?.message);
              setShowLoader(true);
            } else {
              toaster(
                "error",
                res?.data?.message ? res?.data?.message : GENERAL_ERROR_MESSAGE
              );
              
            }
            setShowLoader(false);
            setFormData({
              firstname: {
                valueText: "",
                errorText: "",
                check: ["required"],
              },
              email: { valueText: "", errorText: "", check: ["required", EMAIL_PATTERN] },
              phonenumber: {
                valueText: "",
                errorText: "",
                check: ["required"],
              },
              text: {
                valueText: "",
                errorText: "",
                check: ["required"],
              },
            });
            
          })
          .catch((err) => {
            toaster(
              "error",
              err?.data?.message ? err?.data?.message : GENERAL_ERROR_MESSAGE
            );
            setShowLoader(false);
          });
      }
    // }
  };


  // useEffect(() => {
  //   if (apiRefresh === true) {
  //     setApiRefresh(false);
  //   }
  // }, [apiRefresh]);
  return (
    <>
   
    <div className="container">
    {showLoader && <Loader />}
      <div className="feedback-form-width content-margin mt-4">
        <div className="fw-700 fs-24 mt-4">Support & Feedback</div>
        <div className="fw-400 small-text-gray mt-4">
          Reach out to us for any suggestions, business communications or
          anything else you have in mind.
        </div>
        <div className="mt-4">
          <label className="fw-600 fs-14 mb-2">Your Name*</label>
          {/* <input className="support-feedback-input p-2 w-100" /> */}
          <input
            autoFocus={true}
            value={formData?.firstname?.valueText}
            type="text"
            name="firstname"
            maxLength={32}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
            }}
            className={
              (formData?.firstname?.errorText ? "error" : "") +
              " form-control support-feedback-input support-feedback-input-border"
            }
          />
          <div className="field-error mt-1">
            {formData?.firstname?.errorText}
          </div>
        </div>
        <div className="mt-4">
          <label className="fw-600 fs-14 mb-2">Email*</label>
          {/* <input className="support-feedback-input p-2 w-100" /> */}
          <input
            type="email"
            name="email"
            // maxLength={50}
            value={formData?.email?.valueText}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
              if (formData?.email?.errorText == "") {
                emailValidation(formData, setFormData);
              }
            }}
            className={
              (formData?.email?.errorText ? "error" : "") +
              "  form-control support-feedback-input support-feedback-input-border"
            }
          />

          <div className="field-error mt-1">{formData?.email?.errorText}</div>
        </div>
        <div className="mt-4">
          <label className="fw-600 fs-14 mb-2">Contact Number*</label>
          {/* <input className="support-feedback-input p-2 w-100" /> */}
          <input
            value={formData?.phonenumber?.valueText}
            type="number"
            name="phonenumber"
            // onKeyDown={(e) => {
            //   submitFormOnEnter(e);
            // }}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
              setPhoneNumber($event.target.value);

              if (formData?.phonenumber?.errorText == "") {
                mobileValidation(formData, setFormData);
              }
            }}

            // onChange={($event) => {
            //   onFormFeildsChange($event, formData, setFormData);
            //   if (formData?.phonenumber?.valueText) {
            //     const isPhoneNumberValid = checkPhoneNumberValid(
            //       formData?.phonenumber?.valueText,
            //       $event?.target?.value
            //     );
            //     let copyFormData = { ...formData };

            //     if (!isPhoneNumberValid) {
            //       copyFormData.phonenumber.errorText =
            //         FORM_VALIDATION_MESSAGE.PHONE;
            //     } else {
            //       copyFormData.phonenumber.errorText = "";
            //     }
            //     setFormData(copyFormData);
            //   }
            //   if (formData?.phonenumber?.errorText == "") {
            //     mobileValidation(formData, setFormData);
            //   }
            // }}
            // className={
            //   (formData?.phonenumber?.errorText ? "error" : "") +
            //   " form-control support-feedback-input support-feedback-input-border"
            // }
            // onChange={($event) => {
            //   onFormFeildsChange($event, formData, setFormData);
            //   setPhoneNumber($event.target.value);
            //   // if (e.target.value.length > 10) {
            //   //   setIsError(true);
            //   // }
            // }}
            className={
              (formData?.phonenumber?.errorText ? "error" : "") +
              " form-control"
            }
          />
          <p className="field-error mt-1">{isError.phonenumberError}</p>
          {/* <div className="field-error mt-1">
            {formData?.phonenumber?.errorText}
          </div> */}
        </div>
        <div className="mt-4">
          <label className="fw-600 fs-14 mb-2">Your Message*</label>
          <textarea
            rows={5}
            // className="w-100 mt-1"
            value={formData?.text?.valueText}
            type="text"
            name="text"
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
            }}
            className={
              (formData?.text?.errorText ? "error" : "") +
              " form-control support-feedback-input-border"
            }
          />
          <div className="field-error mt-1">{formData?.text?.errorText}</div>{" "}
          {/* /> */}
        </div>
        <div className="mt-4 d-flex justify-content-end mb-5">
          <button
            className="btn btn-dark send-feedback-btn fw-700 fs-16"
            // onClick={() => {
            //   saveFeedback();
            // }}
            onClick={() => {
              if (formData?.phonenumber?.errorText == "") {
                saveFeedback();
                // setFormData({
                // firstname: {
                //   valueText: "",
                // },
            //  }); 
              }
              // clear();
            }}
          >
            Send
          </button>
        </div>
      </div>
    </div>
    </>
  );
}

export default SupportAndFeedback;
