import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.scss";
import LoginPage from "./pages/login_page";
import NotFoundPage from "./pages/not_found_page";
import reportWebVitals from "./reportWebVitals";
// import MemberProfile from "./components/routes";
import DashboardPage from "./pages/auth_protected/dashboard_page";
import ForgetPasswordLinkPage from "./pages/forget_password_link_page";
import ForgetPasswordPage from "./pages/forget_password_page";
// import JobPostPage from "./pages/auth_protected/job-post-page/jobs_post_main_page";
import {
  ACCOUNT_PAGE_ROUTE,
  APPLICATIONS_MAIN_PAGE_ROUTE,
  APPLICATION_PAGE_ROUTE,
  COMPANY_PROFILE_PAGE_ROUTE,
  INVITE_REFEREE_PAGE_ROUTE,
  JOB_CREATE_TEMPLATE_PAGE,
  JOB_POSTS_PAGE_ROUTE,
  JOB_POST_CREATE_PAGE_ROUTE,
  JOB_TEMPLATES,
  NAVIGATION_SIDEBAR_ROUTE,
  REFEREE_DETAILS_PAGE_ROUTE,
  TEAMS_MEMBERS_ROUTE,
  MEMBERS_DETAIL_PAGE_ROUTE,
  TEAMS_DETAIL_PAGE_ROUTE,
  DRAFT_JOB_POST_PAGE_ROUTE,
  REFEREE_ID_PATH_VARIABLE,
  REFEREE_SCREEN_ROUTE,
  SUPPORT_AND_FEEDBACK,
  TEAM_ID_PATH_VARIABLE,
  TEAM_PAGE_ROUTE,
  ALL_TEAMS_SCREEN_ROUTE,
  JOIN_VIA_INVITE_ROUTE,
  ALL_USERS_ROUTE,
  NEW_REQUEST_PAGE_ROUTE,
  REPORTS_PAGE_ROUTE,
  ADMIN_JOB_POST_PAGE_ROUTE,
  NOTIFICATION_TO_REFEREE_PAGE_ROUTE,
  NOTIFICATION_TO_CANDIDATE_PAGE_ROUTE,
  ALL_ORGS_SCREEN_ROUTE,
  ADMIN_REFEREE_SCREEN_ROUTE,
  INDIVIDUAL_PROFILE_PAGE,
  ORG_PAGE_ROUTE,
  ORG_ID_PATH_VARIABLE,
  SEIKOR_ADMINS_SCREEN,
  DATA_FIELDS_PAGE_ROUTE,
  APPROVED_DETAIL_PAGE_ROUTE,
  FOR_APPROVAL_DETAIL_PAGE_ROUTE,
  PREVIEW_PAGE_ROUTE,
  BUSINESS_PAGE_ROUTE,
  BUSINESS_ID_PATH_VARIABLE,
  BUSINESS_USER_PROFILE_ROUTE,
  NOTIFICATION_REFEREE_HASH_ROUTE,
  INDIVIDUAL_TEAM_ID,
  INDIVIDUAL_TEAM_INDEX_ID,
  INDIVIDUAL_MEMBER_INDEX_ID,
  JOB_DETAILS_PAGE_ROUTE,
  SCRATCH_PREVIEW_PAGE_ROUTE,
} from "./config/page_routes_constants";
import AccountPage from "./pages/auth_protected/account_page";
import Applications from "./pages/auth_protected/applications_main_page/applications";
import CompanyProfilePage from "./pages/auth_protected/company_profile_page/company_profile_page";
import JobPostApplicationPage from "./pages/auth_protected/job_application_view_page/job_post_applications_page";
import JobTemplatesPage from "./pages/auth_protected/job_templates/job_templates_page";
import IndividualTeamDetails from "./pages/individual_team_details";
import AuthGuard from "./_services/authguard/auth-guard";
// import CreateJobPostMainPage from "./pages/auth_protected/jobs/JobsModals/create-job-main-page";
import ChangePasswordPage from "./pages/auth_protected/change_password_page";
import InviteReferee from "./pages/auth_protected/invite_referee/index";
import CreateJobPostMainPage from "./pages/auth_protected/jobs_post_page/create_job_main_page";
import JobPostPage from "./pages/auth_protected/jobs_post_page/job_post_main_page";
import JobCreateTemplatePage from "./pages/auth_protected/job_template_page/job_create_template_page";
import NavigationSidebarModule from "./pages/auth_protected/navigation_sidebar_module";
import RefereeScreen from "./pages/auth_protected/referee_screen";
import ReferreeDetailsPage from "./pages/auth_protected/referee_screen/referee_details_page";
import TeamsMembersPage from "./pages/auth_protected/teams&members/teams_members_page";
import MemberDetailPage from "./pages/auth_protected/teams&members/member_detail_page";
import TeamDetailPage from "./pages/auth_protected/teams&members/teams_detail_page";
import DraftJobPostPage from "./pages/auth_protected/draft_job_posts/draft_job_posts_page";
import SupportAndFeedback from "./pages/auth_protected/support_and_feedback";
import AllTeamsScreen from "./pages/auth_protected/all_teams";
import { ALLTEAMS } from "./config/filter_constants";
import NotificationRefereesPage from "./pages/auth_protected/applications_main_page/action_pages/notification_referees";
import NotificationCandidatePage from "./pages/auth_protected/applications_main_page/action_pages/notification_candidate";
import JoinViaInvitePage from "./pages/join_via_invite_page";
import AllUsersPage from "./pages/auth_protected/all_users/all_users_page";
import NewRequestPage from "./pages/auth_protected/new_request_page/new_request_page";
import ReportsPage from "./pages/auth_protected/reports_page/reports_page";
import AdminAllJobsPage from "./pages/auth_protected/admin_all_jobs_page/admin_all_jobs_page";
import AdminAllOrgs from "./pages/auth_protected/admin_all_orgs";
import AdminAllReferees from "./pages/auth_protected/admin_all_referees";
import ProfilePage from "./pages/auth_protected/individual_profile_page/profile_page";
import AdminOrgDetails from "./pages/admin_org_details";
import SeikorAdminsScreen from "./pages/auth_protected/seikor_admins";
import DataFieldsPage from "./pages/auth_protected/data_fields_page/data_fields_page";
import ApprovedDetailPage from "./pages/auth_protected/data_fields_page/approved_detail_page";
import ForApprovalDetailPage from "./pages/auth_protected/data_fields_page/for_approval_detail_page";
import JobDetailsPage from "./pages/auth_protected/jobs_post_page/job_details_page";
import IndividualBusinessProfileDetails from "./pages/individual_business_profile_details";
import ScratchJobDetailsPage from "./pages/auth_protected/jobs_post_page/scratch_job_details_page";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="login" element={<LoginPage />} />
        <Route path={JOIN_VIA_INVITE_ROUTE} element={<JoinViaInvitePage />} />

        <Route path="forget-password" element={<ForgetPasswordPage />} />
        <Route path="change-password" element={<ChangePasswordPage />} />
        <Route
          path="forget-password-link/:tokenId"
          element={<ForgetPasswordLinkPage />}
        />
        <Route
          path={`${JOB_DETAILS_PAGE_ROUTE}/:id`}
          element={<JobDetailsPage />}
        />
        <Route element={<AuthGuard />}>
          <Route
            path={NAVIGATION_SIDEBAR_ROUTE}
            element={<NavigationSidebarModule />}
          >
            <Route path="" element={<DashboardPage />} />
            <Route path={JOB_POSTS_PAGE_ROUTE} element={<JobPostPage />} />
            <Route
              path={COMPANY_PROFILE_PAGE_ROUTE}
              element={<CompanyProfilePage />}
            />
            <Route path={ACCOUNT_PAGE_ROUTE} element={<AccountPage />} />
            <Route
              path={`${TEAM_PAGE_ROUTE}/:${TEAM_ID_PATH_VARIABLE}/:${INDIVIDUAL_TEAM_INDEX_ID}`}
              element={<IndividualTeamDetails />}
            />
            <Route
              path={`${BUSINESS_USER_PROFILE_ROUTE}/:${TEAM_ID_PATH_VARIABLE}/:${BUSINESS_ID_PATH_VARIABLE}/:${INDIVIDUAL_MEMBER_INDEX_ID}`}
              element={<IndividualBusinessProfileDetails />}
            />
            IndividualBusinessProfileDetails
            <Route path={JOB_TEMPLATES} element={<JobTemplatesPage />} />
            <Route
              path={SUPPORT_AND_FEEDBACK}
              element={<SupportAndFeedback />}
            />
            <Route
              path={INVITE_REFEREE_PAGE_ROUTE}
              element={<InviteReferee />}
            />
            <Route path={REFEREE_SCREEN_ROUTE} element={<RefereeScreen />} />
            <Route
              // path={`${REFEREE_DETAILS_PAGE_ROUTE}/:${REFEREE_ID_PATH_VARIABLE}`}
              path={`${REFEREE_DETAILS_PAGE_ROUTE}/:refererId`}
              element={<ReferreeDetailsPage />}
            />
            <Route
              path={ADMIN_REFEREE_SCREEN_ROUTE}
              element={<AdminAllReferees />}
            />
            <Route path={TEAMS_MEMBERS_ROUTE} element={<TeamsMembersPage />} />
            <Route
              path={`${MEMBERS_DETAIL_PAGE_ROUTE}/:id`}
              element={<MemberDetailPage />}
            />
            <Route
              path={`${TEAMS_DETAIL_PAGE_ROUTE}/:id`}
              element={<TeamDetailPage />}
            />
            <Route
              path={DRAFT_JOB_POST_PAGE_ROUTE}
              element={<DraftJobPostPage />}
            />
            <Route path={ALL_TEAMS_SCREEN_ROUTE} element={<AllTeamsScreen />} />
            <Route path={ALL_USERS_ROUTE} element={<AllUsersPage />} />
            <Route path={NEW_REQUEST_PAGE_ROUTE} element={<NewRequestPage />} />
            <Route path={REPORTS_PAGE_ROUTE} element={<ReportsPage />} />
            <Route
              path={ADMIN_JOB_POST_PAGE_ROUTE}
              element={<AdminAllJobsPage />}
            />
            <Route path={ALL_ORGS_SCREEN_ROUTE} element={<AdminAllOrgs />} />
            <Route
              path={`${ORG_PAGE_ROUTE}/:${ORG_ID_PATH_VARIABLE}`}
              element={<AdminOrgDetails />}
            />
            <Route
              path={SEIKOR_ADMINS_SCREEN}
              element={<SeikorAdminsScreen />}
            />
            <Route path={DATA_FIELDS_PAGE_ROUTE} element={<DataFieldsPage />} />
            <Route
              path={APPROVED_DETAIL_PAGE_ROUTE + "/:masterType"}
              element={<ApprovedDetailPage />}
            />
            <Route
              path={FOR_APPROVAL_DETAIL_PAGE_ROUTE + "/:masterType"}
              element={<ForApprovalDetailPage />}
            />
          </Route>
          <Route
            path={`${PREVIEW_PAGE_ROUTE}/:id`}
            element={<JobDetailsPage />}
          />
          <Route
            path={`${SCRATCH_PREVIEW_PAGE_ROUTE}`}
            element={<ScratchJobDetailsPage />}
          />
          <Route
            path={JOB_POST_CREATE_PAGE_ROUTE}
            element={<CreateJobPostMainPage />}
          />
          <Route
            path={JOB_POST_CREATE_PAGE_ROUTE + "/:jobId"}
            element={<CreateJobPostMainPage />}
          />

          <Route
            path={JOB_CREATE_TEMPLATE_PAGE}
            element={<JobCreateTemplatePage />}
          />
          <Route
            path={JOB_CREATE_TEMPLATE_PAGE + "/:id"}
            element={<JobCreateTemplatePage />}
          />
          <Route
            path={APPLICATION_PAGE_ROUTE + "/:id"}
            element={<JobPostApplicationPage />}
          />
          <Route
            path={APPLICATIONS_MAIN_PAGE_ROUTE + "/:jobId"}
            element={<Applications />}
          />
          <Route
            path={NOTIFICATION_TO_REFEREE_PAGE_ROUTE + "/:jobId"}
            element={<NotificationRefereesPage />}
          />
          {/* <Route
            path={NOTIFICATION_REFEREE_HASH_ROUTE +"/:jobId"}
            element={<NotificationRefereesPage />}
          /> */}
          <Route
            path={NOTIFICATION_TO_CANDIDATE_PAGE_ROUTE + "/:jobId"}
            element={<NotificationCandidatePage />}
          />

          <Route
            path={INDIVIDUAL_PROFILE_PAGE + "/:userId"}
            element={<ProfilePage />}
          />
        </Route>

        <Route
          path={APPLICATION_PAGE_ROUTE + "/:id"}
          element={<JobPostApplicationPage />}
        />
        <Route
          path={APPLICATIONS_MAIN_PAGE_ROUTE + "/:jobId"}
          element={<Applications />}
        />

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
