import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import "../../pages/auth_protected/jobs_post_page/job-post-style.scss";
import scratchStart from "../../assests/scratchStart.svg";
import RightArrow from "../../assests/Vector.svg";
import copyPrevious from "../../assests/copyPrevious.svg";
import orgTemplate from "../../assests/orgTemplate.svg";
import Vectorleft from "../../assests/Vectorleft.svg";

import { isTemplateExpression } from "typescript";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { JOB_POST_CREATE_PAGE_ROUTE } from "../../config/page_routes_constants";
import { getJobTemplate } from "../../_services/view.service";

const Card = ({ allTemplateDetails, action }) => {
  const navigate = useNavigate();
  const editTemp = (value, tempJobId) => {
    localStorage.setItem("EditKeys", value);
    navigate("/" + JOB_POST_CREATE_PAGE_ROUTE + "/" + tempJobId);
  };
  return (
    <>
      {allTemplateDetails && allTemplateDetails.length > 0
        ? allTemplateDetails.map((item, key) => {
            return (
              <div
                onClick={() => {
                  editTemp("tempJob", item?.templateId);
                }}
                className="card border-1 m-3 radius12 pointer"
              >
                <div className="card-body  position-relative d-flex align-items-center startScratch justify-content-between">
                  <div>
                    <p className="fs-16 col-8 m-0 p-0 pb-2">
                      {item.templateTitle}
                    </p>
                  </div>
                  <div className="">
                    <div
                      type="button"
                      style={{
                        height: "37px",
                        width: "37px",
                      }}
                      className="fs-12 btn bg-black text-white scratch-btn justify-content-center align-items-center"
                    >
                      <img
                        alt=""
                        style={{
                          height: "1rem",
                          width: "1rem",
                        }}
                        src={RightArrow}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </>
  );
};
const CreateFromTemplateModal = (props) => {
  const initialFilterData = {
    pageNo: 0,
    pageSize: 0,
  };
  const [allTemplateDetails, setAllTemplateDetails] = useState([]);
  const [allJobsPostFilterData, setAllJobsPostFilterData] =
    useState(initialFilterData);

  const getTemplateDetails = (data) => {
    getJobTemplate(data)?.then((res) => {
      setAllTemplateDetails(res?.data?.data);
    });
  };
  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      getTemplateDetails(allJobsPostFilterData);
    }
  };

  useEffect(() => {
    getTemplateDetails(initialFilterData);
  }, []);

  return (
    <div className="radius19">
      <Modal
        show={props.show}
        onHide={() => props.close()}
        backdrop="static"
        fullscreen="lg-down"
        centered
        keyboard={false}
        dialogClassName="modal-fixed-height"
        scrollable={true}
      >
        <Modal.Header className="dialog-header border-0 m-0 pb-0 pt-1 ms-3 me-2">
          <div className="mt-4 d-flex gap-4 align-items-center">
            <img
              src={Vectorleft}
              alt=""
              className="pointer"
              height="16px"
              onClick={() => {
                props.close();
                props.setShowCreateJobPostModal(true);
              }}
            ></img>
            <div className="fs-16 color-primary fw-600 px-3">
              Create from a template
            </div>
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            height="25px"
            width="25px"
            onClick={() => props.close()}
            className="pointer m-0"
          />
        </Modal.Header>
        <p className="m-0 py-0 mx-3 my-3">
          <hr className="m-0 py-0 mx-3" />
        </p>

        <Modal.Body className="dialog-body pt-0">
          <p
            className="mx-3 p-0 fs-14 mt-0"
            style={{ marginTop: "-2rem !important" }}
          >
            Select a template:
          </p>
          <div className="page-search-custom mx-3 h-54">
            <input
              className="col-12 h-54"
              type="text"
              placeholder="Search by job title"
              onChange={(event) => {
                setAllJobsPostFilterData({
                  ...allJobsPostFilterData,
                  searchString: event.target.value,
                  pageNo: 0,
                  pageSize: 0,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
            />
          </div>
          <Card allTemplateDetails={allTemplateDetails} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateFromTemplateModal;
