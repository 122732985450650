import React, { useEffect, useState } from "react";
import "./job-post-style.scss";
import Vectorleft from "../../../assests/Vectorleft.svg";
import closeRoundedRed from "../../../assests/closeroundedred.svg";
import logoAirbnb from "../../../assests/images/airbnblogo.png";
import checkedLogo from "../../../assests/ic_selected16.svg";
import minusRounded from "../../../assests/icons/ic_minus.svg";
import plusRounded from "../../../assests/icons/ic_plus.svg";
import icPlusIcon from "../../../assests/icons/ic-plus-icon.svg";
import icDelete from "../../../assests/icons/ic_delete.svg";
import Vectorplusroundedgray from "../../../assests/Vectorplusroundedgray.svg";
import Vectorcompletionflag from "../../../assests/Vectorcompletionflag.svg";
import downDottedArrow from "../../../assests/downdottedaarrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchComboBox from "../../../components/SearchComboBox";
import {
  DRAFT_JOB_POST_PAGE_ROUTE,
  JOB_POSTS_PAGE_ROUTE,
  PREVIEW_PAGE_ROUTE,
  SCRATCH_PREVIEW_PAGE_ROUTE,
} from "../../../config/page_routes_constants";
import { isEmpty, onFormFeildsChange } from "../../../utils/form_validators";
import {
  getAutoSave,
  getCurrency,
  getLocations,
  getMaster,
  postAutoSave,
  postNewJob,
  viewPreviousJob,
  viewPreviousJobTemp,
} from "../../../_services/view.service";
import { MASTER_TYPE } from "../../../config/be_api_constants";
import { useStoreActions, useStoreState } from "easy-peasy";
import toaster from "../../../utils/toaster";
import { getLocalStorage } from "../../../utils/storage";
import {
  ORG_ID,
  ORG_LOGO,
  STATUS_SUCCESS,
  USER_ID,
} from "../../../config/localstorage_keys";
import Loader from "../../../components/common/loader";
import { getCompanyDetails } from "../../../_services/company.service";

const CreateJobPostMainPage = (props) => {
  const URL = window.location.href.split("/");
  const isShowPrevBtn = URL[URL.length - 1] === "scratch";
  const isShowSratchPreviewBtn = useStoreState(
    (state) => state?.isShowSratchPreviewBtn
  );
  const setIsShowScratchPreviewBtn = useStoreActions(
    (action) => action?.setIsShowScratchPreviewBtn
  );

  const notAppliedCompanyDetails = useStoreState(
    (state) => state?.notAppliedCompanyDetails
  );
  const setNotAppliedCompanyDetails = useStoreActions(
    (action) => action?.setNotAppliedCompanyDetails
  );
  const isPreviewed = useStoreState((state) => state?.isPreviewed);
  const setIsPreviewed = useStoreActions((action) => action?.setIsPreviewed);

  const [isPreviewBtnDisable, setIsPreviewBtnDisable] = useState(false);
  let { jobId } = useParams();
  const navigate = useNavigate();
  const Character_Limit = 250;
  const Character = 450;
  const [total, setTotal] = useState("");
  const [locationvalue, setLocationValue] = useState([]);
  const [referralAmount, setReferralAmount] = useState();
  const [referralAmount1, setReferralAmount1] = useState();
  const [referralBouns, setReferralBouns] = useState();
  const [reportingrole, SetReportingRole] = useState();
  const [actionValue, setActionValue] = useState();
  const current = new Date();
  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  const currentDate = `${dd}-${mm}-${yyyy}`;
  const [formData, setFormData] = useState({
    jobTitle: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    departmentName: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    locationName: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    reportingType: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    jobType: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    monthOfContract: {
      valueText: "01",
      initial: "",
      errorText: "",
      check: [""],
    },
    numPosition: {
      valueText: "1",
      initial: "1",
      errorText: "",
      check: [""],
    },
    reportingRole: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    minExpRequiredMonths: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    maxExpRequiredMonths: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    isRange: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    salCurrency: {
      valueText: "",
      initial: "",
      errorText: "",
      check: [""],
    },
    minSalary: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    maxSalary: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    salaryType: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    referralBonusType: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    referralBonus: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    screeningProcess: {
      valueText: [],
      initial: "",
      errorText: "",
      check: ["required"],
    },
    skillsRequired: {
      valueText: [],
      initial: "",
      errorText: "",
      check: ["required"],
    },
    jobAdvantage: {
      valueText: [],
      initial: "",
      errorText: "",
      check: ["required"],
    },
    jobEveryDayTask: {
      valueText: [],
      initial: "",
      errorText: "",
      check: ["required"],
    },
    jobPower: {
      valueText: [],
      initial: "",
      errorText: "",
      check: ["required"],
    },
    jobCareerPath: {
      valueText: [],
      initial: "",
      errorText: "",
      check: ["required"],
    },
    additionalRemarks: {
      valueText: "",
      initial: "",
      errorText: "",
      check: [""],
    },
    reportingStructure: {
      valueText: [],
      initial: [],
      errorText: "",
      check: [],
    },
    referralCriteriaCompany: {
      valueText: [],
      initial: "",
      errorText: "",
      check: [""],
    },
    referralCriteriaIndustry: {
      valueText: [],
      initial: "",
      errorText: "",
      check: [""],
    },
    referralCriteriaOther: {
      valueText: [],
      initial: "",
      errorText: "",
      check: [""],
    },
    questionText: {
      valueText: [],
      initial: "",
      errorText: "",
      check: [""],
    },
    questions: {
      valueText: [],
      initial: "",
      errorText: "",
      check: [""],
    },
  });
  const [jobDepartmentOutput, setJobDepartmentOutput] = useState([]);
  const [jobDepartmentOutputStatic, setJobDepartmentOutputStatic] = useState(
    []
  );
  const [jobDepartment, setJobDepartment] = useState([]);
  const [jobLocationOutput, setJobLocationOutput] = useState([]);
  const [jobLocationOutputStatic, setJobLocationOutputStatic] = useState([]);
  const [maxExperience, setMaxExperience] = useState();
  const [minExperience, setMinExperience] = useState();
  const [bonusCal, setBonusCal] = useState("0");
  const [calMinSalPer, setCalMinSalPer] = useState("");
  const [salCurrency, setsalCurrency] = useState([]);
  const [salCurrencyPop, setsalCurrencyPop] = useState("");
  const [salaryId, setSalaryId] = useState("Indian Rupee");
  const [autoSave, setAutoSave] = useState(true);
  const [salary_Type, setSalary_Type] = useState("ANNUAL");
  const [value, setValue] = useState();
  const [inputs, setInputs] = useState();
  const [input, setInput] = useState();
  const [structureArray, setStructureArray] = useState([]);
  const [Maxsalary_Error, setMaxSalary_Error] = useState("");
  const [Minsalary_Error, setMinSalary_Error] = useState("");
  const [contract_Error, setcontract_Error] = useState("");
  const [reportStructure1, setreportStructure1] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [keySkill, setKeySkill] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [jobrole, setJobRole] = useState([]);
  const [jobReportingType, setJobReportingType] = useState([]);
  const [company, setCompany] = useState([]);
  const [locations, setLocation] = useState();
  const [jobtype, setJobtype] = useState([]);
  const [showContractField, setShowContractField] = useState(false);
  const [numberOfRolesInput, setNumberOfRolesInput] = useState(1);
  const [viewPreviousJobs, setViewPreviousJobs] = useState([]);
  const [screeningProcessFields, setScreeningProcessFields] = useState([""]);
  const [jobAdvantageFields, setjobAdvantageFields] = useState([]);
  const [jobEveryDayTaskFields, setJobEveryDayTaskFields] = useState([""]);
  const [jobQuestionsFields, setJobQuestionsFields] = useState([]);
  const [referralCriteriaOtherFields, setreferralCriteriaOtherFields] =
    useState([""]);
  const [jobCareerpathFields, setJobCareerPathFields] = useState([""]);
  const [salarycheck, setSalaryCheck] = useState();
  const [maxSalarychange, setMaxSalaryChange] = useState();
  const [check, setCheck] = useState();
  const [isminSalaryChecked, setIsminSalaryChecked] = useState(false);
  const [isFixedAmtChecked, setIsFixedAmtChecked] = useState(false);
  const [isRangeChecked, setIsRangeChecked] = useState(true);
  const [RangeValue, setRangeValue] = useState();
  const [referralBonusType, setReferralBonusType] = useState("p");
  const [JobCareerPath, setJobCareerPath] = useState([]);
  const [jobEveryDay, setjobEveryDay] = useState([]);
  const [jobFields, setjobFields] = useState([]);
  const [refCriteriaOther, setrefCriteriaOther] = useState([]);
  const [jobSkills, setJobSkills] = useState([]);
  const [refCriteriaIndustry, setRefCriteriaIndustry] = useState([]);
  const [refCriteriaCompany, setRefCriteriaCompany] = useState([]);
  const [reportingStructures, setReportingStructures] = useState([]);
  const [refBonusType, setRefBonusType] = useState("");
  const [repotvalue, setReportvalue] = useState();
  const [check_autoSave, setcheck_autoSave] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [jobPowers, setjobPowers] = useState([]);
  const [jobAdvantage, setjobAdvantage] = useState([]);
  const [isMinimumExpChecked, setIsMinimumExpChecked] = useState(false);
  const [company_Id, setCompany_Id] = useState("");
  const [job_Id, setJob_Id] = useState("");
  const [business_Id, setBusiness_Id] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [businessUser_Id, setBusinessUser_Id] = useState();
  const [salaryTypeOptions, setSalaryTypeOptions] = useState([]);
  const [screeningProcess, setScreeningProcess] = useState([]);
  const userId = getLocalStorage(USER_ID);
  const orgLogo = getLocalStorage(ORG_LOGO);
  const orgId = getLocalStorage(ORG_ID);
  let initialJobQuestionsFieldsState = {
    action: "ADD",
    isMandatory: "N",
    quesType: "F_T",
    questionId: jobQuestionsFields?.length,
    questionText: "",
  };
  const onInputChange = (name, value) => {
    setValue(formData?.referralBonus?.valueText);
    let event = { target: { value: "", name: "" } };
    event.target.value = value;
    event.target.name = name;
    if (name === "minExpRequiredMonths") {
      if (!value) {
        setMinExperience("");
      } else {
        let number = Number(value);
        if (number < maxExperience) {
          setMaxExperience(number);
          setMinExperience(number);
        } else {
          setMinExperience(number);
        }
      }
    }

    if (name === "maxExpRequiredMonths") {
      if (!value) {
        setMaxExperience("");
      } else {
        let number = Number(value);
        if (number < minExperience) {
          setMinExperience(number);
          setMaxExperience(number);
        } else {
          setMaxExperience(number);
        }
      }
    }

    if (name === "departmentName") {
      setJobDepartmentOutputStatic(event.target.value);
    }
    if (name === "locationName") {
      setJobLocationOutputStatic(event.target.value);
    }

    if (name === "reportingStructure") {
      setInputs(event.target.value);
    }
    if (name === "reportingRole") {
      setInput(event.target.value);
    }

    if (name === "reportingStructure") {
      setreportStructure1([event.target.value]);
    }
    if (name === "reportingRole") {
      // setreportStructure2([reportStructure1, event.target.value])
      SetReportingRole(event.target.value);
      // setReportingStructures(reportStructure1,  event.target.value);
    }

    if (name === "salCurrency") {
      setSalaryId(event.target.value);
    }

    if (name === "salaryType") {
      setSalary_Type(event.target.value);
    }

    if (name === "jobPower") {
      setjobPowers([event.target.value]);
    }
    const structureArray1 = [inputs, input];
    setStructureArray(structureArray1);
    if (name === "minSalary") {
      setCalMinSalPer(event.target.value);
    }
    if (name === "referralBonus") {
      setBonusCal(event.target.value);
      setReferralAmount(event.target.value);
      // setReferralBouns(event.target.value);
    }
    // if (name === "referralBonus") {
    //   setReferralAmount(event.target.value);
    // }
    //   else{
    //     setReferralBouns(referralAmount);
    //       //  alert("esle")
    // }

    if (name === "maxSalary") {
      if (formData?.maxSalary?.valueText < formData?.minSalary?.valueText) {
        setMaxSalary_Error("Max Salary is greater than Min Salary");
      } else {
        setMaxSalary_Error("");
      }
    }
    if (name === "minSalary") {
      // if (formData?.minSalary?.valueText < formData?.maxSalary?.valueText) {
      //   setMinSalary_Error("Min Salary is less than Max Salary");
      // } else {
      //   setMinSalary_Error("");
      // }
    }
    onFormFeildsChange(event, formData, setFormData);
  };

  // const onInputReportingStructure = (name, value) => {
  //   if (name === "reportingStructure") {
  //     setreportStructure1(value);
  //   }
  //   if (name === "reportingStructure1") {
  //     setReportingStructures(reportStructure1, value);
  //   }
  //   // if (name === "reportingStructure") {
  //   //   setInputs(event.target.value);
  //   // }
  //   // if (name === "reportingStructure1") {
  //   //   setInput(event.target.value);
  //   // }
  // };

  const getPercentage = (calMinSalPer, bonusCal) => {};

  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const fetchDetails = () => {
    setShowLoader(true);
    getCompanyDetails()
      .then((res) => {
        if (res?.data?.status == STATUS_SUCCESS) {
          const userId = getLocalStorage(USER_ID);
          if (userId) {
            saveCandidateDetails(userId);
          }
          let companyData = res?.data?.data[0];
          setNotAppliedCompanyDetails({
            ...notAppliedCompanyDetails,
            companyData,
          });
          setCompanyData(companyData);
          setCompany_Id(res?.data?.data[0]?.companyId);
          setBusiness_Id(res?.data?.data[0]?.companyId);
          setShowLoader(false);
        } else {
          toaster("error", "Company data is not available");
        }
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };
  useEffect(() => {
    if (isShowPrevBtn) {
      setIsShowScratchPreviewBtn(true);
    } else {
      setIsShowScratchPreviewBtn(false);
    }
    fetchDetails();
  }, []);

  if (numberOfRolesInput <= 0) {
    setNumberOfRolesInput(1);
  }
  const getAllMasterData = async () => {
    const reportingTypes = await getMaster(MASTER_TYPE.WORKMODE);
    let reportingType = reportingTypes.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setJobReportingType(reportingType);

    const companies = await getMaster(MASTER_TYPE.COMPANY);
    let company = companies.map((el) => {
      return { name: el.masterValue, description: el.Code };
    });
    setCompany(company);
    const functions = await getMaster(MASTER_TYPE.FUNCTIONS);
    let funct = functions.map((el) => {
      return { name: el.masterValue, description: el.Code };
    });
    setFunctions(funct);

    const jobRole = await getMaster(MASTER_TYPE.JOBROLE);
    let Role = jobRole.map((el) => {
      return { name: el.masterValue, description: el.Code };
    });
    setJobRole(Role);

    const jobTypes = await getMaster(MASTER_TYPE.JOBTYPES);
    let jobType = jobTypes.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setJobtype(jobType);

    const skills = await getMaster(MASTER_TYPE.KEYSKILLS);
    const industries = await getMaster(MASTER_TYPE.INDUSTRIES);
    const salaryTypes = await getMaster(MASTER_TYPE.SALARYTYPE);
    // const location = await getMaster(MASTER_TYPE.LOCATION);

    if (salaryTypes && salaryTypes.length > 0) {
      const salaryTypeValue = salaryTypes?.map(
        (item) => item.masterValue && { name: item.masterCode }
      );
      if (salaryTypeValue && salaryTypeValue.length > 0) {
        setSalaryTypeOptions(salaryTypeValue);
      }
    }
    if (skills && skills.length > 0) {
      const skillValues = skills?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (skillValues && skillValues.length > 0) {
        setKeySkill(skillValues);
      }
    }
    if (industries && industries.length > 0) {
      const industryValues = industries?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (industryValues && industryValues.length > 0) {
        setIndustries(industryValues);
      }
    }
    if (functions && functions.length > 0) {
      const functionValues = functions?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (functionValues && functionValues.length > 0) {
        setFunctions(functionValues);
        setJobDepartment(functionValues);
      }
    }

    if (jobrole && jobrole.length > 0) {
      const RoleValue = jobrole?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (RoleValue && RoleValue.length > 0) {
        setJobRole(RoleValue);
        // setJobDepartment(functionValues);
      }
    }

    // let loc = location.map((el) => {
    //   return { name: el.masterValue, description: el.Code };
    // });
    // setLocation(loc);

    getLocations().then((res) => {
      setLocation(res?.data);
      return res;
    });
    getCurrency().then((res) => {
      setsalCurrency(res);
      return res;
    });
  };

  const getAutoSaveJobPosts = () => {
    getAutoSave().then((res) => {
      setcheck_autoSave(res?.data?.data?.autoSave);
    });
  };

  const PreviousJobs = () => {
    (localStorage.getItem("EditKeys") === "tempJob"
      ? viewPreviousJobTemp(jobId)
      : check_autoSave === true
      ? getAutoSave()
      : viewPreviousJob(jobId)
    )?.then((res) => {
      if (res?.data.status === STATUS_SUCCESS) {
        toaster("success", "Data fetch successfully!");
        if (!isPreviewed) {
          setReferralAmount(res?.data?.data?.referralAmount);
          setReferralBouns(res?.data?.data?.referralBonus);
          setjobPowers(res?.data?.data?.jobPower);
          setJobDepartmentOutputStatic(res?.data?.data?.departmentName);
          setJobLocationOutputStatic(res?.data?.data?.locationName);

          const populatedFormData = { ...formData };
          populatedFormData.jobPower.valueText = res?.data?.data.jobPower;
          populatedFormData.referralBonusType.valueText =
            res?.data?.data.referralBonusType;
          if (!isEmpty(res?.data?.data?.jobTitle)) {
            populatedFormData.jobTitle.valueText = [res?.data?.data?.jobTitle];
          }
          if (!isEmpty(res?.data?.data?.departmentName)) {
            populatedFormData.departmentName.valueText = [
              res?.data?.data?.departmentName,
            ];
          }
          if (!isEmpty(res?.data?.data?.locationName)) {
            populatedFormData.locationName.valueText = [
              res?.data?.data?.locationName,
            ];
          }
          if (!isEmpty(res?.data?.data?.reportingStructure)) {
            populatedFormData.reportingStructure.valueText =
              res?.data?.data?.reportingStructure;
          }
          if (!isEmpty(res?.data?.data?.reportingRole)) {
            populatedFormData.reportingRole.valueText =
              res?.data?.data?.reportingRole;
          }
          if (!isEmpty(res?.data?.data?.skillsRequired)) {
            populatedFormData.skillsRequired.valueText =
              res?.data?.data?.skillsRequired;
          }
          if (!isEmpty(res?.data?.data?.isRange)) {
            populatedFormData.isRange.valueText = res?.data?.data?.isRange;
          }
          if (!isEmpty(res?.data?.data?.monthOfContract)) {
            populatedFormData.monthOfContract.valueText =
              res?.data?.data?.monthOfContract;
          }
          if (!isEmpty(res?.data?.data?.referralBonus)) {
            populatedFormData.referralBonus.valueText =
              res?.data?.data?.referralBonus;
          }
          if (!isEmpty(res?.data?.data?.additionalRemarks)) {
            populatedFormData.additionalRemarks.valueText =
              res?.data?.data?.additionalRemarks;
          }
          if (!isEmpty(res?.data?.data?.jobType)) {
            populatedFormData.jobType.valueText = res?.data?.data?.jobType;
          }
          if (!isEmpty(res?.data?.data?.reportingType)) {
            populatedFormData.reportingType.valueText =
              res?.data?.data?.reportingType;
          }
          if (!isEmpty(res?.data?.data?.numPosition)) {
            populatedFormData.numPosition.valueText =
              res?.data?.data?.numPosition;
          }
          if (!isEmpty(res?.data?.data?.minSalary)) {
            populatedFormData.minSalary.valueText = res?.data?.data?.minSalary;
          }
          if (!isEmpty(res?.data?.data?.maxSalary)) {
            populatedFormData.maxSalary.valueText = res?.data?.data?.maxSalary;
          }
          if (!isEmpty(res?.data?.data?.minExpRequiredMonths)) {
            populatedFormData.minExpRequiredMonths.valueText =
              res?.data?.data?.minExpRequiredMonths;
          }
          if (!isEmpty(res?.data?.data?.maxExpRequiredMonths)) {
            populatedFormData.maxExpRequiredMonths.valueText =
              res?.data?.data?.maxExpRequiredMonths;
          }
          if (!isEmpty(res?.data?.data?.jobAdvantage)) {
            populatedFormData.jobAdvantage.valueText = [
              res?.data?.data.jobAdvantage,
            ];
          }
          if (!isEmpty(res?.data?.data?.jobEveryDayTask)) {
            populatedFormData.jobEveryDayTask.valueText = [
              res?.data?.data.jobEveryDayTask,
            ];
          }
          if (!isEmpty(res?.data?.data?.referralCriteriaIndustry)) {
            populatedFormData.referralCriteriaIndustry.valueText =
              res?.data?.data.referralCriteriaIndustry;
          }
          if (!isEmpty(res?.data?.data?.referralCriteriaOther)) {
            populatedFormData.referralCriteriaOther.valueText =
              res?.data?.data.referralCriteriaOther;
          }
          if (!isEmpty(res?.data?.data?.referralCriteriaCompany)) {
            populatedFormData.referralCriteriaCompany.valueText =
              res?.data?.data.referralCriteriaCompany;
          }
          setFormData(populatedFormData);
          setViewPreviousJobs(res?.data.data);
          setsalCurrencyPop(res?.data?.data?.salCurrency);
          setIsRangeChecked(res?.data?.data?.isRange);
          setRangeValue(res?.data?.data?.isRange);
          setReferralBonusType(res?.data?.data?.referralBonusType);
          setJobCareerPath(res?.data?.data.jobCareerPath);
          setjobFields(res?.data?.data?.jobAdvantage);
          if (isEmpty(res?.data?.data?.questions)) {
            setJobQuestionsFields([initialJobQuestionsFieldsState]);
          } else {
            setJobQuestionsFields(res?.data?.data?.questions);
          }
          setreferralCriteriaOtherFields(
            res?.data?.data?.referralCriteriaOther
          );
          setrefCriteriaOther(res?.data?.data?.referralCriteriaOther);
          setScreeningProcessFields(res?.data?.data?.screeningProcess);
          setJobCareerPathFields(res?.data?.data?.jobCareerPath);
          setJobEveryDayTaskFields(res?.data?.data?.jobEveryDayTask);
          setScreeningProcess(res?.data?.data?.screeningProcess);
          setjobAdvantageFields(res?.data?.data?.jobAdvantage);
          setjobAdvantage(res?.data?.data?.jobAdvantage);
          setSalary_Type(res?.data?.data?.salaryType);
          setjobEveryDay(res?.data?.data?.jobEveryDayTask);
          setJobSkills(res?.data?.data?.skillsRequired);
          setjobPowers(res?.data?.data?.jobPower);
          setMaxExperience(res?.data?.data?.maxExpRequiredMonths);
          setMinExperience(res?.data?.data?.minExpRequiredMonths);
          setreportStructure1(res?.data?.data?.reportingStructure);
          setNumberOfRolesInput(res?.data?.data?.numPosition);
          setRefCriteriaIndustry(res?.data?.data?.referralCriteriaIndustry);
          setRefCriteriaCompany(res?.data?.data?.referralCriteriaCompany);
          // setReferralAmount(res?.data?.data?.referralAmount);
          setReferralBouns(res?.data?.data?.referralBonus);
          setActionValue(res?.data?.data?.action);
        } else {
          const populatedFormData = { ...formData };

          // job title
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.jobTitle)) {
            populatedFormData.jobTitle.valueText = [
              notAppliedCompanyDetails?.jobData?.jobTitle,
            ];
          }
          //  job department
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.departmentName)) {
            populatedFormData.departmentName.valueText = [
              notAppliedCompanyDetails?.jobData?.departmentName,
            ];
            setJobDepartmentOutputStatic([
              notAppliedCompanyDetails?.jobData?.departmentName,
            ]);
          }
          //  job location
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.locationId)) {
            populatedFormData.locationName.valueText = [
              notAppliedCompanyDetails?.jobData?.locationId,
            ];
            setJobLocationOutputStatic([
              notAppliedCompanyDetails?.jobData?.locationId,
            ]);
          }
          // job reporting
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.reportingType)) {
            populatedFormData.reportingType.valueText =
              notAppliedCompanyDetails?.jobData?.reportingType;
          }

          // job type
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.jobType)) {
            populatedFormData.jobType.valueText =
              notAppliedCompanyDetails?.jobData?.jobType;
          }

          // number of roles
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.numPosition)) {
            populatedFormData.numPosition.valueText =
              notAppliedCompanyDetails?.jobData?.numPosition;
            setNumberOfRolesInput(
              notAppliedCompanyDetails?.jobData?.numPosition
            );
          }
          // Experience range or minimum experienc
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.isRange)) {
            populatedFormData.isRange.valueText =
              notAppliedCompanyDetails?.jobData?.isRange;
            setIsRangeChecked(notAppliedCompanyDetails?.jobData?.isRange);
            setRangeValue(notAppliedCompanyDetails?.jobData?.isRange);
          }
          // input value min and maxexperience
          if (
            !isEmpty(notAppliedCompanyDetails?.jobData?.minExpRequiredMonths)
          ) {
            populatedFormData.minExpRequiredMonths.valueText =
              notAppliedCompanyDetails?.jobData?.minExpRequiredMonths;
            setMinExperience(
              notAppliedCompanyDetails?.jobData?.minExpRequiredMonths
            );
          }
          if (
            !isEmpty(notAppliedCompanyDetails?.jobData?.maxExpRequiredMonths)
          ) {
            populatedFormData.maxExpRequiredMonths.valueText =
              notAppliedCompanyDetails?.jobData?.maxExpRequiredMonths;
            setMaxExperience(
              notAppliedCompanyDetails?.jobData?.maxExpRequiredMonths
            );
          }

          // salary currency
          // if (!isEmpty(notAppliedCompanyDetails?.jobData?.salCurrencyId)) {
          //   getCurrency().then((res) => {
          //     res?.map((output) => {
          //       if (
          //         notAppliedCompanyDetails?.jobData.salCurrencyId ===
          //         output?.name
          //       ) {
          //         setsalCurrencyPop(output?.code);
          //       }
          //     });
          //   });
          // }
          //salary annual/monthly/ week
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.salaryType)) {
            populatedFormData.salaryType.valueText =
              notAppliedCompanyDetails?.jobData?.salaryType;
            setSalary_Type(notAppliedCompanyDetails?.jobData.salaryType);
          }
          //  salary minimum sal
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.minSalary)) {
            populatedFormData.minSalary.valueText =
              notAppliedCompanyDetails?.jobData?.minSalary;
          }
          // salary max sal
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.maxSalary)) {
            populatedFormData.maxSalary.valueText =
              notAppliedCompanyDetails?.jobData?.maxSalary;
          }

          //referral bonus min sal / fixed amt

          if (!isEmpty(notAppliedCompanyDetails?.jobData?.referralBonusType)) {
            populatedFormData.referralBonusType.valueText =
              notAppliedCompanyDetails?.jobData?.referralBonusType;
            setReferralBonusType(
              notAppliedCompanyDetails?.jobData?.referralBonusType
            );
            setCalMinSalPer(
              notAppliedCompanyDetails?.jobData?.referralBonusType
            );
            setSalaryCheck(
              notAppliedCompanyDetails?.jobData?.referralBonusType
            );
            setRefBonusType(
              notAppliedCompanyDetails?.jobData?.referralBonusType
            );
          }

          // referra bonus value
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.referralBonus)) {
            populatedFormData.referralBonus.valueText =
              notAppliedCompanyDetails?.jobData?.referralBonus;
            setReferralAmount(notAppliedCompanyDetails?.jobData?.referralBonus);
            setReferralBouns(notAppliedCompanyDetails?.jobData?.referralBonus);
          }

          //screening process steps
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.screeningProcess)) {
            populatedFormData.screeningProcess.valueText = [
              notAppliedCompanyDetails?.jobData?.screeningProcess,
            ];
            setScreeningProcessFields(
              notAppliedCompanyDetails?.jobData?.screeningProcess
            );
            setScreeningProcess(
              notAppliedCompanyDetails?.jobData?.screeningProcess
            );
          }

          // skills
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.skillsRequired)) {
            populatedFormData.skillsRequired.valueText =
              notAppliedCompanyDetails?.jobData?.skillsRequired;
            setJobSkills(notAppliedCompanyDetails?.jobData?.skillsRequired);
          }

          // super power
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.jobPower)) {
            populatedFormData.jobPower.valueText =
              notAppliedCompanyDetails?.jobData.jobPower;
            setjobPowers(notAppliedCompanyDetails?.jobData.jobPower);
          }

          // Why you should look at this role
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.jobAdvantage)) {
            populatedFormData.jobAdvantage.valueText = [
              notAppliedCompanyDetails?.jobData.jobAdvantage,
            ];
            setjobFields(notAppliedCompanyDetails?.jobData.jobAdvantage);
            setjobAdvantageFields(
              notAppliedCompanyDetails?.jobData.jobAdvantage
            );
            setjobAdvantage(notAppliedCompanyDetails?.jobData.jobAdvantage);
          }

          // What you will do everyday
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.jobEveryDayTask)) {
            populatedFormData.jobEveryDayTask.valueText = [
              notAppliedCompanyDetails?.jobData.jobEveryDayTask,
            ];
            setJobEveryDayTaskFields(
              notAppliedCompanyDetails?.jobData.jobEveryDayTask
            );
            setjobEveryDay(notAppliedCompanyDetails?.jobData.jobEveryDayTask);
          }

          // Career Path
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.jobCareerPath)) {
            populatedFormData.jobCareerPath.valueText =
              notAppliedCompanyDetails?.jobData?.jobCareerPath;
            setJobCareerPath(notAppliedCompanyDetails?.jobData?.jobCareerPath);
            setJobCareerPathFields(
              notAppliedCompanyDetails?.jobData?.jobCareerPath
            );
          }

          // Reporting Structure
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.reportingStructure)) {
            populatedFormData.reportingStructure.valueText =
              notAppliedCompanyDetails?.jobData?.reportingStructure;

            setreportStructure1(
              notAppliedCompanyDetails?.jobData?.reportingStructure
            );
          }

          // Reporting Role
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.reportingRole)) {
            populatedFormData.reportingRole.valueText =
              notAppliedCompanyDetails?.jobData?.reportingRole;
          }
          // What Else
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.additionalRemarks)) {
            populatedFormData.additionalRemarks.valueText =
              notAppliedCompanyDetails?.jobData?.additionalRemarks;
          }

          // Additional Questions to Candidates
          if (!isEmpty(notAppliedCompanyDetails?.jobData?.questions)) {
            populatedFormData.questions.valueText =
              notAppliedCompanyDetails?.jobData?.questions;
            setJobQuestionsFields(notAppliedCompanyDetails?.jobData?.questions);
          }

          // Company
          if (
            !isEmpty(notAppliedCompanyDetails?.jobData?.referralCriteriaCompany)
          ) {
            populatedFormData.referralCriteriaCompany.valueText =
              notAppliedCompanyDetails?.jobData?.referralCriteriaCompany;
            setRefCriteriaCompany(
              notAppliedCompanyDetails?.jobData?.referralCriteriaCompany
            );
          }

          // Industry
          if (
            !isEmpty(
              notAppliedCompanyDetails?.jobData?.referralCriteriaIndustry
            )
          ) {
            populatedFormData.referralCriteriaIndustry.valueText =
              notAppliedCompanyDetails?.jobData.referralCriteriaIndustry;
            setRefCriteriaIndustry(
              notAppliedCompanyDetails?.jobData.referralCriteriaIndustry
            );
          }

          // Other
          if (
            !isEmpty(notAppliedCompanyDetails?.jobData?.referralCriteriaOther)
          ) {
            populatedFormData.referralCriteriaOther.valueText =
              notAppliedCompanyDetails?.jobData.referralCriteriaOther;
            setreferralCriteriaOtherFields(
              notAppliedCompanyDetails?.jobData.referralCriteriaOther
            );
            setrefCriteriaOther(
              notAppliedCompanyDetails?.jobData.referralCriteriaOther
            );
          }

          //

          if (!isEmpty(notAppliedCompanyDetails?.jobData?.monthOfContract)) {
            populatedFormData.monthOfContract.valueText =
              notAppliedCompanyDetails?.jobData?.monthOfContract;
          }

          if (!isEmpty(notAppliedCompanyDetails?.jobData?.referralAmount)) {
            populatedFormData.referralAmount.valueText =
              notAppliedCompanyDetails?.jobData?.referralAmount;
          }
          setActionValue(res?.data?.data?.action);
          setFormData(populatedFormData);
        }
      }
    });
  };

  useEffect(() => {
    if (screeningProcessFields === "" || screeningProcessFields === null) {
      setScreeningProcessFields([{ value: "" }]);
      setScreeningProcess([]);
    }
    if (jobCareerpathFields === "" || jobCareerpathFields === null) {
      setJobCareerPathFields([""]);
      setJobCareerPath([]);
    }
    if (jobAdvantageFields === "" || jobAdvantageFields === null) {
      setjobAdvantageFields([""]);
      setjobAdvantage([]);
    }
    if (jobEveryDayTaskFields === "" || jobEveryDayTaskFields === null) {
      setJobEveryDayTaskFields([{ value: "" }]);
      setjobEveryDay([]);
    }
    if (jobQuestionsFields === "" || jobQuestionsFields === null) {
      setJobQuestionsFields([initialJobQuestionsFieldsState]);
      // setJobQuestionsFields([]);
    }
    if (
      referralCriteriaOtherFields === "" ||
      referralCriteriaOtherFields === null
    ) {
      setreferralCriteriaOtherFields([{ value: "" }]);
      setrefCriteriaOther([]);
    }
    //  if(jobCareerpathFields === "" || jobCareerpathFields === null){
    //   setJobCareerPathFields([{value:""}])
    //   setJobCareerPath([])
    // }
    // {salarycheck === "P"
    // ? Math.round((referralBouns / 100) *  formData?.minSalary?.valueText)
    // : referralBouns}
    if (referralBonusType === "P") {
      setIsminSalaryChecked(true);
      setRefBonusType("P");
      setSalaryCheck("P");
    } else {
      setIsFixedAmtChecked(true);
      setSalaryCheck("F");
      setRefBonusType("F");
    }
    if (referralBonusType === "P") {
      setReferralAmount1(
        Math.round((referralAmount / 100) * formData?.minSalary?.valueText)
      );
      // setIsminSalaryChecked(true);
      // setRefBonusType("P");
      // setSalaryCheck("P");
    } else {
      setReferralAmount1(referralBouns);
      // setIsFixedAmtChecked(true);
      // setSalaryCheck("F");
      // setRefBonusType("F");
    }

    const a = reportingStructures.toString().split(",");
    setReportvalue(a);
  }, [
    jobReportingType,
    screeningProcessFields,
    jobCareerpathFields,
    jobEveryDayTaskFields,
    jobAdvantageFields,
    jobQuestionsFields,
    referralCriteriaOtherFields,
  ]);

  const onChangeIsFixedAmt = () => {
    setRefBonusType("F");

    // setIsFixedAmtChecked(!isFixedAmtChecked);
    // if (isFixedAmtChecked === true) {
    //   setIsminSalaryChecked(false);
    //   setRefBonusType("F");
    // }
    // else{
    //   setRefBonusType("");
    // }
  };
  const onChangeIsminSalary = () => {
    setRefBonusType("P");

    // setIsminSalaryChecked(!isminSalaryChecked);
    // if (isminSalaryChecked === true) {
    //   setIsFixedAmtChecked(false);
    //   setRefBonusType("P");
    // }
    // else{
    //   setRefBonusType("");
    // }
  };
  const onChangeExprience = () => {
    // if (isMinimumExpChecked === true) {
    setIsRangeChecked(false);
    setIsMinimumExpChecked(true);
    // setMaxExperience("");
    // }
  };

  const onChangeRange = () => {
    // if (isRangeChecked === true) {
    setIsRangeChecked(true);
    setIsMinimumExpChecked(false);

    // }
    // setIsRangeChecked(!isRangeChecked);
  };
  // setIsMinimumExpChecked(!isMinimumExpChecked);
  function AddscreeningProcessField() {
    const addscreeningProcess = [...screeningProcessFields];
    addscreeningProcess.push("");
    setScreeningProcessFields(addscreeningProcess);
  }

  function ScreeningProcessOutput(i, e) {
    const addscreeningProcess = [...screeningProcessFields];
    addscreeningProcess[i] = e.target.value;
    setScreeningProcessFields(addscreeningProcess);
    // setFormData({
    //   ...formData,
    //   screeningProcess: addscreeningProcess,
    // });
  }
  function removeScreeningProcess(data, index) {
    const rows = [...screeningProcessFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    rows.splice(itemIndex, 1);
    setScreeningProcessFields(rows);
  }

  function AddjobAdvantageFields() {
    const addjobAdvantageFields = [...jobAdvantageFields];
    addjobAdvantageFields.push("");
    setjobAdvantageFields(addjobAdvantageFields);
  }

  function jobAdvantageFieldsOutput(i, e) {
    const addAdvantage = [...jobAdvantageFields];
    addAdvantage[i] = e.target.value;
    setjobAdvantageFields(addAdvantage);
    setFormData({
      ...formData,
      jobAdvantage: addAdvantage,
    });
  }
  function removejobAdvantage(data, index) {
    const rows = [...jobAdvantageFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    rows.splice(itemIndex, 1);
    setjobAdvantageFields(rows);
  }

  function AddreferralCriteriaOtherFields() {
    const addreferralCriteriaOther = [...referralCriteriaOtherFields];
    addreferralCriteriaOther.push("");
    setreferralCriteriaOtherFields(addreferralCriteriaOther);
  }

  function referralCriteriaOtherOutput(i, e) {
    const addreferralCriteriaOther = [...referralCriteriaOtherFields];
    addreferralCriteriaOther[i] = e.target.value;
    setreferralCriteriaOtherFields(addreferralCriteriaOther);
    setFormData({
      ...formData,
      referralCriteriaOther: addreferralCriteriaOther,
    });
  }

  function removeReferralCriteriaOther(data, index) {
    const rows = [...referralCriteriaOtherFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    rows.splice(itemIndex, 1);
    setreferralCriteriaOtherFields(rows);
  }

  function AddjobEveryDayTaskFields() {
    const addjobEveryDayTaskFields = [...jobEveryDayTaskFields];
    addjobEveryDayTaskFields.push("");
    setJobEveryDayTaskFields(addjobEveryDayTaskFields);
  }

  function jobEveryDayTaskOutput(i, e) {
    if (e.target.value.length >= Character_Limit) {
      setTotal(e.target.value.length - Character_Limit);
    }
    const addEveryDay = [...jobEveryDay];
    addEveryDay[i] = e.target.value.length;

    setjobEveryDay(addEveryDay);

    const addjobEveryDayTaskFields = [...jobEveryDayTaskFields];
    addjobEveryDayTaskFields[i] = e.target.value;
    setJobEveryDayTaskFields(addjobEveryDayTaskFields);
    setFormData({
      ...formData,
      jobEveryDayTask: addEveryDay,
    });
  }

  const removeEveryDayTaskField = (data, index) => {
    const rows = [...jobEveryDayTaskFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    rows.splice(itemIndex, 1);
    setJobEveryDayTaskFields(rows);
  };

  function AddQuestionsFields(i) {
    const addQuestionsFields = {
      action: "ADD",
      isMandatory: "N",
      quesType: "F_T",
      questionId: i + 1,
      questionText: "",
    };

    setJobQuestionsFields([...jobQuestionsFields, addQuestionsFields]);
  }

  const onDelete = (data, i) => {
    let tempJobQuestionsFields = [...jobQuestionsFields];
    tempJobQuestionsFields.splice(i, 1);
    setJobQuestionsFields(tempJobQuestionsFields);
  };

  function AddCareerPathFields() {
    const addCareerPathFields = [...jobCareerpathFields];
    addCareerPathFields.push("");
    setJobCareerPathFields(addCareerPathFields);
  }

  function jobCareerpathOutput(i, e) {
    const addCareerPathFields = [...jobCareerpathFields];
    addCareerPathFields[i] = e.target.value;
    setJobCareerPathFields(addCareerPathFields);
    setFormData({
      ...formData,
      jobCareerPath: addCareerPathFields,
    });
  }
  function removeCareerpathField(data, index) {
    const rows = [...jobCareerpathFields];
    let itemIndex = rows.findIndex((item, index) => item === data);

    rows.splice(itemIndex, 1);
    setJobCareerPathFields(rows);
  }

  useEffect(() => {
    if (localStorage.getItem("EditKeys") === "tempJob") {
      // localStorage.getItem("EditKeys") === "PreJob" )
      //  && jobId === "scratch")
      setJob_Id("");
    } else if (localStorage.getItem("EditKeys") === "PreJob") {
      setJob_Id("");
    } else if (localStorage.getItem("EditKeys") === "") {
      setJob_Id(jobId);
      // }
    }

    setBusinessUser_Id(userId);
    setCompanyLogo(orgLogo);
    getAllMasterData();
    PreviousJobs();

    if (jobId === "scratch") {
      getAutoSaveJobPosts();
    }

    if (isMinimumExpChecked !== true) {
      getPercentage(calMinSalPer, bonusCal);
    }
  }, [check_autoSave]);

  useEffect(() => {
    if (formData?.isRange?.valueText === true) {
      //  isRangeChecked=== formData?.isRange?.valueText) {

      setCheck("Range");
      setIsRangeChecked(true);
      setIsMinimumExpChecked(false);
    }
    if (formData?.isRange?.valueText === false) {
      setCheck("Experience");
      setIsRangeChecked(false);
      setIsMinimumExpChecked(true);
    }
    // if (referralBonusType === "P") {
    //   setIsminSalaryChecked(true);
    //   setRefBonusType("P");
    //   setSalaryCheck("P");
    // } else {
    //   setIsFixedAmtChecked(true);
    //   setSalaryCheck("F");
    //   setRefBonusType("F");
    // }
  }, [
    formData?.isRange?.valueText,
    referralBonusType,
    referralAmount,
    jobPowers,
    formData?.reportingType?.valueText,
    formData?.jobType?.valueText,
  ]);

  let data = "";
  const AutoSave_Draft_Publish = (Savetype) => {
    // var isValid = true;

    // Object.keys(formData)?.forEach((key) => {
    //   if (!validateField(key, formData, setFormData)) {
    //     isValid = false;
    //   }
    // });

    const autoSave = Savetype;
    const monthOfContract =
      formData?.jobType?.valueText === "CONTRACT"
        ? formData?.monthOfContract?.valueText
        : "";
    const companyId = company_Id;
    const jobId = job_Id === "scratch" ? "" : job_Id;
    // job_Id === "" || job_Id === "scratch" ? "" : job_Id;
    const action = Savetype;

    const businessId = business_Id;
    const businessUserId = businessUser_Id;
    const additionalRemarks = formData?.additionalRemarks?.valueText;
    const jobTitle =
      formData?.jobTitle?.valueText?.toString() !== ""
        ? formData?.jobTitle?.valueText?.toString()
        : "";
    //  formData?.jobTitle?.valueText?.toString();
    const departmentName =
      jobDepartmentOutputStatic?.toString() !== ""
        ? jobDepartmentOutputStatic?.toString()
        : jobDepartmentOutput.toString();
    // formData?.departmentName?.valueText?.toString() !== ""
    //   ? formData?.departmentName?.valueText?.toString()
    //   : jobDepartmentOutput.toString();
    const jobType = formData?.jobType?.valueText;
    const reportingType = formData?.reportingType?.valueText;
    const locationId =
      jobLocationOutputStatic?.toString() !== ""
        ? jobLocationOutputStatic?.toString()
        : locationvalue.toString();
    // formData?.locationName?.valueText?.toString() !== ""
    //   ? formData?.locationName?.valueText?.toString()
    //   : locationvalue.toString();
    const numPosition = numberOfRolesInput;
    const reportingStructure =
      // reportStructure2 !==[]
      //  ?reportingStructures
      // :
      reportStructure1;
    const minExpRequiredMonths = minExperience;
    // formData?.minExpRequiredMonths?.valueText;
    const maxExpRequiredMonths = maxExperience;
    // formData?.maxExpRequiredMonths?.valueText;
    const isRange = isRangeChecked;
    const minSalary = formData?.minSalary?.valueText;
    const maxSalary = formData?.maxSalary?.valueText;
    const salaryType = salary_Type;
    const referralBonusType = refBonusType;
    const reportingRole =
      formData?.reportingRole?.valueText !== ""
        ? formData?.reportingRole?.valueText
        : reportingrole;
    const targetDate = currentDate;
    const salCurrencyId = salaryId;
    const referralBonus =
      // formData?.referralBonus?.valueText!==""
      // // ?referralAmount
      //  formData?.referralBonus?.valueText;
      // referralAmount1===""
      // ?
      referralAmount;
    // : formData?.referralBonus?.valueText
    // :

    // referralAmount === ""
    // ?formData?.referralBonus?.valueText
    // :referralAmount;

    const screeningProcess =
      screeningProcessFields?.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? // action === "draft" ||
          // action==="save") ||
          // Savetype === true ||
          // Savetype === false
          []
        : screeningProcessFields;
    // ||(screeningProcessFields?.filter(function (o) {
    //   return o.hasOwnProperty("value");
    // }).length > 0 &&
    //   // action === "draft") ||
    //   action==="save")
    //   ? []
    //   : screeningProcessFields
    const skillsRequired =
      formData?.skillsRequired?.valueText === []
        ? []
        : formData?.skillsRequired?.valueText;
    const jobAdvantage =
      jobAdvantageFields?.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? // action === "draft" )||
          // // action==="save") ||
          // Savetype === true ||
          // Savetype === false
          []
        : jobAdvantageFields;
    // ||(jobAdvantageFields?.filter(function (o) {
    //   return o.hasOwnProperty("value");
    // }).length > 0 &&
    //   // action === "draft" )||
    //   action==="save") ||
    //   Savetype === true ||
    //   Savetype === false
    //   ? jobAdvantageFields
    //   : []

    const jobEveryDayTask =
      jobEveryDayTaskFields?.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? // action === "draft" )||
          // // action==="save") ||
          // Savetype === true ||
          // Savetype === false
          []
        : jobEveryDayTaskFields;
    // ||(jobEveryDayTaskFields?.filter(function (o) {
    //   return o.hasOwnProperty("value");
    // }).length > 0 &&
    //   // action === "draft" )||
    //   action==="save") ||
    //   Savetype === true ||
    //   Savetype === false
    //   ? jobEveryDayTaskFields
    //   : []
    const jobPower = jobPowers;
    const jobCareerPath =
      jobCareerpathFields?.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? // action === "draft" )||
          // // action==="save") ||
          // Savetype === true ||
          // Savetype === false
          []
        : jobCareerpathFields;
    // ||(jobCareerpathFields?.filter(function (o) {
    //   return o.hasOwnProperty("value");
    // }).length > 0 &&
    //   // action === "draft" )||
    //   action==="save") ||
    //   Savetype === true ||
    //   Savetype === false
    //   ? jobCareerpathFields
    //   : []
    const referralCriteriaCompany =
      formData?.referralCriteriaCompany?.valueText;
    const referralCriteriaIndustry =
      formData?.referralCriteriaIndustry?.valueText;
    const referralCriteriaOther =
      referralCriteriaOtherFields.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? []
        : referralCriteriaOtherFields;
    const questions = jobQuestionsFields;
    // jobQuestionsFields;

    // jobQuestionsFields;

    data = {
      businessId: businessId,
      businessUserId: businessUserId,
      companyId: companyId,
      jobTitle: jobTitle,
      monthOfContract: monthOfContract,
      locationId: locationId,
      departmentName: departmentName,
      reportingType: reportingType,
      jobType: jobType,
      numPosition: numPosition,
      reportingRole: reportingRole,
      minExpRequiredMonths: minExpRequiredMonths,
      maxExpRequiredMonths: maxExpRequiredMonths,
      isRange: isRangeChecked,
      reportingStructure: reportingStructure,
      salCurrencyId: salaryId,
      minSalary: minSalary,
      maxSalary: maxSalary,
      salaryType: salary_Type,
      referralBonusType: referralBonusType,
      referralBonus: referralBonus,
      screeningProcess: screeningProcess,
      skillsRequired: isEmpty(skillsRequired) ? [] : skillsRequired,
      jobAdvantage: jobAdvantage,
      jobEveryDayTask: jobEveryDayTask,
      jobPower: jobPower,
      jobCareerPath: jobCareerPath,
      additionalRemarks: additionalRemarks,
      targetDate: targetDate,
      questions:
        //   (jobQuestionsFields?.filter(function (o) {
        //   return o.hasOwnProperty("value");
        // }).length > 0 &&
        //   action === "save") ||
        //   (jobQuestionsFields?.filter(function (o) {
        //     return o.hasOwnProperty("value");
        //   }).length > 0 &&
        //     action === "draft") ||
        //   Savetype === true ||
        //   Savetype === false
        //   ? []
        //   : jobQuestionsFields,
        questions,
      referralCriteriaCompany: referralCriteriaCompany,
      referralCriteriaIndustry: referralCriteriaIndustry,
      referralCriteriaOther: referralCriteriaOther,
    };

    let draftPublishData = { ...data };
    draftPublishData = {
      ...data,
      action: action,
      jobId: jobId,
    };
    let autoSaveData = { ...data };
    autoSaveData = {
      ...data,
      action: "draft",
      autoSave: autoSave,
    };
    let isValid = true;
    // Object.keys(formData)?.forEach((key) => {
    if (data === "") {
      // && validateField(key, formData, setFormData)){
      // if (validateField1(key, formData, setFormData)) {
      isValid = false;
    }
    // });
    let Valid = true;

    if (jobTitle == "" && departmentName == "" && locationId == "") {
      Valid = false;
    }

    // if(isValid){
    //   toaster("error", "All fileds required ");
    //  }
    if (data?.minSalary < data?.maxSalaryy) {
      // console.log(data?.minSalary < data?.maxSalary, data?.maxSalary < data?.minSalary,"data maxsalary")
      toaster("error", " please enter Max Salary is greater than Min Salary");
      return;
    }
    if (data?.minSalary <= 0 && data?.maxSalary) {
      toaster("error", " Salary can not be negative and not be zero");
      return;
    }
    if (data?.minSalary % 1 != 0 && data?.maxSalary % 1 != 0) {
      toaster("error", " Salary can not be decimal");
      return;
    }

    if (data?.minExpRequiredMonths < 0 || data?.maxExpRequiredMonths < 0) {
      toaster("error", "Experience cannot be negative");
      return;
    }

    // if(data?.isRange===true){
    //   if (data?.maxExpRequiredMonths < data?.minExpRequiredMonths) {
    //     toaster(
    //       "error",
    //       "Minimum experience cannot be greater than maximum experience"
    //     );
    //     return;
    //   }
    // }
    else {
      if (autoSave == true || Savetype === true || Savetype === false) {
        postAutoSave(autoSaveData).then((res) => {
          if (Valid) {
            if (res?.data?.status === STATUS_SUCCESS) {
              setIsPreviewed(false);
              setNotAppliedCompanyDetails();
              console.log(res?.data, "job save data");
              if (action === "save") {
                if (isValid) {
                  if (res?.data?.status === STATUS_SUCCESS) {
                    toaster("success", res?.data?.message);
                    navigate(JOB_POSTS_PAGE_ROUTE);
                  } else {
                    if (res?.data?.status === "ERROR" && res?.data === false)
                      toaster("error", res?.data?.message);
                  }
                } else {
                  toaster("error", res?.data?.message);
                }
              }
            } else {
              toaster("error", res?.data?.message);
            }
          }
        });
      }
      if (Savetype == "save" || Savetype == "draft") {
        postNewJob(draftPublishData).then((res) => {
          if (Valid) {
            if (res?.data?.status === STATUS_SUCCESS) {
              setIsPreviewed(false);
              setNotAppliedCompanyDetails();
              console.log(res?.data, "job save data");
              if (action === "save") {
                if (isValid) {
                  if (res?.data?.status === STATUS_SUCCESS) {
                    toaster("success", res?.data?.message);
                    navigate(JOB_POSTS_PAGE_ROUTE);
                  } else {
                    if (res?.data?.status === "ERROR" && res?.data === false)
                      toaster("error", res?.data?.message);
                  }
                } else {
                  toaster("error", res?.data?.message);
                }
              }
            } else {
              toaster("error", res?.data?.message);
            }
          }
          // else{
          //   toaster("error", res?.message ? res?.message : GENERAL_ERROR_MESSAGE);
          // }

          if (Valid) {
            if (res?.data?.status === STATUS_SUCCESS) {
              if (action === "draft") {
                if (res?.data?.status === STATUS_SUCCESS) {
                  toaster("success", "Draft added successfully");
                  navigate("/" + DRAFT_JOB_POST_PAGE_ROUTE);
                } else {
                  if (res?.data?.status === "ERROR" && res?.data === false)
                    //ERROR
                    toaster("error", res?.data?.message);
                }
              }
            } else {
              toaster("error", res?.data?.message);
            }
          } else {
            toaster("error", res?.data?.message);
          }
        });
      }
    }
  };
  return (
    <div className="" style={{ backgroundColor: "#f4f7fb" }}>
      {/* {showLoader && <Loader />} */}

      {/* <div className="w-100 ">
        <Header />
      </div> */}
      <div
        className="w-100 pt-4"
        style={{
          borderRadius: "0px !important",
        }}
      >
        <div
          className="container px-md-4 px-3"
          style={{ marginTop: "-1.5rem" }}
        >
          <div className=" border-0 mb-0 mt-2 py-0 d-flex justify-content-between pt-4">
            <div className="mt-2 d-flex gap-4 align-items-center">
              <Link
                to={-1}
                className="backTitle p-0 m-0 d-flex align-items-center gap-2"
                style={{ textDecoration: "none", color: "black" }}
                onClick={() => {
                  if (autoSave && actionValue !== "save") {
                    AutoSave_Draft_Publish(autoSave);
                    AutoSave_Draft_Publish("draft");
                  }
                }}
              >
                <img
                  src={Vectorleft}
                  alt=""
                  className="pointer"
                  height="12px"
                  width="16px"
                />
                <span
                  className="fs-12 fw-400"
                  style={{ borderBottom: "1px solid #1C1C1C" }}
                >
                  {" "}
                  Go Back
                </span>
              </Link>
            </div>
            <Link
              to={-1}
              className="backTitle p-0 m-0 d-flex align-items-center"
              style={{ textDecoration: "none", color: "black" }}
              onClick={() => {
                if (autoSave && actionValue !== "save") {
                  AutoSave_Draft_Publish(autoSave);

                  AutoSave_Draft_Publish("draft");
                }
              }}
            >
              <img
                src={closeRoundedRed}
                alt="close-icon"
                height="30px"
                width="30px"
                style={{ marginRight: "1rem" }}
                onClick={() => props.close()}
                className="pointer m-0 mt-1"
              />
            </Link>
          </div>

          <hr />

          {/* NEW JOB POST */}
          <div className="pt-0 gap-4">
            <div className=" d-md-flex justify-content-between flex-wrap align-items-center gap-1">
              <div>
                <p className="fw-700 fs-24" style={{ minWidth: "190px" }}>
                  New job Post
                </p>
              </div>
              <div className=" d-flex flex-wrap">
                <div
                  className="d-flex mt-2 col col-sm-6 col-md-3 pe-3 pe-sm-3 pe-md-5"
                  style={{ minWidth: "130px" }}
                >
                  <p className="fs-14 fw-700 pe-2 pe-sm-4">AutoSave </p>
                  <span className="form-switch ">
                    <input
                      type="checkbox"
                      className="form-check-input switch-btn pointer"
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAutoSave(true);
                          AutoSave_Draft_Publish(true);
                        } else {
                          setAutoSave(false);
                          AutoSave_Draft_Publish(false);
                        }
                      }}
                      defaultChecked={autoSave ? autoSave : check_autoSave}
                    />
                  </span>{" "}
                  <span className="fs-14 fw-400 p-0 m-0">Yes</span>
                </div>

                <div className="col-6  col-md-3 ps-2 px-md-4 px-0">
                  <button
                    className="btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                    style={{ minWidth: "130px" }}
                    onClick={() => AutoSave_Draft_Publish("draft")}
                  >
                    Save Draft
                  </button>
                </div>
                {isShowPrevBtn && (
                  <div className="col-6 col-md-3  align-middle">
                    {isEmpty(formData?.jobTitle?.valueText) ? (
                      <div
                        className={`btn btn-outline-secondary fs-14 fw-700 h-40  w-100`}
                        style={{ minWidth: "130px" }}
                      >
                        Preview
                      </div>
                    ) : (
                      <Link
                        to={SCRATCH_PREVIEW_PAGE_ROUTE}
                        className={`btn btn-outline-dark fs-14 fw-700 h-40  w-100`}
                        style={{ minWidth: "130px" }}
                        onClick={() => {
                          setIsPreviewed(true);
                          const monthOfContract =
                            formData?.jobType?.valueText === "CONTRACT"
                              ? formData?.monthOfContract?.valueText
                              : "";
                          const companyId = company_Id;
                          const businessId = business_Id;
                          const businessUserId = businessUser_Id;
                          const additionalRemarks =
                            formData?.additionalRemarks?.valueText;
                          const jobTitle =
                            formData?.jobTitle?.valueText?.toString() !== ""
                              ? formData?.jobTitle?.valueText?.toString()
                              : "";
                          const departmentName =
                            jobDepartmentOutputStatic?.toString() !== ""
                              ? jobDepartmentOutputStatic?.toString()
                              : jobDepartmentOutput.toString();
                          const jobType = formData?.jobType?.valueText;
                          const reportingType =
                            formData?.reportingType?.valueText;
                          const locationId =
                            jobLocationOutputStatic?.toString() !== ""
                              ? jobLocationOutputStatic?.toString()
                              : locationvalue.toString();

                          const numPosition = numberOfRolesInput;
                          const reportingStructure = reportStructure1;
                          const minExpRequiredMonths = minExperience;
                          const maxExpRequiredMonths = maxExperience;
                          const minSalary = formData?.minSalary?.valueText;
                          const maxSalary = formData?.maxSalary?.valueText;
                          const referralBonusType = refBonusType;
                          const reportingRole =
                            formData?.reportingRole?.valueText !== ""
                              ? formData?.reportingRole?.valueText
                              : reportingrole;
                          const targetDate = currentDate;
                          const referralBonus = referralAmount;

                          const screeningProcess =
                            screeningProcessFields?.filter(function (o) {
                              return o.hasOwnProperty("value");
                            }).length > 0
                              ? []
                              : screeningProcessFields;

                          const skillsRequired =
                            formData?.skillsRequired?.valueText === []
                              ? []
                              : formData?.skillsRequired?.valueText;
                          const jobAdvantage =
                            jobAdvantageFields?.filter(function (o) {
                              return o.hasOwnProperty("value");
                            }).length > 0
                              ? []
                              : jobAdvantageFields;

                          const jobEveryDayTask =
                            jobEveryDayTaskFields?.filter(function (o) {
                              return o.hasOwnProperty("value");
                            }).length > 0
                              ? []
                              : jobEveryDayTaskFields;

                          const jobPower = jobPowers;
                          const jobCareerPath =
                            jobCareerpathFields?.filter(function (o) {
                              return o.hasOwnProperty("value");
                            }).length > 0
                              ? []
                              : jobCareerpathFields;

                          const referralCriteriaCompany =
                            formData?.referralCriteriaCompany?.valueText;
                          const referralCriteriaIndustry =
                            formData?.referralCriteriaIndustry?.valueText;
                          const referralCriteriaOther =
                            referralCriteriaOtherFields.filter(function (o) {
                              return o.hasOwnProperty("value");
                            }).length > 0
                              ? []
                              : referralCriteriaOtherFields;
                          const questions = jobQuestionsFields;

                          let jobData = {
                            businessId: businessId,
                            businessUserId: businessUserId,
                            companyId: companyId,
                            jobTitle: jobTitle,
                            monthOfContract: monthOfContract,
                            locationId: locationId,
                            departmentName: departmentName,
                            reportingType: reportingType,
                            jobType: jobType,
                            numPosition: numPosition,
                            reportingRole: reportingRole,
                            minExpRequiredMonths: minExpRequiredMonths,
                            maxExpRequiredMonths: maxExpRequiredMonths,
                            isRange: isRangeChecked,
                            reportingStructure: reportingStructure,
                            salCurrencyId: salaryId,
                            minSalary: minSalary,
                            maxSalary: maxSalary,
                            salaryType: salary_Type,
                            referralBonusType: referralBonusType,
                            referralBonus: referralBonus,
                            screeningProcess: screeningProcess,
                            skillsRequired:
                              skillsRequired === [] || skillsRequired === null
                                ? []
                                : skillsRequired,
                            jobAdvantage: jobAdvantage,
                            jobEveryDayTask: jobEveryDayTask,
                            jobPower: jobPower,
                            jobCareerPath: jobCareerPath,
                            additionalRemarks: additionalRemarks,
                            targetDate: targetDate,
                            questions: questions,
                            referralCriteriaCompany: referralCriteriaCompany,
                            referralCriteriaIndustry: referralCriteriaIndustry,
                            referralCriteriaOther: referralCriteriaOther,
                          };
                          setNotAppliedCompanyDetails({
                            ...notAppliedCompanyDetails,
                            jobData,
                          });
                        }}
                      >
                        Preview
                      </Link>
                    )}
                  </div>
                )}

                {jobId === "scratch" ? (
                  // <div className="col-6  col-md-3 ps-2 px-md-4 px-0">
                  //   </div>
                  <div className="col-6  col-md-3 ps-2 px-md-4 px-0 ">
                    <button
                      style={{
                        minWidth: "130px",
                        backgroundColor: "#1C1C1C",
                        color: "#FFFFFF",
                      }}
                      className="btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                      // className="btn btn-outline-black fs-14 fw-700  h-40 w-100"
                      onClick={() => {
                        AutoSave_Draft_Publish("save");
                        // if (autoSave) {
                        //   AutoSave_Draft_Publish("draft");
                        // }
                      }}
                    >
                      Publish
                    </button>
                  </div>
                ) : (
                  <div className="col-6 col-md-3  align-middle">
                    <Link
                      to={PREVIEW_PAGE_ROUTE + `/${jobId}`}
                      className={
                        "btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                        // jobId === "scratch"
                        // ?
                        // "btn btn-outline-secondary active fs-14 fw-700  h-40 w-100 align-items-center d-flex justify-content-center disable-link"
                        //  :"btn btn-outline-datrrk fs-14 fw-700  h-40 w-100 align-items-center d-flex justify-content-center"
                      }
                      style={{ minWidth: "130px" }}
                    >
                      Preview
                    </Link>
                  </div>
                )}
                {jobId === "scratch" ? (
                  <div className="col-6  col-md-3 ps-2 px-md-4 px-0"></div>
                ) : (
                  <div className="col-6 ps-2 col-md-3 ">
                    <button
                      style={{
                        minWidth: "130px",
                        backgroundColor: "#1C1C1C",
                        color: "#FFFFFF",
                      }}
                      className="btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                      // className="btn btn-outline-black fs-14 fw-700  h-40 w-100"
                      onClick={() => {
                        AutoSave_Draft_Publish("save");
                        // if (autoSave) {
                        //   AutoSave_Draft_Publish("draft");
                        // }
                      }}
                    >
                      Publish
                    </button>
                  </div>
                )}
              </div>
            </div>
            {/* JOB POST BY */}
            <div className="d-flex justify-content-between flex-wrap mt-4 gap-4 mb-3">
              <div
                className="profile-box-shadow job-post-by justify-content-between  col-12 col-sm-2 p-3"
                style={{ minWidth: "250px" }}
              >
                <p className="fs-16 fw-600">Job Post By</p>
                <div className="d-flex gap-3">
                  <div className="logo-border-box position-relative">
                    {/* <img src={logoAirbnb} alt="airbnb-logo" /> */}
                    {/* {viewPreviousJobs?.companyProfile?.companyLogo === null || viewPreviousJobs?.companyProfile?.companyLogo === "" ? (
                     <ProfileImage
                     src={viewPreviousJobs?.companyProfile?.companyLogo}
                     width="50px"
                     height="50px"
                     initialsContainerClass="initialsStyle2-xl"
                   />
                      
                    ):(
                     

                    <ProfileImage
                    src={Org_logo}
                    width="50px"
                    height="50px"
                    initialsContainerClass="initialsStyle2-xl"
                  />
                    )
                    }  */}
                    {companyData?.companyLogo === null &&
                    companyData?.companyLogo === undefined ? (
                      <img src={logoAirbnb} width="50px" alt="" />
                    ) : (
                      <img
                        src={`data:image/jpeg;base64 , ${companyData?.companyLogo}`}
                        width="50px"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="color-primary fw-500 fs-14 pt-3">
                    {companyData?.companyName}
                  </div>
                </div>
              </div>

              {/* JOB TITLE */}
              <div
                className="profile-box-shadow  justify-content-between  col p-3"
                style={{ minWidth: "250px" }}
              >
                <p className="fs-16 fw-600">
                  Job Title
                  <span className="astrick">*</span>
                </p>

                <div className=" col-12 ">
                  {/* <input
                    // maxLength={Character}
                    type="text"
                    className={
                      (formData?.jobTitle?.errorText ? "error" : "") +
                      " modal-form-input small-text-medium-gray text-dark h-40 form-control fs-14"
                    }
                    placeholder="Add Job Title"
                    defaultValue={
                      formData?.jobTitle?.valueText
                        ? formData?.jobTitle?.valueText
                        : ""
                    }
                    required
                    name="jobTitle"
                    key="jobTitle"
                    onChange={($event) => {
                      onInputChange("jobTitle", $event.target.value);
                    }}
                  /> */}
                  <div className="d-flex gap-4 align-items-start flex-grow">
                    <SearchComboBox
                      name="jobTitle"
                      inputData={jobrole}
                      key="jobTitle"
                      // defaultValue={jobTitleOutput}
                      defaultValue={formData?.jobTitle?.valueText}
                      isMultiSelect={false}
                      // inputCssClass={`modal-input combo-search-box ${formData?.jobTitle?.errorText} form-control`}
                      inputCssClass={"modal-input combo-search-box"}
                      wrapperCssClass={"form-group col-12 col-md-12"}
                      placeholder={"Search or Select"}
                      onSelect={(event) => {
                        if (!isEmpty(event)) {
                          setIsPreviewBtnDisable(false);
                        }
                        onInputChange("jobTitle", event);
                      }}
                      searchListHeight={150}
                      badgeThemeCssClass={"blue fs-12"}
                      hideBadges={false}
                      isAllowUserDefined={true}
                    />
                  </div>
                  {formData?.jobTitle?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.jobTitle?.errorText}
                    </div>
                  )}

                  {/* <h4
                    className="fs-11 fw-400 mt-2"
                    style={{ color: "#909090" }}
                  >
                    {formData?.jobTitle?.valueText?.length}-{Character}
                    characters left
                  </h4> */}
                </div>
              </div>
            </div>
            {/* JOB DEPARTMENT */}
            <div className="d-flex justify-content-between flex-wrap  gap-4 mb-3">
              <div className="profile-box-shadow mt-2  w-100">
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Job Department<span className="astrick">*</span>
                  </h4>
                  <div className="d-flex gap-4 align-items-start flex-grow">
                    <SearchComboBox
                      name="departmentName"
                      inputData={functions}
                      key="departmentName"
                      // defaultValue={jobDepartmentOutputStatic}
                      defaultValue={formData?.departmentName?.valueText}
                      isMultiSelect={false}
                      // inputCssClass={`modal-input combo-search-box ${formData?.departmentName?.errorText} form-control`}
                      inputCssClass={"modal-input combo-search-box"}
                      wrapperCssClass={"form-group col-12 col-md-3"}
                      placeholder={"Search or Select"}
                      onSelect={(event) => {
                        onInputChange("departmentName", event);
                      }}
                      searchListHeight={150}
                      badgeThemeCssClass={"blue fs-12"}
                      hideBadges={false}
                      isAllowUserDefined={true}
                    />

                    <div className="d-flex flex-wrap  gap-2">
                      {jobDepartment?.map((output, index) => {
                        if (
                          output.name === "Engineering" ||
                          output.name === "Design" ||
                          output.name === "Sales" ||
                          output.name === "Marketing" ||
                          output.name === "Operations"
                        ) {
                          return (
                            <div
                              className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer"
                              // key={index<=5}
                              onClick={() => {
                                if (!isEmpty(jobDepartmentOutput)) {
                                  const newJobDepartment = [
                                    ...jobDepartmentOutput,
                                  ];

                                  if (newJobDepartment.includes(output.name)) {
                                    let itemIndex =
                                      jobDepartmentOutput?.findIndex(
                                        (tm) => tm === output.name
                                      );

                                    newJobDepartment.splice(itemIndex, 1);

                                    //   } else {
                                  }
                                  setJobDepartmentOutput(newJobDepartment);
                                  setJobDepartmentOutputStatic("");
                                } else {
                                  setJobDepartmentOutput([output.name]);
                                  // setJobDepartmentOutputStatic([""]);
                                }
                              }}
                            >
                              {jobDepartmentOutput.includes(output.name) ? (
                                <img
                                  height="16px"
                                  width="16px"
                                  src={checkedLogo}
                                  alt=""
                                ></img>
                              ) : (
                                ""
                              )}

                              <div>{output?.name} </div>
                            </div>
                          );
                        }
                        // }
                      })}
                    </div>
                  </div>
                  <br />
                  {/* <div className="d-flex flex-wrap  gap-2">
                    {jobDepartmentOutput?.map((output, index) => {
                      return (
                        <div
                          className="blue-badge d-flex  height-28 gap-3"
                          key={index}
                          onClick={() => {
                            let arr = jobDepartmentOutput.filter((item) => {
                              return item !== output;
                            });
                            setJobDepartmentOutput(arr);
                            setJobDepartmentOutputStatic(arr);
                            let department_Name = arr.toString();

                            setFormData({
                              ...formData,
                              departmentName: department_Name,
                            });
                          }}
                        >
                          <div>{output} </div>
                          <img
                            src={icCloseBlue}
                            alt="blue-close-icon"
                            className="pointer"
                            height="10px"
                          />
                        </div>
                      );
                    })}
                  </div> */}
                  {formData.departmentName.errorText && (
                    <div className="field-error mt-1">
                      {formData.departmentName.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* JOB LOCATION */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4 mt-4 mb-3">
              <div
                className="profile-box-shadow  col"
                style={{ minWidth: "250px" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Job Location<span className="astrick">*</span>
                  </h4>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="col-12">
                      <SearchComboBox
                        name="locationName"
                        key="locationName"
                        inputData={locations}
                        defaultValue={formData?.locationName?.valueText}
                        isMultiSelect={false}
                        inputCssClass={"modal-input combo-search-box"}
                        // inputCssClass={`modal-input combo-search-box ${
                        //   formData?.locationName?.errorText ? "error" : ""
                        // } form-control`}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        onSelect={($event) => {
                          onInputChange("locationName", $event);
                        }}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue fs-12"}
                        hideBadges={false}
                        isAllowUserDefined={true}
                      />
                    </div>
                  </div>

                  <br />
                  {/* <div className="d-flex flex-wrap  gap-2">
                    {jobLocationOutput?.map((output, index) => {
                      return (
                        <div
                          className="blue-badge d-flex  height-28 gap-3"
                          key={index}
                          onClick={() => {
                            let arr = jobLocationOutput.filter((item) => {
                              return item !== output;
                            });
                            setJobLocationOutput(arr);
                            // setJobDepartmentOutputStatic(arr);
                            let department_Name = arr.toString();
                          }}
                        >
                          <div>{output} </div>
                          <img
                            src={icCloseBlue}
                            alt="blue-close-icon"
                            className="pointer"
                            height="10px"
                          />
                        </div>
                      );
                    })}
                  </div> */}
                  <br />
                  <div className="d-md-flex d-none flex-wrap height-28 gap-2 ">
                    {locations?.map((output, index) => {
                      // if (index <= 5 ) {
                      if (
                        output.name === "Mumbai" ||
                        output.name === "Delhi" ||
                        output.name === "Bangalore" ||
                        output.name === "Pune" ||
                        output.name === "Hyderabad"
                      ) {
                        return (
                          <div
                            className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer"
                            // key={index<= 5}
                            onClick={() => {
                              if (!isEmpty(jobLocationOutput)) {
                                const newJobLocation = [...jobLocationOutput];
                                if (newJobLocation.includes(output.name)) {
                                  let itemIndex = jobLocationOutput?.findIndex(
                                    (tm) => tm === output.name
                                  );
                                  newJobLocation.splice(itemIndex, 1);
                                  // } else {
                                  //   newJobLocation.push(output.name);
                                }
                                setLocationValue(newJobLocation);
                                setJobLocationOutputStatic("");
                              } else {
                                setLocationValue([output.name]);
                                // setJobLocationOutputStatic([output.name]);
                              }
                            }}
                          >
                            {locationvalue.includes(output.name) ? (
                              <img
                                height="16px"
                                width="16px"
                                src={checkedLogo}
                                alt=""
                              ></img>
                            ) : (
                              ""
                            )}

                            {/* {output.name==="Mumbai" || output.name==="Delhi" || output.name==="Bangalore" || output.name==="Pune" || output.name==="Hyderabad" ? */}
                            <div>{output.name} </div>
                            {/* :"" */}
                            {/* } */}
                          </div>
                        );
                      }
                      // }
                    })}
                  </div>
                </div>

                {formData.locationName.errorText && (
                  <div className="field-error mt-1">
                    {formData.locationName.errorText}
                  </div>
                )}
              </div>

              {/* JOB REPORTING */}
              <div
                className="profile-box-shadow  col"
                style={{ minWidth: "250px" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Job Reporting<span className="astrick">*</span>
                  </h4>

                  {/* <div className=" mt-4 mx-1 d-flex flex-wrap gap-3">
                    {jobReportingType?.map((output, i) => {
                      return (
                        <div className="form-check col-6" key={i}>
                          <input
                            className={
                              (formData?.reportingType?.errorText
                                ? "error"
                                : "") + "form-check-input pointer"
                            }
                            type="radio"
                            name="reportingType"
                            id={output.description}
                            defaultChecked={
                              check_ReportType === output.description
                            }
                            onChange={() => {
                              onInputChange(
                                "reportingType",
                                output.description
                              );
                            }}
                            required
                          />
                          <label
                            className="fs-14 fw-400 form-check-label pointer"
                            htmlFor={output.description}
                          >
                            {output.name}
                          </label>
                        </div>
                      );
                    })}
                  </div> */}

                  <div className="d-lg-flex d-sm-grid flex-wrap mt-4 mx-1 gap-3">
                    {jobReportingType?.map((output, i) => {
                      return (
                        <div className="form-check col-6" key={i}>
                          <input
                            className={
                              (formData?.reportingType?.errorText
                                ? "error"
                                : "") + " form-check-input pointer"
                            }
                            type="radio"
                            name="reportingType"
                            key="reportingType"
                            id={output.description}
                            checked={
                              formData?.reportingType?.valueText ===
                              output.description
                                ? true
                                : false
                            }
                            // defaultChecked={
                            //   formData?.reportingType?.valueText
                            //   ===  output.description
                            //     ? true
                            //     : false
                            //   // check_ReportType=== output.description
                            // }
                            onChange={() => {
                              onInputChange(
                                "reportingType",
                                output.description
                              );
                            }}
                            required
                          />
                          <label
                            className="fs-14 fw-400 form-check-label pointer"
                            for="fulltime"
                            htmlFor={output.description}
                          >
                            {output.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {formData?.reportingType?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.reportingType?.errorText}
                  </div>
                )}
              </div>

              {/* JOB TYPE */}
              <div
                className="profile-box-shadow  col"
                style={{ minWidth: "250px" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Job Type<span className="astrick">*</span>
                  </h4>

                  <div className="d-lg-flex d-sm-grid flex-wrap mt-4 mx-1 gap-3">
                    {jobtype?.map((output, i) => {
                      return (
                        <div className="form-check col-6" key={i}>
                          <input
                            className={
                              (formData?.jobType?.errorText ? "error" : "") +
                              " form-check-input pointer"
                            }
                            type="radio"
                            name="jobtype"
                            key="jobtype"
                            id={output.description}
                            // defaultChecked={
                            //   formData?.jobType?.valueText ===
                            //   output.description

                            // }
                            defaultChecked={
                              formData?.jobType?.valueText ===
                              output.description
                                ? true
                                : false
                            }
                            onChange={() => {
                              onInputChange("jobType", output.description);
                              if (formData?.jobType?.valueText === "CONTRACT") {
                                setShowContractField(!showContractField);
                              } else {
                                setShowContractField(false);
                              }
                            }}
                            required
                          />
                          <label
                            className="fs-14 fw-400 form-check-label pointer"
                            for="fulltime"
                            htmlFor={output.description}
                          >
                            {output.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  {showContractField ? (
                    <>
                      <hr />
                      <div className="d-flex flex-wrap mt-4 mx-1 gap-2 align-items-center">
                        <div className="form-check">
                          <label
                            className="fs-14 fw-400 form-check-label pointer"
                            for="exampleRadios2"
                          >
                            Contract
                          </label>
                        </div>
                        <div className="ms-4 me-2 d-flex gap-2">
                          <input
                            defaultValue={formData?.monthOfContract?.valueText}
                            type="number"
                            className="h-40"
                            name="monthOfContract"
                            key="monthOfContract"
                            style={{ width: "76px" }}
                            onChange={($event) => {
                              onInputChange(
                                "monthOfContract",
                                $event.target.value
                              );
                            }}
                          />
                          <p
                            className="fs-11 fw-400 d-flex align-items-center m-0 "
                            style={{ color: "#909090" }}
                          >
                            Months
                          </p>
                        </div>
                      </div>
                      <div className="field-error mt-1">{contract_Error}</div>
                    </>
                  ) : (
                    ""
                  )}
                  {formData?.jobType?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.jobType?.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* NUMBER OF ROLES */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4 my-4">
              <div className="profile-box-shadow num-roles-width">
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Number of Roles<span className="astrick">*</span>
                  </h4>
                </div>
                <div className="d-flex gap-2 justify-content-start mb-4 px-3">
                  <div
                    className="border radius4 d-flex align-items-center justify-content-center pointer"
                    style={{ width: "58px", height: "40px" }}
                    onClick={() =>
                      setNumberOfRolesInput(numberOfRolesInput - 1)
                    }
                  >
                    <img src={minusRounded} alt="" />
                  </div>
                  <input
                    style={{
                      width: "85px",
                      borderRadius: "4px",
                      border: "1px solid #B9B9B9",
                    }}
                    value={numberOfRolesInput}
                    required
                    name="numPosition"
                    key="numPosition"
                    type="number"
                    className={
                      (formData?.numPosition?.errorText ? "error" : "") +
                      " col-4 text-center "
                    }
                  />

                  <div
                    className="border radius4  d-flex align-items-center justify-content-center pointer"
                    style={{ width: "58px", height: "40px" }}
                    onClick={() =>
                      setNumberOfRolesInput(numberOfRolesInput + 1)
                    }
                  >
                    <img src={plusRounded} alt="" />
                  </div>
                </div>
                {formData?.numPosition?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.numPosition?.errorText}
                  </div>
                )}
              </div>

              {/* EXPERIENCE */}
              <div
                className="profile-box-shadow  col"
                // style={{ width: "66.66%" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Experience<span className="astrick">*</span>
                  </h4>
                  <div className="d-flex gap-3 align-items-center flex-wrap ">
                    <div
                      className="fs-12 d-flex height-28 gap-1 border px-2 py-1 rounded-pill pointer"
                      onClick={() => {
                        // setCheck("Experience");
                        setCheck("Range");
                        onChangeRange();
                        // setIsRangeChecked(!isRangeChecked);
                      }}
                    >
                      {check === "Range" ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                        />
                      ) : (
                        ""
                      )}

                      <span>Range </span>
                    </div>
                    <div
                      className="fs-12 fw-400 d-flex height-28 gap-1 border px-2 py-1 rounded-pill pointer"
                      style={{ minWidth: "156px" }}
                      onClick={() => {
                        // setCheck("Range");
                        setCheck("Experience");
                        onChangeExprience();
                      }}
                    >
                      {check === "Experience" ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                      <div>Minimum Experience</div>
                    </div>
                    <input
                      style={{
                        width: "76px",
                        borderRadius: "4px",
                        border: "1px solid #B9B9B9",
                        height: "40px",
                      }}
                      // defaultValue={formData?.minExpRequiredMonths?.valueText}
                      type="number"
                      name="minExpRequiredMonths"
                      key="minExpRequiredMonths"
                      min={0}
                      max={70}
                      className={
                        (formData?.minExpRequiredMonths?.errorText
                          ? "error"
                          : "") + " col-4 text-center "
                      }
                      value={minExperience}
                      onChange={($event) => {
                        // onInputChange(
                        //   "minExpRequiredMonths",
                        //   $event.target.value
                        // );
                        if ($event?.target?.value > 100) {
                          return;
                        } else {
                          onInputChange(
                            "minExpRequiredMonths",
                            $event.target.value
                          );
                        }
                      }}
                      onKeyDown={(evt) =>
                        evt.key === "$event" && evt.preventDefault()
                      }
                    />
                    <span
                      className="fs-14 fw-400 align-self-end  pb-2"
                      style={{ color: "#808080" }}
                    >
                      Years
                    </span>
                    <span
                      className="fs-14 fw-400  align-self-end  pb-2"
                      style={{ color: "#808080" }}
                    >
                      to
                    </span>
                    <input
                      style={{
                        width: "76px",
                        borderRadius: "4px",
                        border: "1px solid #B9B9B9",
                        height: "40px",
                      }}
                      disabled={isMinimumExpChecked === true}
                      // defaultValue={formData?.maxExpRequiredMonths?.valueText}
                      type="number"
                      name="maxExpRequiredMonths"
                      key="maxExpRequiredMonths"
                      min={0}
                      max={70}
                      className={
                        (formData?.maxExpRequiredMonths?.errorText
                          ? "error"
                          : "") + " col-4 text-center"
                      }
                      // className={
                      //   (formData?.maxExpRequiredMonths?.errorText ? "error" : "") +
                      //   " form-control text-center w-30 "
                      // }
                      value={maxExperience}
                      onChange={($event) => {
                        //   onInputChange(
                        //     "maxExpRequiredMonths",
                        //     $event.target.value
                        //   );
                        // }}
                        if ($event?.target?.value > 100) {
                          return;
                        } else {
                          onInputChange(
                            "maxExpRequiredMonths",
                            $event.target.value
                          );
                        }
                      }}
                      onKeyDown={(evt) =>
                        evt.key === "$event" && evt.preventDefault()
                      }
                    />
                    <span
                      className="fs-14 fw-400  align-self-end pb-2"
                      style={{ color: "#808080" }}
                    >
                      years
                    </span>
                  </div>
                </div>
                {formData?.maxExpRequiredMonths?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.maxExpRequiredMonths?.errorText}
                  </div>
                )}
              </div>
            </div>
            {/* SALARY */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4 h-100 mt-4 mb-3">
              <div
                className="profile-box-shadow  col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="px-3 py-2">
                  <h4 className="fs-16 fw-600">
                    Salary<span className="astrick">*</span>
                  </h4>
                </div>
                <div className="mx-3 d-flex flex-wrap gap-3">
                  <div className="page-filter align-items-center ">
                    <select
                      className="form-select page-filter-button rounded-pill  border-dark fs-12 fw-400 pointer h-28 align-items-center p-0"
                      aria-label="Default select example"
                      name="salCurrency"
                      key="salCurrency"
                      onChange={($event) => {
                        onInputChange("salCurrency", $event.target.value);
                      }}
                    >
                      {salCurrency?.map((output, i) => {
                        return (
                          <option
                            selected={
                              salCurrencyPop === null || salCurrencyPop === ""
                                ? output.code === "INR"
                                : salCurrencyPop === output.code

                              // === output.code
                            }
                            //  value={salCurrencyPop===output.code}
                          >
                            {output.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="page-filter align-items-center">
                    <select
                      name="salaryType"
                      className="form-select page-filter-button rounded-pill border-dark fs-12 fw-400 pointer h-28 align-items-center p-0"
                      aria-label="Default select example"
                      onChange={($event) => {
                        onInputChange("salaryType", $event.target.value);
                      }}
                    >
                      {salaryTypeOptions?.map((output, i) => {
                        return (
                          <option
                            // selected={
                            //   formData?.salaryType?.valueText === output.name
                            //     ? formData?.salaryType?.valueText
                            //     : output.name
                            // }
                            selected={salary_Type === output.name}
                            defaultValue={output.name}
                            key="salaryType"
                            name="salaryType"
                          >
                            {output.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="d-md-flex d-sm-grid flex-wrap pb-3 mt-4  ">
                  <div className="mx-3  col mb-3">
                    <p className="back-button fs-11 fw-400 mt-0 mb-2">
                      Minimum Salary
                    </p>
                    <input
                      // style={{
                      //   borderRadius: "4px",
                      //   border: "1px solid #B9B9B9",
                      // }}
                      type="number"
                      min={0}
                      className={
                        (formData?.minSalary?.errorText ? "error" : "") +
                        " form-control text-center w-100"
                      }
                      defaultValue={formData?.minSalary?.valueText}
                      name="minSalary"
                      key="minSalary"
                      required
                      onChange={($event) => {
                        onInputChange("minSalary", $event.target.value);
                      }}
                    />
                    {formData?.minSalary?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.minSalary?.valueText
                          ? formData?.maxSalary?.errorText
                          : Minsalary_Error}
                      </div>
                    )}
                  </div>
                  <div className="mx-3 col">
                    <p className="back-button fs-11 fw-400 mt-0 mb-2">
                      Maximum Salary
                    </p>
                    <input
                      type="number"
                      name="maxSalary"
                      key="maxSalary"
                      required
                      className={
                        (formData?.maxSalary?.errorText ? "error" : "") +
                        " form-control text-center w-100"
                      }
                      defaultValue={formData?.maxSalary?.valueText}
                      onChange={($event) => {
                        onInputChange("maxSalary", $event.target.value);
                      }}
                    />

                    {/* <div className="field-error mt-1">
                      {
                        Maxsalary_Error
                      }
                    </div> */}

                    {/* {<div className="field-error mt-1">{salary_Error}</div>} */}
                    {formData?.maxSalary?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.maxSalary?.errorText}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* REFERRAL BONUS */}
              <div
                className="profile-box-shadow col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="px-3 pt-3 pb-0">
                  <h4 className="fs-16 fw-600">
                    Referral Bonus<span className="astrick">*</span>
                  </h4>
                </div>
                <div className="d-xs-grid d-sm-flex flex-wrap mx-3 ">
                  <div
                    className="fs-12 d-flex height-28 gap-1 border px-2 py-1 my-2 me-2 rounded-pill pointer"
                    style={{ width: "fit-content" }}
                    onClick={() => {
                      setSalaryCheck("P");
                      // setRefBonusType("P");
                      onChangeIsminSalary();
                    }}
                  >
                    {salarycheck === "P" ? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    <div>% of Min Salary</div>
                  </div>
                  <div
                    className="fs-12 fw-400 d-flex height-28 gap-1 border px-2 py-1  my-2 rounded-pill pointer"
                    style={{ width: "fit-content" }}
                    onClick={() => {
                      setSalaryCheck("F");
                      // setRefBonusType("F");
                      onChangeIsFixedAmt();
                    }}
                  >
                    {salarycheck === "F" ? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    <div>Fixed Amount</div>
                  </div>
                </div>
                <div className="mx-3 my-3 col mt-3">
                  <p className="back-button fs-11 fw-400 mt-0 mb-2">
                    Bonus Value
                  </p>

                  <input
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #B9B9B9",
                      maxWidth: "95px",
                    }}
                    defaultValue={referralAmount}
                    required
                    type="number"
                    name="referralBonus"
                    key="referralBonus"
                    className={
                      (formData?.referralBonus?.errorText ? "error" : "") +
                      " text-center w-100"
                    }
                    // className={
                    //   (formData?.referralBonus?.errorText ? "error" : "") +
                    //   " form-control text-center w-30 "
                    // }
                    onChange={(event) => {
                      onInputChange("referralBonus", event.target.value);
                    }}
                  />
                  <span className="mx-2 fs-14 fw-400">
                    = ₹
                    {formData?.minSalary?.valueText !== calMinSalPer
                      ? referralAmount1
                      : salarycheck === "P"
                      ? Math.round((bonusCal / 100) * calMinSalPer)
                      : bonusCal}
                    {/* {formData?.minSalary?.valueText !== calMinSalPer
                      ? referralBonusType === "p"
                        ? Math.round(
                            (referralBouns / 100) *
                              formData?.minSalary?.valueText
                          )
                        : referralBouns
                      : salarycheck === "P"
                      ? Math.round((bonusCal / 100) * calMinSalPer)
                      : bonusCal} */}
                  </span>
                </div>
                {formData?.referralBonus?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.referralBonus?.errorText}
                  </div>
                )}
              </div>
            </div>
            {/* Screening Process */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-2 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Screening Process</h4>
                  <p className="back-button fs-11 fw-400 mt-0">
                    Add screenig steps
                  </p>
                </div>
                <div className="d-flex gap-2 pointer mx-3 mt-0">
                  <img
                    src={icPlusIcon}
                    alt="plus-icon"
                    onClick={AddscreeningProcessField}
                  />
                  <div
                    className="fs-12 color-primary fw-700"
                    onClick={AddscreeningProcessField}
                  >
                    Add Next Step
                  </div>
                </div>
                {screeningProcessFields?.map((input, i) => {
                  return (
                    <>
                      <div className="pt-3 d-flex  ps-3 pb-2" key={i}>
                        <input
                          id={i}
                          maxLength={Character_Limit}
                          name="screeningProcess"
                          key="screeningProcess"
                          // value={formData?.screeningProcess?.valueText}
                          value={input}
                          className={
                            (formData?.screeningProcess?.errorText
                              ? "error"
                              : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Step ${i + 1}`}
                          onChange={(e) => ScreeningProcessOutput(i, e)}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removeScreeningProcess(input, i)}
                        />
                      </div>
                      {formData?.screeningProcess?.errorText && (
                        <div className="field-error mt-1">
                          {formData?.screeningProcess?.errorText}
                        </div>
                      )}
                      <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                        {screeningProcessFields[i]?.length
                          ? screeningProcessFields[i]?.length
                          : "0"}
                        -{Character_Limit}characters
                      </h1>
                    </>
                  );
                })}
              </div>
            </div>
            {/* Skills Required */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Skills Required<span className="astrick">*</span>
                  </h4>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="col-12 ">
                      <SearchComboBox
                        inputData={keySkill}
                        name="skillsRequired"
                        key="skillsRequired"
                        defaultValue={
                          jobSkills === ""
                            ? formData?.skillsRequired?.valueText
                            : jobSkills
                        }
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3 ${
                          formData?.skillsRequired?.errorText ? "error" : ""
                        } form-control w-30`}
                        // inputCssClass={
                        //   "modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3"
                        // }
                        wrapperCssClass={"form-group "}
                        placeholder={"Search or Select"}
                        searchListHeight={150}
                        badgeThemeCssClass={"red fs-12"}
                        hideBadges={false}
                        onSelect={(event) => {
                          onInputChange("skillsRequired", event);
                        }}
                        isAllowUserDefined={true}
                      />
                    </div>
                  </div>
                  {formData.skillsRequired.errorText && (
                    <div className="field-error mt-1">
                      {formData.skillsRequired.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Superpowers */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Superpowers<span className="astrick">*</span>
                  </h4>
                  <div className="d-flex flex-wrap gap-4 align-items-baseline">
                    <div className="col-12 ">
                      {/* <SearchComboBox
                        inputData={jobSuperPower}
                        defaultValue={jobPowers}
                        name="jobPower"
                        key="jobPower"
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3 ${formData?.jobPower?.errorText ? "error" : ""
                          } form-control w-30`}
                        // inputCssClass={
                        //   "modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3"
                        // }
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        onSelect={(event) => {
                          onInputChange("jobPower", event);
                        }}
                        searchListHeight={150}
                        badgeThemeCssClass={"red fs-12"}
                        hideBadges={false}
                        isAllowUserDefined={true}
                      /> */}

                      <input
                        // maxLength={Character}
                        type="text"
                        className={
                          (formData?.jobPower?.errorText ? "error" : "") +
                          " modal-form-input small-text-medium-gray text-dark h-40 form-control fs-14"
                        }
                        placeholder="Add Job Power"
                        defaultValue={
                          formData?.jobPower?.valueText
                            ? formData?.jobPower?.valueText
                            : jobPowers
                        }
                        // defaultValue={formData?.jobPower?.valueText}
                        required
                        name="jobPower"
                        key="jobPower"
                        onChange={($event) => {
                          onInputChange("jobPower", $event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {formData.jobPower.errorText && (
                    <div className="field-error mt-1">
                      {formData.jobPower.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Why you should look at this role
                    <span className="astrick">*</span>
                  </h4>
                </div>
                <div className="d-flex gap-2 pointer mx-3 mt-0">
                  <img
                    src={icPlusIcon}
                    alt="plus-icon"
                    onClick={AddjobAdvantageFields}
                  />
                  <div
                    className="fs-12 color-primary fw-700"
                    onClick={AddjobAdvantageFields}
                  >
                    Add Reason
                  </div>
                </div>
                {jobAdvantageFields?.map((input, i) => {
                  return (
                    <>
                      <div className="pt-3 d-flex  ps-3 pb-2" key={i}>
                        <input
                          id={i}
                          maxLength={Character_Limit}
                          type={input.type}
                          name="jobAdvantage"
                          // key="jobAdvantage"
                          // key={i}
                          value={input}
                          className={
                            (formData?.jobAdvantage?.errorText ? "error" : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Reason ${i + 1} here`}
                          defaultValue={jobAdvantage[i]}
                          onChange={(e) => jobAdvantageFieldsOutput(i, e)}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removejobAdvantage(input, i)}
                        />
                      </div>
                      {formData?.jobAdvantage?.errorText && (
                        <div className="field-error mt-1">
                          {formData?.jobAdvantage?.errorText}
                        </div>
                      )}

                      <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                        {jobAdvantageFields[i]?.length
                          ? jobAdvantageFields[i]?.length
                          : "0"}
                        -{Character_Limit}characters left
                      </h1>
                    </>
                  );
                })}
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    What you will do everyday<span className="astrick">*</span>
                  </h4>
                </div>
                <div className="d-flex gap-2 pointer mx-3 mt-0">
                  <img
                    src={icPlusIcon}
                    alt="plus-icon"
                    onClick={AddjobEveryDayTaskFields}
                  />
                  <div
                    className="fs-12 color-primary fw-700"
                    onClick={AddjobEveryDayTaskFields}
                  >
                    Add Point
                  </div>
                </div>
                {jobEveryDayTaskFields?.map((input, i) => {
                  return (
                    <>
                      <div className="pt-3 d-flex  ps-3 pb-2" key={i}>
                        <input
                          id={i}
                          maxLength={Character_Limit}
                          name="jobEveryDayTask"
                          key={i}
                          // key="jobEveryDayTask"
                          type={input.type}
                          //className="form-control modal-form-input small-text-medium-gray text-dark"
                          value={input}
                          className={
                            (formData?.jobEveryDayTask?.errorText
                              ? "error"
                              : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Reason ${i + 1} here`}
                          defaultValue={jobEveryDay[i]}
                          onChange={(e) => jobEveryDayTaskOutput(i, e)}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removeEveryDayTaskField(input, i)}
                        />
                        {formData?.jobEveryDayTask?.errorText && (
                          <div className="field-error mt-1">
                            {formData?.jobEveryDayTask?.errorText}
                          </div>
                        )}
                      </div>
                      <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                        {jobEveryDayTaskFields[i]?.length
                          ? jobEveryDayTaskFields[i]?.length
                          : "0"}
                        -{Character_Limit}characters left
                      </h1>
                    </>
                  );
                })}
              </div>
            </div>
            {/* CAREER PATH */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4  mb-4">
              <div
                className="profile-box-shadow   col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600 pb-3">
                    Career Path<span className="astrick">*</span>
                  </h4>
                  <div className="d-flex gap-3 mt-1 ms-1">
                    <img src={Vectorcompletionflag} alt="" />
                    <p className="fs-14 fw-400 back-button mx-1 my-3">
                      This Role
                    </p>
                  </div>
                  <div className="ms-2">
                    <img src={downDottedArrow} alt="" />
                  </div>
                  {jobCareerpathFields?.map((input, i) => {
                    return (
                      <div className="d-flex gap-3">
                        <img src={Vectorplusroundedgray} alt="" />
                        <div className=" d-flex   pb-2 align-items-center mt-3 w-100 gap-1">
                          <input
                            id={i}
                            type={input.type}
                            name="jobCareerPath"
                            key={i}
                            value={input}
                            //className="form-control modal-form-input small-text-medium-gray text-dark m-1"
                            className={
                              (formData?.jobCareerPath?.errorText
                                ? "error"
                                : "") +
                              " form-control modal-form-input small-text-medium-gray text-dark m-1"
                            }
                            placeholder={`Add Role ${i + 1}`}
                            defaultValue={JobCareerPath[i]}
                            onChange={(e) => jobCareerpathOutput(i, e)}
                          />
                          <img
                            src={icDelete}
                            alt="delete-icon"
                            className=" pointer"
                            onClick={() => removeCareerpathField(input, i)}
                          />
                          {formData?.jobCareerPath?.errorText && (
                            <div className="field-error mt-1">
                              {formData?.jobCareerPath?.errorText}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                  <div className="d-flex gap-3">
                    <img
                      src={icPlusIcon}
                      alt="plus-icon"
                      onClick={AddCareerPathFields}
                      className="pointer"
                    />
                    <div
                      className="fs-12 color-primary fw-700 pointer"
                      onClick={AddCareerPathFields}
                    >
                      Add Point
                    </div>
                  </div>
                </div>
              </div>

              {/* Reporting Structure */}
              <div
                className="profile-box-shadow h-100 col pb-4"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Reporting Structure<span className="astrick">*</span>
                  </h4>
                </div>

                <div className="gap-2 d-flex flex-column">
                  <div className="mx-3">
                    <input
                      type="text"
                      name="reportingStructure"
                      key="reportingStructure"
                      className={
                        (formData?.reportingStructure?.errorText
                          ? "error"
                          : "") +
                        " form-control modal-form-input small-text-medium-gray text-dark"
                      }
                      placeholder="write here"
                      defaultValue={
                        reportStructure1 !== null
                          ? reportStructure1?.toString().split(",")[0]
                          : repotvalue[0]
                          ? reportStructure1 === null
                          : ""
                      }
                      // defaultValue={reportStructure1}
                      // onChange={($event) => {
                      //   onInputReportingStructure(
                      //     "reportingStructure",
                      //     $event.target.value
                      //   );
                      // }}
                      onChange={($event) => {
                        onInputChange(
                          "reportingStructure",
                          $event.target.value
                        );
                      }}
                    />
                    {formData?.reportingStructure?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.reportingStructure?.errorText}
                      </div>
                    )}
                  </div>
                  <div className="mx-4 mt-3 mb-2">
                    <img src={downDottedArrow} alt="" />
                  </div>
                  <p className="fs-11 fw-600 mx-3">This Role</p>
                  <div className="mx-4 pt-0 mb-2">
                    <img src={downDottedArrow} alt="" />
                  </div>
                  <p className="fs-12 fw-400 mx-3 my-0">
                    This role will be reporting to
                  </p>
                  <div className="mx-3">
                    <input
                      type="text"
                      name="reportingRole"
                      className={
                        (formData?.reportingRole?.errorText ? "error" : "") +
                        " form-control modal-form-input small-text-medium-gray text-dark"
                      }
                      placeholder="write here"
                      defaultValue={formData?.reportingRole?.valueText}
                      // defaultValue={
                      //   reportingStructures !== null
                      //     ? reportingStructures.toString().split(",")[1]
                      //     : repotvalue[1]
                      //       ? reportingStructures === null
                      //       : ""
                      // }
                      onChange={($event) => {
                        onInputChange("reportingRole", $event.target.value);
                      }}
                    />
                    {formData?.reportingRole?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.reportingRole?.errorText}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* What Else */}
            <div className="d-flex justify-content-between flex-wrap my-4  col-12 gap-4">
              <div
                className="profile-box-shadow col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">What Else</h4>
                  <p className="back-button fs-11 fw-400 mt-0">
                    Add any additional information that the candidates need to
                    know before applying
                  </p>
                  <textarea
                    type="text"
                    // className={(formData?.additionalRemarks?.errorText ? "error" : "") +"form-control modal-form-input small-text-medium-gray align-self-start"}
                    // style={{ minHeight: "80px" }}
                    defaultValue={formData?.additionalRemarks?.valueText}
                    name="additionalRemarks"
                    key="additionalRemarks"
                    onChange={($event) => {
                      onInputChange("additionalRemarks", $event.target.value);
                    }}
                    className={
                      (formData?.additionalRemarks?.errorText ? "error" : "") +
                      " form-control modal-form-input small-text-medium-gray align-self-start"
                    }
                  />
                  {formData?.additionalRemarks?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.additionalRemarks?.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Additional Questions to Candidates */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div className="profile-box-shadow mt-1 mb-3 col">
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Additional Questions to Candidates
                  </h4>
                  <p className="back-button fs-11 fw-400 mt-0">
                    Add any additional questions for the candidate to apply for
                    this role
                  </p>
                  <div className="d-flex gap-2 pointer mt-0 mb-2">
                    <img
                      src={icPlusIcon}
                      alt="plus-icon"
                      onClick={() =>
                        AddQuestionsFields(jobQuestionsFields?.length)
                      }
                    />
                    <div
                      className="fs-12 color-primary fw-700"
                      onClick={() =>
                        AddQuestionsFields(jobQuestionsFields?.length)
                      }
                    >
                      Add Question
                    </div>
                  </div>
                  {/* jobQuestionsFields?.filter((check,i )=> check.action === "DELETE") && */}
                  {jobQuestionsFields?.map((input, i) => {
                    return (
                      <>
                        <div className="d-xs-grid d-sm-flex mt-4 align-items-baseline gap-3">
                          <h4 className="fs-14 fw-700 me-4">
                            Question {i + 1}
                          </h4>
                          <div className="form-check " key={i}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`jobQuestionsFields${i}`}
                              defaultChecked={
                                input?.quesType == "F_T" ? true : false
                              }
                              checked={input.quesType == "F_T" ? true : false}
                              onChange={(event) => {
                                let copyJobQuestionsFields = [
                                  ...jobQuestionsFields,
                                ];
                                if (event.target.checked === true) {
                                  copyJobQuestionsFields[i].quesType = "F_T";
                                } else {
                                  copyJobQuestionsFields[i].quesType = "Y_N";
                                }
                                setJobQuestionsFields(copyJobQuestionsFields);
                                // QuestionsFieldsOutput()
                              }}
                            />
                            <label
                              className=" fs-14 fw-400 form-check-label"
                              for="exampleRadios1"
                            >
                              Freeform as answer
                            </label>
                          </div>
                          <div className="form-check mx-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`jobQuestionsFields${i}`}
                              defaultChecked={
                                input?.quesType == "Y_N" ? true : false
                              }
                              checked={input.quesType == "Y_N" ? true : false}
                              onChange={(event) => {
                                let copyJobQuestionsFields = [
                                  ...jobQuestionsFields,
                                ];
                                if (event.target.checked === true) {
                                  copyJobQuestionsFields[i].quesType = "Y_N";
                                } else {
                                  copyJobQuestionsFields[i].quesType = "F_T";
                                }
                                setJobQuestionsFields(copyJobQuestionsFields);
                              }}
                            />

                            <label
                              className="fs-14 fw-400 form-check-label"
                              for="exampleRadios2"
                            >
                              Yes/No as answer
                            </label>
                          </div>
                        </div>
                        <div className="form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={input.isMandatory === "Y"}
                            onChange={(event) => {
                              let copyJobQuestionsFields = [
                                ...jobQuestionsFields,
                              ];
                              if (event.target.checked === true) {
                                copyJobQuestionsFields[i].isMandatory = "Y";
                              } else {
                                copyJobQuestionsFields[i].isMandatory = "N";
                              }

                              setJobQuestionsFields(copyJobQuestionsFields);
                            }}
                          />
                          <label
                            className=" fs-14 fw-400 form-check-label"
                            for="exampleRadios1"
                          >
                            Mandatory to answer
                          </label>
                        </div>
                        <div className=" d-flex   pb-2 align-items-center mt-3">
                          <input
                            type="text"
                            maxLength={Character}
                            // className="form-control modal-form-input small-text-medium-gray text-dark"
                            className={
                              (formData?.questionText?.errorText
                                ? "error"
                                : "") +
                              " form-control modal-form-input small-text-medium-gray text-dark"
                            }
                            placeholder="Add Question"
                            name="jobQuestionsFields"
                            value={input.questionText}
                            onChange={(event) => {
                              let copyJobQuestionsFields = [
                                ...jobQuestionsFields,
                              ];
                              copyJobQuestionsFields[i].questionText =
                                event.target.value;
                              setJobQuestionsFields(copyJobQuestionsFields);
                            }}
                          />
                          <img
                            src={icDelete}
                            alt="delete-icon"
                            className="p-2 pointer"
                            onClick={() => {
                              // removeQuestionsFields();
                              onDelete(input, i);
                            }}
                          />
                          {formData?.questionText?.errorText && (
                            <div className="field-error mt-1">
                              {formData?.questionText?.errorText}
                            </div>
                          )}
                        </div>
                        <br />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
            <div>
              <p className="fs-24 fw-700 mt-4 p-0 m-0">Add Referral Criteria</p>
              <p className="fs-16 fw-400 mt-1 mb-2 p-0 m-0 color-tertiary">
                Target candidates with the following criteria:
              </p>
            </div>
            {/* Company */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Company</h4>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="col-12 col-sm-3 ">
                      <SearchComboBox
                        name="referralCriteriaCompany"
                        key="referralCriteriaCompany"
                        inputData={company}
                        defaultValue={refCriteriaCompany}
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box ${
                          formData?.referralCriteriaCompany?.errorText
                            ? "error"
                            : ""
                        } form-control`}
                        // inputCssClass={"modal-input combo-search-box"}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue fs-12"}
                        hideBadges={false}
                        onSelect={(event) => {
                          onInputChange("referralCriteriaCompany", event);
                        }}
                        isAllowUserDefined={true}
                      />
                      {formData.referralCriteriaCompany.errorText && (
                        <div className="field-error mt-1">
                          {formData.referralCriteriaCompany.errorText}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Industry */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Industry</h4>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="col-12 col-sm-3 ">
                      <SearchComboBox
                        name="referralCriteriaIndustry"
                        key="referralCriteriaIndustry"
                        inputData={industries}
                        defaultValue={refCriteriaIndustry}
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box ${
                          formData?.referralCriteriaIndustry?.errorText
                            ? "error"
                            : ""
                        } form-control`}
                        // inputCssClass={"modal-input combo-search-box"}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue fs-12"}
                        hideBadges={false}
                        // onSelect={JobIndustryValue}
                        onSelect={(event) => {
                          onInputChange("referralCriteriaIndustry", event);
                        }}
                        isAllowUserDefined={true}
                      />
                      {formData.referralCriteriaIndustry.errorText && (
                        <div className="field-error mt-1">
                          {formData.referralCriteriaIndustry.errorText}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Other */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-3 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Other</h4>
                  <div className="d-flex gap-2 pointer mt-3">
                    <img
                      src={icPlusIcon}
                      alt="plus-icon"
                      onClick={AddreferralCriteriaOtherFields}
                    />
                    <div
                      className="fs-12 color-primary fw-700"
                      onClick={AddreferralCriteriaOtherFields}
                    >
                      Add Another
                    </div>
                  </div>
                  {referralCriteriaOtherFields?.map((input, i) => {
                    return (
                      <div className="pt-3 d-flex   pb-2">
                        <input
                          id={i}
                          type={input.type}
                          name="referralCriteriaOther"
                          key="referralCriteriaOther"
                          value={input}
                          className={
                            (formData?.referralCriteriaOther?.errorText
                              ? "error"
                              : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Reason  ${i + 1} here`}
                          onChange={(e) => referralCriteriaOtherOutput(i, e)}
                          defaultValue={refCriteriaOther[i]}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removeReferralCriteriaOther(input, i)}
                        />
                        {formData?.referralCriteriaOther?.errorText && (
                          <div className="field-error mt-1">
                            {formData?.referralCriteriaOther?.errorText}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* Bottom btns */}
            <div className="d-md-flex justify-content-between flex-wrap align-items-center gap-1 mt-4 mb-5">
              <div className=" d-flex">
                <div className="col-6 ps-2 px-md-4 px-0">
                  <button
                    className="btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                    style={{ minWidth: "130px" }}
                    onClick={() => AutoSave_Draft_Publish("draft")}
                  >
                    Save Draft
                  </button>
                </div>
                <div className="col-6 ps-2">
                  <button
                    style={{
                      minWidth: "130px",
                      backgroundColor: "#1C1C1C",
                      color: "#FFFFFF",
                    }}
                    className="btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                    // className="btn btn-outline-black fs-14 fw-700  h-40 w-100"
                    onClick={() => {
                      AutoSave_Draft_Publish("save");
                      // if (autoSave) {
                      //   AutoSave_Draft_Publish("draft");
                      // }
                    }}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
            {/* End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateJobPostMainPage;
