import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import {
  isEmpty,
  onFormFeildsChange,
  validateField,
} from "../../utils/form_validators";
import checkedLogo from "../../assests/ic_selected16.svg";
import { checkedItemsHandler } from "../../utils/utils";
import profileImg from "../../assests/images/candidate-profile-pic.jpg";
import { addteam, getLoggedInUserMembers } from "../../_services/view.service";
import toaster from "../../utils/toaster";
import { REQUIRED } from "../../config/validation_patterns_constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { STATUS_SUCCESS } from "../../config/be_api_constants";

// HeLLO

const AddTeamDialog = ({
  show,
  close,
  setApiRefresh,
  setShowAddTeamDialog,
  currentMemberPageset,
  dataCountMembers,
  fetchMoreMembers,
  getTeamsList,
  setSwitchValue,
}) => {
  const [teamname, setTeamName] = useState();
  const [userIdList, setUserIdList] = useState([]);
  const [obj, setObj] = useState([]);
  const [usermemberlist, setUsermemberlist] = useState([]);

  const [formData, setFormData] = useState({
    teamname: {
      valueText: "",
      errorText: "",
      check: [REQUIRED],
    },
  });

  const checkHandler = (condition, item) => {
    if (condition) {
      setObj([...obj, item?.id]);
    } else {
      let index = obj.indexOf(item?.id);
      if (index >= 0) {
        // let copyObj = [...obj];
        // delete copyObj[index];
        setObj([...obj.splice(0, index), ...obj.splice(index + 1)]);

        // setObj(copyObj);
      }
    }
  };

  const getusermemberlist = () => {
    getLoggedInUserMembers({ pageNo: 0, pageSize: 0 })
      .then((res) => {
        setApiRefresh(true);

        setUsermemberlist(res?.data?.data);
      })
      .catch((err) => {
        setApiRefresh(false);
      });
  };

  useEffect(() => {
    if (show === true) {
      getusermemberlist();
    }
  }, [show]);

  const [selectedMemberList, setSelectedMemberList] = useState([]);

  const checkboxClickHandler = (index) => {
    checkedItemsHandler(index, selectedMemberList, setSelectedMemberList);
  };

  const [isError, setIsError] = useState({ TeamError: "" });

  const checkTeamNameValid = () => {
    let isValid = true;
    if (teamname === "") {
      setIsError({ TeamError: " Please Enter Team Name " });
      isValid = false;
    } else {
      setIsError({
        TeamError: "",
      });
      isValid = true;
    }
    //   setFormData(formData);
    return isValid;
  };
  const [showLoader, setShowLoader] = useState(false);

  const saveteam = () => {
    if (teamname === "") {
      toaster("error", "Please Enter Team Name");
    } else {
      // if (checkTeamNameValid()) {
      // let isValid = true;

      // Object.keys(formData)?.forEach((key) => {
      //   if (!validateField(key, formData, setFormData)) {
      //     isValid = false;
      //   }
      // });

      // if (isValid) {
        const name = formData?.teamname?.valueText;
        //const request = { teamname: formData?.teamname?.valueText };
        
        addteam(teamname, obj, name)
          .then((res) => {
            setShowLoader(true);
            if (res.data.status === STATUS_SUCCESS) {
              toaster("success", res?.data?.message);
                
            // setShowAddTeamDialog();
            } else {
              toaster("error", res.data.message);
            } close();
            setSwitchValue();
            setShowLoader(false);
             setTeamName("");
            
            // getTeamsList();
            // setSwitchValue();
          })
          .catch((err) => {
            setShowLoader(false);
            toaster("error", err);
          });
      // }
    }
  };


  return (
    <div>
      {" "}
      <Modal
        show={show}
        onHide={() => close()}
        centered
        fullscreen="lg-down"
        backdrop="static"
        keyboard={false}
        className=" p-0"
        // contentClassName="modal-content-custom"
      >
        <Modal.Header className=" border-0" bsPrefix="modal-header-custom">
          <div className="fs-20 color-primary fw-700 px-2 pt-2">
            Add New Team
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            onClick={() => close()}
            className="pointer me-2 mt-2"
            height="25px"
            width="25px"
          />
        </Modal.Header>
        <Modal.Body className="p-0 pb-2">
          <div className="">
            <div className=" px-3">
              <div className="fs-14 fw-600">Team name</div>
              <div className="py-2">
                <input
                  type="text"
                  name="teamname"
                  className={
                    (formData?.teamname?.errorText ? "error" : "") +
                    "form-control modal-form-input text-dark"
                  }
                  placeholder=""
                  onChange={($event) => {
                    onFormFeildsChange($event, formData, setFormData);
                    setTeamName($event.target.value);
                  }}
                />
                {/* <p className="field-error mt-1">
                    {isError.TeamError}
                  </p> */}
                <span className="field-error mt-1">
                  {formData?.teamname?.errorText}
                </span>
              </div>
            </div>

            <p className="fs-14 fw-600 px-3 pt-3 pb-0">Select members</p>
            <InfiniteScroll
              dataLength={currentMemberPageset}
              next={() => fetchMoreMembers()}
              hasMore={dataCountMembers > currentMemberPageset}
              className="container-fluid"
            >
              <ul
                className="m-0 p-0 overflow-auto"
                style={{ maxHeight: "300px" }}
              >
                {usermemberlist?.length > 0 ? (
                  usermemberlist?.map((item, index) => {
                    return (
                      <>
                        {item?.isDeleted ? (
                          <li
                            key={index}
                            className="px-3 d-flex fs-12 fw-400 border-bottom py-3 pointer align-items-center"
                            onClick={() => {
                              checkboxClickHandler(index);
                            }}
                          >
                            <input
                              type="checkbox"
                              className="pointer"
                              // checked={
                              //   selectedMemberList.includes(index) ? true : false
                              // }
                              onChange={(e) => {
                                
                                setUserIdList(e);
                              }}
                              onClick={(e) => {
                                
                              }}
                            />{" "}
                            <img
                              src={profileImg}
                              alt="profile"
                              className="rounded-circle ms-4"
                              width="32px"
                              height="32px"
                            />
                            <label className="fs-14 fw-600 ps-3 color-tertiary">
                              {item?.email}
                            </label>
                          </li>
                        ) : (
                          <li
                            key={index}
                            className="px-3 d-flex fs-12 fw-400 border-bottom py-3 pointer align-items-center"
                            onClick={() => {
                              checkboxClickHandler(index);
                            }}
                          >
                            <input
                              type="checkbox"
                              className="pointer"
                              checked={
                                selectedMemberList.includes(index)
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                checkHandler(e?.target?.checked, item);
                              }}
                            />{" "}
                            <img
                              src={profileImg}
                              alt="profile"
                              className="rounded-circle ms-4"
                              width="32px"
                              height="32px"
                            />
                            <label className="fs-14 fw-600 ps-3">
                              {item?.email}
                            </label>
                          </li>
                        )}
                      </>
                    );
                  })
                ) : (
                  <p className="color-tertiary">No members to add</p>
                )}
              </ul>
            </InfiniteScroll>
          </div>
        </Modal.Body>
       
        <Modal.Footer className="dialog-footer ">
          { formData?.teamname?.valueText == "" ? (
            <button
            className="btn-dialog-save pointer fs-12 fw-700 text-light border-0 "
            style={{ backgroundColor: "#B9B9B9" }}
              // className="btn-dialog-save pointer fs-12 fw-700"
              // onClick={() => {
              //   saveteam();
              //   // close();
              // }}
            >
              Add & Save
            </button>
          ): (
            <button
            className="btn-dialog-save pointer fs-12 fw-700"
              // className="btn-dialog-save pointer fs-12 fw-700 text-light border-0 "
              // style={{ backgroundColor: "#B9B9B9" }}
              onClick={() => {
                saveteam();
                //close();
              }}
            >
              Add & Save
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddTeamDialog;
