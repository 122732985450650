import { Link, useLocation, useNavigate } from "react-router-dom";
import mobileLogo from "../../assests/ic_seikor.svg";
import logo from "../../assests/ic_seikorfull.svg";
import logo2 from "../../assests/ic_skrcolor.svg";
import icHumburger from "../../assests/ic_humburger.svg";
import icNotifications from "../../assests/ic_notifications.svg";
import icChevronDown from "../../assests/ic_chevron_down24.svg";
import useWindowDimensions from "../../utils/use_window_dimension";
import { useState, useRef, useEffect } from "react";
import logo_airbnb from "../../assests/images/airbnblogo.png";
import Modal from "react-bootstrap/Modal";
import HeaderDropDown from "./header_drop_down";
import { PREV_NOTIFICATION_COUNT } from "../../config/localstorage_keys";
import { NOTIFICATIONS_UPDATE_MESSAGE } from "../../config/messages";

import {
  ALL_JOBS_PAGE_ROUTE,
  CANDIDATE_MY_VIEW_PAGE_ROUTE,
  NAVIGATION_SIDEBAR_ROUTE,
  RECOMMENDED_JOBS_PAGE_ROUTE,
} from "../../config/page_routes_constants";
import Notifications from "../notifications_dropdown";
import NotificationsDropdown from "../notifications_dropdown";
import { useStoreActions, useStoreState } from "easy-peasy";
import ProfileImage from "./../profile_image";
import {
  downloadFile,
  getAllJobDraft,
  getjobSummary,
  getNotifications,
} from "../../_services/view.service";
import { isEmpty } from "../../utils/form_validators";

import { NOTIFICATION_REFRESH_INTERVAL_IN_MINUTES } from "../../config/config";
import { USER_ROLE } from "../../config/be_api_constants";
import CompanyImage from "../company_image";
import MobileViewModal from "./navigation_sidebar_mobile_view";
import toaster from "../../utils/toaster";
import {
  LOG_TO_ORG,
  ORG_ID,
  ORG_LOGO,
  STATUS_SUCCESS,
  USER_ID,
} from "../../config/localstorage_keys";
import {
  getLocalStorage,
  getSessionStorage,
  setLocalStorage,
  setSessionStorage,
} from "../../utils/storage";
import {
  allOrganization,
  getCompanyDetails,
} from "../../_services/company.service";
import Loader from "./loader";
import { getAllJobPost } from "../../_services/serikoradmin_view.service";

const Header = () => {
  const BREAKPOINT_COLLAPSING_MENU = 1000;
  const BREAKPOINT_SHOWING_MOBILE_LOGO = 600;
  const { height, width } = useWindowDimensions();
  const [showDropDown, setShowDropDown] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [userInitials, setUserInitials] = useState("");
  const [profileSrc, setProfileSrc] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [notification, setNotification] = useState();
  const [jobMessage, setJobMessage] = useState();
  const [apiRefresh, setApiRefresh] = useState(false);
  const location = useLocation();
  const [allOrg, setAllOrg] = useState([]);
  // const role = useStoreState((state) => state?.candidate?.role);
  const role = localStorage.getItem("role");
  const log_to_org = localStorage.getItem(LOG_TO_ORG);
  const [total_Jobs, setTotal_Jobs] = useState("");
  const [total_DraftJobs, setTotal_DraftJobs] = useState("");
  const [total_SeikorAdminJobs, setTotal_SeikorAdminJobs] = useState("");

  // Notification outside click close function
  const notificationRef = useRef();
  const dropDownRef = useRef();

  const candidateDetails = useStoreState(
    (state) => state?.candidate?.candidateDetails
  );
  const orgLogo = getLocalStorage(ORG_LOGO);
  // const closeOpenNotification = (event) => {
  //   if (
  //     showNotification !== undefined &&
  //     notificationRef !== undefined &&
  //     !notificationRef?.current?.contains(event.target)
  //   ) {
  //     setShowNotification(false);
  //   }
  // };
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const [companyData, setCompanyData] = useState([]);
  const fetchDetails = (searchString) => {
    setShowLoader(true);
    setApiRefresh(true);
    getCompanyDetails()
      .then((res) => {
        if (res?.data?.status == STATUS_SUCCESS) {
          const userId = getLocalStorage(USER_ID);
          if (userId) {
            saveCandidateDetails(userId);
          }
          let companyData = res?.data?.data[0];
          setCompanyData(companyData);
          setApiRefresh(false);
          setShowLoader(false);
        }
        // else {
        //   toaster("error", "Company data is not available");
        //   // setApiRefresh(false);
        // }
      })
      .catch((error) => {
        setShowLoader(false);
      });
    // setApiRefresh(true);
    getjobSummary({ pageNo: 0, pageSize: 0 })
      .then((res) => {
        setTotal_Jobs(res?.data?.totalRecord);
        setShowLoader(false);
        // setApiRefresh(false);
      })
      .catch((error) => {
        setShowLoader(false);
        // setApiRefresh(false);
      });
    // setApiRefresh(true);
    getAllJobDraft({ pageNo: 0, pageSize: 0 })
      .then((res) => {
        setTotal_DraftJobs(res?.data?.totalRecord);
        setShowLoader(false);
        // setApiRefresh(false);
      })
      .catch((error) => {
        // setApiRefresh(false);
        setShowLoader(false);
      });
    setApiRefresh(true);
    getAllJobPost({ pageNo: 0, pageSize: 0 })
      .then((res) => {
        setTotal_SeikorAdminJobs(res?.totalCount);
        setShowLoader(false);
        // setApiRefresh(false);
      })
      .catch((error) => {
        // setApiRefresh(false);
        setShowLoader(false);
      });
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  useEffect(() => {
    // fetchDetails();
    if (apiRefresh === true) {
      fetchDetails();
      setApiRefresh(false);
    }
  }, [apiRefresh]);

  const notificationHandler = () => {
    setShowNotification(!showNotification);
  };
  const dropdownRef = useRef(null);

  const closeOpenProfileDropdown = (event) => {
    if (
      showDropDown !== undefined &&
      dropdownRef !== undefined &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowDropDown(false);
    }
  };

  const profileDropdownHandler = () => {
    setShowDropDown(!showDropDown);
  };

  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        setProfileSrc(res);
      } else {
        setProfileSrc(null);
      }
    });
  };

  let pageSize = 10;
  const navigate = useNavigate();
  // notification api call
  const fetchNotification = (pageNo, pageSize) => {
    setShowLoader(true);
    getNotifications(pageNo, pageSize).then(
      (res) => {
        if (!isEmpty(res) && res?.status === 200) {
          setNotification(res?.data?.data);
          setShowLoader(false);
          setNotificationCount(res?.data?.newRecordCount);
          const previousNotificationCount = getSessionStorage(
            PREV_NOTIFICATION_COUNT
          );
          if (previousNotificationCount < res?.data?.totalRecord) {
            toaster("success", NOTIFICATIONS_UPDATE_MESSAGE);
          }
          setSessionStorage(PREV_NOTIFICATION_COUNT, res?.data?.totalRecord);
        } else {
          setNotification([]);
          setJobMessage(res?.data?.message);
        }
      },
      (error) => {
        setShowLoader(false);
        toaster("error", error);
      }
    );
  };

  useEffect(() => {
    fetchNotification(1, 10);
    const interval = setInterval(() => {
      getNotifications(1, pageSize);
    }, NOTIFICATION_REFRESH_INTERVAL_IN_MINUTES * 1000);
    return () => clearInterval(interval);
  }, []);
  //  useEffect(()=>{
  //   openToNewTab();
  //  },[role])
  useEffect(() => {
    if (candidateDetails?.adminUserResponse) {
      downloadPicture(
        candidateDetails?.adminUserResponse?.profilePicDownloadUrl
      );
    }
    let candidateName = candidateDetails?.adminUserResponse?.name;
    let candidateInitials =
      candidateName?.split(" ")[0]?.charAt(0)?.toUpperCase() +
      candidateName?.split(" ")[1]?.charAt(0)?.toUpperCase();
    setUserInitials(candidateInitials);
  }, [candidateDetails]);

  const BackTab = (orgId) => {
    setLocalStorage(ORG_ID, orgId);
    const temp_role = localStorage.getItem("temp_role");
    localStorage.setItem("role", temp_role);
    localStorage.setItem("log_to_org", "false");
    localStorage.setItem("orgId", "null");
    localStorage.setItem("orgLogo", "");

    navigate("/");
  };

  // useEffect(() => {
  //   toaster("success", "New notification recieved", 3000);
  // }, [notificationCount]);

  return (
    <>
      {showLoader && <Loader />}
      <nav className="navbar fixed-top navbar-expand-lg header-container p-0">
        <div className="container py-0">
          <div className="d-flex pointer my-2 ps-sm-1 ">
            <img
              src={icHumburger}
              alt=""
              className={
                width >= BREAKPOINT_COLLAPSING_MENU
                  ? "d-none"
                  : "d-block me-2 pointer"
              }
              onClick={() => {
                setFullscreen(true);
                setShow(true);
              }}
            />
            <Link className="navbar-brand" to={NAVIGATION_SIDEBAR_ROUTE}>
              <img
                style={{ height: "25px" }}
                src={width < BREAKPOINT_SHOWING_MOBILE_LOGO ? logo2 : logo2}
                alt=""
              />
            </Link>
          </div>

          <div className="d-flex align-items-center gap-2 ">
            {log_to_org === "true" ? (
              <div className="link position-relative ">
                <button
                  className="text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark org-hover-btn"
                  onClick={() => BackTab()}
                >
                  Go Back
                </button>
              </div>
            ) : (
              ""
            )}
            {/* Notification Icon */}

            <div className="d-flex align-items-center" ref={notificationRef}>
              <div
                className="d-flex notification-button position-relative"
                data-bs-toggle="notification"
                role="button"
                aria-expanded="false"
                aria-controls="notification"
              >
                <div
                  className="d-flex position-relative"
                  onClick={() => {
                    notificationHandler();
                  }}
                >
                  <img src={icNotifications} alt="" />
                  <div
                    className={
                      notificationCount > 0
                        ? "count-badge d-flex justify-content-center align-items-center d-block"
                        : "d-none count-badge "
                    }
                  >
                    {notificationCount}
                  </div>
                </div>

                <NotificationsDropdown
                  parentRef={notificationRef}
                  show={showNotification}
                  onNotoficationDropdownClose={() => setShowNotification(false)}
                  notification={notification}
                  notificationUpdate={setNotification}
                  onNotificationRefreshNeeded={() => fetchNotification()}
                />
              </div>
            </div>

            {/* Username and dropdown */}
            <div className="position-relative" ref={dropDownRef}>
              <div
                className="px-0 px-sm-1 py-1 d-flex align-items-center border rounded pointer gap-1"
                onClick={() => profileDropdownHandler()}
              >
                {/* <img className="profile-pic me-1" src={userInitials} alt="" /> */}
                <div className="defaultProfileDiv profile-pic">
                  <ProfileImage
                    src={profileSrc}
                    name={candidateDetails?.adminUserResponse?.name}
                    initialsContainerClass="initialsStyle2"
                  />
                </div>
                <div className="text-capitalize header-ellipse-1 header-name-width">
                  {candidateDetails?.adminUserResponse?.name}
                </div>

                <img className="pointer mx-1" src={icChevronDown} alt="" />
              </div>
              {/* <div
                className={
                  showDropDown ? "notification-wrapper d-block" : "d-none"
                }
                onClick={closeOpenProfileDropdown}
              ></div> */}
              {/* <div ref={dropdownRef}> */}
              {showDropDown && (
                <HeaderDropDown
                  show={showDropDown}
                  onProfileDropdownClose={() => setShowDropDown(false)}
                  parentRef={dropdownRef}
                />
              )}
              {/* </div> */}
              <MobileViewModal
                show={show}
                setShow={setShow}
                fullscreen={fullscreen}
                total_Jobs={total_Jobs}
                total_DraftJobs={total_DraftJobs}
                total_SeikorAdminJobs={total_SeikorAdminJobs}
              />
            </div>
            {(role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER) && (
              <div className="p-2 rounded border d-none d-sm-block">
                {/* <img
                src={`data:image/jpeg;base64 , ${companyData?.companyLogo}`}
                width="23px"
                height="23px"
                initialsContainerClass="initialsStyle2-xl"
              /> */}
                <img
                  src={`data:image/jpeg;base64 , ${orgLogo}`}
                  width="23px"
                  height="23px"
                  initialsContainerClass="initialsStyle2-xl"
                />
              </div>
            )}
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;
