import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "../admin_all_referees/styles.scss";
import downArrow from "../../../assests/downArrow.svg";
import blackdone from "../../../assests/icons/ic_blackdone.svg";
import filterIcon from "../../../assests/icons/ic_sort.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import funnelFilterIcon from "../../../assests/icons/ic-filter.svg";
import searchIcon from "../../../assests/icons/ic-search-16.svg";
import RefereeBarChart from "../../../components/referee_bar_chart";
import RefereeProfile from "../../../dialogs/application_dialogs/referee_profile";
import { Link, useSearchParams } from "react-router-dom";
import { REFEREE_DETAILS_PAGE_ROUTE } from "../../../config/page_routes_constants";
import {
  checkedItemsHandler,
  convertToInternationalCurrencySystem,
} from "../../../utils/utils";
import AdminRefereeSortDialog from "../../../dialogs/admin_referees_dialogs/admin_referee_sort_dialog";
import AdminRefereeFilterDialog from "../../../dialogs/admin_referees_dialogs/admin_referee_filter_dialog";
import checkedLogo from "../../../assests/ic_selected16.svg";
import toaster from "../../../utils/toaster";
import { isEmpty } from "../../../utils/form_validators";
import {
  refereeSummary,
  referreList,
} from "../../../_services/member-profile.service";
import ProfileImage from "../../../components/profile_image";
import { DEFAULT_PAGE_SIZE } from "../../../config/config";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import InfiniteScroll from "react-infinite-scroll-component";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";
import Loader from "../../../components/common/loader";
import { getRefereeProfile } from "../../../_services/view.service";

function AdminAllReferees() {
  let isInfinite = false;
  const [arraySortIndex, setArraySortIndex] = useState(0);
  const [showRefereeProfile, setShowRefereeProfile] = useState(false);
  const [arrayRefereeIndex, setArrayRefereeIndex] = useState([]);
  const [sortTableShow, setSortTableShow] = useState(false);
  const [filterRefereeShow, setFilterRefereeShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [refreelist, setrefreeList] = useState([]);
  const [summary, setSummary] = useState([]);
  const [totalcount, setTotalCount] = useState();
  const [apiRefresh, setapiRefresh] = useState(false);
  const [allJobMessage, setAllJobMessage] = useState("");
  const [list, setList] = useState([]);
  const [userId, setUserId] = useState();
  const [refereeProfile, setRefereeProfile] = useState([]);
  const [quickFilters, setQuickFilters] = useState([
    {
      name: "Conversion",
      value: "CONVERSION",
    },
    {
      name: "Referrals",
      value: "MOST_REFERRALS",
    },
  ]);
  const [roleOutput, setRoleOutput] = useState("Conversion");

  const sortingArray = [
    { name: "Conversion", value: "CONVERSION" },
    { name: "Most Referrals", value: "MOST_REFERRALS" },
    { name: "Most Applications", value: "MOST_APPLICATIONS" },
    { name: "Most Joinees", value: "MOST_JOINESS" },
    { name: "Bonus Amount", value: "BONUSAMOUNT" },
    { name: "A-Z", value: "A_Z" },
  ];
  const [topArraySortIndex, setTopArraySortIndex] = useState(null);
  const [daysArraySortIndex, setDaysArraySortIndex] = useState(null);

  const [analyticsSortByDropdownRedDot, setAnalyticsSortByDropdownRedDot] =
    useState("d-none");
  // const [referralsDropdownRedDot, setReferralsDropdownRedDot] =
  //   useState("d-none");
  // const [
  //   insightsSortByDropdownRedDot,
  //   setInsightsSortByDropdownRedDot,
  // ] = useState("d-none");

  useEffect(() => {
    if (arraySortIndex == null) {
      setAnalyticsSortByDropdownRedDot("d-none");
    } else {
      setAnalyticsSortByDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  // // useEffect(() => {
  // //   if (topArraySortIndex == null) {
  // //     setReferralsDropdownRedDot("d-none");
  // //   } else {
  // //     setReferralsDropdownRedDot("d-block");
  // //   }
  // }, [topArraySortIndex]);

  // useEffect(() => {
  //   if (daysArraySortIndex == null) {
  //     setInsightsSortByDropdownRedDot("d-none");
  //   } else {
  //     setInsightsSortByDropdownRedDot("d-block");
  //   }
  // }, [daysArraySortIndex]);

  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const [dataCountReferees, setDataCountReferees] = useState(0);
  const [currentRefereesPageSet, setCurrentRefereesPageSet] = useState(0);
  const [allRefsFilterData, setAllRefsFilterData] = useState(initialFilterData);
  const [allRefsFilterDataParams, setAllRefsFilterDataParams] = useSearchParams(
    {
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    }
  );

  const RefereeSummary = () => {
    // setShowLoader(true);
    refereeSummary().then(
      (res) => {
        if (!isEmpty(res)) {
          setSummary(res?.data?.data);
        } else {
          setSummary([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };

  let bonusDisbursed = convertToInternationalCurrencySystem(
    summary?.bonusDisbursed,
    "INR"
  );

  let averageBonus = convertToInternationalCurrencySystem(
    summary?.averageBonus,
    "INR"
  );

  const RefereeList = (data) => {
    setShowLoader(true);
    referreList(data).then(
      (res) => {
        console.log("res---", res?.data?.totalRecord);
        if (res?.data.status === STATUS_SUCCESS) {
          setDataCountReferees(res?.data?.totalRecord);
          toaster("success", "Fetch referees successfully!!");
          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...refreelist, ...res?.data?.data];
          }
          setCurrentRefereesPageSet(data?.length);
          setrefreeList(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
          setList(res?.data?.data[0]);
        } else {
          setShowLoader(false);
          toaster("error", " Referees is not available");
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };
  console.log(
    "checkeddddd",
    dataCountReferees,
    currentRefereesPageSet,
    dataCountReferees > currentRefereesPageSet ? true : false
  );

  const fetchMoreReferees = () => {
    setCurrentRefereesPageSet(currentRefereesPageSet + 10);
    isInfinite = true;
    RefereeList({
      ...allRefsFilterData,
      pageNo: allRefsFilterData?.pageNo + 1,
    });
    setAllRefsFilterData({
      ...allRefsFilterData,
      pageNo: allRefsFilterData?.pageNo + 1,
    });
  };

  // useEffect(() => {
  // }, [apiRefresh]);

  // useEffect(() => {
  //   let temp = { ...initialFilterData };
  //   for (let entry of allRefsFilterDataParams.entries()) {
  //     if (entry[0] === "searchString") {
  //       temp = {
  //         ...temp,
  //         searchString: entry[1],
  //       };
  //     } else if (entry[0] === "index") {
  //       setArraySortIndex(Number(entry[1]));
  //     } else if (entry[0] === "sortBy") {
  //       temp = {
  //         ...temp,
  //         sortBy: entry[1],
  //       };
  //     }
  //   }
  //   RefereeList(temp);
  //   setAllRefsFilterData(temp);
  // }, []);

  const dropdownSortHandler = (index, value) => {
    setArraySortIndex(index);
    setAllRefsFilterData({
      ...allRefsFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    RefereeList({
      ...allRefsFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const submitFormOnEnter = (e) => {
    console.log("search", e);
    if (e?.keyCode == 13) {
      setAllRefsFilterData(allRefsFilterData);
      RefereeList(allRefsFilterData);
    }
  };

  // useEffect(() => {
  //   // RefereeList(allRefsFilterData);
  // }, []);

  const refereeBarChartData = {
    referrals: summary?.referrals,
    applications: summary?.applications,
    joinees: summary?.joinees,
    joineesPercentage: summary?.joineesPercentage,
  };

  console.log("refereeBarChartData", refereeBarChartData);

  const dropdownRefereeArray = [
    "Have Conversions",
    "Have made applications",
    "Have employer notes",
  ];

  const dropdownAllTeamsHandler = (index) => {
    checkedItemsHandler(index, arrayRefereeIndex, setArrayRefereeIndex);
  };

  const ViewReferee = (userId) => {
    getRefereeProfile(userId)?.then((res) => {
      setRefereeProfile(res?.data?.data);
    });
  };

  const onview = (userId) => {
    setShowRefereeProfile(true);
    setUserId(userId);
    ViewReferee(userId);
  };
  useEffect(() => {
    RefereeList(allRefsFilterData);

    if (apiRefresh === true) {
      setapiRefresh(false);
    }
    RefereeSummary();
  }, [apiRefresh, showRefereeProfile]);

  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin">
        <div className="fw-700 fs-24 mt-4">Referees({dataCountReferees})</div>
        <div className="d-flex justify-content-lg-between flex-column flex-sm-row flex-wrap cards-graph-container py-2 mt-4">
          <div className="flex-1">
            <div className="title-cards ps-3 d-flex align-items-end me-2">
              <div>
                <div className="fw-700 fs-20">
                  ₹
                  {bonusDisbursed.toString().length > 3
                    ? bonusDisbursed.toString().substring(0, 4) +
                      bonusDisbursed
                        ?.toString()
                        ?.slice(
                          bonusDisbursed?.length - 1,
                          bonusDisbursed?.length
                        )
                    : bonusDisbursed.toString()}
                  {/* {bonusDisbursed.toString().substring(0,4) + bonusDisbursed?.toString()?.slice(bonusDisbursed?.length - 1, bonusDisbursed?.length)} */}
                </div>
                <div className="small-text-gray fw-400 fs-14 mt-1 mb-4">
                  Bonus Disbursed
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 mt-3 mt-sm-0">
            <div className="title-cards ps-3 d-flex align-items-end me-2">
              <div>
                <div className="fw-700 fs-20">
                  ₹
                  {averageBonus.toString().length > 3
                    ? averageBonus.toString().substring(0, 4) +
                      averageBonus
                        ?.toString()
                        ?.slice(averageBonus?.length - 1, averageBonus?.length)
                    : averageBonus.toString()}
                  {/* ₹ {averageBonus.toString().substring(0,4) + averageBonus?.toString()?.slice(averageBonus?.length - 1, averageBonus?.length)} */}
                </div>
                <div className="small-text-gray fw-400 fs-14 mt-1 mb-4">
                  Average bonus
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className=" ms-2">
              {/* Graph Component */}

              <RefereeBarChart refereeBarChartData={refereeBarChartData} />
            </div>
          </div>
        </div>
        <div className="filter-search-container  d-md-flex justify-content-between mt-5 w-100">
          <div className="d-flex">
            <Dropdown className="timeline-select-dropdown me-3 h-28">
              <Dropdown.Toggle
                variant="none"
                // id="dropdown-basic"
                className="btn down-arrow referee-filter-button d-flex py-1 px-2 bg-transparent align-items-center d-flex justify-content-around referee-btn-focus"
              >
                <img src={filterIcon} alt="" className="" />
                <span className="fs-12">Sort By</span>
                <span
                  className={`${analyticsSortByDropdownRedDot} round-red-dot rounded-circle mb-2`}
                ></span>
                <img className="" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                {sortingArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setArraySortIndex(index);
                        if (arraySortIndex == index) {
                          dropdownSortHandler(null);
                        } else {
                          dropdownSortHandler(index, sortItem.value);
                        }
                      }}
                      key={index}
                      className="d-flex pb-3"
                    >
                      <img
                        src={blackdone}
                        alt="blackdone-icon"
                        className={
                          arraySortIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          arraySortIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* <Dropdown className="timeline-select-dropdown" autoClose="outside">
              <Dropdown.Toggle
                variant="none"
                // id="dropdown-basic"
                className="btn down-arrow referee-filter-button d-flex py-1 px-2 bg-transparent align-items-center d-flex justify-content-around"
              >
                <img src={funnelFilterIcon} alt="" className="" />
                <span className="fs-12">Filters</span>
                <img className="" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary ">
                {dropdownRefereeArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item key={index}>
                      <div
                        className="d-flex pb-3"
                        style={{ zIndex: 1000 }}
                        onClick={(e) => {
                          dropdownAllTeamsHandler(index);
                        }}
                      >
                        <input
                          type="checkbox"
                          className="me-2"
                          id={index}
                          name={index}
                          checked={
                            arrayRefereeIndex.includes(index) ? true : false
                          }
                          onClick={(e) => {
                            dropdownAllTeamsHandler(index);
                          }}
                        />{" "}
                        <label>{sortItem}</label>
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown> */}
            <div className="flex-wrap gap-2 d-none d-xl-flex h-28 referee-btn-focus">
              {quickFilters?.map((output, index) => {
                return (
                  <div
                    className="fs-12   h-28 d-flex align-items-center px-2 gap-1 border  rounded-pill pointer referee-btn-focus"
                    key={index}
                    onClick={() => {
                      dropdownSortHandler(index, output.value);
                      setRoleOutput(`${output.name}`);
                    }}
                  >
                    {roleOutput.includes(output.name) ? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      ></img>
                    ) : (
                      ""
                    )}

                    <div>{output.name} </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="page-search d-flex col-12 col-md-5 col-lg-4 h-32 mt-3 mt-md-0 referee-btn-focus">
            <input
              className=" job-post-search col-12 h-32 referee-btn-focus"
              type="text"
              placeholder="Search"
              defaultValue={allRefsFilterData?.searchString}
              onChange={(event) => {
                setAllRefsFilterData({
                  ...allRefsFilterData,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
            />
          </div>
        </div>
        {/* Table */}
        <InfiniteScroll
          dataLength={currentRefereesPageSet}
          next={() => fetchMoreReferees()}
          hasMore={dataCountReferees > currentRefereesPageSet}
          className="container-fluid"
        >
          <div className="table-responsive referee-table mt-4 mb-5">
            <table className="justify-content-between table ">
              <thead className="w-100 ">
                <tr className="">
                  <th
                    className="ps-4 gap-2 text-left "
                    style={{ width: "175px" }}
                  >
                    <div className="fs-12 fw-700 py-3">Referee</div>
                  </th>
                  <th className="gap-2 text-center  " style={{ width: "80px" }}>
                    <div className="fs-12 fw-700 py-3">Referrals</div>
                  </th>
                  <th className="gap-2 text-center  " style={{ width: "80px" }}>
                    <div className="fs-12 fw-700  py-3">Applications</div>
                  </th>
                  <th className="gap-2 text-center  " style={{ width: "80px" }}>
                    <div className="fs-12 fw-700 py-3">Joinees</div>
                  </th>
                  <th className="gap-2 text-center " style={{ width: "80px" }}>
                    <div className="fs-12 fw-700 py-3">Conversion</div>
                  </th>
                  <th className="gap-2 text-left " style={{ width: "200px" }}>
                    <div className="fs-12 fw-700 ms-4 py-3">Bonus</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {refreelist && refreelist?.length > 0
                  ? refreelist.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            className="card-parent fs-14 position-relative"
                          >
                            <td className="ps-4 py-3">
                              <div className="d-flex gap-2 align-items-center">
                                <div>
                                  {/* {item.profilePIc === "" ||
                                  item.profilePIc === undefined ? (
                                    <ProfileImage
                                      src={profileImg}
                                      width="40px"
                                      height="40px"
                                      initialsContainerClass="rounded-circle"
                                    />
                                  ) : ( */}
                                  <ProfileImage
                                    src={
                                      process.env.REACT_APP_IMAGE_BASEURL +
                                      item.profilePIc
                                    }
                                    width="40px"
                                    height="40px"
                                    initialsContainerClass="rounded-circle"
                                  />
                                  {/* )} */}
                                </div>
                                <div>
                                  <div
                                    className="fs-12 fw-400"
                                    style={{ color: "#262626" }}
                                  >
                                    {item?.name}
                                  </div>
                                  <div className="small-text-gray fs-12 fw-300">
                                    {item?.designation}
                                  </div>
                                  <div className="small-text-gray  fs-12 fw-300">
                                    {item?.company}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center  fs-14 fw-400">
                              <div>{item?.referrals}</div>
                            </td>
                            <td className="align-middle text-center  fs-14 fw-400">
                              <div>{item?.applications}</div>
                            </td>
                            <td className="align-middle text-center  fs-14 fw-400">
                              <div> {item?.joiness}</div>
                            </td>
                            <td className="align-middle text-center  fs-14 fw-400">
                              <div> {item?.conversionPerc}%</div>
                            </td>
                            <td className="align-middle text-left position-relative">
                              <div className="ms-4  fs-14 fw-400">
                                {" "}
                                {item?.bonus}
                              </div>
                              <div className="fw-700 ms-4 fs-12 admin-referee-red-color">
                                {item?.payment_status}
                              </div>
                              <div className="onhover-show3">
                                <div className="d-flex align-items-center ">
                                  <div
                                    onClick={() => {
                                      // setShowRefereeProfile(true);
                                      // setUserId(item.userId);
                                      onview(item?.userId);
                                    }}
                                    className="link"
                                  >
                                    <button className="text-decoration-none btn btn-outline-dark fs-12 fw-700 text-dark bg-light px-3 py-2 referee-btn-focus">
                                      View Profile
                                    </button>
                                  </div>
                                  <div className="link ms-2">
                                    <Link
                                      to={
                                        REFEREE_DETAILS_PAGE_ROUTE +
                                        `/${item.userId}`
                                      }
                                      className=" text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark px-3 py-2"
                                    >
                                      Details
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : "No Data Found"}
              </tbody>
            </table>
          </div>
          {/* Table end */}
          {/* Small Table start */}
          <div className="mt-4 mb-5 d-md-none">
            {refreelist && refreelist?.length > 0
              ? refreelist?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="position-relative card-parent-bg-none align-items-center mt-3 py-2 p-3 border-bottom"
                    >
                      <div>
                        <span>
                          {/* {item.profilePIc === "" ||
                          item.profilePIc === undefined ? (
                            <ProfileImage
                              src={profileImg}
                              width="40px"
                              height="40px"
                              initialsContainerClass="rounded-circle"
                            />
                          ) : ( */}
                          <ProfileImage
                            src={
                              process.env.REACT_APP_IMAGE_BASEURL +
                              item.profilePIc
                            }
                            width="40px"
                            height="40px"
                            initialsContainerClass="rounded-circle"
                          />
                          {/* )} */}
                        </span>
                        <span className="ms-2 fs-14">{item?.name}</span>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-12">Referrals</div>
                        <div className="fs-14 fw-400">{item?.referrals}</div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-12">Joinees</div>
                        <div className="fs-14 fw-400">{item?.joiness}</div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-12">Conversion</div>
                        <div className="fs-14 fw-400">
                          {item?.conversionPerc}
                        </div>
                      </div>
                      <div
                        className="referee-small-card-btn pb-2 onhover-show2 pe-2"
                        style={{ right: 0, left: 0, bottom: 0 }}
                      >
                        <div className="d-flex gap-2  pt-2 pb-3 col-6">
                          <div className="col-12 col-sm-6">
                            {/* <Link
                              to={""}
                              id={index}
                              type="button"
                              className={`w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-light bg-dark`}
                              style={{ minWidth: "100px" }}
                            >
                              Edit Job
                            </Link> */}
                            <div
                              onClick={() => {
                                // setShowRefereeProfile(true);
                                // setUserId(item.userId);
                                onview(item?.userId);
                                // console.log(item.userId,"id value in view profile");
                              }}
                              className="link"
                            >
                              <button className="w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-light bg-dark ">
                                View Profile
                              </button>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            {/* <Link
                              id={index}
                              to={
                                REFEREE_DETAILS_PAGE_ROUTE + `/${item.userId}`
                              }
                              className="w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                            >
                              Applications
                            </Link> */}
                            <Link
                              to={
                                REFEREE_DETAILS_PAGE_ROUTE + `/${item.userId}`
                              }
                              className="w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                            >
                              Details
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </InfiniteScroll>
        {/* Small Table end */}

        {/* Filter and Sort Table */}

        {/* <div className="position-fixed bottom-0 d-block d-md-none referee-fixed-sort-div d-flex justify-content-center w-100">
          <div
            style={{ marginRight: "100px" }}
            className="d-flex w-50 justify-content-between"
          >
            <div
              className="d-flex justify-content-center align-items-center"
            >
              <div
                className="d-flex p-2"
                onClick={() => setFilterRefereeShow(true)}
              >
                <div>
                  <img alt="sort-icon" src={funnelFilterIcon} />
                </div>
                <div className="color-primary fs-12 ms-1 d-flex align-items-center">
                  Filter
                </div>
              </div>
            </div>
            <div
              className="d-flex justify-content-center align-items-center"
            >
              <div
                className="d-flex p-2"
                onClick={() => setSortTableShow(true)}
              >
                <div>
                  <img alt="sort-icon" src={filterIcon} />
                </div>
                <div className="color-primary fs-12 ms-1 d-flex align-items-center">
                  Sort By
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Dialogs */}

        <AdminRefereeSortDialog
          show={sortTableShow}
          onSortClose={() => setSortTableShow(false)}
          sortArray={sortingArray}
        />
        <AdminRefereeFilterDialog
          show={filterRefereeShow}
          onSortClose={() => setFilterRefereeShow(false)}
          sortArray={sortingArray}
          arraySortIndex={arraySortIndex}
          setArraySortIndex={setArraySortIndex}
        />

        <RefereeProfile
          show={showRefereeProfile}
          userId={userId}
          refereeProfile={refereeProfile}
          showRefereeProfile={showRefereeProfile}
          onRefereeProfileClose={() => setShowRefereeProfile(false)}
        />
      </div>
    </div>
  );
}

export default AdminAllReferees;
