import React, { useState } from "react";
import SwitchButton from "./../../../components/SwitchButton";
import "./data_fields_page.scss";
import DataFieldApprovedPage from "./data_field_approved_page";
import DataFieldForApprovalPage from "./data_field_for_approval_page";

function DataFieldsPage() {
  const [switchValue, setSwitchValue] = useState("Approved");

  return (
    <div className="container">
      <div className="content-margin mt-4">
        <div>
          <h1 className="fs-24 fw-700">Data Fields</h1>
        </div>
        <div className="d-flex flex-wrap justify-content-between mt-3">
          <div>
            <SwitchButton
              data={["Approved", "For Approval"]}
              value={switchValue}
              setValue={setSwitchValue}
              theme={"black"}
            />
          </div>
        </div>

        {switchValue === "Approved" ? (
          <DataFieldApprovedPage />
        ) : (
          <DataFieldForApprovalPage />
        )}
      </div>
    </div>
  );
}

export default DataFieldsPage;
