import React from "react";
import { NOT_MENTIONED } from "../config/messages";

const RefereeBarChart = ({ refereeBarChartData }) => {
  const getPercentage = (total, entityValue) => {
    return Math.round((entityValue * 100) / total);
  };

  return (
    <div>
      <div>
        <div className="d-flex justify-content-center">
          <div className="w-100">
            <div
              className="d-flex gap-3 px-3 align-items-end"
              style={{ height: "100px" }}
            >
              <div
                className="referrals-blue-div"
                style={{
                  height: `${getPercentage(
                    refereeBarChartData?.referrals,
                    refereeBarChartData?.referrals
                  )}%`,
                }}
              ></div>
              <div
                className="applications-yellow-div"
                style={{
                  height: `${getPercentage(
                    refereeBarChartData?.referrals,
                    refereeBarChartData?.applications
                  )}%`,
                }}
              ></div>
              {/* <div
                className="applications-yellow-div"
                style={{ height: "100%" }}
              ></div> */}
              <div
                className="joinees-green-div"
                style={{
                  height: `${getPercentage(
                    refereeBarChartData?.referrals,
                    refereeBarChartData?.joinees
                  )}%`,
                }}
              ></div>
            </div>
            <hr className="w-100 mt-0 pt-0"></hr>
            <div className="d-flex gap-3 px-3">
              <div className="div-width">
                <div className="color-secondary fw-500 fs-12">
                  {refereeBarChartData?.referrals}
                </div>
                <div className="fs-12 color-tertiary fw-300">Referrals</div>
              </div>
              <div className="div-width">
                <div className="color-secondary fw-500 fs-12">
                  {refereeBarChartData?.applications}
                </div>
                <div className="fs-12 color-tertiary fw-300">Applications</div>
              </div>
              <div className="div-width">
                <div className="color-secondary fw-500 fs-12">
                  {/* {refereeBarChartData?.joinees} */}
                  {refereeBarChartData?.referrals &&
                  refereeBarChartData?.joinees
                    ? `${refereeBarChartData?.joinees} (${getPercentage(
                        refereeBarChartData?.referrals,
                        refereeBarChartData?.joinees
                      )}%)`
                    : NOT_MENTIONED}
                  {/* ({getPercentage(refereeBarChartData?.applications, refereeBarChartData?.joinees)}%) */}
                </div>
                <div className="fs-12 color-tertiary fw-300">Joinees</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefereeBarChart;
