/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BackButton from "../../../assests/icons/back-icon.svg";
import close from "../../../assests/icons/ic-close-24.svg";
import down from "../../../assests/icons/ic_down.svg";
import up from "../../../assests/icons/ic_up.svg";
// import whitecircle from "../../../assests/icons/ic_whitecircle.svg";
import AboutEmployer from "../../../components/AboutEmployer";
import JobDetails from "../../../components/JobDetails";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ApplyForJobQuestions from "../../../components/ApplyForJobQuestions";
// import disabled from "../../../assests/icons/ic_disabled.svg";
import {
  fetchJobDetails,
  applyingForJob,
} from "../../../_services/member-profile.service";
import { useNavigate } from "react-router-dom";

import JobCardSave from "../../../components/JobCardSave";

import CompanyImage from "./../../../components/company_image";
import Loader from "../../../components/common/loader";
import NoDataFoundCard from "../../../components/common/no_data_found_card";
import ReferAJobDialog from "./../../../components/refer_a_job_dialog";
import {
  convertToInternationalCurrencySystem,
  monthConversion,
  yearConversion,
} from "../../../utils/utils";
import JobSaveCardForDetails from "./../../../components/JobSaveCardForDetails";
import ApplyForJobDialog from "./../../../components/ApplyForJobDialog";
import { REFEREE_ID_SEARCH_PARAMS } from "../../../config/page_routes_constants";
import { SOMETHING_WENT_WRONG } from "./../../../config/be_api_constants";
import { APPLIED } from "./../../../config/be_api_constants";
import { FROM_REFEREE_PAGE, NOT_MENTIONED } from "./../../../config/messages";
import NotFoundPage from "../../not_found_page";
import toaster from "../../../utils/toaster";
import { viewPreviousJob } from "../../../_services/view.service";

const JobDetailsPage = (props) => {
  let { id } = useParams();

  console.log(" previewId", id);

  const navigate = useNavigate();

  //CAndidate Details
  // const candidateDetails = useStoreState(
  //   (state) => state.candidate.candidateDetails
  // );
  // const saveCandidateDetails = useStoreActions(
  //   (actions) => actions.candidate.saveCandidateDetails
  // );
  // const isLoading = useStoreState((state) => state.candidate.isLoading);

  // useEffect(() => {
  //   const userId = getLocalStorage(USER_ID);
  //   if (userId) {
  //     saveCandidateDetails(userId);
  //   }
  // }, []);

  //Candidate Details end

  // const [isResumeSelected, setIsResumeSelected] = useState(false);
  const [refereeId, setRefereeId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [isJobDetails, setIsJobDetails] = useState(true);
  const [isReferJob, setIsReferJob] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [disableReferralBtn, setDisableReferralBtn] = useState(false);
  const [isFeatured, setIsFeatured] = useState(true); //check featured product
  const [mainImage, setMainImage] = useState(
    "https://bitsofco.de/content/images/2018/12/broken-1.png"
  );
  const [addImage1, setAddImage1] = useState("");
  const [addImage2, setAddImage2] = useState("");
  const [jobDetailsProps, setJobDetailsProps] = useState({
    jobTitle: "",
    companyName: "",
    companyLogo: "",
  });
  const [appliedForJob, setAppliedForJob] = useState(false);

  // const getRefereeId = () => {
  //   if (window?.location?.href?.includes("=")) {
  //     let indexOfRefereeId = window?.location?.href?.lastIndexOf("=");
  //     let refereeId = window?.location?.href?.slice(indexOfRefereeId + 1);
  //
  //     setRefereeId(refereeId);
  //   } else {
  //
  //     setRefereeId("");
  //   }
  // };

  const SetJobDetailsObject = (jobDetails) => {
    const jobTitle = jobDetails?.jobTitle;
    const companyName = jobDetails?.companyProfile?.companyName;
    const companyLogo = jobDetails?.companyProfile?.companyLogo;

    setJobDetailsProps({
      ...jobDetailsProps,
      jobTitle,
      companyName,
      companyLogo,
    });
  };

  const isJobDetailsHandler = () => {
    setIsJobDetails(!isJobDetails);
  };
  const isReferJobHandler = () => {
    setIsReferJob(!isReferJob);
  };
  const AboutEmployerHandler = () => {
    setIsEmployer(!isEmployer);
  };

  const [show, setShow] = useState(false);

  // const popupPageHandler = () => {
  //   if (isPageShow === true) {
  //     setIsPageShow(false);
  //   } else {
  //     if (!isMandatory) {
  //       applyingForJob(applyForJob)
  //         .then((res) => {
  //
  //           toaster("success", "Successfully applied to the job");
  //           setShow(false);
  //         })
  //         .catch((err) => {
  //
  //           toaster("error", err);
  //         });
  //     }
  //     setIsPageShow(true);
  //   }
  // };
  const [scrollOffset, setScrollOffset] = useState(0);
  const [referButtonClicked, setReferButtonClicked] = useState(false);
  const [notFoundPage, setNotFoundPage] = useState(false);
  const [linkInvalid, setLinkInvalid] = useState({
    state: false,
    class: "d-block",
  });
  const [apiMessage, setApiMessage] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();

  const downloadImage = (url, setState) => {
    // setShowLoader(true);
    // downloadFile(url)
    //   .then((res) => {
    //     setShowLoader(false);
    //     setState(res);
    //   })
    //   .catch((err) => {
    //     setShowLoader(false);
    //     toaster("error", err);
    //   });
    // url = url.substr(url.indexOf("/", 1));
    url = url.replace(/\//, "");
    console.log(url);
    if (url) setState(`${process.env.REACT_APP_API_URL}${url}`);
  };

  const getJobDetails = () => {
    viewPreviousJob(id)
      .then((res) => {
        setJobDetails(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        if (err) {
          toaster("error", err?.message ? err?.message : SOMETHING_WENT_WRONG);
        }
      });
  };

  // fetchJobDetails(Number(id))
  //   .then((res) => {
  //     if (res?.data?.data?.jobId === null) {
  //       // setNotFoundPage(true);
  //       setLinkInvalid({ ...linkInvalid, state: false });
  //       // navigate("/not-found", { replace: true });
  //       setShowLoader(false);
  //     } else {
  //       if (res.status === 200) {
  //         console.log(res?.data?.data);
  //         setNotFoundPage(false);
  //         setLinkInvalid({ ...linkInvalid, state: false });
  //         setJobDetails(res?.data?.data);
  //         setIsFeatured(res?.data?.data?.isPromotion);
  //         if (res?.data?.data?.isPromotion) {
  //           if (res?.data?.data?.JobPromotion?.mainImage)
  //             downloadImage(
  //               res?.data?.data?.JobPromotion?.mainImage,
  //               setMainImage
  //             );
  //           if (res?.data?.data?.JobPromotion?.addImage1)
  //             downloadImage(
  //               res?.data?.data?.JobPromotion?.addImage1,
  //               setAddImage1
  //             );
  //           if (res?.data?.data?.JobPromotion?.addImage2)
  //             downloadImage(
  //               res?.data?.data?.JobPromotion?.addImage2,
  //               setAddImage2
  //             );
  //         }
  //         SetJobDetailsObject(res?.data?.data);
  //         setShowLoader(false);
  //       } else {
  //         toaster(
  //           "error",
  //           res?.data?.error ? res?.data?.error : SOMETHING_WENT_WRONG
  //         );
  //         if (res?.data?.error) {
  //           setApiMessage(res?.data?.error);
  //         } else {
  //           setApiMessage(SOMETHING_WENT_WRONG);
  //         }
  //         setShowLoader(false);
  //         setLinkInvalid({ ...linkInvalid, state: true, class: "d-none" });
  //       }
  //     }
  //     // setIsFeatured(false);
  //   })
  //   .catch((err) => {
  //     setShowLoader(false);
  //     navigate(-1);
  //     if (err) {
  //       toaster("error", err?.message ? err?.message : SOMETHING_WENT_WRONG);
  //     }
  //   });

  console.log("jobDetails", jobDetails);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    const onScroll = () => setScrollOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    setShowLoader(true);
    getJobDetails();

    setRefereeId(searchParams?.get(REFEREE_ID_SEARCH_PARAMS));

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const scrollShow = scrollOffset > 130;

  const [referJobShow, setReferJobShow] = useState(false);

  const referring = searchParams?.get("source") === FROM_REFEREE_PAGE;

  return (
    <>
      {showLoader && <Loader />}
      {linkInvalid?.state ? <NoDataFoundCard text={apiMessage} /> : ""}
      {notFoundPage ? (
        <NotFoundPage />
      ) : (
        <div className={`my-4`}>
          <header className="job-detail-header">
            <div className="container mt-4 pt-5 px-3">
              <div className="d-flex">
                <div className="col-md-8 col-12">
                  <span className="d-flex justify-content-between ">
                    {isFeatured ? (
                      <>
                        <span className="gradient-background p-1 mx-2 m-md-0">
                          {" "}
                          {jobDetails?.tagTitle}
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                  <p className="big-font-gray p-0 m-0">
                    {jobDetails?.jobTitle}
                  </p>
                  <br />
                  <span className="fs-12 fw-400 d-block d-lg-none">
                    <JobSaveCardForDetails
                      isSaved={jobDetails?.isSavedApplied}
                      jobId={jobDetails?.jobId}
                      isReferer={jobDetails?.isSavedReferal}
                      jobDetails={jobDetails}
                    />
                    {/* <JobCardSave saveStatus={false} jobId={id}>
                  Save this job
                </JobCardSave>{" "} */}
                  </span>
                </div>
                <div className="col-md-4 col-12 text-end d-none d-lg-block mt-4">
                  <div className="d-flex justify-content-end">
                    <JobSaveCardForDetails
                      isSaved={jobDetails?.isSavedApplied}
                      jobId={jobDetails?.jobId}
                      isReferer={jobDetails?.isSavedReferal}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex ">
                <div className="col-md-8 col-12 d-md-flex gap-4 pt-4">
                  {/* <img
                src={`data:image/jpeg;base64 , ${jobDetails?.companyProfile?.companyLogo}`}
                className="bg-white p-1 d-md-block d-none rounded"
                width="40px"
                height="40px"
              /> */}

                  <CompanyImage
                    src={jobDetails?.companyProfile?.companyLogo}
                    width="50px"
                    // height="50px"
                    name={jobDetails?.companyProfile?.companyName}
                    initialsContainerClass="initialsStyle2-xl bg-white p-1 d-md-block d-none rounded"
                  />

                  <span>
                    <span className="small-text-gray "> Posted By </span>
                    <br />
                    <span className="black-text">
                      {" "}
                      {jobDetails?.companyProfile?.companyName}{" "}
                    </span>
                  </span>
                  <br />
                  <br className="d-md-none d-block"></br>
                  <span>
                    <span className="small-text-gray "> Job Location </span>
                    <br />
                    <span className="black-text">
                      {" "}
                      {jobDetails.reportingType === "OPEN_TO_ALL"
                        ? "Open To All"
                        : jobDetails.reportingType &&
                          jobDetails.reportingType === "INPERSON"
                        ? "In Person"
                        : jobDetails.reportingType}{" "}
                      | {jobDetails.locationName}{" "}
                    </span>
                  </span>
                </div>
                {/* <div className="col-md-4 col-12 d-md-block d-none text-md-end text-center">
                  {referring ? (
                    <input
                      type="button"
                      // onClick={() => setReferJobShow(true)}
                      value="Refer This Role"
                      className={`btn-primary btn-rounded text-white ps-lg-5 pe-lg-5 ps-md-4 pe-md-4`}
                    />
                  ) : (
                    <input
                      type="button"
                      // onClick={() => setShow(true)}
                      disabled={
                        jobDetails?.applicationStatus === APPLIED ? true : false
                      }
                      value={
                        jobDetails?.applicationStatus === APPLIED
                          ? "Applied"
                          : "Apply For This Role"
                      }
                      className={` btn-rounded text-white ps-lg-5 pe-lg-5 ps-md-4 pe-md-4 ${
                        jobDetails?.applicationStatus === APPLIED
                          ? "btn-disable"
                          : "btn-primary"
                      } `}
                    />
                  )}
                </div> */}
              </div>
            </div>
          </header>
          <div
            className=" z-index-align d-flex d-md-none justify-content-center bg-white position-fixed w-100 py-2"
            style={{ bottom: 0 }}
          >
            {referring ? (
              <input
                type="button"
                // onClick={() => setReferJobShow(true)}
                value="Refer This Role"
                className="btn-primary btn-rounded text-white p-2 w-90"
              />
            ) : (
              <input
                type="button"
                // onClick={() => setShow(true)}
                disabled={
                  jobDetails?.applicationStatus === APPLIED ? true : false
                }
                value={
                  jobDetails?.applicationStatus === APPLIED
                    ? "Applied"
                    : "Apply For This Role"
                }
                className={` btn-rounded text-white ps-lg-5 pe-lg-5 ps-md-4 pe-md-4 w-90 ${
                  jobDetails?.applicationStatus === APPLIED
                    ? "btn-disable"
                    : "btn-primary"
                } `}
              />
            )}
          </div>
          {/* <div
            className={`d-none d-md-block position-fixed float-bottom-button-hidden p-3 ${
              scrollShow ? "float-bottom-button" : ""
            }`}
          >
            {referring ? (
              <input
                type="button"
                // onClick={() => setReferJobShow(true)}
                value="Refer This Role"
                className="btn-primary btn-rounded text-white ps-lg-5 pe-lg-5 ps-md-4 pe-md-4"
              />
            ) : (
              <input
                type="button"
                // onClick={() => setShow(true)}
                disabled={
                  jobDetails?.applicationStatus === APPLIED ? true : false
                }
                value={
                  jobDetails?.applicationStatus === APPLIED
                    ? "Applied"
                    : "Apply For This Role"
                }
                className={` btn-rounded text-white ps-lg-5 pe-lg-5 ps-md-4 pe-md-4 ${
                  jobDetails?.applicationStatus === APPLIED
                    ? "btn-disable"
                    : "btn-primary"
                } `}
              />
            )}
          </div> */}
          <div className="bg-white rounded-border shadow h-auto job-detail-content">
            <div className="container pt-2">
              <div className="d-md-flex gap-md-4">
                <span>
                  <span className="small-text-gray "> Job Type </span>
                  <br />
                  <span className="black-text">
                    {" "}
                    {jobDetails.jobType === "FULLTIME"
                      ? "Full Time"
                      : jobDetails.jobType && jobDetails.jobType === "PARTTIME"
                      ? "Part Time"
                      : jobDetails.jobType}{" "}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Salary </span>
                  <br />
                  <span className="black-text">
                    {`${convertToInternationalCurrencySystem(
                      jobDetails?.minSalary,
                      jobDetails?.salCurrency
                    )} - ${convertToInternationalCurrencySystem(
                      jobDetails?.maxSalary,
                      jobDetails?.salCurrency
                    )}  ${jobDetails?.salCurrency} ${jobDetails?.salaryType}`}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Experience </span>
                  <br />
                  <span className="black-text">
                    {" "}
                    {`${yearConversion(
                      jobDetails?.minExpRequiredMonths,
                      jobDetails?.maxExpRequiredMonths
                    )}`}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Function </span>
                  <br />
                  <span className="black-text">
                    {" "}
                    {jobDetails?.departmentName}{" "}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Target Start Date </span>
                  <br />
                  <span className="black-text"> {jobDetails?.targetDate} </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Referral Bonus </span>
                  <br />
                  {/* <span className="black-text">
                    {" "}
                    {jobDetails?.referralBonus}{" "}
                  </span> */}
                  <span className="black-text">
                    {jobDetails?.referralBonus &&
                    (jobDetails?.salCurrency || jobDetails?.salaryCurrency)
                      ? convertToInternationalCurrencySystem(
                          jobDetails?.referralBonus,
                          jobDetails?.salCurrency || jobDetails?.salaryCurrency
                        ) +
                        " " +
                        jobDetails?.salCurrency
                      : NOT_MENTIONED}
                  </span>
                </span>
              </div>
              <hr className="my-3"></hr>
              <div className="">
                <div className="d-flex justify-content-between w-100">
                  <span className="fw-bold text-primary py-1 ">
                    Job Details
                  </span>
                  <div
                    className="btn-blue-round  pt-1 pb-1 px-2 pointer"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseDiv"
                    aria-expanded="false"
                    aria-controls="collapseDiv"
                    onClick={isJobDetailsHandler}
                  >
                    <img
                      src={isJobDetails ? down : up}
                      alt="down-btn"
                      className="text-primary "
                    />
                  </div>
                </div>
                {/* Collapse Element  */}

                <div
                  className={isJobDetails ? "collapse show d-block" : "d-none"}
                  id="collapseDiv"
                >
                  <div
                    className={
                      isFeatured
                        ? "pt-2 d-md-flex gap-2 d-block"
                        : "d-none pt-2 d-flex gap-2"
                    }
                  >
                    <div className="col-md-6 col-12 p-0">
                      <img
                        src={mainImage}
                        className="rounded-image w-100 p-1"
                      />
                    </div>
                    <div className="col-md-6 col-12 p-0">
                      <img src={addImage1} className="rounded-image w-50 p-1" />

                      <img src={addImage2} className="rounded-image w-50 p-1" />

                      <p className="font-color-gray p-1 pt-3">
                        {jobDetails?.JobPromotion?.tagsLine}
                      </p>
                    </div>
                  </div>
                  <div className="w-100">
                    <JobDetails
                      jobDetails={jobDetails}
                      showReferJobDialog={() => {
                        setReferJobShow(true);
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr className="my-3"></hr>
              <div className="d-flex justify-content-between">
                <span className="fw-bold text-primary py-1">
                  Refer this Job
                </span>
                <div
                  className="btn-blue-round p-2 pt-1 pb-1 pointer"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#referjobcollapse"
                  aria-expanded="false"
                  aria-controls="referjobcollapse"
                  onClick={isReferJobHandler}
                >
                  <img
                    src={(referring ? !isReferJob : isReferJob) ? down : up}
                    alt="down-btn"
                    className="text-black"
                  />
                </div>
              </div>
              <div
                className={
                  (referring ? isReferJob : !isReferJob)
                    ? "collapse h-100"
                    : "h-0"
                }
                id="referjobcollapse"
              >
                <div className="row ">
                  <div className="col-12 mt-4">
                    <p className="font-medium-gray">
                      {" "}
                      For a better referral conversion, refer candidates with
                      following criteria :{" "}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 mt-4">
                    <span className="font-color-black fw-600 fs-16">
                      {" "}
                      Current Company{" "}
                    </span>{" "}
                    <br />
                    <div className="pt-1 pb-3 d-flex flex-wrap">
                      {jobDetails?.referralCriteriaCompany?.map(
                        (company, index) => {
                          return (
                            <>
                              <div
                                className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2"
                                key={index}
                              >
                                {company}
                              </div>
                            </>
                          );
                        }
                      )}
                      {/* <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Google
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Uber
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    AirBnB
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    KhataBook
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Cred
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    ICICI Bank
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    HDFC Bank
                  </div> */}
                    </div>
                  </div>

                  <div className="col-md-6 col-12 mt-4">
                    <span className="font-color-black fw-600 fs-16">
                      Industry
                    </span>
                    <br />
                    <div className="pt-1 pb-3 d-flex flex-wrap">
                      {jobDetails?.referralCriteriaIndustry?.map(
                        (industry, index) => {
                          return (
                            <>
                              <div
                                className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2"
                                key={index}
                              >
                                {industry}
                              </div>
                            </>
                          );
                        }
                      )}
                      {/* <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Payments
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Loans
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Payments
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Digital Payments
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    Digital Payments
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    eWallets
                  </div> */}
                    </div>
                  </div>

                  <div className="col-md-6 col-12 mt-3">
                    <span className="font-color-black fw-600 fs-16">
                      {" "}
                      Other{" "}
                    </span>
                    <br />
                    <div className="pt-1 pb-3 d-flex flex-wrap">
                      {jobDetails?.referralCriteriaOther?.map(
                        (other, index) => {
                          return (
                            <>
                              <div
                                className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2"
                                key={index}
                              >
                                {other}
                              </div>
                            </>
                          );
                        }
                      )}

                      {/* <div className="font-gray-bg p-1 ps-2 px-2 mt-2">
                    On Notice Period
                  </div>
                  <div className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2">
                    in Service Company
                  </div> */}
                    </div>
                  </div>
                  {/* <div className="col-12">
                    <input
                      type="button"
                      value=" Refer this Role "
                      className="btn-black mb-4 me-4 mt-5"
                      onClick={() => {
                        setReferJobShow(true);
                      }}
                    />
                  </div> */}
                </div>
              </div>
              <hr className="my-3"></hr>
              <div className="d-flex justify-content-between">
                <span className="fw-bold text-primary py-1">
                  About the Employer
                </span>
                <div
                  className="btn-blue-round px-2 py-1 pointer"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#employer"
                  aria-expanded="false"
                  aria-controls="employer"
                  onClick={AboutEmployerHandler}
                  role="button"
                >
                  <img
                    src={isEmployer ? down : up}
                    alt="down-btn"
                    className="text-primary"
                  />
                </div>
              </div>
              <div
                id="employer"
                className={isEmployer ? "collapse pt-2 d-block" : "d-none"}
                aria-expanded="false"
              >
                <AboutEmployer jobDetails={jobDetails} mainImage={mainImage} />
              </div>
              <hr className="mt-3 mb-3"></hr>
              <br />
              <br />
              <br />
            </div>
          </div>

          {/* Modal component for apply-for-job */}
          {
            <ApplyForJobDialog
              show={show}
              setShow={setShow}
              id={id}
              jobDetails={jobDetails}
              refereeId={refereeId}
              getJobDetails={() => {
                getJobDetails();
              }}
            />
          }
          {/* <Modal
        fullscreen="lg-down"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        scrollable={true}
      >
        <Modal.Header className="m-1 justify-content-end border-0">
          <Link to={`/job/${id}`} onClick={() => setShow(false)}>
            <img
              src={close}
              alt="close-icon"
              className="rounded-circle border-2 p-1 text-black pt-1 pb-1 forgot-round end-0 me-3"
            />
          </Link>
        </Modal.Header>
        <Modal.Body className="scrolling-dialog">
          {isPageShow ? (
            <ApplyForJob
              jobDetails={jobDetails}
              applyForJob={applyForJob}
              setApplyForJob={setApplyForJob}
              setIsMandatory={setIsMandatory}
              isResumeSelected={isResumeSelected}
              setIsResumeSelected={setIsResumeSelected}
            />
          ) : (
            <ApplyForJobQuestions
              jobDetails={jobDetails}
              applyForJob={applyForJob}
              setApplyForJob={setApplyForJob}
              setIsMandatory={setIsMandatory}
              isMandatory={isMandatory}
              isResumeSelected={isResumeSelected}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="modal-dialog-footer">
          <div className="row w-100">
            <div className="col-6">
              <div className="d-flex gap-3">
                <div className={isPageShow ? "order-first" : "order-last"}>
                  <button className="btn-primary rounded-pill ps-3 pe-3 mt-2 text-light">
                    {isPageShow ? "1/2" : "2/2"}
                  </button>
                </div>
                <div className="mt-2 ">
                  <img
                    src={disabled}
                    alt="disabled-page-btn"
                    onClick={popupPageHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="d-flex justify-content-end">
                {isPageShow ? (
                  ""
                ) : (
                  <button
                    className="btn text-decoration-underline mt-2 fw-700 fs-14 "
                    onClick={() => setIsPageShow(true)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                )}
              </div>
            </div>

            <div className="col-4 ">
              <Button
                className="btn-rounded btn-primary w-100"
                onClick={popupPageHandler}
                disabled={!isPageShow && isMandatory ? true : false}
              >
                {isPageShow ? " Continue " : " Submit Application "}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal> */}
        </div>
      )}

      {/* Refer this job modal */}

      {/* <ReferAJobDialog
        onClosedButtonClick={() => {
          setReferJobShow(false);
        }}
        isShow={referJobShow}
        referButtonClicked={referButtonClicked}
        setReferButtonClicked={setReferButtonClicked}
        disableReferralBtn={disableReferralBtn}
        setDisableReferralBtn={setDisableReferralBtn}
        referJobId={id}
        referJobShow={referJobShow}
        setReferJobShow={setReferJobShow}
        jobDetailsProps={jobDetailsProps}
        editReferral={false}
      /> */}
    </>
  );
};

export default JobDetailsPage;
