import React from "react";
import NoDataFoundCard from "../../../../components/common/no_data_found_card";
import { NO_EXPERIENCE_ADDED } from "../../../../config/messages";

const RefereeProfessionalExperience = ({refereeProfile}) => {
  const education = [
    {
      startDate: "Jun 2020",
      endDate: "Present",
      jobRole: "UX Designer II",
      companyName: "Glowhomes Technologies",
      jobLocation: "New Delhi",
      jobType: "Full Time",
      jobFunction: "Design",
      jobSummary:
        "Worked on design explorations and execution for e-commerce flatform for construction industry. Worked on design explorations and execution for e-commerce flatform for construction industry",
    },
    {
      startDate: "Apr 2018",
      endDate: "Mar 2020",
      jobRole: "UX Designer",
      companyName: "Microsoft",
      jobLocation: "Gurgaon",
      jobType: "Full Time",
      jobFunction: "Design",
      jobSummary:
        "Worked on design explorations and execution for e-commerce flatform for construction industry. Worked on design explorations and execution for e-commerce flatform for construction industry",
    },
  ];
  return (
    <div className="">
      <div className="title-card mt-4 fw-600 fs-16 pb-0 pb-4 ps-1">
        Professional Experience
      </div>
      {refereeProfile?.educationalExperienceResponse?.length > 0 ? (
      <div className="shadow-box p-4">
        {refereeProfile?.workExperienceResponse?.map((edu, index) => {
          return (
            <>
              <div
                className={
                  index === edu.length - 1 ? "" : "card-container"
                }
                key={index}
              >
                <div className={index === 0 ? "pt-1" : "pt-3"}>
                  <div>
                    <span className="gray-color-badge">
                      {edu.startDate} - {edu.endDate}
                    </span>
                  </div>
                  <div className="color-primary fw-600 fs-14 py-2">
                    {edu.jobRole}
                  </div>
                  <div className="color-secondary fs-14 pb-1">
                    {`${edu?.companyName} |
                      ${edu?.jobLocation} |
                      ${edu?.jobType} |
                      ${edu?.jobFunction}`}
                  </div>
                  <div className="color-tertiary fs-12">{edu?.jobSummary}</div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      ) : (
        <NoDataFoundCard text={NO_EXPERIENCE_ADDED} />
      )}
    </div>
  );
};

export default RefereeProfessionalExperience;
