import React, { useState, useEffect } from "react";
import icWhiteRight from "../../../assests/icons/ic-right-white.svg";
import starbucksLogo from "../../../assests/starbuck_logo.png";
import icGreenDot from "../../../assests/icons/ic-green-dot.svg";
import icBigScreen from "../../../assests/icons/ic-big-screen.svg";
import icShare from "../../../assests/icons/ic-share.svg";
import JobCardLocation from "./company_details/JobCardLocation";
import JobCardExperience from "./company_details/JobCardExperience";
import JobCardSalary from "./company_details/JobCardSalary";
import JobCardReferralBonus from "./company_details/JobCardReferralBonus";
import JobCardSkills from "./company_details/job_card_skills";
import JobCardSuperpowers from "./company_details/job_card_superpowers";
import JobCardCurrentCompany from "./company_details/job_card_current_company";
import JobCardIndustry from "./company_details/job_card_industry";
import JobCardOthers from "./company_details/job_card_others";
import { getJobDetails } from "../../../_services/view.service";
import { useParams } from "react-router";
import CompanyImage from "../../../components/company_image";
import { Link } from "react-router-dom";

const CompanyDetailsSection = () => {
  const [companyDetailsWidth, setCompanyDetailsWidth] = useState(false);

  const [jobViewDetails, setjobViewDetails] = useState([]);
  let { jobId } = useParams();
  // console.log("jobId----------->", jobId)

  const item = {
    jobMinSalary: 50000,
    salaryCurrency: "INR",
    jobMaxSalary: 100000,
    salaryType: "",
  };

  const ViewJobDetails = () => {
    getJobDetails(jobId) &&
      getJobDetails(jobId).then((resp) => {
        setjobViewDetails(resp?.data?.data);
      });
  };

  console.log(jobViewDetails, "<--response");
  // console.log("jobid:", getJobId && getJobId);

  useEffect(() => {
    ViewJobDetails();
  }, []);

  return (
    <>
      <div className="h-100 position-relative">
        <div
          className={"right-arrow-btn-position"}
          style={{ left: companyDetailsWidth ? "375px" : "30px" }}
        >
          <div className="d-flex gap-md-3 align-items-center">
            <div
              className="bg-dark rounded-circle btn-size d-flex justify-content-center align-items-center pointer"
              onClick={() => setCompanyDetailsWidth(!companyDetailsWidth)}
            >
              <img
                src={icWhiteRight}
                style={{
                  transform: companyDetailsWidth
                    ? "rotate(180deg)"
                    : "rotate(0deg)",
                }}
                alt="white-arrow-right"
              />
            </div>
            {/* <div className="btn-black-text text-white pt-1 pb-1 hide-details-text d-md-block d-none">
              {companyDetailsWidth ? "hide (Esc)" : "Show Job Details"}
            </div> */}
          </div>
        </div>
      </div>
      <div
        className={
          companyDetailsWidth === true
            ? "d-none bg-company-details h-100"
            : "d-block bg-company-details h-100"
        }
      >
        <div className="position-fixed h-100  d-lg-block d-none"></div>
      </div>

      <div className="">
        <div
          className={
            companyDetailsWidth ? "d-block company-details-width " : "d-none"
          }
        >
          <div className="pt-4">
            <div className="d-flex align-items-end">
              <div>
                {/* <img
                  src={starbucksLogo}
                  alt="company-logo"
                  width="40px"
                  height="40px"
                /> */}
                <CompanyImage
                  src={jobViewDetails?.companyProfile?.companyLogo}
                  width="50px"
                  initialsContainerClass="initialsStyle2-xl"
                />
              </div>
              <div className="small-text-gray">
                {jobViewDetails && jobViewDetails?.companyProfile?.companyName}
              </div>
            </div>
            <div className="black-gray-text fs-20 fw-700 pt-2">
              {/* UX Design Manager, Selling Partner Experience */}
              {jobViewDetails && jobViewDetails.jobTitle}
            </div>
            <div>
              <div className="d-flex flex-wrap gap-2">
                <JobCardLocation jobViewDetails={jobViewDetails} />
                <JobCardExperience jobViewDetails={jobViewDetails} />
                <JobCardSalary data={item} jobViewDetails={jobViewDetails} />
                <JobCardReferralBonus jobViewDetails={jobViewDetails} />
              </div>
              <div className="d-flex justify-content-between align-items-center pt-3">
                <div className="black-text fs-12 fw-600">
                  {jobViewDetails?.numPosition} Roles -{" "}
                  {jobViewDetails?.offeredPosition} Filled
                </div>
                <div className="d-flex gap-2 align-items-center">
                  <div>
                    <img src={icGreenDot} alt="green-dot-icon" />
                  </div>
                  <div className="color-tertiary fs-11 fw-300">
                    Live | Posted {jobViewDetails?.jobCreationDate}
                  </div>
                </div>
              </div>
            </div>
            <hr className="mt-2 mb-2"></hr>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-2 align-items-center">
                <div>
                  <img src={icBigScreen} alt="big-screen-icon" />
                </div>
                <Link
                  to={`/job/${jobId}`}
                  className="black-text text-decoration-underline fs-12"
                >
                  See Job on Seikor
                </Link>
              </div>
              {/* <div className="d-flex gap-2 align-items-center">
                <div>
                  <img src={icShare} alt="share-icon" />
                </div>
                <div className="black-text text-decoration-underline fs-12">
                  Share
                </div>
              </div> */}
            </div>
            <hr className="mt-2"></hr>
            <div>
              <JobCardSkills jobViewDetails={jobViewDetails} />
            </div>
            <div>
              <JobCardSuperpowers jobViewDetails={jobViewDetails} />
            </div>
            <hr></hr>
            <div className="pb-3">
              <div className="color-primary fw-700 fs-12">
                Referral criteria
              </div>
              <div className="pb-5">
                <JobCardCurrentCompany jobViewDetails={jobViewDetails} />
                <JobCardIndustry jobViewDetails={jobViewDetails} />
                <JobCardOthers jobViewDetails={jobViewDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetailsSection;
