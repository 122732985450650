import React, { useEffect, useState } from "react";
import IcDoneWhite from "../assests/icons/ic_done_white.svg";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_SAVED_SUCCESS,
  JOB_UNSAVED_SUCCESS,
} from "../config/messages";
import toaster from "../utils/toaster";
import { updateSavedAndPinJob } from "../_services/job.service";
import Loader from "./common/loader";

const JobSaveCardForDetails = (props) => {
  const [isSavedJob, setIsSavedJob] = useState();
  const [isReferer, setIsReferer] = useState();
  const [forApplying, setForApplying] = useState(false);

  const [showLoader, setShowLoader] = useState(false);

  const onButtonClick = (isReferer, isSavedJob) => {
    setShowLoader(true);
    setIsSavedJob(isSavedJob);
    setIsReferer(isReferer);
    updateSavedAndPinJob(props?.jobId, isSavedJob, isReferer, false)
      .then((res) => {
        setShowLoader(false);
        if (forApplying) {
          if (res?.isSave) {
            toaster("success", JOB_SAVED_SUCCESS);
          } else {
            toaster("success", JOB_UNSAVED_SUCCESS);
          }
        } else {
          if (res?.isReferrer) {
            toaster("success", JOB_SAVED_SUCCESS);
          } else {
            toaster("success", JOB_UNSAVED_SUCCESS);
          }
        }
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("Error", err?.message ? err?.message : GENERAL_ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    setIsReferer(props?.isReferer);
    setIsSavedJob(props?.isSaved);
  }, [props]);

  return (
    <div>
      {showLoader && <Loader />}
      {/* <div className="d-flex gap-2">
        <div
          className="btn page-filter-button bg-white text-center d-flex justify-content-center"
          // onClick={() => {
          //   onButtonClick(isReferer, setIsSavedJob(!isSavedJob));
          //   setForApplying(true);
          // }}
        >
          <span
            className={
              isSavedJob === true
                ? "p-1 pt-0 pb-0 bg-black rounded-circle me-1 d-block"
                : "d-none"
            }
          >
            <img src={IcDoneWhite} alt="whitedone-icon" />
          </span>
          Save For Applying
        </div>
        <div
          className="btn page-filter-button bg-white text-center d-flex justify-content-center"
          // onClick={() => {
          //   onButtonClick(setIsReferer(!isReferer), isSavedJob);
          //   setForApplying(false);
          // }}
        >
          <span
            className={
              isReferer === true
                ? "p-1 pt-0 pb-0 bg-black rounded-circle me-1 d-block"
                : "d-none"
            }
          >
            <img src={IcDoneWhite} alt="whitedone-icon" />
          </span>
          Save For Referring
        </div>
      </div> */}
    </div>
  );
};

export default JobSaveCardForDetails;
