/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import ApplicationHoverCard from "./application_hover_card";
import profileImg from "../../../../assests/images/candidate-profile-pic.jpg";
import icGreenDone from "../../../../assests/icons/ic-green-done.svg";
import DropdownCardOffered from "../dropdown_card_offered";
import icFlag from "../../../../assests/icons/ic-flag.svg";
import icDownload from "../../../../assests/icons/ic-download.svg";
import { checkedItemsHandler } from "../../../../utils/utils";
import ProfileImage from "../../../../components/profile_image";
import { updateApplicationStatus } from "../../../../_services/view.service";
import toaster from "../../../../utils/toaster";

const ApplicationCardJoined = (
  {
    applicationCardDetailsList,
    jobId,
    selectedStatus,
    getApplicationCardDetails,
  },
  props
) => {
  const [selectForDownloadClicked, setSelectForDownloadClicked] =
    useState(false);
  const [cardClicked, setCardClicked] = useState(null);
  const [cardSelected, setCardSelected] = useState([]);

  console.log("getAppCardsList---------->", applicationCardDetailsList);

  const statusChange = (appStatus, id) => {
    console.log("buttonclick-->", appStatus);
    updateApplicationStatus(id, appStatus).then((res) => {
      console.log("update Application Status", res);
      // setShowLoader(false);
      getApplicationCardDetails();
    });
    // setShowLoader(true);
  };

  useEffect(() => {
    if (props?.selectManually) {
      setSelectForDownloadClicked(true);
    } else {
      setSelectForDownloadClicked(false);
    }
  }, [props?.selectManually]);

  const cardSelectToggle = (index) => {
    checkedItemsHandler(index, cardSelected, setCardSelected);
  };

  return (
    <div className="mt-2">
      <div className="row">
        {applicationCardDetailsList?.applicationList?.map((details, index) => {
          return (
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mt-3" key={index}>
              <div
                className={
                  selectForDownloadClicked
                    ? "application-card p-3 pb-0"
                    : "application-card p-3 "
                }
                style={{
                  border:
                    cardClicked === index ? "1px solid #808080 !important" : "",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2 pt-2 ps-2">
                    <div>
                      {/* <img
                        src={profileImg}
                        alt="profile-image"
                        className="rounded-circle"
                      /> */}
                      <ProfileImage
                        src={
                          process.env.REACT_APP_IMAGE_BASEURL +
                          details?.profilePicUrl
                        }
                        // name={sms.senderName}
                        width="40px"
                        height="40px"
                        initialsContainerClass="rounded-circle"
                      />
                    </div>
                    <div>
                      <div className="medium-black-text fs-14 fw-600 text-break">
                        {details?.candidateName}
                      </div>
                      <div className="fs-11 fw-3-- color-tertiary">
                        {details?.candidateDesignation}
                      </div>
                      <div className="fs-11 fw-3-- color-tertiary">
                        {details?.candidateCompany}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {/* <img src={icThreeDots} alt="retract-icon" /> */}
                    <DropdownCardOffered
                      applicationId={details.applicationId}
                      getApplicationCardDetails={() =>
                        getApplicationCardDetails()
                      }
                    />
                  </div>
                </div>
                {/* card header finished */}
                <hr className="mb-2"></hr>
                {/* <div className="">
                  <div className="d-flex justify-content-between">
                    <div
                      className="dark-red-text fs-12 fw-600 p-2 "
                      onClick={() => {
                        statusChange("RETRACTED", details.applicationId);
                        toaster("success", "Dropout successfully")

                      }}
                    >
                      Dropout
                    </div>
                    <div className="h-1 border"></div>
                    <div
                      className="dark-green-text fs-12 fw-600 p-2 "
                      onClick={() => {
                        statusChange("APPLIED", details.applicationId);
                        toaster("success", "Accepted successfully")

                      }}
                    >
                      Accepted
                    </div>
                    <div className="h-1 border"></div>
                    <div
                      className="inactive-text fs-12 fw-600  p-2 "
                      onClick={() => {
                        statusChange("JOINED", details.applicationId);
                        toaster("success", "Joined successfully")

                      }}
                    >
                      Joined
                    </div>
                  </div>
                </div> */}

                <div className="d-flex justify-content-between">
                  <div>
                    <div className="fs-11 color-tertiary fw-300">Joined On</div>
                  </div>
                  <div className="text-end">
                    <div className="fs-11 color-tertiary fw-300">
                      {details?.joinedDate}
                    </div>

                    {/* <div className="fade-gray-text fs-11 fw-500">
                      {details?.experience} Years | {details?.roles} Roles
                    </div> */}
                  </div>
                </div>
                <hr className="mt-2"></hr>
                {/* referral section */}
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="fs-11 color-tertiary fw-300">Referral</div>
                    {details?.isRefferal === true ? (
                      <div className="d-flex gap-1 align-items-center">
                        <div>
                          <img src={icGreenDone} alt="green-done icon" />
                        </div>
                        <div className="dark-green-text fs-11 fw-600">
                          {/* {details?.referralStatus} */}Yes
                        </div>
                      </div>
                    ) : (
                      <div className="color-tertiary fs-11 fw-600">
                        {/* {details?.referralStatus} */}No
                      </div>
                    )}
                  </div>
                  <div className="text-end">
                    <div className="fs-11 color-tertiary fw-300">
                      Experience
                    </div>

                    <div className="fade-gray-text fs-11 fw-500">
                      {details?.experience} Years | {details?.roles} Roles
                    </div>
                  </div>
                </div>
                <hr className="mt-2"></hr>
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="color-tertiary fs-11 fw-300">Bonus</div>
                    <div className="fade-gray-text fs-12 fw-500">
                      ₹ {details?.bonus}
                    </div>
                  </div>

                  <div>
                    {details?.paymentStatus === "REQUEST_PAYMENT" && (
                      <div className="dark-red-text fs-12 fw-500">
                        Payment<br/>Requested
                      </div>
                    )}
                    {details?.paymentStatus === "PAYMENT_PROCESSING" && (
                      <div className="text-warning fs-12 fw-500">
                        Payment<br/>Processeing
                      </div>
                    )}
                    {details?.paymentStatus === "PAYMENT_REJECTED" && (
                      <div className="dark-red-text fs-12 fw-500">
                        Payment<br/>Rejected
                      </div>
                    )}
                    {details?.paymentStatus === "PAYMENT_PROCESSED" && (
                      <div className="dark-green-text fs-12 fw-500">
                        Payment<br/>Processed
                      </div>
                    )}
                  </div>
                </div>
                <div className="application-hover-card">
                  <ApplicationHoverCard
                    applicationId={details?.applicationId}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApplicationCardJoined;
