import React, { useState } from "react";

const ProfileSwitcher = ({ profileSwitcher, setProfileSwitcher }) => {
  const onSwitchClick = (name) => {
    setProfileSwitcher(name);
  };

  return (
    <div className="switch-widget d-inline-block black">
      <button
        className={`option d-inline-block ${
          profileSwitcher === "Applications" ? "active" : ""
        }`}
        onClick={() => {
          onSwitchClick("Applications");
        }}
      >
        Applications
      </button>
      <button
        className={`option d-inline-block ${
          profileSwitcher === "Actions" ? "active" : ""
        }`}
        onClick={() => {
          onSwitchClick("Actions");
        }}
      >
        Actions
      </button>
      <button
        className={`option d-inline-block ${
          profileSwitcher === "Insights" ? "active" : ""
        }`}
        onClick={() => {
          onSwitchClick("Insights");
        }}
      >
        Insights
      </button>
    </div>
  );
};
export default ProfileSwitcher;
