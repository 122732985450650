import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icSort from "../../assests/icons/ic_sort.svg";
import funnelFilterIcon from "../../assests/icons/ic-filter.svg";
import icClose from "../../assests/icons/ic-close-rounded.svg";
import blackdone from "./../../assests/icons/ic_blackdone.svg";

function AllTeamsFilterTeamsDialog(props) {
  const [arrayIndex, setArrayIndex] = useState(0);

  return (
    <div>
      <Modal
        animation={false}
        dialogClassName="w-100 margin-0-imp"
        contentClassName="all-team-pos-bottom"
        bsPrefix="modal"
        show={props.show}
        onHide={() => props.onSortClose()}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <Modal.Header className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex gap-2 fs-16 fw-600 color-primary">
              <div>
                <img src={icSort} alt="" />
              </div>
              <div style={{ marginTop: "1px" }}> Sort By </div>
            </div>
            <div
              className="d-flex gap-3 pointer"
              onClick={() => props.onSortClose()}
            >
              <img src={icClose} alt="close-icon" className="" width="20px" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ps-4 pb-2">
          <div className="pb-2">
            {props?.sortArray?.map((sort, index) => {
              return (
                <div
                  href="#/action-2"
                  key={index}
                  className="d-flex pb-4 pt-2"
                  onClick={() => {
                    if (props.rolesArraySortIndex == index) {
                      // props.setRolesArraySortIndex(null);
                      props.dropdownSortHandler(index, null);

                    } else {
                      props.dropdownSortHandler(index, sort?.value);

                    }
                  }}
                  // onClick={() => {
                  //   props.dropdownSortHandler(index, sort?.value);
                  // }}
                >
                  <img
                    src={blackdone}
                    alt="blackdone-icon"
                    className={
                      props?.rolesArraySortIndex === index
                        ? "pe-3 d-block"
                        : "d-none pe-1"
                    }
                  />{" "}
                  <span
                    className={
                      props?.rolesArraySortIndex === index
                        ? "ps-0 color-primary fw-700 fs-12"
                        : "ps-4 fs-12"
                    }
                  >
                    {sort.name}
                  </span>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AllTeamsFilterTeamsDialog;
