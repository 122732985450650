import React from "react";
import icLocation from "../../../../assests/icons/ic_location12.svg";

const RefereeLocation = ({refereeProfile}) => {
  return (
    <div>
      <div className="shadow-box mt-4">
        <div className="p-3">
          <div>
            <div className="fs-14 color-primary fw-600 d-flex gap-2">
              <img src={icLocation} alt="phone-icon" />
              <span>Current Location</span>
            </div>
            <div className="color-tertiary fw-400 fs-14 mt-2">{refereeProfile?.additionalInfoProfileResponse?.currentLocation}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefereeLocation;
