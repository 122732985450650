import React from "react";
import { Modal } from "react-bootstrap";
import "../../pages/auth_protected/jobs_post_page/job-post-style.scss";
import scratchStart from "../../assests/scratchStart.svg";
import RightArrow from "../../assests/Vector.svg";
import copyPrevious from "../../assests/copyPrevious.svg";
import orgTemplate from "../../assests/orgTemplate.svg";
import icCloseRounded from "../../assests/closebtn.svg";
import tick from "../../assests/icons/ic-combo-tick.svg";

import { useState } from "react";
import CopyPreviousJobPostModal from "./copy-previous-job-modal";
import { JOB_POST_CREATE_PAGE_ROUTE } from "../../config/page_routes_constants";
import { Link } from "react-router-dom";

const SortByFilterModal = ({
  dropdownSortHandler,
  arraySortIndex,
  setArraySortIndex,
  dropdownSortArray,
  show,
  close,
  setShowCreateJobPostModal,
  setTableTimeDropdownRedDot,
}) => {
  return (
    <div className="radius19">
      <Modal
        animation={false}
        dialogClassName="w-100 margin-0-imp"
        contentClassName="all-team-pos-bottom"
        show={show}
        bsPrefix="modal"
        onHide={() => close()}
        scrollable={true}
      >
        <Modal.Header
          className="dialog-header border-0 py-3 header-container"
          style={{ borderBottom: "1px solid " }}
        >
          <div className="fs-12 color- fw-700 small-text-gray ps-4">
            SORT BY
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            height="14px"
            width="14px"
            onClick={() => close()}
            className="pointer"
          />
        </Modal.Header>
        <hr className="divider m-0 p-0" />
        <Modal.Body className="dialog-body px-3 m-0">
          <div>
            {dropdownSortArray.map((sortItem, index) => {
              return (
                <li
                  key={index}
                  className="d-flex pb-3 pointer"
                  onClick={() => {
                    if (arraySortIndex == index) {
                      dropdownSortHandler(null);
                      if (setTableTimeDropdownRedDot) {
                        setTableTimeDropdownRedDot("d-none");
                      }
                    } else {
                      dropdownSortHandler(index, sortItem.value);
                      if (setTableTimeDropdownRedDot) {
                        setTableTimeDropdownRedDot("d-block");
                      }
                    }
                  }}
                >
                  <img
                    src={tick}
                    alt="blackdone-icon"
                    className={
                      arraySortIndex === index ? "pe-2 d-block" : "pe-2 d-none"
                    }
                  />
                  <span
                    className={
                      arraySortIndex === index
                        ? "ps-1 color-primary fw-700 fs-12"
                        : "ps-4 fs-12 fw-400"
                    }
                  >
                    {sortItem.name}
                  </span>
                </li>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SortByFilterModal;
