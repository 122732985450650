import React from "react";

const JobCardCurrentCompany = ({jobViewDetails}) => {
  const companies = [
    "Microsoft",
    "Google",
    "Uber",
    "AirBnB",
    "Cred",
    "ICICI Bank",
  ];

  return (
    <div>
      <div className="mt-4">
        <div className="d-flex align-items-center">
          <div className="fs-14 fw-600 color-primary">Current Company</div>
        </div>

        {jobViewDetails.referralCriteriaCompany && jobViewDetails.referralCriteriaCompany?.length > 0 && (
          <div className="d-flex flex-wrap mt-2 gap-2">
            {jobViewDetails.referralCriteriaCompany && jobViewDetails.referralCriteriaCompany.map((company, index) => (
              <span className="gray-color-badge" key={index}>
                {company}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCardCurrentCompany;
