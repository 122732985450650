import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icSort from "../../assests/icons/ic_sort.svg";
import filterIcon from "../../assests/icons/ic-filter.svg";
import icClose from "../../assests/icons/ic-close-rounded.svg";
import blackdone from "./../../assests/icons/ic_blackdone.svg";

function TeamSortTableDialog(props) {
  // const [arrayIndex, setArrayIndex] = useState(0);
  const CUSTOM_DATE_OPTION = "Custom";
  // useEffect(() => {
  //   if (props?.arraySortIndex == null || props?.arraySortIndex == 5) {
  //     props?.setTimeDropdownRedDot("d-none");
  //   } else {
  //     props?.setTimeDropdownRedDot("d-block");
  //   }
  // }, [props?.arraySortIndex]);

  return (
    <div>
      <Modal
        animation={false}
        dialogClassName="w-100 margin-0-imp"
        contentClassName="all-team-pos-bottom"
        bsPrefix="modal"
        show={props.show}
        onHide={() => props.onSortClose()}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <Modal.Header className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex gap-2 fs-16 fw-600 color-primary">
              <div>
                <img src={filterIcon} alt="" />
              </div>
              <div style={{ marginTop: "1px" }}> Time Interval </div>
            </div>
            <div
              className="d-flex gap-3 pointer"
              onClick={() => props.onSortClose()}
            >
              <img src={icClose} alt="close-icon" className="" width="20px" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ps-4 pb-1">
          <div className="pb-2">
            {props?.sortArray?.map((sort, index) => {
              return (
                <div
                  key={index}
                  className="d-flex flex-column pb-4 pt-2"
                  onClick={() => {
                    if (props?.arraySortIndex == index) {
                      props?.setArraySortIndex(null);
                      props?.setTimeDropdownRedDot("d-none");
                      // if (sort == CUSTOM_DATE_OPTION) {
                      //   props?.setCustomStartDate("");
                      //   props?.setCustomEndDate("");
                      // }
                    } else {
                      // props?.setArraySortIndex(index);
                      // onCustomDateSelect(name);
                      if (sort == CUSTOM_DATE_OPTION) {
                        if (
                          props?.customStartDate == "" ||
                          props?.customEndDate == ""
                        ) {
                          props?.setTimeDropdownRedDot("d-none");
                        }
                        props?.setShowDatePickerModal(true);
                      } else {
                        props?.setArraySortIndex(index);
                        props?.setShowDatePickerModal(false);
                        props?.setTimeDropdownRedDot("d-block");
                      }
                    }
                  }}
                >
                  <div className="d-flex">
                    <img
                      src={blackdone}
                      alt="blackdone-icon"
                      className={
                        props?.arraySortIndex === index
                          ? "pe-3 d-block"
                          : "d-none pe-1"
                      }
                    />{" "}
                    <span
                      className={
                        props?.arraySortIndex === index
                          ? "ps-0 color-primary fw-700 fs-12"
                          : "ps-4 fs-12"
                      }
                    >
                      {sort}
                    </span>
                  </div>

                  {sort == CUSTOM_DATE_OPTION &&
                  props?.arraySortIndex == 5 &&
                  props?.customStartDate != "" &&
                  props?.customEndDate != "" ? (
                    <div className="ps-4 small-text-gray fs-12 mt-2">
                      {`${props?.customStartDate} to ${props?.customEndDate}`}
                    </div>
                  ) : null}
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TeamSortTableDialog;
