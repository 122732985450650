import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Dropzone from "react-dropzone";
import userProfilePic from "../../../../assests/images/profile.jpg";
import Loader from "../../../../components/common/loader";
import ProfileImage from "../../../../components/profile_image";
import {
  SOMETHING_WENT_WRONG,
  USER_ID,
} from "../../../../config/localstorage_keys";
import {
  GENERAL_ERROR_MESSAGE,
  IMAGE_DIMENSION,
  IMAGE_SIZE_EXCEEDING,
  INVALID_IMAGE_TYPE,
} from "../../../../config/messages";
import { getLocalStorage } from "../../../../utils/storage";
import useWindowDimensions from "../../../../utils/use_window_dimension";
import { updateProfilePicture } from "../../../../_services/candidate.service";
import {
  download,
  downloadFile,
  uploadFile,
} from "../../../../_services/view.service";
import editIcon from "../../../../assests/icons/edit-icon.svg";
import deleteIcon from "../../../../assests/icons/delete.svg";
import close from "../../../../assests/icons/ic-invite-pg-close.svg";
import toaster from "./../../../../utils/toaster";

function ChangeProfilePictureModal(props) {
  // const [profileModalShow, setProfileModalShow] = useState(false);
  const [usernameModalShow, setUsernameModalShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [smartViewScreenDisplay, setSmartViewScreenDisplay] = useState(false);
  const [initials, setInitials] = useState("");
  const [profilePicId, setprofilePicId] = useState(null);
  const [profileSrc, setProfileSrc] = useState(null);
  const [profileModalPhoto, setProfileModalPhoto] = useState(null);
  // const [profilePhotoBackup, setProfilePhotoBackup] = useState(null);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [previewRequested, setPreviewRequested] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [usernameData, setUsernameData] = useState();
  const [url, setUrl] = useState();

  const [pictureData, setPictureData] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });
  const [files, setFiles] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });

  const BREAKPOINT_TABLET_VIEW = 900;
  const { width } = useWindowDimensions();

  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );

  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const adminUserResponse = useStoreState(
    (state) => state?.candidate?.candidateDetails?.adminUserResponse
  );
  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        setProfileSrc(res);
      } else {
        setProfileSrc(null);
      }
    });
    // alert(downloadedUrl);
  };
  const getInitialsLetter = async () => {
    const name = candidateDetails?.adminUserResponse?.name;

    if (name?.split(" ").length > 1) {
      let InitName =
        name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
        name?.split(" ")[1]?.charAt(0)?.toUpperCase();
      setInitials(InitName);
    } else {
      let InitName = name?.split(" ")[0]?.charAt(0)?.toUpperCase();
      setInitials(InitName);
    }
  };

  const getProfilePicture = () => {
    if (
      candidateDetails?.adminUserResponse?.profilePicDownloadUrl !==
      (undefined || null)
    ) {
      downloadPicture(
        candidateDetails?.adminUserResponse?.profilePicDownloadUrl
        // adminUserResponse?.profilePicDownloadUrl
      );
    } else {
      setProfileSrc(null);
    }
  };

  useEffect(() => {
    getProfilePicture();
    getInitialsLetter();
  }, [candidateDetails]);

  const updateProfilePictureApiCall = (response) => {
    setprofilePicId(response?.id);
    const profilePicUpdateObject = {
      profilePicId: response?.id,
    };

    updateProfilePicture(profilePicUpdateObject)
      .then((data) => {
        const userId = getLocalStorage(USER_ID);
        if (userId) {
          saveCandidateDetails(userId);
        }
        props?.setProfileModalShow(false);
        setTimeout(() => {
          setShowLoader(false);
          toaster("success", "Profile picture updated successfully!");
        }, 1000);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster(
          "error",
          "Couldn't update profile picture, please try again later"
        );
      });
  };

  const onSavePicture = () => {
    setPreviewRequested(false);
    if (deleteFlag) {
      let response = {
        id: "",
      };
      updateProfilePictureApiCall(response);
    } else {
      // let formData = new FormData();
      // const fileObjects = pictureData?.acceptedFiles.map((file) => {
      //   formData.append("contentData", file);
      //   formData.append(
      //     "contentMetaData",
      //     JSON.stringify({
      //       contentType: "PROFILE",
      //       contentName: `${pictureData?.fileName}`,
      //       contentExtention: `${pictureData?.extension}`,
      //     })
      //   );
      // });

      if (previewRequested) {
        let formData = new FormData();
        const fileObjects = files?.acceptedFiles.map((file) => {
          formData.append("contentData", file);
          formData.append(
            "contentMetaData",
            JSON.stringify({
              contentType: "PROFILE",
              contentName: `${files?.fileName}`,
              contentExtention: `${files?.extension}`,
            })
          );
        });

        setShowLoader(true);
        uploadFile(formData)
          .then((response) => {
            if (response?.id) {
              updateProfilePictureApiCall(response);

              // console.log(response?.downloadUrl + "urlvalue");
            } else {
              setShowLoader(false);
              toaster("error", SOMETHING_WENT_WRONG);
            }
          })
          .catch((error) => {
            toaster(
              "error",
              error?.message ? error?.message : SOMETHING_WENT_WRONG
            );
            setShowLoader(false);
            setPreviewRequested(false);
          });
      }
    }
  };

  // const onUploadPicture = (acceptedFiles) => {
  //   const fileSize = acceptedFiles[0]?.size;
  //   const fileName = acceptedFiles[0]?.name;
  //   const extensionIndex = fileName.lastIndexOf(".");
  //   const extension = fileName.slice(extensionIndex + 1);
  //   const isFileExtValid =
  //     fileName.includes(".png") ||
  //     fileName.includes(".jpg") ||
  //     fileName.includes(".jpeg");
  //   const isFileSizeValid = fileSize < 2e6;

  //   if (isFileExtValid && isFileSizeValid) {
  //     setPictureData({ ...pictureData, acceptedFiles, fileName, extension });
  //     let previewImage = Object.assign(acceptedFiles[0], {
  //       preview: URL.createObjectURL(acceptedFiles[0]),
  //     });
  //     setPreviewRequested(true);
  //     setPreviewSrc(previewImage?.preview);
  //     setDeleteFlag(false);
  //   } else {
  //     toaster("error", INVALID_IMAGE_TYPE);
  //     setPreviewRequested(false);
  //   }
  // };

  const onUploadPicture = (acceptedFiles) => {
    setFiles({
      ...files,
      acceptedFiles,
      // fileName,
      // extension,
    });
    if (acceptedFiles && acceptedFiles?.length >= 1) {
      var image = new Image();
      let isValid = true;

      const fileSize = acceptedFiles[0]?.size;
      const fileName = acceptedFiles[0]?.name;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension = fileName.slice(extensionIndex + 1);
      const isFileExtValid =
        fileName.includes(".png") ||
        fileName.includes(".jpg") ||
        fileName.includes(".jpeg");

      if (!isFileExtValid) {
        toaster("error", INVALID_IMAGE_TYPE);
        setPreviewRequested(false);
        return;
      }

      const isFileSizeValid = fileSize < 2e6;

      if (!isFileSizeValid) {
        toaster("error", IMAGE_SIZE_EXCEEDING);
        setPreviewRequested(false);
        return;
      }

      if (isFileExtValid && isFileSizeValid) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid) {
        let dimensionValid = false;

        setPictureData({
          ...pictureData,
          acceptedFiles,
          fileName,
          extension,
        });
        let previewImage = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });

        image.src = previewImage?.preview;
        image.onload = function () {
          if (image.width <= 200 && image.height <= 200) {
            dimensionValid = true;
          }
          if (isValid && dimensionValid) {
            setPreviewRequested(true);
            setPreviewSrc(previewImage?.preview);
            setDeleteFlag(false);
          } else {
            toaster("error", IMAGE_DIMENSION);
            setPreviewRequested(false);
          }
        };
      }
    } else {
      toaster("error", GENERAL_ERROR_MESSAGE);
      setPreviewRequested(false);
    }
  };
  const handleDeletePicture = () => {
    setPreviewRequested(true);
    setPreviewSrc(null);
    setDeleteFlag(true);
  };

  const populatedUsername = () => {
    const candidateName = candidateDetails?.adminUserResponse?.name;
    let firstName = candidateName?.split(" ")[0];
    let lastName = candidateName?.split(" ")[1];
    let copyUsernameData = { ...usernameData };
    copyUsernameData.firstName.valueText = firstName;
    copyUsernameData.lastName.valueText = lastName;
    setUsernameData(copyUsernameData);
  };

  // const downloadPhoto = async (id) => {
  //   let formData = new FormData();
  //   formData.append("fileId", id);
  //   return downloadFile(formData);
  // };

  useEffect(() => {
    if (candidateDetails) {
      setUserDetails(candidateDetails);
    }
  }, [candidateDetails]);

  return (
    <Modal
      show={props?.profileModalShow}
      onHide={() => {
        props?.setProfileModalShow(false);
      }}
      className="lg-dialog-modal referee-btn-focus"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ borderBottom: "none" }} className="dialog-header referee-btn-focus">
        <Modal.Title className="dialog-title pt-3 ps-3 d-flex w-100 justify-content-between referee-btn-focus">
          <div>Profile Picture</div>
          <div className="position-relative">
            <img
              src={close}
              alt="close-icon"
              className="p-1 pt-1 pb-1 username-dialog-close pointer referee-btn-focus"
              onClick={() => {
                props?.setProfileModalShow(false);
              }}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center flex-column referee-btn-focus">
        <div className="modal-profile-picture-div referee-btn-focus">
          <div
            className="position-absolute deleteIconStyle pointer referee-btn-focus"
            onClick={handleDeletePicture}
          >
            <img src={deleteIcon} alt="delete icon" />
          </div>
          {/* <img style={{ width: "100%" }} src={userProfilePic} /> */}
          {profileSrc || previewSrc ? (
            <div>
              {" "}
              {/* <div className="position-relative d-flex justify-content-center profileImageStyle border rounded-3 overflow-hidden"> */}
              {previewRequested ? (
                <ProfileImage
                  src={previewSrc}
                  name={candidateDetails?.adminUserResponse?.name}
                  initialsContainerClass=" initialsStyle2-xxxl"
                  height="150px"
                  width="150px"
                />
              ) : (
                <img
                  src={profileSrc}
                  name={candidateDetails?.adminUserResponse?.name}
                  initialsContainerClass=" initialsStyle2-xxxl"
                  height="150px"
                  width="150px"
                />
              )}
            </div>
          ) : (
            // </div>
            <p className="modal-default-profile-picture d-flex justify-content-center align-items-center referee-btn-focus">
              {initials}
            </p>
          )}
        </div>
        {/* <p className="mt-4 text-center small-text-gray">* {IMAGE_DIMENSION}</p>
        <p className="mt-1 text-center small-text-gray">
          * {IMAGE_SIZE_EXCEEDING}
        </p> */}
      </Modal.Body>
      <Modal.Footer>
        <Dropzone
          onDrop={(acceptedFiles) => {
            onUploadPicture(acceptedFiles);
          }}
          multiple={false}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <>
                <div className="label d-flex align-items-center justify-content-center referee-btn-focus">
                  <Button
                    variant="secondary"
                    style={{
                      background: "transparent",
                      color: "black",
                    }}
                  >
                    Upload
                  </Button>
                </div>
              </>
            </div>
          )}
        </Dropzone>
        <Button
          className="referee-btn-focus"
          style={{ background: "black" }}
          onClick={() => {
            onSavePicture();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
    // <div className="bg-white h-100">
    //   {showLoader && <Loader />}
    //   <div className="container">
    //     <div
    //       className={`sidebar-wrapper ${
    //         width < BREAKPOINT_TABLET_VIEW && smartViewScreenDisplay
    //           ? "d-none"
    //           : "d-block"
    //       }`}
    //     >
    // <div className="list-group sidebar ">
    //   <div className="profileName">
    // <div style={{ display: "flex", alignItems: "end" }}>
    //   {/* <section> */}
    //   <div className="sidebar-profile-pic">
    //     <ProfileImage
    //       src={profileSrc}
    //       name={candidateDetails?.adminUserResponse?.name}
    //       initialsContainerClass="sidebar-default-profile-picture"
    //     />
    //     <img
    //       className="profileImageIcon pointer"
    //       onClick={() => {
    //         props?.setProfileModalShow(true);
    //         getProfilePicture();
    //         setDeleteFlag(false);
    //         setPreviewRequested(false);
    //       }}
    //       src={editIcon}
    //       alt=""
    //     />
    //   </div>
  );
}

export default ChangeProfilePictureModal;
