import React, { isValidElement, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ALPHABET_CHECK,
  EMAIL_PATTERN,
  getPhoneNumberPattern,
  LOGIN,
  PASSWORD_PATTERN,
  pattern,
  PHONE_CHECK,
  PHONE_NUMBER_PATTERN,
  PHONE_NUMBER_PATTERN_LOGIN,
  REQUIRED,
} from "../config/validation_patterns_constants";
import { onFormFeildsChange, validateField } from "../utils/form_validators";
import toaster from "../utils/toaster";
import { login } from "../_services/auth.service";
import Loader from "./common/loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { checkPhoneNumberValid } from "../utils/utils";
import { mobileValidation } from "../_services/candidate.service";
import { FORM_VALIDATION_MESSAGE } from "../config/messages";
import { NAVIGATION_SIDEBAR_ROUTE } from "../config/page_routes_constants";

const LoginForm = (props) => {
  const [formData, setFormData] = useState({
    username: {
      valueText: "",
      errorText: "",
      check: [REQUIRED, EMAIL_PATTERN, PHONE_CHECK],
    },
    password: {
      valueText: "",
      errorText: "",
      check: [REQUIRED],
    },
  });
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [isPasswordShown, setIsPasswordShown] = useState("password");
  const [passwordIcon, setPasswordIcon] = useState(<FaEyeSlash />);

  const togglePasswordVisiblity = () => {
    if (isPasswordShown === "password") {
      setIsPasswordShown("text");
      setPasswordIcon(FaEye);
    } else {
      setIsPasswordShown("password");
      setPasswordIcon(FaEyeSlash);
    }
  };

  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      if (formData?.username?.errorText == "") {
        submitLoginForm();
      }
    }
  };

  const submitLoginForm = () => {

    let emailValid = true;
    if (ALPHABET_CHECK.test(formData?.username?.valueText)) {
      emailValid = validateField("username", formData, setFormData);
    }
    if (emailValid && validateField("password", formData, setFormData)) { 
      setShowLoader(true);
      login(formData?.username?.valueText, formData?.password?.valueText)
        .then((res) => {
          setShowLoader(false);
          if (res.status === 200) {
            if (
              EMAIL_PATTERN.test(formData?.username?.valueText) &&
              res?.data?.data?.emailVerified == false
            ) {
              if (props?.verificationNeeded) {
                props?.setEmail(formData?.username?.valueText);
                props?.verificationNeeded();
              }
            } else {
              
              navigate("/" + NAVIGATION_SIDEBAR_ROUTE);
             
            }
          } else {
            toaster("error", res.data.message);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toaster("error", err);
        });
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div className="heading1">Log In</div>
      <div className="mb-5">
        {/* Don't have account? <Link to="/registration">Register Here</Link> */}
      </div>
      <form autoComplete="off">
        <div className="form-row">
          <label className="form-label">Email/Phone Number</label>
          <input
            onKeyDown={(e) => {
              submitFormOnEnter(e);
            }}
            type="text"
            className={
              (formData?.username?.errorText ? "error" : "") + " form-control"
            }
            name="username"
            defaultValue={formData.username.valueText}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);

              if (!ALPHABET_CHECK.test(formData?.username?.valueText)) {
                const isPhoneNumberValid = checkPhoneNumberValid(
                  $event?.target?.value
                );
                let copyFormData = { ...formData };
                if (!isPhoneNumberValid) {
                  copyFormData.username.errorText =
                    FORM_VALIDATION_MESSAGE.PHONE;
                  setFormData(copyFormData);
                } else {
                  copyFormData.username.errorText = "";
                  setFormData(copyFormData);
                }
              }
            }}
            autoFocus="autoFocus"
          />
          {formData.username.errorText && (
            <div className="field-error mt-1">
              {formData.username.errorText}
            </div>
          )}
        </div>
        <div className="form-row">
          <label className="form-label">Password</label>
          <input
            onKeyDown={(e) => {
              submitFormOnEnter(e);
            }}
            type={isPasswordShown}
            className={
              (formData?.password?.errorText ? "error" : "") + " form-control"
            }
            name="password"
            defaultValue={formData.password.valueText}
            onChange={($event) => {
              onFormFeildsChange($event, formData, setFormData);
            }}
          />
          <span className="icons" onClick={togglePasswordVisiblity}>
            {passwordIcon}
          </span>
          <br />
          {formData.password.errorText && (
            <div className="field-error mt-1">
              {formData.password.errorText}
            </div>
          )}
          <br />
          <div className="forget-password-link mt-1">
            {" "}
            <Link to="/forget-password">Forget password?</Link>
          </div>
        </div>
        <input
          type="button"
          onClick={() => {
            if (formData?.username?.errorText == "") {
              submitLoginForm();
            }
          }}
      
          className="w-100 btn-rounded btn-primary login-btn-focus"
          value="Log In"
        />
      </form>
    </>
  );
};
export default LoginForm;
