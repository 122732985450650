import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useParams } from "react-router-dom";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import Vectorleft from "../../../assests/Vectorleft.svg";
import CompanyImage from "../../../components/company_image";
import ProfileImage from "../../../components/profile_image";
import RefereeBarChart from "../../../components/referee_bar_chart";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import { APPLICATIONS_MAIN_PAGE_ROUTE } from "../../../config/page_routes_constants";
import RefereeProfile from "../../../dialogs/application_dialogs/referee_profile";
import { isEmpty } from "../../../utils/form_validators";
import toaster from "../../../utils/toaster";
import {
  refereeDetails,
  refereeSummary,
} from "../../../_services/member-profile.service";
import { getRefereeProfile } from "../../../_services/view.service";
import "../referee_screen/styles.scss";

function RefereeScreen(props) {
  let isInfinite = false;

  const [arraySortIndex, setArraySortIndex] = useState(0);
  const [showRefereeProfile, setShowRefereeProfile] = useState(false);
  const [refreedetails, setrefreedetails] = useState([]);
  const [list, setList] = useState([]);

  // const[name,setname]=useState();
  const [showLoader, setShowLoader] = useState(false);

  const sortingArray = [
    "Conversion",
    "Most Referrals",
    "Most Applications",
    "Most Joinees",
    "Bonus Amount",
    "A-Z",
  ];

  let { refererId } = useParams();
  const [totalcount, setTotalCount] = useState();
  console.log(refererId, "refererId=--->");
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,

    // searchString: null,
    // sortBy: null,
  };

  // const [totalcount, setTotalCount] = useState();
  const [allJobMessage, setAllJobMessage] = useState("");

  const [dataCountRefereeDetail, setDataCountRefereeDetail] = useState(0);
  const [currentRefereeDetailPageSet, setCurrentRefereeDetailPageSet] =
    useState(0);
  const [allRefDetailFilterData, setAllRefDetailFilterData] =
    useState(initialFilterData);

  const RefereeDetails = () => {
    refereeDetails(refererId).then(
      (res) => {
        console.log("temp", res);
        if (res?.data.status === STATUS_SUCCESS) {
          // setrefreedetails(res?.data?.data);
          setDataCountRefereeDetail(res?.data?.totalRecord);

          // setname(res?.data?.data[0]);
          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...refreedetails, ...res?.data?.data];
          }
          setCurrentRefereeDetailPageSet(data?.length);
          setrefreedetails(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);

          setList(res?.data?.data[0]);
        } else {
          // setrefreedetails([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };
  console.log(
    "check",
    dataCountRefereeDetail,
    currentRefereeDetailPageSet,
    dataCountRefereeDetail > currentRefereeDetailPageSet ? true : false
  );
  const fetchMoreRefereeDetails = () => {
    setCurrentRefereeDetailPageSet(currentRefereeDetailPageSet + 10);
    isInfinite = true;

    RefereeDetails({
      ...allRefDetailFilterData,
      pageNo: allRefDetailFilterData?.pageNo + 1,
    });
    setAllRefDetailFilterData({
      ...allRefDetailFilterData,
      pageNo: allRefDetailFilterData?.pageNo + 1,
    });
  };

  useEffect(() => {
    RefereeDetails(allRefDetailFilterData);
  }, []);

  useEffect(() => {
    let temp = { ...initialFilterData };
    // for (let entry of allRefFilterDataParams.entries()) {
    //   if (entry[0] === "searchString") {
    //     temp = {
    //       ...temp,
    //       searchString: entry[1],
    //     };
    //   } else if (entry[0] === "index") {
    //     setArraySortIndex(Number(entry[1]));
    //   } else if (entry[0] === "sortBy") {
    //     temp = {
    //       ...temp,
    //       sortBy: entry[1],
    //     };
    //   }
    // }
    RefereeDetails(temp);
    setAllRefDetailFilterData(temp);
  }, []);

  const [refereeProfile, setRefereeProfile] = useState([]);
  const ViewCandidateProfile = () => {
    getRefereeProfile(refererId)?.then((res) => {
      setRefereeProfile(res?.data?.data);
    });
  };
  console.log(
    process.env.REACT_APP_IMAGE_BASEURL +
      refereeProfile?.basicDetailsResponse?.profilePicDownloadURL,
    "refereeProfile?.workExperienceResponse?.jobRole"
  );
  useEffect(() => {
    ViewCandidateProfile();
  }, [props.showRefereeProfile]);

  const [summary, setSummary] = useState([]);
  const referralId = props?.referralId;
  console.log(referralId, "referralId");

  const RefereeSummary = () => {
    refereeSummary(refererId).then(
      (res) => {
        if (!isEmpty(res)) {
          setSummary(res?.data?.data);
        } else {
          setSummary([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };
  useEffect(() => {
    RefereeSummary();
  }, []);

  const refereeBarChartData = {
    referrals: summary?.referrals,
    applications: summary?.applications,
    joinees: summary?.joinees,
    joineesPercentage: summary?.joineesPercentage,
  };

  let data = [
    // {
    //   id: 1,
    //   applicationName: "Shantanu Bhatia",
    //   applicationDesignation: "Senior Program Manager",
    //   applicationCompany: "Airsoft Inc",
    //   roleName: "Amazon",
    //   roleDesignation: "Mendix - Business Development Representative",
    //   date: "10 Sep 2022",
    //   status: "Joined",
    //   bonus: "₹ 5,643",
    //   isProcessed: true,
    // },
    // {
    //   id: 2,
    //   applicationName: "Shantanu Bhatia",
    //   applicationDesignation: "Senior Program Manager",
    //   applicationCompany: "Airsoft Inc",
    //   roleName: "Amazon",
    //   roleDesignation: "Mendix - Business Development Representative",
    //   date: "10 Sep 2022",
    //   status: "Applied",
    //   bonus: "₹ 5,643",
    //   isProcessed: false,
    // },
    // {
    //   id: 3,
    //   applicationName: "Shantanu Bhatia",
    //   applicationDesignation: "Senior Program Manager",
    //   applicationCompany: "Airsoft Inc",
    //   roleName: "Amazon",
    //   roleDesignation: "Mendix - Business Development Representative",
    //   date: "10 Sep 2022",
    //   status: "Not Applied",
    //   bonus: "₹ 5,643",
    //   isProcessed: false,
    // },
    // {
    //   id: 3,
    //   applicationName: "Shantanu Bhatia",
    //   applicationDesignation: "Senior Program Manager",
    //   applicationCompany: "Airsoft Inc",
    //   roleName: "Amazon",
    //   roleDesignation: "Mendix - Business Development Representative",
    //   date: "10 Sep 2022",
    //   status: "Rejected",
    //   bonus: "₹ 5,643",
    //   isProcessed: false,
    // },
    // {
    //   id: 4,
    //   applicationName: "Shantanu Bhatia",
    //   applicationDesignation: "Senior Program Manager",
    //   applicationCompany: "Airsoft Inc",
    //   roleName: "Amazon",
    //   roleDesignation: "Mendix - Business Development Representative",
    //   date: "10 Sep 2022",
    //   status: "Assessment",
    //   bonus: "₹ 5,643",
    //   isProcessed: false,
    // },
  ];
  // console.log(name?.name, "name");
  console.log(refreedetails, "refreedetails$$");
  // const refereeBarChartData = {
  //   referrals: 332,
  //   applications: 538,
  //   joinees: 94,
  // };

  return (
    <div className="container">
      <div className="content-margin my-4">
        <Link
          to={-1}
          className="backTitle p-0 m-0 d-flex gap-2 flex-wrap align-items-center text-decoration-none text-dark mb-3"
        >
          <img
            src={Vectorleft}
            alt=""
            className="pointer"
            height="12px"
            width="16px"
          />
          <p className="fs-12 fw-400 m-0 p-0 border-bottom border-dark">
            {" "}
            Go Back
          </p>
        </Link>
        <div className="d-flex col justify-content-lg-between flex-column flex-sm-row cards-graph-container py-2 align-items-start">
          <div
            className="d-flex w-55 flex-column w-100 mb-4"
            style={{ justifyContent: "space-between" }}
          >
            <div
              className="d-flex "
              style={{ minWidth: "250px", paddingBottom: "41px" }}
            >
              {/* <img
                src={profileImg}
                alt="profile"
                className="rounded-circle"
                width="50px"
                height="50px"
              /> */}
              {/* {refereeProfile?.basicDetailsResponse
                ?.profilePicDownloadURL === null ||
              refereeProfile?.basicDetailsResponse?.profilePicDownloadURL ===
                undefined ? (
                <img
                  src={profileImg}
                  alt="profile"
                  className="rounded-circle"
                  width="50px"
                  height="50px"
                />
              ) : ( */}
              <img
                src={
                  process.env.REACT_APP_IMAGE_BASEURL +
                  refereeProfile?.basicDetailsResponse?.profilePicDownloadURL
                }
                alt="profile"
                className="rounded-circle"
                width="50px"
                height="50px"
              />

              {/* )} */}
              <div className="mx-2">
                <div className="fs-16 fw-700" style={{ color: "#262626" }}>
                  {/* Anil Grove */}
                  {refereeProfile?.userRegistrationDetails?.name}
                  {/* {refreedetails?.name} */}
                </div>
                {refereeProfile?.workExperienceResponse?.length > 0 &&
                  refereeProfile?.workExperienceResponse.map((data, index) => {
                    return (
                      <>
                        <div className="small-text-gray fs-12 fw-300">
                          {/* Senior Program Manager */}
                          {data.jobRole}
                        </div>

                        <div className="small-text-gray fs-12 fw-300">
                          {data?.companyName}
                        </div>
                      </>
                    );
                  })}
              </div>
            </div>
            <div className="d-flex border-top border-bottom align-items-center m-0 p-0 bonus-received me-3 ">
              <div className="">
                <div className="fw-600 fs-16">₹{summary?.bonusDisbursed}</div>
                <div className="fs-12 color-tertiary fw-300">
                  Bonus Received
                </div>
              </div>
            </div>
          </div>

          {/* Graph Component */}
          <div className="d-sm-border-bottom pb-3 d-flex justify-content-center all-teams-search-div referee-detail-graph">
            <RefereeBarChart refereeBarChartData={refereeBarChartData} />
          </div>
        </div>

        {/* Table */}
        <InfiniteScroll
          dataLength={currentRefereeDetailPageSet}
          next={() => fetchMoreRefereeDetails()}
          hasMore={dataCountRefereeDetail > currentRefereeDetailPageSet}
          className="container-fluid"
        >
          <div className="table-responsive d-none d-md-block">
            <div>
              <h2 className="fw-700 fs-16 mb-0">
                Referrals ({dataCountRefereeDetail})
              </h2>
            </div>
            <Table className="mt-4 justify-content-between border-0">
              <thead className="w-100 border-bottom">
                <tr>
                  <th className="text-start col-3 ps-0">
                    <div className="fs-12 fw-700 text-start">Application</div>
                  </th>

                  <th className="text-center col-3">
                    <div className="fs-12 fw-700 text-start">Role</div>
                  </th>
                  <th className="text-center ">
                    <div className="fs-12 fw-700 text-start">Date</div>
                  </th>
                  <th className="text-center">
                    <div className="fs-12 fw-700 text-start">Status</div>
                  </th>
                  <th className=" text-center">
                    <div className="fs-12 fw-700 text-start">Bonus</div>
                  </th>
                </tr>
              </thead>
              <tbody className="border-0">
                {refreedetails && refreedetails?.length > 0
                  ? refreedetails.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            className="card-parent fs-14 position-relative border-bottom border-top"
                          >
                            <td className="py-3">
                              <div className="d-flex gap-2 align-items-center text-start">
                                {/* <img
                              src={
                                process.env.REACT_APP_IMAGE_BASEURL +
                                item?.profilePic
                              }
                              alt="profile"
                              className="rounded-circle"
                              width="40px"
                              height="40px"
                            /> */}
                                {item?.profilePic === "" ? (
                                  <img
                                    // src={profileImg}
                                    alt="profile"
                                    className="rounded-circle"
                                    width="40px"
                                    height="40px"
                                  />
                                ) : (
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_BASEURL +
                                      item?.profilePic
                                    }
                                    alt="profile"
                                    className="rounded-circle"
                                    width="40px"
                                    height="40px"
                                  />
                                )}
                                <div>
                                  <div
                                    className="fs-12 fw-400"
                                    style={{ color: "#262626" }}
                                  >
                                    {item?.name}
                                  </div>
                                  <div className="small-text-gray fs-12 fw-300">
                                    {item?.currentDesignation}
                                  </div>
                                  <div className="small-text-gray  fs-12 fw-300">
                                    {item?.company}
                                  </div>
                                </div>
                              </div>
                            </td>

                            <td className="py-3">
                              <div className="d-flex gap-2 align-items-center">
                                {item?.companyLogo === "" ? (
                                  <img
                                    src={profileImg}
                                    alt="profile"
                                    className=""
                                    width="40px"
                                    height="40px"
                                  />
                                ) : (
                                  <img
                                    src={`data:image/jpeg;base64 , ${item?.companyLogo}`}
                                    alt="profile"
                                    className=""
                                    width="40px"
                                    height="40px"
                                  />
                                )}

                                <div>
                                  <div className="fs-12 fw-400 small-text-gray">
                                    {item?.jobTitle}
                                  </div>
                                  <div className="fs-12 fw-700">
                                    {item?.reportingRole}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  right: "10px",
                                }}
                                className="onhover-show"
                              >
                                <div className="d-flex align-items-center ">
                                  <div className="link ms-2">
                                    <Link
                                      to={
                                        "/" +
                                        APPLICATIONS_MAIN_PAGE_ROUTE +
                                        `/${item?.jobId}`
                                      }
                                      className=" text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark px-3 py-2"
                                    >
                                      View Application
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-start small-text-gray fs-12 fw-300 py-3">
                              <div> {item?.appliedDate}</div>
                            </td>
                            <td className="align-middle text-start fs-12 fw-400 py-3">
                              <div> {item?.applicationStatus}</div>
                            </td>
                            <td className="align-middle text-start py-3">
                              <div>
                                {" "}
                                <p className="mb-0 pb-0 fs-12 fw-400">
                                  {item?.bonus}{" "}
                                </p>
                                {item?.status === "PAYMENT_PROCESSED" && (
                                  <span className="processed fs-12 fw-700 text-green p-0 m-0 text-end">
                                    Processed
                                  </span>
                                )}
                                {item?.status === "REQUEST_PAYMENT" && (
                                  <span className="processed fs-12 fw-700 dark-red-text p-0 m-0 text-end">
                                    Payment Requested
                                  </span>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : "No Data Found"}
              </tbody>
            </Table>
          </div>

          <div className="d-md-none mt-4 ">
            {refreedetails && refreedetails?.length > 0
              ? refreedetails?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="position-relative d-flex card-parent-bg-none row justify-content-center align-items-center mt-3 pt-2 m-0 border-bottom "
                    >
                      <h6 className="pb-3 fw-600 fs-11  m-0 p-0">
                        Application
                      </h6>
                      <div className="d-flex gap-3 align-items-center text-start  px-0">
                        {/* <img
                      src={profileImg}
                      alt="profile"
                      className="rounded-circle"
                      width="36px"
                      height="36px"
                    /> */}
                        {item?.profilePic === "" ? (
                          <img
                            src={profileImg}
                            alt="profile"
                            className="rounded-circle"
                            width="40px"
                            height="40px"
                          />
                        ) : (
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_BASEURL +
                              item?.profilePic
                            }
                            alt="profile"
                            className="rounded-circle"
                            width="40px"
                            height="40px"
                          />
                        )}
                        <div>
                          <div
                            className="fs-12 fw-400"
                            style={{ color: "#262626" }}
                          >
                            {/* {item?.applicationId} */}
                            <div>
                              <div
                                className="fs-12 fw-400"
                                style={{ color: "#262626" }}
                              >
                                {item?.name}
                              </div>
                              <div className="small-text-gray fs-12 fw-300">
                                {item?.currentDesignation}
                              </div>
                              <div className="small-text-gray  fs-12 fw-300">
                                {item?.company}
                              </div>
                            </div>
                          </div>
                          <div className="small-text-gray fs-12 fw-300">
                            {item?.applicationDesignation}
                          </div>
                          <div className="small-text-gray  fs-12 fw-300">
                            {item?.applicationCompany}
                          </div>
                        </div>
                      </div>
                      <h6 className="pt-4 fw-600 fs-11 px-0">Role</h6>
                      <div className="d-flex gap-3 align-items-center text-start px-0">
                        {item?.companyLogo === "" ? (
                          <img
                            src={profileImg}
                            alt="profile"
                            className=""
                            width="40px"
                            height="40px"
                          />
                        ) : (
                          <img
                            src={`data:image/jpeg;base64 , ${item?.companyLogo}`}
                            alt="profile"
                            className=""
                            width="40px"
                            height="40px"
                          />
                        )}

                        <div>
                          <div className="fs-14  small-text-gray  fs-12 fw-300">
                            {item?.jobTitle}
                          </div>
                        </div>

                        <div className="fs-12  fs-12 fw-700">
                          {item?.reportingRole}
                        </div>

                        <div>
                          <div className="fs-14  small-text-gray  fs-12 fw-300">
                            {item?.roleName}
                          </div>
                          <div className="fs-12  fs-12 fw-700">
                            {item?.roleDesignation}
                          </div>
                        </div>
                      </div>

                      <h6 className="pt-4 fw-600 fs-11 px-0">Date</h6>
                      <div className="d-flex justify-content-between px-0 pt-0">
                        <div className="fs-12 fw-300 col-6 small-text-gray">
                          {item?.appliedDate}
                        </div>
                        {/* <div className="fs-12 fw-400 col-6">{item?.status}</div> */}
                      </div>

                      {/* <div className="fs-12 fw-400 small-text-gray">{item?.appliedDate}</div> */}

                      <h6 className="pt-4 fw-600 fs-11 px-0">Bonus</h6>
                      <div className="d-flex px-0">
                        <div className="fs-12 fw-400">
                          <p className="mb-0 pb-0 text-start">
                            ₹ {item?.bonus}{" "}
                          </p>
                          {item?.status === "PAYMENT_PROCESSED" && (
                            <span className="processed fs-12 fw-700 text-green p-0 m-0 text-end">
                              Processed
                            </span>
                          )}
                          {item?.status === "REQUEST_PAYMENT" && (
                            <span className="processed fs-12 fw-700 dark-red-text p-0 m-0 text-end">
                              Payment Requested
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className="mt-3 align-items-end mx-0 px-0 "
                        style={{ bottom: "15px" }}
                      >
                        <div className="gy-3 w-100 ">
                          <div className="onhover-show2 pb-4">
                            <Link
                              to={
                                "/" +
                                APPLICATIONS_MAIN_PAGE_ROUTE +
                                `/${item?.applicationId}`
                              }
                              className="   w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                            >
                              Applications
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </InfiniteScroll>

        {/* Table end */}

        <RefereeProfile
          show={showRefereeProfile}
          onRefereeProfileClose={() => setShowRefereeProfile(false)}
        />
      </div>
    </div>
  );
}

export default RefereeScreen;
