import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { isEmpty } from "../../utils/form_validators";
import checkedLogo from "../../assests/ic_selected16.svg";
import filterLogo from "../../assests/filterLogo.svg";
import SearchComboBox from "../../components/SearchComboBox";
import { allOrganization } from "../../_services/company.service";
import { DEFAULT_PAGE_SIZE } from "../../config/be_seikorAdmin_api_constants";
import SearchComboBoxForId from "../../components/SearchComboBoxForId";

const AllJobsFilterDialog = (props) => {
  const [JobPostByOutput, setJobPostByOutput] = useState("");
  const [promotedOutput, setPromotedOutput] = useState("");
  const [selectedPromoted, setSelectedPromoted] = useState("");

  const [jobWithOutput, setJobWithOutput] = useState("");
  const [jobStatusOutput, setJobStatusOutput] = useState("");
  const [selected_Org, setSelected_Org] = useState([]);
  const [promoted, setRole] = useState([
    {
      name: "All Jobs",
      value: "null",
    },
    {
      name: "Promoted",
      value: true,
    },
    {
      name: "Non Promoted",
      value: false,
    },
  ]);

  const [jobWith, setJobWith] = useState([
    {
      name: "All Jobs",
      value: "null",
    },
    {
      name: "New Applications or referrals",
      value: "REFERRALS_COUNT",
    },
    {
      name: "Pending screening",
      value: "PENDING_SCREENING",
    },
  ]);
  const [jobStatus, setjobStatus] = useState([
    {
      name: "All Status",
      value: "null",
    },
    {
      name: "Live",
      value: "LIVE",
    },
    {
      name: "Filled",
      value: "FILLED",
    },
    {
      name: "Closed",
      value: "CLOSED",
    },
  ]);
  const [allOrg, setAllOrg] = useState([]);

  const fetchAllOrg = () => {
    allOrganization({ pageNo: 0, pageSize: 0 }).then((res) => {
      let OrgName = res?.data?.data?.map((el) => {
        return { name: el.orgName, id: el.id };
      });
      setAllOrg(OrgName);
    });
  };

  useEffect(() => {
    fetchAllOrg(props.allJobFilterData);
  }, [props.show]);
  const onSelect_promoted = (value) => {
    if (value === "Promoted") {
      setSelectedPromoted(true);
    } else if (value === "Non Promoted") {
      setSelectedPromoted(false);
    }
  };

  const ResetFilter = () => {
    setPromotedOutput(``);
    setJobStatusOutput("");
    setJobWithOutput("");
    setSelectedPromoted("");
    setSelected_Org([]);
    setAllOrg([]);
  };
  const ApplyFilter = () => {
    props.fetchAllJobs({
      ...props.allJobFilterData,
      sortBy: jobWithOutput,
      isPromoted: selectedPromoted,
      jobStatus: jobStatusOutput,
      orgIds: selected_Org,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    props.close();
    ResetFilter();
  };

  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        onHide={() => props.close()}
        backdrop="static"
        fullscreen="md-down"
        centered
        keyboard={false}
        className="p-0 m-0"
        // contentClassName=" sm-radius-0"
      >
        <Modal.Header
          className=" border-0 border-bottom"
          bsPrefix="modal-header-custom"
        >
          <div className="fs-16 color-primary fw-600 px-2 pt-2">
            <img
              src={filterLogo}
              alt=""
              className="me-2"
              height="15px"
              width="20px"
            />
            Filters
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            onClick={() => props.close()}
            className="pointer me-2 mt-2"
            height="20px"
            width="20px"
          />
        </Modal.Header>
        <Modal.Body className="m-4 mt-0">
          <div className="border-bottom ">
            <div className="d-flex justify-content-between">
              <div className="fs-14 fw-700 mt-1 ">Job Post by</div>
              <button
                className="pointer btn border-0 fs-12 fw-400 "
                style={{ color: "#EB1260" }}
                // onClick={() => setSelected_Org([])}
                onClick={() => {
                  setAllOrg([]);
                  setSelected_Org([]);
                 
                  
                }}
              >
                Clear
              </button>
            </div>
            <div className="mt-3 mb-4">
              <SearchComboBox
                inputData={allOrg}
                defaultValue={null}
                isMultiSelect={true}
                inputCssClass={"modal-input combo-search-box"}
                wrapperCssClass={"form-group"}
                placeholder={"All Organizations"}
                onSelect={(event) => setSelected_Org(event)}
                searchListHeight={150}
                badgeThemeCssClass={"blue"}
                //hideBadges={true}
                seikorAdminOrgList={true}
              />
            </div>
          </div>

          <div className="border-bottom py-3">
            <div className="">
              <div className="d-flex justify-content-between">
                <div className="fs-14 fw-700">Jobs with</div>
                <button
                  className="pointer btn border-0 fs-12 fw-400"
                  style={{ color: "#EB1260" }}
                  onClick={() => setJobWithOutput("")}
                >
                  Clear
                </button>
              </div>
              <div className="d-flex flex-wrap gap-2 mt-2">
                {jobWith?.map((output, index) => {
                  return (
                    <div
                      className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer h-24 align-items-center"
                      key={index}
                      onClick={() => {
                        setJobWithOutput(`${output.value}`);
                        // props.setAllJobFilterData({
                        //   ...props.allJobFilterData,
                        //   sortBy: output.value,
                        //   pageNo: 1,
                        //   pageSize: DEFAULT_PAGE_SIZE,
                        // });
                      }}
                    >
                      {jobWithOutput.includes(output.value) ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                          className=""
                        ></img>
                      ) : (
                        ""
                      )}

                      <div>{output.name} </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div></div>
          </div>

          <div className="border-bottom py-3">
            <div className="">
              <div className="d-flex justify-content-between">
                <div className="fs-14 fw-700">Job Status</div>
                <button
                  className="pointer btn border-0 fs-12 fw-400"
                  style={{ color: "#EB1260" }}
                  onClick={() => setJobStatusOutput("")}
                >
                  Clear
                </button>
              </div>
              <div className="d-flex flex-wrap gap-2 mt-2">
                {jobStatus?.map((output, index) => {
                  return (
                    <div
                      className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer h-24 align-items-center"
                      key={index}
                      onClick={() => {
                        setJobStatusOutput(`${output.value}`);
                        // props.setAllJobFilterData({
                        //   ...props.allJobFilterData,
                        //   jobStatus: output.value,
                        //   pageNo: 1,
                        //   pageSize: DEFAULT_PAGE_SIZE,
                        // });
                      }}
                    >
                      {jobStatusOutput.includes(output.value) ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                          className=""
                        ></img>
                      ) : (
                        ""
                      )}

                      <div>{output.name} </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div></div>
          </div>

          <div className=" py-3">
            <div className="">
              <div className="d-flex justify-content-between">
                <div className="fs-14 fw-700">Promoted</div>
                <button
                  className="pointer btn border-0 fs-12 fw-400"
                  style={{ color: "#EB1260" }}
                  onClick={() => setPromotedOutput("")}
                >
                  Clear
                </button>
              </div>

              <div className="d-flex flex-wrap gap-2 mt-2">
                {promoted?.map((output, index) => {
                  return (
                    <div
                      className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer h-24  align-items-center"
                      key={index}
                      onClick={() => {
                        setPromotedOutput(`${output.name}`);
                        onSelect_promoted(`${output.name}`);
                      }}
                    >
                      {promotedOutput === output.name && (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                          className=""
                        ></img>
                      )}

                      <div>{output.name} </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div></div>
          </div>
        </Modal.Body>
        <Modal.Footer className="dialog-footer d-flex justify-content-between">
          <button
            className="pointer btn border-0 fs-12 fw-400"
            style={{ color: "#EB1260" }}
            onClick={ResetFilter}
          >
            Clear All
          </button>
          <button
            className="btn pointer fs-12 fw-700 bg-dark border-0 text-white px-4"
            onClick={ApplyFilter}
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllJobsFilterDialog;
