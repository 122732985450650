import React, { useState, useEffect } from "react";
import addIcon from "../../../assests/icons/ic-add-white.svg";
import icThreeDots from "../../../assests/icons/ic_retract.svg";
import "../job_templates/job_templates.scss";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { JOB_CREATE_TEMPLATE_PAGE } from "../../../config/page_routes_constants";
import {
  deleteJobTemplate,
  getJobTemplate,
} from "../../../_services/view.service";
import { STATUS_SUCCESS } from "../../../config/localstorage_keys";

import Loader from "../../../components/common/loader";
import { GENERAL_ERROR_MESSAGE, JOB_FETCH_FAILED } from "../../../config/messages";
import toaster from "../../../utils/toaster";
import { DEFAULT_CARD_SIZE, DEFAULT_PAGE_SIZE } from "../../../config/config";
import { isEmpty } from "../../../utils/form_validators";
import InfiniteScroll from "react-infinite-scroll-component";

function JobTemplatesPage() {
  let isInfinite = false;

  const navigate = useNavigate();

  const [allTemplateDetails, setAllTemplateDetails] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [apiRefresh, setapiRefresh] = useState(false);
  const [allJobMessage, setAllJobMessage] = useState("");



  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    // searchString: null,
    // sortBy: null,
  };
  const [allDraftFilterDataParams, setAllDraftFilterDataParams] =
  useSearchParams({
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [dataCountTemplete, setDataCountTemplete] = useState(0);
  const [currentTempleteOffset, setCurrentTempleteOffSet] = useState(0);
  const [allTempFilterData, setAllTempFilterData] = useState(initialFilterData);


  const getTemplateDetails = (data) => {

    setShowLoader(true);
    getJobTemplate(data)
      .then((res) => {
        if (res?.data.status === STATUS_SUCCESS) {
          setDataCountTemplete(res?.data?.totalRecord);
          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...allTemplateDetails, ...res?.data?.data];
          }
          setCurrentTempleteOffSet(data?.length);
          setAllTemplateDetails(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setShowLoader(false);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      });
  };

  const fetchMoreTemplete = () => {
    // Move the "setCurrentTempleteOffSet" to the end of this function to ensure it's updated after the data is fetched.
    isInfinite = true;
    getTemplateDetails({
      ...allTempFilterData,
      pageNo: allTempFilterData?.pageNo + 1,
    });
    setAllTempFilterData({
      ...allTempFilterData,
      pageNo: allTempFilterData?.pageNo + 1,
    });
    setCurrentTempleteOffSet(currentTempleteOffset + 10); // Move this here
  };

  useEffect(() => {
    let temp = { ...initialFilterData };
    for (let entry of allDraftFilterDataParams.entries()) {
      if (entry[0] === "searchString") {
        temp = {
          ...temp,
          searchString: entry[1],
        };
      }
    }
    setAllTempFilterData(temp);
    getTemplateDetails(temp);
    // getTemplateDetails(allTempFilterData);
  }, []);

  useEffect(() => {
    if (apiRefresh === true) {
      // getTemplateDetails(allTempFilterData);
      setapiRefresh(false);
    }
  }, [apiRefresh]);


  const editTemplate = (id) => {
    navigate("/" + JOB_CREATE_TEMPLATE_PAGE + "/" + id);
  };
  const deleteTemplate = (id) => {
    setShowLoader(true);
    setapiRefresh(true);
    deleteJobTemplate(id)
      .then((res) => {
        if (res?.data.status == STATUS_SUCCESS) {
          toaster("success", "Delete Successfully!");
          setShowLoader(false);
          setapiRefresh(true);
        } else {
          toaster("error", res?.message ? res?.message : GENERAL_ERROR_MESSAGE);
          setShowLoader(false);
          setapiRefresh(true);
        }
      })
      .catch((e) => {
        setShowLoader(false);
        setapiRefresh(false);
      });
  };

  return (
    <div className="container">
      <div className="content-margin w-100 mt-4">
        {showLoader && <Loader />}
        
        <div className="d-block d-sm-flex justify-content-between">
          <div className="fw-700 fs-24">Job Templates</div>
          <div>
            <Link
              to={"/" + JOB_CREATE_TEMPLATE_PAGE}
              className="mt-3 mt-sm-0 btn btn-dark color-white add-template-btn d-flex justify-content-around fs-14 align-items-center"
            >
              <img src={addIcon} />
              New Job Template
            </Link>
          </div>
        </div>
        <InfiniteScroll
          dataLength={currentTempleteOffset}
          next={() => fetchMoreTemplete()}
          hasMore={dataCountTemplete > currentTempleteOffset}
          className="container-fluid"
        >
          <div className="row mt-4 mb-5">
            {allTemplateDetails && allTemplateDetails?.length > 0
              ? allTemplateDetails?.map((template, index) => {
                return (
                  <div className="mb-3 m-sm-0 col-md-4 col-sm-6 col-12 ">
                    <div className="col-vertical-gap template-card p-3 pb-5 mt-3 ">
                      <div key={index} className=" d-flex row text-left">
                        <div className="align-items-center">
                          <div className="d-flex justify-content-between gap-2">
                            <div className="fs-16 fw-700 pt-2 pb-3">
                              {template?.templateTitle}
                            </div>

                            <div className="d-flex">
                              <Dropdown className="job-template-dropdown">
                                <Dropdown.Toggle
                                  variant="none"
                                  id="dropdown-basic"
                                  className="d-flex p-0 m-0 border-0 bg-transparent job-template-icon"
                                >
                                  <img src={icThreeDots} alt="retract-icon" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                                  <Dropdown.Item
                                    className="d-flex pb-2 pt-2"
                                    onClick={() =>
                                      editTemplate(template.templateId)
                                    }
                                  >
                                    <div className="d-flex gap-2">
                                      <span className="">Edit</span>
                                    </div>
                                  </Dropdown.Item>
                                  <hr className="m-0 p-0" />
                                  <Dropdown.Item
                                    className="d-flex pb-2 pt-2"
                                    onClick={() => {
                                      deleteTemplate(template.templateId)
                                      getTemplateDetails(getTemplateDetails)
                                    }

                                    }
                                  >
                                    <div className="d-flex gap-2">
                                      <span className="color-red">Delete</span>
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="small-text-gray">
                            {template?.templateDescription}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              : ""}

          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default JobTemplatesPage;
