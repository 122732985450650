import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BackButton from "../../../assests/icons/back-icon.svg";
import ProfileSwitcher from "../../../components/ProfileSwitcher";
import icPinkDot from "../../../assests/icons/ic_pink_dot.svg";
import icSort from "../../../assests/icons/ic_sort.svg";
import icFilter from "../../../assests/icons/ic-filter.svg";
import blackdone from "./../../../assests/icons/ic_blackdone.svg";
import "./application.scss";
import ApplicationCard from "./cards/application_card";
import FilterDialog from "../../../dialogs/application_dialogs/filterdialog";
import { Dropdown } from "react-bootstrap";
import ApplicationCardShortlisted from "./cards/application_card_shortlisted";
import ApplicationCardOffered from "./cards/application_card_offered";
import ApplicationCardJoined from "./cards/application_card_joined";
import ApplicationCardRejected from "./cards/application_card_rejected";
import ApplicationCardDropout from "./cards/application_card_dropout";
import SortDialog from "../../../dialogs/application_dialogs/sortdialog";
import ApplicationSubpart from "../../../dialogs/application_dialogs/application_subpart";
import icBack from "../../../assests/icons/back-icon.svg";
import { ADMIN_JOB_POST_PAGE_ROUTE, JOB_POSTS_PAGE_ROUTE } from "../../../config/page_routes_constants";
import CompanyDetailsSection from "./company_details";
import useWindowDimensions from "../../../utils/use_window_dimension";
import {
  downloadApplication,
  downloadApplicationCount,
  getApplicationJobListing,
} from "../../../_services/view.service";
import SelectForDownload from "../../../dialogs/application_dialogs/selectfordownload";
import ActionsComponent from "./actions";
import InsightsComponent from "./insights";
import ApplicationOptions from "../../../dialogs/application_dialogs/applicationoptions";
import icDown from "../../../assests/icons/ic-chevron-down-16.svg";
import { getLocalStorage } from "../../../utils/storage";
import { USER_ROLE } from "../../../config/be_api_constants";

const Applications = () => {
  let { jobId } = useParams();

  const [profileSwitcher, setProfileSwitcher] = useState("Applications");
  const [showFilter, setShowFilter] = useState(false);
  const [sortShow, setSortShow] = useState(false);
  const [arrayIndex, setArrayIndex] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("APPLIED");
  const [applicationShow, setApplicationShow] = useState(false);
  const [applicationCardDetailsList, setApplicationCardDetailsList] = useState(
    []
  );
  const [noOfSelect, setNoOfSelect] = useState("");
  const [no0fCardSelect, setno0fCardSelect] = useState([]);

  const [selectedApp_Id, setSelectedApp_Id] = useState([]);

  const [showSelectForDownload, setShowSelectForDownload] = useState(false);
  const [selectManually, setSelectManually] = useState(false);
  const [apiRefresh, setApiRefresh] = useState(false);
  const [sortArray, setSortArray] = useState([]);
  const [optionsShow, setOptionsShow] = useState(false);
  const [cardsSelected, setCardsSelected] = useState(false);
  const [appId, setAppId] = useState(null);
  const [Id, setId] = useState();
  const [download_Application, setdowload_Application] = useState([]);

  console.log("cardsSelected", cardsSelected);

  console.log("selectManually", selectManually);
  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    if (winWidth < 769) {
      if (profileSwitcher === "Applications") {
        setApplicationShow(true);
        setOptionsShow(false);
      } else {
        setOptionsShow(true);
      }
    } else {
      setApplicationShow(false);
    }
  };
  useEffect(() => {
    setSelectedApp_Id(no0fCardSelect);
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, []);
  console.log("no0fCardSelect", no0fCardSelect);
  const getApplicationCardDetails = (
    searchString,
    withReferrals,
    minExperience,
    maxExperience,
    qualification,
    location,
    jobSearchStatus,
    sortBy
  ) => {
    getApplicationJobListing(
      1,
      10,
      jobId,
      selectedStatus,
      withReferrals,
      minExperience,
      maxExperience,
      qualification,
      location,
      jobSearchStatus,
      searchString,
      sortBy
    )?.then((res) => {
      setApplicationCardDetailsList(res?.data?.data);
      setAppId(res?.data?.data?.applicationList);
    });
    downloadApplicationCount(jobId ,selectedStatus).then((res) => {
      setdowload_Application(res?.data?.data);
      console.log("download_Application", res?.data?.data);
    });
  };
  useEffect(() => {
    getApplicationCardDetails();
    setId(jobId);
  }, [selectedStatus]);



  useEffect(() => {
    if (apiRefresh === true) {
      getApplicationCardDetails();
      setApiRefresh(false);
    }
  }, [apiRefresh]);
  const applicationStatus = [
    {
      name: "Received",
      status: "APPLIED",
      count:
        applicationCardDetailsList?.statusCount?.hasOwnProperty("APPLIED") &&
        applicationCardDetailsList?.statusCount?.APPLIED !== ""
          ? applicationCardDetailsList?.statusCount?.APPLIED
          : 0,
      newCount:  applicationCardDetailsList?.newAppliedCount >0 ? applicationCardDetailsList?.newAppliedCount :"",
    },
    {
      name: "Shortlisted",
      status: "SHORTLISTED",
      count:
        applicationCardDetailsList?.statusCount?.hasOwnProperty(
          "SHORTLISTED"
        ) && applicationCardDetailsList?.statusCount?.SHORTLISTED !== ""
          ? applicationCardDetailsList?.statusCount?.SHORTLISTED
          : 0,
      newCount: "0",
    },
    {
      name: "Assessment",
      status: "ASSESSMENT",
      count:
        applicationCardDetailsList?.statusCount?.hasOwnProperty("ASSESSMENT") &&
        applicationCardDetailsList?.statusCount?.ASSESSMENT !== ""
          ? applicationCardDetailsList?.statusCount?.ASSESSMENT
          : 0,
      newCount: "0",
    },
    {
      name: "Offered",
      status: "OFFERED",
      count:
        applicationCardDetailsList?.statusCount?.hasOwnProperty("OFFERED") &&
        applicationCardDetailsList?.statusCount?.OFFERED !== ""
          ? applicationCardDetailsList?.statusCount?.OFFERED
          : 0,
      newCount: "0",
    },
    {
      name: "Joined",
      status: "JOINED",
      count:
        applicationCardDetailsList?.statusCount?.hasOwnProperty("JOINED") &&
        applicationCardDetailsList?.statusCount?.JOINED !== ""
          ? applicationCardDetailsList?.statusCount?.JOINED
          : 0,
      newCount: "0",
    },
    {
      name: "Rejected",
      status: "REJECTED",
      count:
        applicationCardDetailsList?.statusCount?.hasOwnProperty("REJECTED") &&
        applicationCardDetailsList?.statusCount?.REJECTED !== ""
          ? applicationCardDetailsList?.statusCount?.REJECTED
          : 0,
      newCount: "0",
    },
    {
      name: "Dropout",
      status: "RETRACTED",
      count:
        applicationCardDetailsList?.statusCount?.hasOwnProperty("RETRACTED") &&
        applicationCardDetailsList?.statusCount?.RETRACTED !== ""
          ? applicationCardDetailsList?.statusCount?.RETRACTED
          : 0,
      newCount: "0",
    },
  ];

  const selectForDownloads = [
    {
      name: "Flagged but not downloaded",
      value:
        download_Application?.FLAGGED_NOT_DOWNLOADED === ""
          ? "0"
          : download_Application?.FLAGGED_NOT_DOWNLOADED,
    },
    {
      name: "All Flagged",
      value:
        download_Application?.ALL_FLAGGED === ""
          ? "0"
          : download_Application?.ALL_FLAGGED,
    },
    {
      name: "All Downloaded",
      value:
        download_Application?.ALL_DOWNLOADED === ""
          ? "0"
          : download_Application?.ALL_DOWNLOADED,
    },
    {
      name: "Select All",
      value: download_Application?.ALL === "" ? "0" : download_Application?.ALL,
    },
    ,
    {
      name: "Select Manually"
    },
  ];
  const location = useLocation();
  useEffect(() => {
    if (location?.hash === "#insignts") {
      setProfileSwitcher("Insights");
    } else if (location?.hash === "#actions") {
      setProfileSwitcher("Actions");
    } else {
      setProfileSwitcher("Applications");
    }
  }, []);

  useEffect(() => {
    if (profileSwitcher === "Actions") {
      window.location.hash = "#actions";
    } else if (profileSwitcher === "Insights") {
      window.location.hash = "#insignts";
    } else if (location?.hash === "#application") {
      setProfileSwitcher("Applications");
    } else {
      window.location.hash = "#applications";
    }
  }, [profileSwitcher]);

  const dropdownSortArray = [
    "New Applications First",
    "New Referrals First",
    "Flagged First",
    "Not Flagged First",
    "Downloaded First",
    "Not Downloaded First",
    "Latest First",
    "Oldest First",
    "Experience - More to Less",
    "Experience - Less to More",
    "A-z",
  ];

  const sortAssesmentArray = [
    "Experience - More to Less",
    "Experience - Less to More",
    "A-z",
  ];

  const sortOfferedArray = ["Offer Date", "A-z"];
  const sortJoinedArray = ["Joining Date", "A-z"];
  const sortRejectedArray = [
    "Rejection Date - Latest First",
    "Rejection Date - Earliest First",
    "Experience - More to Less",
    "Experience - Less to More",
    "A-z",
  ];
  const sortDropoutArray = ["Dropout Date", "A-z"];

  useEffect(() => {
    if (
      location?.hash === "#application/APPLIED" ||
      // location?.hash === "#application" ||
      location?.hash === "#application"
    ) {
      setSelectedStatus("APPLIED");
      setSortArray(dropdownSortArray);
      setProfileSwitcher("Applications");
    } else if (location?.hash === "#application/SHORTLISTED") {
      setSelectedStatus("SHORTLISTED");
      setSortArray(sortAssesmentArray);
      setProfileSwitcher("Applications");
    } else if (location?.hash === "#application/ASSESSMENT") {
      setSelectedStatus("ASSESSMENT");
      setSortArray(sortAssesmentArray);
      setProfileSwitcher("Applications");
    } else if (location?.hash === "#application/OFFERED") {
      setSelectedStatus("OFFERED");
      setSortArray(sortOfferedArray);
      setProfileSwitcher("Applications");
    } else if (location?.hash === "#application/JOINED") {
      setSelectedStatus("JOINED");
      setSortArray(sortJoinedArray);
      setProfileSwitcher("Applications");
    } else if (location?.hash === "#application/REJECTED") {
      setSelectedStatus("REJECTED");
      setSortArray(sortRejectedArray);
      setProfileSwitcher("Applications");
    } else if (location?.hash === "#application/RETRACTED") {
      setSelectedStatus("RETRACTED");
      setSortArray(sortDropoutArray);
      setProfileSwitcher("Applications");
    } else if (location?.hash === "#actions") {
      setProfileSwitcher("Actions");
    } else if (location?.hash === "#insignts") {
      setProfileSwitcher("Insights");
    } else {
      setSelectedStatus("APPLIED");
      setSortArray(dropdownSortArray);
    }
  }, [location]);

  const [sortDropdownRedDot, setSortDropdownRedDot] = useState("d-none");
  const [filtersDropdownRedDot, setFiltersDropdownRedDot] = useState("d-none");
  const [allFiltersDropdownRedDot, setAllFiltersDropdownRedDot] =
    useState(false);

  useEffect(() => {
    if (arrayIndex == null) {
      setSortDropdownRedDot("d-none");
    } else {
      setSortDropdownRedDot("d-block");
    }
  }, [arrayIndex]);

  //  useEffect(() => {
  //    if () {
  //      setFiltersDropdownRedDot("d-none");
  //    } else {
  //      setFiltersDropdownRedDot("d-block");
  //    }
  //  }, [arrayIndex]);

  const role = getLocalStorage("role");

  return (
    <div className="bg-main-application w-100">
      <div className="container">
        <div className="d-flex h-100 mt-0 pt-0 ps-0 flex-fill overflow-x-hidden pt-4">
          {profileSwitcher === "Applications" && (
            <div className="mt-0 pt-0 position-relative">
              <div className="company-details-section">
                <CompanyDetailsSection />
              </div>
            </div>
          )}
          <div
            className={
              profileSwitcher === "Applications"
                ? "px-2 pt-1 pt-lg-4 pb-5 w-100 company-section-less-width"
                : "ps-2 pt-1 pt-lg-4 pb-5 w-100"
            }
          >
            <div className="p-md-4 p-lg-0 pb-3 pb-lg-3 ">
              <div className="d-flex align-items-center">
                <div className="d-flex mb-4 col-lg-4 col-md-3 col-12 pt-2 pt-md-0 d-md-block d-none">
                  <Link
                    to={(role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) ? JOB_POSTS_PAGE_ROUTE : `/${ADMIN_JOB_POST_PAGE_ROUTE}`}
                    className="color-primary d-flex gap-2 align-items-center"
                  >
                    <div>
                      <img src={BackButton} alt={BackButton} />
                    </div>
                    <div className="color-primary fs-12 fw-400 back-btn-width">
                      Go Back
                    </div>
                  </Link>
                </div>
                {/* switcher */}
                <div className="col-lg-8 col-md-9 col-12 d-md-block d-none text-start">
                  <div className="">
                    <ProfileSwitcher
                      data={["Applications", "Actions", "Insights"]}
                      value={profileSwitcher}
                      profileSwitcher={profileSwitcher}
                      setProfileSwitcher={setProfileSwitcher}
                      onClick={() => setProfileSwitcher(!profileSwitcher)}
                    />
                  </div>
                </div>
              </div>
              {profileSwitcher === "Applications" && (
                <div className="">
                  {/* subpart starts */}
                  <div className="d-lg-block d-none">
                    <div className="d-flex py-md-4 py-2 ">
                      {applicationStatus?.map((status, index) => {
                        return (
                          <div
                            key={index}
                            className={
                              selectedStatus === status.status
                                ? "subpart-div-after pt-2"
                                : "subpart-div pt-2"
                            }
                            onClick={() => {
                              setSelectedStatus(status.status);
                              window.location.hash = `#application/${status.status}`;
                            }}
                          >
                            <div className="color-primary fs-14 fw-600 d-flex justify-content-center">
                              {status.name}
                            </div>
                            <div className="d-flex gap-2 align-items-center justify-content-center">
                              <div className="color-tertiary fs-11 fw-600">
                                {status.count}
                              </div>
                              {status.newCount > 0 && (
                                <div className="d-flex gap-1 align-items-center">
                                  <div>
                                    <img
                                      src={icPinkDot}
                                      alt="pink-dot icon"
                                      width="6px"
                                    />
                                  </div>
                                  <div className="dark-pink-text fs-11 fw-600">
                                    {status.newCount} New
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* for medium devices to small device ui */}
                  <div className="d-md-none d-block">
                    <div className="d-flex gap-2 pt-3 ">
                      <div className="d-flex gap-3 color-primary align-items-center">
                        <Link
                          to={-1}
                          onClick={() => {
                            setApplicationShow(true);
                            setProfileSwitcher("Applications");
                          }}
                          className="pointer"
                        >
                          <img src={icBack} alt="back-icon" />
                        </Link>
                        <div className="fs-20 fw-700 medium-black-text">
                          {selectedStatus}
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                  </div>

                  {/* filters part */}
                  <div
                    className={
                      cardsSelected === false
                        ? "d-flex justify-content-lg-between flex-lg-row flex-column justify-content-start"
                        : "d-flex justify-content-md-between justify-content-sm-end flex-sm-row flex-column pt-lg-1 pt-md-4 pt-2"
                    }
                  >
                    {!selectManually ? (
                      <div className="d-md-block d-none">
                        <div className="d-flex gap-2 align-items-center">
                          <Dropdown className="page-filter-button down-arrow m-0 p-0">
                            <Dropdown.Toggle
                              variant="none"
                              id="dropdown-basic"
                              className="d-flex p-0 m-0 border-0 bg-transparent align-items-center pointer"
                              style={{ width: "100px" }}
                            >
                              <div>
                                <img
                                  src={icSort}
                                  alt="sort-icon"
                                  className="ms-2"
                                />
                              </div>
                              <div className="fs-12 fw-400">Sort</div>
                              <span
                                className={`${sortDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                              ></span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="fs-12 text-secondary mt-2">
                              {sortArray.map((sort, index) => {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    className="d-flex pb-3 pointer"
                                    onClick={() => {
                                      if (arrayIndex == index) {
                                        setArrayIndex(null);
                                      } else {
                                        setArrayIndex(index);
                                      }
                                      if (sort === "New Applications First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "NEW_APP_FIRST"
                                        );
                                      }
                                      if (sort === "New Referrals First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "NEW_REFERRAL_FIRST"
                                        );
                                      }
                                      if (sort === "Flagged First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "FLAGGED_FIRST"
                                        );
                                      }
                                      if (sort === "Not Flagged First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "NOT_FLAGGED_FIRST"
                                        );
                                      }
                                      if (sort === "Downloaded First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "DOWNLOADED_FIRST"
                                        );
                                      }
                                      if (sort === "Not Downloaded First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "NOT_DOWNLOADED_FIRST"
                                        );
                                      }
                                      if (sort === "Latest First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "LATEST_FIRST"
                                        );
                                      }
                                      if (sort === "Oldest First") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "OLDEST_FIRST"
                                        );
                                      }
                                      if (
                                        sort === "Experience - More to Less"
                                      ) {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "EXP_DESC"
                                        );
                                      }
                                      if (
                                        sort === "Experience - Less to More"
                                      ) {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "EXP_ASC"
                                        );
                                      }
                                      if (sort === "A-z") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "ATOZ"
                                        );
                                      }
                                      if (sort === "Offer Date") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "OFFER_DATE"
                                        );
                                      }
                                      if (sort === "Joining Date") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "JOINING_DATE"
                                        );
                                      }
                                      if (sort === "Dropout Date") {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "DROPOUT_DATE"
                                        );
                                      }
                                      if (
                                        sort === "Rejection Date - Latest First"
                                      ) {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "REJ_LATEST_FIERST"
                                        );
                                      }
                                      if (
                                        sort ===
                                        "Rejection Date - Earliest First"
                                      ) {
                                        getApplicationCardDetails(
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          null,
                                          "REJ_EARLIEST_FIERST"
                                        );
                                      }
                                    }}
                                  >
                                    <img
                                      src={blackdone}
                                      alt="blackdone-icon"
                                      className={
                                        arrayIndex === index
                                          ? "pe-2 d-block"
                                          : "d-none pe-1"
                                      }
                                    />{" "}
                                    <span
                                      className={
                                        arrayIndex === index
                                          ? "ps-0 color-primary fw-700"
                                          : "ps-4"
                                      }
                                    >
                                      {sort}
                                    </span>
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>

                          <div className="page-filter-button down-arrow p-1">
                            <div
                              className="d-flex gap-1 align-items-center pointer"
                              onClick={() => setShowFilter(true)}
                            >
                              <div>
                                <img
                                  src={icFilter}
                                  alt="sort-icon"
                                  className="ms-2"
                                />
                              </div>
                              <div>Filters</div>
                              <div
                                className={`${filtersDropdownRedDot} round-red-dot rounded-circle mb-2`}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          selectManually
                            ? "col-lg-4 col-12 pb-3 pb-lg-0"
                            : "col-sm-4 col-12 pb-3 pb-sm-0"
                        }
                      >
                        <div className="d-flex flex-column">
                          <div className="color-primary fs-16 fw-700">
                            Download Applications
                          </div>
                          <div className="color-tertiary fs-14">
                            Select manually ({" "}
                            {noOfSelect?.length === ""
                              ? "0"
                              : no0fCardSelect?.length  }
                            {""}Selected)
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="w-100">
                      <div className="d-flex flex-sm-row flex-column justify-content-end align-items-center">
                        <div
                          className={
                            selectManually
                              ? "col-sm-6 order-last order-sm-first col-12"
                              : "col-lg-3 col-md-6 col-sm-6 order-last order-sm-first col-12"
                          }
                        >
                          <div
                            className={
                              selectManually
                                ? "d-flex justify-content-between gap-2 mt-3 mt-sm-0 me-2 select-for-download-btns"
                                : "mt-3 mt-sm-0 me-2"
                            }
                          >
                            <button
                              // disabled={
                              //   selectManually === false ? true : false
                              // }
                              className={
                                selectManually || !cardsSelected
                                  ? "btn btn-dark fs-12 fw-600 w-100 pointer"
                                  : "btn btn-download-disabled w-100 pointer"
                              }
                              onClick={() => setShowSelectForDownload(true)}
                              style={{ width: "156px" }}
                            >
                              { selectManually
                                ? "Download Selected"
                                : "Select for Download"
                                
                              }
                            </button>
                            {selectManually && (
                              <button
                                style={{ height: "32px", width: "110px" }}
                                className="btn-close-action d-flex justify-content-center align-items-center"
                                onClick={() => setSelectManually(false)}
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="order-first order-sm-last col-12 col-sm-6 col-md-5 col-lg-4 ">
                          <div className="pointer">
                            <input
                              className="search-btn w-100 mt-3 mt-sm-0"
                              type="text"
                              placeholder="Search"
                              onChange={(event) =>
                                getApplicationCardDetails(
                                  event.target.value,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null,
                                  null
                                )
                              }
                            />
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* card part starts */}
                  <div>
                    {selectedStatus === "APPLIED" && (
                      <ApplicationCard
                        applicationCardDetailsList={applicationCardDetailsList}
                        getApplicationCardDetails={() =>
                          getApplicationCardDetails(
                            // 1,
                            // 10,
                            // jobId,
                            // selectedStatus,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )
                        }
                        jobId={jobId}
                        selectedStatus={selectedStatus}
                        appId={appId}
                        selectManually={selectManually}
                        setSelectManuallyClicked={setSelectManually}
                        setSelectManually={setSelectManually}
                        setNoOfSelect={setNoOfSelect}
                        setno0fCardSelect={setno0fCardSelect}
                        no0fCardSelect={no0fCardSelect}
                        setCardsSelected={setCardsSelected}
                        cardsSelected={cardsSelected}
                        setApiRefresh={setApiRefresh}
                      />
                    )}
                    {selectedStatus === "SHORTLISTED" && (
                      <ApplicationCardShortlisted
                        isAssessment={false}
                        applicationCardDetailsList={applicationCardDetailsList}
                        getApplicationCardDetails={() =>
                          getApplicationCardDetails(
                            // 1,
                            // 10,
                            // jobId,
                            // selectedStatus,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )
                        }
                        jobId={jobId}
                        selectedStatus={selectedStatus}
                      />
                    )}
                    {selectedStatus === "ASSESSMENT" && (
                      <ApplicationCardShortlisted
                        isAssessment={true}
                        applicationCardDetailsList={applicationCardDetailsList}
                        getApplicationCardDetails={() =>
                          getApplicationCardDetails(
                            // 1,
                            // 10,
                            // jobId,
                            // selectedStatus
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )
                        }
                        jobId={jobId}
                        selectedStatus={selectedStatus}
                      />
                    )}
                    {selectedStatus === "OFFERED" && (
                      <ApplicationCardOffered
                        applicationCardDetailsList={applicationCardDetailsList}
                        getApplicationCardDetails={() =>
                          getApplicationCardDetails(
                            // 1,
                            // 10,
                            // jobId,
                            // selectedStatus
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )
                        }
                        jobId={jobId}
                        selectedStatus={selectedStatus}
                      />
                    )}
                    {selectedStatus === "JOINED" && (
                      <ApplicationCardJoined
                        applicationCardDetailsList={applicationCardDetailsList}
                        getApplicationCardDetails={() =>
                          getApplicationCardDetails(
                            // 1,
                            // 10,
                            // jobId,
                            // selectedStatus
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )
                        }
                        jobId={jobId}
                        selectedStatus={selectedStatus}
                      />
                    )}
                    {selectedStatus === "REJECTED" && (
                      <ApplicationCardRejected
                        applicationCardDetailsList={applicationCardDetailsList}
                        getApplicationCardDetails={() =>
                          getApplicationCardDetails(
                            // 1,
                            // 10,
                            // jobId,
                            // selectedStatus
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )
                        }
                        jobId={jobId}
                        selectedStatus={selectedStatus}
                      />
                    )}
                    {selectedStatus === "RETRACTED" && (
                      <ApplicationCardDropout
                        applicationCardDetailsList={applicationCardDetailsList}
                        getApplicationCardDetails={() =>
                          getApplicationCardDetails(
                            // 1,
                            // 10,
                            // jobId,
                            // selectedStatus
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null,
                            null
                          )
                        }
                        jobId={jobId}
                        selectedStatus={selectedStatus}
                      />
                    )}
                  </div>
                  {/* application responsive */}
                  <div className="d-block d-md-none">
                    {applicationStatus && (
                      <ApplicationSubpart
                        applicationStatus={applicationStatus}
                        setSelectedStatus={setSelectedStatus}
                        arrayIndex={arrayIndex}
                        show={applicationShow}
                        setApplicationShow={setApplicationShow}
                        onApplicationClose={() => setApplicationShow(false)}
                      />
                    )}
                  </div>

                  {/* filter-sort responsive */}
                  <div className="position-fixed bottom-0 d-block d-md-none col-12 fixed-filter-div">
                    <div className="d-flex ">
                      <div
                        className="d-flex gap-2 col-6 justify-content-center  align-items-center p-2 pointer"
                        // {sortArray.map((sort, index) => {  
                        onClick={() => {
                          setSortShow(true);
                        
                        }}
                        
                        
                        
                        
                        
                      >
                        <div>
                          <img alt="sort-icon" src={icSort} />
                        </div>
                        <div className="color-primary fs-12">Sort By</div>
                        <span
                          className={`${sortDropdownRedDot} round-red-dot rounded-circle mb-2`}
                        
                        ></span>
                      </div>
                      <div
                        className="d-flex gap-2 col-6 justify-content-center align-items-center p-2 border-start mt-1 mb-1 pointer"
                        onClick={() => setShowFilter(true)}
                      >
                        <div>
                          <img alt="filter-icon" src={icFilter} />
                        </div>
                        <div className="color-primary fs-12">Filter</div>
                        {allFiltersDropdownRedDot && (
                          <div
                            className={`round-red-dot rounded-circle mb-2`}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {profileSwitcher !== "Applications" && (
                <div className="d-md-none d-block">
                  <div className="d-flex gap-2 pt-3 justify-content-between align-items-center">
                    <div className="d-flex gap-3 color-primary align-items-center">
                      <Link
                        to={-1}
                        onClick={() => {
                          setApplicationShow(true);
                        }}
                        className="pointer"
                      >
                        <img src={icBack} alt="back-icon" />
                      </Link>
                      <div className="fs-20 fw-700 medium-black-text">
                        {profileSwitcher}
                      </div>
                    </div>
                    <div onClick={() => setOptionsShow(true)} className="pe-1">
                      <img
                        src={icDown}
                        alt="down-icon"
                        className=""
                        width="15px"
                      />
                    </div>
                  </div>
                  <hr></hr>
                </div>
              )}
              {profileSwitcher === "Actions" && <ActionsComponent />}
              {profileSwitcher === "Insights" && (
                <InsightsComponent
                  applicationCardDetailsList={applicationCardDetailsList}
                  setApplicationShow={setApplicationShow}
                  setOptionsShow={setOptionsShow}
                  Id={Id}
                />
              )}
              {/* dialog boxes */}
              <FilterDialog
                show={showFilter}
                onFilterClose={() => setShowFilter(false)}
                setFiltersDropdownRedDot={setFiltersDropdownRedDot}
                allFiltersDropdownRedDot={allFiltersDropdownRedDot}
                setAllFiltersDropdownRedDot={setAllFiltersDropdownRedDot}
                getApplicationCardDetails={getApplicationCardDetails}
              />
              <SortDialog
                dropdownSortArray={sortArray}
                show={sortShow}
                onSortClose={() => setSortShow(false)}
                arrayIndex={arrayIndex}
                setArrayIndex={setArrayIndex}
                getApplicationCardDetails={getApplicationCardDetails}
              />
              <SelectForDownload
                downloadOptions={selectForDownloads}
                show={showSelectForDownload}
                setShowSelectForDownload={setShowSelectForDownload}
                onBtnClose={() => setShowSelectForDownload(false)}
                setSelectManuallyClicked={setSelectManually}
                SelectManuallyClicked={selectManually}
                cardsSelected={cardsSelected}
                Application={download_Application}
                no0fCardSelect={no0fCardSelect}
                selectedApp_Id={selectedApp_Id}
                setApiRefresh={setApiRefresh}
                jobId={jobId}
              />
              {applicationShow && (
                <ApplicationSubpart
                  applicationStatus={applicationStatus}
                  setSelectedStatus={setSelectedStatus}
                  arrayIndex={arrayIndex}
                  show={applicationShow}
                  optionsShow={optionsShow}
                  onApplicationClose={() => {
                    setApplicationShow(false);
                  }}
                />
              )}
              <ApplicationOptions
                show={optionsShow}
                onOptionsClose={() => setOptionsShow(false)}
                applicationClose={() => {
                  setApplicationShow(false);
                }}
                setProfileSwitcher={setProfileSwitcher}

              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Applications;
