import React, {useState} from "react";
import {Modal} from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import {isEmpty} from "../../utils/form_validators";
import checkedLogo from "../../assests/ic_selected16.svg";
import {
    deleteMemberTeam,
    REMOVE_MEMBERS_TEAM,
} from "../../_services/view.service";
import toaster from "../../utils/toaster";
import {useParams} from "react-router";

const RemoveMemberDialog = (props) => {
    const [deletemembersteam, setMembersteam] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [teamDetails, setTeamDetails] = useState();
    const removeMembers = () => {
      setShowLoader(true);
      let teamId = props.teamId;
      let userId = props.memberId;
      deleteMemberTeam(teamId, userId)
        .then((res) => {
          setMembersteam(res?.data?.data);
          setShowLoader(false);
          props.close();
          props.getTeamMembers(props.teamId, 1, 10);
        })
        .catch((err) => {
          setShowLoader(false);
          toaster("error", err);
        });
    };

    return (
        <div>
            {" "}
            <Modal
                show={props.show}
                onHide={() => props.close()}
                centered
                backdrop="static"
                keyboard={false}
                className="modal-md"
                contentClassName="modal-content-custom"
            >
                <Modal.Header className=" border-0" bsPrefix="modal-header-custom">
                    <div className="fs-24 color-primary fw-700 px-2 pt-2">
                        Remove Member
                    </div>
                    <img
                        src={icCloseRounded}
                        alt="close-icon"
                        onClick={() => props.close()}
                        className="pointer"
                        height="30px"
                        width="30px"
                    />
                </Modal.Header>
                <Modal.Body>
                    <p className="color-tertiary fs-14 fw-400">
                        {/* Remove {props?.memberId?.email} from “{props?.showEmail}”? */}
                        Remove {props?.email} from “{props?.nameTeam}”
                    </p>
                </Modal.Body>
                <Modal.Footer className="dialog-footer border-0">
                    <button
                        className="btn pointer text-dark fs-12 fw-700  border-dark border-1"
                        onClick={() => props.close()}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn pointer fs-12 fw-700 yes-delete border-0"
                            onClick={() => removeMembers()}
                    >
                        Yes Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default RemoveMemberDialog;
