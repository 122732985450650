import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "../referee_screen/styles.scss";
import downArrow from "../../../assests/downArrow.svg";
import blackdone from "../../../assests/icons/ic_blackdone.svg";
import filterIcon from "../../../assests/icons/ic_sort.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import searchIcon from "../../../assests/icons/ic-search-16.svg";
import RefereeBarChart from "../../../components/referee_bar_chart";
import RefereeProfile from "../../../dialogs/application_dialogs/referee_profile";
import { Link, useSearchParams } from "react-router-dom";

import {
  REFEREE_DETAILS_PAGE_ROUTE,
  REFEREE_ID_PATH_VARIABLE,
} from "../../../config/page_routes_constants";

import RefereeScreenFilterDialog from "../../../dialogs/referee_screen_dialogs/referee_screen_filter_dialog";
import {
  refereeSummary,
  referreList,
} from "../../../_services/member-profile.service";
import { isEmpty } from "../../../utils/form_validators";
import toaster from "../../../utils/toaster";
import ProfileImage from "../../../components/profile_image";
import {
  downloadFile,
  getRefereeProfile,
} from "../../../_services/view.service";
import { isConstructorDeclaration } from "typescript";
import Loader from "../../../components/common/loader";
import { DEFAULT_PAGE_SIZE } from "../../../config/config";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";
import InfiniteScroll from "react-infinite-scroll-component";
import { convertToInternationalCurrencySystem } from "../../../utils/utils";
import checkedLogo from "../../../assests/ic_selected16.svg";

function RefereeScreen(props) {
  let isInfinite = false;

  const [arraySortIndex, setArraySortIndex] = useState(null);
  const [showRefereeProfile, setShowRefereeProfile] = useState(false);
  const [sortShow, setSortShow] = useState(false);
  const [sortDropdownRedDot, setSortDropdownRedDot] = useState("d-none");
  const [showLoader, setShowLoader] = useState(false);
  const [referree_list, setReferree_list] = useState([]);
  const [summary, setSummary] = useState([]);
  const [list, setList] = useState([]);
  const [profileSrc, setProfileSrc] = useState(null);
  const [referralId, setReferralId] = useState();
  const [userId, setUserId] = useState();
  const [refereeProfile, setRefereeProfile] = useState([]);
  const [refereevalue, setRefereeValue] = useState([]);

  const [apiRefresh, setapiRefresh] = useState(false);
  const [roleOutput, setRoleOutput] = useState("Conversion");

  const sortingArray = [
    { name: "Conversion", value: "CONVERSION" },
    { name: "Most Referrals", value: "MOST_REFERRALS" },
    { name: "Most Applications", value: "MOST_APPLICATIONS" },
    { name: "Most Joinees", value: "MOST_JOINESS" },
    { name: "Bonus Amount", value: "BONUSAMOUNT" },
    { name: "A-Z", value: "A_Z" },
  ];
  const [quickFilters, setQuickFilters] = useState([
    {
      name: "Conversion",
      value: "CONVERSION",
    },
    {
      name: "Referrals",
      value: "MOST_REFERRALS",
    },
  ]);
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    // searchString: null,
    // sortBy: null,
  };
  const [analyticsSortByDropdownRedDot, setAnalyticsSortByDropdownRedDot] =
    useState("d-none");

  useEffect(() => {
    if (arraySortIndex == null) {
      setAnalyticsSortByDropdownRedDot("d-none");
    } else {
      setAnalyticsSortByDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  const [dataCountReferee, setDataCountReferee] = useState(0);
  const [currentRefereeOffset, setCurrentRefereeOffSet] = useState(0);
  const [allRefFilterData, setAllRefFilterData] = useState(initialFilterData);
  const [allRefFilterDataParams, setAllRefFilterDataParams] = useSearchParams({
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const fetchRefereeList = (data) => {
    setShowLoader(true);
    referreList(data).then(
      (res) => {
        console.log("responseeee---", res?.data?.totalRecord);
        if (res?.data.status === STATUS_SUCCESS) {
          setDataCountReferee(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...referree_list, ...res?.data?.data];
          }
          setCurrentRefereeOffSet(data?.length);
          setReferree_list(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);

          setList(res?.data?.data[0]);
        } else {
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };
  console.log(
    "check",
    dataCountReferee,
    currentRefereeOffset,
    dataCountReferee > currentRefereeOffset ? true : false
  );

  const fetchMoreReferee = () => {
    setCurrentRefereeOffSet(currentRefereeOffset + 10);
    isInfinite = true;

    fetchRefereeList({
      ...allRefFilterData,
      pageNo: allRefFilterData?.pageNo + 1,
    });
    setAllRefFilterData({
      ...allRefFilterData,
      pageNo: allRefFilterData?.pageNo + 1,
    });
  };

  // useEffect(() => {
  //   fetchRefereeList(allRefFilterData);
  // }, [apiRefresh]);

  // console.log("referree_list?.userId",referree_list?.userId);
  const ViewReferee = (userId) => {
    getRefereeProfile(userId)?.then((res) => {
      setRefereeProfile(res?.data?.data);
    });
  };

  const onview = (userId) => {
    setShowRefereeProfile(true);
    setUserId(userId);
    ViewReferee(userId);
  };
  useEffect(() => {
    if (apiRefresh === true) {
      // ViewReferee();
      setapiRefresh(false);
    }
  }, [apiRefresh]);
  useEffect(() => {
    let temp = { ...initialFilterData };
    for (let entry of allRefFilterDataParams.entries()) {
      if (entry[0] === "searchString") {
        temp = {
          ...temp,
          searchString: entry[1],
        };
      } else if (entry[0] === "index") {
        setArraySortIndex(Number(entry[1]));
      } else if (entry[0] === "sortBy") {
        temp = {
          ...temp,
          sortBy: entry[1],
        };
      }
    }
    fetchRefereeList(temp);
    setAllRefFilterData(temp);
  }, []);

  const dropdownSortHandler = (index, value) => {
    setArraySortIndex(index);
    setAllRefFilterData({
      ...allRefFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    fetchRefereeList({
      ...allRefFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };
  const submitFormOnEnter = (e) => {
    console.log("search", e);
    if (e?.keyCode == 13) {
      setAllRefFilterData(allRefFilterData);
      fetchRefereeList(allRefFilterData);
    }
  };
  const RefereeSummary = () => {
    // setShowLoader(true);
    refereeSummary().then(
      (res) => {
        if (!isEmpty(res)) {
          setSummary(res?.data?.data);
        } else {
          setSummary([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };

  let bonusDisbursed = convertToInternationalCurrencySystem(
    summary?.bonusDisbursed,
    "INR"
  );

  let averageBonus = convertToInternationalCurrencySystem(
    summary?.averageBonus,
    "INR"
  );

  useEffect(() => {
    // fetchRefereeList(allRefFilterData);
    RefereeSummary();
  }, [showRefereeProfile]);

  const refereeBarChartData = {
    referrals: summary?.referrals,
    applications: summary?.applications,
    joinees: summary?.joinees,
    joineesPercentage: summary?.joineesPercentage,
  };

  useEffect(() => {
    if (arraySortIndex == null) {
      setSortDropdownRedDot("d-none");
    } else {
      setSortDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin mt-4">
        <div className="fw-700 fs-24 mt-4">
          Org Referees ({dataCountReferee})
        </div>
        <div className="d-flex justify-content-around flex-column flex-sm-row flex-wrap cards-graph-container mt-3 align-items-sm-end">
          <div className="flex-1">
            <div className="me-0 me-sm-2 title-cards ps-3 d-flex align-items-end">
              <div>
                <div className="fw-700 fs-20">
                  ₹{/* {summary?.bonusDisbursed} */}
                  {bonusDisbursed.toString().length > 3
                    ? bonusDisbursed.toString().substring(0, 4) +
                      bonusDisbursed
                        ?.toString()
                        ?.slice(
                          bonusDisbursed?.length - 1,
                          bonusDisbursed?.length
                        )
                    : bonusDisbursed.toString()}
                  {/* {bonusDisbursed.toString().substring(0,4) + bonusDisbursed?.toString()?.slice(bonusDisbursed?.length - 1, bonusDisbursed?.length)} */}
                </div>

                <div className="small-text-gray fw-400 fs-14 mt-1 mb-4">
                  Bonus Disbursed
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 mt-3 mt-sm-0">
            <div className="me-0 me-sm-2 title-cards ps-3 d-flex align-items-end">
              <div>
                <div className="fw-700 fs-20">
                  ₹
                  {averageBonus.toString().length > 3
                    ? averageBonus.toString().substring(0, 4) +
                      averageBonus
                        ?.toString()
                        ?.slice(averageBonus?.length - 1, averageBonus?.length)
                    : averageBonus.toString()}
                  {/* ₹{averageBonus.toString().substring(0,4) + averageBonus?.toString()?.slice(averageBonus?.length - 1, averageBonus?.length)} */}
                  {/* {summary?.averageBonus} */}
                  {/* if number are greater than zero
                 {summary?.averageBonus.toLocaleString()} */}
                </div>
                <div className="small-text-gray fw-400 fs-14 mt-1 mb-4">
                  Average Bonus
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="referee-bar-chart-width">
              {/* Graph Component */}
              <RefereeBarChart refereeBarChartData={refereeBarChartData} />
              {/* <RefereeBarChart data={refereeBarChartData} /> */}
            </div>
          </div>
        </div>
        <div className="filter-search-container  d-flex justify-content-between mt-5">
          <div className="d-flex">
            <Dropdown className="timeline-select-dropdown me-3 h-28">
              <Dropdown.Toggle
                variant="none"
                // id="dropdown-basic"
                className="btn down-arrow referee-filter-button d-flex py-1 px-2 bg-transparent align-items-center d-flex justify-content-around referee-btn-focus"
              >
                <img src={filterIcon} alt="" className="" />
                <span className="fs-12">Sort By</span>
                <span
                  className={`${analyticsSortByDropdownRedDot} round-red-dot rounded-circle mb-2`}
                ></span>
                <img className="" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                {sortingArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      onClick={() => {
                        setArraySortIndex(index);
                        if (arraySortIndex == index) {
                          dropdownSortHandler(null);
                        } else {
                          dropdownSortHandler(index, sortItem.value);
                        }
                      }}
                      key={index}
                      className="d-flex pb-3"
                    >
                      <img
                        src={blackdone}
                        alt="blackdone-icon"
                        className={
                          arraySortIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          arraySortIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
            {/* <Dropdown className="timeline-select-dropdown" autoClose="outside">
              <Dropdown.Toggle
                variant="none"
                // id="dropdown-basic"
                className="btn down-arrow referee-filter-button d-flex py-1 px-2 bg-transparent align-items-center d-flex justify-content-around"
              >
                <img src={funnelFilterIcon} alt="" className="" />
                <span className="fs-12">Filters</span>
                <img className="" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary ">
                {dropdownRefereeArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item key={index}>
                      <div
                        className="d-flex pb-3"
                        style={{ zIndex: 1000 }}
                        onClick={(e) => {
                          dropdownAllTeamsHandler(index);
                        }}
                      >
                        <input
                          type="checkbox"
                          className="me-2"
                          id={index}
                          name={index}
                          checked={
                            arrayRefereeIndex.includes(index) ? true : false
                          }
                          onClick={(e) => {
                            dropdownAllTeamsHandler(index);
                          }}
                        />{" "}
                        <label>{sortItem}</label>
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown> */}
            <div className="flex-wrap gap-2 d-none d-xl-flex h-28 referee-btn-focus">
              {quickFilters?.map((output, index) => {
                return (
                  <div
                    className="fs-12   h-28 d-flex align-items-center px-2 gap-1 border  rounded-pill pointer referee-btn-focus"
                    key={index}
                    onClick={() => {
                      dropdownSortHandler(index, output.value);
                      setRoleOutput(`${output.name}`);
                    }}
                  >
                    {roleOutput.includes(output.name) ? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      ></img>
                    ) : (
                      ""
                    )}

                    <div>{output.name} </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="referee-search-input-div position-relative pointer referee-btn-focus ">
            <input
              className="referee-search-input p-2 fs-12 referee-btn-focus "
              placeholder="Search"
              defaultValue={allRefFilterData?.searchString}
              onChange={(event) => {
                setAllRefFilterData({
                  ...allRefFilterData,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
              // onChange={(e) => fetchRefereeList(e.target.value, null)}
            />
            <img className="referee-search-icon-position" src={searchIcon} />
          </div>
        </div>
        <InfiniteScroll
          dataLength={currentRefereeOffset}
          next={() => fetchMoreReferee()}
          hasMore={dataCountReferee > currentRefereeOffset}
          className="container-fluid"
        >
          {/* Table */}
          <div className="table-responsive referee-table mt-4 mb-5">
            <table className="mt-0 justify-content-between table">
              <thead className="w-100">
                <tr>
                  <th className="gap-2 text-left" style={{ width: "220px" }}>
                    <div className="fs-12 fw-700 ps-4">Referee</div>
                  </th>
                  <th className="gap-2 text-center" style={{ width: "100px" }}>
                    <div className="fs-12 fw-700">Referrals</div>
                  </th>
                  <th className="gap-2 text-center" style={{ width: "100px" }}>
                    <div className="fs-12 fw-700">Applications</div>
                  </th>
                  <th className="gap-2 text-center" style={{ width: "100px" }}>
                    <div className="fs-12 fw-700">Joinees</div>
                  </th>
                  <th className="gap-2 text-center" style={{ width: "100px" }}>
                    <div className="fs-12 fw-700">Conversion</div>
                  </th>
                  <th className="gap-2 text-left " style={{ width: "200px" }}>
                    <div className="fs-12 fw-700 ms-4">Bonus</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataCountReferee && dataCountReferee > 0
                  ? referree_list?.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            className="card-parent fs-14 position-relative"
                          >
                            <td>
                              <div className="d-flex gap-2 align-items-center ps-4">
                                <div>
                                  {/* {item.profilePIc === "" ||
                                  item.profilePIc === undefined ? (
                                  <ProfileImage
                                    src={profileImg}
                                    width="40px"
                                    height="40px"
                                    initialsContainerClass="rounded-circle"
                                  />
                                ) : ( */}
                                  <ProfileImage
                                    src={
                                      process.env.REACT_APP_IMAGE_BASEURL +
                                      item.profilePIc
                                    }
                                    width="40px"
                                    height="40px"
                                    initialsContainerClass="rounded-circle"
                                  />
                                  {/* )} */}
                                </div>

                                <div>
                                  <div
                                    className="fs-14 fw-400 header-ellipse-1"
                                    style={{
                                      color: "#262626",
                                      maxWidth: "120px",
                                    }}
                                  >
                                    {item?.name}
                                  </div>
                                  <div
                                    style={{
                                      maxWidth: "120px",
                                    }}
                                    className="small-text-gray header-ellipse-1"
                                  >
                                    {item?.designation}
                                  </div>
                                  <div
                                    style={{
                                      maxWidth: "120px",
                                    }}
                                    className="small-text-gray header-ellipse-1"
                                  >
                                    {item?.company}
                                  </div>
                                </div>
                              </div>
                              <div className="action-card action-card-referee-position position-absolute">
                                <div className="d-flex align-items-center ">
                                  <div
                                    onClick={() => {
                                      // setShowRefereeProfile(true);
                                      // setUserId(item.userId);

                                      onview(item?.userId);
                                    }}
                                    className="link"
                                  >
                                    <button className="text-decoration-none btn btn-outline-dark fs-12 fw-700 text-dark bg-light px-3 py-2 referee-btn-focus ">
                                      View Profile
                                    </button>
                                  </div>
                                  <div className="link ms-2">
                                    <Link
                                      to={
                                        REFEREE_DETAILS_PAGE_ROUTE +
                                        `/${item.userId}`
                                      }
                                      className=" text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark px-3 py-2 referee-btn-focus "
                                    >
                                      Details
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle text-center">
                              <div>{item?.referrals}</div>
                            </td>
                            <td className="align-middle text-center">
                              <div>{item?.applications}</div>
                            </td>
                            <td className="align-middle text-center">
                              <div> {item?.joiness}</div>
                            </td>
                            <td className="align-middle text-center">
                              <div> {item?.conversionPerc}%</div>
                            </td>
                            <td className="align-middle text-left">
                              <div className="ms-3"> {item?.bonus}</div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : "No Data Found"}
              </tbody>
            </table>
          </div>
          {/* Table end */}
        </InfiniteScroll>
        {/* Small Table start */}
        <div className=" referee-table-small-display mt-4 mb-5">
          {dataCountReferee && dataCountReferee > 0
            ? referree_list?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="small-referee-cards d-flex flex-column justify-content-center align-items-center mt-3 py-2 position-relative"
                  >
                    <div className="w-100 d-flex ms-4 mb-2">
                      <div className="d-flex align-items-center">
                        <img
                          style={{ width: "36px" }}
                          className="rounded-circle"
                          src={profileImg}
                        />
                      </div>
                      <div className="ms-2">
                        <div
                          style={{ maxWidth: "55vw" }}
                          className="fw-400 fs-12 header-ellipse-1"
                        >
                          {item?.name}
                        </div>
                        <div
                          style={{ maxWidth: "55vw" }}
                          className="small-text-gray fw-400 fs-12 header-ellipse-1"
                        >
                          {item?.designation}
                        </div>
                        <div
                          style={{ maxWidth: "55vw" }}
                          className="small-text-gray fw-400 fs-12 header-ellipse-1"
                        >
                          {item?.company}
                        </div>
                      </div>
                    </div>
                    <div className="w-100 d-flex ms-4 mt-2 row-6 mb-2">
                      <div className="w-100">
                        <div className="fw-600 fs-11">Referrals</div>
                        <div className="fs-14 fw-400">{item?.referrals}</div>
                      </div>
                      <div className="w-100">
                        <div className="fw-600 fs-11">Applications</div>
                        <div className="fs-14 fw-400">{item?.applications}</div>
                      </div>
                    </div>
                    <div className="w-100 d-flex ms-4 mt-2 row-6 mb-2">
                      <div className="w-100">
                        <div className="fw-600 fs-11">Joinees</div>
                        <div className="fs-14 fw-400">{item?.joiness}</div>
                      </div>
                      <div className="w-100">
                        <div className="fw-600 fs-11">Conversion</div>
                        <div className="fs-14 fw-400">{item?.conversion}</div>
                      </div>
                    </div>
                    <div className="w-100 d-flex ms-4 mt-2 mb-2">
                      <div>
                        <div className="fw-600 fs-11">Bonus</div>
                        <div className="fs-14 fw-400">{item?.bonus}</div>
                      </div>
                    </div>
                    <div className="w-100 referee-small-card-btn p-2">
                      <div className="row gx-2 gy-3 hover-bg pt-5">
                        <div className="col-12 col-sm-6">
                          <button
                            onClick={() => {
                              // setShowRefereeProfile(true)
                              // setUserId(item.userId);
                              onview(item?.userId);
                            }}
                            type="button"
                            className="w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-dark bg-light"
                          >
                            View Profile
                          </button>
                        </div>
                        <div className="col-12 col-sm-6">
                          <Link
                            to={REFEREE_DETAILS_PAGE_ROUTE + `/${item.userId}`}
                            className="w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                          >
                            Details
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>

        {/* Small Table end */}

        {/* Filter and Sort Table */}

        <div className="position-fixed bottom-0 d-block d-sm-none referee-fixed-sort-div d-flex justify-content-center w-100">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="d-flex p-2 pointer"
              onClick={() => setSortShow(true)}
            >
              <div>
                <img alt="sort-icon" src={filterIcon} />
              </div>
              <div style={{ marginTop: "2px" }} className="color-primary fs-12">
                Sort By
              </div>
              <span
                className={`${sortDropdownRedDot} round-red-dot rounded-circle ms-1 mt-1`}
              ></span>
            </div>
          </div>
        </div>

        {/* Dialogs */}
        <RefereeProfile
          show={showRefereeProfile}
          showRefereeProfile={showRefereeProfile}
          onRefereeProfileClose={() => setShowRefereeProfile(false)}
          userId={userId}
          refereeProfile={refereeProfile}
        />

        <RefereeScreenFilterDialog
          show={sortShow}
          onSortClose={() => setSortShow(false)}
          sortArray={sortingArray}
          arraySortIndex={arraySortIndex}
          setArraySortIndex={setArraySortIndex}
        />
      </div>
    </div>
  );
}

export default RefereeScreen;
