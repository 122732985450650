import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import aicon from "../../assests/icons/ic_aicon.svg";
import playstore from "../../assests/icons/ic_playstore.svg";
import linkedin from "../../assests/icons/ic_linkedin.svg";
import youtube from "../../assests/icons/ic_youtube.svg";
import twitter from "../../assests/icons/ic_twitter.svg";
import facebook from "../../assests/icons/ic_facebook.svg";
import toaster from "../../utils/toaster";
import { create } from "../../_services/company.service";
import Loader from "../../components/common/loader";
import { STATUS_200, STATUS_SUCCESS } from "../../config/be_api_constants";
import vicon from "../../assests/icons/ic_vicon.svg";

const CompanyPresence = (props) => {
  const [Apple, setApple] = useState();
  const [store, setstore] = useState();
  const [twitt, setTwitt] = useState();
  const [Facebook, setFacebock] = useState();
  const [Youtube, setYouTube] = useState();
  const [Vicon, setVicon] = useState();
  const [LinkedIn, setLinkedIn] = useState();
  const [websites, setWebsites] = useState();
  const [showLoader, setShowLoader] = useState(false);

  const [prasence, setPrasence] = useState([]);
  // { linkTitle: "", linkUrl: "" }
  useEffect(() => {
    setWebsites(props.presence?.WEBSITE);
    setApple(props.presence?.APPLESTORE);
    setstore(props.presence?.PLAYSTORE);
    setTwitt(props.presence?.TWITTER);
    setFacebock(props.presence?.FACEBOOK);
    setYouTube(props.presence?.YOUTUBE);
    setVicon(props.presence?.VICON);
    setLinkedIn(props.presence?.LINKEDIN);
  }, []);
  const changeURl = (e, linkTitle) => {
    setPrasence({
      linkTitle: linkTitle,
      linkUrl: e.target.value,
    });
    const newpresence = [...prasence];
    newpresence.push({ linkTitle: linkTitle, linkUrl: e.target.value });
    setPrasence(newpresence);
  };
  // useEffect(() => {

  // }, []);

  // const newpresence = [...prasence];
  // newpresence.push({ prasence: "" });
  // setPrasence(newpresence);

  const saveLink = {
    companyId: props.companyId,
    presence: prasence,
  };
  const SavePresence = () => {
    setShowLoader(true);
    create(saveLink)
      .then((res) => {
        setShowLoader(false);
        props?.setApiRefresh(true);
        // if (res?.data?.status == STATUS_SUCCESS) {
        //   toaster(
        //     "success",
        //     res?.data?.message ? res?.data?.message : STATUS_SUCCESS
        //   );
        //   // setShowLoader(false);
        // }
        setShowLoader(false);
        if (res?.status == STATUS_200) {
          toaster(
            "success",
            res?.data?.message ? res?.data?.message : STATUS_SUCCESS
          );
        }
      })
      .catch((err) => {
        setShowLoader(false);
        props?.setApiRefresh(false);
        toaster("error", err);
      });
  };
  return (
    <>
      {showLoader && <Loader />}
      <div>
        <Modal
          show={props.show}
          onHide={() => props.onPresenceClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal dialog-wrapper"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onPresenceClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">Presence</div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body dialog-body">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600">Website*</div>
                <div className="pt-2 pb-5">
                  <input
                    type="text"
                    name="WEBSITE"
                    //  value={props.presence?.WEBSITE}
                    className="modal-form-input input-color-black"
                    placeholder="www.companyname.com"
                    defaultValue={props.presence?.WEBSITE}
                    onChange={(e) => {
                      changeURl(e, "WEBSITE");
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600 d-flex gap-2">
                  <img src={vicon} alt="aicon-icon" />
                  <div>Vicon</div>
                </div>
                <div className="pt-2 pb-5">
                  <input
                    type="text"
                    className="modal-form-input input-color-black"
                    name="APPLESTORE"
                    value={props.prasence?.VICON}
                    // checked={userPrivacySettings?.location}
                    placeholder="www.companyname.com"
                    // defaultValue={props.presence?.APPLESTORE}
                    defaultValue={props.presence?.VICON}
                    onChange={(e) => {
                      changeURl(e, "VICON");
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600 d-flex gap-2">
                  <img src={aicon} alt="aicon-icon" />
                  <div>iOS App Link </div>
                </div>
                <div className="pt-2 pb-5">
                  <input
                    type="text"
                    className="modal-form-input input-color-black"
                    name="APPLESTORE"
                    value={props.prasence?.APPLESTORE}
                    // checked={userPrivacySettings?.location}
                    placeholder="www.companyname.com"
                    // defaultValue={props.presence?.APPLESTORE}
                    defaultValue={props.presence?.APPLESTORE}
                    onChange={(e) => {
                      changeURl(e, "APPLESTORE");
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600 d-flex gap-2">
                  <img src={playstore} alt="playstore-icon" />
                  <div>Android App Link</div>
                </div>
                <div className="pt-2 pb-5">
                  <input
                    type="text"
                    name="PLAYSTORE"
                    className="modal-form-input input-color-black"
                    placeholder="www.companyname.com"
                    value={props.prasence?.PLAYSTORE}
                    defaultValue={props.presence?.PLAYSTORE}
                    onChange={(e) => {
                      changeURl(e, "PLAYSTORE");
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600 d-flex gap-2">
                  <img src={linkedin} alt="linkedin-icon" />
                  <div>LinkedIn</div>
                </div>
                <div className="pt-2 pb-5">
                  <input
                    name="LINKEDIN"
                    type="text"
                    className="modal-form-input input-color-black"
                    placeholder="www.companyname.com"
                    defaultValue={props.presence?.LINKEDIN}
                    onChange={(e) => {
                      changeURl(e, "LINKEDIN");
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600 d-flex gap-2">
                  <img src={youtube} alt="youtube-icon" />
                  <div>Youtube</div>
                </div>
                <div className="pt-2 pb-5">
                  <input
                    name="YOUTUBE"
                    type="text"
                    className="modal-form-input input-color-black"
                    placeholder="www.companyname.com"
                    defaultValue={props.presence?.YOUTUBE}
                    onChange={(e) => {
                      changeURl(e, "YOUTUBE");
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600 d-flex gap-2">
                  <img src={twitter} alt="twitter-icon" />
                  <div>Twitter</div>
                </div>
                <div className="pt-2 pb-5">
                  <input
                    name="TWITTER"
                    type="text"
                    className="modal-form-input input-color-black"
                    placeholder="www.companyname.com"
                    defaultValue={props.presence?.TWITTER}
                    onChange={(e) => {
                      changeURl(e, "TWITTER");
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600 d-flex gap-2">
                  <img src={facebook} alt="facebook-icon" />
                  <div>Facebook</div>
                </div>
                <div className="pt-2 pb-5">
                  <input
                    name="FACEBOOK"
                    type="text"
                    className="modal-form-input input-color-black"
                    placeholder="www.companyname.com"
                    defaultValue={props.presence?.FACEBOOK}
                    onChange={(e) => {
                      changeURl(e, "FACEBOOK");
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onPresenceClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                SavePresence();
                props.onPresenceClose();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyPresence;
