import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import icAddBlue from "../../assests/icons/ic-add-blue.svg";
import icDeleteRed from "../../assests/icons/ic-delete-red.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";

const CompanyVideo = (props) => {
  const [selected, setSelected] = useState(false);
  const [video, setVideo] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const saveVideo = {
    companyId: props.companyId,
    videoLink: video,
  };
  const onSaveVideoName = () => {
    if (video === "") {
      toaster("error", "Please Add video");
    } else {
      setShowLoader(true);
      create(saveVideo)
        .then((res) => {
          setShowLoader(false);
          props?.setApiRefresh(true);
          props.onVideoClose();
          // if (res?.data?.status == STATUS_SUCCESS) {
          //   //   toaster(
          //   //     "success",
          //   //     res?.data?.message ? res?.data?.message : STATUS_SUCCESS
          //   //   );
          // }
        })
        .catch((err) => {
          setShowLoader(false);
          props?.setApiRefresh(false);
          toaster("error", err);
        });
    }
  };
  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        onHide={() => props.onVideoClose()}
        backdrop="static"
        fullscreen="lg-down"
        centered
        keyboard={false}
        className="lg-dialog-modal"
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100">
            <div className="d-flex justify-content-end">
              <img
                src={icCloseRounded}
                alt="close-icon"
                onClick={() => props.onVideoClose()}
                className="pointer"
              />
            </div>
            <div className="fs-24 color-primary fw-700 ps-3">Company Video</div>
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body">
          <div className="p-3 pt-0">
            <div>
              <div className="fs-14 color-secondary fw-600">Link to video*</div>
              <div className="pt-3 pb-5">
                <input
                  type="text"
                  className={
                    (video === ""
                      ? "error"
                      : "modal-form-input small-text-medium-gray") +
                    " form-control"
                  }
                  // className="modal-form-input small-text-medium-gray"
                  // placeholder="youtube.com/fuhifi34r"
                  defaultValue={props.videoLink}
                  onChange={(e) => {
                    setVideo(e.target.value);
                  }}
                />
                <div className="ps-3 fs-14 color-tertiary pt-2">
                  Replace "watch?v=" with "embed/" in the link if required
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="dialog-footer">
          <button
            className="btn-dialog-cancel pointer"
            onClick={() => props.onVideoClose()}
          >
            Cancel
          </button>
          <button
            className="btn-dialog-save pointer"
            onClick={() => {
              onSaveVideoName();
              // props.onNameClose();
            }}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CompanyVideo;
