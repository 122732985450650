import React from "react";
import bonusIcon from "../../../../assests/icons/ic-bonus.svg";

const JobCardReferralBonus = ({ text,  jobViewDetails }) => {
  return (
    <div className="package d-flex align-items-center">
      <img src={bonusIcon} alt="salary" />
      {/* &nbsp;{`Referral Bonus - $ ${text ? text : "150"}`} */}
      Referral Bonus - ${jobViewDetails && jobViewDetails.referralBonus}
    </div>
  );
};
export default JobCardReferralBonus;
