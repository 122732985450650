import axios from "axios";
import { TOKEN, ORG_ID } from "../config/localstorage_keys";
import { SESSION_EXPIRED_MESSAGE } from "../config/messages";
import { getLocalStorage, setLocalStorage } from "../utils/storage";
import toaster from "../utils/toaster";

// const getNavigate = (url) => {
//   const navigate = useNavigate();
//   navigate(url);
// };
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 1000 * 30, // Wait for 10 seconds
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => {
    return true;
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = getLocalStorage(TOKEN);
    const organizationId = getLocalStorage(ORG_ID);
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
      //   config.headers["x-access-token"] = token; // for Node.js Express back-end
      config.headers["orgId"] = organizationId;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    if (res?.status === 401 ) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      toaster("error", SESSION_EXPIRED_MESSAGE);
      setTimeout(() => {
        window.location.replace("/login");
      }, 500);
    } else if (res?.headers?.token) {
      setLocalStorage(TOKEN, res?.headers?.token);
    }
    return res;
  },
  async (err) => {}
);

export default instance;
