import icSetting from "../../assests/icons/setting.svg";
import logOut from "../../assests/icons/log-out.svg";
import { useNavigate } from "react-router";
import {
  ACCOUNT_PAGE_ROUTE,
  LOGIN_PAGE_ROUTE,
} from "../../config/page_routes_constants";
import { logout } from "../../_services/auth.service";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";

const HeaderDropDown = (props) => {
  const navigate = useNavigate();
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        props?.show &&
        ref?.current &&
        !ref?.current?.contains(e?.target) &&
        !props?.parentRef?.current?.contains(e?.target)
      ) {
        props.onProfileDropdownClose();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props.show]);

  // const clearCandidateDetails = useStoreActions(
  //   (actions) => actions.candidate.clearCandidateData
  // );
  return (
    <>
      <div className="header-dropdown-container fw-400 fs-12 position-absolute mt-2">
        <div className="">
          <div className="header-dropdown-subcontainer">
            <div className="row ">
              <div className="col-2 pb-2 icon-positioning pointer">
                <img src={icSetting} alt="Setting" />
              </div>
              <div className="col-10 pb-2 px-0">
                <Link
                  to={ACCOUNT_PAGE_ROUTE}
                  className="text-decoration-none color-primary"
                  onClick={() => props.onProfileDropdownClose()}
                >
                  Account Settings
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div
            className="header-dropdown-subcontainer pb-0 pointer"
            onClick={() => {
              logout();
              navigate(LOGIN_PAGE_ROUTE);
            }}
          >
            <div className="row ">
              <div className="col-2 pb-2 icon-positioning pointer">
                <img src={logOut} alt="Log Out" />
              </div>
              <div className="col-10 pb-3 px-0 ">
                <Link
                  to={LOGIN_PAGE_ROUTE}
                  className="text-decoration-none color-primary"
                  onClick={() => props.onProfileDropdownClose()}
                >
                  Log out
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <RefereeProfileEditDialog
        show={showRefereePreferencesDialog}
        onDismissDialogClick={() => setShowRefereePreferencesDialog(false)}
        isShowFlow={true}
      /> */}
    </>
  );
};
export default HeaderDropDown;
