import React, { useEffect, useState } from "react";
import aboutLogo from "../../../../assests/icons/about.svg";
import ProfileImage from "../../../../components/profile_image";
import { downloadFile } from "../../../../_services/view.service";

const RefereeAboutMe = ({ refereeProfile }) => {
  const [profileSrc, setProfileSrc] = useState(null);

  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        setProfileSrc(res);
      } else {
        setProfileSrc(null);
      }
    });
  };

  const getProfilePicture = () => {
    if (
      refereeProfile?.basicDetailsResponse?.profilePicDownloadURL !==
      (undefined || null)
    ) {
      downloadPicture(
        refereeProfile?.basicDetailsResponse?.profilePicDownloadURL
      );
    } else {
      setProfileSrc(null);
    }
  };

  useEffect(() => {
    getProfilePicture();
  }, []);

  return (
    <div>
      <div className="shadow-box">
        <div className="p-3">
          <div className="d-flex gap-3 align-items-center">
            <div className="sidebar-profile-pic">
              {/* <img
                src={require("./../../../../assests/images/profile.jpg")}
                alt="profile-img"
                width="70px"
                className="border-radius"
              /> */}
              <ProfileImage
                src={profileSrc}
                name="profile-img"
                initialsContainerClass="initialsStyle2-xl"
                width="70px"
              />
            </div>
            <div className="pt-1">
              <h4 className="fs-16 fw-600 m-0">
                {refereeProfile?.basicDetailsResponse?.name}
              </h4>
              <div className="d-flex flex-wrap gap-1">
                <span className="fs-16 fw-500">
                  {
                    refereeProfile?.additionalInfoProfileResponse
                      ?.currentDesignation
                  }
                </span>
                <span className="fs-16 fw-400 color-tertiary">at</span>
                <span className="fs-16 fw-500">
                  {refereeProfile?.additionalInfoProfileResponse?.company}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefereeAboutMe;
