import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import backButton from "../../../assests/icons/back-icon.svg";
import icCloseBlue from "../../../assests/icons/ic-close-blue.svg";
import Loader from "../../../components/common/loader";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";
import toaster from "../../../utils/toaster";
import {
  AddFileds,
  delete_MasterApprove,
  delete_MasterDetails,
  getMasterDetails_DataFields,
} from "../../../_services/serikoradmin_view.service";

function ApprovedDetailPage() {
  const { masterType } = useParams();
  const [showLoader, setShowLoader] = useState(false);
  const [masterDetails, setMasterDetails] = useState([]);
  const [apiRefresh, setApiRefresh] = useState(false);
  const [inputFields, setInputFields] = useState({ email: [] });

  // const [master_Values, setMaster_Values] = useState([]);

  const [listA, setListA] = useState([]);
  const [listB, setListB] = useState([]);
  const [listC, setListC] = useState([]);
  const [listD, setListD] = useState([]);
  const [listE, setListE] = useState([]);
  const [listF, setListF] = useState([]);
  const [listG, setListG] = useState([]);
  const [listH, setListH] = useState([]);
  const [listI, setListI] = useState([]);
  const [listJ, setListJ] = useState([]);
  const [listK, setListK] = useState([]);
  const [listL, setListL] = useState([]);
  const [listM, setListM] = useState([]);
  const [listN, setListN] = useState([]);
  const [listO, setListO] = useState([]);
  const [listP, setListP] = useState([]);
  const [listQ, setListQ] = useState([]);
  const [listR, setListR] = useState([]);
  const [listS, setListS] = useState([]);
  const [listT, setListT] = useState([]);
  const [listU, setListU] = useState([]);
  const [listV, setListV] = useState([]);
  const [listW, setListW] = useState([]);
  const [listX, setListX] = useState([]);
  const [listY, setListY] = useState([]);
  const [listZ, setListZ] = useState([]);
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  const [list3, setList3] = useState([]);
  const [list4, setList4] = useState([]);
  const [list5, setList5] = useState([]);
  const [list6, setList6] = useState([]);
  const [list7, setList7] = useState([]);
  const [list8, setList8] = useState([]);
  const [list9, setList9] = useState([]);
  const [list10, setList10] = useState([]);

  const allmasterDetails = (searchString) => {
    setShowLoader(true);
    getMasterDetails_DataFields(0, 20, masterType, searchString)
      .then((res) => {
        setListA(res?.data?.data?.A);
        setListB(res?.data?.data?.B);
        setListC(res?.data?.data?.C);
        setListD(res?.data?.data?.D);
        setListE(res?.data?.data?.E);
        setListF(res?.data?.data?.F);
        setListG(res?.data?.data?.G);
        setListH(res?.data?.data?.H);
        setListI(res?.data?.data?.I);
        setListJ(res?.data?.data?.J);
        setListK(res?.data?.data?.K);
        setListL(res?.data?.data?.L);
        setListM(res?.data?.data?.M);
        setListN(res?.data?.data?.N);
        setListO(res?.data?.data?.O);
        setListP(res?.data?.data?.P);
        setListQ(res?.data?.data?.Q);
        setListR(res?.data?.data?.R);
        setListS(res?.data?.data?.S);
        setListT(res?.data?.data?.T);
        setListU(res?.data?.data?.U);
        setListV(res?.data?.data?.V);
        setListW(res?.data?.data?.W);
        setListX(res?.data?.data?.X);
        setListY(res?.data?.data?.Y);
        setListZ(res?.data?.data?.Z);
        // console.log(res?.data?.data?.[1] ,"data[1]");
        setList1(res?.data?.data?.[1]);
        setList2(res?.data?.data?.[2]);
        setList3(res?.data?.data?.[3]);
        setList4(res?.data?.data?.[4]);
        setList5(res?.data?.data?.[5]);
        setList6(res?.data?.data?.[6]);
        setList7(res?.data?.data?.[7]);
        setList8(res?.data?.data?.[8]);
        setList9(res?.data?.data?.[9]);
        setList10(res?.data?.data?.[10]);
        
        if (res?.data?.status === STATUS_SUCCESS) {
          toaster("success", "Data fetched successfully.");
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  let master_Values = [];
  const Sort = () => {
    masterDetails.map((sort, i) => {
      let sortElement = sort?.masterValue;
      master_Values.push(sortElement);
      // master_Values([...master_Values , sort?.masterValue])
    });
  };

  // const startsWithA = master_Values.filter(
  //   (sort) => sort.startsWith("A") || sort.startsWith("a")
  // );
  // const startsWithB = master_Values.filter(
  //   (sort) => sort.startsWith("B") || sort.startsWith("b")
  // );

  // const startsWithC = master_Values.filter(
  //   (sort) => sort.startsWith("C") || sort.startsWith("c")
  // );
  // const startsWithD = master_Values.filter(
  //   (sort) => sort.startsWith("D") || sort.startsWith("d")
  // );

  // const startsWithA = master_Values.filter(
  //   (sort) => sort.startsWith("A") || sort.startsWith("a")
  // );
  // const startsWithA = master_Values.filter(
  //   (sort) => sort.startsWith("A") || sort.startsWith("a")
  // );
  // const startsWithA = master_Values.filter(
  //   (sort) => sort.startsWith("A") || sort.startsWith("a")
  // );

  const selectDelete = (id) => {
    setShowLoader(true);
    setApiRefresh(true);
    delete_MasterApprove(id)
      .then((res) => {
        // console.log("setShowLoader", res);
        // toaster("success", "Data Deleted successfully.");

        if (res?.status === STATUS_SUCCESS) {
          setShowLoader(false);
          setApiRefresh(true);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setApiRefresh(false);
        setShowLoader(false);
      });
  };

  const [mcode, setMCode] = useState();
  const [mvalue, setMValue] = useState("");

  // const handleChange = (event) => {
  //   // const result = event.target.value.toUpperCase();
  //   setMCode( event.target.value.toUpperCase());
  //   // const value= event.target.value;
  //   setMValue(event.target.value);
  //
  // };

  //   const initializeFieldData = () => {
  //     alert("gii")
  //     setMValue({ mvalue: "" })
  //     console.log(mvalue,"mvalue.........");
  //   };
  //   console.log(mvalue,"mvalue.ee........");
  // useEffect(() => {
  //   initializeFieldData();
  //   console.log(mvalue,"mvalue");
  // }, []);

  const saveData = () => {
    setShowLoader(true);
    setApiRefresh(true);
    let isValid = true;
    if (mvalue == "") {
      isValid = false;
    }
    if (isValid) {
      AddFileds(masterType, mcode, mvalue)
        .then((res) => {
          if (res?.data?.status === STATUS_SUCCESS) {
            setApiRefresh(true);
            toaster("success", "New Tag Added");
            allmasterDetails();
          } else {
            setShowLoader(false);
          }
          setMValue("");
        })
        .catch((err) => {
          setShowLoader(false);
          setApiRefresh(false);
        });
    } else {
      toaster("error", "Please enter value");
    }
  };

  useEffect(() => {
    allmasterDetails();
    if (apiRefresh === true) {
      allmasterDetails();
      setApiRefresh(false);
    }
    Sort();
  }, [apiRefresh]);

  const listCustom = [
    {
      id: 1,
      name: "80 Level manager",
      description: "80 Level manager",
    },
  ];
  return (
    <div className="container">
      {showLoader && <Loader />}

      <div className="content-margin mt-4">
        <div className="">
          <Link
            to={-1}
            className="color-primary d-flex gap-2 align-items-center"
          >
            <div>
              <img src={backButton} alt={backButton} />
            </div>
            <div className="color-primary fs-12 fw-400 back-btn-width">
              Go Back
            </div>
          </Link>
          <div className=" my-2 d-grid  d-md-flex">
            <div className="col fs-24 fw-700">{masterType}</div>
            <div className="page-search m-0 col-12 col-md-5 col-lg-4 mt-2 mt-md-0">
              <input
                className="col-12"
                type="text"
                placeholder="Search"
                onChange={(e) => allmasterDetails(e.target.value)}
              />
            </div>
          </div>
          <div className="d-none d-sm-block">
            <p className="fs-14 fw-600 m-0 py-2">Add new field</p>
            <div className=" m-0 p-0 ">
              <input
                type="text"
                name="mvalue"
                className="modal-form-input small-text-medium-gray text-dark col-md-6 col-12"
                placeholder="Type here"
                value={mvalue}
               
                onChange={(e) => {
                  setMValue(e.target.value);
                  setMCode(e.target.value.toUpperCase());
                }}
              />

              <button
                className="btn-dialog-save pointer mx-3 "
                onClick={() => {
                  saveData();
                }}
              >
                Add Field
              </button>
            </div>
          </div>

          <div className="my-2">
            {listA && listA.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  A
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listA?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listB && listB.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  B
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listB?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listC && listC.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  C
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listC?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listD && listD.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  D
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listD?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listE && listE.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  E
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listE?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listF && listF.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  F
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listF?.map((item, index) => {
                    return (
                      <div
                        className="d-flex blue-badge gap-2 align-items-center"
                        key={index}
                      >
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listG && listG.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  G
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listG?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listH && listH.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  H
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listH?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listI && listI.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  I
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listI?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listJ && listJ.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  J
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listJ?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listK && listK.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  K
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listK?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listL && listL.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  L
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listL?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listM && listM.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  M
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listM?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listN && listN.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  N
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listN?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listO && listO.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  O
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listO?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listP && listP.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  P
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listP?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listQ  ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  Q
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listQ?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listR && listR.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  R
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listR?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listS && listS.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  S
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listS?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listT && listT.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  T
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listT?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listU && listU.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  U
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listU?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listV && listV.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  V
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listV?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listW && listW.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  W
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listW?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listX && listX.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  X
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listX?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {listY && listY.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  Y
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listY?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}

            {listZ && listZ.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  Z
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {listZ?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {list1 && list1.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  1
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list1?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {list2 && list2.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  2
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list2?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {list3 && list3.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  3
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list3?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {list4 && list4.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  4
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list4?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {list5 && list5.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  5
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list5?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            
            {list6 && list6.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  6
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list6?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
             {list7 && list7.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  7
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list7?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
             {list8 && list8.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  8
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list8?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
             {list9 && list9.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  9
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list9?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
             {list10 && list10.length > 0 ? (
              <div className="d-flex gap-4 py-3">
                <div className="" style={{ minWidth: "20px" }}>
                  10
                </div>
                <span className=" d-flex gap-2 flex-wrap ">
                  {list10?.map((item, index) => {
                    return (
                      <div className="d-flex blue-badge gap-2 align-items-center">
                        <div>{item?.masterValues} </div>
                        <img
                          src={icCloseBlue}
                          alt="blue-close-icon"
                          className="pointer d-flex "
                          height="9px"
                          width="9px"
                          onClick={() => selectDelete(item?.id)}
                        />
                      </div>
                    );
                  })}
                </span>
              </div>
            ) : (
              ""
            )}
            {/* <div className="d-flex gap-4 py-3">
              <div className="" style={{ minWidth: "20px" }}>
                +
              </div>
              <span className=" d-flex gap-2 flex-wrap">
                {listCustom?.map((item, index) => {
                  return (
                    <div className="d-flex blue-badge gap-2 align-items-center">
                      <div>{item?.name} </div>
                      <img
                        src={icCloseBlue}
                        alt="blue-close-icon"
                        className="pointer"
                        height="9px"
                        width="9px"
                      />
                    </div>
                  );
                })}
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApprovedDetailPage;
