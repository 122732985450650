import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import icDownArrow from "../../../../assests/icons/ic-down-arrow.svg";
import { STATUS_SUCCESS } from "../../../../config/be_api_constants";
import { DEFAULT_PAGE_SIZE } from "../../../../config/be_seikorAdmin_api_constants";
import { GENERAL_ERROR_MESSAGE, JOB_FETCH_FAILED } from "../../../../config/messages";
import { NOTIFICATION_REFEREE_HASH_ROUTE, NOTIFICATION_TO_REFEREE_PAGE_ROUTE } from "../../../../config/page_routes_constants";
import { isEmpty } from "../../../../utils/form_validators";
import toaster from "../../../../utils/toaster";
import { getActionHistory, getActionHistoryById, sendRefereesNotification } from "../../../../_services/view.service";
import SendNotification from "./send_notification";

const History = ({setSwitchValue, setStoreData}) => {
  let isInfinite = false;

  const [isOpened, setIsOpened] = useState(null);
  const [historyArray, setHistoryArray] = useState(null);
  const[history,setHistory]=useState([]);
  const [showLoader, setShowLoader] = useState(false);
  // const [switchValue, setSwitchValue] = useState("");
  const [sendNotification, setSendNotification] = useState(true);
  const location = useLocation();
  const navigate= useNavigate();
  console.log(location,"location^^^");

  const [list, setList] = useState([]);



  // useEffect(() => {
   
  // }, [switchValue]);

  const onSwitch=(userId)=>{

    // if (switchValue === "Send Notification") {
    //   window.location.hash = "#send-notification";
    // } else if (switchValue === "History") {
    //   window.location.hash = "#history";
    // } else {
    //   window.location.hash = "#send-notification";
    // }
   
    // navigate("/" +
    //   NOTIFICATION_TO_REFEREE_PAGE_ROUTE
    //    +
    //   `/${jobId}#send-notification/${userId}`)


    // if (location?.hash === "#send-notification") {
    //        setSwitchValue("Send Notification");
    //        console.log(switchValue,"switchValue");
    //     if (switchValue === "Send Notification") {
    //       //<SendNotification sendNotification={sendNotification} />
        
    //        }
      
    }
    
  // //   window.location.hash = "#send-notification";
  // // } else if (switchValue === "History") {
  // //   window.location.hash = "#history";
  // // } else {
  // //   window.location.hash = "#send-notification";
  // // }
    
   
  // }
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    notificationSentType :"REFEREE"

    // searchString: null,
    // sortBy: null,
  };
  const [allJobMessage, setAllJobMessage] = useState("");

  const [dataCountHistory, setDataCountHistory] = useState(0);
  const [currentHistoryPageset, setCurrentHistoryPageSet] = useState(0);
  const [allHisFilterData, setAllHisFilterData] = useState(initialFilterData);

  const [apiRefresh, setApiRefresh] = useState(false);

  
  const { jobId } = useParams();
  console.log(jobId,"jobIdjobId")

 const fetchHistory=(data)=>{
  setShowLoader(true);
  setApiRefresh(true);
  getActionHistory(data).then(
    (res) => {
      if (res?.data.status === STATUS_SUCCESS) {
        setHistory(res?.data?.data);
        setDataCountHistory(res?.data?.totalRecord);
        setShowLoader(false);
        let data;
        if (!isInfinite) {
          data = res?.data?.data;
        } else {
          data = [...history, ...res?.data?.data];
        }
        setCurrentHistoryPageSet(data?.length);
        setHistory(data);
        setShowLoader(false);
        setApiRefresh(false);
      } else if (isEmpty(res) && res?.status === 200) {
        setShowLoader(false);
        setApiRefresh(false);
        setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);

        setList(res?.data?.data[0]);
      } else {
        // setHistory([]);
        setShowLoader(false);
      }
    },
    (error) => {
      toaster(
        "error",
        error?.message ? error?.message : GENERAL_ERROR_MESSAGE
      );
      setShowLoader(false);
    }
  );
 } ;
 console.log(
  "check",
  dataCountHistory,
  currentHistoryPageset,
  dataCountHistory> currentHistoryPageset ? true : false
);
// console.log("response history",history);
const fetchMoreHistory = () => {
  setCurrentHistoryPageSet(currentHistoryPageset + 10);
  isInfinite = true;

  fetchHistory({
    ...allHisFilterData,
    pageNo: allHisFilterData?.pageNo + 1,
  });
  setAllHisFilterData({
    ...allHisFilterData,
    pageNo: allHisFilterData?.pageNo + 1,
  });
};

useEffect(() => {
  fetchHistory(allHisFilterData);
}, []);
// useEffect(() => {
//   if (apiRefresh === true) {
//     setapiRefresh(false);
//   }
// }, [apiRefresh]);
useEffect(() => {
  let temp = { ...initialFilterData };
  // for (let entry of allRefFilterDataParams.entries()) {
  //   if (entry[0] === "searchString") {
  //     temp = {
  //       ...temp,
  //       searchString: entry[1],
  //     };
  //   } else if (entry[0] === "index") {
  //     setArraySortIndex(Number(entry[1]));
  //   } else if (entry[0] === "sortBy") {
  //     temp = {
  //       ...temp,
  //       sortBy: entry[1],
  //     };
  //   }
  // }
  fetchHistory(temp);
  setAllHisFilterData(temp);
}, []);

const sendNotificationtoReferees = (locations, keySkills, industries, functions, minYear, maxYear, id) => {
  setShowLoader(true);
  setApiRefresh(true);
  sendRefereesNotification(locations, keySkills, industries, functions, minYear, maxYear, id)
  .then((res) => {
    console.log(res?.data?.message,"res?.statu")
   
    if (res?.status === 200) {
      toaster("success", "Data Fetched Successfully");
      // fetchHistory();
      setShowLoader(false);
      window?.location?.reload();
    // } else {
    //   toaster(
    //     "error",
    //     res?.message ? res?.message : GENERAL_ERROR_MESSAGE
    //   );
    }
  })
  .catch((err) => {
    setShowLoader(false);
    setApiRefresh(false);

    toaster("error", err?.message ? err?.message : GENERAL_ERROR_MESSAGE);
  });
};
useEffect(() => {
  if (apiRefresh === true) {
    fetchHistory();
    setApiRefresh(false);
  }
}, [apiRefresh]);
const editHistory = (id) => {
  getActionHistoryById(1, 10, id).then((res) => {
    if(!isEmpty(res)){
    setStoreData(res?.data?.data[0])
    }
  })
}

useEffect(() => {
  fetchHistory();
}, []);

  // const history = [
  //   {
  //     no: "01",
  //     date: "21 Aug 2022",
  //     criteria: "23 criteria",
  //   },
  //   {
  //     no: "02",
  //     date: "28 Aug 2022",
  //     criteria: "16 criteria",
  //   },
  //   {
  //     no: "03",
  //     date: "01 Sep 2022",
  //     criteria: "32 criteria",
  //   },
  // ];

  // const cities = ["Bengaluru", "New Delhi", "Pune", "Hyderabad", "Mumbai"];
  // const skills = [
  //   "Critical Thinking",
  //   "User Interviews",
  //   "Usability Study",
  //   "Prototyping",
  //   "User Research",
  //   "Branding",
  //   "Design Thinking",
  // ];
  // const industry = [
  //   "Agency",
  //   "Finance",
  //   "Banking",
  //   "Transactions",
  //   "Loans",
  //   "Trading",
  //   "Research",
  //   "Retail",
  // ];
  // const functions = ["Engineering", "Design", "Product", "Human Resource"];
  // const jobTitles = [
  //   "Senior Developer",
  //   "Engineering Manager",
  //   "Product Manager",
  //   "GRM",
  //   "Recruiter",
  //   "Design manager",
  //   "Admin",
  // ];
  // const employers = [
  //   "Microsoft",
  //   "Google",
  //   "Uber",
  //   "ICICI",
  //   "Zerodha",
  //   "Siemens",
  // ];

  const historyDropdown = (index) => {
    if (isOpened == index + 1) {
      setIsOpened(null);
    } else {
      // setIsOpened(history[index.toString()].no);
      setIsOpened(index);
    }
    console.log("history[index]---->", index)
  };
  return (
    <div>
      <div className="fs-16 fw-600 color-primary">Notification History</div>
      <hr></hr>
      <div className="fs-12 action-shadow-card p-3 d-none d-sm-block">
        <Table responsive="sm" className="fs-12 table-component" borderless>
          <thead className="">
            <tr className="">
              <th>
                <div className="py-2">SNo.</div>
              </th>
              <th>
                <div className="py-2">Notification Date</div>
              </th>
              <th>
                <div className="py-2">Criteria</div>
              </th>
              <th>
                <div className="py-2 status-padding-right"> </div>
              </th>
              <th>
                <div className="py-2"> </div>
              </th>
              <th>
                <div className="py-2"></div>
              </th>
            </tr>
            <tr className="p-0 m-0">
              <td colSpan={6} className="p-0 m-0">
                <div className="border-top p-0 m-0"></div>
              </td>
            </tr>
          </thead>
          <tbody hover>
            {history.length>0 &&
            history?.map((data, index) => {
              return (
                <>
                  <tr
                    key="index"
                    className={
                      isOpened === index
                        ? "medium-black-text fw-700 fs-14"
                        : "medium-black-text fw-400 fs-14"
                    }
                  >
                    <td>
                    <div className="pt-2 pe-5">{index+1}</div>
                    </td>
                    <td>
                      <div className="pt-2 pe-5">{data?.notificationDate}</div>
                    </td>
                    <td>
                      <div className="pt-2 pe-5">{data?.criteriaCount}</div>
                    </td>
                    <td></td>
                    <td>
                      <div className="pe-5 d-flex gap-2 hover-btns ">
                        <button className="btn-empty-white py-2 pointer "
                        onClick={() => {sendNotificationtoReferees([data?.locations], [data?.keySkills], [data?.industries], [data?.functions], data?.minExp, data?.maxExp, data?.jobId)}}>
                          Send Again
                        </button>
                        
                        <button
                          // // to={
                          //   // `notification-referees/${jobId}#send-notification` }
                          // to={
                          //   "/" +
                          //   NOTIFICATION_TO_REFEREE_PAGE_ROUTE
                          //    +
                          //   `/${jobId}#send-notification`
                          // }

                          
                          //
                           // to={-1}

                          onClick={() => {
                            // onSwitch(data?.userId);
                            
                          //  setSwitchValue("Send Notification")
                          //  window.location.hash(`#send-notification/${data?.userId}`)
      // window.location.hash = `#send-notification/${data?.userId}`;
      // navigate(`${jobId}/${NOTIFICATION_REFEREE_HASH_ROUTE}`)
      setSwitchValue("Send Notification")
      editHistory(data?.id)
      // window.create({"url": "https://www.youtube.com/", "incognito": true})
      // console.log("clicked")
                           
                          }}
                          className="btn-black-text px-4 py-2 text-decoration-none pointer "
                        >
                          Edit Criteria
                        </button>
                      </div>
                    </td>
                    <td>
                      <div
                        className="py-2"
                        onClick={() => {
                          setHistoryArray(index);
                          historyDropdown(index);
                        }}
                      >
                        <img src={icDownArrow} alt="down-arrow-icon" />
                      </div>
                    </td>
                  </tr>
                  <tr className="m-0 p-0">
                    <td colSpan={6} className="m-0 p-0">
                      <div
                        className={
                          isOpened === index ? "d-block p-4" : "d-none"
                        }
                      >
                        <div className="color-primary fw-600 fs-14">
                          Location
                        </div>
                        <div className="d-flex gap-2 ">
                          {/* {cities?.map((city, index) => {
                            return ( */}
                              <div className="blue-badge mt-3">{data?.locations}</div>
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Key Skills
                        </div>
                        <div className="d-flex gap-2 ">
                          {/* {skills?.map((skill, index) => { */}
                            {/* return ( */}
                              <div className="blue-badge mt-3">{data?.keySkills}</div>
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Industry
                        </div>
                        <div className="d-flex gap-2 ">
                          {/* {industry?.map((data, index) => {
                            return ( */}
                              <div className="blue-badge mt-3">{data?.industries}</div>
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Function
                        </div>
                        <div className="d-flex gap-2 ">
                         
                              <div className="blue-badge mt-3">{data?.functions}</div>
                           
                        </div>
                        {/* <div className="color-primary fw-600 fs-14 mt-4">
                          Current Job Title
                        </div> */}
                        {/* <div className="d-flex gap-2 "> */}
                          {/* {jobTitles?.map((job, index) => { */}
                            {/* return  */}
                            {/* <div className="blue-badge mt-3">{data?.jobTitle}</div> */}
                          {/* })} */}
                        {/* </div> */}
                        {/* <div className="color-primary fw-600 fs-14 mt-4">
                          Current Employer
                        </div> */}
                        <div className="d-flex gap-2 ">
                          {/* {employers?.map((employer, index) => {
                            return ( */}
                              {/* <div className="blue-badge mt-3">{data?.currentEmployer}</div> */}
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Experience
                        </div>
                        <div className="color-primary fw-400 fs-14 mt-2">
                          {data?.minExp}-{data?.maxExp} Years
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="m-0 p-0">
                    <td colSpan={6} className="p-0 m-0">
                      <div className="border-top p-0 m-0"></div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
      </div>
      <InfiniteScroll
          dataLength={currentHistoryPageset}
          next={() => fetchMoreHistory()}
          hasMore={dataCountHistory> currentHistoryPageset}
          className="container-fluid"
        >
      <div className="d-block d-sm-none action-shadow-card">
        <div>
        {/* <div className="py-2">SNo.</div>
        <div className="py-2">Notification Date</div>
        <div className="py-2">Criteria</div> */}
          <Table responsive="sm" className="fs-12 table-component" borderless>
          <thead className="">
            <tr className="">
              <th>
                <div className="py-2">SNo.</div>
              </th>
              <th>
                <div className="py-2">Notification Date</div>
              </th>
              <th>
                <div className="py-2">Criteria</div>
              </th>
              <th>
                <div className="py-2 status-padding-right"> </div>
              </th>
              <th>
                <div className="py-2"> </div>
              </th>
              <th>
                <div className="py-2"></div>
              </th>
            </tr>
            <tr className="p-0 m-0">
              <td colSpan={6} className="p-0 m-0">
                <div className="border-top p-0 m-0"></div>
              </td>
            </tr>
          </thead>
          <tbody hover>
            {history.length>0 &&
            history?.map((data, index) => {
              return (
                <>
                  <tr
                    key="index"
                    className={
                      isOpened === index
                        ? "medium-black-text fw-700 fs-14"
                        : "medium-black-text fw-400 fs-14"
                    }
                  >
                    <td>
                    <div className="pt-2 pe-5">{index+1}</div>
                    </td>
                    <td>
                      <div className="pt-2 pe-5">{data?.notificationDate}</div>
                    </td>
                    <td>
                      <div className="pt-2 pe-5">{data?.criteriaCount}</div>
                    </td>
                    <td></td>
                    <td>
                     
                    <div>
                      <div className="d-flex gap-2 hover-btns">
                        <button className="btn-empty-white py-1 pointer"
                        onClick={() => {sendNotificationtoReferees([data?.locations], [data?.keySkills], [data?.industries], [data?.functions], data?.minExp, data?.maxExp, data?.jobId)}}
                        >
                          Send Again
                        </button>
                        <button
                         onClick={() => {
                          setSwitchValue("Send Notification")
                          editHistory(data?.id)
                        }}
                        className="btn-black-text px-4 py-2 text-decoration-none pointer "
                      >
                        Edit Criteria
                      </button>
                        {/* <Link
                        //  to={}
                          // to={
                          //   "/" +
                          //   NOTIFICATION_REFEREE_HASH_ROUTE +
                          //   `/${jobId}`
                          // }
                          // to={-1}
                          className="btn-black-text px-4 py-1 text-decoration-none pointer"
                        >
                          Edit Criteria
                        </Link> */}
                      </div>
                    </div>
                    </td>
                    <td>
                      <div
                        className="py-2"
                        onClick={() => {
                          setHistoryArray(index);
                          historyDropdown(index);
                        }}
                      >
                        <img src={icDownArrow} alt="down-arrow-icon" />
                      </div>
                    </td>
                  </tr>
                  <tr className="m-0 p-0">
                    <td colSpan={6} className="m-0 p-0">
                      <div
                        className={
                          isOpened === index ? "d-block p-4" : "d-none"
                        }
                      >
                        <div className="color-primary fw-600 fs-14">
                          Location
                        </div>
                        <div className="d-flex gap-2 ">
                          {/* {cities?.map((city, index) => {
                            return ( */}
                              <div className="blue-badge mt-3">{data?.locations}</div>
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Key Skills
                        </div>
                        <div className="d-flex gap-2 ">
                          {/* {skills?.map((skill, index) => { */}
                            {/* return ( */}
                              <div className="blue-badge mt-3">{data?.keySkills}</div>
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Industry
                        </div>
                        <div className="d-flex gap-2 ">
                          {/* {industry?.map((data, index) => {
                            return ( */}
                              <div className="blue-badge mt-3">{data?.industries}</div>
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Function
                        </div>
                        <div className="d-flex gap-2 ">
                          {/* {functions?.map((data, index) => {
                            return ( */}
                              <div className="blue-badge mt-3">{data?.functions}</div>
                            {/* );
                          })} */}
                        </div>
                        {/* <div className="color-primary fw-600 fs-14 mt-4">
                          Current Job Title
                        </div> */}
                        {/* <div className="d-flex gap-2 "> */}
                          {/* {jobTitles?.map((job, index) => { */}
                            {/* return  */}
                            {/* <div className="blue-badge mt-3">{data?.jobTitle}</div> */}
                          {/* })} */}
                        {/* </div> */}
                        {/* <div className="color-primary fw-600 fs-14 mt-4">
                          Current Employer
                        </div> */}
                        <div className="d-flex gap-2 ">
                          {/* {employers?.map((employer, index) => {
                            return ( */}
                              {/* <div className="blue-badge mt-3">{data?.currentEmployer}</div> */}
                            {/* );
                          })} */}
                        </div>
                        <div className="color-primary fw-600 fs-14 mt-4">
                          Experience
                        </div>
                        <div className="color-primary fw-400 fs-14 mt-2">
                          {data?.minExp}-{data?.maxExp} Years
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="m-0 p-0">
                    <td colSpan={6} className="p-0 m-0">
                      <div className="border-top p-0 m-0"></div>
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table>
        </div>
      </div>
      </InfiniteScroll>
    </div>
  );
};

export default History;