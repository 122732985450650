import React, { useEffect } from "react";
import { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BackButton from "../../../../assests/icons/back-icon.svg";
import SwitchButton from "../../../../components/SwitchButton";
import { APPLICATIONS_MAIN_PAGE_ROUTE } from "../../../../config/page_routes_constants";
import CandidateHistory from "./history _candidate";
import SendNotificationCandidate from "./send_notification_candidate";

const NotificationCandidatePage = () => {
  const [switchValue, setSwitchValue] = useState("Send Notification");
  const [sendNotification, setSendNotification] = useState(false);
  const [storeData, setStoreData] = useState();
  const { jobId } = useParams();


  const location = useLocation();
  useEffect(() => {
    if (location?.hash === "#send-notification") {
      setSwitchValue("Send Notification");
    } else if (location?.hash === "#history") {
      setSwitchValue("History");
    }
  }, []);

  useEffect(() => {
    if (switchValue === "Send Notification") {
      window.location.hash = "#send-notification";
    } else if (switchValue === "History") {
      window.location.hash = "#history";
    } else {
      window.location.hash = "#send-notification";
    }
  }, [switchValue]);

  return (
    <div className="container">
      <div className="content-margin mt-4">
        <div className="d-flex mb-4 col-lg-4 col-md-3 col-12 pt-3">
          <Link
            to={`/` + APPLICATIONS_MAIN_PAGE_ROUTE + `/${jobId}` + `#actions`}
            className="color-primary d-flex gap-2 align-items-center"
          >
            <div>
              <img src={BackButton} alt={BackButton} />
            </div>
            <div className="color-primary fs-12 fw-400 back-btn-width">
              Go Back
            </div>
          </Link>
        </div>
        <div className="black-text fs-24 fw-700">Notification to Candidate</div>
        <div className="fs-14 fw-700 mt-3 mb-4">
          <SwitchButton
            data={["Send Notification", "History"]}
            value={switchValue}
            setValue={setSwitchValue}
            theme={"black"}
          />
        </div>
        <hr></hr>
        {switchValue === "Send Notification" && (
          <SendNotificationCandidate sendNotification={sendNotification} storeData ={storeData}/>
        )}
        {switchValue === "History" && <CandidateHistory setSwitchValue={setSwitchValue} setStoreData={setStoreData}/>}
      </div>
    </div>
  );
};

export default NotificationCandidatePage;
