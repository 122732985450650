import React, { useEffect, useState } from "react";
import backButton from "../assests/icons/back-icon.svg";
import forwardButton from "../assests/icons/forward-ic.svg";
import dropdownArrow from "../assests/downArrow.svg";
import upArrow from "../assests/upArrow.svg";
import downArrow from "../assests/downArrow.svg";

import IcDoneWhite from "../assests/icons/ic_done.svg";
import profileImg from "../assests/images/candidate-profile-pic.jpg";
import rightArrow from "../assests/Vector.svg";
import tick from "../assests/icons/ic-combo-tick.svg";
import SortBy from "../assests/ic_sort.svg";
import filterIcon from "../assests/icons/ic-filter.svg";
import blackdone from "../assests/icons/ic_blackdone.svg";
import "../styles/individual_teams.scss";
import { Table, Dropdown } from "react-bootstrap";
import ProgressBarGraph from "../components/graph/progress_bar_graph";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import useWindowDimensions from "../utils/use_window_dimension";
import { useNavigate, useParams } from "react-router";
import { checkedItemsHandler } from "../utils/utils";
import TeamFilterTeamsDialog from "../dialogs/team_filter_dialog/team_filter_teams_dialog";
import TeamSortTableDialog from "../dialogs/team_filter_dialog/team_sort_table_dialog";
import SortByFilterModal from "../dialogs/jobs_dialogs/sort-by-filter-modal";
import AllFiltersMobileModal from "../dialogs/jobs_dialogs/all_filters_mobile";
import {
  getjobSummary,
  getmember,
  getLoggedInUserMembers,
  getMemberWiseReferralBonus,
  getMemberWiseTeamsSummary,
  getTeamMembers,
  getTeams,
  viewteamMembers,
} from "../_services/view.service";
import { STATUS_SUCCESS } from "../config/be_api_constants";
import toaster from "../utils/toaster";
import {
  APPLICATIONS_MAIN_PAGE_ROUTE,
  BUSINESS_USER_PROFILE_ROUTE,
  JOB_POST_CREATE_PAGE_ROUTE,
  TEAM_PAGE_ROUTE,
} from "../config/page_routes_constants";
import DatePickerModal from "../dialogs/date_picker_modal";
import { isEmpty } from "../utils/form_validators";
import { DEFAULT_PAGE_SIZE } from "../config/be_seikorAdmin_api_constants";
import { useSearchParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

function IndividualBusinessProfileDetails() {
  const { teamId, businessId, individualMember_Index_Id } = useParams();
  const [team_Id, setteam_Id] = useState("");
  const [apiRefresh, setApiRefresh] = useState(false);
  const [currentPage, setcurrentPage] = useState("");
  const [nextPage, setnextPage] = useState();
  const [previousPage, setpreviousPage] = useState("");
  const [nextPage_Id, setnextPage_Id] = useState();
  const [upDownArrowView, setUpDownArrowView] = useState(true);
  const [upDownArrowApplication, setUpDownArrowApplication] = useState(true);
  const [upDownArrowReferrals, setUpDownArrowReferrals] = useState(true);
  const [upDownArrowShort, setUpDownArrowShort] = useState(true);
  const [upDownArrowAssess, setUpDownArrowAssess] = useState(true);
  const [upDownArrowPendingScreeen, setUpDownArrowPendingScreeen] =
    useState(true);
  const [teamChart, setTeamChart] = useState([]);

  useEffect(() => {
    let currentPageIndex = individualMember_Index_Id - 1;
    let previousPageIndex = currentPageIndex - 1;
    let nextPageIndex = currentPageIndex + 1;
    setcurrentPage(currentPageIndex);
    setpreviousPage(previousPageIndex);
    setnextPage(nextPageIndex);
  }, [nextPage_Id]);

  const [viewTeam, setViewTeam] = useState([]);
  const [viewTeamFirst, setViewTeamFirst] = useState([]);
  const [firstDate, setfirstDate] = useState();
  const [secondDate, setsecondDate] = useState();

  const getAllTeamMember = (fromDate, toDate) => {
    setShowLoader(true);
    viewteamMembers({
      teamId: teamId,
      fromDate: fromDate,
      toDate: toDate,
    })
      .then((res) => {
        setViewTeam(res?.data?.data);
        setViewTeamFirst(res?.data?.data[0]);
        setShowLoader(false);
        // setApiRefresh(true);
        // setTeamMemberCount(res?.totalRecord);
      })
      .catch((err) => {
        setShowLoader(false);
      });
  };

  useEffect(() => {
    getAllTeamMember();
  }, []);

  console.log("name member -->", viewTeam);

  let i;

  const [memberEmail, setMemberEmail] = useState();
  const [nextOrDropdown, setNextOrDropdown] = useState(false);

  const goToNextMember = (nextPage) => {
    if (nextPage !== currentPage) {
      // getMemberWiseTeamsSummary(1, 10, businessId, teamId)?.then((res) => {
      // });

      viewteamMembers({
        teamId: teamId,
      })?.then((res) => {
        console.log("viewteamMembersteamId=>", res?.data?.data);
        for (const [index] of res?.data?.data.entries(0)) {
          i = index;
          // console.log(index,"index befor if");
          if (index === nextPage + 1) {
            // console.log(index,"index after if");
            something(res?.data?.data?.[index]?.id);
            setnextPage_Id(res?.data?.data?.[index]?.id);
            setTeamChart(res?.data?.data[index]?.id);

            console.log("[index]?.id", res?.data?.data?.[index]?.id);

            // console.log("job next",jobslist,nextPage_Id);
          }
        }

        // console.log(i,"index for");
        console.log("index_sigle", i);
      });
      //
      //  getjobSummary({pageNo:0, pageSize:0 , memberId :businessId }).then(
      //       (res) => {
      //          setJobslist(res?.data?.data);
      //       });
    }
  };

  console.log("========================================?", nextPage_Id);

  const something = (id) => {
    console.log("something page id", id);
    setNextOrDropdown(false);
    navigate(`/${BUSINESS_USER_PROFILE_ROUTE}/${teamId}/${id}/${nextPage + 1}`);
    getAllTeamMember(teamId);
    getjobSummary({ pageNo: 0, pageSize: 0, memberId: id }).then((res) => {
      setJobslist(res?.data?.data);
    });
  };

  const [storeIndex, setStoreIndex] = useState();
  useEffect(() => {
    setStoreIndex(individualMember_Index_Id);
  }, []);

  const changeDropdown = (indx, memberName) => {
    setBusinessArraySortIndex(viewTeam?.email);
    setNextOrDropdown(true);
    setMemberEmail(memberName);
    navigate(
      `/${BUSINESS_USER_PROFILE_ROUTE}/${teamId}/${viewTeam[indx]?.id}/${
        indx + 1
      }`
    );
    getAllTeamMember();
    // getMemberWiseTeamsSummary(1, 10, businessId, teamId)?.then((res) => {
    //   setTeamChart(res?.data?.data[indx]?.id);

    //   });

    console.log("memberName", memberName, teamChart);
  };

  // CURRENT DATE
  const current = new Date();
  // current.setMonth(current.getMonth() - 3);

  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const date = `${yyyy}-${mm}-${dd}`;
  // console.log("give me todays date", date);

  // PAST 1 MONTHS
  const past1Months = new Date();
  past1Months.setMonth(past1Months.getMonth() - 1);
  var dd = String(past1Months.getDate()).padStart(2, "0");
  var mm = String(past1Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past1Months.getFullYear();
  const month1 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month1);

  // PAST 3 MONTHS
  const past3Months = new Date();
  past3Months.setMonth(past3Months.getMonth() - 3);
  var dd = String(past3Months.getDate()).padStart(2, "0");
  var mm = String(past3Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past3Months.getFullYear();
  const month3 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month3);

  // PAST 6 MONTHS
  const past6Months = new Date();
  past6Months.setMonth(past6Months.getMonth() - 6);
  var dd = String(past6Months.getDate()).padStart(2, "0");
  var mm = String(past6Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past6Months.getFullYear();
  const month6 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month6);

  // PAST 12 MONTHS
  const past12Months = new Date();
  past12Months.setMonth(past12Months.getMonth() - 12);
  var dd = String(past12Months.getDate()).padStart(2, "0");
  var mm = String(past12Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past12Months.getFullYear();
  const month12 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month12);

  const CUSTOM_DATE_OPTION = "Custom";
  const navigate = useNavigate();
  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    if (winWidth > 1500) {
      return 955 + "px";
    } else if (winWidth > 1300 && winWidth > 1499) {
      return winWidth - 420 + "px";
    } else if (winWidth < 1300 && winWidth > 991) {
      return winWidth - 420 + "px";
    } else if (winWidth < 990 && winWidth >= 768) {
      return winWidth - 70 + "px";
    } else if (winWidth < 767 && winWidth > 551) {
      return winWidth - 70 + "px";
    } else if (winWidth < 550) {
      return winWidth - 110 + "px";
    }
  };

  const [arraySortIndex, setArraySortIndex] = useState(null);
  const [tableDropdownSortIndex, setTableDropdownSortIndex] = useState(null);
  const [businessSortIndex, setBusinessArraySortIndex] = useState(0);
  const [sortUserTimeShow, setSortUserTimeShow] = useState(false);
  const [filterTeamsShow, setFilterTeamsShow] = useState(false);
  const [timeDropdownRedDot, setTimeDropdownRedDot] = useState("d-none");
  const [tableTimeDropdownRedDot, setTableTimeDropdownRedDot] =
    useState("d-none");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [displayDatePicker, setDisplayDatePicker] = useState("d-none");
  const [displayDatePickerTable, setDisplayDatePickerTable] =
    useState("d-none");

  const [jobslist, setJobslist] = useState([]);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [currentDate, setCurrentDate] = useState();
  const [member_Id, setMember_Id] = useState("");

  useEffect(() => {
    const todaysDate = new Date();
    setCurrentDate(todaysDate);
  }, [team_Id]);

  const showSelectedDate = (startDt, endDt) => {
    if (startDt) {
      const startDate = startDt?.toString()?.slice(4, 16);
      setCustomStartDate(startDate);
    }
    if (endDt) {
      const endDate = endDt?.toString()?.slice(4, 16);
      setCustomEndDate(endDate);
    }
  };

  const handleDateRange = (event) => {
    setDateRange([event?.selection]);
    let startDate = event?.selection?.startDate;
    let endDate = event?.selection?.endDate;
    showSelectedDate(startDate, endDate);

    let convertStartDate = JSON.stringify(startDate).slice(1, 11);
    let convertEndDate = JSON.stringify(endDate).slice(1, 11);

    setfirstDate(convertStartDate);
    setsecondDate(convertEndDate);
  };
  console.log("firstDate", firstDate);
  console.log("secondDate", secondDate);

  const onCustomDateSelect = (name) => {
    if (name == CUSTOM_DATE_OPTION) {
      setDisplayDatePicker("d-block");
    } else {
      setDisplayDatePicker("d-none");
    }
  };

  const onCustomDateSelectTable = (name) => {
    if (name == CUSTOM_DATE_OPTION) {
      setDisplayDatePickerTable("d-block");
    } else {
      setDisplayDatePickerTable("d-none");
    }
  };

  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  const [memberBonus, setMemberBonus] = useState([]);

  const TeamSummaryChart = () => {
    // changes
    setApiRefresh(true);
    getMemberWiseTeamsSummary(1, 10, businessId, teamId)?.then((res) => {
      setTeamChart(res?.data?.data);
      setApiRefresh(false);
      // if (res?.data?.status === STATUS_SUCCESS) {
      //   getjobSummary(1, 10, teamId, null, null, null, null, businessId)
      //     .then((res) => {
      //       setJobslist(res?.data?.data);
      //       if (res?.status === STATUS_SUCCESS) {
      //         setShowLoader(false);
      //       } else {
      //         setShowLoader(false);
      //       }
      //     })
      //     .catch((err) => {
      //       setShowLoader(true);
      //     });
      // }
    });
  };
  console.log("teamChart", teamChart);
  const memberReferralBonus = () => {
    // changes
    getMemberWiseReferralBonus(businessId)?.then((res) => {
      setMemberBonus(res?.data?.data);
    });
  };

  useEffect(() => {
    setteam_Id(teamId);
    setMember_Id(businessId);
    TeamSummaryChart();
    memberReferralBonus();
  }, [jobslist]);
  // useEffect(() => {
  //   // setteam_Id(teamId);
  //   // setMember_Id(businessId);
  //   // TeamSummaryChart();

  //   if (apiRefresh === true) {
  //     TeamSummaryChart();
  //      setApiRefresh(false);
  //   }

  // }, [jobslist,apiRefresh]);

  const getLengedPercentage = (total, value) => {
    if (total === 0 || value === 0) {
      return 0 + "%";
    } else if (total && value) {
      return Math.round((parseInt(value) * 100) / parseInt(total)) + "%";
    }
  };

  const rolesPerformance = [
    {
      title: "Roles Posted",
      value:
        teamChart[0]?.hasOwnProperty("rolesPosted") &&
        teamChart[0]?.rolesPosted !== ""
          ? teamChart[0]?.rolesPosted
          : 0,
    },
    {
      title: "Roles Filled",
      value:
        teamChart[0]?.hasOwnProperty("rolesFilled") &&
        teamChart[0]?.rolesFilled !== ""
          ? teamChart[0]?.rolesFilled
          : 0,
    },
    {
      title: "Roles Closed",
      value:
        teamChart[0]?.hasOwnProperty("dropped") && teamChart[0]?.dropped !== ""
          ? teamChart[0]?.dropped
          : 0,
    },
    {
      title: "Total Referrals",
      value:
        teamChart[0]?.hasOwnProperty("referralCount") &&
        teamChart[0]?.referralCount !== ""
          ? teamChart[0]?.referralCount
          : 0,
    },
    {
      title: "Offered to Joined Ratio",
      // value: "640/480(80%)",
      value: `${
        teamChart[0]?.hasOwnProperty("jobsOffered") &&
        teamChart[0]?.jobsOffered !== ""
          ? teamChart[0]?.jobsOffered
          : 0
      }/${
        teamChart[0]?.hasOwnProperty("rolesFilled") &&
        teamChart[0]?.rolesFilled !== ""
          ? teamChart[0]?.rolesFilled
          : 0
      }(${getLengedPercentage(
        teamChart[0]?.rolesFilled,
        teamChart[0]?.jobsOffered === 0 ? "0" : teamChart[0]?.jobsOffered
      )}) `,
    },
  ];

  const timelineArray = [
    { name: "All Time", value: " " },
    { name: "Last 1 Month", value: month1 },
    { name: "Last 3 Months", value: month3 },
    { name: "Last 6 Months", value: month6 },
    { name: "Last 12 Months", value: month12 },
    { name: CUSTOM_DATE_OPTION, value: CUSTOM_DATE_OPTION },
  ];

  const businessArray = [
    "Business User Name",
    "Biz User 2",
    "Biz User 3",
    "Biz User 4",
    "Biz User 5",
    "Biz User 6",
    "Biz User 7",
    "Biz User 8",
    "Biz User 9",
  ];

  // const [barData, setBarData] = useState([
  //   { entity: "Applied", entityValue: "584", color: "#1C1C1C" },
  //   { entity: "Shortlisted", entityValue: "312", color: "#3D81FF" },
  //   { entity: "Assessment", entityValue: "258", color: "#FFD027" },
  //   { entity: "Offered", entityValue: "95", color: "#12BD6B" },
  //   { entity: "Joined", entityValue: "68", color: "#2FFF9B" },
  //   { entity: "Dropout", entityValue: "26", color: "#EB1260" },
  //   { entity: "Rejected", entityValue: "200", color: "#721212" },
  // ]);

  const barData = [
    {
      entity: "Applied",
      entityValue:
        teamChart[0]?.statusWiseData?.hasOwnProperty("APPLIED") &&
        teamChart[0]?.statusWiseData?.APPLIED !== ""
          ? teamChart[0]?.statusWiseData?.APPLIED
          : 0,
      color: "#1C1C1C",
    },
    {
      entity: "Shortlisted",
      entityValue:
        teamChart[0]?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
        teamChart[0]?.statusWiseData?.SHORTLISTED !== ""
          ? teamChart[0]?.statusWiseData?.SHORTLISTED
          : 0,
      color: "#3D81FF",
    },
    {
      entity: "Assessment",
      entityValue:
        teamChart[0]?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
        teamChart[0]?.statusWiseData?.ASSESSMENT !== ""
          ? teamChart[0]?.statusWiseData?.ASSESSMENT
          : 0,
      color: "#FFD027",
    },
    {
      entity: "Offered",
      entityValue:
        teamChart[0]?.statusWiseData?.hasOwnProperty("OFFERED") &&
        teamChart[0]?.statusWiseData?.OFFERED !== ""
          ? teamChart[0]?.statusWiseData?.OFFERED
          : 0,
      color: "#12BD6B",
    },
    {
      entity: "Joined",
      entityValue:
        teamChart[0]?.statusWiseData?.hasOwnProperty("JOINED") &&
        teamChart[0]?.statusWiseData?.JOINED !== ""
          ? teamChart[0]?.statusWiseData?.JOINED
          : 0,
      color: "#2FFF9B",
    },
    {
      entity: "Dropout",
      entityValue:
        teamChart[0]?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        teamChart[0]?.statusWiseData?.RETRACTED !== ""
          ? teamChart[0]?.statusWiseData?.RETRACTED
          : 0,
      color: "#EB1260",
    },
    {
      entity: "Rejected",
      entityValue:
        teamChart[0]?.statusWiseData?.hasOwnProperty("REJECTED") &&
        teamChart[0]?.statusWiseData?.REJECTED !== ""
          ? teamChart[0]?.statusWiseData?.REJECTED
          : 0,
      color: "#721212",
    },
  ];

  const dropdownSortArray = [
    // "New Applications First",
    // "New Referrals First",
    // "Pending Screening First",
    // "Post Date",
    // "Status",
    // "Most Applications",
    // "Most Views",
    // "Most Referrals",
    // "A-Z",
    { name: "New Applications First", value: "NEW_APPLICATION_FIRST" },
    { name: "New Referrals First", value: "NEW_REFERRALS_FIRST" },
    { name: "Pending Screening", value: "PENDING_SCREENING" },
    { name: "Shortlisted", value: "SHORTLIST_COUNT" },
    { name: "Status", value: "JOB_STATUS" },
    { name: "Most Applications", value: "MOST_APPLICATION" },
    { name: "Most Views", value: "MOST_VIEWS" },
    { name: "Most Referrals", value: "MOST_REFERRALS" },
    { name: "A-Z", value: "A_Z" },
  ];

  const dropdownAllTeamsArray = [
    "All Teams",
    "Team Name 1",
    "Team Name 2",
    "Team Name 3",
    "Team Name 4",
    "Team Name 5",
  ];
  const dropdownAllStatusArray = [
    { name: "All Status", value: "ALL_STATUS" },
    { name: "Live", value: "LIVE" },
    { name: "Paused", value: "PAUSED" },
    { name: "Filled", value: "FILLED" },
    { name: "Closed", value: "CLOSED" },
  ];
  const dropdownPostByArray = [
    "All",
    "Me",
    "Business Profile Name 1",
    "Business Profile Name 2",
    "Business Profile Name 3",
    "Business Profile Name 4",
  ];

  const [arrayAllTeamsIndex, setArrayAllTeamsIndex] = useState([]);
  const [arrayAllStatusIndex, setArrayAllStatusIndex] = useState([]);
  const [arrayAllTimeIndex, setArrayAllTimeIndex] = useState(null);
  const [postByArrayIndex, setPostByArrayIndex] = useState(null);
  const [showSortByFilter, setShowSortByFilter] = useState(false);
  const [showAllFilters, setShowAllFilters] = useState(false);

  const tableDropdownSortHandler = (index) => {
    setTableDropdownSortIndex(index);
  };
  const dropdownAllTimeHandler = (index) => {
    setArrayAllTimeIndex(index);
  };
  const [teamArray, setTeamArray] = useState([]);
  const [allStatus, setAllStatus] = useState([]);

  const dropdownSortHandler = (index, value) => {
    setArraySortIndex(index);
    setAllJobsPostFilterData({
      ...allJobsPostFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    fetchAllJobs({
      ...allJobsPostFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };
  const dropdownAllTeamsHandler = (index) => {
    checkedItemsHandler(index, arrayAllTeamsIndex, setArrayAllTeamsIndex);
  };

  const dropdownAllStatusHandler = (index, value) => {
    let statusArray = [...allStatus];
    if (statusArray.includes(value)) {
      let newIndex = statusArray.findIndex((elem) => {
        return elem === value;
      });
      statusArray.splice(newIndex, 1);
      setAllStatus(statusArray);
      return;
    }
    if (!statusArray.includes(value)) {
      statusArray.push(value);
      setAllStatus(statusArray);
    }

    // let statusArray = [...allStatus];
    // statusArray.push(value);

    // setAllStatus(statusArray);
    checkedItemsHandler(index, arrayAllStatusIndex, setArrayAllStatusIndex);
  };
  let isInfinite = false;
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    memberId: businessId,
  };
  const [allJobsPostFilterDataParams, setAllJobsPostFilterDataParams] =
    useSearchParams({
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      memberId: businessId,
    });

  const [allJobsPostFilterData, setAllJobsPostFilterData] =
    useState(initialFilterData);
  const [currentPageset, setCurrentPageSet] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  // const [sortByDropdownRedDot, setSortByDropdownRedDot] = useState("d-none");
  // const [timeIntervalDropdownRedDot, setTimeIntervalDropdownRedDot] =
  //   useState("d-none");
  // const [allTeamsDropdownRedDot, setAllTeamsDropdownRedDot] =
  //   useState("d-none");
  // const [allStatusDropdownRedDot, setAllStatusDropdownRedDot] =
  //   useState("d-none");
  // const [allFiltersDropdownRedDot, setAllFiltersDropdownRedDot] =
  //   useState(false);

  const [sortByDropdownRedDot, setSortByDropdownRedDot] = useState("d-none");
  const [timeIntervalDropdownRedDot, setTimeIntervalDropdownRedDot] =
    useState("d-none");
  const [allTeamsDropdownRedDot, setAllTeamsDropdownRedDot] =
    useState("d-none");
  const [allStatusDropdownRedDot, setAllStatusDropdownRedDot] =
    useState("d-none");
  const [allFiltersDropdownRedDot, setAllFiltersDropdownRedDot] =
    useState(false);

  const [teamData, setTeamData] = useState([]);

  console.log("member_Idmember_Idmember_Idmember_Id", member_Id);
  // const fetchAllJobs = (
  //   teamId,
  //   fromDate,
  //   toDate,
  //   searchString,
  //   sortBy,
  //   applicationstatus
  // ) => {
  //   getjobSummary(
  //   {pageNo:0, pageSize:0 , memberId : businessId}
  //   )
  //     .then((res) => {
  //       setJobslist(res?.data?.data);
  //       if (res?.status === STATUS_SUCCESS) {
  //         setShowLoader(false);
  //       } else {
  //         setShowLoader(false);
  //       }
  //     })
  //     .catch((err) => {
  //       setShowLoader(true);
  //     });
  // };
  useEffect(() => {
    if (arraySortIndex == null) {
      setSortByDropdownRedDot("d-none");
    } else {
      setSortByDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  const fetchAllJobs = (data) => {
    setShowLoader(true);
    getjobSummary(data).then(
      (res) => {
        if (res && !isEmpty(res?.data?.data)) {
          setDataCount(res?.data?.totalRecord);
          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...jobslist, ...res?.data?.data];
          }
          setCurrentPageSet(data?.length);
          setJobslist(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      },
      (error) => {
        setShowLoader(false);
      }
    );
  };

  const checkAllTeamsHandler = (condition, item) => {
    if (condition) {
      setTeamArray([...teamArray, item?.id]);
    } else {
      let index = teamArray.indexOf(item?.id);
      if (index >= 0) {
        // let copyObj = [...teamArray];
        // delete copyObj[index];
        setTeamArray([
          ...teamArray.splice(0, index),
          ...teamArray.splice(index + 1),
        ]);

        // setTeamArray(copyObj);
      }
    }
  };

  const checkAllStatusHandler = (condition, value) => {
    if (condition) {
      setAllStatus([...allStatus, value]);
    } else {
      let index = allStatus.indexOf(value);
      if (index >= 0) {
        // let copyObj = [...allStatus];
        // delete copyObj[index];
        setAllStatus([
          ...allStatus.splice(0, index),
          ...allStatus.splice(index + 1),
        ]);

        // setAllStatus(copyObj);
      }
    }
  };

  const fetchMoreJobs = () => {
    setCurrentPageSet(currentPageset + 10);
    isInfinite = true;

    fetchAllJobs({
      ...allJobsPostFilterData,
      pageNo: allJobsPostFilterData?.pageNo + 1,
    });
    setAllJobsPostFilterData({
      ...allJobsPostFilterData,
      pageNo: allJobsPostFilterData?.pageNo + 1,
    });
  };
  const teamStatus = () => {
    setShowLoader(true);

    // let pageNo = 1;
    // let pageSize = 10;
    getTeams({ pageNo: 0, pageSize: 0 })
      .then((res) => {
        setTeamData(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        toaster("Error", err);
      });
    setShowLoader(false);
  };

  useEffect(() => {
    teamStatus();
    // getAllTeamAndMemberJobs();
    fetchAllJobs(allJobsPostFilterData);
    // if (arraySortIndex == null) {
    //   setSortByDropdownRedDot("d-none");
    // } else {
    //   setSortByDropdownRedDot("d-block");
    // }
  }, [arraySortIndex]);

  // useEffect(() => {
  //   if (arrayAllTimeIndex == null) {
  //     setTimeIntervalDropdownRedDot("d-none");
  //   } else {
  //     setTimeIntervalDropdownRedDot("d-block");
  //   }
  // }, [arrayAllTimeIndex]);

  useEffect(() => {
    if (arrayAllTeamsIndex?.length > 0) {
      setAllTeamsDropdownRedDot("d-block");
    } else {
      setAllTeamsDropdownRedDot("d-none");
    }
  }, [arrayAllTeamsIndex]);

  useEffect(() => {
    if (arrayAllStatusIndex?.length > 0) {
      setAllStatusDropdownRedDot("d-block");
    } else {
      setAllStatusDropdownRedDot("d-none");
    }
  }, [arrayAllStatusIndex]);

  useEffect(() => {
    if (
      arrayAllTimeIndex != null ||
      arrayAllStatusIndex?.length != 0 ||
      arrayAllTeamsIndex?.length != 0
    ) {
      setAllFiltersDropdownRedDot(true);
    } else {
      setAllFiltersDropdownRedDot(false);
    }
  }, [
    arrayAllTimeIndex,
    postByArrayIndex,
    arrayAllStatusIndex,
    arrayAllTeamsIndex,
  ]);

  // useEffect(() => {
  //   if (arraySortIndex == null || arraySortIndex == 5) {
  //     setTimeDropdownRedDot("d-none");
  //   } else {
  //     setTimeDropdownRedDot("d-block");
  //   }
  // }, [arraySortIndex]);

  console.log("StoreIndex-->", storeIndex);

  return (
    <div className="container">
      <div className="content-margin mt-4">
        <div className="d-flex justify-content-center justify-content-sm-between mt-3">
          <div onClick={() => navigate(-1)} className="individual-back-btn-pos">
            <img className="me-2 pointer" src={backButton} />
            <span className="text-underline pointer back-btn-display fs-12 fw-400">
              Back to Team
            </span>
          </div>
          <div className="text-center">
            <div>
              <Dropdown
                onClick={() => {
                  if (hrSrlDiamension?.width <= 768) {
                    setFilterTeamsShow(true);
                  }
                }}
                className="team-selection-dropdown"
              >
                <Dropdown.Toggle
                  variant="none"
                  // id="dropdown-basic"
                  className="d-flex p-0 m-0 border-0 text-break"
                >
                  <img
                    style={{ width: "36px" }}
                    className="rounded-circle me-2"
                    src={profileImg}
                  />
                  {/* <div className="fw-700 fs-20">{teamChart[0]?.teamName}</div> */}
                  {!nextOrDropdown ? (
                    <div className="fw-700 fs-20">
                      {viewTeam[nextPage - 1]?.email}
                    </div>
                  ) : (
                    <div className="fw-700 fs-20">{memberEmail}</div>
                  )}
                  <img
                    src={downArrow}
                    alt="retract-icon"
                    className="drop-down-arrow"
                  />
                </Dropdown.Toggle>
                <div className="d-none d-md-block">
                  <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                    <ul
                      className="m-0 p-0 overflow-auto"
                      style={{ maxHeight: "300px" }}
                    >
                      {viewTeam?.length > 0
                        ? viewTeam?.map((team, index) => {
                            // storeIndex = index;
                            // setStoreIndex(index)
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  // setBusinessArraySortIndex(viewTeam?.email)
                                  // navigate(`/${BUSINESS_USER_PROFILE_ROUTE}/${teamId}/${viewTeam[index]?.id}/${index + 1}`);
                                  // getAllTeamMember()
                                  changeDropdown(index, team?.email);
                                }}
                                // href={`#/action-${index}`}
                                className="d-flex pb-2 pt-2"
                              >
                                <div className="d-flex gap-2 w-100">
                                  <span className="dropdown-icon-span">
                                    {businessSortIndex === index ? (
                                      <img
                                        src={IcDoneWhite}
                                        alt="blackdone-icon"
                                        className=""
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>

                                  <span
                                    className={
                                      businessSortIndex === index
                                        ? "fw-700"
                                        : ""
                                    }
                                  >
                                    {team?.email}
                                  </span>
                                </div>
                              </Dropdown.Item>
                            );
                          })
                        : ""}
                    </ul>
                  </Dropdown.Menu>
                </div>
              </Dropdown>
            </div>
          </div>
          <div className="ms-3 back-btn-display">
            <span
              className="text-underline pointer me-2 fs-12 fw-400"
              onClick={() => goToNextMember(nextPage)}
            >
              Next Member
            </span>
            <img
              className="pointer"
              src={forwardButton}
              onClick={() => goToNextMember(nextPage)}
            />
          </div>
        </div>
        <hr />
        <div className="col-6 col-sm-12 d-sm-flex justify-content-between mt-3">
          <div className="mb-3 mb-sm-0 fs-20 fw-700">
            Business Username's Performance
          </div>
          <div className="position-relative">
            <div
              className={`${displayDatePicker} custome-date-box custome-date-box-position p-3`}
            >
              <DateRange
                ranges={dateRange}
                onChange={(e) => {
                  handleDateRange(e);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                maxDate={currentDate}
              />
              <div className="d-flex justify-content-end">
                <button
                  style={{ border: "1px solid black", width: "80px" }}
                  className="btn bnt-light color-dark fs-14 p-0"
                  onClick={() => {
                    setDisplayDatePicker("d-none");
                    setArraySortIndex(null);
                    setTimeDropdownRedDot("d-none");
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ border: "1px solid black", width: "80px" }}
                  className="btn bnt-light color-dark fs-14 p-0 ms-2 "
                  onClick={() => {
                    setDisplayDatePicker("d-none");
                    setTimeDropdownRedDot("d-block");
                    setArraySortIndex(5);
                    getAllTeamMember(firstDate, secondDate);
                  }}
                >
                  OK
                </button>
              </div>
            </div>
            <Dropdown
              onClick={() => {
                if (hrSrlDiamension?.width <= 768) {
                  setSortUserTimeShow(true);
                }
              }}
              className="timeline-select-dropdown"
            >
              <Dropdown.Toggle
                // disabled={true}
                variant="none"
                // id="dropdown-basic"
                className="btn down-arrow timeline-select-button d-flex py-1 px-2 bg-transparent align-items-center justify-content-around"
              >
                <img src={filterIcon} alt="" className="me-1" />
                <span className="fs-12">Time Interval</span>
                <span
                  className={`${timeDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                ></span>
                <img className="ms-1" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <div className="d-none d-md-block">
                <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                  {timelineArray?.map((name, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        className="d-flex pb-3"
                        onClick={() => {
                          if (arraySortIndex == index) {
                            setArraySortIndex(null);
                            setTimeDropdownRedDot("d-none");
                            // if (name == CUSTOM_DATE_OPTION) {
                            //   setCustomStartDate("");
                            //   setCustomEndDate("");
                            // }
                          } else {
                            if (name == CUSTOM_DATE_OPTION) {
                              if (
                                customStartDate == "" ||
                                customEndDate == ""
                              ) {
                                setTimeDropdownRedDot("d-none");
                              }
                              onCustomDateSelect(name);
                            } else {
                              setArraySortIndex(index);
                              setTimeDropdownRedDot("d-block");
                            }
                          }

                          // if (name === "All Time") {
                          //   getAllTeamMember();
                          // }
                          // if (name === "1 Month") {
                          //   getAllTeamMember(month1, date);
                          // }
                          // if (name === "3 Months") {
                          //   getAllTeamMember(month3, date);
                          // }
                          // if (name === "6 Months") {
                          //   getAllTeamMember(month6, date);
                          // }
                          // if (name === "12 Months") {
                          //   getAllTeamMember(month12, date);
                          // }
                        }}
                      >
                        <div>
                          <div className="d-flex">
                            <img
                              src={tick}
                              alt="blackdone-icon"
                              className={
                                arraySortIndex === index
                                  ? "pe-2 d-block"
                                  : "d-none pe-1"
                              }
                            />
                            <span
                              className={
                                arraySortIndex === index
                                  ? "ps-0 color-primary fw-700"
                                  : "ps-4"
                              }
                            >
                              {name.name}
                            </span>
                          </div>
                          {name == CUSTOM_DATE_OPTION &&
                          arraySortIndex == 5 &&
                          customStartDate != "" &&
                          customEndDate != "" ? (
                            <div className="small-text-gray fs-12 mt-2">
                              {`${customStartDate} to ${customEndDate}`}
                            </div>
                          ) : null}
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </div>
            </Dropdown>
          </div>
        </div>
        {/* Overview */}
        <div>
          <div className="my-4 outer-border p-2 p-sm-3">
            <div className="d-flex justify-content-between flex-wrap mt-3 org-roles-performance">
              {rolesPerformance?.map((obj) => (
                <>
                  <div className="ps-2 stats">
                    <div className="fw-400 fs-32 text-break">{obj.value}</div>
                    <div className="fw-400 fs-16 color-tertiary">
                      {obj.title}
                    </div>
                  </div>
                </>
              ))}
            </div>
            <ProgressBarGraph data={barData} height={"22"} showLegends={true} />
          </div>
          {/* <h1 className="text-center">Overview</h1> */}
        </div>
        {/* Info Cards Section */}
        <div className="row align-items-stretch">
          <div className="col-12 col-sm-6 col-md-4">
            <div className="border border-gray p-3 rounded team-job-info mt-2">
              <div className="fs-20 fw-700">42 Days</div>
              <div className="fs-16 color-gray fw-400">Average Joining</div>
              <div className="fs-12 fw-400 color-saffron">
                10 days slow than your org avg
              </div>
              {/* <div className="fs-12 fw-400 color-green">
                10 days faster than your org avg 
              </div> */}
            </div>
          </div>

          <div className="col-6 col-md-4">
            <div className="border border-gray p-3 rounded team-job-info mt-2">
              <div className="fs-20 fw-700">$ {memberBonus} K</div>
              <div className="fs-16 color-gray fw-400">Referral Bonus</div>
            </div>
          </div>
          <div className="col-6 col-md-4">
            <div className="border border-gray p-3 rounded team-job-info mt-2">
              <div className="fs-20 fw-700">{teamChart[0]?.rolesOpen}</div>
              <div className="fs-16 color-gray fw-400">Open Roles</div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 mt-4">
          <Dropdown
            onClick={() => {
              if (hrSrlDiamension?.width <= 768) {
                setShowSortByFilter(true);
              }
            }}
            className="filter-button h-28 "
          >
            <Dropdown.Toggle
              variant="none"
              id="dropdown-basic"
              className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
            >
              <img src={SortBy} alt="" />
              <span className="fs-12 fw-400">Sort By</span>
              <span
                className={`${sortByDropdownRedDot} round-red-dot rounded-circle ms-2 mb-2`}
              ></span>
            </Dropdown.Toggle>
            <div className="d-none d-md-block">
              <Dropdown.Menu className="fs-12 text-secondary ">
                {dropdownSortArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex pb-3"
                      // onClick={() => {
                      //   if (tableDropdownSortIndex == index) {
                      //     tableDropdownSortHandler(null);
                      //     setTableTimeDropdownRedDot("d-none");
                      //   } else {
                      //     tableDropdownSortHandler(index);
                      //     setTableTimeDropdownRedDot("d-block");
                      //   }
                      //   if (sortItem === "Status") {
                      //     fetchAllJobs(
                      //       null,
                      //       null,
                      //       null,
                      //       "JOB_STATUS",
                      //       null,
                      //       null
                      //     );
                      //   }
                      //   if (sortItem === "Most Applications") {
                      //     fetchAllJobs(
                      //       null,
                      //       null,
                      //       null,
                      //       "MOST_APPLICATION",
                      //       null,
                      //       null
                      //     );
                      //   }
                      //   if (sortItem === "Most Views") {
                      //     fetchAllJobs(
                      //       null,
                      //       null,
                      //       null,
                      //       "MOST_VIEWS",
                      //       null,
                      //       null
                      //     );
                      //   }
                      //   if (sortItem === "Most Referrals") {
                      //     fetchAllJobs(
                      //       null,
                      //       null,
                      //       null,
                      //       "MOST_REFERRALS",
                      //       null,
                      //       null
                      //     );
                      //   }
                      //   if (sortItem === "A-Z") {
                      //     fetchAllJobs(null, null, null, "A_Z", null, null);
                      //   }
                      // }}
                      onClick={() => {
                        if (arraySortIndex == index) {
                          dropdownSortHandler(null);
                        } else {
                          dropdownSortHandler(index, sortItem.value);
                        }
                      }}
                    >
                      <img
                        src={tick}
                        alt="blackdone-icon"
                        className={
                          tableDropdownSortIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          tableDropdownSortIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </div>
          </Dropdown>

          <Dropdown
            className="filter-button d-none d-md-flex h-28"
            autoClose="outside"
          >
            <Dropdown.Toggle
              variant="multiple"
              id="dropdown-autoclose-false"
              className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
            >
              <img src={filterIcon} alt="" />
              <span className="fs-12 fw-400">All Teams</span>
              <span
                className={`${allTeamsDropdownRedDot}  round-red-dot rounded-circle ms-1 mb-2`}
              ></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="fs-12 text-secondary ">
              <ul
                className="m-0 p-0 overflow-auto"
                style={{ maxHeight: "300px" }}
              >
                {teamData?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item key={index}>
                      <div
                        className="d-flex pb-3"
                        style={{ zIndex: 1000 }}
                        onClick={(e) => {
                          dropdownAllTeamsHandler(index);
                        }}
                      >
                        <input
                          type="checkbox"
                          className="me-2"
                          id={index}
                          name={index}
                          checked={
                            arrayAllTeamsIndex.includes(index) ? true : false
                          }
                          // onClick={() => {
                          //   dropdownAllTeamsHandler(index, sortItem.id);
                          // }}
                          onChange={(e) => {
                            checkAllTeamsHandler(e?.target?.checked, sortItem);
                          }}
                        />
                        <label htmlFor={index}>{sortItem.team}</label>
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </ul>
              <div clasname="mx-4">
                <button
                  className="btn gap-2 rounded fs-14 fw-700 border border-dark mx-2 w-90"
                  onClick={() => {
                    fetchAllJobs({
                      ...allJobsPostFilterData,
                      teamId: teamArray,
                      pageNo: 1,
                      pageSize: DEFAULT_PAGE_SIZE,
                    });
                  }}
                >
                  <span className="fs-12 fw-700 m-2">Apply</span>
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown
            className="filter-button d-none d-md-flex h-28"
            autoClose="outside"
          >
            <Dropdown.Toggle
              variant="none"
              id="dropdown-autoclose-false"
              className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
            >
              <img src={filterIcon} alt="" />
              <span className="fs-12 fw-400">All status</span>
              <span
                className={`${allStatusDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
              ></span>
            </Dropdown.Toggle>

            <Dropdown.Menu className="fs-12 text-secondary ">
              {dropdownAllStatusArray.map((sortItem, index) => {
                return (
                  <Dropdown.Item
                    key={index}
                    className="d-flex pb-3"
                    onClick={() => {
                      dropdownAllStatusHandler(index);
                    }}
                  >
                    <div
                      className="d-flex pb-3"
                      style={{ zIndex: 1000 }}
                      onClick={() => {
                        dropdownAllStatusHandler(index);
                      }}
                    >
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={
                          arrayAllStatusIndex.includes(index) ? true : false
                        }
                        onChange={(e) => {
                          checkAllStatusHandler(
                            e?.target?.checked,
                            sortItem.value
                          );
                        }}
                      />{" "}
                      <label>{sortItem.name}</label>
                    </div>
                  </Dropdown.Item>
                );
              })}
              <div clasname="mx-4">
                <button
                  className="btn gap-2 rounded fs-14 fw-700 border border-dark mx-2 w-90"
                  onClick={() => {
                    fetchAllJobs({
                      ...allJobsPostFilterData,
                      jobStatus: allStatus,
                      pageNo: 1,
                      pageSize: DEFAULT_PAGE_SIZE,
                    });
                  }}
                >
                  <span className="fs-12 fw-700 m-2">Apply</span>
                </button>
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <div className="position-relative">
            <div
              className={`${displayDatePickerTable} custome-date-box custome-date-box-position p-3`}
            >
              <DateRange
                ranges={dateRange}
                onChange={(e) => {
                  handleDateRange(e);
                }}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                maxDate={currentDate}
              />
              <div className="d-flex justify-content-end">
                <button
                  style={{ border: "1px solid black", width: "80px" }}
                  className="btn bnt-light color-dark fs-14 p-0"
                  onClick={() => {
                    setDisplayDatePickerTable("d-none");
                    dropdownAllTimeHandler(null);
                    setTimeIntervalDropdownRedDot("d-none");
                  }}
                >
                  Cancel
                </button>
                <button
                  style={{ border: "1px solid black", width: "80px" }}
                  className="btn bnt-light color-dark fs-14 p-0 ms-2 "
                  onClick={() => {
                    setDisplayDatePickerTable("d-none");
                    setTimeIntervalDropdownRedDot("d-block");
                    dropdownAllTimeHandler(5);
                  }}
                >
                  OK
                </button>
              </div>
            </div>

            <Dropdown
              className="filter-button d-none d-md-flex h-28"
              style={{ width: "fit-content" }}
            >
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
              >
                <img src={filterIcon} alt="" />
                <span className="fs-12 fw-400">All Time</span>
                <span
                  className={`${timeIntervalDropdownRedDot} round-red-dot rounded-circle ms-2 mb-2`}
                ></span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary ">
                {timelineArray.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex pb-3"
                      onClick={() => {
                        if (arrayAllTimeIndex == index) {
                          dropdownAllTimeHandler(null);
                          setTimeIntervalDropdownRedDot("d-none");
                        } else {
                          if (sortItem == CUSTOM_DATE_OPTION) {
                            if (customStartDate == "" || customEndDate == "") {
                              setTimeIntervalDropdownRedDot("d-none");
                            }
                            onCustomDateSelectTable(sortItem);
                          } else {
                            dropdownAllTimeHandler(index);
                            setTimeIntervalDropdownRedDot("d-block");
                          }
                        }
                        // if (sortItem === "1 Month") {
                        //   fetchAllJobs(null, month1, date, null, null, null);
                        // }
                        // if (sortItem === "3 Months") {
                        //   fetchAllJobs(null, month3, date, null, null, null);
                        // }
                        // if (sortItem === "6 Months") {
                        //   fetchAllJobs(null, month6, date, null, null, null);
                        // }
                        // if (sortItem === "12 Months") {
                        //   fetchAllJobs(null, month12, date, null, null, null);
                        // }
                      }}
                    >
                      <div>
                        <div className="d-flex">
                          <img
                            src={tick}
                            alt="blackdone-icon"
                            className={
                              arrayAllTimeIndex === index
                                ? "pe-2 d-block"
                                : "d-none pe-1"
                            }
                          />
                          <span
                            className={
                              arrayAllTimeIndex === index
                                ? "ps-0 color-primary fw-700"
                                : "ps-4"
                            }
                          >
                            {sortItem.name}
                          </span>
                        </div>

                        {sortItem == CUSTOM_DATE_OPTION &&
                        arrayAllTimeIndex == 5 &&
                        customStartDate != "" &&
                        customEndDate != "" ? (
                          <div className="small-text-gray fs-12 mt-2">
                            {`${customStartDate} to ${customEndDate}`}
                          </div>
                        ) : null}
                      </div>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>

            {/* All Filters DD shown below md */}

            <Dropdown
              onClick={() => {
                if (hrSrlDiamension?.width <= 768) {
                  setShowAllFilters(true);
                }
              }}
              className="filter-button d-block d-md-none h-28"
              autoClose="outside"
            >
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
                style={{ height: "28px" }}
              >
                <img src={filterIcon} alt="" className="me-1" />
                <span className="fs-12 fw-400">Filters</span>
                {allFiltersDropdownRedDot && (
                  <span className="round-red-dot rounded-circle ms-2 mb-2"></span>
                )}
              </Dropdown.Toggle>
            </Dropdown>
          </div>
        </div>

        {/* Table */}

        <div
          style={{
            width: getHorizotalScrollWidth(hrSrlDiamension.width),
          }}
          className="d-none d-md-block mb-5 table-responsive"
        >
          <InfiniteScroll
            dataLength={currentPageset}
            next={() => fetchMoreJobs()}
            hasMore={dataCount > currentPageset}
            className="container-fluid"
          >
            <table className="table mt-4 justify-content-between">
              <thead className="border-bottom">
                <tr className="">
                  <th
                    style={{ minWidth: "200px" }}
                    className="border-0 fs-12 fw-700 pb-4"
                  >
                    Title
                  </th>
                  <th
                    style={{ width: "80px" }}
                    className=" border-0 fs-12 fw-700 "
                  >
                    <span
                      className=" m-0 d-flex fs-12 fw-700 pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Views
                      {upDownArrowView ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_VIEWS_ASC");
                            setUpDownArrowView(!upDownArrowView);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_VIEWS");
                            setUpDownArrowView(!upDownArrowView);
                          }}
                        ></img>
                      )}{" "}
                    </span>
                  </th>
                  <th
                    style={{ width: "80px" }}
                    className="border-0 fs-12 fw-700"
                  >
                    <span
                      className=" m-0 d-flex fs-12 fw-700 pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Applications
                      {upDownArrowApplication ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_APPLICATION_ASC");
                            setUpDownArrowApplication(!upDownArrowApplication);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_APPLICATION");
                            setUpDownArrowApplication(!upDownArrowApplication);
                          }}
                        ></img>
                      )}{" "}
                    </span>
                  </th>
                  <th
                    style={{ width: "80px" }}
                    className="border-0 fs-12 fw-700"
                  >
                    <span
                      className=" m-0 d-flex fs-12 fw-700  pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Referrals
                      {upDownArrowReferrals ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_REFERRALS_ASC");
                            setUpDownArrowReferrals(!upDownArrowReferrals);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_REFERRALS");
                            setUpDownArrowReferrals(!upDownArrowReferrals);
                          }}
                        ></img>
                      )}{" "}
                    </span>
                  </th>
                  <th
                    style={{ width: "80px" }}
                    className="border-0 fs-12 fw-700"
                  >
                    <span
                      className=" m-0 d-flex fs-12 fw-700  pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Shortlisted
                      {upDownArrowShort ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "SHORTLIST_COUNT_ASC");
                            setUpDownArrowShort(!upDownArrowShort);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "SHORTLIST_COUNT");
                            setUpDownArrowShort(!upDownArrowShort);
                          }}
                        ></img>
                      )}{" "}
                    </span>
                  </th>
                  <th
                    style={{ width: "80px" }}
                    className="border-0 fs-12 fw-700"
                  >
                    <span
                      className=" m-0 d-flex fs-12 fw-700  pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Assessment
                      {upDownArrowAssess ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "ASSESSMENT_ASC");
                            setUpDownArrowAssess(!upDownArrowAssess);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "ASSESSMENT");
                            setUpDownArrowAssess(!upDownArrowAssess);
                          }}
                        ></img>
                      )}{" "}
                    </span>
                  </th>
                  <th
                    style={{ width: "250px" }}
                    className="border-0 fs-12 fw-700 align-top"
                  >
                    <span className=" m-0 d-flex align-items-center fs-12 fw-700">
                      <span className="me-1">Pending Screening</span>
                      {upDownArrowPendingScreeen ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "PENDING_SCREENING_ASC");
                            setUpDownArrowPendingScreeen(
                              !upDownArrowPendingScreeen
                            );
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "PENDING_SCREENING");
                            setUpDownArrowPendingScreeen(
                              !upDownArrowPendingScreeen
                            );
                          }}
                        ></img>
                      )}{" "}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="table_body mt-4">
                {jobslist && jobslist.length > 0 ? (
                  jobslist?.map((item, key) => {
                    return (
                      <>
                        <tr
                          key={key}
                          className="card-parent col-12 position-relative"
                        >
                          <td className="col-md-4">
                            <div className="p-0 m-0">
                              <p className="m-0 p-0 fs-14 fw-400 color-262626 ">
                                {item?.jobTitle}
                              </p>

                              <p
                                className="fs-11 fw-300 d-flex align-items-center mt-1"
                                style={{ color: "#808080" }}
                              >
                                {item?.jobStatus == "LIVE" ? (
                                  <>
                                    <span className="dot green mt-0 me-1"></span>
                                    <span className="">
                                      {item?.jobStatus} | {item.jobView} |
                                      {item.jobPostingDate
                                        ? ` Posted ${item.jobPostingDate}`
                                        : ""}
                                    </span>
                                  </>
                                ) : item?.jobStatus === "PAUSED" ? (
                                  <>
                                    <span className="dot yellow mt-0 me-1"></span>
                                    <span className="">
                                      {item?.jobStatus} | {item.views} |
                                      {item.jobPostingDate
                                        ? ` Posted ${item.jobPostingDate}`
                                        : ""}
                                    </span>
                                  </>
                                ) : item?.jobStatus === "FILLED" ? (
                                  <>
                                    <span className="dot gray mt-0 me-1"></span>
                                    <span className="">
                                      {item?.jobStatus} | {item.views} |
                                      {item.jobPostingDate
                                        ? ` Posted ${item.jobPostingDate}`
                                        : ""}
                                    </span>
                                  </>
                                ) : item?.jobStatus === "CLOSED" ? (
                                  <>
                                    <span className="dot gray mt-0 me-1"></span>
                                    <span className="">
                                      {item?.jobStatus} | {item.views} |
                                      {item.jobPostingDate
                                        ? ` Posted ${item.jobPostingDate}`
                                        : ""}
                                    </span>
                                  </>
                                ) : (
                                  ""
                                )}
                              </p>
                            </div>
                          </td>
                          <td className="fs-14 align-middle">
                            <p className="m-0">{item?.jobView}</p>
                          </td>
                          <td className="fs-14  align-middle">
                            <p className=" m-0">{item?.applicationCount} </p>
                            {item.newApplicationCount > 0 ? (
                              <span
                                className="fs-11 fw-700"
                                style={{ color: "#EB1260" }}
                              >
                                {item.newApplicationCount} New
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="fs-14  align-middle">
                            <p className=" m-0">{item?.referralCount}</p>
                            {item.referralCount > 0 ? (
                              <span
                                className="fs-11 fw-700"
                                style={{ color: "#EB1260" }}
                              >
                                {item.referralCount} New
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="fs-14  align-middle">
                            <p className="m-0">{item?.shortlistedCount}</p>
                          </td>
                          <td className="fs-14  align-middle">
                            {" "}
                            <p className="m-0"> {item?.assessmentCount}</p>
                          </td>
                          <td className="align-middle align-items-center">
                            <div
                              style={{
                                display: "grid",
                                gridAutoFlow: "column",
                              }}
                            >
                              <p className="fs-14 m-0">
                                {item?.pendingScreening}
                              </p>

                              <div className="onhover-show ">
                                <div
                                  className="d-flex align-items-center  position-absolute h-32 gap-3 "
                                  style={{
                                    right: "10px",
                                  }}
                                >
                                  <div
                                    onClick={() => {
                                      navigate(
                                        "/" +
                                          APPLICATIONS_MAIN_PAGE_ROUTE +
                                          "/" +
                                          item?.jobId
                                      );
                                    }}
                                    type="button"
                                    className={`fs-12 btn bg-black  align-middle px-3 text-white down-arrow  align-items-center `}
                                    style={{ minWidth: "100px" }}
                                  >
                                    Manage
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <center className="margin-auto">No Data Found</center>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>

        {/* Table Cards */}

        <div className="d-block d-md-none">
          <div className=" d-flex  flex-wrap justify-content-between my-sm-3 my-md-5 card-main my-2 mb-5 text-decoration-none text-dark">
            {jobslist?.length > 0
              ? jobslist?.map((item, key) => {
                  return (
                    <div key={key} className="  col-12 col-sm-6 p-2">
                      <div className="card-body card small-referee-cards rounded-4">
                        <div className="card-title fs-14 fw-400 ">
                          {item?.title}
                        </div>
                        <div className=" fs-11 fw-300 text-muted d-flex ">
                          <div>
                            {item.status === "LIVE" ? (
                              <>
                                <span className="dot green"></span>
                              </>
                            ) : item.status === "PAUSED" ? (
                              <>
                                <span className="dot yellow"></span>
                              </>
                            ) : item.status === "FILLED" ? (
                              <>
                                <span className="dot gray"></span>
                              </>
                            ) : item.status === "CLOSED" ? (
                              <>
                                <span className="dot gray"></span>
                              </>
                            ) : (
                              ""
                            )}
                            {item.status} | {item.views} | {item.jobPostingDate}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mt-2">
                          <div className="card-title fs-12 fw-400 py-0">
                            Applications
                          </div>
                          <div className="fs-14 fw-400">
                            {item?.applications}
                            {/* <span className="px-1 fs-11 text-danger fw-600 m-0">
                            6 New
                          </span> */}
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div className="card-title fs-12 fw-400 py-0">
                            Assessment
                          </div>
                          <div className="fs-14 fw-400">{item?.assesments}</div>
                        </div>

                        <div
                          className="referee-small-card-btn p-2"
                          style={{ right: 0, left: 0, bottom: 0 }}
                        >
                          <div className="">
                            <div
                              type="button"
                              className={`w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-light bg-dark`}
                              // style={{ minWidth: "100px" }}
                            >
                              Manage
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
            {jobslist?.length === 0 ? (
              <div className="row">
                <center className="m-4">No Data Found</center>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      {/* User / Filter Modals */}

      <TeamFilterTeamsDialog
        userType={"Select User"}
        viewTeam={viewTeam}
        show={filterTeamsShow}
        onSortClose={() => setFilterTeamsShow(false)}
        sortArray={businessArray}
        teamsSortIndex={businessSortIndex}
        setTeamsArraySortIndex={setBusinessArraySortIndex}
      />

      <TeamSortTableDialog
        show={sortUserTimeShow}
        onSortClose={() => setSortUserTimeShow(false)}
        sortArray={timelineArray}
        arraySortIndex={arraySortIndex}
        setArraySortIndex={setArraySortIndex}
        setTimeDropdownRedDot={setTimeDropdownRedDot}
        showDatePickerModal={showDatePickerModal}
        setShowDatePickerModal={setShowDatePickerModal}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        setCustomStartDate={setCustomStartDate}
        setCustomEndDate={setCustomEndDate}
        displayDatePicker={displayDatePicker}
        setDisplayDatePicker={setDisplayDatePicker}
      />

      <SortByFilterModal
        dropdownSortHandler={tableDropdownSortHandler}
        arraySortIndex={tableDropdownSortIndex}
        setArraySortIndex={setTableDropdownSortIndex}
        dropdownSortArray={dropdownSortArray}
        show={showSortByFilter}
        close={() => setShowSortByFilter(false)}
        setTableTimeDropdownRedDot={setTableTimeDropdownRedDot}
      />

      <AllFiltersMobileModal
        dropdownAllStatusArray={dropdownAllStatusArray}
        dropdownAllTeamsArray={dropdownAllTeamsArray}
        dropdownAllTimeArray={timelineArray}
        show={showAllFilters}
        close={() => setShowAllFilters(false)}
        setArrayAllTimeIndex={setArrayAllTimeIndex}
        setArrayAllTeamsIndex={setArrayAllTeamsIndex}
        setArrayAllStatusIndex={setArrayAllStatusIndex}
        arrayAllTeamsIndex={arrayAllTeamsIndex}
        arrayAllStatusIndex={arrayAllStatusIndex}
        arrayAllTimeIndex={arrayAllTimeIndex}
        postByArrayIndex={postByArrayIndex}
        setPostByArrayIndex={setPostByArrayIndex}
        showDatePickerModal={showDatePickerModal}
        setShowDatePickerModal={setShowDatePickerModal}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        setCustomStartDate={setCustomStartDate}
        setCustomEndDate={setCustomEndDate}
        displayDatePicker={displayDatePicker}
        setDisplayDatePicker={setDisplayDatePicker}
        setTimeDropdownRedDot={setAllFiltersDropdownRedDot}
      />

      <DatePickerModal
        showDatePickerModal={showDatePickerModal}
        setShowDatePickerModal={setShowDatePickerModal}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        setCustomStartDate={setCustomStartDate}
        setCustomEndDate={setCustomEndDate}
        dateRange={dateRange}
        setDateRange={setDateRange}
        currentDate={currentDate}
        displayDatePicker={displayDatePicker}
        setDisplayDatePicker={setDisplayDatePicker}
        setArraySortIndex={dropdownAllTimeHandler}
        handleDateRange={handleDateRange}
        setTimeDropdownRedDot={setAllFiltersDropdownRedDot}
      />
    </div>
  );
}

export default IndividualBusinessProfileDetails;
