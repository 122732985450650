import React from "react";
import { useState } from "react";
import icGreenDone from "../../../assests/icons/ic-green-tick.svg";
import PaymentRequestProceed from "../../../dialogs/application_dialogs/requestpaymentproceed";
import { convertToInternationalCurrencySystem } from "../../../utils/utils";

const ApplicationReferralBonus = ({
  applicationDetails,
  appDetails,
  viewApplicationDetails,
  id,
}) => {
  const [showProceedDialog, setShowProceedDialog] = useState(false);

  let referralAmount = convertToInternationalCurrencySystem(
    applicationDetails?.amount,
    "INR"
  );
  console.log("application---------->", applicationDetails?.amount);

  return (
    <div className="shadow-box mt-4">
      <div className="p-3">
        <div className="color-secondary fs-16 fw-700">Referral Bonus </div>
        <div className="d-flex justify-content-between mt-2">
          <div className="">
            <div>
              ₹{" "}
              
              {referralAmount === null 
  ? referralAmount
  : ((applicationDetails?.amount) === undefined )
    ? 0
    : Number(applicationDetails?.amount)
}
            </div>

            {applicationDetails?.paymentStatus === "PAYMENT_PROCESSED" ? (
              <div className="d-flex gap-2">
                <div>
                  <img src={icGreenDone} alt="green tick icon" />
                </div>
                <div className="fs-14 fw-700 dark-green-text">Processed</div>
              </div>
            ) : (
              ""
            )}

            {applicationDetails?.paymentStatus === "REQUEST_PAYMENT" ? (
              <div className="d-flex gap-2">
                <div className="fs-14 fw-700 dark-red-text">
                  Payment Requested
                </div>
              </div>
            ) : (
              ""
            )}

            {applicationDetails?.paymentStatus === "PAYMENT_PROCESSING" ? (
              <div className="d-flex gap-2">
                <div className="fs-14 fw-700 text-warning">
                  Payment Processing
                </div>
              </div>
            ) : (
              ""
            )}

            {applicationDetails?.paymentStatus === "PAYMENT_REJECTED" ? (
              <div className="d-flex gap-2">
                <div className="fs-14 fw-700 dark-red-text">
                  Payment Rejected
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            {applicationDetails?.paymentStatus !== "PAYMENT_PROCESSED" ? (
              <button
                className="btn-black-bg ps-5 pe-5 "
                onClick={() => setShowProceedDialog(true)}
              >
                View Details
              </button>
            ) : null}
          </div>
        </div>
      </div>
      
      <PaymentRequestProceed
        applicationDetails={applicationDetails}
        appDetails={appDetails}
        show={showProceedDialog}
        onPaymentProceedClose={() => setShowProceedDialog(false)}
        viewApplicationDetails={viewApplicationDetails}
        id={id}
      />
    </div>
  );
};

export default ApplicationReferralBonus;
