/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import ApplicationHoverCard from "./application_hover_card";
import profileImg from "../../../../assests/images/candidate-profile-pic.jpg";
import icGreenDone from "../../../../assests/icons/ic-green-done.svg";
import DropdownCardOffered from "../dropdown_card_offered";
import ProfileImage from "../../../../components/profile_image";
import icFlag from "../../../../assests/icons/ic-flag.svg";
import icDownload from "../../../../assests/icons/ic-download.svg";
import { checkedItemsHandler } from "../../../../utils/utils";
import {
  updateAcceptedStatus,
  updateApplicationStatus,
} from "../../../../_services/view.service";
import toaster from "../../../../utils/toaster";

const ApplicationCardOffered = (
  {
    applicationCardDetailsList,
    jobId,
    selectedStatus,
    getApplicationCardDetails,
  },
  props
) => {
  const [selectForDownloadClicked, setSelectForDownloadClicked] =
    useState(false);
  const [cardClicked, setCardClicked] = useState(null);
  const [cardSelected, setCardSelected] = useState([]);

  console.log("getAppCardsList---------->", applicationCardDetailsList);

  const statusChange = (appStatus, id) => {
    console.log("buttonclick-->", appStatus);
    updateApplicationStatus(id, appStatus).then((res) => {
      console.log("update Application Status", res);
      // setShowLoader(false);
      getApplicationCardDetails();

      if (res?.data?.message !== "Application Status Updated !!") {
        toaster("error", res?.data?.message, 4000);
      } else {
        toaster("success", res?.data?.message, 4000);
      }
    });
    // setShowLoader(true);
  };

  const offerAccepted = (id, isOfferedAccept) => {
    updateAcceptedStatus(id, isOfferedAccept).then((res) => {
      console.log("update Application Status", res);
      // setShowLoader(false);
      getApplicationCardDetails();
    });
    // setShowLoader(true);
  };

  useEffect(() => {
    if (props?.selectManually) {
      setSelectForDownloadClicked(true);
    } else {
      setSelectForDownloadClicked(false);
    }
  }, [props?.selectManually]);

  const cardSelectToggle = (index) => {
    checkedItemsHandler(index, cardSelected, setCardSelected);
  };

  return (
    <div className="mt-2">
      <div className="row">
        {applicationCardDetailsList?.applicationList?.map((details, index) => {
          return (
            <div className="col-xl-3 col-lg-4 col-sm-6 col-12 mt-3" key={index}>
              <div
                className={
                  selectForDownloadClicked
                    ? "application-card p-3 pb-0"
                    : "application-card p-3 "
                }
                style={{
                  border:
                    cardClicked === index ? "1px solid #808080 !important" : "",
                }}
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex gap-2 pt-2 ps-2">
                    <div>
                      {/* <img
                        src={profileImg}
                        alt="profile-image"
                        className="rounded-circle"
                      /> */}
                      <ProfileImage
                        src={
                          process.env.REACT_APP_IMAGE_BASEURL +
                          details?.profilePicUrl
                        }
                        // name={sms.senderName}
                        width="40px"
                        height="40px"
                        initialsContainerClass="rounded-circle"
                      />
                    </div>
                    <div>
                      <div className="medium-black-text fs-14 fw-600 text-break">
                        {details?.candidateName}
                      </div>
                      <div className="fs-11 fw-3-- color-tertiary">
                        {details?.candidateDesignation}
                      </div>
                      <div className="fs-11 fw-3-- color-tertiary">
                        {details?.candidateCompany}
                      </div>
                    </div>
                  </div>
                  <div className="">
                    {/* <img src={icThreeDots} alt="retract-icon" /> */}
                    <DropdownCardOffered
                      applicationId={details.applicationId}
                      getApplicationCardDetails={() =>
                        getApplicationCardDetails()
                      }
                    />
                  </div>
                </div>
                {/* card header finished */}
                <hr className="mb-1"></hr>
                <div className="">
                  <div className="d-flex justify-content-between">
                    <div
                      className="dark-red-text fs-12 fw-600 p-2 pointer "
                      onClick={() => {
                        statusChange("RETRACTED", details.applicationId);
                        // toaster("success", "Dropout successfully");
                      }}
                    >
                      Dropout
                    </div>
                    <div className="h-1 border"></div>
                    {!details?.isOfferedAccept ? (
                      <div
                        className="dark-green-text fs-12 fw-600 p-2 pointer "
                        onClick={() => {
                          // statusChange("APPLIED", details.applicationId);
                          offerAccepted(details?.applicationId, true);
                          // toaster("success", "Accepted successfully");
                        }}
                      >
                        Accept
                      </div>
                    ) : (
                      <div className="inactive-text fs-12 fw-600 p-2 ">
                        Accepted
                      </div>
                    )}

                    {/* <div
                      className="dark-green-text fs-12 fw-600 p-2 "
                      onClick={() => {
                        // statusChange("APPLIED", details.applicationId);
                        offerAccepted(details?.applicationId, true);
                        toaster("success", "Accepted successfully")
                      }}
                    >
                      Accepted
                    </div> */}
                    <div className="h-1 border"></div>

                    {details?.isOfferedAccept ? (
                      <div
                        className="dark-green-text fs-12 fw-600  p-2 pointer"
                        onClick={() => {
                          statusChange("JOINED", details.applicationId);
                          // toaster("success", "Joined successfully");
                        }}
                      >
                        Join
                      </div>
                    ) : (
                      <div className="inactive-text fs-12 fw-600  p-2 ">
                        Join
                      </div>
                    )}

                    {/* <div
                      className="inactive-text fs-12 fw-600  p-2 "
                      onClick={() => {
                        statusChange("JOINED", details.applicationId);
                        toaster("success", "Joined successfully");
                      }}
                    >
                      Joined
                    </div> */}
                  </div>
                </div>
                <hr className="mt-1"></hr>
                {/* referral section */}
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="fs-11 color-tertiary fw-300">Referral</div>
                    {details?.isRefferal === true ? (
                      <div className="d-flex gap-1 align-items-center">
                        <div>
                          <img src={icGreenDone} alt="green-done icon" />
                        </div>
                        <div className="dark-green-text fs-11 fw-600">
                          {/* {details?.referralStatus} */}Yes
                        </div>
                      </div>
                    ) : (
                      <div className="color-tertiary fs-11 fw-600">
                        {/* {details?.referralStatus} */}No
                      </div>
                    )}
                  </div>
                  <div className="text-end">
                    <div className="fs-11 color-tertiary fw-300">
                      Experience
                    </div>

                    <div className="fade-gray-text fs-11 fw-500">
                      {details?.experience} Years | {details?.roles} Roles
                    </div>
                  </div>
                </div>
                <hr className="mt-2"></hr>
                <div>
                  {details?.isOfferedAccept ? (
                    <div className="dark-green-text fs-11 fw-400">
                      Offer Accepted
                    </div>
                  ) : (
                    <div className="color-tertiary fs-11 fw-300">
                      Offer not accepted yet
                    </div>
                  )}
                </div>
                {selectForDownloadClicked && (
                  <div>
                    <hr className="mb-1"></hr>
                    <div className="d-flex mb-1 gap-1">
                      <div className="bg-green-icon d-flex align-item-center p-1">
                        <img src={icFlag} alt="flag-icon" />
                      </div>
                      <div className="bg-gray-icon d-flex align-item-center p-1">
                        <img src={icDownload} alt="download-icon" />
                      </div>
                    </div>
                    <div className="side-margin-width">
                      <hr className="m-0 p-0"></hr>
                      <div className="d-flex justify-content-between pt-2 select-for-export ps-3 pe-3">
                        <div className="fs-11 color-primary fw-600">
                          Select for Export
                        </div>
                        <div className="form-check form-switch">
                          <div
                            className=""
                            onClick={() => {
                              cardSelectToggle(index);
                            }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input switch-btn-small pointer color-primary"
                              checked={
                                cardSelected.includes(index) ? true : false
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {selectForDownloadClicked === false && (
                  <div className="application-hover-card">
                    <ApplicationHoverCard
                      applicationId={details?.applicationId}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApplicationCardOffered;
