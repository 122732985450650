import React, { useRef, useEffect, useState } from "react";
import { Col, Dropdown, Row, Table } from "react-bootstrap";
import AddImageLogo from "../../../assests/icons/ic-add-filled.svg";
import SortBy from "../../../assests/ic_sort.svg";
import RightArrow from "../../../assests/Vector.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import checkedLogo from "../../../assests/ic_selected16.svg";
import "../jobs_post_page/job-post-style.scss";
import JobPostsCard from "../../../components/JobPostsCard";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import AddLogoWhite from "../../../assests/addLogoWhite.svg";
import dropdownArrow from "../../../assests/downArrow.svg";
import upArrow from "../../../assests/upArrow.svg";
import filterLogo from "../../../assests/filterLogo.svg";
import tick from "../../../assests/icons/ic-combo-tick.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import pinUnfilled from "../../../assests/icons/ic_pin.svg";
import pinFilled from "../../../assests/icons/ic_pin_filled.svg";
import {
  APPLICATIONS_MAIN_PAGE_ROUTE,
  JOB_POST_CREATE_PAGE_ROUTE,
} from "../../../config/page_routes_constants";
import { SORT } from "../../../config/filter_constants";
import SortByFilterModal from "../../../dialogs/jobs_dialogs/sort-by-filter-modal";
import { getjobSummary, pinIconJob } from "../../../_services/view.service";
import AllJobsFilterDialog from "../../../dialogs/all_jobs_dialogs/all_jobs_filter_dialog";
import "./admin_all_jobs.scss";
import useWindowDimensions from "../../../utils/use_window_dimension";
import {
  getAllJobPost,
  jobPostsPinOrUnpin,
} from "../../../_services/serikoradmin_view.service";
import { isEmpty } from "../../../utils/form_validators";
import Loader from "../../../components/common/loader";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import toaster from "../../../utils/toaster";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import { filterfeaturedJobs } from "../../../utils/featured_job_layout";
import ProfileImage from "../../../components/profile_image";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";

const AdminAllJobsPage = () => {
  let isInfinite = false;
  const navigate = useNavigate();

  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    // searchString: null,
    // sortBy: null,
  };

  const [allJobFilterData, setAllJobFilterData] = useState(initialFilterData);
  const [showLoader, setShowLoader] = useState(false);

  const [jobList, setJobList] = useState([]);
  const [currentOffset, setCurrentOffSet] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [allJobMessage, setAllJobMessage] = useState("");
  const [tempJobFilter, setTempJobFilter] = useState({});
  const [allJobFilterDataParams, setAllJobFilterDataParams] = useSearchParams({
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });
  const [arraySortIndex, setArraySortIndex] = useState(0);
  const [showSortByFilter, setShowSortByFilter] = useState(false);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [apiRefresh, setApiRefresh] = useState(false);

  const [roleOutput, setRoleOutput] = useState("New Applications");
  const [quickFilters, setQuickFilters] = useState([
    {
      name: "New Applications",
      value: "APPLICATION_COUNT",
    },
    {
      name: "New Referrals",
      value: "REFERRALS_COUNT",
    },
    {
      name: "Pending Screening",
      value: "PENDING_SCREENING",
    },
  ]);

  const fetchAllJobsInitialcall = () => {
    setJobList([]);
    setAllJobFilterData(initialFilterData);
    fetchAllJobs(initialFilterData);
  };

  const pinOrUnpinJobs = (jobId, isPin) => {
    setShowLoader(true);
    setApiRefresh(true);

    jobPostsPinOrUnpin(jobId, isPin)
      .then((res) => {
        if (res?.data?.status === STATUS_SUCCESS) {
          toaster("success", res?.data?.message);

          fetchAllJobsInitialcall();
          setShowLoader(false);
          setApiRefresh(true);
        } else {
          toaster("success", res?.data?.message);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
        toaster("error", err);
      });
  };

  const dropdownSortArray = [
    { name: "New Applications First", value: "NEW_APPLICATION_FIRST" },
    { name: "New Referrals First", value: "NEW_REFERRALS_FIRST" },
    { name: "Pending Screening", value: "PENDING_SCREENING" },
    { name: "Application Count", value: "APPLICATION_COUNT" },
    { name: "Referral Count", value: "REFERRALS_COUNT" },
    { name: "Views", value: "VIEWS" },
    { name: "Status", value: "STATUS" },
    { name: "Shortlist Count", value: "SHORTLIST_COUNT" },
    { name: "Assessment Count", value: "ASSESSMENT_COUNT" },
  ];

  const [analyticsFiltersDropdownRedDot, setAnalyticsFiltersDropdownRedDot] =
    useState("d-none");

  const [analyticsSortByDropdownRedDot, setAnalyticsSortByDropdownRedDot] =
    useState("d-none");

  useEffect(() => {
    if (arraySortIndex == null) {
      setAnalyticsSortByDropdownRedDot("d-none");
    } else {
      setAnalyticsSortByDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  useEffect(() => {
    if (arraySortIndex?.length > 0) {
      setAnalyticsFiltersDropdownRedDot("d-none");
    } else {
      setAnalyticsFiltersDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  const [upDownArrowView, setUpDownArrowView] = useState(true);
  const [upDownArrowApplication, setUpDownArrowApplication] = useState(true);
  const [upDownArrowReferrals, setUpDownArrowReferrals] = useState(true);
  const [upDownArrowPendingScreeen, setUpDownArrowPendingScreeen] =
    useState(true);

  const dropdownSortHandler = (index, value) => {
    setArraySortIndex(index);
    setAllJobFilterData({
      ...allJobFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    fetchAllJobs({
      ...allJobFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  // const hrSrlDiamension = useWindowDimensions();
  // const getHorizotalScrollWidth = (winWidth) => {
  //   if (winWidth > 1500) {
  //     return 955 + "px";
  //   } else if (winWidth > 1300 && winWidth > 1499) {
  //     return winWidth - 420 + "px";
  //   } else if (winWidth < 1300 && winWidth > 991) {
  //     return winWidth - 800 + "px";
  //   } else if (winWidth < 990 && winWidth > 768) {
  //     return winWidth - 80 + "px";
  //   } else if (winWidth < 767 && winWidth > 551) {
  //     return winWidth - 110 + "px";
  //   } else if (winWidth < 550) {
  //     return winWidth - 110 + "px";
  //   }
  // };

  const fetchAllJobs = (filter) => {
    setShowLoader(true);
    getAllJobPost(filter).then(
      (res) => {
        // if (res && !isEmpty(res?.data?.data)) {
        //   const data = res?.data?.data;
        //   const mergeData = [...jobList, ...data];
        //   setJobList(mergeData);
        // setCurrentOffSet(data?.length);
        // } else {
        //   setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        // }
        // toaster("success","Fetched All post jobs Successfully");
        if (res && !isEmpty(res?.data)) {
          setDataCount(res?.totalCount);

          let data;
          if (!isInfinite) {
            data = res?.data;
          } else {
            data = [...jobList, ...res?.data];
          }
          setCurrentOffSet(data?.length);
          setJobList(data);

          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };

  const fetchMoreData = () => {
    // if (jobList.length < dataCount) {
    setCurrentOffSet(currentOffset + 20);
    //   setPage_No(page_No + 1);
    // }
    isInfinite = true;

    fetchAllJobs({
      ...allJobFilterData,
      pageNo: allJobFilterData?.pageNo + 1,
    });
    setAllJobFilterData({
      ...allJobFilterData,
      pageNo: allJobFilterData?.pageNo + 1,
    });
  };
  // const refresh = () => {
  //   fetchMoreData();
  // };
  useEffect(() => {
    // setCurrentOffSet(allJobFilterData?.pageNo);
    // fetchMoreData();
    let temp = { ...initialFilterData };
    for (let entry of allJobFilterDataParams.entries()) {
      if (entry[0] === "searchString") {
        temp = {
          ...temp,
          searchString: entry[1],
        };
      } else if (entry[0] === "index") {
        setArraySortIndex(Number(entry[1]));
      } else if (entry[0] === "sortBy") {
        temp = {
          ...temp,
          sortBy: entry[1],
        };
      }
    }
    setAllJobFilterData(temp);
    fetchAllJobs(temp);
  }, []);

  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      // setAllJobFilterDataParams(allJobFilterData);
      fetchAllJobs(allJobFilterData);
    }
  };

  const editPreJobs = (value, PreJobId) => {
    localStorage.setItem("EditKeys", value);
    navigate("/" + JOB_POST_CREATE_PAGE_ROUTE + "/" + PreJobId);
  };

  const pinJobIcon = (jobId, pinJobs) => {
    setShowLoader(true);
    setApiRefresh(true);
    pinIconJob(jobId, pinJobs)
      .then((res) => {
        setShowLoader(false);
        if (res?.data?.status === STATUS_SUCCESS) {
          fetchAllJobsInitialcall();
          toaster("success", res.data.message);
          setShowLoader(false);
          setApiRefresh(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
        toaster("error", err);
      });
  };

  return (
    <div className="container  ">
      {showLoader && <Loader />}
      <div className="content-margin my-4">
        <div className=" justify-content-between align-items-center col-lg-12 d-sm-grid d-md-flex jobs-btn-focus">
          <p className="fs-24 fw-700 col-sm-12 col-md-6 col-lg-4 mb-2 ">
            All Jobs ({dataCount})
          </p>
        </div>
        <div className="mt-3 justify-content-between col-lg-12 d-md-flex gap-2 flex-wrap">
          <div className="flex-wrap gap-2 d-flex jobs-btn-focus">
            <Dropdown
              className="filter-button  h-28"
              onClick={() => setShowAllFilters(true)}
            >
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button jobs-btn-focus"
              >
                <img src={filterLogo} alt="" />
                <p className="fs-12 fw-400 pt-3 ps-1 ">Filters</p>
                {/* <span
                      className={`${analyticsFiltersDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                    ></span> */}
              </Dropdown.Toggle>
            </Dropdown>
            <Dropdown className="filter-button h-28 ">
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button jobs-btn-focus"
              >
                <img src={SortBy} alt="" />
                <p className="fs-12 fw-400 pt-3 ps-1">Sort By</p>
                <span
                  className={`${analyticsSortByDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                ></span>
                {/* <img className="" src={downArrow} alt="" /> */}
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary ">
                {dropdownSortArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex pb-3 jobs-btn-focus"
                      onClick={() => {
                        dropdownSortHandler(index, sortItem?.value);
                      }}
                    >
                      <img
                        src={tick}
                        alt="blackdone-icon"
                        className={
                          arraySortIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          arraySortIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>

            <div className="flex-wrap gap-2 d-none d-xl-flex h-28 jobs-btn-focus">
              {quickFilters?.map((output, index) => {
                return (
                  <div
                    className="fs-12   h-28 d-flex align-items-center px-2 gap-1 border  rounded-pill pointer jobs-btn-focus"
                    key={index}
                    onClick={() => {
                      setRoleOutput(`${output.name}`);
                      dropdownSortHandler(index, output?.value);
                    }}
                  >
                    {roleOutput.includes(output.name) ? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      ></img>
                    ) : (
                      ""
                    )}

                    <div>{output.name} </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="page-search m-0 my-md-0 my-4">
            <input
              className="col-12 h-32 jobs-btn-focus"
              type="text"
              placeholder="Search"
              defaultValue={allJobFilterData?.searchString}
              onChange={(event) => {
                setAllJobFilterData({
                  ...allJobFilterData,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });

                setTempJobFilter({
                  ...tempJobFilter,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
            />
            {/* onChange={(e) => fetchAllJobs(e.target.value, null)} */}
          </div>
        </div>
        {jobList && jobList?.length > 0 && (
          <InfiniteScroll
            dataLength={currentOffset}
            next={() => fetchMoreData()}
            hasMore={dataCount > currentOffset}
            className="container-fluid jobs-btn-focus"
          >
            <Table
              responsive
              className="mt-4 d-none d-md-block border-top responsive"
              // style={{
              //   width: getHorizotalScrollWidth(hrSrlDiamension.width),
              // }}
            >
              <thead className="border-bottom border-light jobs-btn-focus">
                <tr className="">
                  <th className=" fs-12">
                    <p className="py-4 m-0 d-flex fs-12">Organization</p>
                  </th>
                  <th>
                    <p className="py-4 m-0 d-flex fs-12">Title & Status</p>
                  </th>
                  <th>
                    <p
                      className="py-4 m-0 d-flex fs-12"
                      style={{ gap: "0.6rem" }}
                    >
                      Views
                      {upDownArrowView ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "VIEWS_ASC");
                            setUpDownArrowView(!upDownArrowView);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "VIEWS");
                            setUpDownArrowView(!upDownArrowView);
                          }}
                        ></img>
                      )}
                    </p>
                  </th>
                  <th>
                    <p
                      className="py-4 m-0 d-flex fs-12 "
                      style={{ gap: "0.6rem" }}
                    >
                      Applications
                      {upDownArrowApplication ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "APPLICATION_COUNT_ASC");
                            setUpDownArrowApplication(!upDownArrowApplication);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "APPLICATION_COUNT");
                            setUpDownArrowApplication(!upDownArrowApplication);
                          }}
                        ></img>
                      )}
                    </p>
                  </th>
                  <th>
                    <p
                      className="py-4 m-0 d-flex fs-12"
                      style={{ gap: "0.6rem" }}
                    >
                      Referrals
                      {upDownArrowReferrals ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "REFERRALS_COUNT_ASC");
                            setUpDownArrowReferrals(!upDownArrowReferrals);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "REFERRALS_COUNT");
                            setUpDownArrowReferrals(!upDownArrowReferrals);
                          }}
                        ></img>
                      )}
                    </p>
                  </th>

                  <th>
                    <p
                      className="py-4 m-0 d-flex fs-12"
                      style={{ gap: "0.6rem" }}
                    >
                      Pending Screening
                      {upDownArrowPendingScreeen ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "PENDING_SCREENING_ASC");
                            setUpDownArrowPendingScreeen(
                              !upDownArrowPendingScreeen
                            );
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "PENDING_SCREENING");
                            setUpDownArrowPendingScreeen(
                              !upDownArrowPendingScreeen
                            );
                          }}
                        ></img>
                      )}
                    </p>
                  </th>
                </tr>
              </thead>

              <tbody className="table_body overflow-scroll border-top border-light">
                {jobList && jobList?.length > 0
                  ? jobList?.map((item, key) => {
                      return (
                        <>
                          <tr className="card-parent position-relative border-bottom border-top border-0">
                            <td className="fs-14 fw-500 align-middle col-2">
                              {/* {item?.organizationLogo === null ? ( */}
                              {/* <img
                              src={profileImg}
                              alt="profile"
                              className="radius-4 me-2 my-4"
                              width="32px"
                              height="32px"
                            /> */}
                              {/* ) : ( */}
                              <img
                                src={`data:image/jpeg;base64 , ${item?.organizationLogo}`}
                                alt="Org"
                                className="radius-4 me-2 my-4"
                                width="32px"
                                height="32px"
                              />
                              {/* )} */}
                              {item?.organizationName}
                            </td>
                            <td
                              className=" align-baseline col-4"
                              style={{ color: "#767676" }}
                            >
                              <div className="p-0 m-0">
                                <p className="m-0 p-0 pt-3 fs-14 fw-500">
                                  {item?.jobTitle}
                                </p>
                                <p
                                  className="fs-11 fw-300 d-flex "
                                  style={{ color: "#808080" }}
                                >
                                  {item.jobStatus === "LIVE" ? (
                                    <>
                                      <span className="dot green"></span>{" "}
                                      {item.jobStatus}
                                    </>
                                  ) : item.jobStatus === "PAUSED" ? (
                                    <>
                                      <span className="dot yellow "></span>{" "}
                                      {item.jobStatus}
                                    </>
                                  ) : item.jobStatus === "FILLED" ? (
                                    <>
                                      <span className="dot gray "></span>{" "}
                                      {item.jobStatus}
                                    </>
                                  ) : item.jobStatus === "CLOSED" ? (
                                    <>
                                      <span className="dot gray "></span>{" "}
                                      {item.jobStatus}
                                    </>
                                  ) : (
                                    ""
                                  )}{" "}
                                  | {item.numberOfRole} Roles | posted |{" "}
                                  {item.createdDate}
                                </p>
                              </div>
                            </td>

                            <td
                              className="fs-14  align-baseline col-1"
                              style={{ color: "#767676" }}
                            >
                              <p className=" m-0 fs-14 fw-500 ">
                                {item?.views}{" "}
                              </p>
                            </td>
                            <td
                              className="fs-14  align-baseline col-1"
                              style={{ color: "#767676" }}
                            >
                              <p className=" m-0 fs-14 fw-500 ">
                                {item?.application}{" "}
                                <span
                                  className="fs-12 fw-400"
                                  style={{ color: "#ACACAC" }}
                                >
                                  Applications
                                </span>
                              </p>
                              {item.newApplicationCount > 0 ? (
                                <span
                                  className="fs-12 fw-600"
                                  style={{ color: "#EB1260" }}
                                >
                                  {item.newApplicationCount} New
                                </span>
                              ) : (
                                ""
                              )}
                            </td>
                            <td
                              className="fs-14  align-baseline col-1"
                              style={{ color: "#767676" }}
                            >
                              <p className=" m-0 fs-14 fw-500 ">
                                {item?.referral}{" "}
                                <span
                                  className="fs-12 fw-400"
                                  style={{ color: "#ACACAC" }}
                                >
                                  Referrals
                                </span>
                              </p>
                              {item.isNewReferral > 0 ? (
                                <span
                                  className="fs-12 fw-600"
                                  style={{ color: "#EB1260" }}
                                >
                                  {item.isNewReferral} New
                                </span>
                              ) : (
                                ""
                              )}
                            </td>

                            <td
                              className="align-baseline col-3 position-relative"
                              style={{ color: "#767676" }}
                            >
                              <div className="d-flex fs-14 fw-500 ">
                                {item?.pendingScreening}
                                <div className="onhover-show3 ">
                                  <div
                                    className="d-flex align-items-center  position-absolute h-32 gap-3 "
                                    style={{
                                      right: "10px",
                                    }}
                                  >
                                    <div
                                      className="pin-bg-admin-jobs d-flex justify-content-center align-items-center pointer jobs-btn-focus "
                                      onClick={() => {
                                        item?.isPinned
                                          ? pinOrUnpinJobs(item?.jobId, false)
                                          : pinOrUnpinJobs(item?.jobId, true);
                                        // fetchAllJobs(allJobFilterData);
                                      }}
                                    >
                                      <img
                                        style={{
                                          height: "14px",
                                          width: "10px",
                                        }}
                                        src={
                                          item?.isPinned
                                            ? pinFilled
                                            : pinUnfilled
                                        }

                                        // use "pinFilled" in src for filled icon
                                      />
                                    </div>

                                    {/* <div
                                      type="button"
                                      className={`fs-12 btn bg-black  align-middle px-3 text-white down-arrow  align-items-center `}
                                      style={{ minWidth: "100px" }}
                                      onClick={() => {
                                        editPreJobs("PreJob", item?.jobId);
                                      }}
                                    >
                                      Edit Job
                                    </div> */}
                                    <Link
                                      to={
                                        "/" +
                                        APPLICATIONS_MAIN_PAGE_ROUTE +
                                        `/${item?.jobId}`
                                      }
                                      type="button"
                                      className={`fs-12 btn bg-black  px-4 align-middle   text-white down-arrow  align-items-center gap-1 jobs-btn-focus `}
                                    >
                                      Manage
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : "No Data Found"}
              </tbody>
            </Table>
          </InfiniteScroll>
        )}
        <div
          className="d-sm-block d-md-none"
          style={{ marginBottom: "3.5rem" }}
        >
          <div className="d-flex flex-wrap">
            {jobList?.length > 0
              ? jobList?.map((item, key) => {
                  return (
                    <div className="col-12 py-3 position-relative">
                      <div className="admin-jobs-md-cards">
                        <div className="card-body text-decoration-none text-dark">
                          <div className="">
                            {" "}
                            <div className="d-flex gap-4">
                              <img
                                src={`data:image/jpeg;base64 , ${item?.organizationLogo}`}
                                alt="Org"
                                className="radius-4 me-2 my-4"
                                width="32px"
                                height="32px"
                              />
                              <p className="m-0 p-0  fs-14 fw-500 ">
                                {item?.organizationName}
                              </p>
                            </div>
                            <div className="p-0 m-0">
                              <p className="m-0 p-0  fs-14 fw-500 color-light-gray">
                                {item?.jobTitle}
                              </p>
                              <p
                                className="fs-11 fw-300 d-flex "
                                style={{ color: "#808080" }}
                              >
                                {item.jobStatus === "LIVE" ? (
                                  <>
                                    <span className="dot green"></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : item.jobStatus === "PAUSED" ? (
                                  <>
                                    <span className="dot yellow "></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : item.jobStatus === "FILLED" ? (
                                  <>
                                    <span className="dot gray "></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : item.jobStatus === "CLOSED" ? (
                                  <>
                                    <span className="dot gray "></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                | {item.jobView} | {item.jobPostingDate}
                              </p>
                            </div>
                          </div>

                          <div className="d-flex justify-content-between mt-2">
                            <p className="card-title fs-14 fw-400">Views</p>{" "}
                            <p className="fs-14 fw-500 color-light-gray">
                              {item?.views}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p className="card-title fs-14 fw-400">
                              Applications
                            </p>{" "}
                            <p className="">
                              <p className=" m-0 fs-14 fw-500 color-light-gray">
                                {item?.application}
                                <span className="fs-11 fw-400">
                                  {item.newApplicationCount > 0 ? (
                                    <span
                                      className="ps-2  fs-12 fw-600"
                                      style={{ color: "#EB1260" }}
                                    >
                                      {item.newApplicationCount} New
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </p>
                            </p>
                          </div>

                          <div className="d-flex justify-content-between">
                            <p className="card-title fs-14 fw-400">Referrals</p>{" "}
                            <p className="">
                              <p className=" m-0 fs-14 fw-500 color-light-gray">
                                {item?.referral}
                                <span className="fs-11 fw-400">
                                  {item.isNewReferral > 0 ? (
                                    <span
                                      className="ps-2  fs-12 fw-600"
                                      style={{ color: "#EB1260" }}
                                    >
                                      {item.isNewReferral} New
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </p>
                            </p>
                          </div>

                          <div className="d-flex justify-content-between pb-2 border-bottom">
                            <p className="card-title fs-14 fw-400">
                              Pending Screening
                            </p>{" "}
                            <p className="fs-14 fw-500 color-light-gray">
                              {item?.pendingScreening}
                            </p>
                          </div>

                          <div className="onhover-show-admin-jobs ">
                            <div
                              className="d-flex flex-column align-items-center  position-absolute h-32 gap-2 hover-bg w-100 p-2"
                              style={{
                                bottom: "75px",
                              }}
                            >
                              <div className="row-12 d-flex w-100">
                                <div
                                  className="pin-bg-admin-jobs d-flex justify-content-center align-items-center pointer me-2"
                                  onClick={() => {
                                    item?.isPinned
                                      ? pinOrUnpinJobs(item?.jobId, false)
                                      : pinOrUnpinJobs(item?.jobId, true);
                                    // fetchAllJobs(allJobFilterData);
                                  }}
                                >
                                  <img
                                    style={{ height: "14px", width: "10px" }}
                                    // src={pinFilled}
                                    src={
                                      item?.isPinned ? pinFilled : pinUnfilled
                                    }

                                    // use "pinFilled" in src for filled icon
                                  />
                                </div>

                                {/* <div
                                  type="button"
                                  className={`fs-12 btn bg-black  align-middle px-3 text-white down-arrow  align-items-center w-100`}
                                  style={{ minWidth: "100px" }}
                                
                                >
                                  Edit Job
                                </div> */}
                              </div>
                              <div
                                style={{ height: "36px" }}
                                className="row-12 w-100"
                              >
                                <Link
                                  to={
                                    "/" +
                                    APPLICATIONS_MAIN_PAGE_ROUTE +
                                    `/${item?.jobId}`
                                  }
                                  type="button"
                                  className={`fs-12 btn bg-black  px-4 align-middle   text-white down-arrow  align-items-center gap-1 w-100 md-cards-btn-width jobs-btn-focus`}
                                >
                                  Manage
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "No Data Found"}
          </div>
        </div>

        <SortByFilterModal
          dropdownSortHandler={dropdownSortHandler}
          arraySortIndex={arraySortIndex}
          setArraySortIndex={setArraySortIndex}
          dropdownSortArray={dropdownSortArray}
          show={showSortByFilter}
          close={() => setShowSortByFilter(false)}
        />

        <AllJobsFilterDialog
          show={showAllFilters}
          initialFilterData={initialFilterData}
          allJobFilterDataParams={allJobFilterDataParams}
          close={() => setShowAllFilters(false)}
          fetchAllJobs={fetchAllJobs}
          setAllJobFilterData={setAllJobFilterData}
          allJobFilterData={allJobFilterData}
        />
      </div>
      {/* <div
        style={{ backgroundColor: "#F4F7FB", left: "0px" }}
        className="position-fixed bottom-0 d-sm-block d-md-none w-100 pb-2 "
      >
        <Link
          className="btn down-arrow col-6 gap-2 btn-outline-none"
          to=""
          onClick={() => setShowSortByFilter(true)}
        >
          <img
            alt=""
            style={{
              borderRadius: "50%",
              height: "0.9rem",
              width: "0.9rem",
            }}
            src={SortBy}
          />
          <span className="fs-12 fw-400 ps-1">Sort By</span>
        </Link>
        <Link
          className="btn down-arrow col-5 gap-2 btn-outline-none border-start"
          to=""
          onClick={() => setShowAllFilters(true)}
        >
          <img
            alt=""
            style={{
              borderRadius: "50%",
              height: "0.9rem",
              width: "0.9rem",
            }}
            src={filterLogo}
          />
          <span className="fs-12 fw-400 ps-1">Filter</span>
        </Link>
      </div> */}
    </div>
  );
};

export default AdminAllJobsPage;
