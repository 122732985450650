import ApplyForJob from "./ApplyForJob";
import { useEffect, useState } from "react";
import ApplyForJobQuestions from "./ApplyForJobQuestions";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import disabled from "../assests/icons/ic_disabled.svg";
import close from "../assests/icons/ic-close-24.svg";
import toaster from "../utils/toaster";
import { applyingForJob } from "../_services/member-profile.service";

import { useStoreState, useStoreActions } from "easy-peasy";
import { getLocalStorage } from "../utils/storage";
import {
  SOMETHING_WENT_WRONG,
  STATUS_SUCCESS,
} from "./../config/be_api_constants";
import { USER_ID } from "../config/localstorage_keys";
import { STATUS_FAILED, SUCCESSFULLY_APPLIED_JOB } from "./../config/messages";

const ApplyForJobDialog = (props) => {
  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );

  const [isMandatory, setIsMandatory] = useState(true);
  const [isResumeSelected, setIsResumeSelected] = useState(false);
  const [isPageShow, setIsPageShow] = useState(true);
  const [newCandidateDetails, setNewCandidateDetails] = useState({});
  const [applyForJob, setApplyForJob] = useState({
    jobId: Number(props.id),
    referalId: "",
    resumeId: 0,
    jobAnswers: [],
    isMandatory: true,
    userProfileSnap: newCandidateDetails,
  });

  useEffect(() => {
    if (props?.refereeId) {
      setApplyForJob({ ...applyForJob, referalId: props?.refereeId });
    }
  }, [props?.refereeId]);

  useEffect(() => {
    const userId = getLocalStorage(USER_ID);
    saveCandidateDetails(userId);
  }, []);

  useEffect(() => {
    setNewCandidateDetails(candidateDetails);
  }, [candidateDetails]);

  useEffect(() => {
    setApplyForJob({
      ...applyForJob,
      userProfileSnap: newCandidateDetails,
    });
    // console.log("newCandidateDetails", newCandidateDetails);
  }, [newCandidateDetails]);

  const popupPageHandler = () => {
    if (isPageShow === true) {
      setIsPageShow(false);
    } else {
      if (!isMandatory) {
        applyingForJob(applyForJob)
          .then((res) => {
            if (res?.status === STATUS_FAILED) {
              toaster(
                "error",
                res?.message ? res?.message : SOMETHING_WENT_WRONG
              );
            } else if (res?.status === STATUS_SUCCESS) {
              toaster(
                "success",
                res?.message ? res?.message : SUCCESSFULLY_APPLIED_JOB
              );
              if (props.onJobSaved) {
                props.onJobSaved();
              }
              if (props?.getJobDetails) {
                props?.getJobDetails();
              }
            } else {
              toaster("error", SOMETHING_WENT_WRONG);
            }

            props.setShow(false);
          })
          .catch((err) => {
            console.log(err);
            toaster(
              "error",
              err?.message ? err?.message : SOMETHING_WENT_WRONG
            );
            props.setShow(false);
          });
      }
      setIsPageShow(true);
    }
  };
  return (
    <>
      {/* Modal component for apply-for-job */}
      <Modal
        fullscreen="lg-down"
        show={props.show}
        onHide={() => props.setShow(false)}
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        scrollable={true}
        className="lg-dialog-modal"
      >
        <Modal.Header className="m-1 justify-content-end border-0">
          <img
            onClick={() => props.setShow(false)}
            src={close}
            alt="close-icon"
            className="rounded-circle border-2 p-1 text-black pt-1 pb-1 forgot-round end-0 me-3"
          />
        </Modal.Header>
        <Modal.Body className="scrolling-dialog">
          {isPageShow ? (
            <ApplyForJob
              jobDetails={props.jobDetails}
              applyForJob={applyForJob}
              setApplyForJob={setApplyForJob}
              setIsMandatory={setIsMandatory}
              isResumeSelected={isResumeSelected}
              setIsResumeSelected={setIsResumeSelected}
              newCandidateDetails={newCandidateDetails}
              setNewCandidateDetails={setNewCandidateDetails}
            />
          ) : (
            <ApplyForJobQuestions
              jobDetails={props.jobDetails}
              applyForJob={applyForJob}
              setApplyForJob={setApplyForJob}
              setIsMandatory={setIsMandatory}
              isMandatory={isMandatory}
              isResumeSelected={isResumeSelected}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="modal-dialog-footer">
          <div className="d-flex  w-100">
            <div className="col-md-6 col-sm-4 col-3">
              <div className="d-flex gap-md-3 gap-sm-2 gap-1 align-items-center">
                <div className={isPageShow ? "order-first" : "order-last"}>
                  <button className="btn-primary rounded-pill ps-3 pe-3 mt-2 text-light">
                    {isPageShow ? "1/2" : "2/2"}
                  </button>
                </div>
                <div className="mt-2 ">
                  <img
                    src={disabled}
                    alt="disabled-page-btn"
                    onClick={popupPageHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="d-flex justify-content-end">
                {isPageShow ? (
                  ""
                ) : (
                  <button
                    className="btn text-decoration-underline mt-2 fw-700 fs-14 "
                    onClick={() => setIsPageShow(true)}
                  >
                    {" "}
                    Back{" "}
                  </button>
                )}
              </div>
            </div>

            <div className="col-md-4 col-sm-6 col-7">
              <Button
                className="btn-rounded btn-primary w-100"
                onClick={popupPageHandler}
                disabled={
                  !isPageShow && (isMandatory || !isResumeSelected)
                    ? true
                    : isPageShow && !isResumeSelected
                    ? true
                    : false
                }
              >
                {isPageShow ? " Continue " : " Submit Application "}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ApplyForJobDialog;
