import React from "react";
import icSkill from "../../../assests/icons/skill-and-experience.svg";
import NoDataFoundCard from "../../../components/common/no_data_found_card";
import { NO_SKILLS_EXPERIENCE_ADDED } from "../../../config/messages";

const ApplicationSkills = ({ applicationDetails }) => {
  const skills = [
    "Critical Thinking ",
    "User Interviews",
    "Usability Study",
    "Prototyping",
  ];
  const industries = [
    "Healthcare",
    "Information Technology",
    "Edtech",
    "Healthcare",
  ];

  const functions = ["Design", "Marketing"];
  return (
    <div className="mt-5">
      <div className="fw-bold fs-24">
        <img src={icSkill} className="me-2" alt="Skills-icon" />
        Skill and Experience
      </div>
      {applicationDetails?.viewProfileResponse?.skillsResponse?.industries ||
      (applicationDetails?.skillsResponse?.industries !== undefined &&
        applicationDetails?.viewProfileResponse?.skillsResponse?.functions) ||
      applicationDetails?.skillsResponse?.functions !== undefined ? (
        <div>
          <div className="shadow-box border-radius p-4 mt-3 ">
            <div>
              <div className="color-primary fs-16 fw-600"> Key Skills </div>
              <div className="d-flex gap-2 flex-wrap my-3">
                {(applicationDetails?.viewProfileResponse?.skillsResponse
                  ?.keySkills
                  ? applicationDetails?.viewProfileResponse?.skillsResponse
                      ?.keySkills
                  : applicationDetails?.skillsResponse?.keySkills
                )?.map((skill, index) => {
                  return (
                    <div className="pink-color-badge" key={index}>
                      {skill}
                    </div>
                  );
                })}
              </div>
              <hr></hr>
            </div>
            <div>
              <div className="color-primary fs-16 fw-600"> Industries </div>
              <div className="d-flex gap-2 flex-wrap my-3">
                {(applicationDetails?.viewProfileResponse?.skillsResponse
                  ?.industries
                  ? applicationDetails?.viewProfileResponse?.skillsResponse
                      ?.industries
                  : applicationDetails?.skillsResponse?.industries
                )?.map((industry, index) => {
                  return (
                    <div className="blue-color-badge" key={index}>
                      {industry}
                    </div>
                  );
                })}
              </div>
              <hr></hr>
            </div>
            <div>
              <div className="color-primary fs-16 fw-600"> Functions </div>
              <div className="d-flex gap-2 flex-wrap my-3">
                {(applicationDetails?.viewProfileResponse?.skillsResponse
                  ?.functions
                  ? applicationDetails?.viewProfileResponse?.skillsResponse
                      ?.functions
                  : applicationDetails?.skillsResponse?.functions
                )?.map((keyFunction, index) => {
                  return (
                    <div className="gray-color-badge" key={index}>
                      {keyFunction}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <NoDataFoundCard text={NO_SKILLS_EXPERIENCE_ADDED} />
      )}
    </div>
  );
};

export default ApplicationSkills;
