import React, { useEffect, useRef, useState } from "react";
import icRetract from "./../assests/icons/ic_retract.svg";
import icCloseBtnSmall from "./../assests/icons/ic_closebtnsmall.svg";
import icPinkDot from "./../assests/icons/ic_pink_dot.svg";
import Dropdown from "react-bootstrap/Dropdown";
import toaster from "../utils/toaster";
import {
  getClearNotifications,
  getReadNotifications,
} from "../_services/view.service";
import icDeleteIcon from "../assests/icons/ic_delete_gray.svg";

import { isEmpty } from "../utils/form_validators";
import Loader from "./common/loader";
import {
  GENERAL_ERROR_MESSAGE,
  NOTIFICATION_EMPTY_MESSAGE,
} from "../config/messages";
import { APPLICATIONS_MAIN_PAGE_ROUTE, APPLICATION_PAGE_ROUTE, REFEREE_SCREEN_ROUTE } from "../config/page_routes_constants";
import { useNavigate } from "react-router";
import { USER_ROLE } from "../config/be_api_constants";
import { getLocalStorage } from "../utils/storage";

const NotificationsDropdown = (props) => {

  const role = getLocalStorage("role")
  const navigate = useNavigate();
  const [notificationId, setNotificationId] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [notifications, setNotifications] = useState([props?.notification]);

  useEffect(() => {
    let notificationsId = props?.notification?.map((data, index) => {
      return data?.notificationId;
    });

    setNotificationId(notificationsId);
  }, [props]);

  const readNotificationHandler = (id) => {
    setShowLoader(true);
    getReadNotifications(id).then(
      (res) => {
        setShowLoader(true);
        if (!isEmpty(res) && res?.status === "SUCCESS") {
          setShowLoader(false);
        } else {
          setNotifications([]);
          setErrorMessage(res?.message);
          setShowLoader(false);
        }
        props.onNotificationRefreshNeeded();
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };

  const clearNotificationHandler = (id) => {
    setShowLoader(true);
    getClearNotifications(id).then(
      (res) => {
        setShowLoader(true);
        if (!isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
        } else {
          setNotifications([]);
          setErrorMessage(
            res?.data?.message ? res?.data?.message : GENERAL_ERROR_MESSAGE
          );
          setShowLoader(false);
        }
        props.onNotificationRefreshNeeded();
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };

  // useEffect(() => {
  //   toaster("success", "New Notification received", 3000)
  // }, [props?.notification?.length])


  // useEffect(() => {
  //   props.onNotificationRefreshNeeded();
  // }, [notifications]);

  const ref = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (props.show && ref.current && !ref.current.contains(e.target)) {
        props.onNotoficationDropdownClose();
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [props.show]);


  const redirectToPage = (type, additionalData) => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      if (type === "SYSADMIN_PAYMENT_DETAILS") {
        navigate(`/${APPLICATION_PAGE_ROUTE}/${additionalData?.id}`);
      }
    } else if (
      role === USER_ROLE.BUSINESS_ADMIN ||
      role === USER_ROLE.BUSINESS_USER
    ) {
      if (type == "VIEW_JOB_DETAILS_BUSINESS") {
        navigate(
          `/${APPLICATIONS_MAIN_PAGE_ROUTE}/${additionalData?.id}/#applications`
        );
      } else if (type == "APPLICANT_DROPOUT_BU") {
        navigate(
          `/${APPLICATION_PAGE_ROUTE}/${additionalData?.id}/#application/RETRACTED`
        );
      } else if (type == "VIEW_JOB_DETAILS_BUSINESS") {
        navigate(
          `/${APPLICATION_PAGE_ROUTE}/${additionalData?.id}`
        );
      } 

    }
  };

  return (
    <div>
      {showLoader && <Loader />}
      <div
        className={props.show ? "bg-white notification-box d-block" : "d-none"}
        ref={ref}
      >
        <div className="">
          <div className="d-flex justify-content-between p-3 pt-3">
            <div className="fw-600 fs-16 text-black">Notifications </div>
            <div className="d-flex gap-4">
            <Dropdown className="p-0 m-0 notification-dropdown" align="end">
                {props.notification?.length >= 0 ? (
                  <Dropdown.Toggle
                    variant="none"
                    id="dropdown-basic"
                    className="p-0 m-0 border-0 bg-transparent text-end"
                    style={{ width: "100px" }}
                  >
                    <img
                      src={icRetract}
                      alt="menu-icon"
                      className="sortbtn-background"
                    />
                  </Dropdown.Toggle>
                ) : (
                  <div style={{ width: "100px" }}></div>
                )}

                <Dropdown.Menu className="fs-12 text-secondary mt-2 notification-sort-dropdown ">
                  <Dropdown.Item
                    className="fs-12 fw-400 color-primary pe-5"
                    onClick={() => {
                      readNotificationHandler(notificationId);
                    }}
                  >
                    Mark all as read
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="fs-12 fw-400 dark-pink-text pe-5"
                    onClick={() => {
                      clearNotificationHandler([]);
                    }}
                  >
                    Clear all
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <div
                onClick={() => {
                  props.onNotoficationDropdownClose();
                }}
              >
                <img src={icCloseBtnSmall} alt="close-icon" />
              </div>
            </div>
          </div>
        </div>
        <hr className="m-0"></hr>
        {props.notification?.length === 0 ? (
          <div className="p-3 pt-4">
            {errorMessage ? errorMessage : NOTIFICATION_EMPTY_MESSAGE}
          </div>
        ) : (
          <div className="notification-height overflow-auto">
            {props.notification?.length > 0 &&
              props.notification?.map((notification, index) => {
                return (
                  <>
                    {notification?.notificationContent !== null ? (
                      <div
                        key={index}
                        className={
                          notification?.viewFlag === true
                            ? " notification-hide "
                            : ""
                        }
                      >
                        <div className="px-2 border-end py-4 d-flex justify-content-between">
                          <div
                            className="d-flex gap-3"
                            onClick={() => {
                              readNotificationHandler([
                                notification?.notificationId,
                              ]);
                              if (notification?.redirectType !== "")
                                redirectToPage(
                                  notification?.redirectType,
                                  notification?.relatedData
                                );
                            }}
                          >
                            <div className="text-center ps-2">
                              {notification?.viewFlag === true ? (
                                ""
                              ) : (
                                <img
                                  src={icPinkDot}
                                  alt="pinkdot-icon"
                                  height="6px"
                                />
                              )}
                            </div>
                            <div>
                              <div
                                className={
                                  notification?.viewFlag === true
                                    ? "inactive fs-12"
                                    : "small-text-dark-gray fw-400"
                                }
                              >
                                {notification?.notificationContent}
                              </div>
                              <div className="fs-12 small-text-medium-gray">
                                {" "}
                                {notification?.notificationDate}
                              </div>
                            </div>
                          </div>
                          <div className="pt-0 top-0 ps-2 pe-2">
                            <img
                              src={icDeleteIcon}
                              alt="delete-icon"
                              onClick={() => {
                                clearNotificationHandler([
                                  notification?.notificationId,
                                ]);
                              }}
                            />
                          </div>
                        </div>
                        <hr className="m-0"></hr>
                      </div>
                    ) : null}
                  </>
                );
              })}
          </div>
        )}

        <br />
      </div>
    </div>
  );
};

export default NotificationsDropdown;
