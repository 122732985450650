import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import addLogo from "../../../assests/icons/ic-add-filled.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import threeDotsIcon from "../../../assests/icons/three-dots-icon.svg";
import "../seikor_admins/styles.scss";
import SeikorAdminAddMemberDialog from "../../../dialogs/seikor_admins/seikor_admin_add_member_dialog";
import SeikorAdminAddSuccessDialog from "../../../dialogs/seikor_admins/seikor_admin_add_success_dialog";
import SeikorAdminManageDialog from "../../../dialogs/seikor_admins/seikor_admin_manage_dialog";
import { Link } from "react-router-dom";
import {
  deleteAdmin,
  getAllOrglist,
  getAllSerikorAdmin,
} from "../../../_services/serikoradmin_view.service";
import toaster from "../../../utils/toaster";

import { setISODay } from "date-fns";
import { DEFAULT_PAGE_SIZE } from "../../../config/config";
import { isEmpty } from "../../../utils/form_validators";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/common/loader";
import { SUCCESS } from "dropzone";
import { STATUS_SUCCESS } from "../../../config/localstorage_keys";

function SeikorAdminsScreen() {
  let isInfinite = false;

  const [addAdminMemberModalShow, setAddAdminMemberModalShow] = useState(false);
  const [AllOrg, setAllOrg] = useState();
  const [adminAddSuccess, setAdminAddSuccess] = useState(false);
  const [adminManageModalShow, setAdminManageModalShow] = useState(false);
  const [details, setDetails] = useState([]);
  const [id, setId] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [apiRefresh, setApiRefresh] = useState(false);
  const [alldata, setAllData] = useState({});
  const [allJobMessage, setAllJobMessage] = useState("");
  const [showemail, setShowEmail] = useState("");

  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    // searchString: null,
    // sortBy: null,
  };

  const [dataCountAdmins, setDataCountAdmins] = useState(0);
  const [currentAdminsPageset, setCurrentAdminsPageset] = useState(0);
  const [allAdmFilterData, setAllAdmFilterData] = useState(initialFilterData);
  const [eamil, setEmail] = useState();

  const fetchallDetails = (data) => {
    setShowLoader(true);
    getAllSerikorAdmin(data).then(
      (res) => {
        if (res && !isEmpty(res?.data?.data)) {
          setDetails(res?.data?.totalRecord);
          toaster("success", res?.data?.message);
          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...details, ...res?.data?.data];
          }
          setCurrentAdminsPageset(data?.length);
          setDetails(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          // setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setShowLoader(false);
        }
        // setShowLoader(false);
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };

  const fetchMoreAdmins = () => {
    setCurrentAdminsPageset(currentAdminsPageset + 10);
    isInfinite = true;

    fetchallDetails({
      ...allAdmFilterData,
      pageNo: allAdmFilterData?.pageNo + 1,
    });
    setAllAdmFilterData({
      ...allAdmFilterData,
      pageNo: allAdmFilterData?.pageNo + 1,
    });
  };

  useEffect(() => {
    if (apiRefresh === true) {
      fetchallDetails(initialFilterData);
      setApiRefresh(false);
    }
  }, [apiRefresh]);
  useEffect(() => {
    let temp = { ...initialFilterData };
    fetchallDetails(temp);
    setAllAdmFilterData(temp);
  }, []);

  const deleteData = (id) => {
    setShowLoader(true);

    deleteAdmin(id)
      .then((res) => {
        console.log(res?.data?.message, "success");
        // toaster("success", "Data Deleted successfully.");
        setApiRefresh(true);
        if (res?.status === 200) {
          fetchallDetails(initialFilterData);

          // setShowLoader(false);
          // setApiRefresh(false);
          toaster("success", res?.data?.message);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
      });
  };

  const AllOrgData = (id) => {
    if (id !== "" ) {
      getAllOrglist({ pageNo: 0, pageSize: 0, userId: id })
        .then((res) => {
          setShowLoader(false);
          setAllOrg(res?.data?.data);
        })
        .catch((err) => {
          setShowLoader(false);
          // props?.setApiRefresh(false);
          toaster("error", err);
        })
    }
    ;
  };

 useEffect(()=>{
console.log(alldata,"alldata");
 },[alldata])

  const onManage = (id, data) => {
    setAdminManageModalShow(true);
    setAllData(data);
    AllOrgData(id);
  };

  const onMember = (data) => {
    setAddAdminMemberModalShow(true);
    setAllData(data);
  };
  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin mt-4">
        <div className="d-block d-sm-flex justify-content-between mt-4">
          <div className="fw-700 fs-24">Seikor Admins</div>
          <div>
            <button
              onClick={() => {
                // setAddAdminMemberModalShow(true)
                // setAllData(details);
                onMember(details);
              }}
              className="new-job-template btn gap-2 rounded-pill fs-14 fw-700 border border-dark d-flex mt-2 referee-btn-focus"
            >
              <img alt="add member" src={addLogo} />
              <span>Add Member</span>
            </button>
          </div>
        </div>

        {/* Table */}
        <InfiniteScroll
          dataLength={currentAdminsPageset}
          next={() => fetchMoreAdmins()}
          hasMore={dataCountAdmins > currentAdminsPageset}
          className="container-fluid"
        >
          <div className="d-none d-md-block table-responsive mt-4">
            <table className="table ">
              <thead className="">
                <tr>
                  <th style={{ width: "250px" }}>
                    <div className="d-flex align-items-center gap-2">
                      <div className="fs-12 fw-700 ms-4">Member</div>
                    </div>
                  </th>
                  <th style={{ width: "200px" }}>
                    <div className="d-flex flex-column align-items-center">
                      <div className="w-100 fs-12 fw-700 text-left ">Role</div>
                    </div>
                  </th>
                  <th style={{ width: "auto" }}>
                    <div className="d-flex flex-column align-items-center">
                      <div className="w-100 fs-12 fw-700 text-left ">
                        Access
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {details && details?.length > 0
                  ? details.map((item, index) => {
                    return (
                      <>
                        <tr
                          key={index}
                          className="card-parent fs-14 position-relative"
                        >
                          <td scope="row">
                            <div className="d-flex gap-2 align-items-center">
                              <div>
                                {/* {item?.profilePic === null ? (
                                    <img
                                      src={profileImg}
                                      alt="profile-image"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  ) : ( */}
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_BASEURL +
                                    item?.profilePic
                                  }
                                  // alt="profile-image"
                                  className="rounded-circle"
                                  width="32px"
                                  height="32px"
                                />
                                {/* )} */}
                              </div>
                              <div className="fs-14 fw-400">{item?.name}</div>
                            </div>
                          </td>
                          <td className="align-middle">
                            <div>{item?.role}</div>
                          </td>
                          <td className="align-middle">
                            <div className="d-flex justify-content-between">
                              <div>
                                {item?.access === true
                                  ? "All Orgs"
                                  : item?.orgListCount}
                              </div>
                              <div className="position-absolute seikor-admin-action-card-position">
                                <div className=" action-card ">
                                  <button
                                    onClick={() => {
                                      // setAdminManageModalShow(true)
                                      // setAllData(item);

                                      // setId(item?.id);
                                      onManage(item?.id, item);
                                      console.log("item manage",item);
                                    }}
                                    className="text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark org-hover-btn referee-btn-focus"
                                  >
                                    Manage
                                  </button>
                                </div>
                              </div>
                              <div>
                                <Dropdown className="job-template-dropdown referee-btn-focus">
                                  <Dropdown.Toggle
                                    variant="none"
                                    id="dropdown-basic"
                                    className="d-flex p-0 m-0 border-0 bg-transparent job-template-icon referee-btn-focus"
                                  >
                                    <img
                                      src={threeDotsIcon}
                                      alt="retract-icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                                    <Dropdown.Item className="d-flex pb-2 pt-2">
                                      <div className="d-flex gap-2">
                                        <div className="d-flex gap-2">
                                          <span
                                            className=""
                                            onClick={() => {
                                              onManage(item?.id, item);
                                            }}
                                          >
                                            Manage Access
                                          </span>
                                        </div>
                                      </div>
                                    </Dropdown.Item>
                                    <hr className="m-0 p-0" />
                                    <Dropdown.Item className="d-flex pb-2 pt-2">
                                      <div className="d-flex gap-2">
                                        <span
                                          style={{ color: "#eb1260" }}
                                          onClick={() => deleteData(item?.id)}
                                        >
                                          Delete User
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                  : "No Data Found"}
              </tbody>
            </table>
          </div>

          {/* Small Table start */}
          <div className="d-block d-md-none px-3 mt-0">
            {details && details?.length > 0
              ? details?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="card-parent-bg-none d-flex row justify-content-center align-items-center my-4 border-bottom"
                  >
                    <div className="d-flex justify-content-between px-0">
                      <div>
                        <span>
                        <img
                                  src={
                                    process.env.REACT_APP_IMAGE_BASEURL +
                                    item?.profilePic
                                  }
                                  // alt="profile-image"
                                  className="rounded-circle"
                                  width="32px"
                                  height="32px"
                                />
                        </span>
                        <span className=" fs-14 fw-600">{item?.name}</span>
                      </div>
                      <div className="mt-2">
                        <Dropdown className="job-template-dropdown">
                          <Dropdown.Toggle
                            variant="none"
                            id="dropdown-basic"
                            className="d-flex p-0 m-0 border-0 bg-transparent job-template-icon"
                          >
                            <img src={threeDotsIcon} alt="retract-icon" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                            <Dropdown.Item className="d-flex pb-2 pt-2">
                              <div className="d-flex gap-2">
                                <div className="d-flex gap-2">
                                  <span className="" onClick={() => {
                                              onManage(item?.id, item);
                                            }}
                                            >Manage Access</span>
                                </div>
                              </div>
                            </Dropdown.Item>
                            <hr className="m-0 p-0" />
                            <Dropdown.Item className="d-flex pb-2 pt-2">
                              <div className="d-flex gap-2">
                                <span
                                  style={{ color: "#eb1260" }}
                                  onClick={() => deleteData(item?.id)}
                                >
                                  Delete User
                                </span>
                              </div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                    <div className="d-flex justify-content-start pt-3 pb-1 px-0">
                      <div className="fs-14 fw-400">{item?.role}</div>
                    </div>
                    <div className="d-flex justify-content-start px-0">
                      <div className="fs-14 fw-400">{item?.access}</div>
                    </div>
                    <div
                      className="mt-3 align-items-end p-0"
                      style={{ bottom: "15px" }}
                    >
                      <div className="gy-3 w-100 ">
                        <div className="onhover-show2 pb-4">
                          <Link
                            to={""}
                            onClick={() => setAdminManageModalShow(true)}
                            className="   w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                          >
                            Manage
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
        </InfiniteScroll>
        {/* Small Table end */}

        {/* Dialogs */}

        <SeikorAdminAddMemberDialog
          addAdminMemberModalShow={addAdminMemberModalShow}
          setAddAdminMemberModalShow={setAddAdminMemberModalShow}
          setAdminAddSuccess={setAdminAddSuccess}
          setApiRefresh={setApiRefresh}
          setShowEmail={setShowEmail}
        />
        <SeikorAdminAddSuccessDialog
          adminAddSuccess={adminAddSuccess}
          setAdminAddSuccess={setAdminAddSuccess}
          apiRefresh={apiRefresh}
          alldata={alldata}
          AllOrg={AllOrg}
          showemail={showemail}
        />
        <SeikorAdminManageDialog
          adminManageModalShow={adminManageModalShow}
          setAdminManageModalShow={setAdminManageModalShow}
          setApiRefresh={setApiRefresh}
          
          apiRefresh={apiRefresh}
          alldata={alldata}
          id={id}
          AllOrg={AllOrg}
        />
      </div>
    </div>
  );
}

export default SeikorAdminsScreen;
