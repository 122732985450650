import React from "react";

const JobCardOthers = ({jobViewDetails}) => {
  const others = [
    "Actively Looking for a change",
    "in Service Company",
    "On Notice Period",
  ];
  return (
    <div>
      <div className="mt-4">
        <div className="d-flex align-items-center">
          <div className="fs-14 fw-600 color-primary">Others</div>
        </div>

        {jobViewDetails.referralCriteriaOther && jobViewDetails.referralCriteriaOther?.length > 0 && (
          <div className="d-flex flex-wrap mt-2 gap-2">
            {jobViewDetails.referralCriteriaOther && jobViewDetails.referralCriteriaOther.map((other, index) => (
              <span className="gray-color-badge" key={index}>
                {other}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCardOthers;
