import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";

const PaymentRequest = (props) => {
  const [paymentMethod, setPaymentMethod] = useState("PAY PAL");
  return (
    <div>
      <Modal
        show={props.show}
        onHide={() => props.onRequestPaymentClose()}
        backdrop="static"
        // fullscreen="md-down"
        centered
        keyboard={false}
        className=""
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100">
            <div className="d-flex justify-content-end">
              <img
                src={icCloseRounded}
                alt="close-icon"
                onClick={() => props.onRequestPaymentClose()}
                className="pointer"
              />
            </div>
            <div className="fs-24 color-primary fw-700 ps-3">
              Payment Request
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body mt-0 ">
          <div className="p-3 pt-0">
            <div>
              <div className="fs-14 color-primary fw-400">Request Details</div>
              <div className="color-tertiary fs-11 fw-300">
                Request Date - 21 Sep 2022
              </div>
              {paymentMethod === "BHIM UPI" && (
                <div className="pt-3 medium-black-text d-flex justify-content-between">
                  <div>
                    <div className=" fs-12 fw-700">BHIM UPI</div>
                    <div className="fs-14 fw-400">8800278211@upi</div>
                  </div>
                  <button className="btn-copy-payment">Copy</button>
                </div>
              )}
              {paymentMethod === "GPAY VPA" && (
                <div className="pt-3 medium-black-text d-flex justify-content-between">
                  <div>
                    <div className=" fs-12 fw-700">GPAY VPA</div>
                    <div className="fs-14 fw-400">float.do@okicici</div>
                  </div>
                  <button className="btn-copy-payment">Copy</button>
                </div>
              )}
              {paymentMethod === "Bank Details" && (
                <div className="pt-3 medium-black-text">
                  <div className=" fs-12 fw-700">BANK DETAILS</div>
                  <div className="fs-14 fw-400">A/c No - 9883 8319 130</div>
                  <div className="fs-14 fw-400">IFSC - ICICI0000314</div>
                  <div className="fs-14 fw-400">
                    Account Holder - Roopnath Grandhi
                  </div>
                </div>
              )}
              {paymentMethod === "PAY PAL" && (
                <div className="pt-3 medium-black-text">
                  <div className=" fs-12 fw-700">PAY PAL</div>
                  <div className="fs-14 fw-400">A/c No - 9883 8319 130</div>
                  <div className="fs-14 fw-400">
                    Account Holder - Roopnath Grandhi
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start px-4">
          <div className="">
            <button
              className="btn-black-bg pointer border-0 fs-12 fw-700"
              onClick={() => {
                props?.onRequestPaymentClose();
                props?.onShowReferralBonus();
              }}
            >
              Mark as processed
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentRequest;
