import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import closeIcon from "../../assests/icons/ic-invite-pg-close.svg";
import profileImg from "../../assests/images/candidate-profile-pic.jpg";
import IcDoneWhite from "../../assests/icons/ic_done_white.svg";
import checkedLogo from "../../assests/ic_selected16.svg";
import toaster from "../../utils/toaster";
import {
  getAllOrg,
  getAllOrglist,
  saveAdminMember,
} from "../../_services/serikoradmin_view.service";
import { STATUS_SUCCESS } from "../../config/localstorage_keys";
import { isEmpty } from "../../utils/form_validators";
import { EMAIL_PATTERN } from "../../config/validation_patterns_constants";
import Loader from "../../components/common/loader";

function SeikorAdminManageDialog(props) {
  // const [AllOrg, setAllOrg] = useState();
  const [roleOutput, setRoleOutput] = useState([]);
  const [allOrgsAccess, setAllOrgsAccess] = useState();
  const [role, setRole] = useState();
  const [check, setchecked] = useState(false);
  const [userid, setUserid] = useState();
  const [listOrgs, setListOrgs] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const[changeRole,setChangedRole]=useState();
   console.log("props?.alldata?.access",props?.alldata?.access)
  const [roles, setRoles] = useState([
    {
      name: "Super Admin",
      description: "SUPER_ADMIN",
    },
    {
      name: "Admin",
      description: "SEIKOR_ADMIN",
    },
  ]);

  useEffect(() => {
    setUserid(props?.alldata?.id);
    setRole(props?.alldata?.role);
    setEmail(props?.alldata?.name);
    setchecked(props?.alldata?.access);


    if (props.AllOrg && props.AllOrg.length > 0) {
      // Extract the IDs of selected organizations from the new data (props)
      const selectedOrgIds = props.AllOrg.filter((org) => org.flag).map((org) => org.id);
      setListOrgs(selectedOrgIds);
    }
  }, [props,props.AllOrg]);

  const [email, setEmail] = useState();

  const onSave = () => {
    saveAdminMember(email, role, check, [...listOrgs])
      .then((res) => {
        // setShowLoader(true);
        props?.setApiRefresh(true);
        props?.setAdminManageModalShow(false);
        if (res?.status === 200) {
          setShowLoader(false);
          // props?.setApiRefresh(false);

          toaster(
            "success",
            res?.data?.message ? res?.data?.message : STATUS_SUCCESS
          );
          window?.location?.reload();
        }
      })
      .catch((err) => {
        setShowLoader(false);
        props?.setApiRefresh(false);

        toaster("error", err);
      });
  };
  const AllOrgData = () => {
    if (props?.adminManageModalShow === true) {
      getAllOrg({ pageNo: 0, pageSize: 0 })
        .then((res) => {
          setShowLoader(false);
          // setAllOrg(res?.data?.data);
        })
        .catch((err) => {
          setShowLoader(false);
          toaster("error", err);
        });
    }

  };
  useEffect(() => {
    AllOrgData();
  }, [props?.adminManageModalShow]);


  return (
    <>
      {showLoader && <Loader />}
    <Modal
      show={props?.adminManageModalShow}
      onHide={() => {
        props?.setAdminManageModalShow(false);
      }}
      fullscreen="md-down"
      className="p-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable={true}
    >
      <Modal.Header className="dialog-header border-0 px-4">
        <Modal.Title className="dialog-title pt-3  fw-700 fs-20">
          Manage User
        </Modal.Title>
        <img
          className="pointer m-0 p-0"
          onClick={() => {
            props?.setAdminManageModalShow(false);
          }}
          height="30px"
          width="30px"
          alt=""
          src={closeIcon}
        />
      </Modal.Header>
      <Modal.Body className=" py-3 m-0 px-4">
        <div className="m-0 p-0 overflow-auto" style={{ maxHeight: "450px" }}>
          <div className="d-flex">
            {props?.alldata?.profilePic === null ? (
              <img
                src={profileImg}
                alt="profile-image"
                className="rounded-circle"
                width="32px"
                height="32px"
              />
            ) : (
              <img
                src={
                  process.env.REACT_APP_IMAGE_BASEURL +
                  props?.alldata?.profilePic
                }
                alt="profile-image"
                className="rounded-circle"
                width="32px"
                height="32px"
              />
            )}
            <div className="d-flex align-items-center small-text-gray fs-14 fw-400 ms-3">
              {props?.alldata?.name}
            </div>
          </div>
          </div>
          <div className="d-flex mt-3">
            {/* <div className="fw-600 fs-14 me-3 d-flex align-items-center">
              Role
            </div> */}
            {/* <div className="flex-wrap gap-2 d-flex h-28 ">
              {roles?.map((output, index) => {
                 return ( 
                  <div
                    className="fs-12   h-28 d-flex align-items-center px-2 gap-1 border  rounded-pill pointer"
                    key={index}
                    onClick={() => {
                      if (output.description === "SUPER_ADMIN") {
                        // setChangedRole(output?.name)
                        setRole(`${output.description}`);
                        setchecked(true);
                        setAllOrgsAccess(true);
                      } else {
                        setRole(`${output.description}`);
                        // setChangedRole(output?.name)
                        setchecked(false);
                        setAllOrgsAccess(false);
                      }
                    }}
                    
                    // onClick={() => {
                    //   let tempRoleOutput = [...roleOutput];
                    //   if (tempRoleOutput.includes(output.name)) {
                    //     let itemIndex = roleOutput?.findIndex(
                    //       (tm) => tm === output.name
                    //     );
                    //     tempRoleOutput.splice(itemIndex, 1);
                    //   } else {
                    //     tempRoleOutput.push(output.name);
                    //   }
                    //   // setRole(tempRoleOutput);
                    //   setRoleOutput(tempRoleOutput)
                    // }}
                  >
                    
                    {props?.alldata?.role === output?.description? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      ></img>
                    ) : (
                      ""
                    )}

                    <div>{output.name} </div>
                  </div>
             );
              })} 
            </div> */}
          </div>

          <div className="d-flex mt-3">
            <div className="fw-600 fs-14 me-3 d-flex align-items-center">
              Role
            </div>
            <div className="d-flex justify-content-center align-items-center me-3 admin-btns-border px-2 py-1 pointer">
              {role === "SUPER_ADMIN" ? (
                <span
                  style={{ height: "18px" }}
                  className="py-0 bg-black px-1 rounded-circle me-1"
                >
                  <img className="mb-1" src={IcDoneWhite} />
                </span>
              ) : (
                ""
              )}

              <span
                className="fs-12"
                onClick={() => {
                  setRole("SUPER_ADMIN");
                   setchecked(true);
                  setAllOrgsAccess(true);
                }}
              >
                Super Admin
              </span>
            </div>
            <div className="d-flex justify-content-center align-items-center me-3 admin-btns-border px-2 py-1 pointer">
              {role === "SEIKOR_ADMIN"   ? (
                <span
                  style={{ height: "18px" }}
                  className="py-0 px-1 bg-black rounded-circle me-1"
                >
                  <img className="mb-1" src={IcDoneWhite} />
                </span>
              ) : (
                ""
              )}
              <span
                className="fs-12"
                onClick={() => {
                  setRole("SEIKOR_ADMIN");
                  setchecked(false);
                  setAllOrgsAccess(false);
                  // setAllOrgsAccess(allOrgsAccess);
                }}
              >
                Admin
              </span>
              
            </div>
          </div>
          <div className="d-flex justify-content-between seikor-admin-access-bg mt-3 py-3">
            <div className="fs-14 fw-600 ms-3">Access to all orgs?</div>
            <div className="">
              <span className="form-check form-switch">
                <div className="">
                  <input
                    type="checkbox"
                    className="form-check-input switch-btn-small pointer"
                    checked={
                      check 
                      // ===
                      //  props?.alldata?.access ?  false : true
                    }
                    onChange={(e) => setAllOrgsAccess(e.target.checked)}
                   
                    // defaultChecked={
                    //    allOrgsAccess === props?.alldata?.access ? false : true
                    // }
                  />
                </div>
              </span>
            </div>
          </div>
          {/* <ul
                className="m-0 p-0 overflow-auto"
                style={{ maxHeight: "300px" }}
              >
          {props?.AllOrg &&
            props?.AllOrg.map((output, index) => {
              return (
                <div
                  className="d-flex justify-content-between mt-3"
                  key={index}
                >
                  <div className="fw-500 fs-14">{output?.name}</div>
                  <div>
                    <input
                      type="checkbox"
                      className="me-2 pointer"
                      onChange={() => {
                        if (!isEmpty(listOrgs)) {
                          const orgMember = [...listOrgs];
                          if (orgMember.includes(output.id)) {
                            let itemIndex = listOrgs?.findIndex(
                              (im) => im === output.id
                            );
                            orgMember.splice(itemIndex, 1);
                          } else {
                            orgMember.push(output.id);
                          }
                          setListOrgs(orgMember);
                        } else {
                          setListOrgs([output.id]);
                        }
                      }}
                     
                      defaultChecked={
                        output.flag ? true : false
                      }
                      // disabled={
                      //   props?.alldata?.role==="SUPER_ADMIN" && role==="SUPER_ADMIN"
                      //                     ? true
                      //                     : false
                      // }
                      // checked={
                      //   output?.flag===true && role==="SUPER_ADMIN"
                      //               ? true
                      //               : false
                      //             }
                      disabled={role === "SUPER_ADMIN" ? true : false}
                    />
                  </div>
                </div>
              );
            })}
            </ul> */}




<ul className="m-0 p-0 overflow-auto" style={{ maxHeight: "300px" }}>
      {props?.AllOrg &&
        props?.AllOrg.map((output, index) => {
          return (
            <div className="d-flex justify-content-between mt-3" key={index}>
              <div className="fw-500 fs-14">{output?.name}</div>
              <div>
                <input
                  type="checkbox"
                  className="me-2 pointer"
                  onChange={() => {
                    if (listOrgs.includes(output.id)) {
                      setListOrgs((prevOrgs) => prevOrgs.filter((id) => id !== output.id));
                    } else {
                      setListOrgs((prevOrgs) => [...prevOrgs, output.id]);
                    }
                  }}
                  defaultChecked={output.flag ? true : false}
                  disabled={role === "SUPER_ADMIN"}
                />
              </div>
            </div>
          );
        })}
    </ul>
          {/* <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Amazon</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Microsoft</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Uber</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Cred</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
        </div> */}
      </Modal.Body>
      <Modal.Footer
        style={{ background: "#f4f7fb" }}
        className="username-buttons pt-2 px-4 pb-3"
      >
        <Button
          // onClick={() => {
          //   
          // }}
          onClick={() => {
            onSave();
            // props?.setAdminManageModalShow(false);
          }}
          className="btn btn-dark btn-save px-4"
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}

export default SeikorAdminManageDialog;
