import React, { useRef, useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import SortBy from "../../../assests/ic_sort.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";

// import "./job-post-style.scss";
import { Link, useSearchParams } from "react-router-dom";
import filterLogo from "../../../assests/filterLogo.svg";
import tick from "../../../assests/icons/ic-combo-tick.svg";
import { checkedItemsHandler } from "../../../utils/utils";
import AllUsersSortByModal from "../../../dialogs/all_users_dialogs/sort_all_users_filter";
import CheckBoxFilterModal from "../../../dialogs/checkbox_filter_dialog";
import { INDIVIDUAL_PROFILE_PAGE } from "../../../config/page_routes_constants";
import useWindowDimensions from "../../../utils/use_window_dimension";
import { getAllUsers } from "../../../_services/serikoradmin_view.service";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import { GENERAL_ERROR_MESSAGE } from "../../../config/messages";
import toaster from "../../../utils/toaster";
import { isEmpty } from "../../../utils/form_validators";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/common/loader";

const AllUsersPage = () => {
  const [arraySortIndex, setArraySortIndex] = useState(0);
  const [arrayAllStatusIndex, setArrayAllStatusIndex] = useState([]);
  const [showSortByFilter, setShowSortByFilter] = useState(false);
  const [showAllFilters, setShowAllFilters] = useState(false);

  const dropdownSortArray = [
    { name: "Most Applications", value: "MOST_APPLICATION" },
    { name: "Most Referrals", value: "MOST_REFERRAL" },
    { name: "Notes", value: "NOTES" },
    { name: "Saved Jobs-Applications", value: "SAVED_JOBS_APPLICATIONS" },
    { name: "Saved Jobs-Referrals", value: "SAVED_JOBS_REFERRALS" },
    { name: "Last Active", value: "LAST_ACTIVE" },
  ];
  const [analyticsFiltersDropdownRedDot, setAnalyticsFiltersDropdownRedDot] =
    useState("d-none");

  const [analyticsSortByDropdownRedDot, setAnalyticsSortByDropdownRedDot] =
    useState("d-none");

  useEffect(() => {
    if (arraySortIndex == null) {
      setAnalyticsSortByDropdownRedDot("d-none");
    } else {
      setAnalyticsSortByDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  useEffect(() => {
    if (arraySortIndex == null) {
      setAnalyticsFiltersDropdownRedDot("d-none");
    } else {
      setAnalyticsFiltersDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  const dropdownFiltersArray = [
    { name: "With received referrals", value: "REFERRALS" },
    { name: "Have made applications", value: "APPLICATIONS" },
    { name: "Have employer notes", value: "NOTES" },
  ];

  const dropdownSortHandler = (index, value) => {
    setArraySortIndex(index);
    setAllUsersData({
      ...allUsersData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    fetchAllUsers({
      ...allUsersData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };
  const [filterArray, setFilterArray] = useState([]);

  const dropdownAllStatusHandler = (condition, index, value) => {
    checkedItemsHandler(index, arrayAllStatusIndex, setArrayAllStatusIndex);
    setFilterArray([...filterArray, value]);
    if (condition) {
      setFilterArray([...filterArray, value]);
    } else {
      let index = filterArray.indexOf(value);
      if (index >= 0) {
        setFilterArray([
          ...filterArray.splice(0, index),
          ...filterArray.splice(index + 1),
        ]);
      }
    }
  };

  const checkFilterHandler = (condition, value) => {
    if (condition) {
      setFilterArray([...filterArray, value]);
    } else {
      let index = filterArray.indexOf(value);
      if (index >= 0) {
        setFilterArray([
          ...filterArray.splice(0, index),
          ...filterArray.splice(index + 1),
        ]);
      }
    }
  };

  const checkAllTeamsHandler = (condition, value) => {
    console.log("val[erwkpdjgs", value, condition);
    if (condition) {
      setFilterArray([...filterArray, value]);
    } else {
      let index = filterArray.indexOf(value);
      if (index >= 0) {
        setFilterArray([
          ...filterArray.splice(0, index),
          ...filterArray.splice(index + 1),
        ]);
      }
    }
  };

  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    // console.log("winWidth", winWidth);
    if (winWidth > 1500) {
      return 955 + "px";
    } else if (winWidth > 1300 && winWidth > 1499) {
      return winWidth - 420 + "px";
    } else if (winWidth < 1300 && winWidth > 991) {
      return winWidth - 400 + "px";
    } else if (winWidth < 990 && winWidth > 768) {
      return winWidth - 80 + "px";
    } else if (winWidth < 767 && winWidth > 551) {
      return winWidth - 110 + "px";
    } else if (winWidth < 550) {
      return winWidth - 110 + "px";
    }
  };
  let isInfinite = false;

  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const [showLoader, setShowLoader] = useState(false);
  const [allStatus, setAllStatus] = useState([]);

  const [allUsers, setAllUsers] = useState([]);
  const [allUsersData, setAllUsersData] = useState(initialFilterData);
  const [currentPageset, setCurrentPageSet] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [allUserDataParams, setAllUserDataParams] = useSearchParams({
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const fetchAllUsers = (data) => {
    setShowLoader(true);
    getAllUsers(data).then(
      (res) => {
        // setAllUsers(res?.data?.data);
        toaster("success", "Fetched All Users jobs Successfully");

        if (res && !isEmpty(res?.data?.data)) {
          setDataCount(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...allUsers, ...res?.data?.data];
          }
          setCurrentPageSet(data?.length);
          setAllUsers(data);
          console.log("data", data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          // setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          // setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        // setShowLoader(false);
      }
    );
  };

  const fetchMoreUsers = () => {
    setCurrentPageSet(currentPageset + 10);
    isInfinite = true;
    fetchAllUsers({
      ...allUsersData,
      pageNo: allUsersData?.pageNo + 1,
    });
    setAllUsersData({
      ...allUsersData,
      pageNo: allUsersData?.pageNo + 1,
    });
  };
  const submitFormOnEnter = (e) => {
    console.log("search", e);
    if (e?.keyCode == 13) {
      setAllUserDataParams(allUsersData);
      fetchAllUsers(allUsersData);
    }
  };

  useEffect(() => {
    // setcurrentPageset(allJobFilterData?.pageNo);
    // fetchMoreData();
    let temp = { ...initialFilterData };
    for (let entry of allUserDataParams.entries()) {
      if (entry[0] === "searchString") {
        temp = {
          ...temp,
          searchString: entry[1],
        };
      }
    }
    setAllUsersData(temp);
    fetchAllUsers(temp);
  }, [filterArray, arrayAllStatusIndex]);

  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin mt-4">
        <div className=" mt-0 mb-0 justify-content-between align-items-center col-lg-12 d-sm-grid d-md-flex">
          <p className="fs-24 fw-700 col-sm-12 col-md-6 col-lg-4 mb-2 ">
            All Users ({dataCount})
          </p>
        </div>
        <div className="mt-3 justify-content-between col-lg-12 d-md-flex gap-2 flex-wrap">
          <div
            className="flex-wrap gap-2 d-flex mb-3 mb-md-0 col-12 col-md-6"
            style={{ height: "30px" }}
          >
            <Dropdown className="filter-button h-28">
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button h-28 user-btn-focus"
              >
                <img src={SortBy} alt="" />
                <p className="fs-12 fw-400 pt-3 ps-1">Sort By</p>
                <span
                  className={`${analyticsSortByDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                ></span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary">
                {dropdownSortArray.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex pb-3"
                      onClick={() => {
                        dropdownSortHandler(index, sortItem.value);
                      }}
                    >
                      <img
                        src={tick}
                        alt="blackdone-icon"
                        className={
                          arraySortIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          arraySortIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="filter-button h-28" autoClose="outside">
              <Dropdown.Toggle
                variant="none"
                id="dropdown-autoclose-false"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button h-28 user-btn-focus"
              >
                <img src={filterLogo} alt="" />
                <p className="fs-12 fw-400 pt-3 ps-1">Filters</p>
                {/* <span
                      className={`${analyticsFiltersDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                    ></span> */}
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary ">
                {dropdownFiltersArray.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex pb-3"
                      onClick={(e) => {
                        dropdownAllStatusHandler(
                          e?.target?.checked,
                          index,
                          sortItem.value
                        );
                      }}
                    >
                      <input
                        type="checkbox"
                        className="me-2"
                        checked={
                          arrayAllStatusIndex.includes(index) ? true : false
                        }
                        onChange={(e) => {
                          checkFilterHandler(
                            e?.target?.checked,
                            sortItem.value
                          );
                        }}
                      />{" "}
                      <label>{sortItem.name}</label>
                    </Dropdown.Item>
                  );
                })}
                <div clasname="mx-4">
                  <button
                    className="btn gap-2 rounded fs-14 fw-700 border border-dark mx-2 w-90 user-btn-focus"
                    onClick={() => {
                      fetchAllUsers({
                        ...allUsersData,
                        userFilter: filterArray,
                        pageNo: 1,
                        pageSize: DEFAULT_PAGE_SIZE,
                      });
                    }}
                  >
                    <span className="fs-12 fw-700 m-2">Apply</span>
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="page-search h-32 col-12 col-md-5 col-lg-4">
            <input
              className=" h-32 col-12 user-btn-focus"
              type="text"
              placeholder="Search"
              defaultValue={allUsersData?.searchString}
              onChange={(event) => {
                setAllUsersData({
                  ...allUsersData,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
            />
          </div>
        </div>
        <div
          style={{
            width: getHorizotalScrollWidth(hrSrlDiamension.width),
          }}
        >
          <InfiniteScroll
            dataLength={currentPageset}
            next={() => fetchMoreUsers()}
            hasMore={dataCount > currentPageset}
            className="container-fluid user-btn-focus"
          >
            <Table
              className="mt-4 justify-content-between col-lg-12 d-none d-lg-block user-btn-focus"
              responsive
            >
              <thead>
                <tr>
                  <th className="fs-11 fw-600 min-width-60">
                    <p>User</p>
                  </th>
                  <th>
                    <p className="fs-11 fw-600 min-width-60">Applications</p>
                  </th>
                  <th>
                    <p className="fs-11 fw-600  min-width-60">Referrals</p>
                  </th>
                  <th>
                    <p className="fs-11 fw-600  min-width-60">
                      Profile Complete
                    </p>
                  </th>
                  <th>
                    <p className="fs-11 fw-600  min-width-70">Last Active</p>
                  </th>
                  <th>
                    <p className="fs-11 fw-600  min-width-100">
                      Saved jobs - Applications
                    </p>
                  </th>
                  <th>
                    <p className="fs-11 fw-600  min-width-100">
                      Saved jobs - Referrals
                    </p>
                  </th>
                  <th>
                    <p className="fs-11 fw-600  min-width-100">
                      Notes from Employer
                    </p>
                  </th>
                </tr>
              </thead>
              <tbody className="table_body overflow-scroll">
                {allUsers
                  ? allUsers?.map((item, key) => {
                      return (
                        <>
                          <tr className="card-parent col-12 position-relative">
                            <td className="py-3 col-4">
                              <div className="d-flex align-items-center">
                                {/* {item?.logo ===null  
                                ?<img
                                src={profileImg}

                                alt="profile"
                                className="rounded-circle user-btn-focus"
                                width="36px"
                                height="36px"
                              /> */}
                                {/* : */}
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_BASEURL +
                                    item?.logo
                                  }
                                  alt="profile"
                                  className="rounded-circle user-btn-focus"
                                  width="36px"
                                  height="36px"
                                />
                                {/* } */}
                                <div className="mx-2 all-users-table-turncate-width">
                                  <div className="fs-12 fw-400 header-ellipse-1">
                                    {item?.name}
                                  </div>
                                  <div className="small-text-gray fs-12 fw-300 header-ellipse-1">
                                    {item?.currentDesignation}
                                  </div>
                                  <div className="small-text-gray fs-12 fw-300 header-ellipse-1">
                                    {item?.currentCompany}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="py-3 fs-12 fw-400 align-middle">
                              <p className="m-0">{item?.application}</p>
                            </td>
                            <td className="py-3 fs-12 fw-400 align-middle">
                              <p className="m-0">{item?.referral}</p>
                            </td>
                            <td className="py-3 fs-12 fw-400 align-middle">
                              <p className="m-0">{item?.profileCompleted} %</p>
                            </td>
                            <td className="py-3 fs-12 fw-400 align-middle">
                              <p className="m-0">{item?.lastActive}</p>
                            </td>
                            <td className="py-3 fs-12 fw-400 align-middle">
                              <p className="m-0">{item?.saveJobApplication}</p>
                            </td>
                            <td className="py-3 fs-12 fw-400 align-middle">
                              <p className="m-0">{item?.saveJobReferral}</p>
                            </td>
                            <td className="py-3 fs-12 fw-400 align-middle position-relative">
                              <p className="m-0">
                                {item?.notFromEmployee} notes
                              </p>
                              <div className="onhover-show3">
                                <div className=" link ms-2 align-items-center">
                                  <Link
                                    to={
                                      "/" +
                                      INDIVIDUAL_PROFILE_PAGE +
                                      `/${item?.userId}`
                                    }
                                    className=" text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                                  >
                                    View Profile
                                  </Link>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  : "No Data Found"}
              </tbody>
            </Table>
          </InfiniteScroll>
        </div>

        <div className="d-sm-block d-md-block d-lg-none ">
          <div className=" justify-content-between gap-2 my-4">
            {dataCount > 0
              ? allUsers?.map((item, key) => {
                  return (
                    <div className="card-parent-bg-none  col pt-4 border-bottom">
                      <div className="d-flex gap-2 align-items-center text-start ">
                        <img
                          src={process.env.REACT_APP_IMAGE_BASEURL + item?.logo}
                          alt="profile"
                          className="rounded-circle user-btn-focus"
                          width="36px"
                          height="36px"
                        />
                        <div className="all-users-small-turncate-width">
                          <div
                            className="fs-12 fw-400  header-ellipse-1"
                            style={{ color: "#262626" }}
                          >
                            {item?.name}
                          </div>
                          <div className="small-text-gray fs-12 fw-300  header-ellipse-1">
                            {item?.currentDesignation}
                          </div>
                          <div className="small-text-gray fs-12 fw-300  header-ellipse-1">
                            {item?.currentCompany}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-3">
                        <p className=" fs-14 fw-400">Applications</p>{" "}
                        <p className="fs-14 fw-500 color-light-gray">
                          {item?.application}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p className=" fs-14 fw-400">Referrals</p>{" "}
                        <p className="fs-14 fw-500 color-light-gray">
                          {item?.referrals}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between">
                        <p className=" fs-14 fw-400">Last Active</p>{" "}
                        <p className="fs-14 fw-500 color-light-gray">
                          {item?.lastActive}
                        </p>
                      </div>

                      <div className="d-flex justify-content-between">
                        <p className=" fs-14 fw-400">Notes from Employer</p>{" "}
                        <p className="fs-14 fw-500 color-light-gray">
                          {item?.notesFromEmployer} notes
                        </p>
                      </div>
                      <div
                        className=" align-items-end mx-0 px-0 "
                        style={{ bottom: "15px" }}
                      >
                        <div className="gy-3 w-100 ">
                          <div className="onhover-show2 pb-4">
                            <Link
                              to={
                                "/" +
                                INDIVIDUAL_PROFILE_PAGE +
                                `/${item?.jobId}`
                              }
                              className="   w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                            >
                              View Profile
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : "No Data Found"}
          </div>
        </div>

        <AllUsersSortByModal
          dropdownSortHandler={dropdownSortHandler}
          arraySortIndex={arraySortIndex}
          setArraySortIndex={setArraySortIndex}
          dropdownSortArray={dropdownSortArray}
          show={showSortByFilter}
          close={() => setShowSortByFilter(false)}
        />

        <CheckBoxFilterModal
          Array={dropdownFiltersArray}
          show={showAllFilters}
          close={() => setShowAllFilters(false)}
          setArray={setArrayAllStatusIndex}
        />
      </div>
      {/* <div
        style={{ backgroundColor: "#F4F7FB", left: "0px" }}
        className="position-fixed bottom-0 d-sm-block d-md-none w-100 pb-2 "
      >
        <Link
          className="btn down-arrow col-6 gap-2 btn-outline-none"
          to=""
          onClick={() => setShowSortByFilter(true)}
        >
          <img
            alt=""
            style={{
              borderRadius: "50%",
              height: "0.9rem",
              width: "0.9rem",
            }}
            src={SortBy}
          />
          <span className="fs-12 fw-400 ps-1">Sort By</span>
        </Link>
        <Link
          className="btn down-arrow col-5 gap-2 btn-outline-none border-start"
          to=""
          onClick={() => setShowAllFilters(true)}
        >
          <img
            alt=""
            style={{
              borderRadius: "50%",
              height: "0.9rem",
              width: "0.9rem",
            }}
            src={filterLogo}
          />
          <span className="fs-12 fw-400 ps-1">Filter</span>
        </Link>
      </div> */}
    </div>
  );
};

export default AllUsersPage;
