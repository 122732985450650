import {
  ADDMASTER_ENDPOINT,
  ALL_ORGS_PIN_UNPIN,
  DATA_APPROVE_ENDPOINT,
  DELETE__DATA_FIELDS,
  GET_ALL_JOB_POST,
  GET_ALL_USERS,
  GET_DATA_FIELDS_APPROVED,
  GET_MASTER_DETAILS_DATA_FIELDS,
  OVERVIEW_MORE_INSIGHTS,
  OVERVIEW_ORG_SUMMARY,
  OVERVIEW_ORG_TOP_REFEREES,
  JOB_PROMOTION_ENDPOINT,
  OVERVIEW_UNPIN_JOBS,
  PINNED_JOBS,
  PINNED_ORGS,
  SEIKOR_ADMIN_ALLDATA_ENDPOINT,
  SEIKOR_ADMIN_APPROVAL_ENDPOINT,
  VIEW_CANDIDATE_PROFILE,
  ADD_DATA_FIELDS_ENDPOINT,
  NEW_REQ_GET_FEEDBACK,
  NEW_REQ_DELETE_FEEDBACK,
  DELETE_ADMIN_ENDPOINT,
  SAVEMEMBER_ENDPOINT,
  ALl_ORGANIZATION,
  GET_APPROVE_ENDPOINT,
  ALl_ORGANIZATION_LIST_ENDPOINT,
  DELETE_APPROVE,
  JOB_POSTS_PIN_UNPIN,
  FETCH_MESSAGES,
  SEND_MESSAGES_TO_RECRUITER,
  GET_MASTER,
  GET_REPORT,
  GET_JOB_TAG_MASTER,
  ADD_JOB_TAG_TITLE,
  NOTIFY_FOR_RECOMMENDATION,
  GET_JOB_PROMOTION_ENDPOINT,
  JOB_DELETE_PROMOTINAL_ENDPOINT,
} from "../config/be_seikorAdmin_api_constants";
import api from "./api";

const getApporvedFields = (pageNo, pageSize) => {
  return api
    .post(GET_DATA_FIELDS_APPROVED, {
      pageNo,
      pageSize,
    })
    .then((response) => {
      if (
        response?.status === 200 &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        return response?.data?.data;
      } else {
        return [];
      }
    });
};
const getMasterDetails_DataFields = (
  pageNo,
  pageSize,
  masterType,
  searchString
) => {
  return api
    .post(GET_MASTER_DETAILS_DATA_FIELDS, {
      pageNo,
      pageSize,
      masterType,
      searchString,
    })
    .then((response) => {
      // if (
      //   response?.status === 200 &&
      //   response?.data?.data &&
      //   response?.data?.data?.length > 0
      // ) {
      // console.log(response?.data?.data);
      return response;

      // } else {
      //   return [];
      // }
    });
};

const getApprove = (pageNo, pageSize, masterType, searchString) => {
  return api
    .post(GET_APPROVE_ENDPOINT, {
      pageNo,
      pageSize,
      masterType,
      searchString,
    })
    .then((response) => {
      // if (
      //   response?.status === 200 &&
      //   response?.data?.data &&
      //   response?.data?.data?.length > 0
      // ) {
      // console.log(response?.data?.data);
      return response;

      // } else {
      //   return [];
      // }
    });
};
// DATA_APPROVE_ENDPOINT
const delete_MasterDetails = (id) => {
  return api
    .post(DELETE__DATA_FIELDS, {
      id,
    })
    .then((response) => {
      if (
        response?.status === 200 &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        return response?.data?.data;
      } else {
        return [];
      }
    });
};

const delete_MasterApprove = (id) => {
  return api
    .post(DELETE_APPROVE, {
      id,
    })
    .then((response) => {
      if (
        response?.status === 200 &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        return response?.data?.data;
      } else {
        return [];
      }
    });
};

const Appreove_MasterDetails = (id) => {
  return api
    .post(DATA_APPROVE_ENDPOINT, {
      id,
    })
    .then((response) => {
      // if (
      //   response?.status === 200 &&
      //   response?.data?.data &&
      //   response?.data?.data?.length > 0
      // ) {
      return response;
      // } else {
      //   return [];
      // }
    });
};
const getAllSerikorAdmin = (data) => {
  return api.post(SEIKOR_ADMIN_ALLDATA_ENDPOINT, data).then((response) => {
    return response;
  });
};

const getALLApproval = (pageNo, pageSize) => {
  return api
    .post(SEIKOR_ADMIN_APPROVAL_ENDPOINT, {
      pageNo,
      pageSize,
    })
    .then((response) => {
      if (
        response?.status === 200 &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        return response?.data?.data;
      } else {
        return [];
      }
    });
};
const master = (mType, mCode, mValue) => {
  return api
    .post(ADDMASTER_ENDPOINT, {
      mType,
      mCode,
      mValue,
    })
    .then((response) => {
      return response;
    });
};

const allOrgsPinOrUnpin = (isPin, orgId) => {
  return api.post(ALL_ORGS_PIN_UNPIN, { isPin, orgId }).then((response) => {
    return response;
  });
};

const getAllJobPost = (data) => {
  return api.post(GET_ALL_JOB_POST, data).then((response) => {
    if (
      response?.status === 200 &&
      response?.data?.data &&
      response?.data?.data?.length > 0
    ) {
      return {
        data: response?.data?.data,
        totalCount: response?.data?.totalRecord,
        status: response?.status,
      };
    } else {
      return {
        data: [],
        message: response?.data?.message,
        status: response?.data?.status,
      };
    }
  });
};

const getAllUsers = (data) => {
  return api.post(GET_ALL_USERS, data).then((response) => {
    return response;
  });
};

const pinnedOrgs = (pageNo, pageSize, isPin) => {
  return api.post(PINNED_ORGS, { pageNo, pageSize, isPin }).then((response) => {
    return response;
  });
};

const overviewPinnedJobs = (pageNo, pageSize, isPin) => {
  return api.post(PINNED_JOBS, { pageNo, pageSize, isPin }).then((response) => {
    return response;
  });
};

const overviewUnpinJobs = (jobId, isPin) => {
  return api.post(OVERVIEW_UNPIN_JOBS, { jobId, isPin }).then((response) => {
    return response;
  });
};

const jobPromotion = (
  jobId,
  mainImage,
  addImage1,
  addImage2,
  promotionStartDate,
  promotionEndDate,
  tagsLine,
  promotionFixedDate,
  isPromoted
) => {
  return api
    .post(JOB_PROMOTION_ENDPOINT, {
      jobId,
      mainImage,
      addImage1,
      addImage2,
      promotionStartDate,
      promotionEndDate,
      tagsLine,
      promotionFixedDate,
      isPromoted
    })
    .then((response) => {
      return response;
    });
};


const getJobPromotion=( jobId)=>{
  return api
  .post(GET_JOB_PROMOTION_ENDPOINT,{
    jobId
  })
    .then((response) => {
      return response;
    });
};

const overviewMoreInsights = (fromDate, toDate) => {
  return api
    .post(OVERVIEW_MORE_INSIGHTS, { fromDate, toDate })
    .then((response) => {
      return response;
    }).catch((error) => {
      console.log("All  errr",error);
      return error;
    });
};

const overviewOrgTopReferees = (pageNo, pageSize) => {
  return api
    .post(OVERVIEW_ORG_TOP_REFEREES, { pageNo, pageSize })
    .then((response) => {
      return response;
    });
};

const getViewCandidateProfile = (userId) => {
  return api.get(VIEW_CANDIDATE_PROFILE + `/${userId}`).then((response) => {
    return response;
  });
};

const deleteImage = (id) => {
  return api.get(JOB_DELETE_PROMOTINAL_ENDPOINT + id).then((response) => {

    return response;
  });
};

const AddFileds = (mType, mCode, mValue) => {
  return api
    .post(ADD_DATA_FIELDS_ENDPOINT, [
      {
        mType,
        mCode,
        mValue,
      },
    ])
    .then((response) => {
      return response;
    });
};

const saveAdminMember = (workEmail, role, allOrgsAccess, listOfOrgs) => {
  return api
    .post(SAVEMEMBER_ENDPOINT, {
      workEmail,
      role,
      allOrgsAccess,
      listOfOrgs,
    })
    .then((response) => {
      return response;
    });
};

const getAllOrg = (data) => {
  return api.post(ALl_ORGANIZATION,data).then((response) => {
    return response;
  });
};

const getAllOrglist = (data) => {
  return api
    .post(ALl_ORGANIZATION_LIST_ENDPOINT,data)
    .then((response) => {
      return response;
    });
};
const orgAdminSummary = (pageNo, pageSize, fromDate, toDate) => {
  return api
    .post(OVERVIEW_ORG_SUMMARY, { pageNo, pageSize, fromDate, toDate })
    .then((response) => {
      return response;
    });
};

const newRequestsGetFeedback = (data) => {
  return api.post(NEW_REQ_GET_FEEDBACK, data).then((response) => {
    return response;
  });
};

const newRequestsDeleteFeedback = (id) => {
  return api.post(NEW_REQ_DELETE_FEEDBACK, { id }).then((response) => {
    return response;
  });
};

const deleteAdmin = (id) => {
  return api.get(DELETE_ADMIN_ENDPOINT + id).then((response) => {
    return response;
  });
};

const jobPostsPinOrUnpin = (jobId, isPin) => {
  return api.post(JOB_POSTS_PIN_UNPIN, { jobId, isPin }).then((response) => {
    return response;
  });
};

const fetchChatMessages = (pageNo, pageSize, jobId, jobReffaralId) => {
  return api
    .post(FETCH_MESSAGES, { pageNo, pageSize, jobId, jobReffaralId })
    .then((response) => {
      return response;
    });
};

const sendMessageToRecruiters = (message, jobId, jobReffaralId, receiverId) => {
  return api
    .post(SEND_MESSAGES_TO_RECRUITER, { message, jobId, jobReffaralId, receiverId })
    .then((response) => {
      return response;
    });
};

const getMaster = (type) => {
  return api
    .post(GET_MASTER, {
      mType: type,
      pageNo: 0,
      pageSize: 0,
    })
    .then((response) => {
      if (
        response?.status === 200 &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        return response?.data?.data;
      } else {
        return [];
      }
    });
};

const getReport = (type, toDate, fromDate) => {
  return api
    .post(
      GET_REPORT,
      {
        report: type,
        fromDate: fromDate,
        toDate: toDate,
      },
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      if (response?.status === 200 && response?.data) {
        return URL.createObjectURL(response?.data);
      } else {
        return null;
      }
    });
};

const getJobTags = (data) => {
  return api.post(GET_JOB_TAG_MASTER, data).then((response) => {
    return response;
  });
};

const addJobTags = (jobId, tagTitle) => {
  return api.post(ADD_JOB_TAG_TITLE, {jobId, tagTitle}).then((response) => {
    return response;
  });
};

const applicationReferralsNotifyForRecommendation = (jobId, refereeId, userId) => {
  return api.post(NOTIFY_FOR_RECOMMENDATION, {jobId, refereeId, userId}).then((response) => {
    return response;
  });
};

export {
  getJobPromotion,
  addJobTags, 
  getJobTags,
  getReport,
  getAllJobPost,
  getAllOrglist,
  getViewCandidateProfile,
  getAllUsers,
  deleteAdmin,
  getApporvedFields,
  getMasterDetails_DataFields,
  delete_MasterDetails,
  getAllSerikorAdmin,
  getAllOrg,
  getALLApproval,
  master,
  getApprove,
  Appreove_MasterDetails,
  allOrgsPinOrUnpin,
  pinnedOrgs,
  overviewPinnedJobs,
  overviewUnpinJobs,
  jobPromotion,
  AddFileds,
  overviewMoreInsights,
  overviewOrgTopReferees,
  orgAdminSummary,
  newRequestsGetFeedback,
  saveAdminMember,
  newRequestsDeleteFeedback,
  delete_MasterApprove,
  jobPostsPinOrUnpin,
  fetchChatMessages,
  sendMessageToRecruiters,
  getMaster,
  applicationReferralsNotifyForRecommendation,
  deleteImage,
};
