import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { STATUS_SUCCESS } from "../../../config/localstorage_keys";
import { FOR_APPROVAL_DETAIL_PAGE_ROUTE } from "../../../config/page_routes_constants";
import toaster from "../../../utils/toaster";
import { getALLApproval, getApporvedFields } from "../../../_services/serikoradmin_view.service";

const DataFieldForApprovalPage = () => {
  const [approved_data, setApproved_Data] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const allApprovedFields = () => {
    setShowLoader(true);

    getALLApproval(0, 10)
      .then((res) => {
        setApproved_Data(res);
        // console.log("setShowLoader", res?.status);
        // toaster("success", "Data fetched successfully.");
        setShowLoader(false);
        if (res?.status === STATUS_SUCCESS) {
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(true);
      });
  };

  useEffect(() => {
    allApprovedFields();
  }, []);



  // let data = [
  //   {
  //     id: 1,
  //     fieldName: "Key Skills",
  //     entries: 48,
  //   },
  //   {
  //     id: 2,
  //     fieldName: "Qualifications",
  //     entries: 48,
  //   },
  //   {
  //     id: 3,
  //     fieldName: "Education",
  //     entries: 48,
  //   },
  //   {
  //     id: 4,
  //     fieldName: "Specialization",
  //     entries: 48,
  //   },
  //   {
  //     id: 5,
  //     fieldName: "Candidate Status",
  //     entries: 48,
  //   },
  //   {
  //     id: 6,
  //     fieldName: "Currency",
  //     entries: 48,
  //   },
  //   {
  //     id: 7,
  //     fieldName: "Language",
  //     entries: 48,
  //   },
  //   {
  //     id: 8,
  //     fieldName: "Companies",
  //     entries: 48,
  //   },
  // ];
  return (
    <Table responsive className="mt-4">
      <thead>
        <tr>
          <th className="fs-12 fw-700 col-sm-5 col-md-3">Field Name</th>
          <th className="fs-12 fw-700 col">Entries</th>
        </tr>
      </thead>
      <tbody>
      { approved_data && approved_data.length>0  
          ? approved_data.map((item, key) => {
              return (
                <tr className="card-parent position-relative ">
                  <td className="fs-12 fw-400 align-middle py-3">
                    <p className="m-0">{item?.dataFieldName}</p>
                  </td>
                  <td className="fs-12 fw-400 align-middle position-relative">
                    <p className="m-0">{item?.entriesCount}</p>
                    <div className="onhover-show3 ">
                      <div className="link ms-2 align-items-center">
                        <Link
                          to={
                            FOR_APPROVAL_DETAIL_PAGE_ROUTE +
                            "/" +
                            item?.dataFieldName
                          }
                          className=" text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                        >
                          Manage
                        </Link>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })
          : "No Data Found"}
      </tbody>
    </Table>
  );
};

export default DataFieldForApprovalPage;
