/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import BackButton from "../../../assests/icons/back-icon.svg";
import close from "../../../assests/icons/ic-close-24.svg";
import down from "../../../assests/icons/ic_down.svg";
import up from "../../../assests/icons/ic_up.svg";
// import whitecircle from "../../../assests/icons/ic_whitecircle.svg";
import AboutEmployer from "../../../components/AboutEmployer";
import JobDetails from "../../../components/JobDetails";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ApplyForJobQuestions from "../../../components/ApplyForJobQuestions";
// import disabled from "../../../assests/icons/ic_disabled.svg";
import {
  fetchJobDetails,
  applyingForJob,
} from "../../../_services/member-profile.service";
import { useNavigate } from "react-router-dom";

import JobCardSave from "../../../components/JobCardSave";

import CompanyImage from "./../../../components/company_image";
import Loader from "../../../components/common/loader";
import NoDataFoundCard from "../../../components/common/no_data_found_card";
import ReferAJobDialog from "./../../../components/refer_a_job_dialog";
import {
  convertToInternationalCurrencySystem,
  monthConversion,
  yearConversion,
} from "../../../utils/utils";
import JobSaveCardForDetails from "./../../../components/JobSaveCardForDetails";
import ApplyForJobDialog from "./../../../components/ApplyForJobDialog";
import { REFEREE_ID_SEARCH_PARAMS } from "../../../config/page_routes_constants";
import { SOMETHING_WENT_WRONG } from "./../../../config/be_api_constants";
import { APPLIED } from "./../../../config/be_api_constants";
import { FROM_REFEREE_PAGE, NOT_MENTIONED } from "./../../../config/messages";
import NotFoundPage from "../../not_found_page";
import toaster from "../../../utils/toaster";
import { getCurrency, viewPreviousJob } from "../../../_services/view.service";
import { getCompanyDetails } from "../../../_services/company.service";
import { STATUS_SUCCESS, USER_ID } from "../../../config/localstorage_keys";
import { useStoreActions, useStoreState } from "easy-peasy";
import ScratchJobDetails from "../../../components/ScratchJobDetails";
import ScratchAboutEmployer from "../../../components/ScratchAboutEmployer";

const ScratchJobDetailsPage = (props) => {
  const isShowSratchPreviewBtn = useStoreState(
    (state) => state?.isShowSratchPreviewBtn
  );
  const [refereeId, setRefereeId] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [isJobDetails, setIsJobDetails] = useState(true);
  const [isReferJob, setIsReferJob] = useState(false);
  const [isEmployer, setIsEmployer] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [disableReferralBtn, setDisableReferralBtn] = useState(false);
  const [isFeatured, setIsFeatured] = useState(true); //check featured product
  const [mainImage, setMainImage] = useState(
    "https://bitsofco.de/content/images/2018/12/broken-1.png"
  );
  const [addImage1, setAddImage1] = useState("");
  const [addImage2, setAddImage2] = useState("");
  const [jobDetailsProps, setJobDetailsProps] = useState({
    jobTitle: "",
    companyName: "",
    companyLogo: "",
  });
  const notAppliedCompanyDetails = useStoreState(
    (state) => state?.notAppliedCompanyDetails
  );
  const setNotAppliedCompanyDetails = useStoreActions(
    (action) => action?.setNotAppliedCompanyDetails
  );
  const [countryCode, setCountryCode] = useState("");

  useEffect(() => {
    getCompanyDetails()
      .then((res) => {
        if (res?.data?.status == STATUS_SUCCESS) {
          let companyData = res?.data?.data[0];
          const companyName = companyData?.companyName;
          const companyLogo = companyData?.companyLogo;
          setNotAppliedCompanyDetails({
            ...notAppliedCompanyDetails,
            companyName: companyName,
            companyLogo: companyLogo,
          });
        }
      })
      .catch((error) => {});
  }, []);

  const isJobDetailsHandler = () => {
    setIsJobDetails(!isJobDetails);
  };
  const isReferJobHandler = () => {
    setIsReferJob(!isReferJob);
  };
  const AboutEmployerHandler = () => {
    setIsEmployer(!isEmployer);
  };

  const [show, setShow] = useState(false);

  const [scrollOffset, setScrollOffset] = useState(0);
  const [notFoundPage, setNotFoundPage] = useState(false);
  const [linkInvalid, setLinkInvalid] = useState({
    state: false,
    class: "d-block",
  });
  const [apiMessage, setApiMessage] = useState("");

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    getCurrencyCode();
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    const onScroll = () => setScrollOffset(window.pageYOffset);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    setRefereeId(searchParams?.get(REFEREE_ID_SEARCH_PARAMS));

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const [referJobShow, setReferJobShow] = useState(false);

  const referring = searchParams?.get("source") === FROM_REFEREE_PAGE;
  function getCurrencyCode() {
    getCurrency().then((res) => {
      res?.map((currencyItem, index) => {
        if (
          currencyItem?.name ===
          notAppliedCompanyDetails?.jobData?.salCurrencyId
        ) {
          setCountryCode(currencyItem?.code);
        }
      });
    });
  }
  return (
    <>
      {showLoader && <Loader />}
      {linkInvalid?.state ? <NoDataFoundCard text={apiMessage} /> : ""}
      {notFoundPage ? (
        <NotFoundPage />
      ) : (
        <div className={`my-4`}>
          <header className="job-detail-header">
            <div className="container mt-4 pt-5 px-3">
              <div className="d-flex">
                <div className="col-md-8 col-12">
                  <p className="big-font-gray p-0 m-0">
                    {notAppliedCompanyDetails?.jobData?.jobTitle}
                  </p>

                  <p className="big-font-gray p-0 m-0">
                    {notAppliedCompanyDetails?.jobTitle}
                  </p>
                </div>
              </div>
              <div className="d-flex ">
                <div className="col-md-8 col-12 d-md-flex gap-4 pt-4">
                  <CompanyImage
                    src={notAppliedCompanyDetails?.companyLogo}
                    width="50px"
                    // height="50px"
                    name={notAppliedCompanyDetails?.companyName}
                    initialsContainerClass="initialsStyle2-xl bg-white p-1 d-md-block d-none rounded"
                  />

                  <span>
                    <span className="small-text-gray "> Posted By </span>
                    <br />
                    <span className="black-text">
                      {" "}
                      {notAppliedCompanyDetails?.companyName}{" "}
                    </span>
                  </span>
                  <br />
                  <br className="d-md-none d-block"></br>
                  <span>
                    <span className="small-text-gray "> Job Location </span>
                    <br />
                    <span className="black-text">
                      {" "}
                      {notAppliedCompanyDetails?.jobData?.reportingType ===
                      "OPEN_TO_ALL"
                        ? "Open To All"
                        : notAppliedCompanyDetails?.jobData?.reportingType &&
                          notAppliedCompanyDetails?.jobData?.reportingType ===
                            "INPERSON"
                        ? "In Person"
                        : notAppliedCompanyDetails?.jobData?.reportingType}{" "}
                      | {notAppliedCompanyDetails?.jobData?.locationId}{" "}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </header>
          <div
            className=" z-index-align d-flex d-md-none justify-content-center bg-white position-fixed w-100 py-2"
            style={{ bottom: 0 }}
          >
            {referring ? (
              <input
                type="button"
                value="Refer This Role"
                className="btn-primary btn-rounded text-white p-2 w-90"
              />
            ) : (
              <input
                type="button"
                // onClick={() => setShow(true)}
                disabled={
                  notAppliedCompanyDetails?.jobData?.applicationStatus ===
                  APPLIED
                    ? true
                    : false
                }
                value={
                  notAppliedCompanyDetails?.jobData?.applicationStatus ===
                  APPLIED
                    ? "Applied"
                    : "Apply For This Role"
                }
                className={` btn-rounded text-white ps-lg-5 pe-lg-5 ps-md-4 pe-md-4 w-90 ${
                  notAppliedCompanyDetails?.jobData?.applicationStatus ===
                  APPLIED
                    ? "btn-disable"
                    : "btn-primary"
                } `}
              />
            )}
          </div>

          <div className="bg-white rounded-border shadow h-auto job-detail-content">
            <div className="container pt-2">
              <div className="d-md-flex gap-md-4">
                <span>
                  <span className="small-text-gray "> Job Type </span>
                  <br />
                  <span className="black-text">
                    {" "}
                    {notAppliedCompanyDetails?.jobData?.jobType === "FULLTIME"
                      ? "Full Time"
                      : notAppliedCompanyDetails?.jobData?.jobType &&
                        notAppliedCompanyDetails?.jobData?.jobType ===
                          "PARTTIME"
                      ? "Part Time"
                      : notAppliedCompanyDetails?.jobData?.jobType}{" "}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Salary </span>
                  <br />
                  <span className="black-text">
                    {`${convertToInternationalCurrencySystem(
                      notAppliedCompanyDetails?.jobData?.minSalary,
                      notAppliedCompanyDetails?.jobData?.salCurrency
                    )} - ${convertToInternationalCurrencySystem(
                      notAppliedCompanyDetails?.jobData?.maxSalary,
                      notAppliedCompanyDetails?.jobData?.salCurrency
                    )}  ${countryCode} ${
                      notAppliedCompanyDetails?.jobData?.salaryType
                    }`}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Experience </span>
                  <br />
                  <span className="black-text">
                    {" "}
                    {`${yearConversion(
                      notAppliedCompanyDetails?.jobData?.minExpRequiredMonths,
                      notAppliedCompanyDetails?.jobData?.maxExpRequiredMonths
                    )}`}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Function </span>
                  <br />
                  <span className="black-text">
                    {" "}
                    {notAppliedCompanyDetails?.jobData?.departmentName}{" "}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Target Start Date </span>
                  <br />
                  <span className="black-text">
                    {" "}
                    {notAppliedCompanyDetails?.jobData?.targetDate}{" "}
                  </span>
                </span>
                <hr className="d-block d-md-none"></hr>
                <span>
                  <span className="small-text-gray "> Referral Bonus </span>
                  <br />
                  {/* <span className="black-text">
                    {" "}
                    {notAppliedCompanyDetails?.jobData?.referralBonus}{" "}
                  </span> */}
                  <span className="black-text">
                    {notAppliedCompanyDetails?.jobData?.referralBonus &&
                    (notAppliedCompanyDetails?.jobData?.salCurrencyId ||
                      notAppliedCompanyDetails?.jobData?.salaryCurrencyId)
                      ? convertToInternationalCurrencySystem(
                          notAppliedCompanyDetails?.jobData?.referralBonus,
                          notAppliedCompanyDetails?.jobData?.salCurrencyId ||
                            notAppliedCompanyDetails?.jobData?.salaryCurrencyId
                        ) +
                        " " +
                        countryCode
                      : NOT_MENTIONED}
                  </span>
                </span>
              </div>
              <hr className="my-3"></hr>
              <div className="">
                <div className="d-flex justify-content-between w-100">
                  <span className="fw-bold text-primary py-1 ">
                    Job Details
                  </span>
                  <div
                    className="btn-blue-round  pt-1 pb-1 px-2 pointer"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseDiv"
                    aria-expanded="false"
                    aria-controls="collapseDiv"
                    onClick={isJobDetailsHandler}
                  >
                    <img
                      src={isJobDetails ? down : up}
                      alt="down-btn"
                      className="text-primary "
                    />
                  </div>
                </div>
                {/* Collapse Element  */}

                <div
                  className={isJobDetails ? "collapse show d-block" : "d-none"}
                  id="collapseDiv"
                >
                  <div
                    className={
                      isFeatured
                        ? "pt-2 d-md-flex gap-2 d-block"
                        : "d-none pt-2 d-flex gap-2"
                    }
                  >
                    <div className="col-md-6 col-12 p-0">
                      <img
                        src={mainImage}
                        className="rounded-image w-100 p-1"
                      />
                    </div>
                    <div className="col-md-6 col-12 p-0">
                      <img src={addImage1} className="rounded-image w-50 p-1" />

                      <img src={addImage2} className="rounded-image w-50 p-1" />

                      <p className="font-color-gray p-1 pt-3">
                        {
                          notAppliedCompanyDetails?.jobData?.JobPromotion
                            ?.tagsLine
                        }
                      </p>
                    </div>
                  </div>
                  {isShowSratchPreviewBtn && (
                    <div className="w-100">
                      <ScratchJobDetails
                        showReferJobDialog={() => {
                          setReferJobShow(true);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <hr className="my-3"></hr>
              <div className="d-flex justify-content-between">
                <span className="fw-bold text-primary py-1">
                  Refer this Job
                </span>
                <div
                  className="btn-blue-round p-2 pt-1 pb-1 pointer"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#referjobcollapse"
                  aria-expanded="false"
                  aria-controls="referjobcollapse"
                  onClick={isReferJobHandler}
                >
                  <img
                    src={(referring ? !isReferJob : isReferJob) ? down : up}
                    alt="down-btn"
                    className="text-black"
                  />
                </div>
              </div>
              <div
                className={
                  (referring ? isReferJob : !isReferJob)
                    ? "collapse h-100"
                    : "h-0"
                }
                id="referjobcollapse"
              >
                <div className="row ">
                  <div className="col-12 mt-4">
                    <p className="font-medium-gray">
                      {" "}
                      For a better referral conversion, refer candidates with
                      following criteria :{" "}
                    </p>
                  </div>
                  <div className="col-md-6 col-12 mt-4">
                    <span className="font-color-black fw-600 fs-16">
                      {" "}
                      Current Company{" "}
                    </span>{" "}
                    <br />
                    <div className="pt-1 pb-3 d-flex flex-wrap">
                      {notAppliedCompanyDetails?.jobData?.referralCriteriaCompany?.map(
                        (company, index) => {
                          return (
                            <>
                              <div
                                className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2"
                                key={index}
                              >
                                {company}
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-12 mt-4">
                    <span className="font-color-black fw-600 fs-16">
                      Industry
                    </span>
                    <br />
                    <div className="pt-1 pb-3 d-flex flex-wrap">
                      {notAppliedCompanyDetails?.jobData?.referralCriteriaIndustry?.map(
                        (industry, index) => {
                          return (
                            <>
                              <div
                                className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2"
                                key={index}
                              >
                                {industry}
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>

                  <div className="col-md-6 col-12 mt-3">
                    <span className="font-color-black fw-600 fs-16">
                      {" "}
                      Other{" "}
                    </span>
                    <br />
                    <div className="pt-1 pb-3 d-flex flex-wrap">
                      {notAppliedCompanyDetails?.jobData?.referralCriteriaOther?.map(
                        (other, index) => {
                          return (
                            <>
                              <div
                                className="font-gray-bg p-1 ps-2 px-2 me-2 mt-2"
                                key={index}
                              >
                                {other}
                              </div>
                            </>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <hr className="my-3"></hr>
              <div className="d-flex justify-content-between">
                <span className="fw-bold text-primary py-1">
                  About the Employer
                </span>
                <div
                  className="btn-blue-round px-2 py-1 pointer"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#employer"
                  aria-expanded="false"
                  aria-controls="employer"
                  onClick={AboutEmployerHandler}
                  role="button"
                >
                  <img
                    src={isEmployer ? down : up}
                    alt="down-btn"
                    className="text-primary"
                  />
                </div>
              </div>
              <div
                id="employer"
                className={isEmployer ? "collapse pt-2 d-block" : "d-none"}
                aria-expanded="false"
              >
                <ScratchAboutEmployer
                  companyData={notAppliedCompanyDetails?.companyData}
                  mainImage={mainImage}
                />
              </div>
              <hr className="mt-3 mb-3"></hr>
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ScratchJobDetailsPage;
