import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import backButton from "../../../assests/icons/back-icon.svg";
import icCloseBlue from "../../../assests/icons/ic-close-blue.svg";
import tickGreen from "../../../assests/tick-green.svg";
import crossRed from "../../../assests/cross-red.svg";
import toaster from "./../../../utils/toaster";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";
import {
  Appreove_MasterDetails,
  delete_MasterDetails,
  getApprove,
} from "../../../_services/serikoradmin_view.service";
import Loader from "../../../components/common/loader";

function ForApprovalDetailPage() {
  const { masterType } = useParams();

  const [apiRefresh, setApiRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [listA, setListA] = useState([]);
  const [listB, setListB] = useState([]);
  const [listC, setListC] = useState([]);
  const [listD, setListD] = useState([]);
  const [listE, setListE] = useState([]);
  const [listF, setListF] = useState([]);
  const [listG, setListG] = useState([]);
  const [listH, setListH] = useState([]);
  const [listI, setListI] = useState([]);
  const [listJ, setListJ] = useState([]);
  const [listK, setListK] = useState([]);
  const [listL, setListL] = useState([]);
  const [listM, setListM] = useState([]);
  const [listN, setListN] = useState([]);
  const [listO, setListO] = useState([]);
  const [listP, setListP] = useState([]);
  const [listQ, setListQ] = useState([]);
  const [listR, setListR] = useState([]);
  const [listS, setListS] = useState([]);
  const [listT, setListT] = useState([]);
  const [listU, setListU] = useState([]);
  const [listV, setListV] = useState([]);
  const [listW, setListW] = useState([]);
  const [listX, setListX] = useState([]);
  const [listY, setListY] = useState([]);
  const [listZ, setListZ] = useState([]);
  const selectDelete = (id) => {
    setShowLoader(true);
    setApiRefresh(true);
    delete_MasterDetails(id)
      .then((res) => {
        console.log("setShowLoader", res);
        //toaster("success", "Tag Declined");
        setShowLoader(false);
        if (res?.status === STATUS_SUCCESS) {
          setShowLoader(false);
          setApiRefresh(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
      });
  };

  const selectApprove = (id) => {
    setShowLoader(true);
    // setApiRefresh(true);
    Appreove_MasterDetails(id)
      .then((res) => {
        
        //toaster("success", "Tag Approved");
       
        if (res?.status === 200) {
          setShowLoader(false);
          allmasterDetails();
          // setApiRefresh(false);

          toaster("success", "Master value updated successfully");
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        // setApiRefresh(false);
      });
  };

  const allmasterDetails = (searchString) => {
    setShowLoader(true);
    // setApiRefresh(true);
    getApprove(0, 20, masterType, searchString)
      .then((res) => {
        
        setListA(res?.data?.data?.A);
        setListB(res?.data?.data?.B);
        setListC(res?.data?.data?.C);
        setListD(res?.data?.data?.D);
        setListE(res?.data?.data?.E);
        setListF(res?.data?.data?.F);
        setListG(res?.data?.data?.G);
        setListH(res?.data?.data?.H);
        setListI(res?.data?.data?.I);
        setListJ(res?.data?.data?.J);
        setListK(res?.data?.data?.K);
        setListL(res?.data?.data?.L);
        setListM(res?.data?.data?.M);
        setListN(res?.data?.data?.N);
        setListO(res?.data?.data?.O);
        setListP(res?.data?.data?.P);
        setListQ(res?.data?.data?.Q);
        setListR(res?.data?.data?.R);
        setListS(res?.data?.data?.S);
        setListT(res?.data?.data?.T);
        setListU(res?.data?.data?.U);
        setListV(res?.data?.data?.V);
        setListW(res?.data?.data?.W);
        setListX(res?.data?.data?.X);
        setListY(res?.data?.data?.Y);
        setListZ(res?.data?.data?.Z);
        console.log(res?.data?.data?.A, "res?.data?.data?.A", listA);
        if (res?.status === STATUS_SUCCESS) {
          toaster("success", "Data fetched successfully.");
          setShowLoader(false);
          // setApiRefresh(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        // setApiRefresh(false);
      });
  };
  useEffect(() => {
    allmasterDetails();

    if (apiRefresh === true) {
      allmasterDetails();
      setApiRefresh(false);
    }
  }, [apiRefresh]);

  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className=" content-margin mt-4">
        <Link to={-1} className="color-primary d-flex gap-2 align-items-center">
          <div>
            <img src={backButton} alt={backButton} />
          </div>
          <div className="color-primary fs-12 fw-400 back-btn-width">
            Go Back
          </div>
        </Link>
        <div className="row my-3 d-grid  d-md-flex">
          <div className="col fs-24 fw-700">{masterType}</div>
        </div>

        <div className="my-3">
          {listA && listA.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>A</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listA?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2" key={index}>
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listB && listB.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>B</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listB?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listC && listC.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>C</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listC?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}
          {listD && listD.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>D</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listD?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}
          {listE && listE.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>E</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listE?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listF && listF.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>F</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listF?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listG && listG.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>G</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listG?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listH && listH.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>H</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listH?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listI && listI.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>I</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listI?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listJ && listJ?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>J</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listJ?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listK && listK?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>K</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listK?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listL && listL?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>L</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listL?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listM && listM?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>M</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listM?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listN && listN?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>N</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listN?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listO && listO?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>O</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listO?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listP && listP?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>P</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listP?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listQ && listQ?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>Q</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listQ?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listR && listR?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>R</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listR?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listS && listS?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>S</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listS?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listT && listT?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>T</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listT?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listU && listU?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>U</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listU?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}

          {listV && listV?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>V</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listV?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}
          {listW && listW?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>W</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listW?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}
          {listX && listX?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>X</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listX?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}
          {listY && listY?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>Y</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listY?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}
          {listZ && listZ?.length > 0 ? (
            <div className="d-flex gap-4 py-3">
              <div style={{ minWidth: "20px" }}>Z</div>
              <span className=" d-flex gap-2 flex-wrap">
                {listZ?.map((item, index) => {
                  return (
                    <div className="d-flex gray-color-badge gap-2">
                      <div>{item?.masterValues} </div>
                      <img
                        onClick={() => {
                          selectDelete(item?.id);
                          toaster("danger", "Tag Declined");
                        }}
                        src={crossRed}
                        alt="blue-close-icon"
                        className="pointer"
                      />
                      <img
                        onClick={() => {
                          selectApprove(item?.id);
                          // toaster("success", "Tag Approved");
                        }}
                        src={tickGreen}
                        alt="blue-close-icon"
                        className="pointer border-start ps-2"
                      />
                    </div>
                  );
                })}
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ForApprovalDetailPage;
