import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";

import toaster from "../../utils/toaster";

import Loader from "../../components/common/loader";
import { create } from "../../_services/company.service";

const CompanyAbout = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [description, setDescription] = useState();
  const[descount,setDesCount]=useState();
  const Character_Limit = 250;
  const [total, setTotal] = useState("");

  // const handleChange = (event) => {
  //   if (event.target.value.length >= Character_Limit) {
  //     setTotal(event.target.value.length - Character_Limit);
  //     setDescription(event.target.value);
  //     // setIsActive({ isActive: true });
  //   // } else {
  //   //   setIsActive();
  //   }
  //   // setHireMeField(event.target.value);
  // };
  const saveAbout = {
    companyId: props.companyId,
    companyDesc: description,
  };

  const onSaveAbout = () => {
    // if (description === "") {
    //   toaster("error", "Please Add Description");
    // } else {
    setShowLoader(true);
    create(saveAbout)
      .then((res) => {
        setShowLoader(false);
        toaster("success", "Data updated successfully..");
        props?.setApiRefresh(true);
        props.onAboutClose();
        // if (res?.data?.status == STATUS_SUCCESS) {
        //   toaster(
        //     "success",
        //     res?.data?.message ? res?.data?.message : STATUS_SUCCESS
        //   );
        // } else {
        //   toaster("error", "Company data is available");
        // }
      })
      .catch((e) => {
        props?.setApiRefresh(false);
        setShowLoader(false);
      });
    // }
  };


  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.onAboutClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onAboutClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">About</div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body mt-0 ">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600">
                  About Company
                </div>
                <div className="pt-3 pb-3">
                  <textarea
                     maxLength={Character_Limit}
                    type="text"
                    className="modal-form-input input-color-black"
                    rows="5"
                    placeholder="Airbnb, Inc. is an American company that operates an online marketplace for lodging, primarily homestays for vacation rentals, and tourism activities. Based in San Francisco, California, the platform is accessible via website and mobile app."
                    defaultValue={props.companyDesc}
                    onChange={(e) => {
                      setDescription(e.target.value);
                      setDesCount(e.target.value.length)
                    }}
                    // onChange={handleChange}
                  />
                  <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                        {/* {props.companyDesc}
                        description
                        {props.companyDesc?.length}- */}
                        {descount
                        }-{Character_Limit} Characters Add
                      </h1>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onAboutClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSaveAbout();
                // props.onAboutClose();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyAbout;
