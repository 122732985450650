import Modal from "react-bootstrap/Modal";
import logo from "../../assests/ic_seikorfull.svg";
import { Link, useLocation } from "react-router-dom";
import icHome from "./../../assests/icons/ic_home.svg";
import icAllTeams from "./../../assests/icons/ic-all-teams.svg";
import icJobPosts from "./../../assests/icons/ic-jobposts.svg";
import icDraftJobs from "./../../assests/icons/ic-draftjobs.svg";
import icJobTemplate from "./../../assests/icons/ic-jobtemplate.svg";
import icReferees from "./../../assests/icons/ic-referees.svg";
import icInviteReferees from "./../../assests/icons/ic-invite-referees.svg";
import icManageTeams from "./../../assests/icons/ic-manage-teams.svg";
import icCompanyProfile from "./../../assests/icons/ic-company-profile.svg";
import icAccount from "./../../assests/icons/ic-account.svg";
import icSupport from "./../../assests/icons/ic-support.svg";
import icClose from "./../../assests/icons/ic-close-24.svg";
import "../../styles/navigation_sidebar.scss";
import { ModalBody } from "react-bootstrap";
import {
  ACCOUNT_PAGE_ROUTE,
  JOB_POSTS_PAGE_ROUTE,
  JOB_TEMPLATES,
  SUPPORT_AND_FEEDBACK,
  REFEREE_SCREEN_ROUTE,
  INVITE_REFEREE_PAGE_ROUTE,
  ALL_TEAMS_SCREEN_ROUTE,
  ALL_USERS_ROUTE,
  NEW_REQUEST_PAGE_ROUTE,
  REPORTS_PAGE_ROUTE,
  ALL_ORGS_SCREEN_ROUTE,
  ADMIN_REFEREE_SCREEN_ROUTE,
  SEIKOR_ADMINS_SCREEN,
  ADMIN_JOB_POST_PAGE_ROUTE,
  TEAMS_MEMBERS_ROUTE,
  DATA_FIELDS_PAGE_ROUTE,
} from "../../config/page_routes_constants";
import { BUSINESS_UPDATE_PAGE } from "../../config/page_routes_constants";
import icSideArrow from "./../../assests/icons/ic-side-arrow.svg";
import { DRAFT_JOB_POST_PAGE_ROUTE } from "../../config/page_routes_constants";
import { useStoreState } from "easy-peasy";
import { USER_ROLE } from "../../config/be_api_constants";
import { useEffect, useState } from "react";
import { STATUS_SUCCESS } from "../../config/localstorage_keys";
import { getAllJobDraft, getjobSummary } from "../../_services/view.service";
import { getAllJobPost } from "../../_services/serikoradmin_view.service";

const MobileViewModal = (props) => {
  const location = useLocation();

  // const role = useStoreState((state) => state?.candidate?.role);
  const role = localStorage.getItem("role");

  return (
    <>
      <Modal
        show={props.show}
        centered
        fullscreen="lg-down"
        onHide={() => props.setShow(false)}
        className="navigation-mobile-responsive d-lg-none d-block application-modal"
        animation={false}
      >
        <Modal.Header className="">
          <div className="d-flex justify-content-between align-items-center gap-5">
            <div className="ps-4 pe-4">
              <img src={logo} alt="logo" />
            </div>
            <div className="ps-5" onClick={() => props.setShow(false)}>
              <img src={icClose} alt="close-btn" />
            </div>
          </div>
        </Modal.Header>
        <ModalBody className="p-0 m-0 navigation-sidebar-mobile">
          <div className="d-lg-none d-block">
            <div
              className="navigation-sidebar-mobile h-100 w-100"
              onClick={() => props.setShow(false)}
            >
              <Link
                to={"/"}
                className={
                  (location?.pathname === "/" ? " " : "") +
                  "d-flex justify-content-between sidebar-div align-items-center"
                }
              >
                <div className="d-flex gap-3 align-items-center">
                  <img src={icHome} alt="home-icon" />
                  <div className="pt-1">Overview </div>
                </div>
                <div
                  className={location.pathname === "/" ? "d-block" : "d-none"}
                >
                  <img src={icSideArrow} alt="side-arrow-icon" />
                </div>
              </Link>

              {/* {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={ALL_TEAMS_SCREEN_ROUTE}
                  className={
                    (location?.pathname === "/" + ALL_TEAMS_SCREEN_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">All Teams</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ALL_TEAMS_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={ALL_TEAMS_SCREEN_ROUTE}
                  className={
                    (location?.pathname === "/" + ALL_TEAMS_SCREEN_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">All Teams</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ALL_TEAMS_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={ALL_TEAMS_SCREEN_ROUTE}
                //   className={
                //     (location?.pathname === ALL_TEAMS_SCREEN_ROUTE
                //       ? "active "
                //       : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icAllTeams} alt="companyProfile-icon" />
                //     <div className="pt-1">All Teams</div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === ALL_TEAMS_SCREEN_ROUTE
                //         ? "d-block"
                //         : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )}

              {/* all orgs page */}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={ALL_ORGS_SCREEN_ROUTE}
                  className={
                    (location?.pathname === "/" + ALL_ORGS_SCREEN_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">All Orgs</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ALL_ORGS_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}
              {/* job posts page */}
              {role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN ? (
                <Link
                  to={ADMIN_JOB_POST_PAGE_ROUTE}
                  className={
                    (location?.pathname === "/" + ADMIN_JOB_POST_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icJobPosts} alt="companyProfile-icon" />
                    <div className="pt-1">
                      Job Posts({props.total_SeikorAdminJobs})
                    </div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ADMIN_JOB_POST_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                <Link
                  to={JOB_POSTS_PAGE_ROUTE}
                  className={
                    (location?.pathname === JOB_POSTS_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icJobPosts} alt="companyProfile-icon" />
                    <div className="pt-1">Job Posts ({props.total_Jobs})</div>
                  </div>
                  <div
                    className={
                      location.pathname === JOB_POSTS_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}
              {/* all users page */}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={ALL_USERS_ROUTE}
                  className={
                    (location?.pathname === "/" + ALL_USERS_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">All Users</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ALL_USERS_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}
              {/* draft job post page */}
              {/* {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={DRAFT_JOB_POST_PAGE_ROUTE}
                  className={
                    (location?.pathname === "/" + DRAFT_JOB_POST_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icDraftJobs} alt="home-icon" />
                    <div className="pt-1">Draft Job Posts ({total_DraftJobs})</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + DRAFT_JOB_POST_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={DRAFT_JOB_POST_PAGE_ROUTE}
                  className={
                    (location?.pathname === "/" + DRAFT_JOB_POST_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icDraftJobs} alt="allteams-icon" />
                    <div className="pt-1">
                      Draft Job Posts ({props.total_DraftJobs})
                    </div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + DRAFT_JOB_POST_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={DRAFT_JOB_POST_PAGE_ROUTE}
                //   className={
                //     (location?.pathname === DRAFT_JOB_POST_PAGE_ROUTE
                //       ? "active "
                //       : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icDraftJobs} alt="companyProfile-icon" />
                //     <div className="pt-1">
                //       Draft Job Posts ({props.total_DraftJobs})
                //     </div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === DRAFT_JOB_POST_PAGE_ROUTE
                //         ? "d-block"
                //         : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )}
              {/* job templates page */}
              {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={JOB_TEMPLATES}
                  className={
                    (location?.pathname === "/" + JOB_TEMPLATES
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icJobTemplate} alt="JobTemplate-icon" />
                    <div className="pt-1">Job Templates</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + JOB_TEMPLATES
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}

              {/* {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={JOB_TEMPLATES}
                  className={
                    (location?.pathname === "/" + JOB_TEMPLATES
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icJobTemplate} alt="JobTemplate-icon" />
                    <div className="pt-1">Job Templates</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + JOB_TEMPLATES
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={JOB_TEMPLATES}
                //   className={
                //     (location?.pathname === JOB_TEMPLATES ? "active " : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icJobTemplate} alt="JobTemplate-icon" />
                //     <div className="pt-1">Job Templates</div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === JOB_TEMPLATES ? "d-block" : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )} */}

              {/* business referees page */}
              {/* {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={REFEREE_SCREEN_ROUTE}
                  className={
                    (location?.pathname === "/" + REFEREE_SCREEN_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icReferees} alt="InviteReferees-icon" />
                    <div className="pt-1">Referees</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + REFEREE_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={REFEREE_SCREEN_ROUTE}
                  className={
                    (location?.pathname === "/" + REFEREE_SCREEN_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icReferees} alt="allteams-icon" />
                    <div className="pt-1">Referees</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + REFEREE_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={REFEREE_SCREEN_ROUTE}
                //   className={
                //     (location?.pathname === REFEREE_SCREEN_ROUTE
                //       ? "active "
                //       : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icReferees} alt="companyProfile-icon" />
                //     <div className="pt-1">Referees</div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === REFEREE_SCREEN_ROUTE
                //         ? "d-block"
                //         : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )}
              {/* referees page */}
              {/* {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={ADMIN_REFEREE_SCREEN_ROUTE}
                  className={
                    (location?.pathname === "/" + ADMIN_REFEREE_SCREEN_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icReferees} alt="InviteReferees-icon" />
                    <div className="pt-1">Referees</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ADMIN_REFEREE_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={ADMIN_REFEREE_SCREEN_ROUTE}
                  className={
                    (location?.pathname === "/" + ADMIN_REFEREE_SCREEN_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icReferees} alt="InviteReferees-icon" />
                    <div className="pt-1">All Referees</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ADMIN_REFEREE_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}
              {/* invite referees page */}
              {/* {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={INVITE_REFEREE_PAGE_ROUTE}
                  className={
                    (location?.pathname === "/" + INVITE_REFEREE_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icInviteReferees} alt="InviteReferees-icon" />
                    <div className="pt-1">Invite Referees</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + INVITE_REFEREE_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={INVITE_REFEREE_PAGE_ROUTE}
                  className={
                    (location?.pathname === "/" + INVITE_REFEREE_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icInviteReferees} alt="InviteReferees-icon" />
                    <div className="pt-1">Invite Referees</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + INVITE_REFEREE_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={INVITE_REFEREE_PAGE_ROUTE}
                //   className={
                //     (location?.pathname === INVITE_REFEREE_PAGE_ROUTE
                //       ? "active "
                //       : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icInviteReferees} alt="InviteReferees-icon" />
                //     <div className="pt-1">Invite Referees</div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === INVITE_REFEREE_PAGE_ROUTE
                //         ? "d-block"
                //         : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )}

              {/* seikor admins page */}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={SEIKOR_ADMINS_SCREEN}
                  className={
                    (location?.pathname === "/" + SEIKOR_ADMINS_SCREEN
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">Seikor Admins</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + SEIKOR_ADMINS_SCREEN
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}
              {/* team memebers page */}
              {/* {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={TEAMS_MEMBERS_ROUTE}
                  className={
                    (location?.pathname === "/" + TEAMS_MEMBERS_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icManageTeams} alt="ManageTeams-icon" />
                    <div className="pt-1">Teams & Members</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + TEAMS_MEMBERS_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={TEAMS_MEMBERS_ROUTE}
                  className={
                    (location?.pathname === "/" + TEAMS_MEMBERS_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icManageTeams} alt="ManageTeams-icon" />
                    <div className="pt-1">Teams & Members</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + TEAMS_MEMBERS_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={TEAMS_MEMBERS_ROUTE}
                //   className={
                //     (location?.pathname === TEAMS_MEMBERS_ROUTE
                //       ? "active "
                //       : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icManageTeams} alt="ManageTeams-icon" />
                //     <div className="pt-1">Teams & Members</div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === TEAMS_MEMBERS_ROUTE
                //         ? "d-block"
                //         : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )}

              {/* data-fields page */}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={DATA_FIELDS_PAGE_ROUTE}
                  className="d-flex gap-3 sidebar-div"
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">Data Fields</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + ALL_TEAMS_SCREEN_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}
              {/* new request page */}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={NEW_REQUEST_PAGE_ROUTE}
                  className={
                    (location?.pathname === "/" + NEW_REQUEST_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">Feedbacks</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + NEW_REQUEST_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}
              {/* report page */}
              {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
                <Link
                  to={REPORTS_PAGE_ROUTE}
                  className={
                    (location?.pathname === "/" + REPORTS_PAGE_ROUTE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icAllTeams} alt="allteams-icon" />
                    <div className="pt-1">Reports</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + REPORTS_PAGE_ROUTE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )}

              {/* business profile page */}
              {/* {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={BUSINESS_UPDATE_PAGE}
                  className={
                    (location?.pathname === "/" + BUSINESS_UPDATE_PAGE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icCompanyProfile} alt="companyProfile-icon" />
                    <div className="pt-1">Company Profile</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + BUSINESS_UPDATE_PAGE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={BUSINESS_UPDATE_PAGE}
                  className={
                    (location?.pathname === "/" + BUSINESS_UPDATE_PAGE
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icCompanyProfile} alt="companyProfile-icon" />
                    <div className="pt-1">Company Profile</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + BUSINESS_UPDATE_PAGE
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={BUSINESS_UPDATE_PAGE}
                //   className={
                //     (location?.pathname === BUSINESS_UPDATE_PAGE
                //       ? "active "
                //       : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icCompanyProfile} alt="companyProfile-icon" />
                //     <div className="pt-1">Company Profile</div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === BUSINESS_UPDATE_PAGE
                //         ? "d-block"
                //         : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )}
              {/* account page */}
              <Link
                to={ACCOUNT_PAGE_ROUTE}
                className={
                  (location?.pathname === "/" + ACCOUNT_PAGE_ROUTE
                    ? "active "
                    : "") +
                  "d-flex justify-content-between sidebar-div align-items-center"
                }
              >
                <div className="d-flex gap-3 align-items-center">
                  <img src={icAccount} alt="Account-icon" />
                  <div className="pt-1">Account</div>
                </div>
                <div
                  className={
                    location.pathname === "/" + ACCOUNT_PAGE_ROUTE
                      ? "d-block"
                      : "d-none"
                  }
                >
                  <img src={icSideArrow} alt="side-arrow-icon" />
                </div>
              </Link>
              {/* support ND FEEDBACK PAGE */}
              {/* {(role === USER_ROLE.BUSINESS_ADMIN ||
                role === USER_ROLE.BUSINESS_USER) && (
                <Link
                  to={SUPPORT_AND_FEEDBACK}
                  className={
                    (location?.pathname === "/" + SUPPORT_AND_FEEDBACK
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center">
                    <img src={icSupport} alt="support-icon" />
                    <div className="pt-1">Support & Feedback</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + SUPPORT_AND_FEEDBACK
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              )} */}

              {role === USER_ROLE.BUSINESS_ADMIN ||
              role === USER_ROLE.BUSINESS_USER ? (
                <Link
                  to={SUPPORT_AND_FEEDBACK}
                  className={
                    (location?.pathname === "/" + SUPPORT_AND_FEEDBACK
                      ? "active "
                      : "") +
                    "d-flex justify-content-between sidebar-div align-items-center"
                  }
                >
                  <div className="d-flex gap-3 align-items-center ">
                    <img src={icSupport} alt="support-icon" />
                    <div className="pt-1">Support & Feedback</div>
                  </div>
                  <div
                    className={
                      location.pathname === "/" + SUPPORT_AND_FEEDBACK
                        ? "d-block"
                        : "d-none"
                    }
                  >
                    <img src={icSideArrow} alt="side-arrow-icon" />
                  </div>
                </Link>
              ) : (
                ""
                // <Link
                //   to={SUPPORT_AND_FEEDBACK}
                //   className={
                //     (location?.pathname === SUPPORT_AND_FEEDBACK
                //       ? "active "
                //       : "") +
                //     "d-flex justify-content-between sidebar-div align-items-center"
                //   }
                // >
                //   <div className="d-flex gap-3 align-items-center">
                //     <img src={icSupport} alt="support-icon" />
                //     <div className="pt-1">Support & Feedback</div>
                //   </div>
                //   <div
                //     className={
                //       location.pathname === SUPPORT_AND_FEEDBACK
                //         ? "d-block"
                //         : "d-none"
                //     }
                //   >
                //     <img src={icSideArrow} alt="side-arrow-icon" />
                //   </div>
                // </Link>
              )}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MobileViewModal;
