import React from "react";
import { Modal } from "react-bootstrap";
import close from "../../../../assests/icons/ic-close-24.svg";
import HeaderTwo from "../../../../components/common/headerTwo";
import ChangePasswordPage from "../../change_password_page";

function PasswordChangeLoggedInModal(props) {
  return (
    <Modal
      show={props?.showPasswordChangeModal}
      backdrop="static"
      fullscreen={true}
      className="p-0"
      keyboard={false}
      onHide={() => {
        props?.setShowPasswordChangeModal(false);
      }}
      contentClassName="m-0 p-0"
      dialogClassName="m-0 p-0"
    >
      <Modal.Body className="m-0 p-0">
        <ChangePasswordPage
          onClickCloseDialog={() => {
            props?.setShowPasswordChangeModal(false);
          }}
        />
      </Modal.Body>
    </Modal>
  );
}

export default PasswordChangeLoggedInModal;
