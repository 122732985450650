import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProfileImage from "../../components/profile_image";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";
import { STATUS_SUCCESS } from "../../config/localstorage_keys";
import { GENERAL_ERROR_MESSAGE } from "../../config/messages";
import { REQUIRED } from "../../config/validation_patterns_constants";
import Loader from "../../components/common/loader";

const CompanyName = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  // const [companyId, setCompanyId] = useState();
  const [name, setName] = useState("");

  // const [formData, setFormData] = useState({
  //   name: {
  //     valueText: "",
  //     errorText: "",
  //     check: [REQUIRED],
  //   },
  // });

  const saveName = {
    companyId: props.companyId,
    companyName: name,
    companyCode: props.companyCode,
  };

  const onSaveCompanyName = () => {
    if (name === "") {
      toaster("error", "Please Add Name");
    } else {
      setShowLoader(true);
      toaster("success" ,"Company name updated successfully")
      create(saveName)
        .then((res) => {
          setShowLoader(false);
          props?.setApiRefresh(true);
          props.onNameClose();
          // if (res?.data?.status == STATUS_SUCCESS) {
          //   toaster(
          //     "success",
          //     res?.data?.message ? res?.data?.message : STATUS_SUCCESS
          //   );
          // }
        })
        .catch((err) => {
          setShowLoader(false);
          props?.setApiRefresh(false);
          toaster("error", err);
        });
    }
  };
  // console.log(props.companyId,"this is companyid");

  return (
    <>
      {showLoader && <Loader />}
      <div>
        {/* {showLoader && <Loader />} */}
        <Modal
          show={props.show}
          onHide={() => props.onNameClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onNameClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">
                Company Name
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600"> Name* </div>
                <div className="pt-3 pb-5">
                  <input
                    type="text"
                    name="name"
                    // className="modal-form-input input-color-black"
                    className={
                      (name ==="" 
                        ? "error"
                        : "modal-form-input input-color-black") + " form-control"
                    }
                    placeholder="Company Name"
                    // placeholder="AirBnB"
                    defaultValue={props.companyName}
                    // value={formData.name.valueText}
                    // onChange={($event) => {
                    //   onFormFeildsChange($event, formData, setFormData);
                    // }}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                  {/* {formData.companyName.errorText && (
                  <div className="field-error mt-1">
                    {formData.companyName.errorText}
                  </div>
                )} */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onNameClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSaveCompanyName();
                // props.onNameClose();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyName;
