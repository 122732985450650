import { useState } from "react";
import { LOGIN_ENDPOINT } from "../config/be_api_constants";
import { TOKEN } from "../config/localstorage_keys";
import { clearLocalStorage, setLocalStorage } from "../utils/storage";
import api from "./api";

const login = (username, password) => {
  return api
    .post(LOGIN_ENDPOINT, {
      username,
      password,
    })
    .then((response) => {
      // console.log(response.data.accessToken);
      // localStorage.setItem("token", response.data.accessToken);
      setLocalStorage(TOKEN, response?.data?.data?.accessToken);
      localStorage.setItem("userId", response?.data?.data?.id);
      localStorage.setItem("orgId", response?.data?.data?.orgId);
      localStorage.setItem("orgLogo", response?.data?.data?.orgLogo);
      localStorage.setItem("role", response?.data?.data?.roles);
      localStorage.setItem("log_to_org", "false");

      return response;
    })
    .catch((err) => {
      if (err.response) {
        console.log(err.response);
      }
      console.log(err);
      return err;
    });
};

const logout = () => {
  clearLocalStorage();
};

export { login, logout };
