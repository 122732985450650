import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import toaster from "../../utils/toaster";
import { downloadJobs } from "../../_services/view.service";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import { STATUS_SUCCESS } from "../../config/be_api_constants";
import Loader from "../../components/common/loader";

const SelectForDownload = (props, jobId) => {

  const [download_Applications, setdownload_Applications] = useState([]);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setdownload_Applications(props.downloadOptions);
  }, [props.downloadOptions]);

  const [selectOption, setSelectOption] = useState(null);

  const onManuallyClicked = (option, downloadType) => {
    if (option === "Select Manually") {
      setShowLoader(true);
      if (
        option === "Select Manually" &&
        downloadType === "MANUALLY" &&
        props?.no0fCardSelect.length !== 0
      ) {
        downloadzip(downloadType, props?.no0fCardSelect);
        setShowLoader(false);
        props?.setApiRefresh(true);
        // props?.setShowSelectForDownload(false);
      }
      if (props?.setSelectManuallyClicked) {
        props?.setSelectManuallyClicked(true);
      }

      setShowLoader(false);
    } else {
      props?.setSelectManuallyClicked(false);
      props?.setApiRefresh(false);
    }
  };

  const downloadzip = (downloadType, applicationIds) => {
    setShowLoader(true);
    if (downloadType !== "") {
      downloadJobs(downloadType, applicationIds, props?.jobId)
        .then((res) => {
          setShowLoader(false);
          props?.setApiRefresh(true);
          if (res !== null && res !== "") {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = res;
            a.download = downloadType;
            a.click();
            window.URL.revokeObjectURL(a);
            a.parentNode.removeChild(a);
            var zip = new JSZip();
            zip.folder(a);
            // zip.generateAsync({ type: "blob" }).then(function (content) {
            //   saveAs(content, downloadType);
            // });

            props?.setSelectManuallyClicked(false);
           
          } else {
            props?.setApiRefresh(false);
            toaster("error", "File Not Found!!");
            
          }
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setApiRefresh(false);
          toaster("error", "File Not Found!!");
         
        });
    }
  };

  return (
    <div>
       {showLoader && <Loader />}
      <Modal
        show={props.show}
        onHide={() => props.onBtnClose()}
        backdrop="static"
        fullscreen="md-down"
        centered
        keyboard={false}
        className="lg-dialog-modal rounded-corner-none application-modal"
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100">
            <div className="d-flex justify-content-end">
              <img
                src={icCloseRounded}
                alt="close-icon"
                onClick={() => props.onBtnClose()}
                className="pointer"
              />
            </div>
            <div className="fs-24 color-primary fw-700 ps-3">
              Select application for download
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body p-0 m-0 pt-3">
          <div className="p-0 m-0">
            {download_Applications?.map((option, index) => {
              return (
                <div
                  className={
                    selectOption === option.name
                      ? "download-option pointer"
                      : "rounded-bottom pointer"
                  }
                  key={index}
                  onClick={() => {
                    setSelectOption(option.name);
                    props.onBtnClose();

                    if (option.name === "Flagged but not downloaded") {
                      downloadzip("FLAGGED_NOT_DOWNLOADED", null);
                    }
                    if (option.name === "All Flagged") {
                      downloadzip("ALL_FLAGGED", null);
                    }
                    if (option.name === "All Downloaded") {
                      downloadzip("ALL_DOWNLOADED", null);
                    }
                    if (option.name === "Select All") {
                      downloadzip("ALL", null);
                    }

                    if (option.name === "Select Manually") {
                      onManuallyClicked(option.name, "MANUALLY");

                    }
                  }}
                >
                  {option.name !== "Select Manually" ? <div className="color-primary fs-14 ps-4 py-3">
                    {option.name}  ({option.value})

                  </div> : <div className="color-primary fs-14 ps-4 py-3">
                    {option.name}
                  </div>}
                  {props?.downloadOptions?.length - 1 > index && (
                    <hr className="mt-0 mb-0"></hr>
                  )}
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>

    </div>
  );
};

export default SelectForDownload;
