import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import IcDoneWhite from "../../../../assests/icons/ic_done_white.svg";
import Loader from "../../../../components/common/loader";
import SearchComboBox from "../../../../components/SearchComboBox";
import { MASTER_TYPE } from "../../../../config/be_api_constants";
import { GENERAL_ERROR_MESSAGE } from "../../../../config/messages";
import toaster from "../../../../utils/toaster";
import {
  getLocations,
  getMaster,
  sendRefereesNotification,
} from "../../../../_services/view.service";

const SendNotification = (props) => {
  let { jobId } = useParams();
  const [sendNotification, setSendNotification] = useState(
    props?.sendNotification
  );
  console.log(jobId, "jonid notifi");
  const [ErrorMsg, setErrorMsg] = useState();
  const [experienceCheck, setExperienceCheck] = useState(false);

  console.log("experienceCheck", experienceCheck);

  const [locationOutput, setLocationOutput] = useState(null);
  const [keySkillOutput, setKeySkillOutput] = useState(null);
  const [industriesOutput, setIndustriesOutput] = useState(null);
  const [functionsOutput, setFunctionsOutput] = useState(null);
  const [minYear, setMinYear] = useState();
  const [maxYear, setMaxYear] = useState();
  const [selectedSort, setSelectedSort] = useState("Range");
  const sortArray = ["Range", "Fixed Years"];

  const [keySkill, setKeySkill] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [locations, setLocation] = useState([]);
  const [apiRefresh, setApiRefresh] = useState(false);

  const [minExp, setMinExp] = useState();
  const [maxExp, setMaxExp] = useState();

  const getAllMasterData = async () => {
    // const locations = await getMaster(MASTER_TYPE.LOCATION);
    // let location = locations.map((el) => {
    //   return { name: el.masterValue, description: el.Code };
    // });
    // setLocation(location);

    const skills = await getMaster(MASTER_TYPE.KEYSKILLS);

    const industries = await getMaster(MASTER_TYPE.INDUSTRIES);

    const functions = await getMaster(MASTER_TYPE.FUNCTIONS);
    let funct = functions.map((el) => {
      return { name: el.masterValue, description: el.Code };
    });
    setFunctions(funct);

    if (skills && skills.length > 0) {
      const skillValues = skills?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (skillValues && skillValues.length > 0) {
        setKeySkill(skillValues);
      }
    }

    if (industries && industries.length > 0) {
      const industryValues = industries?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (industryValues && industryValues.length > 0) {
        setIndustries(industryValues);
      }
    }

    if (functions && functions.length > 0) {
      const functionValues = functions?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (functionValues && functionValues.length > 0) {
        setFunctions(functionValues);
      }
    }
    getLocations().then((res) => {
      setLocation(res?.data);
      return res;
    });
  };

  // console.log("props-->", props?.storeData)

  useEffect(() => {
    if (
      props?.storeData !== "" &&
      props?.storeData !== undefined &&
      props?.storeData?.locations !== null
    ) {
      setLocationOutput([props?.storeData?.locations]);
    }
    if (
      props?.storeData !== "" &&
      props?.storeData !== undefined &&
      props?.storeData?.keySkills !== null
    ) {
      setKeySkillOutput([props?.storeData?.keySkills]);
    }
    if (
      props?.storeData !== "" &&
      props?.storeData !== undefined &&
      props?.storeData?.industries !== null
    ) {
      setIndustriesOutput([props?.storeData?.industries]);
    }
    if (
      props?.storeData !== "" &&
      props?.storeData !== undefined &&
      props?.storeData?.functions !== null
    ) {
      setFunctionsOutput([props?.storeData?.functions]);
    }
    if (props?.storeData !== "" && props?.storeData !== undefined) {
      setMinYear(props?.storeData?.minExp);
    }
    if (props?.storeData !== "" && props?.storeData !== undefined) {
      setMaxYear(props?.storeData?.maxExp);
    }
  }, [props?.storeData]);

  // const [locations, setLocations] = useState([
  //   {
  //     name: "India",
  //     description: "India",
  //   },
  //   {
  //     name: "USA",
  //     description: "USA",
  //   },
  //   {
  //     name: "Europe",
  //     description: "Europe",
  //   },
  //   {
  //     name: "Japan",
  //     description: "Japan",
  //   },
  //   {
  //     name: "Korea",
  //     description: "Korea",
  //   },
  // ]);

  // const [specializedOutput, setSpecializedOutput] = useState(null);

  // const [specialized, setSpecialized] = useState([
  //   {
  //     name: "Travel",
  //     description: "Travel",
  //   },
  //   {
  //     name: "Information Technology",
  //     description: "Information Technology",
  //   },
  //   {
  //     name: "Toursim",
  //     description: "Toursim",
  //   },
  //   {
  //     name: "Hospitality",
  //     description: "Hospitality",
  //   },
  // ]);

  useEffect(() => {
    getAllMasterData();
  }, []);
  const [showLoader, setShowLoader] = useState(false);
  const sendNotificationtoReferees = () => {
    setShowLoader(true);
    setApiRefresh(true);
    sendRefereesNotification(
      locationOutput,
      keySkillOutput,
      industriesOutput,
      functionsOutput,
      minYear,
      maxYear,
      jobId
    )
      .then((res) => {
        console.log(res?.data?.message, "res?.statu");
        setShowLoader(false);
        if (res?.status === 200) {
          toaster("success", res?.data?.message);
          // } else {
          //   toaster(
          //     "error",
          //     res?.message ? res?.message : GENERAL_ERROR_MESSAGE
          //   );
        }
        setLocationOutput([]);
        setKeySkillOutput([]);
        setIndustriesOutput([]);
        setFunctionsOutput([]);
        setMinYear();
        setMaxYear();
        setShowLoader(false);
        setApiRefresh(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err?.message ? err?.message : GENERAL_ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    if (apiRefresh === true) {
      sendRefereesNotification();
      setApiRefresh(false);
    }
  }, [apiRefresh]);

  useEffect(() => {
    if (maxYear < minYear) {
      setErrorMsg(
        "Minimum experience cannot be greater than maximum experience"
      );
      setExperienceCheck(false);
    }
    if (maxYear >= minYear) {
      setErrorMsg("");
      setExperienceCheck(true);
    }
  }, [maxYear, minYear]);

  return (
    <div>
      <div>
        {showLoader && <Loader />}
        <div className="">
          <div className="d-flex justify-content-md-between flex-md-row flex-column align-items-center">
            <div className="fs-16 fw-600 color-primary">
              Select the criteria to send the notification
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0 flex-wrap ">
              {sendNotification && (
                <button
                  className="btn-close-action p-2 ps-5 pe-5 send-notification-btns"
                  onClick={() => {
                    setLocationOutput([]);
                    setKeySkillOutput([]);
                    setIndustriesOutput([]);
                    setFunctionsOutput([]);
                    setMinYear("");
                    setMaxYear("");
                  }}
                >
                  Clear All
                </button>
              )}
              <button
                className={
                  sendNotification
                    ? "btn-manage-action p-2 ps-4 pe-4 send-notification-btns"
                    : "btn-notification-disabled p-2 ps-4 pe-4 send-notification-btns"
                }
                onClick={sendNotificationtoReferees}
                // disabled={!sendNotification}
              >
                Send Notification
              </button>
            </div>
          </div>
        </div>
        <hr></hr>
        <div className="d-flex flex-wrap pt-3 mb-3">
          <div className="col-md-6 col-12">
            <div className="action-shadow-card p-3 ps-4 me-md-3">
              <div className="color-primary fs-16 fw-600">Location</div>
              <div className="mt-4 col-9">
                <SearchComboBox
                  inputData={locations}
                  defaultValue={locationOutput}
                  isMultiSelect={true}
                  inputCssClass={"modal-input combo-search-box"}
                  wrapperCssClass={"form-group"}
                  placeholder={"Search or Select"}
                  onSelect={(event) => setLocationOutput(event)}
                  searchListHeight={150}
                  badgeThemeCssClass={"blue"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className="action-shadow-card p-3 ps-4 ms-md-2">
              <div className="color-primary fs-16 fw-600">Key Skills</div>
              <div className="mt-4 col-9">
                <SearchComboBox
                  inputData={keySkill}
                  defaultValue={keySkillOutput}
                  isMultiSelect={true}
                  inputCssClass={"modal-input combo-search-box"}
                  wrapperCssClass={"form-group"}
                  placeholder={"Search or Select"}
                  onSelect={(event) => setKeySkillOutput(event)}
                  searchListHeight={150}
                  badgeThemeCssClass={"blue"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mt-3">
            <div className="action-shadow-card p-3 ps-4 me-md-3">
              <div className="color-primary fs-16 fw-600">Industry</div>
              <div className="mt-4 col-9">
                <SearchComboBox
                  inputData={industries}
                  defaultValue={industriesOutput}
                  isMultiSelect={true}
                  inputCssClass={"modal-input combo-search-box"}
                  wrapperCssClass={"form-group"}
                  placeholder={"Search or Select"}
                  onSelect={(event) => setIndustriesOutput(event)}
                  searchListHeight={150}
                  badgeThemeCssClass={"blue"}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12 mt-3">
            <div className="action-shadow-card p-3 ps-4 ms-md-2">
              <div className="color-primary fs-16 fw-600">Function</div>
              <div className="mt-4 col-9">
                <SearchComboBox
                  inputData={functions}
                  defaultValue={functionsOutput}
                  isMultiSelect={true}
                  inputCssClass={"modal-input combo-search-box"}
                  wrapperCssClass={"form-group"}
                  placeholder={"Search or Select"}
                  onSelect={(event) => setFunctionsOutput(event)}
                  searchListHeight={150}
                  badgeThemeCssClass={"blue"}
                />
              </div>
            </div>
          </div>
          {/* <div className="col-md-6 col-12 mt-3">
            <div className="action-shadow-card p-3 ps-4 me-md-3">
              <div className="color-primary fs-16 fw-600">
                Current Job Title
              </div>
              <div className="mt-4 col-9">
                <SearchComboBox
                  inputData={locations}
                  defaultValue={locationOutput}
                  isMultiSelect={true}
                  inputCssClass={"modal-input combo-search-box"}
                  wrapperCssClass={"form-group"}
                  placeholder={"Search or Select"}
                  onSelect={(event) => setLocationOutput(event)}
                  searchListHeight={150}
                  badgeThemeCssClass={"blue"}
                />
              </div>
            </div>
          </div> */}
          {/* <div className="col-md-6 col-12 mt-3">
            <div className="action-shadow-card p-3 ps-4 ms-md-2">
              <div className="color-primary fs-16 fw-600">Current Employer</div>
              <div className="mt-4 col-9">
                <SearchComboBox
                  inputData={specialized}
                  defaultValue={specializedOutput}
                  isMultiSelect={true}
                  inputCssClass={"modal-input combo-search-box"}
                  wrapperCssClass={"form-group"}
                  placeholder={"Search or Select"}
                  onSelect={(event) => setSpecializedOutput(event)}
                  searchListHeight={150}
                  badgeThemeCssClass={"blue"}
                />
              </div>
            </div>
          </div> */}
          <div className="col-md-6 col-12 mt-3">
            <div className="action-shadow-card p-3 ps-4 me-md-3">
              <div className="color-primary fs-16 fw-600">Experience</div>
              <div className="d-flex justify-content-between align-items-center gap-2 pt-3 pb-3 flex-wrap">
                <div className=" pt-3">
                  <div className="d-flex gap-2">
                    {sortArray?.map((sort, index) => {
                      return (
                        <div
                          className="insight-filter btn-min-width  py-1 d-flex justify-content-center"
                          key={index}
                          onClick={() => setSelectedSort(sort)}
                        >
                          <div
                            className={
                              selectedSort === sort
                                ? "px-1 pt-0 pb-0 bg-black rounded-circle me-1 d-block"
                                : "d-none"
                            }
                          >
                            <img src={IcDoneWhite} alt="whitedone-icon" />
                          </div>
                          <div className="color-primary">{sort}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex gap-2 color-tertiary fs-14 align-items-center  pt-3">
                  <div>
                    <input
                      type="number"
                      className="input-year-btn text-center"
                      placeholder="00"
                      value={minYear}
                      onChange={(event) => setMinYear(event.target.value)}
                      // defaultValue={props?.storeData !== "" && props?.storeData !== undefined ?
                      //   props?.storeData?.minExp : ""
                      // }
                      defaultValue={minYear}
                    />
                    <span className="ps-2">Years</span>
                  </div>
                  <div className="px-2">to</div>
                  <div>
                    <input
                      type="number"
                      value={maxYear}
                      disabled={selectedSort !== "Range"}
                      className="input-year-btn text-center"
                      placeholder="01"
                      onChange={(event) => {
                        setMaxYear(event.target.value);
                      }}
                      defaultValue={maxYear}
                    />
                    <span className="ps-2"> Years</span>
                  </div>
                </div>
                <span className="field-error mt-1">{ErrorMsg}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendNotification;
