import React from "react";
import close from "./../../assests/Vectorblack-cross.svg";
import logo from "../../assests/ic_seikorfull.svg";
import { Link } from "react-router-dom";

const HeaderTwo = ({
  preferencesWelcome,
  onDismissDialogClick,
  onClickCloseDialog,
}) => {
  return (
    <header className="w-100">
      <div className="my-3 w-100">
        <div className="d-flex justify-content-between py-2 px-4">
          <span className="">
            <img src={logo} alt="logo" />
          </span>

          {preferencesWelcome ? (
            <div
              onClick={() => onDismissDialogClick()}
              className="pointer   px-2 py-1 text-black  "
            >
              {" "}
              <img src={close} alt="close-icon" />
            </div>
          ) : (
            <div
              className={
                onClickCloseDialog
                  ? "d-none"
                  : "pointer   text-black px-2 py-1   d-block"
              }
            >
              <Link to="/login">
                <img src={close} alt="close-icon" />
              </Link>
            </div>
          )}
          {onClickCloseDialog ? (
            <div className="pointer    text-black px-2 py-1  d-block">
              <img
                src={close}
                alt="close-icon"
                onClick={() => onClickCloseDialog()}
              />
            </div>
          ) : null}
        </div>
      </div>
      <hr></hr>
    </header>
  );
};

export default HeaderTwo;
