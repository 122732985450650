import React, { useState } from "react";
import amazonLogo from "../assests/amazon_logo.png";
import invitePageLogo from "../assests/join-via-invite-image.png";
import closeIcon from "../assests/icons/ic-invite-pg-close.svg";
import seikorWhiteLogo from "../assests/ic_seikorWhite.svg";
import "../styles/join_via_invite_page.scss";

function JoinViaInvitePage() {
  const [showHidePassword, setShowHidePassword] = useState("password");
  return (
    <div className="d-flex flex-grow-1 container mt-0 pt-0">
      {/* LHS */}
      <div className="flex-grow-1 lhs-bg-picture d-none d-lg-block col-lg-7 position-relative align-items-stretch">
        <div className="lhs-bg-black">
          <img
            alt=""
            style={{ height: "35px" }}
            className="position-absolute invite-seikorlogo-pos"
            src={seikorWhiteLogo}
          />

          {/* <img className="h-100 w-100" src={invitePageLogo} /> */}
        </div>
      </div>
      <div className="position-absolute top-0 end-0 pe-4 pt-4 d-none d-lg-block">
        <img
          alt=""
          className=" pointer p-0 m-0"
          style={{ width: "26px" }}
          src={closeIcon}
        />
      </div>

      {/* RHS */}
      <div className="col-12 col-lg-5  d-flex flex-column position-relative content-margin mt-lg-4 px-3 px-lg-5 mx-lg-4">
        <div className="d-flex justify-content-between ">
          <div className="fw-700 fs-32 mb-3 pe-3 pt-4">
            Join your team at Seikor
          </div>
          <img
            alt=""
            className=" pointer p-0 m-0 d-lg-none"
            style={{ width: "26px" }}
            src={closeIcon}
          />
        </div>

        <div className="d-flex align-items-center mb-5">
          <img style={{ width: "40px" }} src={amazonLogo} alt="" />
          <div className="fs-14 fw-600 ms-2">Amazon</div>
        </div>
        <div className="d-flex flex-column mb-3">
          <label className="fw-600 fs-14 mb-2">Your Name</label>
          <input className="join-invite-input fs-12 px-2 py-2" />
        </div>
        <div className="d-flex flex-column mb-3">
          <label className="fw-600 fs-14 mb-2">Work Email</label>
          <input className="join-invite-input fs-12 px-2 py-2" />
        </div>
        <div className="d-flex flex-column mb-5 position-relative">
          <label className="fw-600 fs-14 mb-2">Create Password</label>
          <input
            type={showHidePassword}
            className="join-invite-input fs-12 px-2 py-2"
          />
          <span
            onClick={() => {
              if (showHidePassword == "password") {
                setShowHidePassword("text");
              } else {
                setShowHidePassword("password");
              }
            }}
            className="fw-600 fs-11 position-absolute pointer password-show-pos"
          >
            Show
          </span>
        </div>
        <div className="mb-5 pb-4">
          <input
            type="button"
            className="w-100 btn-rounded btn-primary fs-16 fw-700"
            value="Create Account"
          />
        </div>
      </div>
    </div>
  );
}

export default JoinViaInvitePage;
