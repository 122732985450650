import React, { useEffect, useState } from "react";
import Vectorleft from "../../../assests/Vectorleft.svg";
import closeRoundedRed from "../../../assests/closeroundedred.svg";
import Header from "../../../components/common/header";
import logoAirbnb from "../../../assests/images/airbnblogo.png";
import checkedLogo from "../../../assests/ic_selected16.svg";
import icCloseBlue from "../../../assests/icons/ic-close-blue.svg";
import minusRounded from "../../../assests/icons/ic_minus.svg";
import plusRounded from "../../../assests/icons/ic_plus.svg";
import icPlusIcon from "../../../assests/icons/ic-plus-icon.svg";
import icDelete from "../../../assests/icons/ic_delete.svg";
import icDeleteRed from "../../../assests/icons/ic-delete-red.svg";
import icclosered from "../../../assests/ic_closered.svg";
import Vectorplusroundedgray from "../../../assests/Vectorplusroundedgray.svg";
import Vectorcompletionflag from "../../../assests/Vectorcompletionflag.svg";
import upDottedArrow from "../../../assests/updottedarrow.png";
import downDottedArrow from "../../../assests/downdottedaarrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import SearchComboBox from "../../../components/SearchComboBox";
import {
  JOB_POSTS_PAGE_ROUTE,
  JOB_TEMPLATES,
} from "../../../config/page_routes_constants";
import {
  isEmpty,
  onFormFeildsChange,
  validateField,
} from "../../../utils/form_validators";
import "../jobs_post_page/job-post-style.scss";
import {
  createJobTemplate,
  getCurrency,
  getJobTemplate,
  getLocations,
  getMaster,
  viewPreviousJobTemp,
} from "../../../_services/view.service";
import {
  MASTER_TYPE,
  STATUS_SUCCESS,
  USER_ID,
} from "../../../config/be_api_constants";
import { getLocalStorage } from "../../../utils/storage";
import { ORG_ID, ORG_LOGO } from "../../../config/localstorage_keys";
import toaster from "../../../utils/toaster";
import { GENERAL_ERROR_MESSAGE } from "../../../config/messages";
import Loader from "../../../components/common/loader";
import { getCompanyDetails } from "../../../_services/company.service";
import { useStoreActions } from "easy-peasy";

const JobCreateTemplatePage = (props) => {
  let { id } = useParams();

  const [locationvalue, setLocationValue] = useState([]);
  const [allTemplateDetails, setAllTemplateDetails] = useState([]);
  const [tempDetails, setTempDetails] = useState();
  const [apiRefresh, setapiRefresh] = useState(false);
  const [isminSalaryChecked, setIsminSalaryChecked] = useState(false);
  const [isFixedAmtChecked, setIsFixedAmtChecked] = useState(false);
  const [salarycheck, setSalaryCheck] = useState();
  const [isRangeChecked, setIsRangeChecked] = useState(true);
  const [referralBonusType, setReferralBonusType] = useState("P");
  const [JobCareerPath, setJobCareerPath] = useState([]);
  const [jobEveryDay, setjobEveryDay] = useState([]);
  const [jobFields, setjobFields] = useState([]);
  const [refCriteriaOther, setrefCriteriaOther] = useState([]);
  const [screeningProcess, setScreeningProcess] = useState([]);
  const [jobAdvantage, setjobAdvantage] = useState([]);
  const Character_Limit = 250;
  const Character = 450;
  const [isMinimumExpChecked, setIsMinimumExpChecked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [total, setTotal] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [salaryId, setSalaryId] = useState("Indian Rupee");
  const [reportingStructures, setReportingStructures] = useState([]);
  const [referralAmount, setReferralAmount] = useState();
  const [referralBouns, setReferralBouns] = useState();
  const [Maxsalary_Error, setMaxSalary_Error] = useState("");
  const [Minsalary_Error, setMinSalary_Error] = useState("");
  const [value, setValue] = useState();
  const [reportStructure1, setreportStructure1] = useState([]);
  const [reportStructure2, setreportStructure2] = useState();
  const [reportingrole, SetReportingRole] = useState();
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const [departmentName, setDepartmentName] = useState();
  const [maxExperience, setMaxExperience] = useState();
  const [minExperience, setMinExperience] = useState();
  const [companyData, setCompanyData] = useState([]);
  const [referralAmount1, setReferralAmount1] = useState();

  const fetchDetails = () => {
    setShowLoader(true);
    getCompanyDetails()
      .then((res) => {
        // setApiRefresh(true);
        if (res?.data?.status == STATUS_SUCCESS) {
          const userId = getLocalStorage(USER_ID);
          if (userId) {
            saveCandidateDetails(userId);
          }
          let companyData = res?.data?.data[0];
          setCompanyData(companyData);
          // setViewDetails(res?.data?.data[0]);
          // setNews(res?.data?.data[0]?.newsLinks);
          // setCompnaypicture(res?.data?.data[0]?.potentialImagesUrl);
          setShowLoader(false);
          // toaster(
          //   "success",
          //   "Company data is available"
          // );
        } else {
          toaster("error", "Company data is not available");
          // setApiRefresh(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        // setApiRefresh(false);
        toaster("error", err);
      });
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  const getTemplateDetails = () => {
    // setShowLoader(true);
    getJobTemplate(1, 10)
      .then((res) => {
        setAllTemplateDetails(res?.data?.data);
        if (res?.data.status === STATUS_SUCCESS) {
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(true);
      });
  };

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    templateTitle: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    templateDescription: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    jobTitle: {
      valueText: "",
      initial: "",
      errorText: "",
      // check: ["required"],
    },
    departmentName: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    locationName: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    reportingType: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    jobType: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    numPosition: {
      valueText: "1",
      initial: "1",
      errorText: "",
    },
    reportingRole: {
      valueText: "",
      initial: "",
      errorText: "",
      // check: [""],
    },
    minExpRequiredMonths: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    maxExpRequiredMonths: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    isRange: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    salCurrencyId: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    minSalary: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    maxSalary: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    salaryType: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    referralBonusType: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    referralBonus: {
      valueText: "",
      initial: "",
      errorText: "",
    },
    screeningProcess: {
      valueText: [],
      initial: "",
      errorText: "",
    },
    skillsRequired: {
      valueText: [],
      initial: "",
      errorText: "",
    },
    jobAdvantage: {
      valueText: [],
      initial: "",
      errorText: "",
    },
    jobEveryDayTask: {
      valueText: [],
      initial: "",
      errorText: "",
    },
    jobPower: {
      valueText: [],
      initial: "",
      errorText: "",
    },
    jobCareerPath: {
      valueText: [],
      initial: "",
      errorText: "",
    },
    additionalRemarks: {
      valueText: "",
      initial: "",
      errorText: "",
      // check: [""],
    },
    reportingStructure: {
      //valueText: ["Project Manager"],
      valueText: [],
      initial: "",
      errorText: "",
    },
    // reportingRole: {
    //   valueText: "",
    //   // valueText: [],
    //   initial: "",
    //   errorText: "",
    // },
    referralCriteriaCompany: {
      valueText: [],
      initial: "",
      errorText: "",
      // check: [""],
    },
    referralCriteriaIndustry: {
      valueText: [],
      initial: "",
      errorText: "",
      // check: [""],
    },
    referralCriteriaOther: {
      valueText: [],
      initial: "",
      errorText: "",
      // check: [""],
    },
    questionText: {
      valueText: [],
      initial: "",
      errorText: "",
      // check: [""],
    },
    // questions: [
    //   {
    //     questionText: "",
    //     isMandatory: "",
    //     action: "",
    //     quesType: "",
    //   },
    // ],
  });

  const [viewPreviousJobs, setViewPreviousJobs] = useState([]);
  const [tempId, settempId] = useState("");
  let reportingStructureArr = [];

  const current = new Date();
  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const currentDate = `${dd}-${mm}-${yyyy}`;
  const [jobPowers, setjobPowers] = useState([]);
  const PreviousJobs = () => {
    if (id !== "") {
      viewPreviousJobTemp(id)?.then((res) => {
        // setShowLoader(true)
        setViewPreviousJobs(res?.data.data);

        // if (res?.data?.data === STATUS_SUCCESS)

        if (res?.data.status === STATUS_SUCCESS) {
          // setShowLoader(false)
          // setCompanyId(res?.data?.data?.companyProfile?.companyId)
          setTempDetails(res?.data?.data);
          settempId(res?.data?.data?.templateId);
          setReferralBonusType(res?.data?.data?.referralBonusType);
          setJobCareerPath(res?.data?.data.jobCareerPath);
          setjobFields(res?.data?.data?.jobAdvantage);
          setJobQuestionsFields(res?.data?.data?.questions);
          setreferralCriteriaOtherFields(
            res?.data?.data?.referralCriteriaOther
          );
          setrefCriteriaOther(res?.data?.data?.referralCriteriaOther);
          setScreeningProcessFields(res?.data?.data?.screeningProcess);
          setJobCareerPathFields(res?.data?.data?.jobCareerPath);
          setJobEveryDayTaskFields(res?.data?.data?.jobEveryDayTask);
          setScreeningProcess(res?.data?.data?.screeningProcess);
          setjobAdvantageFields(res?.data?.data?.jobAdvantage);
          setjobAdvantage(res?.data?.data?.jobAdvantage);
          setSalary_Type(res?.data?.data?.salaryType);
          setjobEveryDay(res?.data?.data?.jobEveryDayTask);
          setJobSkills(res?.data?.data?.skillsRequired);
          setjobPowers(res?.data?.data?.jobPower);
          // setReportingStructures(res?.data?.data?.reportingStructure.toString());
          setRefCriteriaIndustry(res?.data?.data?.referralCriteriaIndustry);
          setRefCriteriaCompany(res?.data?.data?.referralCriteriaCompany);
          setsalCurrencyPop(res?.data?.data?.salCurrency);
          setReportingStructures(res?.data?.data?.reportingStructure);
          setReferralAmount(res?.data?.data?.referralAmount);
          setReferralBouns(res?.data?.data?.referralBonus);
          setMaxExperience(res?.data?.data?.maxExpRequiredMonths);
          setMinExperience(res?.data?.data?.minExpRequiredMonths);
          setJobDepartmentOutputStatic(res?.data?.data?.departmentName);
          setJobLocationOutputStatic(res?.data?.data?.locationName);
          const populatedFormData = { ...formData };

          populatedFormData.templateTitle.valueText =
            res?.data?.data?.templateTitle;
          populatedFormData.salaryType.valueText = res?.data?.data?.salaryType;

          populatedFormData.templateDescription.valueText =
            res?.data?.data?.templateDescription;
          populatedFormData.referralBonusType.valueText =
            res?.data?.data.referralBonusType;
          populatedFormData.jobTitle.valueText = res?.data?.data?.jobTitle;
          populatedFormData.departmentName.valueText = [
            res?.data?.data?.departmentName,
          ];
          populatedFormData.locationName.valueText = [
            res?.data?.data?.locationName,
          ];
          // populatedFormData.reportingStructure.valueText = [
          //   res?.data?.data?.reportingStructure,
          // ];
          populatedFormData.reportingRole.valueText =
            res?.data?.data?.reportingRole;
          populatedFormData.reportingType.valueText =
            res?.data?.data?.reportingType;
          populatedFormData.isRange.valueText = res?.data?.data?.isRange;
          populatedFormData.referralBonus.valueText =
            res?.data?.data?.referralBonus;
          populatedFormData.additionalRemarks.valueText =
            res?.data?.data?.additionalRemarks;
          populatedFormData.jobType.valueText = res?.data?.data?.jobType;
          populatedFormData.numPosition.valueText =
            res?.data?.data?.numPosition;
          populatedFormData.minSalary.valueText = res?.data?.data?.minSalary;
          populatedFormData.maxSalary.valueText = res?.data?.data?.maxSalary;
          populatedFormData.minExpRequiredMonths.valueText =
            res?.data?.data?.minExpRequiredMonths;
          populatedFormData.maxExpRequiredMonths.valueText =
            res?.data?.data?.maxExpRequiredMonths;
          populatedFormData.jobAdvantage.valueText = [
            res?.data?.data.jobAdvantage,
          ];
          populatedFormData.jobEveryDayTask.valueText = [
            res?.data?.data.jobEveryDayTask,
          ];
          populatedFormData.jobPower.valueText = res?.data?.data.jobPower;
          populatedFormData.skillsRequired.valueText =
            res?.data?.data.skillsRequired;
          populatedFormData.referralCriteriaIndustry.valueText =
            res?.data?.data.referralCriteriaIndustry;
          populatedFormData.referralCriteriaOther.valueText =
            res?.data?.data.referralCriteriaOther;
          populatedFormData.referralCriteriaCompany.valueText =
            res?.data?.data.referralCriteriaCompany;
          setIsRangeChecked(res?.data?.data?.isRange);
          setFormData(populatedFormData);
        }
        // salCurrency.map((output) => {
        //   if (res?.data?.data?.salCurrency === output.code) {
        //     setSetsalCurrencyId(output.id);
        //   }
        // });
        // // if (res?.data?.data?.referralBonusType === "F") {
        //   setIsFixedAmtChecked(true);
        // } else {
        //   setIsFixedAmtChecked(false);
        // }
        //   if (res?.data?.data?.referralBonusType === "P") {
        //     setIsminSalaryChecked(true);
        //   } else {
        //     setIsminSalaryChecked(false);
        //   }
      });
    }
  };
  const [repotvalue, setReportvalue] = useState();
  useEffect(() => {}, []);
  useEffect(() => {
    getTemplateDetails();
    PreviousJobs();

    

    const a = reportingStructures.toString().split(",");
    setReportvalue(a);
  }, []);

  useEffect(() => {

    if (referralBonusType === "P") {
      setIsminSalaryChecked(true);
      setRefBonusType("P");
      setSalaryCheck("P");
     
    } else {
      setIsFixedAmtChecked(true);
      setSalaryCheck("F");
      setRefBonusType("F");
    }
    if (referralBonusType === "P") {
      setReferralAmount1(
        Math.round((referralAmount / 100) * formData?.minSalary?.valueText)
      );
      
    } else {
      setReferralAmount1(referralBouns);
    }

    if (formData?.isRange?.valueText === true) {
      //  isRangeChecked=== formData?.isRange?.valueText) {

      setCheck("Range");
      setIsRangeChecked(true);
      setIsMinimumExpChecked(false);
    }
    if (formData?.isRange?.valueText === false) {
      setCheck("Experience");
      setIsRangeChecked(false);
      setIsMinimumExpChecked(true);
    }
    console.log(formData?.isRange?.valueText, "rangenow", isRangeChecked);
  }, [
    formData?.isRange?.valueText,
    referralBonusType,
    referralAmount,
    jobPowers,
    formData?.reportingType?.valueText,
  ]);

  const [bonusCal, setBonusCal] = useState("");
  const [calMinSalPer, setCalMinSalPer] = useState("");

  const [bonusTotal, setBonusTotal] = useState();
  const [input, setInput] = useState();
  const [inputs, setInputs] = useState();
  const [Power, setPower] = useState([]);
  const [structureArray, setStructureArray] = useState([]);
  // const onInputChange = (name, value) => {
  //   let event = { target: { value: "", name: "" } };
  //   event.target.value = value;
  //   event.target.name = name;
  //   if (name === "reportingStructure") {
  //     setInputs(event.target.value);
  //     // setInputs(prevState => ([ ...prevState, [value] ]));
  //   }
  //   if (name === "reportingStructure1") {
  //     setInput(event.target.value);
  //     // setInputs(prevState => ([ ...prevState, [value] ]));
  //   }

  //   if (name === "jobPower") {
  //     setPower([event.target.value]);
  //   }
  //   const structureArray1 = [inputs, input];
  //   setStructureArray(structureArray1);
  //   if (name === "minSalary") {
  //     setCalMinSalPer(event.target.value);
  //   }
  //   if (name === "referralBonus") {
  //     setBonusCal(event.target.value);
  //   }
  //   // if (isminSalaryChecked === true) {
  //   //   setBonusTotal(Math.round((bonusCal / 100) * calMinSalPer));
  //   // }
  //   // if (isFixedAmtChecked === true) {
  //   //   setBonusTotal(formData?.referralBonus?.valueText);
  //   // }
  //   if (name === "salCurrency") {
  //     setSalaryId(value);
  //   }
  //   if (name === "salaryType") {
  //     setSalary_Type(value);
  //   }

  //   onFormFeildsChange(event, formData, setFormData);
  // };

  const onInputChange = (name, value) => {
    // if ("monthOfContract" === name) {
    //   if (formData?.monthOfContract?.valueText !== "") {
    //     setcontract_Error("Please fill the contract Month");
    //   } else {
    //     setcontract_Error("");
    //   }
    // }

    setValue(formData?.referralBonus?.valueText);

    let event = { target: { value: "", name: "" } };
    event.target.value = value;
    event.target.name = name;

    if (name === "minExpRequiredMonths") {
      // alert("min")
      if (!value) {
        setMinExperience("");
      } else {
        console.log(minExperience, maxExperience, "min");
        let number = Number(value);
        if (number < maxExperience) {
          setMaxExperience(number);
          setMinExperience(number);
        } else {
          setMinExperience(number);
        }
      }
    }

    if (name === "maxExpRequiredMonths") {
      // alert("joo")
      if (!value) {
        setMaxExperience("");
        // setMinExperience("");
      } else {
        let number = Number(value);
        console.log(minExperience, maxExperience);
        if (number < minExperience) {
          setMinExperience(number);
          setMaxExperience(number);
        } else {
          setMaxExperience(number);
        }
      }
    }
    if (name === "departmentName") {
      setJobDepartmentOutputStatic(event.target.value);
    }
    if (name === "locationName") {
      setJobLocationOutputStatic(event.target.value);
    }

    if (name === "reportingStructure") {
      setInputs(event.target.value);
    }
    if (name === "reportingStructure1") {
      setInput(event.target.value);
    }

    if (name === "salCurrency") {
      setSalaryId(event.target.value);
    }

    if (name === "salaryType") {
      setSalary_Type(event.target.value);
    }

    if (name === "jobPower") {
      setjobPowers([event.target.value]);
    }
    const structureArray1 = [inputs, input];
    setStructureArray(structureArray1);
    if (name === "minSalary") {
      setCalMinSalPer(event.target.value);
    }
    if (name === "referralBonus") {
      setBonusCal(event.target.value);
      setReferralAmount(event.target.value);
    }

    if (name === "reportingStructure") {
      setreportStructure1([event.target.value]);
    }
    if (name === "reportingRole") {
      // setreportStructure2([reportStructure1, event.target.value])
      SetReportingRole(event.target.value);

      // setReportingStructures(reportStructure1,  event.target.value);
    }

    if (name === "maxSalary") {
      if (formData?.maxSalary?.valueText < formData?.minSalary?.valueText) {
        setMaxSalary_Error("  Max Salary is greater than Min Salary");
      } else {
        setMaxSalary_Error("");
      }
    }
    if (name === "minSalary") {
      if (formData?.minSalary?.valueText < formData?.maxSalary?.valueText) {
        setMinSalary_Error("  Min Salary is less than Max Salary");
      } else {
        setMinSalary_Error("");
      }
    }
    onFormFeildsChange(event, formData, setFormData);
  };
  var reporting = [];
  const onInputReportingStructure = (name, value) => {
    if (name === "reportingStructure1") {
      setreportStructure1(value);
    }
    if (name === "reportingStructure2") {
      setreportStructure2([reportStructure1, value]);
    }
    console.log("monthOfContract", name, value);
  };
  const onInputChangeForReportingRole1 = (name, value) => {
    if (name === "reportingStructure") {
      reporting.push(value);
      console.log("reporting", reporting);
    }

    let event = { target: { value: "", name: "" } };

    event.target.value = value;
    event.target.name = name;
    onFormFeildsChange(event, formData, setFormData);
  };

  const onInputChangeForReportingRole2 = (name, value) => {
    if (name === "reportingStructure") {
      reporting.push(value);
      console.log("reporting", reporting);
    }

    let event = { target: { value: "", name: "" } };

    event.target.value = value;
    event.target.name = name;
    onFormFeildsChange(event, formData, setFormData);
  };
  const onInput = (name, value) => {
    reportingStructureArr.push(formData?.reportingStructure?.valueText);
    let event = { target: { value: "", name: "" } };
    event.target.value = value;
    event.target.name = name;
  };

  const onChangeIsFixedAmt = () => {
    // setIsFixedAmtChecked(!isFixedAmtChecked);
    // if (isFixedAmtChecked === true) {
    //   setIsminSalaryChecked(false);
    setRefBonusType("F");
    // }
  };

  const CurrencyIdSet = (value) => {
    salCurrency.map((check) => {
      if (check.name === value) setSetsalCurrencyId(check.id);
    });
    setFormData({ ...formData, salCurrencyId: setSetsalCurrencyId });
  };
  const onChangeIsminSalary = () => {
    // setIsminSalaryChecked(!isminSalaryChecked);
    // if (isminSalaryChecked === true) {
    //   setIsFixedAmtChecked(false);
    setRefBonusType("P");
    // }
  };

  // const onChangeIsminSalary = () => {
  //   setIsminSalaryChecked(!isminSalaryChecked);
  //   if (isminSalaryChecked === true) {
  //     setIsFixedAmtChecked(false);
  //     setReferralBonusType("F");
  //     let BonusType = "F";
  //     setFormData({
  //       ...formData,
  //       referralBonusType: BonusType,
  //     });
  //   } else {
  //     setReferralBonusType("P");
  //   }
  // };

  console.log("formData=====================>", formData);

  const [showLoader, setShowLoader] = useState(false);
  const [check, setCheck] = useState();
  const [keySkill, setKeySkill] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [jobReportingType, setJobReportingType] = useState([]);
  const [company, setCompany] = useState([]);
  const [locations, setLocation] = useState([]);
  const [jobtype, setJobtype] = useState([]);
  const [salaryTypeOptions, setSalaryTypeOptions] = useState([]);
  const [salCurrency, setsalCurrency] = useState([]);
  const [salCurrencyPop, setsalCurrencyPop] = useState("");
  const [setsalCurrencyId, setSetsalCurrencyId] = useState("");
  const [showContractField, setShowContractField] = useState(false);
  const [salary_Type, setSalary_Type] = useState("ANNUAL");
  const [refBonusType, setRefBonusType] = useState("");

  const [refCriteriaIndustry, setRefCriteriaIndustry] = useState([]);
  const [refCriteriaCompany, setRefCriteriaCompany] = useState([]);
  const [jobSkills, setJobSkills] = useState([]);

  const getAllMasterData = async () => {
    const reportingTypes = await getMaster(MASTER_TYPE.WORKMODE);
    let reportingType = reportingTypes.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setJobReportingType(reportingType);
    const companies = await getMaster(MASTER_TYPE.COMPANY);
    let company = companies.map((el) => {
      return { name: el.masterValue, description: el.Code };
    });
    setCompany(company);
    const functions = await getMaster(MASTER_TYPE.FUNCTIONS);
    let funct = functions.map((el) => {
      return { name: el.masterValue, description: el.Code };
    });
    setFunctions(funct);
    const jobTypes = await getMaster(MASTER_TYPE.JOBTYPES);
    let jobType = jobTypes.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setJobtype(jobType);

    const skills = await getMaster(MASTER_TYPE.KEYSKILLS);
    const industries = await getMaster(MASTER_TYPE.INDUSTRIES);
    const salaryTypes = await getMaster(MASTER_TYPE.SALARYTYPE);
    // const location = await getMaster(MASTER_TYPE.LOCATION);

    if (salaryTypes && salaryTypes.length > 0) {
      const salaryTypeValue = salaryTypes?.map(
        (item) => item.masterValue && { name: item.masterCode }
      );
      if (salaryTypeValue && salaryTypeValue.length > 0) {
        setSalaryTypeOptions(salaryTypeValue);
      }
    }
    if (skills && skills.length > 0) {
      const skillValues = skills?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (skillValues && skillValues.length > 0) {
        setKeySkill(skillValues);
      }
    }
    if (industries && industries.length > 0) {
      const industryValues = industries?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (industryValues && industryValues.length > 0) {
        setIndustries(industryValues);
      }
    }
    if (functions && functions.length > 0) {
      const functionValues = functions?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (functionValues && functionValues.length > 0) {
        setFunctions(functionValues);
        setJobDepartment(functionValues);
      }
    }

    // let loc = location.map((el) => {
    //   return { name: el.masterValue, description: el.Code };
    // });
    // setLocation(loc);
    getLocations().then((res) => {
      setLocation(res?.data);
      return res;
    });

    getCurrency().then((res) => {
      setsalCurrency(res);
      return res;
    });
  };

  useEffect(() => {
    getAllMasterData();
  }, []);

  const [jobDepartmentOutput, setJobDepartmentOutput] = useState([]);
  const [jobDepartmentOutputStatic, setJobDepartmentOutputStatic] = useState(
    []
  );

  const [jobDepartment, setJobDepartment] = useState([
    {
      name: "Engineering",
      description: "Engineering",
    },
    {
      name: "Design",
      description: "Design",
    },
    {
      name: "Product",
      description: "Product",
    },
    {
      name: "Hospitality",
      description: "Hospitality",
    },
    {
      name: "Operations",
      description: "Operations",
    },
    {
      name: "Sales",
      description: "Sales",
    },
    {
      name: "Marketing",
      description: "Marketing",
    },
  ]);

  const [jobLocationOutput, setJobLocationOutput] = useState([]);
  const [jobLocationOutputStatic, setJobLocationOutputStatic] = useState([]);

  const [jobSuperPowerOutput, setJobSuperPowerOutput] = useState([]);

  const [jobSuperPower, setJobSuperPower] = useState([
    {
      name: "Critical Thinking",
      description: "Critical Thinking",
    },
    {
      name: "User Interviews",
      description: "User Interviews",
    },
    {
      name: "Usability Study",
      description: "Usability Study",
    },
    {
      name: "Prototyping",
      description: "Prototyping",
    },
    {
      name: "User Research",
      description: "User Research",
    },
    {
      name: "Branding",
      description: "Branding",
    },
    {
      name: "Design Thinking",
      description: "Design Thinking",
    },
  ]);

  const [referralCriteriaOtherFields, setreferralCriteriaOtherFields] =
    useState([""]);
  const [jobCareerpathFields, setJobCareerPathFields] = useState([
    ""
  ]);
  const [jobAdvantageFields, setjobAdvantageFields] = useState([""]);

  const [jobEveryDayTaskFields, setJobEveryDayTaskFields] = useState([""]);
  const [screeningProcessFields, setScreeningProcessFields] = useState([""]);

  let initialJobQuestionsFieldsState = {
    action: "ADD",
    isMandatory: "N",
    quesType: "",
    questionText: "",
  };
  const [jobQuestionsFields, setJobQuestionsFields] = useState([
    initialJobQuestionsFieldsState,
  ]);

  const [numberOfRolesInput, setNumberOfRolesInput] = useState(1);
  if (numberOfRolesInput <= 0) {
    setNumberOfRolesInput(1);
  }
  const onOfRolesIncrement = () =>
    setNumberOfRolesInput(numberOfRolesInput + 1);
  const onOfRolesDecrement = () =>
    setNumberOfRolesInput(numberOfRolesInput - 1);
  useEffect(() => {
    if (screeningProcessFields === "" || screeningProcessFields === null) {
      setScreeningProcessFields([{ value: "" }]);
      setScreeningProcess([]);
    }
    if (jobCareerpathFields === "" || jobCareerpathFields === null) {
      setJobCareerPathFields([{ value: "" }]);
      setJobCareerPath([]);
    }
    if (jobAdvantageFields === "" || jobAdvantageFields === null) {
      setjobAdvantageFields([{ value: "" }]);
      setjobAdvantage([]);
    }
    if (jobEveryDayTaskFields === "" || jobEveryDayTaskFields === null) {
      setJobEveryDayTaskFields([{ value: "" }]);
      setjobEveryDay([]);
    }
    if (jobQuestionsFields === "" || jobQuestionsFields === null) {
      setJobQuestionsFields([initialJobQuestionsFieldsState]);
      // setJobQuestionsFields([]);
    }
    if (
      referralCriteriaOtherFields === "" ||
      referralCriteriaOtherFields === null
    ) {
      setreferralCriteriaOtherFields([{ value: "" }]);
      setrefCriteriaOther([]);
    }
    //  if(jobCareerpathFields === "" || jobCareerpathFields === null){
    //   setJobCareerPathFields([{value:""}])
    //   setJobCareerPath([])
    // }
    // const a = reportingStructures.toString().split(",");
    // setReportvalue(a);
  }, [
    jobReportingType,
    screeningProcessFields,
    jobCareerpathFields,
    jobEveryDayTaskFields,
    jobAdvantageFields,
    jobQuestionsFields,
    referralCriteriaOtherFields,
  ]);
  function AddscreeningProcessField() {
    const addscreeningProcess = [...screeningProcessFields];
    addscreeningProcess.push("");
    setScreeningProcessFields(addscreeningProcess);
  }
  // function AddscreeningProcessField() {
  //   const addscreeningProcess = [...screeningProcessFields];

  //   // if (screeningProcessFields !== [{ value: "" }]) {
  //     addscreeningProcess.push({ screeningProcessFields: "" });
  //     setScreeningProcessFields(addscreeningProcess);

  // }

  // function ScreeningProcessOutput(i, e) {
  //   if (e.target.value.length >= Character_Limit) {
  //     setTotal(e.target.value.length - Character_Limit);
  //   }
  //   const addProcess = [...screeningProcess];
  //   addProcess[i] = e.target.value.length;

  //   setScreeningProcess(addProcess);
  //   const addscreeningProcess = [...screeningProcessFields];
  //   addscreeningProcess[i] = e.target.value;
  //   setScreeningProcessFields(addscreeningProcess);

  //   setFormData({
  //     ...formData,
  //     screeningProcess: addscreeningProcess,
  //   });
  // }

  function ScreeningProcessOutput(i, e) {
    const addscreeningProcess = [...screeningProcessFields];
    addscreeningProcess[i] = e.target.value;
    setScreeningProcessFields(addscreeningProcess);
    setFormData({
      ...formData,
      screeningProcess: addscreeningProcess,
    });
  }

  function removeScreeningProcess(data, index) {
    const rows = [...screeningProcessFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    console.log("kunal", itemIndex);
    rows.splice(itemIndex, 1);
    setScreeningProcessFields(rows);
  }

   function AddCareerPathFields() {
    const addCareerPathFields = [...jobCareerpathFields];
    addCareerPathFields.push("");
    setJobCareerPathFields(addCareerPathFields);
  }

  function jobCareerpathOutput(i, e) {
    const addCareerPathFields = [...jobCareerpathFields];
    addCareerPathFields[i] = e.target.value;
    setJobCareerPathFields(addCareerPathFields);
    setFormData({
      ...formData,
      jobCareerPath: addCareerPathFields,
    });
  }
  function removeCareerpathField(data, index) {
    const rows = [...jobCareerpathFields];
    let itemIndex = rows.findIndex((item, index) => item === data);

    rows.splice(itemIndex, 1);
    setJobCareerPathFields(rows);
  }

  function AddjobAdvantageFields() {
    const addjobAdvantageFields = [...jobAdvantageFields];
    addjobAdvantageFields.push("");
    setjobAdvantageFields(addjobAdvantageFields);
  }

  // function jobAdvantageFieldsOutput(i, e) {
  //   if (e.target.value.length >= Character_Limit) {
  //     setTotal(e.target.value.length - Character_Limit);
  //   }
  //   const addAdvantage = [...jobAdvantage];
  //   addAdvantage[i] = e.target.value.length;

  //   setjobAdvantage(addAdvantage);

  //   const addjobAdvantageFields = [...jobAdvantageFields];
  //   addjobAdvantageFields[i] = e.target.value;
  //   setjobAdvantageFields(addjobAdvantageFields);
  //   setFormData({
  //     ...formData,
  //     jobAdvantage: addjobAdvantageFields,
  //   });
  // }

  function jobAdvantageFieldsOutput(i, e) {
    const addAdvantage = [...jobAdvantageFields];
    addAdvantage[i] = e.target.value;
    setjobAdvantageFields(addAdvantage);
    setFormData({
      ...formData,
      jobAdvantage: addAdvantage,
    });
  }
  function removejobAdvantage(data, index) {
    const rows = [...jobAdvantageFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    rows.splice(itemIndex, 1);
    setjobAdvantageFields(rows);
  }

  function AddjobEveryDayTaskFields() {
    const addjobEveryDayTaskFields = [...jobEveryDayTaskFields];
    addjobEveryDayTaskFields.push("");
    setJobEveryDayTaskFields(addjobEveryDayTaskFields);
  }

  // function jobEveryDayTaskOutput(i, e) {
  //   if (e.target.value.length >= Character_Limit) {
  //     setTotal(e.target.value.length - Character_Limit);
  //   }
  //   const addEveryDay = [...jobEveryDay];
  //   addEveryDay[i] = e.target.value.length;

  //   setjobEveryDay(addEveryDay);

  //   const addjobEveryDayTaskFields = [...jobEveryDayTaskFields];
  //   addjobEveryDayTaskFields[i] = e.target.value;
  //   setJobEveryDayTaskFields(addjobEveryDayTaskFields);
  //   setFormData({
  //     ...formData,
  //     jobEveryDayTask: addjobEveryDayTaskFields,
  //   });
  // }

  function jobEveryDayTaskOutput(i, e) {
    const addEveryDay = [...jobEveryDayTaskFields];
    addEveryDay[i] = e.target.value;
    setJobEveryDayTaskFields(addEveryDay);
    setFormData({
      ...formData,
      jobEveryDayTask: addEveryDay,
    });
  }
  const removeEveryDayTaskField = (data, index) => {
    const rows = [...jobEveryDayTaskFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    rows.splice(itemIndex, 1);
    setJobEveryDayTaskFields(rows);
  };
  const removejobEveryDayfields = (i) => {
    const addjobEveryDayTaskFields = [...jobEveryDayTaskFields];
    addjobEveryDayTaskFields.splice(i, 1);
    setJobEveryDayTaskFields(addjobEveryDayTaskFields);
  };

  function AddreferralCriteriaOtherFields() {
    const addreferralCriteriaOther = [...referralCriteriaOtherFields];
    addreferralCriteriaOther.push("");
    setreferralCriteriaOtherFields(addreferralCriteriaOther);
  }

  function referralCriteriaOtherOutput(i, e) {
    const addreferralCriteriaOther = [...referralCriteriaOtherFields];
    addreferralCriteriaOther[i] = e.target.value;
    setreferralCriteriaOtherFields(addreferralCriteriaOther);
    setFormData({
      ...formData,
      referralCriteriaOther: addreferralCriteriaOther,
    });
  }

  function removeReferralCriteriaOther(data, index) {
    const rows = [...referralCriteriaOtherFields];
    let itemIndex = rows.findIndex((item, index) => item === data);
    console.log("eee", itemIndex);
    rows.splice(itemIndex, 1);
    setreferralCriteriaOtherFields(rows);
  }
  function AddQuestionsFields(i) {
    const addQuestionsFields = {
      action: "ADD",
      isMandatory: "N",
      quesType: "F_T",
      questionId: i + 1,
      questionText: "",
    };
    setJobQuestionsFields([...jobQuestionsFields, addQuestionsFields]);
  }


  function removeQuestionsFields(i) {
    const addQuestionsFields = [...jobQuestionsFields];
    addQuestionsFields.splice(i, 1);
    setJobQuestionsFields(addQuestionsFields);
  }

  const onDelete = (data,i) => {
    let addQuestionsFields = [...jobQuestionsFields];
    addQuestionsFields.splice(i, 1);
      setJobQuestionsFields(addQuestionsFields);

  };

  // const selectRange = () => {
  //   setIsRangeChecked(!isRangeChecked);
  //   setFormData({ ...formData, isRange: isRangeChecked });
  // };
  const onChangeExprience = () => {
    // if (isMinimumExpChecked === true) {
    setIsRangeChecked(false);
    setIsMinimumExpChecked(true);
    // console.log("isexpvalue",isRangeChecked,isMinimumExpChecked);
    // setMaxExperience("");
    // }
  };

  const onChangeRange = () => {
    // if (isRangeChecked === true) {
    setIsRangeChecked(true);
    setIsMinimumExpChecked(false);

    // console.log("israngevalue",isRangeChecked,isMinimumExpChecked);

    // }
    // setIsRangeChecked(!isRangeChecked);
  };
  useEffect(() => {
    setCompanyLogo(orgLogo);
    getAllMasterData();
    createJobTemplate();
    // salCurrency.map((output) => {
    //   if (salCurrencyPop === output.code) {
    //     setSetsalCurrencyId(output.id);
    //   }
    // });
  }, []);
  useEffect(() => {
    console.log("jobQuestionsFields", jobQuestionsFields);
  }, [jobQuestionsFields]);
  const userId = getLocalStorage(USER_ID);
  const orgLogo = getLocalStorage(ORG_LOGO);
  const orgId = getLocalStorage(ORG_ID);

  const saveTemplateData = () => {
    const companyId = orgId;
    const templateId = tempId !== "" ? tempId : "";
    const templateTitle = formData?.templateTitle?.valueText;
    const templateDescription = formData?.templateDescription?.valueText;
    const businessId = orgId;
    const businessUserId = userId;

    const additionalRemarks = formData?.additionalRemarks?.valueText;
    const jobTitle = formData?.jobTitle?.valueText;
    const departmentName =
      jobDepartmentOutputStatic?.toString() !== ""
        ? jobDepartmentOutputStatic?.toString()
        : jobDepartmentOutput.toString();
    // formData?.departmentName?.valueText?.toString() !== ""
    //   ? formData?.departmentName?.valueText?.toString()
    //   : jobDepartmentOutput.toString();
    const jobType = formData?.jobType?.valueText;
    const reportingType = formData?.reportingType?.valueText;
    const locationId =
      jobLocationOutputStatic?.toString() !== ""
        ? jobLocationOutputStatic?.toString()
        : locationvalue.toString();
    // formData?.locationName?.valueText?.toString() !== ""
    //   ? formData?.locationName?.valueText?.toString()
    //   : locationvalue.toString();
    const numPosition = numberOfRolesInput;
    const minExpRequiredMonths = minExperience;
    // formData?.minExpRequiredMonths?.valueText;
    const maxExpRequiredMonths = maxExperience;
    // formData?.maxExpRequiredMonths?.valueText;
    const isRange = isRangeChecked;
    const minSalary = formData?.minSalary?.valueText;
    const maxSalary = formData?.maxSalary?.valueText;
    const salaryType =
      formData?.salaryType?.valueText === ""
        ? salary_Type
        : formData?.salaryType?.valueText;
    const referralBonusType = refBonusType;
    const reportingRole =
      formData?.reportingRole?.valueText !== ""
        ? formData?.reportingRole?.valueText
        : reportingrole;
    const targetDate = currentDate;
    const salCurrencyId = salaryId;
    const referralBonus = referralAmount;
    // formData?.referralBonus?.valueText;
    const screeningProcess =
      screeningProcessFields.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? []
        : screeningProcessFields;
    const skillsRequired = formData?.skillsRequired?.valueText;
    const jobAdvantage =
      jobAdvantageFields.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? []
        : jobAdvantageFields;

    const jobEveryDayTask =
      jobEveryDayTaskFields.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? []
        : jobEveryDayTaskFields;
    const jobPower = jobPowers;
    const jobCareerPath =
      jobCareerpathFields.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? []
        : jobCareerpathFields;
    const reportingStructure = reportStructure1;
    const referralCriteriaCompany =
      formData?.referralCriteriaCompany?.valueText;
    const referralCriteriaIndustry =
      formData?.referralCriteriaIndustry?.valueText;
    const referralCriteriaOther =
      referralCriteriaOtherFields.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? []
        : referralCriteriaOtherFields;

    const questions =
      jobQuestionsFields?.filter(function (o) {
        return o.hasOwnProperty("value");
      }).length > 0
        ? // action === "save") ||
          // (jobQuestionsFields?.filter(function (o) {
          //   return o.hasOwnProperty("value");
          // }).length > 0 &&
          //   action === "draft") ||
          // Savetype === true ||
          // Savetype === false
          []
        : jobQuestionsFields;
    // if (!isValid) {
    let data = {
      businessId: businessId,
      businessUserId: businessUserId,
      companyId: companyId,
      templateId: templateId,
      templateTitle: templateTitle,
      templateDescription: templateDescription,
      jobTitle: jobTitle,
      isRange: isRangeChecked,
      locationId: locationId,
      departmentName: departmentName,
      reportingType: reportingType,
      jobType: jobType,
      numPosition: numPosition,
      reportingRole: reportingRole,
      minExpRequiredMonths: minExpRequiredMonths,
      maxExpRequiredMonths: maxExpRequiredMonths,

      salCurrencyId: salaryId,
      minSalary: minSalary,
      maxSalary: maxSalary,
      salaryType: salaryType,
      referralBonusType: referralBonusType,
      referralBonus: referralBonus,
      screeningProcess: screeningProcess,
      skillsRequired: skillsRequired,
      jobAdvantage: jobAdvantage,
      jobEveryDayTask: jobEveryDayTask,
      jobPower: jobPower,
      jobCareerPath: jobCareerPath,
      additionalRemarks: additionalRemarks,
      reportingStructure: reportingStructure,
      targetDate: targetDate,
      questions: questions,
      referralCriteriaCompany: referralCriteriaCompany,
      referralCriteriaIndustry: referralCriteriaIndustry,
      referralCriteriaOther: referralCriteriaOther,
    };

    // if (minSalary > maxSalary) {
    //   toaster("error", "Maximun Salary is Greater Than Minimum Salary");
    // } else {

    let isValid = true;

    // data?.forEach((key) => {
    if (templateTitle === "" && templateDescription === "") {
      isValid = false;
    }
    // });
    if (isValid) {
      if (data?.maxSalary < data?.minSalary) {
        toaster("error", " please enter Max Salary is greater than Min Salary");
        return;
      }
      if (data?.minSalary <= 0 && data?.maxSalary) {
        toaster("error", " Salary can not be negative and not be zero");
        return;
      }
      if (data?.minSalary % 1 != 0 && data?.maxSalary % 1 != 0) {
        toaster("error", " Salary can not be decimal");
        return;
      }
      if (data?.minExpRequiredMonths < 0 || data?.maxExpRequiredMonths < 0) {
        toaster("error", "Experience cannot be negative");
        return;
      }

      // if (data?.maxExpRequiredMonths < data?.minExpRequiredMonths) {
      //   toaster(
      //     "error",
      //     "Minimum experience cannot be greater than maximum experience"
      //   );
      //   return;
      // }
      createJobTemplate(data).then((res) => {
        if (res?.data?.status === STATUS_SUCCESS) {
          toaster("success", res?.data?.message);
          navigate("/job-templates");
          // JOB_TEMPLATES
        } else {
          // toaster("error", res?.message ? res?.message : GENERAL_ERROR_MESSAGE);
          toaster("error", res?.data?.message);
        }
      });
    } else {
      toaster("error", " All fields required");
    }
    // }
    // var isValid = true;
  };
  console.log(refBonusType, "onchange---select");

  const handleClear = () => {
    setFormData({
      templateTitle: {
        valueText: "",
      },
      templateDescription: {
        valueText: "",
      },
      jobTitle: {
        valueText: "",
      },
      departmentName: {
        valueText: "",
      },
      locationName: {
        valueText: "",
      },
      reportingType: {
        valueText: "",
      },

      jobType: {
        valueText: "",
      },
      numPosition: {
        valueText: "",
      },
      reportingRole: {
        valueText: "",
      },
      minExpRequiredMonths: {
        valueText: "",
      },
      maxExpRequiredMonths: {
        valueText: "",
      },
      isRange: {
        valueText: "",
      },
      salCurrency: {
        valueText: "",
      },
      minSalary: {
        valueText: "",
      },
      maxSalary: {
        valueText: "",
      },
      salaryType: {
        valueText: "",
      },
      referralBonusType: {
        valueText: "",
      },
      referralBonus: {
        valueText: "",
      },
      screeningProcess: {
        valueText: [],
      },
      skillsRequired: {
        valueText: "",
      },
      jobAdvantage: {
        valueText: [],
      },
      jobEveryDayTask: {
        valueText: [],
      },
      jobPower: {
        valueText: [],
      },
      jobCareerPath: {
        valueText: [],
      },
      additionalRemarks: {
        valueText: "",
      },
      reportingStructure: {
        //valueText: ["Project Manager"],
        valueText: [],
      },
      // reportingStructure1: {
      //   valueText: "",
      //   // valueText: [],
      // },
      referralCriteriaCompany: {
        valueText: "",
      },
      referralCriteriaIndustry: {
        valueText: "",
      },
      referralCriteriaOther: {
        valueText: "",
      },
      questionText: {
        valueText: [],
      },
      // questions: [
      //   {
      //     questionText
      //     isMandatory: "",
      //     action: "",
      //     quesType: "",
      //   },
      // ],
    });
  };

  return (
    <div className="" style={{ backgroundColor: "#f4f7fb" }}>
      {/* {showLoader && <Loader/>} */}

      {/* <div className="w-100 ">
        <Header />
      </div> */}
      <p></p>

      <div
        className="w-100 pt-4 "
        style={{
          borderRadius: "0px !important",
          backgroundColor: "#f4f7fb",
        }}
      >
        <div className="container px-md-4 px-3">
          <div className=" border-0 mb-0 mt-2 py-0 d-flex justify-content-between mt-4">
            <div className="mt-2 d-flex gap-4 align-items-center">
              <Link
                to={-1}
                className="backTitle p-0 m-0 d-flex align-items-center"
                style={{ textDecoration: "none", color: "black" }}
              >
                <img
                  src={Vectorleft}
                  alt=""
                  className="pointer"
                  height="12.03px"
                  style={{ marginRight: "1rem" }}
                />
                <p style={{ borderBottom: "1px solid #1C1C1C" }}> Go Back</p>
              </Link>
            </div>
            <Link
              to={-1}
              className="backTitle p-0 m-0 d-flex align-items-center"
              style={{ textDecoration: "none", color: "black" }}
            >
              <img
                src={closeRoundedRed}
                alt="close-icon"
                height="30px"
                width="30px"
                style={{ marginRight: "1rem" }}
                onClick={() => props.close()}
                className="pointer m-0"
              />
            </Link>
          </div>

          <hr />

          {/* NEW JOB POST */}
          <div className="pt-0">
            <div className="d-flex justify-content-between flex-wrap ">
              <div>
                <p className="fw-700 fs-24 my-0">New job Template</p>
              </div>
              <div className="d-flex gap-3 flex-wrap justify-content-center align-items-center">
                <button
                  className="btn btn-outline-dark fs-14 fw-700 "
                  style={{ height: "35px", minWidth: "150px" }}
                  onClick={() => handleClear()}
                >
                  Cancel
                </button>
                <button
                  style={{ height: "35px", minWidth: "150px" }}
                  onClick={() => saveTemplateData()}
                  className="btn btn-outline-dark fs-14 fw-700"
                >
                  Save Template
                </button>
              </div>
            </div>

            <div className="d-flex flex-wrap">
              <div
                className=" justify-content-between mb-1 col-12 col-sm-3 my-4 me-3"
                style={{ minWidth: "250px" }}
              >
                <p className="fs-16 fw-600">
                  Template Title <span className="astrick">*</span>
                </p>
                <div className="gap-3">
                  <input
                    type="text"
                    // className={
                    //   (formData?.templateTitle?.errorText ? "error" : "") +
                    // "modal-form-input small-text-medium-gray text-dark"}
                    // className="modal-form-input small-text-medium-gray text-dark"
                    className={
                      (formData?.templateTitle?.errorText ? "error" : "") +
                      " modal-form-input small-text-medium-gray text-dark h-40 form-control fs-14"
                    }
                    placeholder="Add Template Title"
                    value={formData?.templateTitle?.valueText}
                    defaultValue={
                      formData?.templateTitle?.valueText
                        ? formData?.templateTitle?.valueText
                        : ""
                    }
                    required
                    onChange={($event) => {
                      onInputChange("templateTitle", $event.target.value);
                    }}
                  />
                  {formData?.templateTitle?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.templateTitle?.errorText}
                    </div>
                  )}
                </div>
              </div>
              <div
                className=" justify-content-between mb-1 col my-4"
                style={{ minWidth: "250px" }}
              >
                <p className="fs-16 fw-600">
                  Template Description <span className="astrick">*</span>
                </p>
                <div className="gap-3">
                  <input
                    type="text"
                    // className="modal-form-input small-text-medium-gray text-dark"
                    className={
                      (formData?.templateDescription?.errorText
                        ? "error"
                        : "") +
                      " modal-form-input small-text-medium-gray text-dark h-40 form-control fs-14"
                    }
                    placeholder="Add Template Description"
                    name="templateDescription"
                    value={formData?.templateDescription?.valueText}
                    defaultValue={
                      formData?.templateDescription?.valueText
                        ? formData?.templateDescription?.valueText
                        : ""
                    }
                    required
                    onChange={($event) => {
                      onInputChange("templateDescription", $event.target.value);
                    }}
                  />
                  {formData?.templateDescription?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.templateDescription?.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-4 mb-0">
              <p className="fw-700 fs-20 my-0">Add Job Details</p>
            </div>

            {/* JOB POST BY */}
            <div className="d-flex justify-content-between flex-wrap mt-4 gap-4 mb-3">
              <div
                className="profile-box-shadow job-post-by justify-content-between  col-12 col-sm-2 p-3"
                style={{ minWidth: "250px" }}
              >
                <p className="fs-16 fw-600">Job Post By</p>
                <div className="d-flex gap-3">
                  <div className="logo-border-box position-relative">
                    {companyData?.companyLogo === null &&
                    companyData?.companyLogo === undefined ? (
                      <img src={logoAirbnb} width="50px" alt="" />
                    ) : (
                      <img
                        src={`data:image/jpeg;base64 , ${companyData?.companyLogo}`}
                        width="50px"
                        alt=""
                      />
                    )}
                  </div>
                  <div className="color-primary fw-500 fs-14 pt-3">
                    {" "}
                    {companyData?.companyName}
                  </div>
                </div>
              </div>

              {/* JOB TITLE */}
              <div
                className="profile-box-shadow  justify-content-between  col p-3"
                style={{ minWidth: "250px" }}
              >
                <p className="fs-16 fw-600">Job Title</p>
                <div className="gap-3">
                  <input
                    type="text"
                    className={
                      (formData?.jobTitle?.errorText ? "error" : "") +
                      " modal-form-input small-text-medium-gray text-dark h-40 form-control"
                    }
                    placeholder="Add Job Title"
                    value={formData?.jobTitle?.valueText}
                    defaultValue={
                      formData?.jobTitle?.valueText
                        ? formData?.jobTitle?.valueText
                        : ""
                    }
                    required
                    name="jobTitle"
                    onChange={($event) => {
                      onInputChange("jobTitle", $event.target.value);
                    }}
                  />
                  {formData?.jobTitle?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.jobTitle?.errorText}
                    </div>
                  )}

                  <h4
                    className="fs-11 fw-400 mt-2"
                    style={{ color: "#909090" }}
                  >
                    {formData?.jobTitle?.valueText?.length}-{Character}
                    characters lefts
                  </h4>
                </div>
              </div>
            </div>

            {/* JOB DEPARTMENT */}
            <div className="d-flex justify-content-between flex-wrap  gap-4 mb-3">
              <div className="profile-box-shadow mt-2  w-100">
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Job Department
                    {/* <span className="astrick"></span> */}
                  </h4>
                  <div className="d-flex gap-4 align-items-start flex-grow">
                    <SearchComboBox
                      name="departmentName"
                      inputData={functions}
                      // value={formData?.departmentName?.valueText}
                      defaultValue={formData?.departmentName?.valueText}
                      isMultiSelect={true}
                      inputCssClass={`modal-input combo-search-box ${formData?.departmentName?.errorText} form-control`}
                      // inputCssClass={
                      //   "modal-input combo-search-box"
                      // }
                      wrapperCssClass={"form-group col-12 col-md-3"}
                      placeholder={"Search or Select"}
                      onSelect={(event) => {
                        onInputChange("departmentName", event);
                      }}
                      searchListHeight={150}
                      badgeThemeCssClass={"blue fs-12"}
                      hideBadges={false}
                      isAllowUserDefined={true}
                    />

                    <div className="d-md-flex d-none flex-wrap  gap-2 m-0 p-0 align-content-start">
                      {jobDepartment?.map((output, index) => {
                        if (
                          output.name === "Engineering" ||
                          output.name === "Design" ||
                          output.name === "Sales" ||
                          output.name === "Marketing" ||
                          output.name === "Operations"
                        ) {
                          return (
                            <div
                              className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer"
                              key={index}
                              onClick={() => {
                                if (!isEmpty(jobDepartmentOutput)) {
                                  const newJobDepartment = [
                                    ...jobDepartmentOutput,
                                  ];
                                  if (newJobDepartment.includes(output.name)) {
                                    let itemIndex =
                                      jobDepartmentOutput?.findIndex(
                                        (tm) => tm === output.name
                                      );
                                    newJobDepartment.splice(itemIndex, 1);
                                    // } else {
                                    //   newJobDepartment.push(output.name);
                                  }
                                  setJobDepartmentOutput(newJobDepartment);
                                  setJobDepartmentOutputStatic("");
                                } else {
                                  setJobDepartmentOutput([output.name]);
                                  // setJobDepartmentOutputStatic([output.name]);
                                }
                              }}
                            >
                              {jobDepartmentOutput.includes(output.name) ? (
                                <img
                                  height="16px"
                                  width="16px"
                                  src={checkedLogo}
                                  alt=""
                                ></img>
                              ) : (
                                ""
                              )}

                              <div>{output.name} </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                  <br />
                  {/* <div className="d-flex flex-wrap  gap-2">
                    {jobDepartmentOutput?.map((output, index) => {
                      return (
                        <div
                          className="blue-badge d-flex  height-28 gap-3"
                          key={index}
                          onClick={() => {
                            let arr = jobDepartmentOutput.filter((item) => {
                              return item !== output;
                            });
                            setJobDepartmentOutput(arr);
                            setJobDepartmentOutputStatic(arr);
                            let department_Name = arr.toString();

                            setFormData({
                              ...formData,
                              departmentName: department_Name,
                            });
                          }}
                        >
                          <div>{output} </div>
                          <img
                            src={icCloseBlue}
                            alt="blue-close-icon"
                            className="pointer"
                            height="10px"
                          />
                        </div>
                      );
                    })}
                  </div> */}
                  {formData.departmentName.errorText && (
                    <div className="field-error mt-1">
                      {formData.departmentName.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* JOB LOCATION */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4 mt-4 mb-3">
              <div
                className="profile-box-shadow  col"
                style={{ minWidth: "250px" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Job Location</h4>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="col-12">
                      <SearchComboBox
                        name="locationName"
                        inputData={locations}
                        defaultValue={formData?.locationName?.valueText}
                        // value={formData?.locationName?.valueText}
                        //                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box ${
                          formData?.locationName?.errorText ? "error" : ""
                        } form-control`}
                        // inputCssClass={"modal-input combo-search-box "}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        onSelect={($event) => {
                          onInputChange("locationName", $event);
                        }}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue fs-12"}
                        hideBadges={false}
                        isAllowUserDefined={true}
                      />
                    </div>
                  </div>

                  <br />
                  {/* <div className="d-flex flex-wrap  gap-2">
                    {jobLocationOutput?.map((output, index) => {
                      return (
                        <div
                          className="blue-badge d-flex  height-28 gap-3"
                          key={index}
                          onClick={() => {
                            let arr = jobLocationOutput.filter((item) => {
                              return item !== output;
                            });
                            setJobLocationOutput(arr);
                            // setJobDepartmentOutputStatic(arr);
                            let department_Name = arr.toString();
                          }}
                        >
                          <div>{output} </div>
                          <img
                            src={icCloseBlue}
                            alt="blue-close-icon"
                            className="pointer"
                            height="10px"
                          />
                        </div>
                      );
                    })}
                  </div> */}
                  <br />
                  <div className="d-md-flex d-none flex-wrap height-28 gap-2 ">
                    {locations?.map((output, index) => {
                      if (
                        output.name === "Mumbai" ||
                        output.name === "Delhi" ||
                        output.name === "Bangalore" ||
                        output.name === "Pune" ||
                        output.name === "Hyderabad"
                      ) {
                        return (
                          <div
                            className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer"
                            // key={index<=5}
                            onClick={() => {
                              if (!isEmpty(jobLocationOutput)) {
                                const newJobLocation = [...jobLocationOutput];
                                if (newJobLocation.includes(output.name)) {
                                  let itemIndex = jobLocationOutput?.findIndex(
                                    (tm) => tm === output.name
                                  );
                                  newJobLocation.splice(itemIndex, 1);
                                  // } else {
                                  //   newJobLocation.push(output.name);
                                }
                                setLocationValue(newJobLocation);
                                setJobLocationOutputStatic("");
                              } else {
                                setLocationValue([output.name]);
                                // setJobLocationOutputStatic([output.name]);
                              }
                            }}
                          >
                            {locationvalue.includes(output.name) ? (
                              <img
                                height="16px"
                                width="16px"
                                src={checkedLogo}
                                alt=""
                              ></img>
                            ) : (
                              ""
                            )}

                            <div>{output.name} </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>

                {formData.locationName.errorText && (
                  <div className="field-error mt-1">
                    {formData.locationName.errorText}
                  </div>
                )}
              </div>

              {/* JOB REPORTING */}
              <div
                className="profile-box-shadow  col"
                style={{ minWidth: "250px" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Job Reporting</h4>

                  <div className="d-lg-flex d-sm-grid flex-wrap mt-4 mx-1 gap-3">
                    {jobReportingType?.map((output, i) => {
                      console.log(
                        "type",
                        formData?.reportingType?.valueText ===
                          output.description
                          ? formData?.reportingType?.valueText
                          : false
                      );
                      return (
                        <div className="form-check col-6" key={i}>
                          <input
                            className={
                              (formData?.reportingType?.errorText
                                ? "error"
                                : "") + " form-check-input pointer"
                            }
                            type="radio"
                            name="reportingType"
                            key="reportingType"
                            id={output.description}
                            value={formData?.reportingType?.valueText}
                            checked={
                              formData?.reportingType?.valueText ===
                              output.description
                                ? true
                                : false
                            }
                            // defaultChecked={
                            //   formData?.reportingType?.valueText ===
                            //     output.description
                            //     // ? true
                            //     // : false
                            // }
                            onChange={() => {
                              onInputChange(
                                "reportingType",
                                output.description
                              );
                            }}
                            required
                          />
                          <label
                            className="fs-14 fw-400 form-check-label pointer"
                            for="fulltime"
                            htmlFor={output.description}
                          >
                            {output.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {formData?.reportingType?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.reportingType?.errorText}
                  </div>
                )}
              </div>

              {/* JOB TYPE */}
              <div
                className="profile-box-shadow  col"
                style={{ minWidth: "250px" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Job Type</h4>

                  <div className="d-lg-flex d-sm-grid flex-wrap mt-4 mx-1 gap-3">
                    {jobtype?.map((output, i) => {
                      return (
                        <div className="form-check col-6" key={i}>
                          <input
                            className={
                              (formData?.jobType?.errorText ? "error" : "") +
                              " form-check-input pointer"
                            }
                            type="radio"
                            name="jobtype"
                            key="jobtype"
                            id={output.description}
                            value={formData?.jobType?.valueText}
                            defaultChecked={
                              formData?.jobType?.valueText ===
                              output.description
                            }
                            onChange={() => {
                              onInputChange("jobType", output.description);
                              if (formData?.jobType?.valueText === "CONTRACT") {
                                setShowContractField(!showContractField);
                              } else {
                                setShowContractField(false);
                              }
                            }}
                            required
                          />
                          <label
                            className="fs-14 fw-400 form-check-label pointer"
                            for="fulltime"
                            htmlFor={output.description}
                          >
                            {output.name}
                          </label>
                        </div>
                      );
                    })}
                  </div>

                  {showContractField ? (
                    <>
                      <hr />
                      <div className="d-flex flex-wrap mt-4 mx-1 gap-2 align-items-center">
                        <div className="form-check">
                          {/* <input
                        className="form-check-input pointer"
                        type="radio"
                        name="exampleRadios"
                        id="exampleRadios2"
                        value="option2"
                      /> */}
                          <label
                            className="fs-14 fw-400 form-check-label pointer"
                            for="exampleRadios2"
                          >
                            Contract
                          </label>
                        </div>
                        <div className="ms-4 me-2 d-flex gap-2">
                          <input
                            defaultValue={formData?.contractMonth?.valueText}
                            type="number"
                            className="h-40"
                            style={{ width: "76px" }}
                          />
                          <p
                            className="fs-11 fw-400 d-flex align-items-center m-0 "
                            style={{ color: "#909090" }}
                          >
                            Months
                          </p>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {formData?.jobType?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.jobType?.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* NUMBER OF ROLES */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4 my-4">
              <div className="profile-box-shadow num-roles-width">
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Number of Roles</h4>
                </div>
                <div className="d-flex gap-2 justify-content-start mb-4 px-3">
                  <div
                    className="border radius4 d-flex align-items-center justify-content-center pointer"
                    style={{ width: "58px", height: "40px" }}
                    // onClick={onOfRolesDecrement}
                    onClick={() =>
                      setNumberOfRolesInput(numberOfRolesInput - 1)
                    }
                  >
                    <img src={minusRounded} alt="" />
                  </div>
                  <input
                    style={{
                      width: "85px",
                      borderRadius: "4px",
                      border: "1px solid #B9B9B9",
                    }}
                    // defaultValue={
                    //   formData?.numPosition?.valueText
                    //     ? formData?.numPosition.valueText
                    //     : ""
                    // }
                    value={numberOfRolesInput}
                    required
                    // onChange={($event) => {
                    //   onInputChange("numPosition", $event.target.value);
                    // }}
                    name="numPosition"
                    type="number"
                    className={
                      (formData?.numPosition?.errorText ? "error" : "") +
                      " col-4 text-center "
                    }
                  />

                  <div
                    className="border radius4  d-flex align-items-center justify-content-center pointer"
                    style={{ width: "58px", height: "40px" }}
                    // onClick={onOfRolesIncrement}
                    onClick={() =>
                      setNumberOfRolesInput(numberOfRolesInput + 1)
                    }
                  >
                    <img src={plusRounded} alt="" />
                  </div>
                </div>
                {formData?.numPosition?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.numPosition?.errorText}
                  </div>
                )}
              </div>

              {/* EXPERIENCE */}
              <div
                className="profile-box-shadow  col"
                // style={{ width: "66.66%" }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Experience</h4>
                  <div className="d-flex gap-3 align-items-center flex-wrap ">
                    <div
                      className="fs-12 d-flex height-28 gap-1 border px-2 py-1 rounded-pill pointer"
                      onClick={() => {
                        setCheck("Range");
                        onChangeRange();
                        // setIsRangeChecked(!isRangeChecked);
                      }}
                    >
                      {check === "Range" ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                        />
                      ) : (
                        ""
                      )}

                      <span>Range </span>
                    </div>
                    <div
                      className="fs-12 fw-400 d-flex height-28 gap-1 border px-2 py-1 rounded-pill pointer"
                      style={{ minWidth: "156px" }}
                      onClick={() => {
                        setCheck("Experience");
                        onChangeExprience();
                        // setIsMinimumExpChecked(!isMinimumExpChecked);
                      }}
                    >
                      {check === "Experience" ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                        />
                      ) : (
                        ""
                      )}
                      <div>Minimum Experience</div>
                    </div>
                    <input
                      style={{
                        width: "76px",
                        borderRadius: "4px",
                        border: "1px solid #B9B9B9",
                        height: "40px",
                      }}
                      // value={formData?.minExpRequiredMonths?.valueText}
                      // defaultValue={formData?.minExpRequiredMonths?.valueText}
                      type="number"
                      name="minExpRequiredMonths"
                      className={
                        (formData?.minExpRequiredMonths?.errorText
                          ? "error"
                          : "") + " col-4 text-center "
                      }
                      // className={
                      //   (formData?.minExpRequiredMonths?.errorText ? "error" : "") +
                      //   " form-control text-center w-30 "
                      // }
                      value={minExperience}
                      onChange={($event) => {
                        // onInputChange(
                        //   "minExpRequiredMonths",
                        //   $event.target.value
                        // );
                        if ($event?.target?.value > 100) {
                          return;
                        } else {
                          onInputChange(
                            "minExpRequiredMonths",
                            $event.target.value
                          );
                        }
                      }}
                      onKeyDown={(evt) =>
                        evt.key === "$event" && evt.preventDefault()
                      }
                    />
                    <span
                      className="fs-14 fw-400 align-self-end  pb-2"
                      style={{ color: "#808080" }}
                    >
                      Years
                    </span>
                    <span
                      className="fs-14 fw-400  align-self-end  pb-2"
                      style={{ color: "#808080" }}
                    >
                      to
                    </span>
                    <input
                      style={{
                        width: "76px",
                        borderRadius: "4px",
                        border: "1px solid #B9B9B9",
                        height: "40px",
                      }}
                      disabled={isMinimumExpChecked === true}
                      // value={formData?.maxExpRequiredMonths?.valueText}
                      // defaultValue={formData?.maxExpRequiredMonths?.valueText}
                      type="number"
                      name="maxExpRequiredMonths"
                      className={
                        (formData?.maxExpRequiredMonths?.errorText
                          ? "error"
                          : "") + " col-4 text-center"
                      }
                      // className={
                      //   (formData?.maxExpRequiredMonths?.errorText ? "error" : "") +
                      //   " form-control text-center w-30 "
                      // }
                      value={maxExperience}
                      onChange={($event) => {
                        //   onInputChange(
                        //     "maxExpRequiredMonths",
                        //     $event.target.value
                        //   );
                        // }}
                        if ($event?.target?.value > 100) {
                          return;
                        } else {
                          onInputChange(
                            "maxExpRequiredMonths",
                            $event.target.value
                          );
                        }
                      }}
                      onKeyDown={(evt) =>
                        evt.key === "$event" && evt.preventDefault()
                      }
                    />
                    <span
                      className="fs-14 fw-400  align-self-end pb-2"
                      style={{ color: "#808080" }}
                    >
                      years
                    </span>
                  </div>
                </div>
                {formData?.maxExpRequiredMonths?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.maxExpRequiredMonths?.errorText}
                  </div>
                )}
              </div>
            </div>

            {/* SALARY */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4 h-100 mt-4 mb-3">
              <div
                className="profile-box-shadow  col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="px-3 py-2">
                  <h4 className="fs-16 fw-600">Salary</h4>
                </div>
                <div className="mx-3 d-flex flex-wrap gap-3">
                  <div className="page-filter align-items-center ">
                    <select
                      className="form-select page-filter-button rounded-pill  border-dark fs-12 fw-400 pointer h-28 align-items-center p-0"
                      aria-label="Default select example"
                      name="salCurrency"
                      onChange={($event) =>
                        onInputChange("salCurrency", $event.target.value)
                      }
                    >
                      {salCurrency?.map((output, i) => {
                        return (
                          <option
                            selected={
                              salCurrencyPop === null || salCurrencyPop === ""
                                ? output.code === "INR"
                                : salCurrencyPop === output.code
                            }
                          >
                            {output.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="page-filter align-items-center">
                    <select
                      name="salaryType"
                      className="form-select page-filter-button rounded-pill border-dark fs-12 fw-400 pointer h-28 align-items-center p-0"
                      aria-label="Default select example"
                      onChange={($event) => {
                        onInputChange("salaryType", $event.target.value);
                      }}
                    >
                      {salaryTypeOptions?.map((output, i) => {
                        return (
                          <option selected={salary_Type === output.name}>
                            {output.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="d-md-flex d-sm-grid flex-wrap pb-3 mt-4  ">
                  <div className="mx-3  col mb-3">
                    <p className="back-button fs-11 fw-400 mt-0 mb-2">
                      Minimum Salary
                    </p>
                    {/* <input
                      style={{
                        borderRadius: "4px",
                        border: "1px solid #B9B9B9",
                      }}
                      type="number"
                      className={
                        (formData?.minSalary?.errorText ? "error" : "") +
                        " text-center w-100"
                      }
                      // defaultValue={formData?.minSalary?.valueText}
                      defaultValue={
                        formData?.minSalary?.valueText
                          ? formData?.minSalary?.valueText
                          : ""
                      }
                      required
                      name="minSalary"
                      onChange={($event) => {
                        onInputChange("minSalary", $event.target.value);
                      }}
                    /> */}

                    <input
                      type="number"
                      className={
                        (formData?.minSalary?.errorText ? "error" : "") +
                        " form-control text-center w-100"
                      }
                      value={formData?.minSalary?.valueText}
                      defaultValue={
                        formData?.minSalary?.valueText
                          ? formData?.minSalary?.valueText
                          : ""
                      }
                      required
                      name="minSalary"
                      onChange={($event) => {
                        onInputChange("minSalary", $event.target.value);
                      }}
                    />
                    {formData?.minSalary?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.minSalary?.valueText
                          ? formData?.maxSalary?.errorText
                          : Minsalary_Error}
                      </div>
                    )}
                  </div>
                  <div className="mx-3 col">
                    <p className="back-button fs-11 fw-400 mt-0 mb-2">
                      Maximum Salary
                    </p>
                    <input
                      // style={{
                      //   borderRadius: "4px",
                      //   border: "1px solid #B9B9B9",
                      // }}
                      type="number"
                      name="maxSalary"
                      required
                      className={
                        (formData?.maxSalary?.errorText ? "error" : "") +
                        " form-control text-center w-100"
                      }
                      value={formData?.maxSalary?.valueText}
                      defaultValue={
                        formData?.maxSalary?.valueText
                          ? formData?.maxSalary?.valueText
                          : ""
                      }
                      onChange={($event) => {
                        onInputChange("maxSalary", $event.target.value);
                      }}
                    />
                    <div className="field-error mt-1">{Maxsalary_Error}</div>
                    {formData?.maxSalary?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.maxSalary?.errorText}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* REFERRAL BONUS */}
              <div
                className="profile-box-shadow col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="px-3 pt-3 pb-0">
                  <h4 className="fs-16 fw-600">Referral Bonus</h4>
                </div>
                <div className="d-xs-grid d-sm-flex flex-wrap mx-3 ">
                  <div
                    className="fs-12 d-flex height-28 gap-1 border px-2 py-1 my-2 me-2 rounded-pill pointer"
                    style={{ width: "fit-content" }}
                    onClick={() => {
                      setSalaryCheck("P");
                      setRefBonusType("P");
                      onChangeIsminSalary();
                    }}
                  >
                    {salarycheck === "P" ? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    <div>% of Min Salary</div>
                  </div>
                  <div
                    className="fs-12 fw-400 d-flex height-28 gap-1 border px-2 py-1  my-2 rounded-pill pointer"
                    style={{ width: "fit-content" }}
                    onClick={() => {
                      setSalaryCheck("F");
                      setRefBonusType("F");
                      onChangeIsFixedAmt();
                    }}
                  >
                    {salarycheck === "F" ? (
                      <img
                        height="16px"
                        width="16px"
                        src={checkedLogo}
                        alt=""
                      />
                    ) : (
                      ""
                    )}
                    <div>Fixed Amount</div>
                  </div>
                </div>
                <div className="mx-3 my-3 col mt-3">
                  <p className="back-button fs-11 fw-400 mt-0 mb-2">
                    Bonus Value
                  </p>
                  <input
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #B9B9B9",
                      maxWidth: "95px",
                    }}
                    // value={formData?.referralBonus?.valueText}
                    defaultValue={referralAmount}
                    required
                    type="number"
                    name="referralBonus"
                    className={
                      (formData?.referralBonus?.errorText ? "error" : "") +
                      " text-center w-100"
                    }
                    // className={
                    //   (formData?.referralBonus?.errorText ? "error" : "") +
                    //   " form-control text-center w-30 "
                    // }
                    onChange={($event) => {
                      onInputChange("referralBonus", $event.target.value);
                    }}
                  />
                  <span className="mx-2 fs-14 fw-400">
                    = ₹
                    {formData?.minSalary?.valueText !== calMinSalPer
                      ? referralAmount1
                      : salarycheck === "P"
                      ? Math.round((bonusCal / 100) * calMinSalPer)
                      : bonusCal}
                  </span>
                </div>
                {formData?.referralBonus?.errorText && (
                  <div className="field-error mt-1">
                    {formData?.referralBonus?.errorText}
                  </div>
                )}
              </div>
            </div>

            {/* Screening Process */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-2 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Screening Process</h4>
                  <p className="back-button fs-11 fw-400 mt-0">
                    Add screenig steps
                  </p>
                </div>
                <div className="d-flex gap-2 pointer mx-3 mt-0">
                  <img
                    src={icPlusIcon}
                    alt="plus-icon"
                    onClick={AddscreeningProcessField}
                  />
                  <div
                    className="fs-12 color-primary fw-700"
                    onClick={AddscreeningProcessField}
                  >
                    Add Next Step
                  </div>
                </div>
                {screeningProcessFields?.map((input, i) => {
                  return (
                    <>
                      <div className="pt-3 d-flex  ps-3 pb-2" key={i}>
                        <input
                          id={i}
                          maxLength={Character_Limit}
                          type={input.type}
                          name="screeningProcess"
                          key="screeningProcess"
                          value={input}
                          // value={formData?.screeningProcess?.valueText}
                          defaultValue={screeningProcess[i]}
                          className={
                            (formData?.screeningProcess?.errorText
                              ? "error"
                              : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Step ${i + 1}`}
                          onChange={(e) => ScreeningProcessOutput(i, e)}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removeScreeningProcess(input, i)}
                        />
                      </div>
                      {formData?.screeningProcess?.errorText && (
                        <div className="field-error mt-1">
                          {formData?.screeningProcess?.errorText}
                        </div>
                      )}
                      <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                        {screeningProcessFields[i]?.length
                          ? screeningProcessFields[i]?.length
                          : "0"}
                        -{Character_Limit}characters
                      </h1>
                    </>
                  );
                })}
              </div>
            </div>

            {/* Skills Required */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Skills Required</h4>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="col-12 ">
                      <SearchComboBox
                        inputData={keySkill}
                        name="skillsRequired"
                        value={formData?.skillsRequired?.valueText}
                        defaultValue={jobSkills}
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3 ${
                          formData?.skillsRequired?.errorText ? "error" : ""
                        } form-control w-30`}
                        // inputCssClass={
                        //   "modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3"
                        // }
                        wrapperCssClass={"form-group "}
                        placeholder={"Search or Select"}
                        searchListHeight={150}
                        badgeThemeCssClass={"red fs-12"}
                        hideBadges={false}
                        onSelect={(event) => {
                          onInputChange("skillsRequired", event);
                        }}
                        isAllowUserDefined={true}
                      />
                    </div>
                  </div>
                  {formData.skillsRequired.errorText && (
                    <div className="field-error mt-1">
                      {formData.skillsRequired.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Superpowers */}

            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Superpowers</h4>
                  <div className="d-flex flex-wrap gap-4 align-items-baseline">
                    <div className="col-12 ">
                      {/* <SearchComboBox
                        inputData={jobSuperPower}
                        defaultValue={jobPowers}
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3 ${
                          formData?.jobPower?.errorText ? "error" : ""
                        } form-control w-30`}
                        // inputCssClass={
                        //   "modal-input combo-search-box col-12 col-md-4 col-sm-5 col-lg-3"
                        // }
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        onSelect={(event) => {
                          onInputChange("jobPower", event);
                        }}
                        searchListHeight={150}
                        badgeThemeCssClass={"red fs-12"}
                        hideBadges={false}
                        name="jobPower"
                        isAllowUserDefined={true}
                      /> */}
                      <input
                        // maxLength={Character}
                        type="text"
                        value={formData?.jobPower?.valueText}
                        className={
                          (formData?.jobPower?.errorText ? "error" : "") +
                          " modal-form-input small-text-medium-gray text-dark h-40 form-control fs-14"
                        }
                        placeholder="Add Job Power"
                        defaultValue={
                          formData?.jobPower?.valueText
                            ? formData?.jobPower?.valueText
                            : jobPowers
                        }
                        required
                        name="jobPower"
                        key="jobPower"
                        onChange={($event) => {
                          onInputChange("jobPower", $event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  {formData.jobPower.errorText && (
                    <div className="field-error mt-1">
                      {formData.jobPower.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Why you should look at this role
                  </h4>
                </div>
                <div className="d-flex gap-2 pointer mx-3 mt-0">
                  <img
                    src={icPlusIcon}
                    alt="plus-icon"
                    onClick={AddjobAdvantageFields}
                  />
                  <div
                    className="fs-12 color-primary fw-700"
                    onClick={AddjobAdvantageFields}
                  >
                    Add Reason
                  </div>
                </div>
                {jobAdvantageFields?.map((input, i) => {
                  return (
                    <>
                      <div className="pt-3 d-flex  ps-3 pb-2" key={i}>
                        <input
                          id={i}
                          maxLength={Character_Limit}
                          type={input.type}
                          name="jobAdvantage"
                          value={input}
                          className={
                            (formData?.jobAdvantage?.errorText ? "error" : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Reason ${i + 1} here`}
                          // value={formData?.jobAdvantage?.valueText}
                          defaultValue={jobAdvantage[i]}
                          onChange={(e) => jobAdvantageFieldsOutput(i, e)}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removejobAdvantage(input, i)}
                        />
                      </div>
                      {formData?.jobAdvantage?.errorText && (
                        <div className="field-error mt-1">
                          {formData?.jobAdvantage?.errorText}
                        </div>
                      )}

                      <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                        {jobAdvantageFields[i]?.length
                          ? jobAdvantageFields[i]?.length
                          : "0"}
                        -{Character_Limit}characters left
                      </h1>
                    </>
                  );
                })}
              </div>
            </div>

            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">What you will do everyday</h4>
                </div>
                <div className="d-flex gap-2 pointer mx-3 mt-0">
                  <img
                    src={icPlusIcon}
                    alt="plus-icon"
                    onClick={AddjobEveryDayTaskFields}
                  />
                  <div
                    className="fs-12 color-primary fw-700"
                    onClick={AddjobEveryDayTaskFields}
                  >
                    Add Point
                  </div>
                </div>
                {jobEveryDayTaskFields?.map((input, i) => {
                  return (
                    <>
                      <div className="pt-3 d-flex  ps-3 pb-2" key={i}>
                        <input
                          id={i}
                          type={input.type}
                          maxLength={Character_Limit}
                          value={input}
                          //className="modal-form-input small-text-medium-gray text-dark"
                          className={
                            (formData?.jobEveryDayTask?.errorText
                              ? "error"
                              : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Reason ${i + 1} here`}
                          // value={formData?.jobEveryDayTask?.valueText}
                          defaultValue={jobEveryDay[i]}
                          onChange={(e) => jobEveryDayTaskOutput(i, e)}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removeEveryDayTaskField(input, i)}
                        />
                        {formData?.jobEveryDayTask?.errorText && (
                          <div className="field-error mt-1">
                            {formData?.jobEveryDayTask?.errorText}
                          </div>
                        )}
                      </div>
                      <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                        {jobEveryDayTaskFields[i]?.length
                          ? jobEveryDayTaskFields[i]?.length
                          : "0"}
                        -{Character_Limit}characters left
                      </h1>
                    </>
                  );
                })}
              </div>
            </div>

            {/* CAREER PATH */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4  mb-4">
              <div
                className="profile-box-shadow   col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600 pb-3">Career Path</h4>
                  <div className="d-flex gap-3">
                    <img
                      src={icPlusIcon}
                      alt="plus-icon"
                      onClick={AddCareerPathFields}
                    />
                    <div
                      className="fs-12 color-primary fw-700"
                      onClick={AddCareerPathFields}
                    >
                      Add Point
                    </div>
                  </div>
                  {jobCareerpathFields?.map((input, i) => {
                    return (
                      <div className="d-flex gap-3">
                        <img src={Vectorplusroundedgray} alt="" />
                        <div className=" d-flex   pb-2 align-items-center mt-3 w-100 gap-1">
                          <input
                            id={i}
                            type={input.type}
                            value={input}
                            //className="modal-form-input small-text-medium-gray text-dark m-1"
                            className={
                              (formData?.jobCareerPath?.errorText
                                ? "error"
                                : "") +
                              " form-control modal-form-input small-text-medium-gray text-dark m-1"
                            }
                            placeholder={`Add Role ${i + 1}`}
                            // value={formData?.jobCareerPath?.valueText}
                            defaultValue={JobCareerPath[i]}
                            onChange={(e) => jobCareerpathOutput(i, e)}
                          />
                          <img
                            src={icDelete}
                            alt="delete-icon"
                            className=" pointer"
                            onClick={() => removeCareerpathField(input, i)}
                          />
                          {formData?.jobCareerPath?.errorText && (
                            <div className="field-error mt-1">
                              {formData?.jobCareerPath?.errorText}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  <div className="ms-2">
                    <img src={upDottedArrow} alt="" />
                  </div>
                  <div className="d-flex gap-3 mt-1 ms-1">
                    <img src={Vectorcompletionflag} alt="" />
                    <p className="fs-14 fw-400 back-button mx-1 my-3">
                      This Role
                    </p>
                  </div>
                </div>
              </div>

              {/* Reporting Structure */}
              <div
                className="profile-box-shadow h-100 col pb-4"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Reporting Structure<span className="astrick">*</span>
                  </h4>
                </div>

                <div className="gap-2 d-flex flex-column">
                  <div className="mx-3">
                    <input
                      type="text"
                      name="reportingStructure"
                      key="reportingStructure"
                      className={
                        (formData?.reportingStructure?.errorText
                          ? "error"
                          : "") +
                        " form-control modal-form-input small-text-medium-gray text-dark"
                      }
                      placeholder="write here"
                      defaultValue={
                        reportingStructures !== null
                          ? reportingStructures?.toString().split(",")[0]
                          : repotvalue[0]
                          ? reportingStructures === null
                          : ""
                      }
                      onChange={($event) => {
                        onInputChange(
                          "reportingStructure",
                          $event.target.value
                        );
                      }}
                    />
                    {formData?.reportingStructure?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.reportingStructure?.errorText}
                      </div>
                    )}
                  </div>
                  <div className="mx-4 mt-3 mb-2">
                    <img src={downDottedArrow} alt="" />
                  </div>
                  <p className="fs-11 fw-600 mx-3">This Role</p>
                  <div className="mx-4 pt-0 mb-2">
                    <img src={downDottedArrow} alt="" />
                  </div>
                  <p className="fs-12 fw-400 mx-3 my-0">
                    This role will be reporting to
                  </p>
                  <div className="mx-3">
                    <input
                      type="text"
                      name="reportingRole"
                      className={
                        (formData?.reportingRole?.errorText ? "error" : "") +
                        " form-control modal-form-input small-text-medium-gray text-dark"
                      }
                      placeholder="write here"
                      // defaultValue={
                      //   reportingStructures !== null
                      //     ? reportingStructures.toString().split(",")[1]
                      //     : repotvalue[1]
                      //       ? reportingStructures === null
                      //       : ""
                      // }
                      defaultValue={formData?.reportingRole?.valueText}
                      onChange={($event) => {
                        onInputChange("reportingRole", $event.target.value);
                      }}
                    />
                    {formData?.reportingRole?.errorText && (
                      <div className="field-error mt-1">
                        {formData?.reportingRole?.errorText}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* What Else */}
            <div className="d-flex justify-content-between flex-wrap my-4  col-12 gap-4">
              <div
                className="profile-box-shadow col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">What Else</h4>
                  <p className="back-button fs-11 fw-400 mt-0">
                    Add any additional information that the candidates need to
                    know before applying
                  </p>
                  <textarea
                    type="text"
                    //className="modal-form-input small-text-medium-gray align-self-start"
                    className={
                      (formData?.additionalRemarks?.errorText ? "error" : "") +
                      " form-control modal-form-input small-text-medium-gray align-self-start"
                    }
                    style={{ minHeight: "80px" }}
                    value={formData?.additionalRemarks?.valueText}
                    defaultValue={formData?.additionalRemarks?.valueText}
                    name="additionalRemarks"
                    onChange={($event) => {
                      onInputChange("additionalRemarks", $event.target.value);
                    }}
                  />
                  {formData?.additionalRemarks?.errorText && (
                    <div className="field-error mt-1">
                      {formData?.additionalRemarks?.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Additional Questions to Candidates */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div className="profile-box-shadow mt-1 mb-3 col">
                <div className="p-3">
                  <h4 className="fs-16 fw-600">
                    Additional Questions to Candidates
                  </h4>
                  <p className="back-button fs-11 fw-400 mt-0">
                    Add any additional questions for the candidate to apply for
                    this role
                  </p>
                  <div className="d-flex gap-2 pointer mt-0 mb-2">
                    <img
                      src={icPlusIcon}
                      alt="plus-icon"
                      onClick={() =>
                        AddQuestionsFields(jobQuestionsFields?.length)
                      }
                    />
                    <div
                      className="fs-12 color-primary fw-700"
                      onClick={() =>
                        AddQuestionsFields(jobQuestionsFields?.length)
                      }
                    >
                      Add Question
                    </div>
                  </div>

                  {jobQuestionsFields?.map((input, i) => {
                    return (
                      <>
                        <div className="d-xs-grid d-sm-flex mt-4 align-items-baseline gap-3">
                          <h4 className="fs-14 fw-700 me-4">
                            Question {i + 1}
                          </h4>
                          <div className="form-check " key={i}>
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`jobQuestionsFields${i}`}
                              defaultChecked={
                                input[i]?.quesType == "F_T" ? true : false
                              }
                              checked={input.quesType == "F_T" ? true : false}
                              onChange={(event) => {
                                let copyJobQuestionsFields = [
                                  ...jobQuestionsFields,
                                ];
                                if (event.target.checked === true) {
                                  copyJobQuestionsFields[i].quesType = "F_T";
                                } else {
                                  copyJobQuestionsFields[i].quesType = "Y_N";
                                }
                                setJobQuestionsFields(copyJobQuestionsFields);
                                // QuestionsFieldsOutput()
                              }}
                            />
                            <label
                              className=" fs-14 fw-400 form-check-label"
                              for="exampleRadios1"
                            >
                              Freeform as answer
                            </label>
                          </div>
                          <div className="form-check mx-2">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`jobQuestionsFields${i}`}
                              defaultChecked={
                                input[i]?.quesType == "Y_N" ? true : false
                              }
                              checked={input.quesType == "Y_N" ? true : false}
                              onChange={(event) => {
                                let copyJobQuestionsFields = [
                                  ...jobQuestionsFields,
                                ];
                                if (event.target.checked === true) {
                                  copyJobQuestionsFields[i].quesType = "Y_N";
                                } else {
                                  copyJobQuestionsFields[i].quesType = "F_T";
                                }
                                setJobQuestionsFields(copyJobQuestionsFields);
                              }}
                            />

                            <label
                              className="fs-14 fw-400 form-check-label"
                              for="exampleRadios2"
                            >
                              Yes/No as answer
                            </label>
                          </div>
                        </div>
                        <div className="form-check mt-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={input.isMandatory === "Y"}
                            onChange={(event) => {
                              let copyJobQuestionsFields = [
                                ...jobQuestionsFields,
                              ];
                              if (event.target.checked === true) {
                                copyJobQuestionsFields[i].isMandatory = "Y";
                              } else {
                                copyJobQuestionsFields[i].isMandatory = "N";
                              }

                              setJobQuestionsFields(copyJobQuestionsFields);
                            }}
                          />
                          <label
                            className=" fs-14 fw-400 form-check-label"
                            for="exampleRadios1"
                          >
                            Mandatory to answer
                          </label>
                        </div>
                        <div className=" d-flex   pb-2 align-items-center mt-3">
                          <input
                            type="text"
                            maxLength={Character}
                            // className="form-control modal-form-input small-text-medium-gray text-dark"
                            className={
                              (formData?.questionText?.errorText
                                ? "error"
                                : "") +
                              " form-control modal-form-input small-text-medium-gray text-dark"
                            }
                            placeholder="Add Question"
                            name="jobQuestionsFields"
                            defaultValue={input.questionText}
                            onChange={(event) => {
                              let copyJobQuestionsFields = [
                                ...jobQuestionsFields,
                              ];
                              copyJobQuestionsFields[i].questionText =
                                event.target.value;
                              setJobQuestionsFields(copyJobQuestionsFields);
                            }}
                          />
                          <img
                            src={icDelete}
                            alt="delete-icon"
                            className="p-2 pointer"
                            onClick={(i) => {
                              // removeQuestionsFields(i + 1);
                              onDelete(input,i);
                            }}
                          />
                          {formData?.questionText?.errorText && (
                            <div className="field-error mt-1">
                              {formData?.questionText?.errorText}
                            </div>
                          )}
                        </div>
                        <br />
                      </>
                    );
                  })}
                </div>
              </div>
            </div>

            <div>
              <p className="fs-24 fw-700 mt-4 p-0 m-0">Add Referral Criteria</p>
              <p className="fs-16 fw-400 mt-1 mb-2 p-0 m-0 color-tertiary">
                Target candidates with the following criteria:
              </p>
            </div>

            {/* Company */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Company</h4>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="col-12 col-sm-3 ">
                      <SearchComboBox
                        name="referralCriteriaCompany"
                        inputData={company}
                        value={formData?.refCriteriaCompany?.valueText}
                        defaultValue={refCriteriaCompany}
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box ${
                          formData?.referralCriteriaCompany?.errorText
                            ? "error"
                            : ""
                        } form-control`}
                        // inputCssClass={"modal-input combo-search-box"}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue fs-12"}
                        hideBadges={false}
                        onSelect={(event) => {
                          onInputChange("referralCriteriaCompany", event);
                        }}
                        isAllowUserDefined={true}
                      />
                      {formData?.referralCriteriaCompany?.errorText && (
                        <div className="field-error mt-1">
                          {formData?.referralCriteriaCompany?.errorText}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Industry */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-4 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Industry</h4>
                  <div className="d-flex flex-wrap gap-4 align-items-center">
                    <div className="col-12 col-sm-3 ">
                      <SearchComboBox
                        name="referralCriteriaIndustry"
                        inputData={industries}
                        value={formData?.referralCriteriaIndustry?.valueText}
                        defaultValue={refCriteriaIndustry}
                        isMultiSelect={true}
                        inputCssClass={`modal-input combo-search-box ${
                          formData?.referralCriteriaIndustry?.errorText
                            ? "error"
                            : ""
                        } form-control`}
                        // inputCssClass={"modal-input combo-search-box"}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue fs-12"}
                        hideBadges={false}
                        // onSelect={JobIndustryValue}
                        onSelect={(event) => {
                          onInputChange("referralCriteriaIndustry", event);
                        }}
                        isAllowUserDefined={true}
                      />
                      {formData?.referralCriteriaIndustry?.errorText && (
                        <div className="field-error mt-1">
                          {formData?.referralCriteriaIndustry?.errorText}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Other */}
            <div className="d-flex justify-content-between flex-wrap  col-12 gap-4">
              <div
                className="profile-box-shadow mt-1 mb-3 col"
                style={{
                  minWidth: "250px",
                }}
              >
                <div className="p-3">
                  <h4 className="fs-16 fw-600">Other</h4>
                  <div className="d-flex gap-2 pointer mt-3">
                    <img
                      src={icPlusIcon}
                      alt="plus-icon"
                      onClick={AddreferralCriteriaOtherFields}
                    />
                    <div
                      className="fs-12 color-primary fw-700"
                      onClick={AddreferralCriteriaOtherFields}
                    >
                      Add Another
                    </div>
                  </div>
                  {referralCriteriaOtherFields?.map((input, i) => {
                    return (
                      <div className="pt-3 d-flex   pb-2">
                        <input
                          id={i}
                          type={input.type}
                          value={input}
                          //className="modal-form-input small-text-medium-gray text-dark"
                          className={
                            (formData?.referralCriteriaOther?.errorText
                              ? "error"
                              : "") +
                            " form-control modal-form-input small-text-medium-gray text-dark"
                          }
                          placeholder={`Write Reason  ${i + 1} here`}
                          onChange={(e) => referralCriteriaOtherOutput(i, e)}
                          // value={formData?.referralCriteriaOther?.valueText}
                          defaultValue={refCriteriaOther[i]}
                        />
                        <img
                          src={icDelete}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => removeReferralCriteriaOther(input, i)}
                        />
                        {formData?.referralCriteriaOther?.errorText && (
                          <div className="field-error mt-1">
                            {formData?.referralCriteriaOther?.errorText}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {/* Bottom btns */}

            <div className="d-md-flex justify-content-between flex-wrap align-items-center gap-1 mt-4 mb-5">
              <div className=" d-flex">
                <div className="col-6 ps-2 px-md-4 px-0">
                  <button
                    className="btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                    style={{ height: "35px", minWidth: "150px" }}
                    onClick={() => handleClear()}
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6 ps-2">
                  <button
                    style={{ height: "35px", minWidth: "150px" }}
                    onClick={() => saveTemplateData()}
                    className="btn btn-outline-dark fs-14 fw-700 h-40  w-100"
                  >
                    Save Template
                  </button>
                </div>
              </div>
            </div>
            {/* End */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobCreateTemplatePage;
