import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { DateRange } from "react-date-range";
import "../styles/custom_date_picker.scss";

function DatePickerModal(props) {
  //   const [showModal, setShowModal] = useState(false);

  //   useEffect(() => {
  //     if (props?.displayDatePicker == "d-block") {
  //       setShowModal(true);
  //     } else {
  //       setShowModal(false);
  //     }
  //   }, [props?.displayDatePicker]);
  const showSelectedDate = (startDt, endDt) => {
    if (startDt) {
      const startDate = startDt?.toString()?.slice(4, 16);
      props?.setCustomStartDate(startDate);
    }
    if (endDt) {
      const endDate = endDt?.toString()?.slice(4, 16);
      props?.setCustomEndDate(endDate);
    }
  };

  const handleDateRange = (event) => {
    props?.setDateRange([event?.selection]);
    let startDate = event?.selection?.startDate;
    let endDate = event?.selection?.endDate;
    props?.showSelectedDate(startDate, endDate);
  };

  return (
    <Modal
      show={props?.showDatePickerModal}
      fullscreen={true}
      scrollable={true}
      bsPrefix="modal"
      animation={false}
      dialogClassName="w-100 h-100 margin-0-imp"
    >
      <Modal.Body>
        <div className="h-100 custome-date-box p-3 d-flex flex-column justify-content-center align-items-center">
          <DateRange
            ranges={props?.dateRange}
            onChange={(e) => {
              props?.handleDateRange(e);
            }}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            maxDate={props?.currentDate}
          />
          <div className="d-flex justify-content-center">
            <button
              style={{ border: "1px solid black", width: "80px" }}
              className="btn bnt-light color-dark fs-14 p-0"
              onClick={() => {
                // props?.setDisplayDatePicker("d-none");
                props?.setShowDatePickerModal(false);
                props?.setArraySortIndex(null);
                props?.setTimeDropdownRedDot("d-none");
              }}
            >
              Cancel
            </button>
            <button
              style={{ border: "1px solid black", width: "80px" }}
              className="btn bnt-light color-dark fs-14 p-0 ms-2 "
              onClick={() => {
                // props?.setDisplayDatePicker("d-none");
                props?.setShowDatePickerModal(false);
                props?.setTimeDropdownRedDot("d-block");
                if (
                  props?.customStartDate != "" &&
                  props?.customEndDate != ""
                ) {
                  props?.setArraySortIndex(5);
                }
              }}
            >
              OK
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DatePickerModal;
