import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";
import { validateField } from "../../utils/form_validators";
import Loader from "../../components/common/loader";

const CompanyPotentialCollegues = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [potentialLink, setPotentialLink] = useState();
  const [potentialDescription, setPotentialDescription] = useState();

  const [formData, setFormData] = useState({
    potentialLink: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
  });

  const onSavePotentialLink = {
    companyId: props.companyId,
    potentialLink: potentialLink,
    potentialDescription: potentialDescription,
  };

  const onSavePotential = () => {
    setShowLoader(true);
    create(onSavePotentialLink)
      .then((response) => {
        setShowLoader(false);
        props?.setApiRefresh(true);
        // toaster("success", "Saved successfully!");
      })
      .catch((e) => {
        props?.setApiRefresh(false);
        setShowLoader(false);
      });
  };
  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.onPotentialColleguesClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onPotentialColleguesClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">
                Potential Colleagues
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body pt- mt-0 ">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600">
                  Link to teams page
                </div>
                <div className="pt-3 pb-5">
                  <input
                    type="text"
                    className="modal-form-input input-color-black"
                    placeholder="airbnb.io/updatesfromourteam"
                    defaultValue={props.potentialLink}
                    onChange={(e) => {
                      setPotentialLink(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600">
                  Add Description
                </div>
                <div className="pt-3 pb-3">
                  <textarea
                    type="text"
                    className="modal-form-input input-color-black"
                    rows="5"
                    placeholder="Our teams go above and beyond the regular work. If you didnt know, our design team killed GIFs usage in web by introducing Lottie and then making it Opensource - Lottie is now the industry standard for animations on the web and apps."
                    defaultValue={props.potentialDescription}
                    onChange={(e) => {
                      setPotentialDescription(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onPotentialColleguesClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSavePotential();
                props.onPotentialColleguesClose();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyPotentialCollegues;
