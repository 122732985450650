import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import {
  isEmpty,
  onFormFeildsChange,
  validateField,
} from "../../utils/form_validators";
import checkedLogo from "../../assests/ic_selected16.svg";
import { ORG_ID, STATUS_SUCCESS } from "../../config/localstorage_keys";
import { GENERAL_ERROR_MESSAGE } from "../../config/messages";
import { inviteMembersAddTeams } from "../../_services/view.service";
import {
  ALPHABET_CHECK,
  EMAIL_PATTERN,
  REQUIRED,
} from "../../config/validation_patterns_constants";
import toaster from "./../../utils/toaster";
import { inviteMembersTeamsList } from "../../_services/view.service";
import { set } from "date-fns";
import { getLocalStorage } from "../../utils/storage";
import Loader from "../../components/common/loader";
const InviteMemberDialog = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [teamsOutput, setTeamsOutput] = useState([]);
  const [teams_List, setTeams_List] = useState();
  const [workEmail, setWorkEmail] = useState();
  const [memberdata, setMemberData] = useState({
    workEmail: null,
    role: null,
    allOrgsAccess: null,
    listOfOrgs: null,
    // listOfTeams:
  });

  const Allteams = () => {
    setShowLoader(true);
    inviteMembersTeamsList({ page: 0, pageSize: 0 })
      .then((res) => {
        if (res?.data?.status == STATUS_SUCCESS) {
          props?.setApiRefresh(true);
          setTeams_List(res?.data?.data);

          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        props?.setApiRefresh(false);
        toaster(
          "error",
          err?.data?.message ? err?.data?.message : GENERAL_ERROR_MESSAGE
        );
      });
  };
  useEffect(() => {
    if (props?.show === true) {
      Allteams();
    }
  }, [props?.show]);
  const [roleOutput, setRoleOutput] = useState("");

  const [role, setRole] = useState([
    {
      name: "Admin",
      description: "BIZ_ADMIN",
    },
    {
      name: "Member",
      description: "BIZ_USER",
    },
  ]);
  const [selectedRole, setSelectedRole] = useState();

  const [selectedTeam, setSelectedTeam] = useState();

  const [formData, setFormData] = useState({
    workEmail: {
      valueText: "",
      errorText: "",
      check: [REQUIRED, EMAIL_PATTERN],
    },

    listOfTeams: {
      valueText: [],
      errorText: "",
      check: [REQUIRED],
    },
  });

  const orgId = getLocalStorage(ORG_ID);
  const data = {
    workEmail: workEmail,
    role: selectedRole,
    allOrgsAccess: false,
    listOfOrgs: [orgId],
    listOfTeams: [...teamsOutput],
  };

  const saveinvite = () => {
    let emailValid = true;
    if (ALPHABET_CHECK.test(formData?.workEmail?.valueText)) {
      emailValid = validateField("workEmail", formData, setFormData);
      setShowLoader(true);
      inviteMembersAddTeams(data)
        .then((res) => {
          // props?.getmemberlists(1, 10);
          props?.setApiRefresh(true);
          if (res?.data?.status == STATUS_SUCCESS) {
            if (
              EMAIL_PATTERN.test(formData?.workEmail?.valueText) &&
              res?.data?.data?.emailVerified == false
            ) {
              if (props?.verificationNeeded) {
                props?.setWorkEmail(formData?.workEmail?.valueText);
                props?.verificationNeeded();
              }
            }
            // setWorkEmail();
            // setTeamsOutput();

            toaster(
              "success",
              res?.data?.message ? res?.data?.message : STATUS_SUCCESS
            );
            props?.close();
            props?.setSelectedState();
            setShowLoader(false);

            setFormData({
              workEmail: {
                valueText: "",
                errorText: "",
                check: [REQUIRED, EMAIL_PATTERN],
              },
              listOfTeams: {
                valueText: "",
                errorText: "",
                check: [REQUIRED],
              },
            });

            setTeamsOutput([]);

            setSelectedRole("");
            // window.location.reload(false);
          } else {
            toaster(
              "error",
              res?.data?.message ? res?.data?.message : GENERAL_ERROR_MESSAGE
            );
            setShowLoader(false);
          }
          // setFormData("");
        })
        .catch((err) => {
          props?.setApiRefresh(false);
          setShowLoader(false);

          // toaster(
          //   "error",
          //   err?.data?.message ? err?.data?.message : GENERAL_ERROR_MESSAGE
          // );
        });
    }
  };

  // useEffect(() => {
  //   if(formData?.listOfTeams?.valueText === "" && formData?.listOfTeams?.valueText === null){

  //   }
  // }, [third])

  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.close()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className=" p-0 "
        >
          <Modal.Header
            className=" border-0 px-3 pt-3"
            bsPrefix="modal-header-custom"
          >
            <div className="fs-24 color-primary fw-700 px-2 pt-2">
              Invite member
            </div>
            <img
              src={icCloseRounded}
              alt="close-icon"
              onClick={() => props.close()}
              className="pointer"
              height="30px"
              width="30px"
            />
          </Modal.Header>
          <Modal.Body className="m-0 p-0">
            <div className="border-bottom pb-4 px-4 pt-3">
              <div className="fs-14 fw-600">Work Email</div>
              <div className="pt-2">
                <input
                  type="email"
                  // className="modal-form-input text-dark h-40"
                  className={
                    (formData?.workEmail?.errorText ? "error" : "") +
                    " form-control modal-form-input text-dark h-40"
                  }
                  placeholder=""
                  value={formData?.workEmail?.valueText}
                  name="workEmail"
                  onChange={($event) => {
                    onFormFeildsChange($event, formData, setFormData);
                    setWorkEmail($event.target.value);
                  }}
                />
                <span className="field-error mt-1">
                  {formData?.workEmail?.errorText}
                </span>
              </div>
            </div>

            <div className="border-bottom pb-4 px-4 pt-3">
              <div className="">
                <div className="fs-14 fw-600">Role</div>
                <div className="d-flex flex-wrap gap-2 mt-2 ">
                  {role?.map((output, index) => {
                    return (
                      <div
                        className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer h-28"
                        // key={index}
                        onClick={() => {
                          if (output.description === "BIZ_ADMIN") {
                            setSelectedRole("BIZ_ADMIN");
                          } else {
                            setSelectedRole("BIZ_USER");
                          }
                          setRoleOutput(`${output.description}`);
                        }}
                      >
                        {roleOutput.includes(output.description) &&
                        selectedRole !== "" &&
                        selectedRole !== null ? (
                          <img
                            height="16px"
                            width="16px"
                            src={checkedLogo}
                            alt=""
                          ></img>
                        ) : (
                          ""
                        )}

                        <div>{output.name} </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div></div>
            </div>

            <div className="fs-14 fw-600 pt-3 px-4">Teams</div>
            <ul
              className="m-0 p-0 overflow-auto"
              style={{ maxHeight: "300px" }}
            >
              <div className="d-flex flex-wrap gap-2 pb-4 px-4 pt-3">
                <ul
                  className="m-0 p-0 overflow-auto"
                  style={{ maxHeight: "300px" }}
                ></ul>
                {teams_List?.map((output, index) => {
                  return (
                    <div
                      className="fs-12  d-flex gap-1 border px-2 py-1 rounded-pill pointer h-28"
                      onClick={() => {
                        if (!isEmpty(teamsOutput)) {
                          const inviteMember = [...teamsOutput];
                          if (inviteMember.includes(output.id)) {
                            let itemIndex = teamsOutput?.findIndex(
                              (im) => im === output.id
                            );
                            inviteMember.splice(itemIndex, 1);
                          } else {
                            inviteMember.push(output.id);
                          }
                          setTeamsOutput(inviteMember);
                        } else {
                          setTeamsOutput([output.name]);
                        }
                      }}
                    >
                      {teamsOutput.includes(output.id) &&
                      teamsOutput !== "" &&
                      teamsOutput !== null ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                        ></img>
                      ) : (
                        ""
                      )}

                      <div>{output.team} </div>
                    </div>
                  );
                })}
              </div>
            </ul>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            {teams_List?.length < 1 ? (
              <button
                className="btn-dialog-save pointer fs-12 fw-700 border-0"
                // onClick={() => {
                //   props.close();
                //   saveinvite();
                // }}
              >
                Send Invite
              </button>
            ) : (
              <button
                className="btn-dialog-save pointer fs-12 fw-700 border-0"
                onClick={() => {
                  if (formData?.workEmail?.errorText == "") {
                    saveinvite();
                  }
                }}
              >
                Send Invite
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default InviteMemberDialog;
