import { action, thunk } from "easy-peasy";
// import { LOGIN_ENDPOINT } from "../constants/api-endpoints";
// import api from "./../_services/api";
import { candidateModel } from "./candidate";

export const model = {
  // loggedIn: {},
  // addLoggedIn: action((state, payload) => {
  //   return {
  //     ...state,
  //     loggedIn: payload,
  //   };
  // }),
  // saveLoggedIn: thunk(async (actions, payload) => {
  //   const { data } = await api.post(LOGIN_ENDPOINT, payload);
  //   actions.addLoggedIn(data);
  // }),
  candidate: candidateModel,
  isNewlyRegistered: false,
  setNewlyRegister: action((state, payload) => {
    return {
      ...state,
      isNewlyRegistered: payload,
    };
  }),
  notAppliedCompanyDetails: {},
  setNotAppliedCompanyDetails: action((state, payload) => {
    return {
      ...state,
      notAppliedCompanyDetails: payload,
    };
  }),
  isShowSratchPreviewBtn: false,
  setIsShowScratchPreviewBtn: action((state, payload) => {
    return {
      ...state,
      isShowSratchPreviewBtn: payload,
    };
  }),
  isPreviewed: false,
  setIsPreviewed: action((state, payload) => {
    return {
      ...state,
      isPreviewed: payload,
    };
  }),
};
