import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import icPlusIcon from "../../assests/icons/ic-plus-icon.svg";
import icDelete from "../../assests/icons/ic_delete.svg";
import icDeleteRed from "../../assests/icons/ic-delete-red.svg";
import { create } from "../../_services/company.service";
import Loader from "../../components/common/loader";
import toaster from "../../utils/toaster";

const CompanyReviews = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [glassdoorRating, setGlassdoorRating] = useState();
  const [allglassdoorReviews, setAllGlassdoorReviews] = useState([
    // { value: "" },
  ]);
  const [glassdoorUrl, setGlassdoorUrl] = useState();
  const handleFormChange = (index, event) => {
    // event.preventDefault();
    const newAllReview = [...allglassdoorReviews];
    newAllReview[index].glassdoorReviews = event.target.value;
    setAllGlassdoorReviews(newAllReview);
  };
  const removeInputFields = (index) => {
    const rows = [...allglassdoorReviews];
    rows.splice(index, 1);
    setAllGlassdoorReviews(rows);
  };
  const addFields = () => {
    const newAllReview = [...allglassdoorReviews];
    newAllReview.push({ glassdoorReviews: "" });
    setAllGlassdoorReviews(newAllReview);
  };
  useEffect(() => {
    const newAllReview = [...allglassdoorReviews];
    newAllReview.push({ glassdoorReviews: "" });
    setAllGlassdoorReviews(newAllReview);
  }, []);
  const GlassdoorReviews = allglassdoorReviews.map(
    (item) => item.glassdoorReviews
  );

  const saveGlassdoor = {
    companyId: props.companyId,
    glassdoorRating: glassdoorRating,
    glassdoorReviews: GlassdoorReviews,
    glassdoorUrl: glassdoorUrl,
  };

  const onSaveReview = () => {
    setShowLoader(true);
    create(saveGlassdoor)
      .then((res) => {
        setShowLoader(false);
        props?.setApiRefresh(true);
        toaster("success" ,"Rating and review updated successfully..")
        // setGlassdoorRating(glassdoorRating);
        // setGlassdoorReviews(glassdoorReviews);
        // setGlassdoorUrl(glassdoorUrl);
      })
      .catch((e) => {
        setShowLoader(false);
        props?.setApiRefresh(false);
      });
  };

  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.onReviewsClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          scrollable={true}
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onReviewsClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">
                Glassdoor Reviews
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body pt- mt-0">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600">
                  Overall Glassdoor Rating
                </div>
                <div className="pt-3 pb-5">
                  <input
                    type="text"
                    className="modal-form-input color-secondary fs-12"
                    placeholder="0.0"
                    defaultValue={props.glassdoorRating}
                    onChange={(e) => {
                      setGlassdoorRating(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600">
                  Glassdoor page link
                </div>
                <div className="pt-3 pb-5">
                  <input
                    type="text"
                    className="modal-form-input input-color-black"
                    placeholder="http://PageLinkUrl.com"
                    defaultValue={props.glassdoorUrl}
                    onChange={(e) => {
                      setGlassdoorUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600">Add Reviews</div>
              </div>
              <div className="d-flex gap-2 pointer pt-3">
                <img
                  src={icPlusIcon}
                  alt="plus-icon"
                  onClick={() => {
                    addFields();
                  }}
                />
                <div
                  className="fs-12 color-primary fw-700"
                  onClick={() => {
                    addFields();
                  }}
                >
                  Add Another
                </div>
              </div>
              {allglassdoorReviews.map((input, index) => {
                return (
                  <div className="pt-3 d-flex " key={index}>
                    {/* <> */}
                    <input
                      id={index}
                      // value={input.glassdoorReviews}
                      type={input.type}
                      className="modal-form-input input-color-black "
                      // placeholder="write here"
                      // defaultValue={props.glassdoorReviews}
                      defaultValue={props?.glassdoorReviews}
                      onChange={(event) => handleFormChange(index, event)}
                    />

                    <img
                      src={icDeleteRed}
                      alt="delete-icon"
                      className="p-2 pointer"
                      //removeInputFields
                      onClick={() => {
                        removeInputFields();
                      }}
                    />
                    {/* </> */}
                  </div>
                );
              })}
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onReviewsClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSaveReview();
                props.onReviewsClose();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyReviews;
