import React, { useEffect, useState } from "react";
import preview from "../assests/icons/preview.svg";
import star from "../assests/icons/ic_star.svg";
import shock from "../assests/icons/ic_shock.svg";
import process from "../assests/icons/ic_process.svg";
import people from "../assests/icons/ic_people.svg";
import product from "../assests/icons/ic_product.svg";
import flag from "../assests/icons/ic_flag.png";
import { NOT_MENTIONED } from "../config/messages";
import { useStoreState } from "easy-peasy";

const ScratchJobDetails = (props) => {
  const notAppliedCompanyDetails = useStoreState(
    (state) => state?.notAppliedCompanyDetails
  );

  return (
    <div className="container">
      <div className="row pt-2 pt-2">
        <div className="col-md-6 col-12 pe-md-2 p-0">
          <div className="font-color-black fw-600 fs-16">
            {" "}
            <img src={preview} alt="preview-icon" className="pe-2" /> Key Skills{" "}
          </div>
          <br />
          <div className="pt-1 pb-3 d-flex flex-wrap">
            {notAppliedCompanyDetails?.jobData?.skillsRequired?.map(
              (skill, index) => {
                return (
                  <div
                    className="font-color-blue p-1 ps-2 pe-2 mt-2 ms-1"
                    key={index}
                  >
                    {skill}
                  </div>
                );
              }
            )}
          </div>
          <hr className="mt-2"></hr>
        </div>
        <div className="col-md-6 col-12 pt-2 px-0">
          <div className="font-color-black fw-600 fs-16">
            <img src={shock} alt="shock-icon" className="pe-2" />
            Your Superpowers
          </div>
          <br />
          <div className="pt-1 pb-3 d-flex flex-wrap">
            {notAppliedCompanyDetails?.jobData?.jobPower?.map(
              (power, index) => {
                return (
                  <div
                    className="font-color-red p-1 ps-2 pe-2 mt-2"
                    key={index}
                  >
                    {" "}
                    {power}
                  </div>
                );
              }
            )}
          </div>
          <hr className="mt-2"></hr>
        </div>
      </div>

      <div className="row pt-2 p-0">
        <div className="col-md-6 col-12 p-0">
          <span className="font-color-black fw-600 fs-16">
            <img src={star} alt="preview-icon" className="" /> Why should you
            look at this role?
          </span>
          <ul className="p-2 ps-4 style-done">
            {notAppliedCompanyDetails?.jobData?.jobAdvantage?.map(
              (advantage, index) => {
                return (
                  <li className="font-medium-gray pb-1" key={index}>
                    {" "}
                    {advantage}
                  </li>
                );
              }
            )}
          </ul>
          <hr className="mt-2"></hr>
        </div>
        <div className="col-md-6 col-12 pt-2 p-0">
          <span className="font-color-black fw-600 fs-16 mt-3">
            <img src={product} alt="preview-icon" className="pe-2" /> What you
            will do everyday?
          </span>
          <ul className="p-2 ps-4 style-done mt-2">
            {notAppliedCompanyDetails?.jobData?.jobEveryDayTask?.map(
              (task, index) => {
                return (
                  <li className="font-medium-gray pb-1" key={index}>
                    {" "}
                    {task}
                  </li>
                );
              }
            )}
          </ul>
          <hr className="mt-2"></hr>
        </div>
      </div>

      <div className="row pt-2 pt-2">
        <div className="col-md-6 col-12 pe-2">
          <span className="font-color-black fw-600 fs-16">
            <img src={process} alt="preview-icon" className="pe-2" /> Screening
            Process
          </span>
          <ul className="p-2 ps-4 ">
            {notAppliedCompanyDetails?.jobData?.screeningProcess?.map(
              (processes, index) => {
                return (
                  <>
                    <li className="font-medium-gray pb-1" key={index}>
                      {" "}
                      {processes}{" "}
                    </li>
                    <br />
                  </>
                );
              }
            )}
          </ul>
          <hr className="mt-2"></hr>
        </div>
        <div className="col-md-6 col-12 pt-2">
          <span className="font-color-black fw-600 fs-16 mt-3">
            <img src={flag} alt="preview-icon" className="pe-2" /> Career Path
          </span>
          <div className="d-flex">
            <div className="multicolor-background my-3 px-1"></div>
            <ul
              className="pt-4 mt-2 style-circle ps-0"
              style={{ marginLeft: "2px" }}
            >
              {notAppliedCompanyDetails?.jobData?.jobCareerPath?.map(
                (path, index) => {
                  return (
                    <>
                      <li className="font-medium-gray pb-1" key={index}>
                        {" "}
                        {path}
                      </li>
                      <br />
                    </>
                  );
                }
              )}
            </ul>
          </div>
          <hr className="mt-2"></hr>
        </div>
      </div>

      <div className="row pt-2 pt-2">
        <div className="col-md-6 col-12 pe-2">
          <span className="font-color-black fw-600 fs-16">
            <img src={people} alt="preview-icon" className="pe-2" /> Reporting
            Structure
          </span>
          <div className="p-2 ps-0">
            <span className="medium-text-dark-gray fw-400">
              Who will be reporting to this role?
            </span>
            <br />
            <span className="font-medium-gray fs-12">
              {notAppliedCompanyDetails?.jobData?.reportingStructure?.length &&
                notAppliedCompanyDetails?.jobData?.reportingStructure
                  ?.toString()
                  .split(",")[0]}
              {/* // reportingStructure[0]} */}
            </span>
          </div>
          <div className="p-2 ps-0">
            <span className="medium-text-dark-gray fw-400">
              This role will be reporting to
            </span>
            <br />
            <span className="font-medium-gray fs-12">
              {notAppliedCompanyDetails?.jobData?.reportingRole?.length
                ? notAppliedCompanyDetails?.jobData?.reportingRole
                    ?.toString()
                    .split(",")[0]
                : NOT_MENTIONED}
            </span>
            <br />
          </div>
        </div>
        <div className="col-md-6 col-12 pt-2">
          <span className="font-color-black fw-600 fs-16 mt-3">
            <img src={product} alt="preview-icon" className="pe-2" /> What else
          </span>
          <p className="p-2 mt-2 font-medium-gray lh-base">
            {notAppliedCompanyDetails?.jobData?.additionalRemarks}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScratchJobDetails;
