import AddImageLogo from "../../../assests/icons/ic-add-filled.svg";

import "../referee_screen/styles.scss";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import RefereeProfile from "../../../dialogs/application_dialogs/referee_profile";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import RightArrow from "../../../assests/Vector.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import SwitchButton from "./../../../components/SwitchButton";
import "./teams_member_style.scss";
import InviteMemberDialog from "./../../../dialogs/teams_and_members/invite_member";
import { MEMBERS_DETAIL_PAGE_ROUTE } from "../../../config/page_routes_constants";
import { getmember } from "../../../_services/view.service";
import { useEffect, useState } from "react";
import Loader from "../../../components/common/loader";
import toaster from "../../../utils/toaster";
import { GENERAL_ERROR_MESSAGE } from "../../../config/messages";

const MemberPage = ({
  memberlist,
  showLoader,
  currentMemberPageset,
  dataCountMembers,
  fetchMoreMembers,
  loading,
}) => {
  console.log(
    "memberlistmemberlistmemberlist",
    
    memberlist
  );
  const [style, setStyle] = useState({ display: "none" });
  const [apiRefresh, setApiRefresh] = useState(false);

  return (
    <div>
      {showLoader && <Loader />}

      <div className="table-responsive d-none d-md-block">
        <InfiniteScroll
          dataLength={currentMemberPageset}
          next={() => fetchMoreMembers()}
          hasMore={dataCountMembers > currentMemberPageset}
          className="container-fluid"
        >
          <Table className="mt-4 justify-content-between border-0">
            <thead className="w-100 border-bottom">
              <tr>
                <th className="gap-2 text-left col-3">
                  <div className="fs-12 fw-700 text-start">Member</div>
                </th>

                <th className="gap-2 text-center col-3">
                  <div className="fs-12 fw-700 text-start">Role</div>
                </th>
                <th className="gap-2 text-center ">
                  <div className="fs-12 fw-700 text-start">Teams</div>
                </th>
                <th className="gap-2 text-center">
                  <div className="fs-12 fw-700 text-start">Joined</div>
                </th>
              </tr>
            </thead>
            <tbody className="border-0">
              {memberlist && memberlist?.length > 0
                ? memberlist.map((item, index) => {
                    return (
                      <>
                        <tr
                          key={index}
                          className="card-parent fs-14 position-relative border-bottom border-top"
                        >
                          {(item.role === "Admin" ||
                            item.role === "Member") && (
                            <>
                              <td className="align-items-center py-3">
                                <div className="d-flex gap-2 align-items-center text-start">
                                  {item?.profilePic === null ||
                                  item?.profilePic === "" ? (
                                    <img
                                      src={profileImg}
                                      alt="profile"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_BASEURL +
                                        item?.profilePic
                                      }
                                      alt="profile"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  )}

                                  <div className="fs-12 fw-500 p-0 m-0">
                                  {item?.member !== "" && item?.member !== null ? item.member : item?.memberEmail }
                                  </div>
                                </div>
                              </td>

                              <td className="fs-12 fw-500 small-text-gray  align-middle text-start">
                                <div> {item?.role}</div>
                              </td>
                              <td className="fs-12 fw-500 align-middle text-start">
                                <div> {item?.teams}</div>
                              </td>
                              <td className="text-start position-relative align-middle">
                                <td className="">
                                  <div className="fs-12 fw-500">
                                    {" "}
                                    {item?.joinedAt}
                                  </div>
                                </td>
                                <div className="onhover-show ">
                                  <div className="link ms-2">
                                    <Link
                                      to={
                                        MEMBERS_DETAIL_PAGE_ROUTE +
                                        `/${item.id}`
                                      }
                                      className=" text-decoration-none py-2 px-3 rounded btn-outline-dark fs-12 fw-700 text-white bg-dark"
                                    >
                                      Manage
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </>
                          )}
                          {item.role === "Invited" && (
                            <>
                              <td className="align-items-center  py-3">
                                <div className="d-flex gap-2 align-items-center text-start">
                                  {/* {item?.profilePic === null ||
                                  item?.profilePic === "" ? ( */}
                                    {/* <img
                                      src={profileImg}
                                      alt="profile"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  ) : ( */}
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_BASEURL +
                                        item?.profilePic
                                      }
                                      alt="profile"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  {/* )} */}

                                  <div className="fs-14 fw-600 color-tertiary  align-middle text-start">
                                    {item?.member !== "" && item?.member !== null ? item.member : item?.memberEmail}
                                  </div>
                                </div>
                              </td>
                              <td className=" align-middle text-start">
                                <div className="fs-12 fw-500 small-text-gray color-tertiary">
                                  {item?.role}
                                </div>
                              </td>
                              <td className=" align-middle text-start">
                                <div className="fs-14 fw-400 small-text-gray color-tertiary">
                                  {item?.teams}
                                </div>
                              </td>
                              <td className=" align-middle text-start">
                                <div className="fs-14 fw-400 small-text-gray color-tertiary">
                                  {item?.joined}
                                </div>
                              </td>
                              <td
                                className=" align-middle text-start"
                                // onMouseEnter={(e) => {
                                //   setStyle({ display: "block" });
                                // }}
                                // onMouseLeave={(e) => {
                                //   setStyle({ display: "none" });
                                // }}
                              >
                                <div>
                                  {/* <button style={style}>Click</button>
                                   */}
                                  <Link
                                    to={
                                      MEMBERS_DETAIL_PAGE_ROUTE + `/${item.id}`
                                    }
                                    id={index}
                                    type="button"
                                    className={`fs-12 btn bg-black d-flex mx-2 align-middle   text-white down-arrow manage-btn  align-items-center gap-1`}
                                  >
                                    Manage
                                    <img
                                      alt=""
                                      style={{
                                        borderRadius: "50%",
                                        height: "1rem",
                                        width: "1rem",
                                        marginLeft: "0.5rem",
                                      }}
                                      src={RightArrow}
                                    />
                                  </Link>
                                </div>
                              </td>
                            </>
                          )}
                          {item.role === "Deleted" && (
                            <>
                              <td className="align-items-center  py-3">
                                <div className="d-flex gap-2 align-items-center text-start">
                                  {/* <img
                                  src={
                                    process.env.REACT_APP_IMAGE_BASEURL +
                                    item?.profilePic
                                  }
                                  alt="profile"
                                  className="rounded-circle"
                                  width="32px"
                                  height="32px"
                                /> */}
                                  {item?.profilePic === null ||
                                  item?.profilePic === "" ? (
                                    <img
                                      src={profileImg}
                                      alt="profile"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_BASEURL +
                                        item?.profilePic
                                      }
                                      alt="profile"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  )}

                                  <div className="fs-14 fw-600 inactive-text  align-middle text-start">
                                    {item?.member}
                                  </div>
                                </div>
                              </td>
                              <td className=" align-middle text-start">
                                <div className="fs-12 fw-500 small-text-gray inactive-text  align-middle text-start">
                                  {item?.role}
                                </div>
                              </td>
                              <td className="align-middle text-start fs-12 fw-500 align-middle text-start">
                                <div> {item?.teams}</div>
                              </td>
                              <td className="text-start position-relative align-middle">
                                <div className="inactive-text fs-12 fw-500">
                                  {" "}
                                  {item?.joinedAt}
                                </div>
                                <div className="action-card d-flex position-absolute justify-content-end m-0 pt-2 pb-1">
                                  <div className="link ms-2">
                                    <Link
                                      to={
                                        MEMBERS_DETAIL_PAGE_ROUTE +
                                        `/${item.id}`
                                      }
                                      className=" text-decoration-none p-2 rounded btn-outline-dark fs-12 fw-700 text-white bg-dark"
                                    >
                                      Manage
                                    </Link>
                                  </div>
                                </div>
                              </td>
                            </>
                          )}
                        </tr>
                      </>
                    );
                  })
                : "No Data Found"}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>

      <div className="d-md-none  d-flex flex-wrap">
        {showLoader && <Loader />}
        {memberlist && memberlist?.length > 0
          ? memberlist?.map((item, index) => {
              return (
                <>
                  {(item.role === "Admin" || item.role === "Member") && (
                    <Link
                      to={MEMBERS_DETAIL_PAGE_ROUTE + `/${item.id}`}
                      key={index}
                      className="text-decoration-none text-black p-3 small-referee-cards  justify-content-center align-items-center mt-3"
                    >
                      <div className="d-flex gap-2 align-items-center text-start">
                        {/* <img
                          src={
                            process.env.REACT_APP_IMAGE_BASEURL +
                            item?.profilePic
                          }
                          alt="profile"
                          className="rounded-circle"
                          width="32px"
                          height="32px"
                        /> */}
                        {/* {item?.profilePic === null ||
                        item?.profilePic === "" ? (
                          <img
                            src={profileImg}
                            alt="profile"
                            className="rounded-circle"
                            width="32px"
                            height="32px"
                          />
                        ) : ( */}
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_BASEURL +
                              item?.profilePic
                            }
                            alt="profile"
                            className="rounded-circle"
                            width="32px"
                            height="32px"
                          />
                        {/* )} */}

                        <div className="fs-14 fw-600">{item?.member}</div>
                      </div>

                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Role</div>
                        <div className="fs-12 fw-500">{item?.role}</div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Teams</div>
                        <div className="fs-12 fw-500">{item?.teams}</div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Joined</div>
                        <div className="fs-12 fw-500">{item?.joinedAt}</div>
                      </div>
                    </Link>
                  )}
                  {item.role === "Invited" && (
                    <Link
                      to={MEMBERS_DETAIL_PAGE_ROUTE + `/${item.id}`}
                      key={index}
                      className="text-decoration-none text-black p-3 small-referee-cards justify-content-center align-items-center mt-3 py-2"
                    >
                      <div className="d-flex gap-2 align-items-center text-start">
                        {/* <img
                          src={
                            process.env.REACT_APP_IMAGE_BASEURL +
                            item?.profilePic
                          }
                          alt="profile"
                          className="rounded-circle"
                          width="32px"
                          height="32px"
                        /> */}
                        {/* {item?.profilePic === null ||
                        item?.profilePic === "" ? (
                          <img
                            src={profileImg}
                            alt="profile"
                            className="rounded-circle"
                            width="32px"
                            height="32px"
                          />
                        ) : ( */}
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_BASEURL +
                              item?.profilePic
                            }
                            alt="profile"
                            className="rounded-circle"
                            width="32px"
                            height="32px"
                          />
                        {/* )} */}

                        <div className="fs-14 fw-600 color-tertiary">
                          {item?.member === "" || item?.member === null ? item.memberEmail : item?.member }
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Role</div>
                        <div className="fs-12 fw-500 color-tertiary">
                          {item?.role}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Teams</div>
                        <div className="fs-12 fw-500 color-tertiary">
                          {item?.teams}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Joined</div>
                        <div className="fs-12 fw-500 color-tertiary">
                          {item?.joined}
                        </div>
                      </div>
                    </Link>
                  )}
                  {item.role === "Deleted" && (
                    <Link
                      to={MEMBERS_DETAIL_PAGE_ROUTE + `/${item.id}`}
                      key={index}
                      className="text-decoration-none text-black p-3 small-referee-cards justify-content-center align-items-center mt-3 py-2 "
                    >
                      <div className="d-flex gap-2 align-items-center text-start">
                        {/* <img
                          src={profileImg}
                          alt="profile"
                          className="rounded-circle"
                          width="32px"
                          height="32px"
                        /> */}
                        {/* {item?.profilePic === null ||
                        item?.profilePic === "" ? (
                          <img
                            src={profileImg}
                            alt="profile"
                            className="rounded-circle"
                            width="32px"
                            height="32px"
                          />
                        ) : ( */}
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_BASEURL +
                              item?.profilePic
                            }
                            alt="profile"
                            className="rounded-circle"
                            width="32px"
                            height="32px"
                          />
                        {/* )} */}

                        <div className="fs-14 fw-600 inactive-text">
                          {item?.member}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Role</div>
                        <div className="fs-12 fw-500 inactive-text">
                          {item?.role}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Teams</div>
                        <div className="fs-12 fw-500 inactive-text">
                          {item?.teams}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mt-2">
                        <div className="fw-700 fs-14">Joined</div>
                        <div className="fs-12 fw-500 inactive-text">
                          {item?.joinedAt}
                        </div>
                      </div>
                    </Link>
                  )}
                </>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default MemberPage;
