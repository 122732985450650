import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import sortIcon from "../../../assests/icons/ic_sort.svg";
import funnelFilterIcon from "../../../assests/icons/ic-filter.svg";
import downArrow from "../../../assests/downArrow.svg";
import blackdone from "../../../assests/icons/ic_blackdone.svg";
import threeDotsIcon from "../../../assests/icons/three-dots-icon.svg";
import searchIcon from "../../../assests/icons/ic-search-16.svg";
import pinIcon from "../../../assests/icons/ic_pin.svg";
import filledPinIcon from "../../../assests/icons/ic_pin_filled.svg";
import noTeamAdded from "../../../assests/icons/no_team_added.svg";
import IcDoneWhite from "../../../assests/icons/ic_done_white.svg";
import "./styles.scss";
import ProgressBarGraph from "../../../components/graph/progress_bar_graph";
import AllTeamsFilterTeamsDialog from "../../../dialogs/all_teams_dialog/all_teams_filter_teams_dialog";
import AllTeamsSortDialog from "../../../dialogs/all_teams_dialog/all_teams_sort_dialog";
import useWindowDimensions from "../../../utils/use_window_dimension";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import tick from "../../../assests/tick-green.svg";
import "react-date-range/dist/theme/default.css";
import AllTeamsRolesDialog from "../../../dialogs/all_teams_dialog/all_team_roles_dialog";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  ALL_TEAMS_SCREEN_ROUTE,
  TEAMS_MEMBERS_ROUTE,
  TEAM_ID_PATH_VARIABLE,
  TEAM_PAGE_ROUTE,
} from "../../../config/page_routes_constants";
import { getAllTeamsSummary } from "../../../_services/view.service";
import { pinteams } from "../../../_services/view.service";
import toaster from "../../../utils/toaster";
import { isEmpty } from "../../../utils/form_validators";
import Loader from "../../../components/common/loader";
import DatePickerModal from "../../../dialogs/date_picker_modal";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";
import {
  DEFAULT_CARD_SIZE,
  DEFAULT_PAGE_SIZE,
} from "../../../config/be_seikorAdmin_api_constants";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import InfiniteScroll from "react-infinite-scroll-component";
import { lastDayOfDecade } from "date-fns";

function AllTeamsScreen() {
  let isInfinite = false;

  const CUSTOM_DATE_OPTION = "Custom";
  const windowDimension = useWindowDimensions();
  const { teamId } = useParams();
  const navigate = useNavigate();

  const [arraySortIndex, setArraySortIndex] = useState(null);
  const [filledOpenRolesArraySortIndex, setFilledOpenRolesArraySortIndex] =
    useState(null);
  const [rolesArraySortIndex, setRolesArraySortIndex] = useState(null);
  const [pinSrc, setPinSrc] = useState(pinIcon);
  const [sortTableShow, setSortTableShow] = useState(false);
  const [filterTeamsShow, setFilterTeamsShow] = useState(false);
  const [filledOpenShow, setFilledOpenShow] = useState(false);
  const [pinUnpinStatus, setPinUnpinStatus] = useState("Pin");
  const [filterSelect, setFilterSelect] = useState("Open Roles");
  const [sortDropdownRedDot, setSortDropdownRedDot] = useState("d-none");
  const [roleDropdownRedDot, setRoleDropdownRedDot] = useState("d-none");
  const [timeDropdownRedDot, setTimeDropdownRedDot] = useState("d-none");
  const [displayDatePicker, setDisplayDatePicker] = useState("d-none");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);

  var d = new Date();
  d.setMonth(d.getMonth() - 3);

  // CURRENT DATE
  const current = new Date();
  // current.setMonth(current.getMonth() - 3);

  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const date = `${yyyy}-${mm}-${dd}`;

  console.log("date", date);

  // PAST 1 MONTHS
  const past1Months = new Date();
  past1Months.setMonth(past1Months.getMonth() - 1);
  var dd = String(past1Months.getDate()).padStart(2, "0");
  var mm = String(past1Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past1Months.getFullYear();
  const month1 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month1);

  // PAST 3 MONTHS
  const past3Months = new Date();
  past3Months.setMonth(past3Months.getMonth() - 3);
  var dd = String(past3Months.getDate()).padStart(2, "0");
  var mm = String(past3Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past3Months.getFullYear();
  const month3 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month3);

  // PAST 6 MONTHS
  const past6Months = new Date();
  past6Months.setMonth(past6Months.getMonth() - 6);
  var dd = String(past6Months.getDate()).padStart(2, "0");
  var mm = String(past6Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past6Months.getFullYear();
  const month6 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month6);

  // PAST 12 MONTHS
  const past12Months = new Date();
  past12Months.setMonth(past12Months.getMonth() - 12);
  var dd = String(past12Months.getDate()).padStart(2, "0");
  var mm = String(past12Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past12Months.getFullYear();
  const month12 = `${yyyy}-${mm}-${dd}`;

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [currentDate, setCurrentDate] = useState();

  useEffect(() => {
    const todaysDate = new Date();
    setCurrentDate(todaysDate);
  }, []);

  const showSelectedDate = (startDt, endDt) => {
    console.log("startDt, endDt", startDt, endDt);
    if (startDt) {
      const startDate = startDt?.toString()?.slice(4, 16);
      setCustomStartDate(startDate);
    }
    if (endDt) {
      const endDate = endDt?.toString()?.slice(4, 16);
      setCustomEndDate(endDate);
    }
  };

  const [firstDate, setfirstDate] = useState();
  const [secondDate, setsecondDate] = useState();

  const handleDateRange = (event) => {
    console.log("event", event);
    setDateRange([event?.selection]);
    let startDate = event?.selection?.startDate;
    let endDate = event?.selection?.endDate;
    showSelectedDate(startDate, endDate);
    console.log("startDate:", startDate);
    console.log("endDate:", endDate);

    let convertDate = JSON.stringify(startDate).slice(1, 11);
    let convertEndDate = JSON.stringify(endDate).slice(1, 11);

    setfirstDate(convertDate);
    setsecondDate(convertEndDate);
  };
  console.log("firstDate", firstDate);
  console.log("secondDate", secondDate);

  const onCustomDateSelect = (name) => {
    if (name == CUSTOM_DATE_OPTION) {
      setDisplayDatePicker("d-block");
    } else {
      setDisplayDatePicker("d-none");
    }
  };

  const filledOpenArray = [
    { name: "Filled Roles", value: "MOST_FILLED_ROLES" },
    { name: "Open Roles", value: "MOST_OPEN_ROLES" },
  ];

  const timelineArray = [
    { name: "All Time" },
    { name: "1 Month", value: month1 },
    { name: "3 Month", value: month3 },
    { name: "6 Months", value: month6 },
    { name: "12 Months", value: month12 },
    { name: CUSTOM_DATE_OPTION, value: CUSTOM_DATE_OPTION },
  ];

  const rolesArray = [
    { name: "Most Open Roles", value: "MOST_OPEN_ROLES" },
    { name: "Least Open Roles", value: "LEAST_OPEN_ROLES" },
    { name: "Most Filled Roles", value: "MOST_FILLED_ROLES" },
    { name: "Least Filled Roles", value: "LEAST_FILLED_ROLES" },
    { name: "Most Applications", value: "MOST_APPL" },
    { name: "Least Applications", value: "LEAST_APPL" },
    { name: "Most Referrals", value: "MOST_REFF" },
    { name: "Least Referrals", value: "LEAST_REFF" },
    { name: "Most Dropouts", value: "MOST_DROP" },
    { name: "Least Dropouts", value: "LEAST_DROP" },
    { name: "A-Z", value: "TITLE_A_Z" },
  ];

  const quickSelectFilter = [
    { name: "Open Roles", value: "MOST_FILLED_ROLES" },
    { name: "Applications", value: "MOST_APPL" },
    { name: "Referrals", value: "MOST_REFF" },
    { name: "Dropouts", value: "MOST_DROP" },
  ];

  // useEffect(() => {
  //   if (
  //     arraySortIndex == null ||
  //     customStartDate == "" ||
  //     customEndDate == ""
  //   ) {
  //     setTimeDropdownRedDot("d-none");
  //   } else {
  //     setTimeDropdownRedDot("d-block");
  //   }
  // }, [arraySortIndex]);

  useEffect(() => {
    if (rolesArraySortIndex == null) {
      setSortDropdownRedDot("d-none");
    } else {
      setSortDropdownRedDot("d-block");
    }
  }, [rolesArraySortIndex]);

  useEffect(() => {
    if (filledOpenRolesArraySortIndex == null) {
      setRoleDropdownRedDot("d-none");
    } else {
      setRoleDropdownRedDot("d-block");
    }
  }, [filledOpenRolesArraySortIndex]);

  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const [allTeamsFilterData, setAllTeamsFilterData] =
    useState(initialFilterData);
  const [allTeamsFilterDataParams, setAllTeamsFilterDataParams] =
    useSearchParams({
      pageNo: 1,
      pageSize: DEFAULT_CARD_SIZE,
    });

  const [currenListSet, setCurrenListSet] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [allJobMessage, setAllJobMessage] = useState("");
  const [allTeamSummary, setAllTeamSummary] = useState([]);
  const [fromDate, setFromDate] = useState();
  const [apireferesh, setApiRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const fetchAllTeams = (data) => {
    setShowLoader(true);
    getAllTeamsSummary(data).then(
      (res) => {
        if (res && !isEmpty(res?.data?.data)) {
          setDataCount(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...allTeamSummary, ...res?.data?.data];
          }
          setCurrenListSet(data?.length);
          setAllTeamSummary(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          // setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };

  const fetchMoreTeams = () => {
    setCurrenListSet(currenListSet + 5);
    isInfinite = true;
    fetchAllTeams({
      ...allTeamsFilterData,
      pageNo: allTeamsFilterData?.pageNo + 1,
    });
    setAllTeamsFilterData({
      ...allTeamsFilterData,
      pageNo: allTeamsFilterData?.pageNo + 1,
    });
  };

  useEffect(() => {
    let temp = { ...initialFilterData };
    for (let entry of allTeamsFilterDataParams.entries()) {
      if (entry[0] === "searchString") {
        temp = {
          ...temp,
          searchString: entry[1],
        };
      }
    }
    fetchAllTeams(temp);
  }, []);

  const dropdownSortHandler = (index, value) => {
    setRolesArraySortIndex(index);
    setAllTeamsFilterData({
      ...allTeamsFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    fetchAllTeams({
      ...allTeamsFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const dropdowntimelineArrayHandler = (index, value) => {
    if (arraySortIndex == index) {
      setArraySortIndex(null);
      setTimeDropdownRedDot("d-none");
      fetchAllTeams({
        ...allTeamsFilterData,
        fromDate: null,
        toDate: null,
        pageNo: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
    } else {
      if (value === CUSTOM_DATE_OPTION) {
        if (customStartDate == "" || customEndDate == "") {
          setTimeDropdownRedDot("d-none");
        }
        onCustomDateSelect(value);
      } else {
        setArraySortIndex(index);

        setTimeDropdownRedDot("d-block");
      }
    }
    if (rolesArraySortIndex == index) {
      setRolesArraySortIndex(null);
    } else {
      setRolesArraySortIndex(index);
      fetchAllTeams({
        ...allTeamsFilterData,
        fromDate: value,
        toDate: date,
        pageNo: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
    }
    setRolesArraySortIndex(value);
    setAllTeamsFilterData({
      ...allTeamsFilterData,
      fromDate: value,
      toDate: date,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const submitFormOnEnter = (e) => {
    console.log("search", e);
    if (e?.keyCode == 13) {
      setAllTeamsFilterDataParams(allTeamsFilterData);
      fetchAllTeams(allTeamsFilterData);
    }
  };

  // useEffect(() => {
  //   fetchAllTeams();
  //   if (apireferesh === true) {
  //     fetchAllTeams();
  //   }
  // }, [apireferesh]);

  const [pinCTeams, setPinCTeams] = useState(true);

  const pinnedTeams = (teamId, pinCTeams) => {
    setShowLoader(true);
    setApiRefresh(true);

    pinteams(teamId, pinCTeams)
      .then((res) => {
        setShowLoader(false);
        // if (res?.data?.status === STATUS_SUCCESS) {
        fetchAllTeams(allTeamsFilterData);
        toaster("success", res.data.message);
        setShowLoader(false);
        setApiRefresh(false);
        // setAllTeamSummary([]);

        // }
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
        toaster("error", err);
      });
  };

  // useEffect(() => {
  //   pinnedTeams(teamId);
  // }, []);

  const barData = [
    {
      entity: "Applied",
      entityValue:
        allTeamSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        allTeamSummary?.statusWiseData?.APPLIED !== ""
          ? allTeamSummary?.statusWiseData?.APPLIED
          : 0,
      color: "#1C1C1C",
    },
    {
      entity: "Shortlisted",
      entityValue:
        allTeamSummary?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
        allTeamSummary?.statusWiseData?.SHORTLISTED !== ""
          ? allTeamSummary?.statusWiseData?.SHORTLISTED
          : 0,
      color: "#3D81FF",
    },
    {
      entity: "Assessment",
      entityValue:
        allTeamSummary?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
        allTeamSummary?.statusWiseData?.ASSESSMENT !== ""
          ? allTeamSummary?.statusWiseData?.ASSESSMENT
          : 0,
      color: "#FFD027",
    },
    {
      entity: "Offered",
      entityValue:
        allTeamSummary?.statusWiseData?.hasOwnProperty("OFFERED") &&
        allTeamSummary?.statusWiseData?.OFFERED !== ""
          ? allTeamSummary?.statusWiseData?.OFFERED
          : 0,
      color: "#12BD6B",
    },
    {
      entity: "Joined",
      entityValue:
        allTeamSummary?.statusWiseData?.hasOwnProperty("JOINED") &&
        allTeamSummary?.statusWiseData?.JOINED !== ""
          ? allTeamSummary?.statusWiseData?.JOINED
          : 0,
      color: "#2FFF9B",
    },
    {
      entity: "Dropout",
      entityValue:
        allTeamSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        allTeamSummary?.statusWiseData?.RETRACTED !== ""
          ? allTeamSummary?.statusWiseData?.RETRACTED
          : 0,
      color: "#EB1260",
    },
    {
      entity: "Rejected",
      entityValue:
        allTeamSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        allTeamSummary?.statusWiseData?.REJECTED !== ""
          ? allTeamSummary?.statusWiseData?.REJECTED
          : 0,
      color: "#721212",
    },
  ];

  // const [barData, setBarData] = useState([
  //   { entity: "Applied", entityValue: "584", color: "#1C1C1C" },
  //   { entity: "Shortlisted", entityValue: "312", color: "#3D81FF" },
  //   { entity: "Assessment", entityValue: "258", color: "#FFD027" },
  //   { entity: "Offered", entityValue: "95", color: "#12BD6B" },
  //   { entity: "Joined", entityValue: "68", color: "#2FFF9B" },
  //   { entity: "Dropout", entityValue: "26", color: "#EB1260" },
  //   { entity: "Rejected", entityValue: "200", color: "#721212" },
  // ]);

  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin mt-4 h-100">
        {dataCount > 0 ? (
          // Teams added
          <div>
            <div className="d-flex justify-content-between mt-4 flex-sm-row flex-column  border-bottom pb-3">
              <div className="fw-700 fs-24">All Teams({dataCount})</div>
              <div className="d-flex pt-3 pt-sm-0 flex-sm-row flex-column">
                <div className="small-text-gray fw-400 fs-14 d-flex align-items-center">
                  Show stats for
                </div>
                <div className="d-flex align-items-center pt-3 pt-sm-0 d-flex gap-sm-2 justify-content-between ">
                  <div className="ms-sm-2">
                    <Dropdown
                      onClick={() => {
                        if (windowDimension?.width <= 576) {
                          setFilledOpenShow(true);
                        }
                      }}
                      className="all-teams-timeline-select-dropdown"
                    >
                      <Dropdown.Toggle
                        variant="none"
                        className="btn down-arrow timeline-select-button d-flex py-1 px-2 bg-transparent align-items-center justify-content-around"
                      >
                        <img className="ms-1" src={funnelFilterIcon} />
                        <span className="fs-12 ms-2">Roles</span>
                        <span
                          className={`${roleDropdownRedDot} round-red-dot rounded-circle mb-2 ms-1`}
                        ></span>
                        <img className="ms-2" src={downArrow} alt="" />
                      </Dropdown.Toggle>
                      <div className="d-none d-sm-block">
                        <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black ">
                          {filledOpenArray?.map((sortItem, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  dropdownSortHandler(index, sortItem?.value);
                                }}
                                key={index}
                                className="d-flex pb-3"
                              >
                                <img
                                  src={tick}
                                  alt="blackdone-icon"
                                  className={
                                    filledOpenRolesArraySortIndex === index
                                      ? "pe-2 d-block "
                                      : "d-none pe-1"
                                  }
                                />
                                <span
                                  className={
                                    filledOpenRolesArraySortIndex === index
                                      ? "ps-0 color-primary fw-700"
                                      : "ps-4"
                                  }
                                >
                                  {sortItem.name}
                                </span>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </div>
                    </Dropdown>
                  </div>
                  <div className="position-relative">
                    <div
                      className={`${displayDatePicker} custome-date-box custome-date-box-position p-3`}
                    >
                      <DateRange
                        ranges={dateRange}
                        onChange={(e) => {
                          handleDateRange(e);
                        }}
                        showSelectionPreview={true}
                        moveRangeOnFirstSelection={false}
                        maxDate={currentDate}
                      />
                      <div className="d-flex justify-content-end">
                        <button
                          style={{ border: "1px solid black", width: "80px" }}
                          className="btn bnt-light color-dark fs-14 p-0"
                          onClick={() => {
                            setDisplayDatePicker("d-none");
                            setArraySortIndex(null);
                            setTimeDropdownRedDot("d-none");
                          }}
                        >
                          Cancel
                        </button>
                        <button
                          style={{ border: "1px solid black", width: "80px" }}
                          className="btn bnt-light color-dark fs-14 p-0 ms-2 "
                          onClick={() => {
                            setDisplayDatePicker("d-none");
                            setTimeDropdownRedDot("d-block");
                            setArraySortIndex(5);
                            fetchAllTeams({
                              ...allTeamsFilterData,
                              fromDate: firstDate,
                              toDate: secondDate,
                              pageNo: 1,
                              pageSize: DEFAULT_PAGE_SIZE,
                            });
                          }}
                        >
                          OK
                        </button>
                      </div>
                    </div>
                    <Dropdown
                      onClick={() => {
                        if (windowDimension?.width <= 576) {
                          setSortTableShow(true);
                        }
                      }}
                      className="timeline-select-dropdown"
                    >
                      <Dropdown.Toggle
                        variant="none"
                        className="btn down-arrow timeline-select-button d-flex py-1 px-2 bg-transparent align-items-center justify-content-around"
                      >
                        <img className="me-1" src={funnelFilterIcon} />

                        <span className="fs-12">Time Interval</span>
                        <span
                          className={`${timeDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                        ></span>
                        <img className="ms-1" src={downArrow} alt="" />
                      </Dropdown.Toggle>
                      <div className="d-none d-sm-block">
                        <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                          {timelineArray?.map((item, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() => {
                                  dropdowntimelineArrayHandler(
                                    index,
                                    item?.value
                                  );
                                }}
                                key={index}
                                className="d-flex pb-3"
                              >
                                <div>
                                  <div className="d-flex">
                                    <img
                                      src={blackdone}
                                      alt="blackdone-icon"
                                      className={
                                        arraySortIndex === index
                                          ? "pe-2 d-block"
                                          : "d-none pe-1"
                                      }
                                    />
                                    <span
                                      className={
                                        arraySortIndex === index
                                          ? "ps-0 color-primary fw-700"
                                          : "ps-4"
                                      }
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                  {item?.name == CUSTOM_DATE_OPTION &&
                                  arraySortIndex == 5 &&
                                  customStartDate != "" &&
                                  customEndDate != "" ? (
                                    <div className="small-text-gray fs-12 mt-2">
                                      {`${customStartDate} to ${customEndDate}`}
                                    </div>
                                  ) : null}
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      </div>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>

            {/* Search Filter container start */}

            <div className="mt-3 all-teams-filter-search-div d-flex justify-content-between  border-bottom pb-3 align-items-center">
              <div className=" d-none d-sm-block w-100 ">
                <div className="d-flex w-100 ">
                  <div>
                    <Dropdown className="timeline-select-dropdown">
                      <Dropdown.Toggle
                        style={{ padding: "0.2rem 0.7rem" }}
                        variant="none"
                        // id="dropdown-basic"
                        className="btn down-arrow referee-filter-button d-flex bg-transparent align-items-center d-flex justify-content-around"
                      >
                        <img src={sortIcon} alt="" className="mt-1" />
                        <span className="fs-12">Sort By</span>
                        <span
                          className={`${sortDropdownRedDot} round-red-dot rounded-circle mb-2`}
                        ></span>
                        <img className="" src={downArrow} alt="" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                        {rolesArray?.map((item, index) => {
                          return (
                            <Dropdown.Item
                              onClick={() => {
                                if (rolesArraySortIndex == index) {
                                  // setRolesArraySortIndex(null);
                                  dropdownSortHandler(null, null);
                                } else {
                                  dropdownSortHandler(index, item?.value);
                                }
                              }}
                              key={index}
                              className="d-flex pb-3"
                            >
                              <img
                                src={blackdone}
                                alt="blackdone-icon"
                                className={
                                  rolesArraySortIndex === index
                                    ? "pe-2 d-block"
                                    : "d-none pe-1"
                                }
                              />
                              <span
                                className={
                                  rolesArraySortIndex === index
                                    ? "ps-0 color-primary fw-700"
                                    : "ps-4"
                                }
                              >
                                {item?.name}
                              </span>
                            </Dropdown.Item>
                          );
                        })}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div className="w-100 d-flex align-items-center  flex-wrap">
                    <div className="d-none d-xl-flex all-teams-quick-select-div justify-content-around gap-2 ms-2">
                      {quickSelectFilter?.map((filter, index) => {
                        return (
                          <button
                            key={index}
                            className="d-flex all-teams-quick-select-btn fw-400 fs-12 align-items-center justify-content-center"
                            onClick={() => {
                              setFilterSelect(filter.name);
                              dropdownSortHandler(index, filter?.value);
                            }}
                            style={{ color: "black" }}
                          >
                            <div
                              className={`${
                                filterSelect == filter.name
                                  ? "d-block"
                                  : "d-none"
                              }   bg-black rounded-circle me-1`}
                            >
                              <img
                                className="ps-1 me-1"
                                src={IcDoneWhite}
                                alt=""
                                height="16px"
                                width="16px"
                              />
                            </div>
                            {filter.name}
                          </button>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-relative pointer all-teams-search-div">
                <input
                  className="all-teams-search-input p-2 fs-12"
                  placeholder="Search"
                  defaultValue={allTeamsFilterData?.searchString}
                  onChange={(event) => {
                    setAllTeamsFilterData({
                      ...allTeamsFilterData,
                      searchString: event.target.value,
                      pageNo: 1,
                      pageSize: DEFAULT_PAGE_SIZE,
                    });
                  }}
                  onKeyDown={(e) => submitFormOnEnter(e)}
                />
                <img
                  className="referee-search-icon-position"
                  src={searchIcon}
                />
              </div>
            </div>
            {/* Search Filter container end */}

            {/* Teams cards start*/}
            <div className="row all-teams-cards-div mt-4 mb-5">
              {filledOpenRolesArraySortIndex === 1
                ? allTeamSummary?.map((team) => {
                    return (
                      <div className="col-12 col-sm-6 col-md-4 mb-4">
                        <div className="all-teams-card p-3">
                          <div className="d-flex justify-content-between fs-14 fw-600">
                            <div
                              className="color-primary mb-1"
                              style={{ height: "36px" }}
                            >
                              {team?.teamName}
                            </div>
                            <div className="d-flex">
                              <img
                                style={{ width: "10px", height: "14px" }}
                                src={team?.isPinned ? filledPinIcon : pinSrc}
                                className="pointer ms-2 me-4"
                                alt="unsaved pin"
                              />

                              <Dropdown className="job-template-dropdown">
                                <Dropdown.Toggle
                                  variant="none"
                                  id="dropdown-basic"
                                  className="d-flex p-0 m-0 border-0 bg-transparent job-template-icon"
                                >
                                  <img src={threeDotsIcon} alt="retract-icon" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                                  <Dropdown.Item className="d-flex pb-2 pt-2">
                                    <div
                                      onClick={() => {
                                        if (team?.isPinned == true) {
                                          // setPinSrc(filledPinIcon);
                                          // setPinUnpinStatus("Unpin");
                                          setPinCTeams(false);
                                          pinnedTeams(team?.teamId, false);
                                        } else {
                                          // setPinSrc(pinIcon);
                                          // setPinUnpinStatus("Pin");
                                          setPinCTeams(true);
                                          pinnedTeams(team?.teamId, true);
                                        }
                                      }}
                                    >
                                      <span className="">
                                        {team?.isPinned ? "Unpin" : "Pin"} Team
                                      </span>
                                    </div>
                                  </Dropdown.Item>
                                  <hr className="m-0 p-0" />
                                  <Dropdown.Item className="d-flex pb-2 pt-2">
                                    {/* <Link
                                      to={`/${TEAM_PAGE_ROUTE}/123`}
                                      className="link text-dark text-decoration-none"
                                    >
                                      <span className="">View Team</span>
                                    </Link> */}

                                    <div className="link text-dark text-decoration-none">
                                      <span className="">View Team</span>
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          {/* Card title end*/}

                          {/* Card graph */}
                          <div className="mt-2 mb-2">
                            <ProgressBarGraph
                              data={[
                                {
                                  entity: "Applied",
                                  entityValue:
                                    team?.statusWiseData?.hasOwnProperty(
                                      "APPLIED"
                                    ) && team?.statusWiseData?.APPLIED !== ""
                                      ? team?.statusWiseData?.APPLIED
                                      : 0,
                                  color: "#1C1C1C",
                                },
                                {
                                  entity: "Shortlisted",
                                  entityValue:
                                    team?.statusWiseData?.hasOwnProperty(
                                      "SHORTLISTED"
                                    ) &&
                                    team?.statusWiseData?.SHORTLISTED !== ""
                                      ? team?.statusWiseData?.SHORTLISTED
                                      : 0,
                                  color: "#3D81FF",
                                },
                                {
                                  entity: "Assessment",
                                  entityValue:
                                    team?.statusWiseData?.hasOwnProperty(
                                      "ASSESSMENT"
                                    ) && team?.statusWiseData?.ASSESSMENT !== ""
                                      ? team?.statusWiseData?.ASSESSMENT
                                      : 0,
                                  color: "#FFD027",
                                },
                                {
                                  entity: "Offered",
                                  entityValue:
                                    team?.statusWiseData?.hasOwnProperty(
                                      "OFFERED"
                                    ) && team?.statusWiseData?.OFFERED !== ""
                                      ? team?.statusWiseData?.OFFERED
                                      : 0,
                                  color: "#12BD6B",
                                },
                                {
                                  entity: "Joined",
                                  entityValue:
                                    team?.statusWiseData?.hasOwnProperty(
                                      "JOINED"
                                    ) && team?.statusWiseData?.JOINED !== ""
                                      ? team?.statusWiseData?.JOINED
                                      : 0,
                                  color: "#2FFF9B",
                                },
                                {
                                  entity: "Dropout",
                                  entityValue:
                                    team?.statusWiseData?.hasOwnProperty(
                                      "RETRACTED"
                                    ) && team?.statusWiseData?.RETRACTED !== ""
                                      ? team?.statusWiseData?.RETRACTED
                                      : 0,
                                  color: "#EB1260",
                                },
                                {
                                  entity: "Rejected",
                                  entityValue:
                                    team?.statusWiseData?.hasOwnProperty(
                                      "REJECTED"
                                    ) && team?.statusWiseData?.REJECTED !== ""
                                      ? team?.statusWiseData?.REJECTED
                                      : 0,
                                  color: "#721212",
                                },
                              ]}
                              height={"7"}
                            />
                          </div>
                          {/* Card graph end */}

                          {/* Card stats */}
                          <div className="mt-2 fs-11 fw-300 color-tertiary">
                            <div className="d-flex justify-content-between">
                              <div>Open Roles</div>
                              <div className="fs-12 fw-700 color-secondary">
                                {team.rolesOpen}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <div>Applied</div>
                              <div className="fs-12 fw-700 color-secondary">
                                {team.appliedCount}
                              </div>
                            </div>
                            <div className="d-flex justify-content-between mt-2">
                              <div>
                                Referrals ({`${Math.round((team?.referralsCount / team?.appliedCount) * 100)}%`})
                              </div>
                              <div className="fs-12 fw-700 color-secondary">
                                {team.referralsCount}
                              </div>
                            </div>
                          </div>
                          {/* Card stats end */}
                        </div>
                      </div>
                    );
                  })
                : allTeamSummary?.map((team, index) => {
                    return (
                      <div className="col-12 col-sm-6 col-md-4 mb-4">
                        <InfiniteScroll
                          dataLength={currenListSet}
                          next={() => fetchMoreTeams()}
                          hasMore={dataCount > currenListSet}
                          className="container-fluid"
                        >
                          <div className="all-teams-card p-3">
                            <div className="d-flex justify-content-between fs-14 fw-600">
                              <div
                                className="color-primary mb-1 mt-1"
                                style={{ height: "36px" }}
                              >
                                {team?.teamName}
                              </div>
                              <div className="d-flex ">
                                <div className="border-0  job-template-icon d-flex justify-items-center align-items-center px-2 h-20 pointer">
                                  <img
                                    style={{ width: "10px", height: "20px" }}
                                    src={
                                      team?.isPinned ? filledPinIcon : pinSrc
                                    }
                                    className="pointer ms-2 me-2"
                                    alt="unsaved pin"
                                    onClick={() => {
                                      if (team?.isPinned == true) {
                                        // setPinSrc(filledPinIcon);
                                        // setPinUnpinStatus("Unpin");
                                        setPinCTeams(false);
                                        pinnedTeams(team?.teamId, false);
                                      } else {
                                        // setPinSrc(pinIcon);
                                        // setPinUnpinStatus("Pin");
                                        setPinCTeams(true);
                                        pinnedTeams(team?.teamId, true);
                                      }
                                    }}
                                  />
                                </div>
                                <Dropdown className="job-template-dropdown ">
                                  <Dropdown.Toggle
                                    variant="none"
                                    id="dropdown-basic"
                                    className="border-0  job-template-icon d-flex justify-items-center align-items-center px-2 h-20"
                                  >
                                    <img
                                      src={threeDotsIcon}
                                      alt="retract-icon"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="fs-12 text-secondary mt-1 border-dark ">
                                    <Dropdown.Item
                                      onClick={() => {
                                        if (team?.isPinned == true) {
                                          // setPinSrc(filledPinIcon);
                                          // setPinUnpinStatus("Unpin");
                                          setPinCTeams(false);
                                          pinnedTeams(team?.teamId, false);
                                        } else {
                                          // setPinSrc(pinIcon);
                                          // setPinUnpinStatus("Pin");
                                          setPinCTeams(true);
                                          pinnedTeams(team?.teamId, true);
                                        }
                                      }}
                                      className="d-flex pb-2 pt-2"
                                    >
                                      <div>
                                        <span className="">
                                          {team?.isPinned ? "Unpin" : "Pin"}{" "}
                                          Team
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                    <hr className="m-0 p-0" />
                                    <Dropdown.Item className="d-flex pb-2 pt-2">
                                      <Link
                                        to={`/${TEAM_PAGE_ROUTE}/${
                                          team?.teamId
                                        }/${index + 1}`}
                                        className="link text-dark text-decoration-none"
                                      >
                                        <span className="">View Team</span>
                                      </Link>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {/* Card title end*/}

                            {/* Card graph */}
                            <div className="mt-2 mb-2">
                              <ProgressBarGraph
                                data={[
                                  {
                                    entity: "Applied",
                                    entityValue:
                                      team?.statusWiseData?.hasOwnProperty(
                                        "APPLIED"
                                      ) && team?.statusWiseData?.APPLIED !== ""
                                        ? team?.statusWiseData?.APPLIED
                                        : 0,
                                    color: "#1C1C1C",
                                  },
                                  {
                                    entity: "Shortlisted",
                                    entityValue:
                                      team?.statusWiseData?.hasOwnProperty(
                                        "SHORTLISTED"
                                      ) &&
                                      team?.statusWiseData?.SHORTLISTED !== ""
                                        ? team?.statusWiseData?.SHORTLISTED
                                        : 0,
                                    color: "#3D81FF",
                                  },
                                  {
                                    entity: "Assessment",
                                    entityValue:
                                      team?.statusWiseData?.hasOwnProperty(
                                        "ASSESSMENT"
                                      ) &&
                                      team?.statusWiseData?.ASSESSMENT !== ""
                                        ? team?.statusWiseData?.ASSESSMENT
                                        : 0,
                                    color: "#FFD027",
                                  },
                                  {
                                    entity: "Offered",
                                    entityValue:
                                      team?.statusWiseData?.hasOwnProperty(
                                        "OFFERED"
                                      ) && team?.statusWiseData?.OFFERED !== ""
                                        ? team?.statusWiseData?.OFFERED
                                        : 0,
                                    color: "#12BD6B",
                                  },
                                  {
                                    entity: "Joined",
                                    entityValue:
                                      team?.statusWiseData?.hasOwnProperty(
                                        "JOINED"
                                      ) && team?.statusWiseData?.JOINED !== ""
                                        ? team?.statusWiseData?.JOINED
                                        : 0,
                                    color: "#2FFF9B",
                                  },
                                  {
                                    entity: "Dropout",
                                    entityValue:
                                      team?.statusWiseData?.hasOwnProperty(
                                        "RETRACTED"
                                      ) &&
                                      team?.statusWiseData?.RETRACTED !== ""
                                        ? team?.statusWiseData?.RETRACTED
                                        : 0,
                                    color: "#EB1260",
                                  },
                                  {
                                    entity: "Rejected",
                                    entityValue:
                                      team?.statusWiseData?.hasOwnProperty(
                                        "REJECTED"
                                      ) && team?.statusWiseData?.REJECTED !== ""
                                        ? team?.statusWiseData?.REJECTED
                                        : 0,
                                    color: "#721212",
                                  },
                                ]}
                                height={"7"}
                              />
                            </div>
                            {/* Card graph end */}

                            {/* Card stats */}
                            <div className="mt-2 fs-11 fw-300 color-tertiary">
                              <div className="d-flex justify-content-between">
                                <div>Filled Roles</div>
                                <div className="fs-12 fw-700 color-secondary">
                                  {team.rolesFilled}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                                <div>Applied</div>
                                <div className="fs-12 fw-700 color-secondary">
                                  {team.appliedCount}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between mt-2">
                              {team.appliedCount !== 0 && team.referralsCount !== 0 ? (
                                <div>
                                Referrals ({`${Math.round((team?.referralsCount / team?.appliedCount) * 100)}%`})
                                </div>
                              ):(
                                <div>
                                Referrals (0%)
                              </div>
                              )}
                                <div className="fs-12 fw-700 color-secondary">
                                  {team.referralsCount}
                                </div>
                              </div>
                            </div>
                            {/* Card stats end */}
                          </div>
                        </InfiniteScroll>
                      </div>
                    );
                  })}
            </div>
            {/* Teams cards end*/}
            {/* Mobile bottom sort */}

            <div className="d-block d-sm-none referee-fixed-sort-div">
              <div className="d-flex justify-content-center align-items-center">
                <div
                  className="d-flex p-2 pointer"
                  onClick={() => setFilterTeamsShow(true)}
                >
                  <div>
                    <img alt="sort-icon" src={sortIcon} />
                  </div>
                  <div className="color-primary fs-12 ms-1 d-flex align-items-center ">
                    Sort By
                  </div>
                  <span
                    className={`${sortDropdownRedDot} round-red-dot rounded-circle ms-1 mt-1`}
                  ></span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // No Teams added

          <div className="h-100">
            <div className="mt-4">
              <div className="fw-700 fs-20">All Teams({dataCount})</div>
            </div>
            <hr />
            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
              <div className="d-flex">
                <div className="me-3 mb-1 d-flex align-items-end">
                  <img src={noTeamAdded} />
                </div>
                <div className="">
                  <div className="small-text-gray fw-400 fs-20">
                    No teams created
                  </div>
                  <div className="small-text-gray fw-400 fs-14">
                    Start by adding a team
                  </div>
                </div>
              </div>
              <div className="mt-3">
                <Link
                  to={"/" + TEAMS_MEMBERS_ROUTE}
                  className="btn btn-light color-dark fw-700 fs-12 manage-teams-button"
                >
                  Manage Teams
                </Link>
              </div>
            </div>
          </div>
        )}
        {/* Dialogs */}
        <AllTeamsFilterTeamsDialog
          show={filterTeamsShow}
          onSortClose={() => setFilterTeamsShow(false)}
          sortArray={rolesArray}
          rolesArraySortIndex={rolesArraySortIndex}
          setRolesArraySortIndex={setRolesArraySortIndex}
          dropdownSortHandler={dropdownSortHandler}
        />
        <AllTeamsSortDialog
          show={sortTableShow}
          onSortClose={() => setSortTableShow(false)}
          sortArray={timelineArray}
          arraySortIndex={arraySortIndex}
          setArraySortIndex={setArraySortIndex}
          showDatePickerModal={showDatePickerModal}
          setShowDatePickerModal={setShowDatePickerModal}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          setCustomStartDate={setCustomStartDate}
          setCustomEndDate={setCustomEndDate}
          displayDatePicker={displayDatePicker}
          setDisplayDatePicker={setDisplayDatePicker}
          setTimeDropdownRedDot={setTimeDropdownRedDot}
          dropdowntimelineArrayHandler={dropdowntimelineArrayHandler}
        />
        <AllTeamsRolesDialog
          show={filledOpenShow}
          onSortClose={() => setFilledOpenShow(false)}
          sortArray={filledOpenArray}
          filledOpenRolesArraySortIndex={filledOpenRolesArraySortIndex}
          setFilledOpenRolesArraySortIndex={setFilledOpenRolesArraySortIndex}
          dropdownSortHandler={dropdownSortHandler}
        />
        <DatePickerModal
          showDatePickerModal={showDatePickerModal}
          setShowDatePickerModal={setShowDatePickerModal}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          setCustomStartDate={setCustomStartDate}
          setCustomEndDate={setCustomEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          currentDate={currentDate}
          displayDatePicker={displayDatePicker}
          setDisplayDatePicker={setDisplayDatePicker}
          setArraySortIndex={setArraySortIndex}
          handleDateRange={handleDateRange}
          setTimeDropdownRedDot={setTimeDropdownRedDot}
          fetchAllTeams={fetchAllTeams}
          allTeamsFilterData={allTeamsFilterData}
        />
      </div>
    </div>
  );
}

export default AllTeamsScreen;
