import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import icThreeDots from "../../../assests/icons/ic_retract.svg";
import icDotsBlack from "../../../assests/icons/ic-dots-black.svg";
import { USER_ID, USER_ROLE } from "../../../config/be_api_constants";
import {
  NOTIFICATION_TO_CANDIDATE_PAGE_ROUTE,
  NOTIFICATION_TO_REFEREE_PAGE_ROUTE,
} from "../../../config/page_routes_constants";
import icPlusIcon from "../../../assests/icons/ic-plus-icon.svg";
import IcDoneWhite from "../../../assests/icons/ic_done_white.svg";
import icClosePink from "./../../../assests/icons/ic-close-pink.svg";
import icDeleteRed from "../../../assests/icons/ic-delete-red.svg";
import aboutEmployer1 from "../../../assests/images/aboutemployer3.png";

import {
  changeJobStatus,
  downloadFile,
  uploadFile,
  viewPreviousJob,
} from "../../../_services/view.service";
import {
  referee,
  refereeStatus,
  refreeList,
} from "../../../_services/member-profile.service";
import {
  APPLY_STATUS,
  SOMETHING_WENT_WRONG,
  STATUS_SUCCESS,
} from "../../../config/localstorage_keys";
import {
  EMAIL_PATTERN,
  REQUIRED,
} from "../../../config/validation_patterns_constants";
import { getLocalStorage } from "../../../utils/storage";
import toaster from "../../../utils/toaster";
import {
  FORM_VALIDATION_MESSAGE,
  GENERAL_ERROR_MESSAGE,
  IMAGE_DIMENSION,
  IMAGE_DIMENSION_ACTION,
  IMAGE_SIZE_EXCEEDING,
  INVALID_IMAGE_TYPE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import {
  isEmailValid,
  onFormFeildsChange,
  validateField,
} from "../../../utils/form_validators";
import { emailValidation } from "../../../_services/candidate.service";
import {
  addJobTags,
  deleteImage,
  getJobPromotion,
  getJobTags,
  jobPromotion,
} from "../../../_services/serikoradmin_view.service";
import Dropzone from "react-dropzone";
import ProfileImage from "../../../components/profile_image";
import SearchComboBox from "../../../components/SearchComboBox";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../../components/common/loader";
import PromoteIcon from "../../../assests/PromoteIcon.png";
import CloseJob from "../../../assests/CloseJob.png";

const ActionsComponent = () => {
  let isInfinite = false;

  const [showBar, setShowBar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [refereeOverview, setRefereeOverview] = useState({});
  const [allEmail, setAllEmail] = useState([]);
  const [profileSrc, setProfileSrc] = useState(null);
  const current = new Date();
  const [status, setStatus] = useState();
  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const date = `${dd}-${mm}-${yyyy}`;

  const [promotionStartDate, setPromotionStartDate] = useState("");
  const Character_Limit = 250;
  const [tagLineField, setTagLineField] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [total, setTotal] = useState("");

  const [Jobdata, setJobData] = useState();
  const [promotionalImagesUrls, setpromotionalImagesUrls] = useState([]);

  const handleChange = (event) => {
    if (event.target.value.length >= Character_Limit) {
      setTotal(event.target.value.length - Character_Limit);
      setIsActive({ isActive: true });
    } else {
      setIsActive();
    }
    setTagLineField(event.target.value);
  };

  const role = localStorage.getItem("role");
  const [selectedRole, setSelectedRole] = useState();

  const [promotionFixedDate, setPromotionFixedDate] = useState();
  const [isPromoted, setIsPromoted] = useState(true);

  const featuredRoles = ["Fixed Date"];
  const [isEmpty1, setIsEmpty1] = useState(true);
  const [isEmpty, setIsEmpty] = useState(true);
  const [isImage1, setIsImage1] = useState(true);
  const [isImage2, setIsImage2] = useState(true);

  const [mainImageLink, setMainImageLink] = useState(null);
  const [imageLink1, setImageLink1] = useState(null);
  const [imageLink2, setImageLink2] = useState(null);

  const [isPublished, setIsPublished] = useState(true);
  const { jobId } = useParams();

  const [previewRequested, setPreviewRequested] = useState(false);
  const [previewRequested1, setPreviewRequested1] = useState(false);
  const [previewRequested2, setPreviewRequested2] = useState(false);

  const [deleteFlag, setDeleteFlag] = useState(false);
  const [deleteFlag1, setDeleteFlag1] = useState(false);
  const [deleteFlag2, setDeleteFlag2] = useState(false);

  const [previewSrc, setPreviewSrc] = useState(null);
  const [previewSrc1, setPreviewSrc1] = useState(null);
  const [previewSrc2, setPreviewSrc2] = useState(null);

  const [profilePicId1, setprofilePicId1] = useState(null);
  const [profilePicId2, setprofilePicId2] = useState(null);
  const [profilePicId, setprofilePicId] = useState(null);

  const [promotionEndDate, setPromotionEndDate] = useState();

  const [selected_JobTags, setSelected_JobTags] = useState("");

  const specializedOutput = ["Actively Hiring"];

  const [addEmail, setAddEmail] = useState();
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const [TagLine, setTagLine] = useState();
  const disablePastDate = () => {
    const today = new Date();
    console.log(today);
    const dd = String(today.getDate()).padStart(2, "0");
    console.log(dd);

    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const emailValidationObject = {
    valueText: "",
    errorText: "",
    check: [REQUIRED, EMAIL_PATTERN],
  };
  const [formData, setFormData] = useState({
    email: [emailValidationObject],
    text: {
      valueText: "",
      errorText: "",
      check: ["required"],
    },
  });

  const initialFilterData = {
    mType: "JOB_TAGS",
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const [allJobsTags, setAllJobsTags] = useState([]);
  const [checkTag, setCheckTag] = useState(true);
  const [emailCheck, setEmailCheck] = useState(false);

  // console.log("emailCheck", emailCheck);

  const fetchAllTags = (data) => {
    getJobTags(data).then((res) => {
      let Job_Tags = res?.data?.data?.map((el) => {
        return { name: el.masterValue, id: el.id };
      });
      setAllJobsTags(Job_Tags);
      // setAllJobsTags(!isEmpty(Job_Tags));
    });
  };

  const PublishJobTag = () => {
    let tagTitle = selected_JobTags.toString();
    if (selected_JobTags !== "") {
      addJobTags(jobId, tagTitle).then((res) => {
        toaster("success", res?.data?.message);
        setCheckTag(false);
      });
    } else {
      toaster("error", "Please Select Job Tag");
    }
  };

  const RemoveJobTag = () => {
    let tagTitle = "";
    addJobTags(jobId, tagTitle).then((res) => {
      toaster("success", "Tag title remove Successfully");
      setSelected_JobTags(tagTitle);
      setCheckTag(!checkTag);
      setSelected_JobTags("");
      setDefaultValue_JobTag([]);
    });
  };

  const handleFormChange = (index, event) => {
    // event.preventDefault();
    const newAllEmails = [...allEmail];
    newAllEmails[index].email = event.target.value;
    setAllEmail(newAllEmails);
    setFormData({
      ...formData,
      email: [...formData?.email, emailValidationObject],
    });
  };
  const initializeEmailData = () => {
    const newAllEmails = [];
    newAllEmails.push({ email: "" });
    setAllEmail(newAllEmails);
  };
  useEffect(() => {
    fetchAllTags(initialFilterData);
    initializeEmailData();
    setPromotionStartDate(date);
  }, []);
  const [apiRefresh, setApiRefresh] = useState(false);

  const sendReferral = () => {
    let isValid = true;

    allEmail?.forEach((key) => {
      if (key?.email == "") {
        isValid = false;
      }
    });

    // setShowLoader(true);
    // setApiRefresh(true);
    // const Emails = allEmail.map((item) => item.email);
    if (isValid && formData?.message?.valueText !== "") {
      // const request = {
      //   email: [formData?.email?.valueText],
      //   message: formData?.message?.valueText,
      // };
      const text = formData?.text?.valueText;
      const Emails = allEmail.map((item) => item.email);
      const request = { email: Emails, message: formData?.message?.valueText };

      referee(request)
        .then((res) => {
          const userId = getLocalStorage(USER_ID);
          if (userId) {
            saveCandidateDetails(userId);
          }
          if (res?.data?.status == STATUS_SUCCESS) {
            toaster(
              "success",
              res?.data?.message ? res?.data?.message : STATUS_SUCCESS
            );
            setShowLoader(false);
            fetchReferee(allListFilterData);
            // setFormData({
            //   email: {
            //     valueText: "",
            //   },
            // });
            setShowLoader(false);
            if (apiRefresh) {
              setApiRefresh(false);
            } else {
              setApiRefresh(true);
            }
            initializeEmailData();

            setFormData({
              email: {
                valueText: "",
              },
              text: {
                valueText: "",
              },
            });

            toaster("success", "Invite refreess succesfully!!");
          } else {
            toaster(
              "error",
              res?.data?.message ? res?.data?.message : GENERAL_ERROR_MESSAGE
            );
            setShowLoader(false);
            setApiRefresh(false);
          }
        })
        .catch((err) => {
          setShowLoader(false);

          toaster(
            "error",
            err?.data?.message ? err?.data?.message : GENERAL_ERROR_MESSAGE
          );
        });
    } else {
      toaster("error", "Enter All fields required");
    }
  };

  const [dataCountInvite, setDataCountInvite] = useState(0);
  const [currentListSet, setCurrentListSet] = useState(0);
  const [allListFilterData, setAllListFilterData] = useState(initialFilterData);
  const [allJobMessage, setAllJobMessage] = useState("");
  const [defaultValue_JobTag, setDefaultValue_JobTag] = useState("");

  const fetchReferee = (data) => {
    setShowLoader(true);
    refreeList(data).then(
      (res) => {
        if (res?.data.status === STATUS_SUCCESS) {
          setDataCountInvite(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...refereeOverview, ...res?.data?.data];
          }
          setCurrentListSet(data?.length);
          setRefereeOverview(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setRefereeOverview([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };

  const viewJob = () => {
    viewPreviousJob(jobId).then((res) => {
      if (
        res?.data?.data?.tagTitle !== null ||
        res?.data?.data?.tagTitle !== undefined ||
        res?.data?.data?.tagTitle !== [""]
      )
        setDefaultValue_JobTag(!isEmpty([res?.data?.data?.tagTitle]));
      if (res?.data?.data?.isPromotion) {
        setIsPublished(false);
      }
      setCheckTag(false);
    });
  };

  const fetchMoreInvite = () => {
    setCurrentListSet(currentListSet + 10);
    isInfinite = true;
    fetchReferee({
      ...allListFilterData,
      pageNo: allListFilterData?.pageNo + 1,
    });
    setAllListFilterData({
      ...allListFilterData,
      pageNo: allListFilterData?.pageNo + 1,
    });
  };

  useEffect(() => {
    viewJob();
    fetchReferee(initialFilterData);
  }, [apiRefresh]);

  // useEffect(() => {
  //   if (apiRefresh === true) {
  //     fetchReferee(allListFilterData);
  //     setApiRefresh(false);
  //   }
  // }, [apiRefresh]);
  useEffect(() => {
    let temp = { ...initialFilterData };
    refreeList(temp);
    setAllListFilterData(temp);
  }, []);

  const [pictureData, setPictureData] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });
  const [files, setFiles] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });

  const Status = (status, id) => {
    setShowLoader(true);
    refereeStatus(id, status)
      .then((res) => {
        // setApiRefresh(false);
        setShowLoader(false);
        if (apiRefresh) {
          setApiRefresh(false);
        } else {
          setApiRefresh(true);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
        toaster("Error", err);
      });
  };

  // const updateProfilePictureApiCall = (response) => {
  //   setpotentialImages(response?.id);
  //   const profilePicUpdateObject = {
  //     potentialImages: [response?.id],
  //     companyId: props.companyId,
  //   };
  //   //use compnay image api call here
  //   create(profilePicUpdateObject)
  //     .then((data) => {
  //       props?.setApiRefresh(true);
  //       // const userId = getLocalStorage(USER_ID);
  //       // if (userId) {
  //       //   saveCandidateDetails(userId);
  //       // }
  //       // props?.setProfileModalShow(false);
  //       // setTimeout(() => {
  //       setShowLoader(false);
  //       toaster("success", "Profile picture updated successfully!");
  //       // }, 1000);
  //     })
  //     .catch((err) => {
  //       props?.setApiRefresh(false);
  //       setShowLoader(false);
  //       // toaster(
  //       //   "error",
  //       //   "Couldn't update profile picture, please try again later"
  //       // );
  //     });
  // };

  const onUploadPicture = (acceptedFiles) => {
    setFiles({
      ...files,
      acceptedFiles,
      // fileName,
      // extension,
    });
    if (acceptedFiles && acceptedFiles?.length >= 1) {
      var image = new Image();
      let isValid = true;

      const fileSize = acceptedFiles[0]?.size;
      const fileName = acceptedFiles[0]?.name;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension = fileName.slice(extensionIndex + 1);
      const isFileExtValid =
        fileName.includes(".png") ||
        fileName.includes(".jpg") ||
        fileName.includes(".jpeg");

      if (!isFileExtValid) {
        toaster("error", INVALID_IMAGE_TYPE);
        setPreviewRequested(false);
        return;
      }

      const isFileSizeValid = fileSize < 2e6;

      if (!isFileSizeValid) {
        toaster("error", IMAGE_SIZE_EXCEEDING);
        setPreviewRequested(false);
        return;
      }

      if (isFileExtValid && isFileSizeValid) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid) {
        let dimensionValid = false;

        setPictureData({
          ...pictureData,
          acceptedFiles,
          fileName,
          extension,
        });
        let previewImage = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });

        image.src = previewImage?.preview;
        image.onload = function () {
          const imageProportion = image.width / image.height;
          if (
            (image.width <= 700 && image.height <= 500) ||
            (imageProportion >= 1.5 && imageProportion <= 1.7)
          ) {
            dimensionValid = true;
          }
          if (isValid && dimensionValid) {
            setIsEmpty(false);
            setPreviewRequested(true);
            setPreviewSrc(previewImage?.preview);
            setDeleteFlag(false);
            ImageValue(acceptedFiles);
          } else {
            toaster("error", IMAGE_DIMENSION_ACTION);
            setPreviewRequested(false);
          }
        };
      }
    } else {
      toaster("error", GENERAL_ERROR_MESSAGE);
      setPreviewRequested(false);
    }
  };

  const onUploadimage1 = (acceptedFiles) => {
    setFiles({
      ...files,
      acceptedFiles,
      // fileName,
      // extension,
    });
    if (acceptedFiles && acceptedFiles?.length >= 1) {
      var image = new Image();
      let isValid = true;

      const fileSize = acceptedFiles[0]?.size;
      const fileName = acceptedFiles[0]?.name;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension = fileName.slice(extensionIndex + 1);
      const isFileExtValid =
        fileName.includes(".png") ||
        fileName.includes(".jpg") ||
        fileName.includes(".jpeg");

      if (!isFileExtValid) {
        toaster("error", INVALID_IMAGE_TYPE);
        setPreviewRequested1(false);
        return;
      }

      const isFileSizeValid = fileSize < 2e6;

      if (!isFileSizeValid) {
        toaster("error", IMAGE_SIZE_EXCEEDING);
        setPreviewRequested1(false);
        return;
      }

      if (isFileExtValid && isFileSizeValid) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid) {
        let dimensionValid = false;

        setPictureData({
          ...pictureData,
          acceptedFiles,
          fileName,
          extension,
        });
        let previewImage = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });

        image.src = previewImage?.preview;
        image.onload = function () {
          if (image.width <= 200 && image.height <= 200) {
            dimensionValid = true;
          }
          if (isValid && dimensionValid) {
            ImageValue1(acceptedFiles);
            setIsImage1(false);
            setPreviewRequested1(true);
            setPreviewSrc1(previewImage?.preview);
            setDeleteFlag1(false);
          } else {
            toaster("error", IMAGE_DIMENSION);
            setPreviewRequested1(false);
          }
        };
      }
    } else {
      toaster("error", GENERAL_ERROR_MESSAGE);
      setPreviewRequested1(false);
    }
  };

  const onUploadimage2 = (acceptedFiles) => {
    setFiles({
      ...files,
      acceptedFiles,
      // fileName,
      // extension,
    });
    if (acceptedFiles && acceptedFiles?.length >= 1) {
      var image = new Image();
      let isValid = true;

      const fileSize = acceptedFiles[0]?.size;
      const fileName = acceptedFiles[0]?.name;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension = fileName.slice(extensionIndex + 1);
      const isFileExtValid =
        fileName.includes(".png") ||
        fileName.includes(".jpg") ||
        fileName.includes(".jpeg");

      if (!isFileExtValid) {
        toaster("error", INVALID_IMAGE_TYPE);
        setPreviewRequested2(false);
        return;
      }

      const isFileSizeValid = fileSize < 2e6;

      if (!isFileSizeValid) {
        toaster("error", IMAGE_SIZE_EXCEEDING);
        setPreviewRequested2(false);
        return;
      }

      if (isFileExtValid && isFileSizeValid) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid) {
        let dimensionValid = false;

        setPictureData({
          ...pictureData,
          acceptedFiles,
          fileName,
          extension,
        });
        let previewImage = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });

        image.src = previewImage?.preview;
        image.onload = function () {
          if (image.width <= 200 && image.height <= 200) {
            dimensionValid = true;
          }
          if (isValid && dimensionValid) {
            ImageValue2(acceptedFiles);
            setIsImage2(false);
            setPreviewRequested2(true);
            setPreviewSrc2(previewImage?.preview);
            setDeleteFlag2(false);
          } else {
            toaster("error", IMAGE_DIMENSION);
            setPreviewRequested2(false);
          }
        };
      }
    } else {
      toaster("error", GENERAL_ERROR_MESSAGE);
      setPreviewRequested2(false);
    }
  };

  const ImageValue = (acceptedFiles) => {
    let formData = new FormData();
    const fileObjects = acceptedFiles?.map((file) => {
      formData.append("contentData", file);
      formData.append(
        "contentMetaData",
        JSON.stringify({
          contentType: "PROFILE",
          contentName: `${files?.fileName}`,
          contentExtention: `${files?.extension}`,
        })
      );
    });

    uploadFile(formData)
      .then((response) => {
        if (response?.id) {
          setprofilePicId(response?.id);
          // setIsEmpty(false);
          // AddJobPromotion(profilePicId);

          // AddJobPromotion(response1);
          // AddJobPromotion(response2);
        } else {
          setShowLoader(false);
          toaster("error", SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          error?.message ? error?.message : SOMETHING_WENT_WRONG
        );
        setShowLoader(false);
        setPreviewRequested(false);
        // setIsEmpty(true);
      });
  };

  const ImageValue1 = (acceptedFiles) => {
    let formData = new FormData();
    const fileObjects = acceptedFiles?.map((file) => {
      formData.append("contentData", file);
      formData.append(
        "contentMetaData",
        JSON.stringify({
          contentType: "PROFILE",
          contentName: `${files?.fileName}`,
          contentExtention: `${files?.extension}`,
        })
      );
    });

    uploadFile(formData)
      .then((response1) => {
        if (response1?.id) {
          setprofilePicId1(response1?.id);
          // setIsEmpty(false);
          // AddJobPromotion(profilePicId1);
        } else {
          setShowLoader(false);
          toaster("error", SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          error?.message ? error?.message : SOMETHING_WENT_WRONG
        );
        setShowLoader(false);
        setPreviewRequested1(false);
        // setIsImage1(true);
      });
  };

  const ImageValue2 = (acceptedFiles) => {
    let formData = new FormData();
    const fileObjects = acceptedFiles?.map((file) => {
      formData.append("contentData", file);
      formData.append(
        "contentMetaData",
        JSON.stringify({
          contentType: "PROFILE",
          contentName: `${files?.fileName}`,
          contentExtention: `${files?.extension}`,
        })
      );
    });

    uploadFile(formData)
      .then((response2) => {
        if (response2?.id) {
          setprofilePicId2(response2?.id);
          // setIsEmpty(false);
          // AddJobPromotion(profilePicId2);
        } else {
          setShowLoader(false);
          toaster("error", SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        toaster(
          "error",
          error?.message ? error?.message : SOMETHING_WENT_WRONG
        );
        setShowLoader(false);
        setPreviewRequested2(false);
        // setIsImage2(true);
      });
  };

  const onUploadedPromotionalImages = (promotionalImagesObject) => {
    if (promotionalImagesObject?.mainImage) {
      promotionalImagesObject?.promotionalImagesUrls?.map((imageObj) => {
        if (imageObj?.mainImage) {
          setprofilePicId(parseInt(imageObj?.mainImage));
        }
      });
      setMainImageLink(promotionalImagesObject?.mainImage);
      setIsEmpty(false);
      setPreviewRequested(true);
    }

    if (promotionalImagesObject?.addImage1) {
      promotionalImagesObject?.promotionalImagesUrls?.map((imageObj) => {
        if (imageObj?.addImage1) {
          setprofilePicId1(parseInt(imageObj?.addImage1));
        }
      });
      // console.log("Setting", promotionalImagesObject?.addImage1);
      setImageLink1(promotionalImagesObject?.addImage1);
      setIsImage1(false);
      setPreviewRequested1(true);
    }

    if (promotionalImagesObject?.addImage2) {
      promotionalImagesObject?.promotionalImagesUrls?.map((imageObj) => {
        if (imageObj?.addImage2) {
          setprofilePicId2(parseInt(imageObj?.addImage2));
        }
      });
      setImageLink2(promotionalImagesObject?.addImage2);
      setIsImage2(false);
      setPreviewRequested2(true);
    }
  };

  const getPromotion = () => {
    setShowLoader(true);
    getJobPromotion(parseInt(jobId)).then(
      (res) => {
        setJobData(res?.data);
        setpromotionalImagesUrls(res?.data?.promotionalImagesUrls);
        // setSelectedRole(res?.data?.promotionFixedDate);
        onUploadedPromotionalImages(res?.data);
        if (res?.data?.promotionFixedDate === true) {
          setSelectedRole("All Roles Filled");
        } else {
          setSelectedRole("Fixed Date");
        }
        // setIsEmpty(true);
        // setPreviewRequested(true);
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };
  //  console.log(Jobdata?.promotionStartDate.toString(),"selectedRole")

  useEffect(() => {
    getPromotion();
  }, []);

  useEffect(() => {}, []);

  const AddJobPromotion = (response, response1, response2) => {
    setShowLoader(true);
    setIsPublished(false);
    setIsPromoted(false);
    jobPromotion(
      parseInt(jobId),
      profilePicId,
      profilePicId1,
      profilePicId2,
      promotionStartDate,
      promotionEndDate,
      tagLineField,
      promotionFixedDate,
      isPromoted
    ).then(
      (res) => {
        //if (!isEmpty(res)) {

        //  setId(res?.data?.data?.id)
        // } else {
        //   setRefereeOverview([]);
        // console.log(res?.data?.status ,"satus");
        // if(res?.data?.status === STATUS_SUCCESS){
        setShowLoader(false);

        // setIsPublished(false);
        setIsEmpty(false);
        setIsImage1(false);
        setIsImage2(false);

        // setIsPublished(false);
        setIsEmpty(false);
        setIsImage1(false);
        setIsImage2(false);
        toaster("success", "Job Featured Added succesfully");
        // window?.location?.reload();
        // setTagLineField("");
        // setPromotionEndDate("");
        // }
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };

  const UnFeatureJob = () => {
    setShowLoader(true);
    jobPromotion(
      parseInt(jobId),
      profilePicId,
      profilePicId1,
      profilePicId2,
      promotionStartDate,
      promotionEndDate,
      tagLineField,
      promotionFixedDate,
      isPromoted
    ).then(
      (res) => {
        //if (!isEmpty(res)) {

        //  setId(res?.data?.data?.id)
        // } else {
        //   setRefereeOverview([]);
        // console.log(res?.data?.status ,"satus");
        // if(res?.data?.status === STATUS_SUCCESS)
        setShowLoader(false);
        setIsPublished(true);
        // setIsPublished(false);
        // setIsEmpty(false);
        // setIsImage1(false);
        // setIsImage2(false);
        toaster("success", "Job Un-Feature");
        // setTagLineField("");
        // setPromotionEndDate("");
        // }
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };
  const closeJob = (jobId, status) => {
    changeJobStatus(jobId, "CLOSED").then((res) => {
      if (res?.status === 200) {
        toaster("success", res?.data?.message, 3000);
      }
    });
  };
  // console.log(
  //   process.env.REACT_APP_IMAGE_BASEURL + Jobdata?.mainImage,
  //   "mainimage"
  // );
  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        setProfileSrc(res);
      } else {
        setProfileSrc(null);
      }
    });
    // alert(downloadedUrl);
  };
  const removeImages1 = () => {
    setPreviewRequested1(true);
    setIsImage1(true);

    setPreviewSrc1(null);
    setDeleteFlag1(true);
  };
  const removeImages2 = () => {
    setPreviewRequested2(true);
    setIsImage2(true);
    // console.log(previewRequested,"Request on remove");
    setPreviewSrc2(null);
    setDeleteFlag2(true);
  };
  const removeImages = () => {
    setPreviewRequested(true);
    setIsEmpty(true);
    // console.log(previewRequested,"Request on remove");
    setPreviewSrc(null);
    setDeleteFlag(true);
    // setprofilePicId1(null);
    // setShowLoader(true);
    // jobPromotion(parseInt(jobId),
    // profilePicId,
    // profilePicId1,
    // profilePicId2,
    // promotionStartDate,
    // promotionEndDate,
    // tagLineField,
    // promotionFixedDate,
    // isPromoted).then(
    //   (res) => {
    //     //if (!isEmpty(res)) {

    //     //  setId(res?.data?.data?.id)
    //     // } else {
    //     //   setRefereeOverview([]);
    //     // console.log(res?.data?.status ,"satus");
    //     // if(res?.data?.status === STATUS_SUCCESS)
    //     setShowLoader(false);

    //     // setIsPublished(false);
    //     // setIsEmpty(false);
    //     // setIsImage1(false);
    //     // setIsImage2(false);
    //     toaster("success", "Job Un-Feature");
    //     // setTagLineField("");
    //     // setPromotionEndDate("");
    //     // }
    //   },
    //   (error) => {
    //     toaster("error", error);
    //     setShowLoader(false);
    //   }
    // );
  };
  // const getProfilePicture = () => {
  //   if (props.potentialImages !== undefined || null) {
  //     downloadPicture(props.potentialImages);
  //     // candidateDetails?.adminUserResponse?.profilePicDownloadUrl
  //     // adminUserResponse?.profilePicDownloadUrl
  //   } else {
  //     setProfileSrc(null);
  //   }
  // };

  // useEffect(() => {
  //   getProfilePicture();
  //   // getInitialsLetter();
  // }, []);
  const removepotentialMainImage = (id, previewRequested) => {
    if (previewRequested > 0) {
      if (previewRequested == 1) {
        setPreviewRequested1(true);
      } else {
        setPreviewRequested2(true);
      }
    } else {
      setPreviewRequested(true);
    }

    setIsEmpty(true);
    setPreviewSrc(null);
    setDeleteFlag(true);
    setIsImage1(true);
    //
    setPreviewSrc1(null);
    setDeleteFlag1(true);
    setIsImage2(true);

    setPreviewSrc2(null);
    setDeleteFlag2(true);
    // // alert("hiii")
    setShowLoader(true);
    setApiRefresh(true);
    deleteImage(id)
      .then((res) => {
        setShowLoader(false);
        if (res?.data.status === STATUS_SUCCESS) {
          // setPreviewRequested(true);

          toaster(
            "success",
            res?.data?.message ? res?.data?.message : STATUS_SUCCESS
          );
          setApiRefresh(true);
          window?.location?.reload();
        }
      })
      .catch((e) => {
        setApiRefresh(false);
        setShowLoader(false);
      });
  };
  // const removepotentialImage1 = (id) => {
  //   // alert("hiii")
  //   setShowLoader(true);
  //   setApiRefresh(true);
  //   deleteImage(id)
  //     .then((res) => {
  //       setShowLoader(false);
  //       if (res?.data.status === STATUS_SUCCESS) {
  //         toaster(
  //           "success",
  //           res?.data?.message ? res?.data?.message : STATUS_SUCCESS
  //         );
  //         setApiRefresh(true);
  //       }
  //     })
  //     .catch((e) => {
  //       setApiRefresh(false);
  //       setShowLoader(false);
  //     });
  // };

  // const removepotentialImage2 = (id) => {
  //   // alert("hiii")
  //   setShowLoader(true);
  //   setApiRefresh(true);
  //   deleteImage(id)
  //     .then((res) => {
  //       setShowLoader(false);
  //       if (res?.data.status === STATUS_SUCCESS) {
  //         toaster(
  //           "success",
  //           res?.data?.message ? res?.data?.message : STATUS_SUCCESS
  //         );
  //         setApiRefresh(true);
  //       }
  //     })
  //     .catch((e) => {
  //       setApiRefresh(false);
  //       setShowLoader(false);
  //     });
  // };

  return (
    <div className="mt-5 px-2 px-lg-0">
      {showLoader && <Loader />}
      <div className="d-flex flex-md-row flex-column h-100">
        <div className="col-md-8 col-12 ">
          <div className="action-cards h-100 p-1 me-md-2 position-relative">
            <div className="color-primary fs-20 fw-700 p-3 pb-1">
              Notification to Referees
            </div>
            <hr></hr>
            <div className="color-tertiary fs-12 fw-400 p-3 pt-0 ">
              A notification for this job was sent to all referees with the
              matching referral prefrences
            </div>
            <div className="color-primary fs-14 fw-700 p-3 pt-1 pb-2">
              Resend Notification?
            </div>
            <div className="color-tertiary fs-12 fw-400 p-3 pt-0">
              Select the criteria and send the notification again
            </div>
            <div className="p-3 pt-2 pt-sm-5">
              <div className="d-flex justify-content-end send-notification-wrapper ">
                <Link
                  // to={"/" + NOTIFICATION_TO_REFEREE_PAGE_ROUTE +'/${jobId}'}
                  to={"/" + NOTIFICATION_TO_REFEREE_PAGE_ROUTE + `/${jobId}`}
                  className="btn-manage-action text-decoration-none send-notification-btns text-center"
                >
                  Manage
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-3 mt-md-0">
          <div className="action-cards h-100 p-1 ms-md-2">
            <div className="d-flex align-middle align-items-center pt-3 px-3">
              <img
                src={PromoteIcon}
                width="29"
                height="29"
                className=""
                onClick={() => {
                  // ImageValue();
                }}
              />
              <div className="px-3 color-primary fs-20 fw-700 pb-1">
                Promote this job
              </div>
            </div>

            <div className="color-tertiary fs-12 fw-400 p-3">
              Make this role visible in premium spots in the listing Add
              additional highlights for candidates to join this role
            </div>
            <div className="p-3 pt-2 pb-0 color-secondary fs-12 fw-400">
              Reachout to <span className="fw-700">promotions@seikor.com </span>
              before or after publishing the job post to promote this job
            </div>
            <div
              className="p-3 pt-5 text-decoration-underline color-primary fs-12 fw-700"
              onClick={() => {
                navigator.clipboard.writeText("promotions@seikor.com");
                toaster("success", "Copied to Clipboard");
              }}
            >
              COPY EMAIL
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-md-row flex-column mt-4 h-100">
        <div className="col-md-8 col-12">
          <div className="action-cards h-100 p-1 me-md-2">
            <div className="color-primary fs-20 fw-700 p-3 pb-1">
              Notification to Candidates
            </div>
            <hr></hr>
            <div className="color-tertiary fs-12 fw-400 p-3 pt-0 ">
              A notification for this job was sent to all Candidates with the
              matching job search prefrences
            </div>
            <div className="color-primary fs-14 fw-700 p-3 pt-1 pb-2">
              Resend Notification?
            </div>
            <div className="color-tertiary fs-12 fw-400 p-3 pt-0">
              Select the criteria and send the notification again
            </div>
            <div className="p-3 pt-2 pt-sm-5">
              <div className="d-flex justify-content-end send-notification-wrapper">
                <Link
                  // to={"/" + NOTIFICATION_TO_CANDIDATE_PAGE_ROUTE+'/${jobId}'

                  to={"/" + NOTIFICATION_TO_CANDIDATE_PAGE_ROUTE + `/${jobId}`}
                  className="btn-manage-action text-decoration-none send-notification-btns text-center"
                >
                  Manage
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-12 mt-3 mt-md-0">
          <div className="action-cards h-100 p-1 ms-md-2">
            <div className="d-flex align-middle align-items-center pt-3 px-3">
              <img
                src={CloseJob}
                width="27"
                height="27"
                className=""
                onClick={() => {
                  // ImageValue();
                }}
              />
              <div className="color-primary fs-20 fw-700 px-3 pb-1">
                Close the job
              </div>
            </div>
            <div className="color-tertiary fs-12 fw-400 p-3">
              <div>
                Job will be marked as filled and will no longer be available to
                apply for when:
              </div>
              <ul>
                <li> all open roles are filled with joined candidates or </li>
                <li>you manually close the job</li>
              </ul>
            </div>
            <div className="d-flex justify-content-end p-3">
              <button
                className="btn-close-action "
                onClick={() => closeJob(jobId, "CLOSED")}
              >
                Close Job
              </button>
            </div>
          </div>
        </div>
      </div>
      {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
        <div className="d-flex flex-md-row flex-column flex-wrap mt-4 h-100">
          <div className="col-md-8 col-12">
            <div className="action-cards h-100 p-1 me-md-2">
              <div className="color-primary fs-20 fw-700 p-3 pb-1">
                Feature this Job
              </div>

              <div className="d-flex p-3 pb-1 flex-wrap">
                <div className="col-lg-6 col-12">
                  <div className="color-primary fs-14 fw-400">
                    Add 3 Images (652 X 428 or 3:2 proportion)
                  </div>
                  <div className="d-flex pt-3 pe-md-3 flex-wrap">
                    <div className="col-md-4 col-6">
                      {isEmpty && mainImageLink == null ? (
                        <div className="img-dotted-border mb-3 mx-1">
                          {console.log("first isEpty")}
                          <div className="d-flex flex-column justify-content-center align-items-center h-100 pointer">
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                onUploadPicture(acceptedFiles);
                                // ImageValue();
                              }}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <>
                                    <div className="label d-flex align-items-center mt-3 mx-1">
                                      <div className="d-flex flex-column justify-content-center align-items-center h-100 pointer">
                                        <img
                                          src={icPlusIcon}
                                          onClick={() => {
                                            // setIsEmpty(false);
                                            // ImageValue();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      ) : previewRequested ? (
                        <div className="color-primary fs-12 fw-400">
                          {console.log("secomd previewReq")}
                          {mainImageLink == null ? (
                            <div className="w-100 h-100 position-relative ms-2">
                              <img
                                src={icDeleteRed}
                                onClick={() => {
                                  setPreviewRequested(false);
                                  setIsEmpty(true);
                                }}
                                className="position-absolute bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                                style={{ bottom: "5px", right: "10px" }}
                              />
                              <img src={previewSrc} width="90%" />
                            </div>
                          ) : (
                            <div className="col-md-4 col-6 w-100 position-relative me-2">
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_BASEURL +
                                  mainImageLink
                                }
                                alt="plus-icon-test"
                                width="100%"
                                className="rounded-2 h-100"
                              />

                              <img
                                src={icDeleteRed}
                                onClick={() => {
                                  {
                                    promotionalImagesUrls?.length > 0 &&
                                      promotionalImagesUrls?.map(
                                        (images, index) => {
                                          removepotentialMainImage(
                                            images.mainImage,
                                            0
                                          );
                                        }
                                      );
                                  }
                                }}
                                className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                              />
                            </div>
                          )}

                          {/* <img
                                src={icDeleteRed}
                                onClick={() => {
                                  removeImages();
                                }}
                                className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                              /> */}
                        </div>
                      ) : (
                        <div className="w-100 position-relative">
                          {console.log("third preiew false")}
                          {Jobdata?.mainImage === null ? (
                            // <img src={previewSrc} width="50px" />
                            // "Main image"
                            <>
                              {console.log(
                                "four preiew false and main img false"
                              )}
                              <img
                                src={previewSrc}
                                // alt="plus-icon"
                                width="100%"
                                className="rounded-2 h-100"
                              />

                              <img
                                src={icDeleteRed}
                                onClick={() => {
                                  removeImages();
                                }}
                                className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                              />
                            </>
                          ) : (
                            <>
                              {console.log("fifth")}
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_BASEURL +
                                  promotionalImagesUrls?.mainImage
                                }
                                alt="plus-get-icon"
                                width="100%"
                                className="rounded-2 h-100"
                              />
                            </>
                          )}

                          <img
                            src={icDeleteRed}
                            onClick={() => {
                              removeImages();
                            }}
                            className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                            alt="delete-icon"
                          />
                        </div>
                      )}
                    </div>

                    <div className="col-md-4 col-6">
                      {isImage1 && imageLink1 == null ? (
                        <div className="img-dotted-border mb-3 mx-1">
                          {console.log("2nd img first")}
                          <div className="d-flex flex-column justify-content-center align-items-center h-100 pointer">
                            {/* <img src={icPlusIcon}  alt="plus-icon" /> */}
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                onUploadimage1(acceptedFiles);
                                // ImageValue1();
                              }}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <>
                                    <div className="label d-flex align-items-center mt-3 mx-1">
                                      <div className="d-flex flex-column justify-content-center align-items-center h-100 pointer">
                                        <img
                                          src={icPlusIcon}
                                          onClick={() => {
                                            // setIsImage1(false);
                                            // setIsEmpty(false);
                                            // ImageValue1();
                                            // ImageValue();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      ) : previewRequested1 ? (
                        <div className="color-primary fs-12 fw-400">
                          {previewRequested1 && imageLink1 === null ? (
                            <div className="w-100 h-100 position-relative ms-2">
                              <img
                                src={icDeleteRed}
                                onClick={() => {
                                  setPreviewRequested1(false);
                                  setIsImage1(true);
                                }}
                                className="position-absolute bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                                style={{ bottom: "5px", right: "10px" }}
                              />
                              <img src={previewSrc1} width="90%" />
                            </div>
                          ) : (
                            // "Main image"
                            <div className="col-md-4 col-6 w-100 position-relative ms-1 me-1">
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_BASEURL +
                                  imageLink1
                                }
                                alt="plus-icon-1"
                                width="100%"
                                className="rounded-2 h-100"
                              />

                              <img
                                src={icDeleteRed}
                                onClick={() => {
                                  {
                                    promotionalImagesUrls?.length > 0 &&
                                      promotionalImagesUrls?.map(
                                        (images, index) => {
                                          removepotentialMainImage(
                                            images.addImage1,
                                            1
                                          );
                                        }
                                      );
                                  }
                                }}
                                className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className="color-primary fs-12 fw-400">
                            {previewRequested1 &&
                            Jobdata?.addImage1 === null ? (
                              <img src={previewSrc1} width="50px" />
                            ) : (
                              // "Main image"
                              <div className="col-md-4 col-6 w-100 position-relative">
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_BASEURL +
                                    Jobdata?.addImage1
                                  }
                                  alt="plus-icon-1"
                                  width="100%"
                                  className="rounded-2 h-100"
                                />

                                <img
                                  src={icDeleteRed}
                                  onClick={() => {
                                    {
                                      promotionalImagesUrls?.length > 0 &&
                                        promotionalImagesUrls?.map(
                                          (images, index) => {
                                            removepotentialMainImage(
                                              images.addImage1,
                                              1
                                            );
                                          }
                                        );
                                    }
                                  }}
                                  className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                                  alt="delete-icon"
                                />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className="col-md-4 col-6 h-100">
                      {isImage2 && imageLink2 == null ? (
                        <div className="img-dotted-border mb-3 mx-1">
                          <div className="d-flex flex-column justify-content-center align-items-center h-100 pointer">
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                onUploadimage2(acceptedFiles);
                                // ImageValue2();
                              }}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <>
                                    <div className="label d-flex align-items-center mt-3 mx-1">
                                      <div className="d-flex flex-column justify-content-center align-items-center h-100 pointer">
                                        <img
                                          src={icPlusIcon}
                                          onClick={() => {
                                            // setIsImage2(false);
                                            // setIsImage1(false);
                                            // setIsEmpty(false);
                                            // ImageValue2();
                                            // ImageValue1();
                                            // ImageValue();
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      ) : previewRequested2 ? (
                        <div className="color-primary fs-12 fw-400">
                          {previewRequested2 && imageLink2 === null ? (
                            <div className="w-100 h-100 position-relative ms-2">
                              <img
                                src={icDeleteRed}
                                onClick={() => {
                                  setPreviewRequested2(false);
                                  setIsImage2(true);
                                }}
                                className="position-absolute bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                                style={{ bottom: "5px", right: "10px" }}
                              />
                              <img src={previewSrc2} width="90%" />
                            </div>
                          ) : (
                            <div className="ms-0 ms-md-2 mt-2 mt-md-0  col-md-4 col-6 w-100 position-relative">
                              <img
                                src={
                                  process.env.REACT_APP_IMAGE_BASEURL +
                                  imageLink2
                                }
                                // alt="plus-icon"
                                width="100%"
                                className="rounded-2 h-100"
                              />

                              <img
                                src={icDeleteRed}
                                onClick={() => {
                                  {
                                    promotionalImagesUrls?.length > 0 &&
                                      promotionalImagesUrls?.map(
                                        (images, index) => {
                                          removepotentialMainImage(
                                            images.addImage2,
                                            2
                                          );
                                        }
                                      );
                                  }
                                }}
                                className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                                alt="delete-icon"
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="w-100 position-relative">
                          {previewRequested2 ? (
                            // <img src={previewSrc} width="50px" />

                            <img
                              src={previewSrc2}
                              alt="plus-icon"
                              width="100%"
                              className="rounded-2 h-100"
                            />
                          ) : (
                            <img
                              src={previewSrc2}
                              alt="plus-icon"
                              width="100%"
                              className="rounded-2 h-100"
                            />
                          )}

                          <img
                            src={icDeleteRed}
                            className="position-absolute top-0 end-0 bg-white p-1 rounded-2 me-1 mt-1"
                            alt="delete-icon"
                            onClick={() => {
                              removeImages2();
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="py-3">
                    <div className="medium-black-text fs-12 fw-700">
                      Keep this featured till
                      <span className="dark-pink-text">*</span>
                    </div>
                    <div className="pt-3">
                      <div className="d-flex flex-wrap gap-2">
                        <div className="d-flex flex-wrap gap-1">
                          {featuredRoles?.map((sort, index) => {
                            return (
                              <div
                                className="insight-filter w-fit  d-flex justify-content-center h-28 pointer"
                                key={index}
                                onClick={() => {
                                  if (sort === "All Roles Filled") {
                                    setPromotionFixedDate(true);
                                    setSelectedRole(sort);
                                  } else {
                                    setPromotionFixedDate(false);
                                    setSelectedRole(sort);
                                  }
                                }}
                                style={{ minWidth: "130px" }}
                              >
                                <div
                                  className={
                                    selectedRole === sort
                                      ? "ms-1 px-1 bg-black rounded-circle  d-block"
                                      : "d-none"
                                  }
                                >
                                  <img src={IcDoneWhite} alt="whitedone-icon" />
                                </div>
                                <div className="color-primary px-2">{sort}</div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="position-relative">
                          <div>
                            <input
                              type="date"
                              className="input-date-type h-40"
                              defaultValue={Jobdata?.promotionEndDate}
                              // value={promotionEndDate}
                              min={disablePastDate()}
                              onChange={(e) => {
                                // const newDate = (new Date(e.target.value)).format('YYYY-MM-DD');
                                setPromotionEndDate(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12 position-relative">
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="medium-black-text fs-12 fw-700">
                      Tag Line <span className="dark-pink-text">*</span>
                    </div>
                    <div className="color-tertiary fs-11 fw-300">
                      {isActive
                        ? `${total}`
                        : `${tagLineField.length}-${Character_Limit}`}
                      characters left
                    </div>
                  </div>
                  <div className="pt-3 pb-5 mb-3">
                    <div>
                      <textarea
                        rows={3}
                        name="TagLine"
                        className="w-100 overflow-y-scroll fs-12 p-2 pb-3"
                        placeholder="Write here"
                        maxLength={Character_Limit}
                        value={TagLine}
                        // onChange={(e) => {
                        //   setTagLineField(e.target.value);
                        // }}
                        onChange={handleChange}
                        defaultValue={Jobdata?.tagsLine}

                        // onChange={(e) => {
                        //   setTagLineField(e.target.value);
                        // }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="d-flex justify-content-end  pt-3 position-absolute end-0 bottom-0 pb-3">
                    {isPublished ? (
                      <button
                        className="btn-manage-action px-4 border-0 h-40"
                        onClick={() => {
                          setIsPublished(false);
                          //  setIsPromoted(false);
                          if (
                            tagLineField !== "" &&
                            tagLineField !== null &&
                            tagLineField !== undefined &&
                            promotionEndDate !== "" &&
                            promotionEndDate !== null &&
                            promotionEndDate !== undefined
                            // &&
                            // ImageValue() &&
                            // ImageValue1() &&
                            // ImageValue2()
                          ) {
                            AddJobPromotion();
                          }
                          // setIsPublished(false);
                        }}
                      >
                        Publish as featured
                      </button>
                    ) : (
                      <div className="d-flex gap-2">
                        <button
                          className="btn-close-action border-0 h-40"
                          onClick={() => {
                            // setIsPromoted(false);
                            UnFeatureJob();
                          }}
                        >
                          Un-Feature
                        </button>
                        <button
                          className="btn-manage-action px-4 border-0 h-40"
                          onClick={() => {
                            // setIsPublished();
                            setIsPromoted(false);
                            // ImageValue();
                            // ImageValue1();
                            // ImageValue2();
                            // if (
                              
                            //   tagLineField !== "" &&
                            //   tagLineField !== null &&
                            //   tagLineField !== undefined &&
                            //   promotionEndDate !== "" &&
                            //   promotionEndDate !== null &&
                            //   promotionEndDate !== undefined
                              
                            //   // &&
                            //   // ImageValue() &&
                            //   // ImageValue1() &&
                            //   // ImageValue2()
                            // ) {
                              AddJobPromotion();
                            // }
                          }}
                        >
                          Publish Changes
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-12 mt-3 mt-md-0">
            <div className="action-cards h-100 p-1 ms-md-2 h-100 position-relative">
              <div className="color-primary fs-20 fw-700 p-3 pb-1">
                Job Tags
              </div>
              <div className="color-tertiary fs-12 fw-400 p-3">
                <div>Add a tag to this job</div>
              </div>
              {isEmpty1 ? (
                <div className="px-3 pb-5 mb-3">
                  <SearchComboBox
                    name="Job Tag"
                    inputData={allJobsTags}
                    defaultValue={defaultValue_JobTag}
                    isMultiSelect={false}
                    inputCssClass={
                      "w-100 modal-form-input small-text-medium-gray text-dark h-40 mb-2"
                    }
                    onSelect={(event) => setSelected_JobTags(event)}
                    searchListHeight={150}
                    badgeThemeCssClass={"blue"}
                    wrapperCssClass={"form-group"}
                    placeholder={"Search or Select"}
                    // hideBadges={true}
                  />
                </div>
              ) : (
                <div className="d-flex flex-wrap  gap-2 ps-3">
                  {specializedOutput?.map((output, index) => {
                    return (
                      <div
                        className="pink-color-badge d-flex gap-3 pb-5 mb-3"
                        key={index}
                      >
                        <div>{output}</div>
                        <img
                          src={icClosePink}
                          alt="pink-close-icon"
                          className="pointer"
                        />
                      </div>
                    );
                  })}
                </div>
              )}
              {checkTag ? (
                <div className="d-flex justify-content-end pb-4 position-absolute end-0 bottom-0  mt-2">
                  <button
                    className="btn-manage-action px-5 me-3 border-0 h-40"
                    onClick={PublishJobTag}
                  >
                    Publish
                  </button>
                </div>
              ) : (
                <div
                  className="d-flex justify-content-end p-3 position-absolute end-0 bottom-0"
                  onClick={RemoveJobTag}
                >
                  <button className="btn-close-action h-40">Remove tag</button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="mt-4">
        <div className="w-100">
          <div className="action-cards h-100 p-4">
            <div className="color-primary fs-20 fw-700 pb-2">
              Invite Referees
            </div>
            <div className="col-lg-6 col-md-9 col-12 pb-3">
              <div className="color-tertiary fs-12">
                You can invite potential referees who are currently not on
                Seikor, to refer for this job
              </div>
              <div className="medium-black-text fs-12 fw-700 pt-4">Email *</div>
              <div className="d-flex align-items-center flex-sm-row flex-column pt-3">
                <div className="col-sm-9 col-12">
                  <div className="me-sm-3">
                    {/* <input
                      type="email"
                      // className="w-100 modal-form-input small-text-medium-gray text-dark "
                      className={
                        (formData?.email?.errorText ? "error" : "") +
                        "w-100 modal-form-input small-text-medium-gray text-dark "
                      }
                      value={formData?.email?.valueText}
                      name="email"
                      onChange={($event) => {
                        onFormFeildsChange($event, formData, setFormData);
                      }}
                    /> */}
                    {allEmail.map((input, index) => {
                      return (
                        <>
                          <input
                            // type="email"
                            name="email"
                            maxLength={50}
                            // value={formData?.email?.valueText}
                            value={input.email}
                            id={index}
                            type={input.type}
                            onChange={($event) => {
                              // onFormFeildsChange($event, formData, setFormData);

                              let copyFormData = { ...formData };
                              copyFormData.email[index].valueText =
                                $event?.target?.value;

                              const checkValidationArray =
                                formData?.email[index]?.check;
                              const fieldValue =
                                copyFormData?.email[index]?.valueText;
                              if (!isEmailValid(fieldValue)) {
                                setEmailCheck(false);
                                copyFormData.email[index].errorText =
                                  FORM_VALIDATION_MESSAGE.EMAIL;
                              }
                              // else{
                              //   setEmailCheck(true)
                              // }
                              // if(isEmailValid(fieldValue)){
                              //   setEmailCheck(true)
                              // }

                              //     if (
                              //       checkValidationArray.includes(REQUIRED) &&
                              //       isEmpty(fieldValue)
                              //     ) {
                              //       copyFormData.email[index].errorText =
                              //         FORM_VALIDATION_MESSAGE.REQUIRED;
                              //     }
                              else {
                                setEmailCheck(true);
                                copyFormData.email[index].errorText = "";
                              }
                              setFormData(copyFormData);

                              handleFormChange(index, $event);
                              if (formData?.email?.errorText == "") {
                                emailValidation(formData, setFormData);
                              }
                            }}
                            className={
                              (formData?.email?.errorText ? "error" : "") +
                              " mb-2 w-100 modal-form-input small-text-medium-gray text-dark"
                            }
                          />
                          <span className="field-error mt-1">
                            {formData?.email[index]?.errorText}
                          </span>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="col-sm-3 col-12">
                  <button
                    className="btn-manage-action btn-invite w-100 mt-3 mt-sm-0"
                    onClick={emailCheck ? sendReferral : null}
                  >
                    Invite
                  </button>
                </div>
              </div>
              <div className="medium-black-text fs-12 fw-700 pt-3">Message</div>
              <div className="inactive-text mt-2">
                <textarea
                  rows={3}
                  className={
                    (formData?.text?.errorText ? "error" : "") +
                    " form-control w-100 overflow-y-scroll fs-12 p-2 "
                  }
                  // value={formData?.message?.valueText}
                  value={formData?.text?.valueText}
                  type="text"
                  name="text"
                  onChange={($event) => {
                    onFormFeildsChange($event, formData, setFormData);
                  }}
                  placeholder="Write your request to the referee here"
                ></textarea>
                <div className="field-error mt-1">
                  {formData?.text?.errorText}
                </div>{" "}
              </div>
            </div>
            <hr></hr>
            <div className="d-none d-sm-block">
              <InfiniteScroll
                dataLength={currentListSet}
                next={() => fetchMoreInvite()}
                hasMore={dataCountInvite > currentListSet}
                className="container-sm-fluid"
              >
                <Table responsive="sm" className="fs-12">
                  <thead className="">
                    <tr>
                      <th>
                        <div className="py-2">Referee Email</div>
                      </th>
                      <th>
                        <div className="py-2">Invited on </div>
                      </th>
                      <th className="status-padding-right">
                        <div className="py-2">Status </div>
                      </th>
                      <th>
                        <div className="py-2"></div>
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {refereeOverview && refereeOverview.length > 0
                      ? refereeOverview.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="py-2">{data?.email} </div>
                              </td>
                              <td className="color-tertiary">
                                <div className="py-2">{data?.invitedOn} </div>
                              </td>
                              <td className="color-tertiary" key={data?.status}>
                                <div className="py-2">{data?.status}</div>
                              </td>
                              <td>
                                <div className="py-2">
                                  <Dropdown
                                    className="down-btn-remove"
                                    align="end"
                                  >
                                    <Dropdown.Toggle
                                      variant="none"
                                      id="dropdown-basic"
                                      className="d-flex p-0 m-0 border-0 bg-transparent "
                                    >
                                      <img
                                        src={icThreeDots}
                                        alt="retract-icon"
                                      />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                                      <Dropdown.Item className="py-1 pe-5">
                                        <div
                                          className="color-secondary pe-5"
                                          // onClick={() => {
                                          //   setStatus(APPLY_STATUS.JOINED);
                                          //   onButtonClick();
                                          // }}
                                          onClick={() =>
                                            Status(APPLY_STATUS.JOINED, data.id)
                                          }
                                        >
                                          Mark as joined
                                        </div>
                                      </Dropdown.Item>
                                      <Dropdown.Divider className="ms-3 me-2" />
                                      <Dropdown.Item className="py-1 pe-5">
                                        <div
                                          className="dark-pink-text pe-5"
                                          // onClick={() => {
                                          //   setStatus(APPLY_STATUS.RETRACTED);
                                          //   onButtonClick();
                                          // }}
                                          onClick={() =>
                                            Status(
                                              APPLY_STATUS.RETRACTED,
                                              data.id
                                            )
                                          }
                                        >
                                          Retract Invitation
                                        </div>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : "No Data Found"}
                  </tbody>
                </Table>
              </InfiniteScroll>
            </div>
            <div className="d-block d-sm-none">
              {refereeOverview && refereeOverview.length > 0
                ? refereeOverview.map((data, index) => {
                    return (
                      <>
                        <div
                          key="index"
                          className="color-tertiary fs-14 d-flex justify-content-between gap-2"
                        >
                          <div className="">
                            <div className="py-1 color-primary text-break">
                              {data?.email}
                            </div>
                            <div className="py-1">{data?.invitedOn} </div>
                            <div className="py-1">{data?.status}</div>
                          </div>
                          <div>
                            <Dropdown className="down-btn-remove" align="end">
                              <Dropdown.Toggle
                                variant="none"
                                id="dropdown-basic"
                                className="d-flex p-0 m-0 border-0 bg-transparent "
                              >
                                <img src={icDotsBlack} alt="retract-icon" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                                <Dropdown.Item className="py-1 pe-5">
                                  <div
                                    className="color-secondary pe-5"
                                    onClick={() =>
                                      Status(APPLY_STATUS.JOINED, data.id)
                                    }
                                  >
                                    Mark as joined
                                  </div>
                                </Dropdown.Item>
                                <Dropdown.Divider className="ms-3 me-2" />
                                <Dropdown.Item className="py-1 pe-5">
                                  <div
                                    className="dark-pink-text pe-5"
                                    onClick={() =>
                                      Status(APPLY_STATUS.RETRACTED, data.id)
                                    }
                                  >
                                    Retract Invitation
                                  </div>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    );
                  })
                : "No Data Found"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsComponent;
