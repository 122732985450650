import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import backButton from "../assests/icons/back-icon.svg";
import credLogo from "../assests/cred_logo.png";
import AdminEditPocDialog from "../dialogs/admin_all_orgs_dialogs/admin_edit_poc_dialog";
import { Link, useNavigate, useParams } from "react-router-dom";
import { viewOraganization } from "../_services/company.service";
import toaster from "../utils/toaster";
import { setLocalStorage } from "../utils/storage";
import { ORG_ID, ORG_LOGO } from "../config/localstorage_keys";

function AdminOrgDetails() {
  const [editPocModalShow, setEditPocModalShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [viewOrg,setViewOrg]=useState([]);
  const [Pocname,setPocName]=useState();
  const { orgId } = useParams();
  const [apiRefresh, setApiRefresh] = useState(false);
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const fetchDetails=()=>{
   
      setShowLoader(true);
      viewOraganization(parseInt(orgId))
      .then((res) => {
        setViewOrg(res?.data?.data);
        setPocName(res?.data?.data?.pocUserName);
        setShowLoader(false);
        
      })
      .catch((err) => {
        setShowLoader(false);
        // props?.setApiRefresh(false);
        toaster("error", err);
      });
    } 
  
  useEffect(() => {
    fetchDetails();
  }, []);

  useEffect(() => {
    if (apiRefresh === true) {
      fetchDetails();
      setApiRefresh(false);
    }
  }, [apiRefresh]);
  const openToNewTab = (orgId, orgLogo) => {
    setLocalStorage(ORG_ID, orgId);
    setLocalStorage("orgLogo", orgLogo);
    localStorage.setItem("temp_role", role);
    localStorage.setItem("role", "BIZ_ADMIN");
    localStorage.setItem("log_to_org", "true");

    navigate("/");
  };
  return (
    <div className="container">
      <div className="content-margin mt-4">
        <Link to={-1} className="color-primary d-flex gap-2 align-items-center">
          <div>
            <img src={backButton} alt={backButton} />
          </div>
          <div className="color-primary fs-12 fw-400 back-btn-width">
            Go Back
          </div>
        </Link>
        <div className="d-block d-sm-flex justify-content-between mt-4">
          <div className="d-flex">
            <div>
              <img style={{ width: "60px" }} src={`data:image/jpeg;base64 , ${viewOrg?.orgLogo}`} />
            </div>
            <div className="ms-2">
              <div className="fw-700 fs-24">{viewOrg?.orgCode}</div>
              <div className="small-text-gray fs-14 fw-400">
                {/* Cred technologies Pvt Ltd */}
                {viewOrg?.orgName}
              </div>
            </div>
          </div>
          <div className="mt-4 mt-sm-0 d-flex align-items-center">
            <Button className="btn btn-dark btn-save fs-12 fw-700 px-4 py-2"
            
            onClick={() => openToNewTab(orgId,viewOrg.orgLogo)}
            >
              Login to Org
            </Button>
          </div>
        </div>
        <hr />
        <div className="d-flex">
          <div className="me-5">
            <div className="fw-700 fs-14">{viewOrg?.orgActiveSince}</div>
            <div className="small-text-gray fw-400 fs-14">Active Since</div>
          </div>
          <div>
            <div className="fw-700 fs-14">{viewOrg?.orgMemberCount}</div>
            <div className="small-text-gray fw-400 fs-14">Members</div>
          </div>
        </div>
        <hr />
        <div className="d-block d-sm-flex justify-content-between">
          <div className="me-4">
            <div className="fw-700 fs-14">POC Details</div>
            {/* If no details added */}
            {/* <div className="small-text-gray fw-400 fs-14">No Details Added</div> */}
            <div>
              <div className="fw-400 fs-14 mt-1">{viewOrg?.pocUserName}</div>
              <div className="small-text-gray fw-400 fs-14 mt-1">
              {viewOrg?.pocUserMobile}
              </div>
              <div className="small-text-gray fw-400 fs-14">
              {viewOrg?.pocUserEmail}
              </div>
              <div className="small-text-gray fw-400 fs-14">
              {viewOrg?.pocUserOther}
              </div>
            </div>
          </div>
          <div className="mt-4 mt-sm-0 d-flex align-items-center">
            <Button
              onClick={() => {
                setEditPocModalShow(true);
              }}
              className="btn btn-light btn-cancel border border-dark text-dark fs-12 fw-700 px-5 py-2"
            >
              Edit
            </Button>
          </div>
        </div>
        <hr />
        <div className="mt-4">
          <div className="fw-600 fs-16">Email</div>
          <div className="small-text-gray fw-400 fs-14">{viewOrg?.orgEmail}</div>
        </div>
        <div className="mt-4">
          <div className="fw-600 fs-16">Phone Number</div>
          <div className="small-text-gray fw-400 fs-14">
            {viewOrg?.orgPhoneNumber}
          </div>
        </div>
        <div className="mt-4">
          <div className="fw-600 fs-16">Corporate Address</div>
          <div className="small-text-gray fw-400 fs-14">
            {viewOrg?.orgAddress}
          </div>
        </div>

        {/* Dialogs */}

        <AdminEditPocDialog
          editPocModalShow={editPocModalShow}
          setEditPocModalShow={setEditPocModalShow}
          Pocname={Pocname}
          viewOrg={viewOrg}
          setApiRefresh={setApiRefresh}
        />
      </div>
    </div>
  );
}

export default AdminOrgDetails;
