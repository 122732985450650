/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import profileImg from "../../../../assests/images/candidate-profile-pic.jpg";
import icGreenDone from "../../../../assests/icons/ic-green-done.svg";
import icFlag from "../../../../assests/icons/ic-flag.svg";
import icDownload from "../../../../assests/icons/ic-download.svg";
import DropdownCard from "../dropdown_card";
import ApplicationHoverCard from "./application_hover_card";
import {
  getApplicationJobListing,
  updateApplicationStatus,
  updateFlag,
} from "../../../../_services/view.service";
import ProfileImage from "../../../../components/profile_image";
import Loader from "../../../../components/common/loader";
import { checkedItemsHandler } from "../../../../utils/utils";
import toaster from "../../../../utils/toaster";
// import { useParams } from "react-router";

const ApplicationCard = (
  {
    applicationCardDetailsList,
    jobId,
    selectedStatus,
    getApplicationCardDetails,
    selectManually,
    setno0fCardSelect,
    no0fCardSelect,
    setSelectManually,
    cardsSelected,
    setCardsSelected,
  },
  props
) => {
  // let { jobId } = useParams();
  console.log("selectManually", selectManually);

  const [showLoader, setShowLoader] = useState(false);
  const [selectForDownloadClicked, setSelectForDownloadClicked] =
    useState(false);
  const [cardClicked, setCardClicked] = useState(null);
  const [cardSelected, setCardSelected] = useState([]);

  useEffect(() => {
    if (selectManually) {
      setSelectForDownloadClicked(true);
    } else {
      setSelectForDownloadClicked(false);
    }
  }, [selectManually, cardSelected, applicationCardDetailsList]);

  useEffect(() => {}, [applicationCardDetailsList]);

  console.log("getAppCardsList---------->", applicationCardDetailsList);

  const statusChange = (appStatus, id) => {
    console.log("buttonclick-->", appStatus);
    updateApplicationStatus(id, appStatus).then((res) => {
      console.log("update Application Status---->", res);
      setShowLoader(false);
      getApplicationCardDetails();

      if (res?.data?.message !== "Application Status Updated !!") {
        toaster("error", res?.data?.message, 4000);
      } else {
        toaster("success", res?.data?.message, 4000);
      }
    });
    setShowLoader(true);
  };

  const changeUnassigned = (isUnassigned, id) => {
    console.log("buttonclick-->", isUnassigned);
    updateApplicationStatus(id, isUnassigned).then((res) => {
      console.log("update isUnassigned", res);
      setShowLoader(false);
      getApplicationCardDetails();
    });
    setShowLoader(true);
  };

  const changeFlag = (isFlagged, id) => {
    console.log("buttonclick-->", isFlagged);
    updateFlag(id, isFlagged).then((res) => {
      console.log("update Flag", res);
      setShowLoader(false);
      getApplicationCardDetails();
    });
    setShowLoader(true);
  };

  // useEffect(() => {
  //   changeFlag()
  // }, [applicationCardDetailsList])
  const cardSelectToggle = (index, applicationId) => {
    let cardClicked = [...no0fCardSelect];
    if (cardClicked.includes(applicationId)) {
      let newIndex = cardClicked.findIndex((elem) => {
        return elem === applicationId;
      });
      cardClicked.splice(newIndex, 1);
      setno0fCardSelect(cardClicked);
      return;
    }
    if (!cardClicked.includes(applicationId)) {
      cardClicked.push(applicationId);
      setno0fCardSelect(cardClicked);
    }

    // if (index >= 0) {
    //   // let newIndex = cardClicked.findIndex((elem) => {
    //   //   return elem === applicationId;
    //   // });
    //   // cardClicked.splice(newIndex, 1);
    //   setno0fCardSelect(!cardClicked);
    //   return;


    // }

    // checkedItemsHandler(index, cardSelected, setCardSelected);
    // if (index >= 0) {
    //   setCardsSelected(!cardsSelected);
    //   if (!selectManually) {
    //   // props?.setSelectManuallyClicked(true);     
    //   setSelectForDownloadClicked(false);
    //    }
    //   //   // setCardsSelected(true)
    //     // setSelectForDownloadClicked(true);
    //   // } else {
    //   //   setSelectForDownloadClicked(false);
    //   // }

    // } else {
    //   setCardsSelected(false);
    // }
  };

  // const cardSelectToggle = (index, applicationId) => {
  //   let cardClicked = [...no0fCardSelect];
    
  //   if (cardClicked.includes(applicationId)) {
  //     let newIndex = cardClicked.findIndex((elem) => elem === applicationId);
  //     cardClicked.splice(newIndex, 1);
  //   } else {
  //     cardClicked.push(applicationId);
  //   }
  
  //   setno0fCardSelect(cardClicked);
  // };
  

  return (
    <>
      {showLoader && <Loader />}
      <div className="mt-2 ">
        <div className="row">
          {applicationCardDetailsList &&
            applicationCardDetailsList?.applicationList?.map(
              (details, index) => {
                return (
                  <div
                    key={index}
                    className="col-xl-3 col-lg-4 col-sm-6 col-12 mt-3"
                    onClick={() => setCardClicked(details)}
                  >
                    <div
                      className={
                        selectForDownloadClicked
                          ? "application-card p-3 pb-0 h-100"
                          : "application-card p-3 h-100"
                      }
                      style={{
                        border:
                          cardClicked === details
                            ? "1px solid #808080 !important"
                            : "",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="d-flex gap-2 pt-2 ps-2">
                          <div>
                            {/* <img
                        src={process.env.REACT_APP_IMAGE_BASEURL + details?.profilePicUrl}
                        alt="profile-image"
                        className="rounded-circle"
                      /> */}
                            <ProfileImage
                              src={
                                process.env.REACT_APP_IMAGE_BASEURL +
                                details?.profilePicUrl
                              }
                              // name={sms.senderName}
                              width="40px"
                              height="40px"
                              initialsContainerClass="rounded-circle"
                            />
                          </div>
                          <div>
                            <div className="medium-black-text fs-14 fw-600 text-break">
                              {details?.candidateName}
                            </div>
                            <div className="fs-11 fw-3-- color-tertiary">
                              {details?.candidateDesignation}
                            </div>
                            <div className="fs-11 fw-3-- color-tertiary">
                              {details?.candidateCompany}
                            </div>
                          </div>
                        </div>
                        <div className="">
                          {/* <img src={icThreeDots} alt="retract-icon" /> */}
                          <DropdownCard
                            applicationId={details.applicationId}
                            getApplicationCardDetails={() =>
                              getApplicationCardDetails()
                            }
                          />
                        </div>
                      </div>
                      {/* card header finished */}
                      <hr className="mb-1"></hr>
                      <div className="">
                        <div className="d-flex justify-content-between">
                          <div
                            className="dark-red-text fs-12 fw-600 p-2 ps-2 pointer"
                            onClick={() => {
                              statusChange("REJECTED", details.applicationId);
                              // toaster("success", "Reject successfully")
                            }}
                          >
                            Reject
                          </div>
                          <div className="h-1 border"></div>
                          <div
                            className="medium-black-text fs-12 fw-600 p-2 pointer"
                            onClick={() => {
                              statusChange("SHORTLISTED", details.applicationId);
                              // changeUnassigned(true,details.applicationId);
                             
                            }}
                          >
                            Shortlist
                          </div>
                          <div className="h-1 border"></div>
                          {details?.isFlagged === false ? (
                            <div
                              className="medium-black-text fs-12 fw-600 p-2 pe-4 pointer"
                              onClick={() => {
                                changeFlag(true, details.applicationId);
                                toaster("success", "Flag successfully");
                              }}
                            >
                              Flag
                            </div>
                          ) : (
                            <div
                              className="medium-black-text fs-12 fw-600 p-2 pe-4 pointer"
                              onClick={() =>
                                changeFlag(false, details.applicationId)
                              }
                            >
                              Un-Flag
                            </div>
                          )}
                          {/* <div
                        className="medium-black-text fs-12 fw-600 p-2 pe-4"
                        onClick={() => changeFlag(true, details.applicationId)}
                      >
                        Flag
                      </div> */}
                        </div>
                      </div>
                      <hr className="mt-1"></hr>
                      {/* referral section */}
                      <div className="d-flex justify-content-between">
                        <div>
                          <div className="fs-11 color-tertiary fw-300">
                            Referral
                          </div>
                          {details?.isRefferal === true ? (
                            <div className="d-flex gap-1 align-items-center">
                              <div>
                                <img src={icGreenDone} alt="green-done icon" />
                              </div>
                              <div className="dark-green-text fs-11 fw-600">
                                Yes
                              </div>
                            </div>
                          ) : (
                            <div className="color-tertiary fs-11 fw-600">
                              No
                            </div>
                          )}
                        </div>
                        <div className="text-end">
                          <div className="fs-11 color-tertiary fw-300">
                            Experience
                          </div>

                          <div className="fade-gray-text fs-11 fw-500">
                            {details?.experience} Years | {details?.roles} Roles
                          </div>
                        </div>
                      </div>
                      <hr className="mt-2"></hr>
                      {/* skills section */}
                      <div className="pb-3">
                        <div className="dark-gray-color fs-11 fw-600">
                          SKILLS
                        </div>
                        <div className="d-flex flex-wrap ">
                          {details?.skills?.map((skill, index) => {
                            return (
                              <div className="pink-color-badge mt-2 me-2">
                                {skill}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {/* qualification section */}
                      <div>
                        <div className="dark-gray-color fs-11 fw-600">
                          QUALIFICATIONS
                        </div>
                        <div className="d-flex flex-wrap ">
                          {details?.qualification?.map(
                            (qualification, index) => {
                              return (
                                <div className="gray-color-badge mt-2 me-2">
                                  {qualification}
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      <hr className="mt-2"></hr>

                      {/* new badge */}
                      <div className="d-flex justify-content-end">
                        {details?.isNew === true ? (
                          <div className="new-badge-div ps-2 pe-2">New</div>
                        ) : (
                          <div className="unassigned-badge-div ps-2 pe-2">
                            Unassigned
                          </div>
                        )}
                      </div>
                      {/* Flag Status */}
                      <hr className="mb-1"></hr>

                      <div className="d-flex mb-1 gap-1">
                        {details?.isFlagged && (
                          <>
                            <div className="bg-green-icon d-flex align-item-center p-1">
                              <img src={icFlag} alt="flag-icon" />
                            </div>
                            <div className="fs-11 color-primary fw-600">
                              Flagged
                            </div>
                          </>
                        )}
                        {/* <div className="bg-gray-icon d-flex align-item-center p-1">
                          <img src={icDownload} alt="download-icon" />
                        </div> */}
                      </div>

                      {selectForDownloadClicked && (
                        <div>
                          <hr className="mb-1"></hr>
                          {/* <div className="d-flex mb-1 gap-1">
                            <div className="bg-green-icon d-flex align-item-center p-1">
                              <img src={icFlag} alt="flag-icon" />
                            </div>
                            <div className="bg-gray-icon d-flex align-item-center p-1">
                              <img src={icDownload} alt="download-icon" />
                            </div>
                          </div> */}
                          <div className="side-margin-width">
                            <div className="d-flex justify-content-between pt-2 select-for-export ps-3 pe-3">
                              <div className="fs-11 color-primary fw-600">
                                Select for Export
                              </div>
                              <div className="form-check form-switch">
                                <div
                                  className=""
                                  onClick={() => cardSelectToggle(index, details?.applicationId)}
                                >
                                  <input
                                    type="checkbox"
                                    className="form-check-input switch-btn-small pointer color-primary"
                                    // checked={
                                    //   cardSelected.includes(index)
                                    //     ? true
                                    //     : false
                                    // }
                                    checked={no0fCardSelect.includes(details?.applicationId)}
/>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {selectForDownloadClicked === false && (
                        <div className="application-hover-card">
                          <ApplicationHoverCard
                            applicationId={details?.applicationId}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            )}
        </div>
      </div>
    </>
  );
};

export default ApplicationCard;
