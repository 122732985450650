import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "react-bootstrap";
import AddIcon from "../../../assests/icons/ic_add.svg";
import threeDotsIcon from "../../../assests/icons/three-dots-icon.svg";
import { Dropdown, ButtonGroup, DropdownButton } from "react-bootstrap";
import {
  APPLY_STATUS,
  STATUS_SUCCESS,
  USER_ID,
} from "../../../config/localstorage_keys";
import {
  FORM_VALIDATION_MESSAGE,
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import toaster from "../../../utils/toaster";
import {
  referee,
  refereeStatus,
  refreeList,
} from "../../../_services/member-profile.service";
import {
  EMAIL_PATTERN,
  PHONE_CHECK,
  REQUIRED,
} from "../../../config/validation_patterns_constants";
import {
  isEmailValid,
  isEmpty,
  onFormFeildsChange,
  validateField,
} from "../../../utils/form_validators";
import { useStoreActions } from "easy-peasy";
import { getLocalStorage } from "../../../utils/storage";
import "../invite_referee/styles.scss";
import Loader from "../../../components/common/loader";
import icDeleteRed from "../../../assests/icons/ic-delete-red.svg";
import { emailValidation } from "../../../_services/candidate.service";
import { DEFAULT_CARD_SIZE, DEFAULT_PAGE_SIZE } from "../../../config/config";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";

const InviteReferee = () => {
  let isInfinite = false;
  // const navigate = useNavigate();

  const [showBar, setShowBar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [refereeOverview, setRefereeOverview] = useState({});
  const [allEmail, setAllEmail] = useState([{ value: "" }]);
  const [inputFields, setInputFields] = useState({ email: [] });
  const [emailCheck, setEmailCheck] = useState(false);
  const [status, setStatus] = useState();
  // const [isRetract, setIsRetract] = useState();
  console.log(refereeOverview, "refereeOverview");
  const handleFormChange = (index, event) => {
    // event.preventDefault();
    const newAllEmails = [...allEmail];
    newAllEmails[index].email = event.target.value;
    setAllEmail(newAllEmails);
  };

  const addFields = () => {
    const newAllEmails = [...allEmail];
    newAllEmails.push({ email: "" });
    setAllEmail(newAllEmails);
    setFormData({
      ...formData,
      email: [...formData?.email, emailValidationObject],
    });
  };
  const initializeEmailData = () => {
    const newAllEmails = [];
    newAllEmails.push({ email: "" });
    setAllEmail(newAllEmails);
    console.log(newAllEmails, "newAllEmails.........");
  };
  useEffect(() => {
    initializeEmailData();
  }, []);

  const [addEmail, setAddEmail] = useState();
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const emailValidationObject = {
    valueText: "",
    errorText: "",
    check: [REQUIRED, EMAIL_PATTERN],
  };
  const [formData, setFormData] = useState({
    email: [emailValidationObject],
    message: {
      valueText: "",
      errorText: "",
      check: ["required"],
    },
  });
  // const isEmailValid = (index,event) => {
  //   const pattern = EMAIL_PATTERN;
  //   if (pattern.test(event)) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const [apiRefresh, setApiRefresh] = useState(false);

  const sendReferral = () => {
    // console.log("send===>", JSON.stringify(inputFields));
    let isValid = true;

    allEmail?.forEach((key) => {
      if (key?.email == "") {
        isValid = false;
      }
    });
    // const Emails = allEmail.map((item) => item.email);
    // const request = { email: Emails, message: formData?.message?.valueText };

    if (isValid && formData?.message?.valueText !== "") {
      const Emails = allEmail.map((item) => item.email);
      const request = { email: Emails, message: formData?.message?.valueText };
      setShowLoader(true);
      // setApiRefresh(true);
      referee(request)
        .then((res) => {
          setShowLoader(false);
          const userId = getLocalStorage(USER_ID);
          if (userId) {
            saveCandidateDetails(userId);
          }
          if (res?.data?.status == STATUS_SUCCESS) {
            toaster("success", res?.data?.message);
            setShowLoader(false);
            // setFormData({
            //   email: {
            //     valueText: "",
            //   },
            //   message: {
            //     valueText: "",
            //   },
            // });
            // console.log(formData.email.valueText, "email form data.....");
            setShowLoader(false);

            if (apiRefresh) {
              setApiRefresh(false);
            } else {
              setApiRefresh(true);
            }
            initializeEmailData();

            setFormData({
              email: [emailValidationObject],
              message: {
                valueText: "",
                errorText: "",
                check: ["required"],
              },
            });

            toaster("success", "Invite refreess succesfully!!");
          } else {
            toaster("error", GENERAL_ERROR_MESSAGE);
            setShowLoader(false);
            // setApiRefresh(true);
          }
        })
        .catch((err) => {
          toaster(
            "error",
            err?.data?.message ? err?.data?.message : GENERAL_ERROR_MESSAGE
          );
          setShowLoader(false);
        });
    } else {
      toaster("error", "Enter All fields required");
    }
    //setAllEmail([{ value: "" }]);
  };

  // const initialFilterData = {
  //   pageNo: 1,
  //   pageSize: DEFAULT_PAGE_SIZE,
  //   // searchString: null,
  //   // sortBy: null,
  // };
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    // searchString: null,
    // sortBy: null,
  };

  const [dataCountInvite, setDataCountInvite] = useState(0);
  const [currentListSet, setCurrentListSet] = useState(0);
  const [allListFilterData, setAllListFilterData] = useState(initialFilterData);
  const [allJobMessage, setAllJobMessage] = useState("");

  const fetchReferee = (data) => {
    setShowLoader(true);
    refreeList(data).then(
      (res) => {
        if (res?.data.status === STATUS_SUCCESS) {
          // setRefereeOverview(res?.data?.data);
          setDataCountInvite(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...refereeOverview, ...res?.data?.data];
          }
          setCurrentListSet(data?.length);
          setRefereeOverview(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setRefereeOverview([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };

  const fetchMoreInvite = () => {
    setCurrentListSet(currentListSet + 10);
    isInfinite = true;
    fetchReferee({
      ...allListFilterData,
      pageNo: allListFilterData?.pageNo + 1,
    });
    setAllListFilterData({
      ...allListFilterData,
      pageNo: allListFilterData?.pageNo + 1,
    });
  };

  useEffect(() => {
    fetchReferee(initialFilterData);
  }, [apiRefresh]);

  // useEffect(() => {
  //   if (apiRefresh === true) {
  //     setApiRefresh(false);
  //   }
  // }, [apiRefresh]);

  // useEffect(() => {
  //   let temp = { ...initialFilterData };
  //   refreeList(temp);
  //   setAllListFilterData(temp);
  // }, []);

  const removeFields = (index) => {
    const Fields = [...allEmail];
    Fields.splice(index, 1);
    setAllEmail(Fields);
  };

  const id = getLocalStorage(USER_ID);
  // const [id, setId] = useState(null);
  // setId(userid);

  // console.log(id + "id");
  const Status = (status, id) => {
    setShowLoader(true);
    refereeStatus(id, status)
      .then((res) => {
        // setApiRefresh(false);
        setShowLoader(false);
        if (apiRefresh) {
          setApiRefresh(false);
        } else {
          setApiRefresh(true);
        }
        // fetchReferee(allListFilterData);
      })
      .catch((err) => {
        setShowLoader(false);
        // setApiRefresh(false);
        toaster("Error", err);
      });
  };

  const fakeData = [
    { email: "dnwiwo", invitedOn: "17-Dec-2022 11:35:33", status: "INVITED" },
  ];

  return (
    <div className="container">
      {showLoader && <Loader />}

      <div className="content-margin mt-4">
        <div className=" col-12 ">
          <div className="fw-700 fs-24">Invite Referees</div>
          <div className="fw-400 fs-12 mb-4 color-tertiary">
            You can invite potential referees who are currently not on Seikor
          </div>
          {/* Box Starts  */}
          <div className="p-3 p-sm-4 shadow-box h-100">
            <div className="col-lg-9 col-12">
              <div className="mb-4 mt-2">
                <div className="fw-700 fs-12 my-1">Email*</div>
                <div className="d-flex align-items-center flex-sm-row flex-column pt-3 position-relative">
                  <div className="col-12 col-sm-9 ">
                    {/* <div className="col-12 col-sm-12"> */}
                    <div className="me-sm-8">
                      {/* {addEmail.map((item, i) => { */}
                      {allEmail.map((input, index) => {
                        return (
                          <div
                            className="d-flex flex-column flex-sm-row pt-3 pb-3 "
                            key={index}
                          >
                            <div className="d-flex w-100">
                              <input
                                // type="email"
                                name="email"
                                maxLength={50}
                                // value={formData?.email?.valueText}
                                value={input.email}
                                id={index}
                                type={input.type}
                                placeholder="Enter refree email"
                                onChange={($event) => {
                                  // onFormFeildsChange($event, formData, setFormData);

                                  let copyFormData = { ...formData };
                                  copyFormData.email[index].valueText =
                                    $event?.target?.value;

                                  const checkValidationArray =
                                    formData?.email[index]?.check;
                                  const fieldValue =
                                    copyFormData?.email[index]?.valueText;
                                  if (!isEmailValid(fieldValue)) {
                                    setEmailCheck(false);
                                    copyFormData.email[index].errorText =
                                      FORM_VALIDATION_MESSAGE.EMAIL;
                                  }
                                  // else{
                                  //   setEmailCheck(true)
                                  // }
                                  // if(isEmailValid(fieldValue)){
                                  //   setEmailCheck(true)
                                  // }

                                  //     if (
                                  //       checkValidationArray.includes(REQUIRED) &&
                                  //       isEmpty(fieldValue)
                                  //     ) {
                                  //       copyFormData.email[index].errorText =
                                  //         FORM_VALIDATION_MESSAGE.REQUIRED;
                                  //     }
                                  else {
                                    setEmailCheck(true);
                                    copyFormData.email[index].errorText = "";
                                  }
                                  setFormData(copyFormData);

                                  handleFormChange(index, $event);
                                  if (formData?.email?.errorText == "") {
                                    emailValidation(formData, setFormData);
                                  }
                                }}
                                className={
                                  (formData?.email?.errorText ? "error" : "") +
                                  " mb-2 w-100 modal-form-input small-text-medium-gray text-dark"
                                }
                              />

                              <span className="field-error mt-1">
                                {formData?.email[index]?.errorText}
                              </span>

                              <span className="p-2">
                                <img
                                  src={icDeleteRed}
                                  alt="delete-icon"
                                  className="icon-style pointer"
                                  onClick={() => {
                                    removeFields();
                                  }}
                                />
                              </span>
                            </div>

                            <div className="col-12 col-sm-3">
                              <button
                                className="mt-3 mt-sm-0 mb-2 btn-invite-referee-invite p-2 w-100 text-center"
                                onClick={sendReferral}
                                // onClick={emailCheck && (formData?.message?.valueText !== null && formData?.message?.valueText !== "") ? sendReferral : null}
                              >
                                Invite
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="col-sm-3 col-12 mb-2">
                    {/* <button
                      className="mt-3 mt-sm-0 btn-invite-referee-invite p-2 w-100 text-center invite-btn-focus"
                      onClick={sendReferral}
                    >
                      Invite
                    </button> */}
                  </div>
                </div>
                <button
                  // className="fw-700 fs-12 btn invite-btn-focus"
                  className="fw-700 fs-12 btn "
                  onClick={() => {
                    addFields();
                  }}
                >
                  <img src={AddIcon} alt="add icon" />
                  &nbsp;&nbsp; <span className="pt-1">Add Another Email </span>
                </button>
              </div>
            </div>
            {/* Add Message  */}
            <div>
              <div className="fw-700 fs-12 mb-2">Message*</div>
              <textarea
                required
                style={{
                  border: "1px solid #B9B9B9",
                  resize: "None",
                  maxWidth: "618px",
                  height: "65px",
                  borderRadius: "4px",
                }}
                value={formData?.message?.valueText}
                type="text"
                name="message"
                onChange={($event) => {
                  onFormFeildsChange($event, formData, setFormData);
                }}
                className={
                  (formData?.message?.errorText ? "error" : "") +
                  "form-control fw-400 fs-12 p-2 w-100"
                }
                // className={
                //   (formData?.text?.errorText ? "error" : "") + " form-control"
                // }
                //placeholder="Hey ! take a look at this role. If you know someone in your circle you might be a great fit for this job, then do refer and earn some bonus as well"
                placeholder="Write your request to the referee here"
              ></textarea>
              <div className="field-error mt-1">
                {formData?.message?.errorText}
              </div>{" "}
            </div>
            {/* Add Message Ends  */}

            <hr />
            <InfiniteScroll
              dataLength={currentListSet}
              next={() => fetchMoreInvite()}
              hasMore={dataCountInvite > currentListSet}
              className="container-sm-fluid"
            >
              <Table responsive className="mt-4 justify-content-between w-100">
                <thead>
                  <tr className="fw-700 fs-12">
                    <th>Referee Email</th>
                    <th>Invited on</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="">
                  {refereeOverview && refereeOverview.length
                    ? refereeOverview.map((item, key) => {
                        return (
                          <tr className="fw-400 fs-14" key={key}>
                            <td className="color-primary text-break">
                              {item?.email}
                            </td>
                            <td
                              className="color-tertiary"
                              key={item?.invitedOn}
                            >
                              {item?.invitedOn}
                            </td>
                            <td className="color-tertiary" key={item?.status}>
                              {item?.status}
                            </td>

                            <td>
                              <Dropdown className="job-template-dropdown">
                                <Dropdown.Toggle
                                  variant="none"
                                  id="dropdown-basic"
                                  className="d-flex p-0 m-0 border-0 bg-transparent job-template-icon"
                                >
                                  <img src={threeDotsIcon} alt="retract-icon" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                                  <Dropdown.Item
                                    href="#/action-1"
                                    className="d-flex pb-2 pt-2"
                                    onClick={() =>
                                      Status(APPLY_STATUS.JOINED, item.id)
                                    }
                                  >
                                    <div className="d-flex gap-2">
                                      <span className="">Mark as joined</span>
                                    </div>
                                  </Dropdown.Item>
                                  <hr className="m-0 p-0" />
                                  <Dropdown.Item
                                    href="#/action-2"
                                    className="d-flex pb-2 pt-2 invite-btn-focus"
                                    onClick={() =>
                                      Status(APPLY_STATUS.RETRACTED, item.id)
                                    }
                                  >
                                    <div className="d-flex gap-2">
                                      <span className="text-danger">
                                        Retract Invitation
                                      </span>
                                    </div>
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })
                    : "No Data Found"}
                </tbody>
              </Table>
            </InfiniteScroll>
          </div>

          {/* Box Ends  */}
        </div>
      </div>
    </div>
  );
};
export default InviteReferee;
