import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";
import { STATUS_SUCCESS } from "../../config/localstorage_keys";
import Loader from "../../components/common/loader";
import { MASTER_TYPE } from "../../config/be_api_constants";
import { getMaster } from "../../_services/view.service";
import SearchComboBox from "../../components/SearchComboBox";

const CompanyHeadcount = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [headcount, setHeadcount] = useState();
  const [headcountMaster, setHeadcountMaster] = useState([]);
  const getAllMasterData = async () => {
    const count = await getMaster(MASTER_TYPE.HEADCOUNT);
    let headcountType = count.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setHeadcountMaster(headcountType);
  };

  // const [formData, setFormData] = useState({
  //   headcount: {
  //     valueText: "",
  //     initial: "",
  //     errorText: "",
  //     check: ["required"],
  //   },
  // });
  const selectedValue = (value, name) => {
    let event = {
      target: {
        value: "",
        name: "",
      },
    };
    event.target.value = value;
    event.target.name = name;
    setHeadcount(value.toString());
  };
  useEffect(() => {
    getAllMasterData();
  }, []);
  const saveHeadCount = {
    companyId: props.companyId,
    headcount: headcount,
  };

  const onSaveCount = () => {
    setShowLoader(true);
    create(saveHeadCount)
      .then((res) => {
        setShowLoader(false);
        toaster("success", "Count updated succesfully..");
        props?.setApiRefresh(true);
        props.onHeadcountClose();
        if (res?.data?.status == STATUS_SUCCESS) {
        }
      })
      .catch((e) => {
        setShowLoader(false);
        props?.setApiRefresh(false);
      });
  };
  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.onHeadcountClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onHeadcountClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">Headcount</div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600">
                  Employee Headcount
                </div>
                <div className="pt-3 pb-5">
                  {/* <input
                    type="text"
                    className="modal-form-input input-color-black"
                    // placeholder="400-500"
                    defaultValue={props.headcount}
                    onChange={(e) => {
                      setHeadcount(e.target.value);
                    }}
                  /> */}

                  <SearchComboBox
                    name="headcount"
                    inputData={headcountMaster}
                    key="headcount"
                    // defaultValue={[props.headcount]}
                    isMultiSelect={false}
                    // inputCssClass={`modal-input combo-search-box ${formData?.jobTitle?.errorText} form-control`}
                    inputCssClass={"modal-input combo-search-box"}
                    wrapperCssClass={"form-group col-12 col-md-12"}
                    placeholder={"Search or Select"}
                    onSelect={($event) => {
                      selectedValue($event, "headcount");
                    }}
                    searchListHeight={150}
                    badgeThemeCssClass={"blue fs-12"}
                    hideBadges={false}
                    isAllowUserDefined={true}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onHeadcountClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSaveCount();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyHeadcount;
