import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import ProfileImage from "../../components/profile_image";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import "../../pages/auth_protected/job_application_view_page/viewmessages.scss";
import {
  fetchChatMessages,
  sendMessageToRecruiters,
} from "../../_services/serikoradmin_view.service";

const ChatBox = (props) => {
  const senderMessages = [
    {
      message:
        "Hey Karthik, thanks for your recommendation and detailed feedback on Naman’s profile. However, we would like to understand a few more things about him from you as he has directly worked with you for over 5 years.",
      time: "3:35pm",
      senderName: "referrerName",
    },
    {
      message:
        "How would you describe Naman’s grasp on PHP and Phython. Any specific example you can provide where these skills were used in a particular project.",
      time: "3:39pm",
      senderName: "receiverName",
    },
    {
      message: "Yes sure, tell me what do you want to know.",
      time: "3:40pm",
      senderName: "referrerName",
    },
    {
      message:
        "Also if you can rate his PHP skills on a scale of 1-5, it would be great.",
      time: "3:45pm",
      senderName: "receiverName",
    },
    {
      message:
        "Let me know if it would help to connect over a 5 min call for the same.",
      time: "3:46pm",
      senderName: "receiverName",
    },
    {
      message: "Sure, pls ping me on my cell 9908079933",
      time: "3:50pm",
      senderName: "referrerName",
    },
    {
      message:
        "Hey Karthik, thanks for your recommendation and detailed feedback on Naman’s profile. However, we would like to understand a few more things about him from you as he has directly worked with you for over 5 years.",
      time: "3:35pm",
      senderName: "receiverName",
    },
    {
      message:
        "How would you describe Naman’s grasp on PHP and Phython. Any specific example you can provide where these skills were used in a particular project.",
      time: "3:39pm",
      senderName: "receiverName",
    },
    {
      message: "Yes sure, tell me what do you want to know.",
      time: "3:40pm",
      senderName: "referrerName",
    },
    {
      message:
        "Also if you can rate his PHP skills on a scale of 1-5, it would be great.",
      time: "3:45pm",
      senderName: "receiverName",
    },
    {
      message:
        "Let me know if it would help to connect over a 5 min call for the same.",
      time: "3:46pm",
      senderName: "receiverName",
    },
    {
      message: "Sure, pls ping me on my cell 9908079933",
      time: "3:50pm",
      senderName: "referrerName",
    },
  ];

  const [chats, setChats] = useState([]);
  const [typedMessage, setTypedMessage] = useState("");
  // const [jobId, setjobId] = useState();
  // const [jobReferralId, setjobReferralId] = useState();

  useEffect(() => {
    setChats([]);
  }, []);

  const fetchChatBoxMessages = (jobId, jobreferalId) => {
    fetchChatMessages(
      1,
      200,
      jobId,
      jobreferalId
      // props?.applicationDetails?.jobApplication?.jobId,
      // props?.applicationDetails?.jobApplication?.referalId
      // 47811,
      // 48787
    )?.then((res) => {
      // if (res?.status === "SUCCESS") {
      setChats(res?.data?.data?.reverse());
      // }
    });
  };

  const sendMessage = (message) => {
    sendMessageToRecruiters(
      message,
      props?.applicationDetails?.jobApplication?.jobId,
      props?.applicationDetails?.jobApplication?.userId,
      props?.applicationDetails?.jobApplication?.referalId
    )?.then((res) => {
      setTimeout(
        fetchChatBoxMessages(
          props?.applicationDetails?.jobApplication?.jobId,
          props?.applicationDetails?.jobApplication?.userId
        ),
        1000
      );
      setTypedMessage("");
    });
  };

  useEffect(() => {
    let jobId = props?.applicationDetails?.jobApplication?.jobId;
    let jobreferalId = props?.applicationDetails?.jobApplication?.userId;
    fetchChatBoxMessages(jobId, jobreferalId);
  }, [
    props?.applicationDetails?.jobApplication?.jobId,
    props?.applicationDetails?.jobApplication?.userId,
  ]);

  return (
    <div>
      <Modal
        fullscreen="lg-down"
        show={props.show}
        onHide={() => props.onMessagesClose()}
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
        scrollable={true}
        className={"rounded-corner-none"}
      >
        <Modal.Header className="mb-2 border-end m-0 p-1 w-100 ">
          <div className="d-flex justify-content-between w-100">
            <div className="p-4 pb-1">
              <div className="large-text-dark">Message to referee</div>
              <div className="small-text-medium-gray">
                Send a message to the referee
              </div>
            </div>
            <div className="pt-3 pe-3">
              <img
                src={icCloseRounded}
                alt="close-icon"
                onClick={() => props.onMessagesClose()}
                className="pointer"
                width="30px"
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="p-4 pt-0">
            <div className="d-flex justify-content-center">
              {/* <div className="date-show"> Mon, 22 Dec </div> */}
            </div>
            <div>
              {chats?.length === 0 ? (
                <div className="d-flex align-items-center justify-content-center font-medium-gray fw-600">
                  {/* {EMPTY_MESSEGE_BOX} */}
                </div>
              ) : (
                <>
                  {chats?.map((sms, index) => {
                    return (
                      <div key={index} className="w-100">
                        {sms?.recruiter === false ? (
                          <div className="d-flex gap-2 pt-1 pb-2 left-data align-items-center">
                            <ProfileImage
                              src={
                                process.env.REACT_APP_IMAGE_BASEURL +
                                sms?.senderProfilePic
                              }
                              name={sms.senderName}
                              width="40px"
                              height="40px"
                              initialsContainerClass="rounded-circle"
                            />
                            <div>
                              <div>{sms?.message}</div>
                              <div className="small-text-gray">
                                {sms?.messageDate}
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {sms?.recruiter === true ? (
                          <div className="d-flex gap-2 pt-1 pb-2 justify-content-end right-data align-items-center">
                            <div>
                              <div>{sms?.message}</div>
                              <div className="small-text-gray text-end">
                                {sms?.messageDate}
                              </div>
                            </div>
                            <ProfileImage
                              src={
                                process.env.REACT_APP_IMAGE_BASEURL +
                                sms?.senderProfilePic
                              }
                              name={sms.receiverName}
                              width="40px"
                              height="40px"
                              initialsContainerClass="rounded-circle"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </>
              )}
            </div>

            {/* Integrated Chats */}
            {/* <div>
              {chats &&
                chats.map((sms, index) => {
                  return (
                    <div className="w-100">
                      {sms?.recruiter === false ? (
                        <div className="d-flex gap-2 pt-1 pb-2 left-data align-items-center">
                          <div>
                            {sms?.receiverProfilePic === null ? (
                              <ProfileImage
                                // src={recruiterSrc}
                                name={sms?.senderName}
                                width="40px"
                                height="40px"
                                initialsContainerClass="rounded-circle"
                              />
                            ) : (
                              <ProfileImage
                                // src={recruiterSrc}
                                src={
                                  process.env.REACT_APP_IMAGE_BASEURL +
                                  sms?.receiverProfilePic
                                }
                                name={sms?.senderName}
                                width="40px"
                                height="40px"
                                initialsContainerClass="rounded-circle"
                              />
                            )}
                          </div>
                          <div>
                            <div>{sms?.message}</div>
                            <div className="small-text-gray text-start">
                              {sms?.messageDate}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className="d-flex gap-2 pt-1 pb-2 justify-content-end right-data align-items-center w-100">
                            <div>
                              <div>{sms?.message}</div>
                              <div className="small-text-gray text-end">
                                {sms?.messageDate}
                              </div>
                            </div>
                            <div>
                              {sms?.senderProfilePic === null ? (
                                <ProfileImage
                                  //   src={referrerSrc}
                                  name={sms?.senderName}
                                  width="40px"
                                  height="40px"
                                  initialsContainerClass="rounded-circle"
                                />
                              ) : (
                                <ProfileImage
                                  //   src={referrerSrc}
                                  src={
                                    process.env.REACT_APP_IMAGE_BASEURL +
                                    sms?.senderProfilePic
                                  }
                                  name={sms?.senderName}
                                  width="40px"
                                  height="40px"
                                  initialsContainerClass="rounded-circle"
                                />
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="w-100">
          <div className="d-flex gap-2 w-100 ps-3 pe-3">
            <input
              type="text"
              className="btn cursor-text fs-12 bg-white border-dark text-start w-100 p-3"
              placeholder="Write your message"
              // defaultValue={typedMessage}
              onChange={(e) => setTypedMessage(e.target.value)}
              value={typedMessage}
            />
            <button
              className="btn btn-outline-dark fs-12 fw-700 text-white bg-dark ps-5 pe-5 p-3"
              onClick={() => {
                sendMessage(typedMessage);
              }}
            >
              Send
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChatBox;
