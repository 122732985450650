import {
  ALL_ORAGANIZATION_ENDPOINT,
  CREATE,
  GET_COMPANY_DETAILS,
  SAVE_POC_MEMBER_ENDPOINT,
  VIEW_ORAGANIZATION_ENDPOINT,
} from "../config/be_api_constants";
import api from "./api";

// const create = (
//   companyId,
//   companyName,
//   companyCode,
//   companyDesc,
//   officialEmail,
//   mobileNumber,
//   alternateNumber,
//   streetAddress,
//   locality,
//   pinCode,
//   state,
//   country,
//   geographiesOprn,
//   categories,
//   industries,
//   prasence,
//   companyVideoId,
//   headcount,
//   funding,
//   employeeBenefits,
//   potentialLink,
//   potentialDescription,
//   newsLinks,
//   glassdoorRating,
//   glassdoorReviews,
//   glassdoorUrl,
//   companyLogo
// ) => {
//   return api
//     .post(CREATE, {
//       companyId,
//       companyName,
//       companyCode,
//       companyDesc,
//       officialEmail,
//       mobileNumber,
//       alternateNumber,
//       streetAddress,
//       locality,
//       pinCode,
//       state,
//       country,
//       geographiesOprn,
//       categories,
//       industries,
//       prasence,
//       companyVideoId,
//       headcount,
//       funding,
//       employeeBenefits,
//       potentialLink,
//       potentialDescription,
//       newsLinks,
//       glassdoorRating,
//       glassdoorReviews,
//       glassdoorUrl,
//       companyLogo,
//     })
//     .then((response) => {
//       console.log("response", response);
//       return response;
//     });
// };

const create = (data) => {
  return api.post(CREATE, data).then((response) => {
    return response;
  });
};

const getCompanyDetails = () => {
  return api
    .post(GET_COMPANY_DETAILS, {
      // pageNo: pageNo,
      // pageSize: pageSize,
      // companyId:companyId
    })
    .then((response) => {
      return response;
    });
};

const viewOraganization = (orgId) => {
  return api
    .post(VIEW_ORAGANIZATION_ENDPOINT, {
      orgId: orgId,
    })
    .then((response) => {
      return response;
    });
};
const allOrganization = (data) => {
  return api.post(ALL_ORAGANIZATION_ENDPOINT, data).then((response) => {
    return response;
  });
};
const saveMember = (
  companyId,
  pocName,
  pocEmail ,
  pocOther ,
  pocNumber,
) => {
  return api
    .post(SAVE_POC_MEMBER_ENDPOINT, {
      companyId,
      pocName,
      pocEmail ,
      pocOther ,
      pocNumber,
      // data
    })
    .then((response) => {
      return response;
    });
};
// export const companyMaster = (mType, pageNo, pageSize) => {
//   return api
//     .post(MASTERCOMPANY, {
//       mType: mType,
//       pageNo: pageNo,
//       pageSize: pageSize,
//     })
//     .then((response) => {
//       console.log(response);
//       return response;
//     });
// };

export {
  create,
  getCompanyDetails,
  viewOraganization,
  allOrganization,
  saveMember,
};
