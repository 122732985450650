import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";
import closeIcon from "../../assests/icons/ic-invite-pg-close.svg";
import addIcon from "../../assests/icons/ic_add.svg";
import Loader from "../../components/common/loader";
import ProfileImage from "../../components/profile_image";
import { STATUS_SUCCESS } from "../../config/be_api_constants";
import {
  GENERAL_ERROR_MESSAGE,
  IMAGE_DIMENSION,
  IMAGE_SIZE_EXCEEDING,
  INVALID_IMAGE_TYPE,
} from "../../config/messages";
import { EMAIL_PATTERN } from "../../config/validation_patterns_constants";
import { onFormFeildsChange, validateField } from "../../utils/form_validators";
import toaster from "../../utils/toaster";
import { create } from "../../_services/company.service";

function AdminAddOrgDialog(props) {
  const [showLoader, setShowLoader] = useState(false);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [formData, setFormData] = useState({
    name: {
      valueText: "",
      errorText: "",
      check: ["required"],
      shouldShowError: false,
    },
    code: {
      valueText: "",
      errorText: "",
      check: ["required"],
      shouldShowError: false,
    },
    email: {
      valueText: "",
      errorText: "",
      check: ["required", EMAIL_PATTERN],
      shouldShowError: false,
    },
  });
  const [companyLogo, setCompanyLogo] = useState();
  const [previewRequested, setPreviewRequested] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);

  const [companyUrl, setCompanyUrl] = useState();
  const [image, setimage] = useState("");
  const [files, setFiles] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });
  // const [pictureData, setPictureData] = useState({
  //   acceptedFiles: null,
  //   fileName: "",
  //   extension: "",
  // });
  const imageUrl = "";
  const handleDrop = (acceptedFiles) => {
    // const canvas = document.getElementById("upload-image");
    setPreviewRequested(true);
    acceptedFiles.map(async (file) => {
      // console.log(file.name);
      // imageUrl=file.name
      setimage(file.name);
      // console.log(image, "images----><");
      const base64 = await convertBase64(file);
      const base64Value = base64.split(",").pop();

      setCompanyLogo(base64Value);
      // console.log("base64",base64Value)
      // console.log(companyLogo," base64Value");
      // setPreviewRequested(false);
    });
    setFiles({
      ...files,
      acceptedFiles,
      // fileName,
      // extension,
    });
    if (acceptedFiles && acceptedFiles?.length >= 1) {
      var image = new Image();
      let isValid = true;

      const fileSize = acceptedFiles[0]?.size;
      const fileName = acceptedFiles[0]?.name;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension = fileName.slice(extensionIndex + 1);
      const isFileExtValid =
        fileName.includes(".png") ||
        fileName.includes(".jpg") ||
        fileName.includes(".jpeg");

      if (!isFileExtValid) {
        toaster("error", INVALID_IMAGE_TYPE);
        setPreviewRequested(false);
        return;
      }

      const isFileSizeValid = fileSize < 2e6;

      if (!isFileSizeValid) {
        toaster("error", IMAGE_SIZE_EXCEEDING);
        setPreviewRequested(false);
        return;
      }

      if (isFileExtValid && isFileSizeValid) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid) {
        let dimensionValid = false;

        setFiles({
          ...files,
          acceptedFiles,
          fileName,
          extension,
        });
        let previewImage = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });

        image.src = previewImage?.preview;
        image.onload = function () {
          if (image.width <= 200 && image.height <= 200) {
            dimensionValid = true;
          }
          if (isValid && dimensionValid) {
            setPreviewRequested(true);
            setPreviewSrc(previewImage?.preview);
            // setDeleteFlag(false);
          } else {
            toaster("error", IMAGE_DIMENSION);
            setPreviewRequested(false);
          }
        };
      }
    } else {
      toaster("error", GENERAL_ERROR_MESSAGE);
      setPreviewRequested(false);
    }
    //console.log("prev--->", previewRequested);

    //console.log(process.env.REACT_APP_IMAGE_BASEURL + image, "image--##")
    //console.log(acceptedFiles, "acceptedFilesacceptedFiles");
  };
  const convertBase64 = (companyLogo) => {
    return new Promise((resovle, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(companyLogo);
      fileReader.onload = () => {
        resovle(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const saveValue = {
    companyName: formData?.name?.valueText,
    companyCode: formData?.code?.valueText,
    officialEmail: formData?.email?.valueText,
    companyLogo: companyLogo,
  };
  const onSave = () => {
    
    if (formData?.name?.valueText === "" || formData?.code?.valueText === "" || previewSrc === null ) {
      toaster("error", "All fields required");
    } else {
      setShowLoader(true);
      //toaster("success" ,"Company name updated successfully")
      if (previewRequested) {
        let formData = new FormData();
        const fileObjects = files?.acceptedFiles.map((file) => {
          formData.append("contentData", file);
          formData.append(
            "contentMetaData",
            JSON.stringify({
              contentType: "PROFILE",
              contentName: `${files?.fileName}`,
              contentExtention: `${files?.extension}`,
            })
          );
        });
        setShowLoader(true);
        props?.setApiRefresh(true);
        create(saveValue)
          .then((res) => {
            setFormData({
              name: {
                valueText: "",
              },
              email: {
                valueText: "",
              },
              code: {
                valueText: "",
              },
            });
            setShowLoader(false);
            props?.setApiRefresh(true);
            // props.onNameClose();
            if (res?.data?.status == STATUS_SUCCESS) {
              toaster(
                "success",
                res?.data?.message ? res?.data?.message : STATUS_SUCCESS
              );
              props?.closeModal();
            }
            // toaster("success" ,"Company name updated successfully")
            // props?.closeModal()
          })
          .catch((err) => {
            setShowLoader(false);
            props?.setApiRefresh(false);
            toaster("error", err);
          });
      }
     
    }
    var isValid = true;

    Object.keys(formData)?.forEach((key) => {
      if (!validateField(key, formData, setFormData)) {
        isValid = false;
      }
    });
  };

  return (
    <>
      <Modal
        show={props?.addOrgModalShow}
        onHide={() => {
          props?.setAddOrgModalShow(false);
        }}
        fullscreen="md-down"
        className=" p-0 radius-16"
        aria-labelledby=""
        centered
      >
        <Modal.Header
          style={{ borderBottom: "none" }}
          className="dialog-header p-4"
        >
          <Modal.Title className="dialog-title fw-700 fs-20">
            Add New Organization
          </Modal.Title>
          <img
            className="pointer"
            onClick={() => {
              props?.setAddOrgModalShow(false);
            }}
            style={{ widht: "30px" }}
            src={closeIcon}
          />
        </Modal.Header>
        <Modal.Body className=" px-4 pb-4 pt-0 mt-0">
          <div className="">
            <label className="modalLabel fs-14 fw-600">Org Legal Name *</label>
            <input
              //className=" form-control modal-input"
              required
              // name="orgLegalName"
              name="name"
              maxLength={32}
              value={formData?.name?.valueText}
              onChange={($event) => {
                onFormFeildsChange($event, formData, setFormData);
                setName($event.target.value);
              }}
              // className={
              //   (formData?.name?.errorText  &&
              //     formData?.name?.shouldShowError
              //     ? "error"
              //     : "") + " form-control modal-input"
              // }

              // className={
              //   (name === "" ? "error" : "") + " modal-input form-control"
              // }

              className={
                (formData?.name?.errorText ? "error" : "") +
                " form-control modal-form-input text-dark h-40"
              }
            />
            {/* <div className="field-error mt-1">
            {formData?.name?.shouldShowError
              ? formData?.name?.errorText
              : ""}
          </div> */}
            {formData.name.errorText && (
              <div className="field-error mt-1">{formData.name.errorText}</div>
            )}
            {/* /> */}
            <label className="modalLabel mt-4 fs-14 fw-600">
              Org Display Name *
            </label>
            <input
              // className=" form-control modal-input"
              required
              // name="orgDisplayName"
              name="code"
                  maxLength={32}
              onChange={($event) => {
                onFormFeildsChange($event, formData, setFormData);
                setCode($event.target.value);
              }}
              value={formData?.code?.valueText}
              // className={
              //   (formData?.code?.errorText &&
              //   formData?.code?.shouldShowError
              //     ? "error"
              //     : "") + " form-control"
              // }

              // className={(code === "" ? "error" : "") + " form-control"}

              className={
                (formData?.code?.errorText ? "error" : "") +
                " form-control modal-form-input text-dark h-40"
              }
            />
            {/* <div className="field-error mt-1">
            {formData?.code?.shouldShowError ? formData?.code?.errorText : ""}
          </div> */}

            {formData.code.errorText && (
              <div className="field-error mt-1">{formData.code.errorText}</div>
            )}

            <div className="mt-4 fs-14 fw-600">
              Logo * (min 164 X 164 Pixel)
              {/* <Modal.Body className="d-flex justify-content-center align-items-center">
            <div className="modal-profile-picture-div">
              {previewRequested ? (
                <ProfileImage
                  src={`data:image/jpeg;base64 , ${companyLogo}`}
                  // name={candidateDetails?.userRegistrationDetails?.name}
                  initialsContainerClass="sidebar-default-profile-picture"
                />

              ) : (
                <ProfileImage
                      src={`data:image/jpeg;base64 , ${props.companyLogo}`}
                      
                      // name={candidateDetails?.userRegistrationDetails?.name}
                      initialsContainerClass="sidebar-default-profile-picture"
                    />
              )}
              
            </div>
          </Modal.Body> */}
              <Dropzone
                onDrop={(acceptedFiles) => {
                  handleDrop(acceptedFiles);
                }}
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <>
                      <div className="label d-flex align-items-center mt-3">
                        <div className="org-logo-upload-div d-flex justify-content-center align-items-center pointer">
                          <img src={addIcon} />
                        </div>
                      </div>
                    </>
                  </div>
                )}
              </Dropzone>
              <br></br>
              {previewSrc ? (
                <div>
                  {" "}
                  <div className="modal-profile-picture-div">
                    {previewRequested ? (
                      //   <ProfileImage
                      //     src={`data:image/jpeg;base64 , ${companyLogo}`}
                      //     // name={candidateDetails?.userRegistrationDetails?.name}
                      //     initialsContainerClass="sidebar-default-profile-picture"
                      //   />

                      // ) : (
                      //   <ProfileImage
                      //         src={`data:image/jpeg;base64 , ${companyLogo}`}

                      //         // name={candidateDetails?.userRegistrationDetails?.name}
                      //         initialsContainerClass="sidebar-default-profile-picture"
                      //       />
                      // )}

                      <img
                        src={previewSrc}
                        width="70px"
                        // name={candidateDetails?.adminUserResponse?.name}
                        initialsContainerClass=" initialsStyle2-xxxl"
                      />
                    ) : (
                      <img
                        width="70px"
                        src={`data:image/jpeg;base64 , ${companyLogo}`}
                        // name={candidateDetails?.adminUserResponse?.name}
                        initialsContainerClass=" initialsStyle2-xxxl"
                      />
                    )}
                  </div>
                </div>
              ) : (
                <p className="modal-default-profile-picture d-flex justify-content-center align-items-center">
                  {/* {initi} */}
                </p>
              )}
            </div>
            <label className="modalLabel mt-4 fs-14 fw-600">
              Org Admin Email
            </label>
            <input
              // className=" form-control modal-input"
              required
              type="email"
              name="email"
              value={formData?.email?.valueText}
              maxLength={50}
              onChange={($event) => {
                onFormFeildsChange($event, formData, setFormData);
              }}
              className={
                (formData?.email?.errorText && formData?.email?.shouldShowError
                  ? "error"
                  : "") + " form-control"
              }
            />
            <div className="field-error mt-1">
              {formData?.email?.shouldShowError
                ? formData?.email?.errorText
                : ""}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ background: "#f4f7fb" }}
          className="username-buttons p-3 mt-3"
        >
          <Button
            className="btn btn-dark btn-save"
            onClick={() => {
              // onSave();
              if (formData?.name?.errorText == "") {
                onSave();
              }
            }}
          >
            Create Org
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AdminAddOrgDialog;
