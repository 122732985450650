/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import logoAirbnb from "./../../../assests/images/airbnblogo.png";
import icEdit from "./../../../assests/icons/ic-edit.svg";
import icStar from "./../../../assests/icons/ic_rating.svg";
import icEditSmall from "./../../../assests/icons/ic-small-edit.svg";
import icCloseBlue from "./../../../assests/icons/ic-close-blue.svg";
import SearchComboBox from "../../../components/SearchComboBox";
import globe from "../../../assests/icons/ic_globe.svg";
import aicon from "../../../assests/icons/ic_aicon.svg";
import twitter from "../../../assests/icons/ic_twitter.svg";
import youtube from "../../../assests/icons/ic_youtube.svg";
import playstore from "../../../assests/icons/ic_playstore.svg";
import vicon from "../../../assests/icons/ic_vicon.svg";
import linkedin from "../../../assests/icons/ic_linkedin.svg";
import facebook from "../../../assests/icons/ic_facebook.svg";
import aboutEmployer from "../../../assests/images/employermain.jpg";
import aboutEmployer1 from "../../../assests/images/aboutemployer2.png";
import aboutEmployer2 from "../../../assests/images/aboutemployer3.png";
import aboutEmployer3 from "../../../assests/images/aboutemployer4.png";
import icAttachnment from "../../../assests/icons/ic_attachment.svg";
import icPlusIcon from "../../../assests/icons/ic-plus-icon.svg";
import icDelete from "../../../assests/icons/ic_delete.svg";
import icDeleteRed from "../../../assests/icons/ic-delete-red.svg";
import { Await, Link } from "react-router-dom";
import CompanyLogo from "../../../dialogs/company_profile_dialogs/companylogo";
import CompanyName from "../../../dialogs/company_profile_dialogs/companyname";
import CompanyEmail from "../../../dialogs/company_profile_dialogs/companyemail";
import CompanyNumber from "../../../dialogs/company_profile_dialogs/companynumber";
import CompanyAddress from "../../../dialogs/company_profile_dialogs/companyaddress";
import CompanyVideo from "../../../dialogs/company_profile_dialogs/companyvideo";
import CompanyHeadcount from "../../../dialogs/company_profile_dialogs/companyheadcount";
import CompanyFunding from "../../../dialogs/company_profile_dialogs/companyfunding";
import CompanyPotentialCollegues from "../../../dialogs/company_profile_dialogs/companypotentialcollegues";
import CompanyPresence from "../../../dialogs/company_profile_dialogs/companypresence";
import CompanyAbout from "../../../dialogs/company_profile_dialogs/companyabout";
import CompanyImages from "../../../dialogs/company_profile_dialogs/companyimages";

import "./companyprofile.scss";
import CompanyReviews from "../../../dialogs/company_profile_dialogs/companyreviews";
import { getLocalStorage } from "../../../utils/storage";
import { useStoreActions, useStoreState } from "easy-peasy";
import toaster from "../../../utils/toaster";
import { STATUS_SUCCESS, USER_ID } from "../../../config/localstorage_keys";
import { GENERAL_ERROR_MESSAGE } from "../../../config/messages";
import { isEmpty, onFormFeildsChange } from "../../../utils/form_validators";
import { useParams } from "react-router-dom";

import CompanyImage from "../../../components/company_image";
import Loader from "../../../components/common/loader";
import ProfileImage from "../../../components/profile_image";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
} from "react-share";
import { SUCCESS } from "dropzone";
import { create, getCompanyDetails } from "../../../_services/company.service";
import { MASTER_TYPE } from "../../../config/be_api_constants";
import { EMAIL_PATTERN } from "../../../config/validation_patterns_constants";
import {
  deleteImage,
  downloadFile,
  getLocations,
  getMaster,
} from "../../../_services/view.service";
import useWindowDimensions from "../../../utils/use_window_dimension";
import { isValid } from "date-fns";
import { isCheckValue } from "../../../utils/utils";
import { isConstructorDeclaration } from "typescript";
import { ProgressBar } from "react-bootstrap";

const CompanyProfilePage = (props) => {
  const { height, width } = useWindowDimensions();
  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );
  const [logo, setLogo] = useState();
  const [profileSrc, setProfileSrc] = useState([]);
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );

  // const companyProfileResponse = useStoreState(
  //   (state) => state?.candidate?.candidateDetails?.companyProfileResponse
  // );

  const [showCompanyLogo, setShowCompanyLogo] = useState(false);
  const [showCompanyName, setShowCompanyName] = useState(false);
  const [showCompanyEmail, setShowCompanyEmail] = useState(false);
  const [showCompanyNumber, setShowCompanyNumber] = useState(false);
  const [showCompanyAddress, setShowCompanyAddress] = useState(false);
  const [showCompanyVideo, setShowCompanyVideo] = useState(false);
  const [showCompanyHeadcount, setShowCompanyHeadcount] = useState(false);
  const [showCompanyFunding, setShowCompanyFunding] = useState(false);
  const [showCompanyPotentialCollegues, setShowCompanyPotentialCollegues] =
    useState(false);
  const [showCompanyPresence, setShowCompanyPresence] = useState(false);
  const [showCompanyAbout, setShowCompanyAbout] = useState(false);
  const [showCompanyImages, setShowCompanyImages] = useState(false);
  const [showCompanyReviews, setShowCompanyReviews] = useState(false);

  const [showLoader, setShowLoader] = useState(false);
  const [companyId, setCompanyId] = useState();
  const [companyName, setCompanyName] = useState();
  const [companyCode, setCompanyCode] = useState();
  const [companyDesc, setCompanyDesc] = useState();
  const [officialEmail, setOfficialEmail] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [alternateNumber, setAlternateNumber] = useState();
  const [streetAddress, setStreetAddress] = useState();
  const [locality, setLocality] = useState();
  const [pinCode, setPincode] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();
  const [geographies, setgeographies] = useState([]);
  const [categories, setCategories] = useState([]);
  const [Companyindustries, setCompnayIndustries] = useState();
  const [presence, setPrasence] = useState({
    // id: "",
    // linkUrl: "",
    // linkTitle: "linkedin",
  });
  const [videoLink, setvideoLink] = useState(null);
  const [headcount, setHeadcount] = useState();
  const [Funding, setFunding] = useState();
  const [allemployeeBenefits, setAllEmployeeBenefits] = useState([
    // { value: "" },
  ]);


  const [employeeBenefitsvalue, setEmployeeBenefitsvalue] = useState([]);
  const [empBenefits,setEmpBenifits] = useState([])
  const [potentialLink, setPotentialLink] = useState();
  const [potentialDescription, setPotentialDescription] = useState();
  const [newsLinks, setNewsLinks] = useState();
  const [news, setNews] = useState([]);
  const [glassdoorRating, setGlassdoorRating] = useState();
  const [glassdoorReviews, setGlassdoorReviews] = useState();
  const [glassdoorUrl, setGlassdoorUrl] = useState();
  const [companyLogo, setCompanyLogo] = useState();
  const [potentialImages, setPotentialImages] = useState([]);
  const [apiRefresh, setApiRefresh] = useState(false);

  const [geographicOp, setgeographicOp] = useState([]);
  const [categoriesOutput, setCategoriesOutput] = useState([]);
  const [industriesOutput, setIndustriesOutput] = useState([]);
  const [functionsOutput, setFunctionsOutput] = useState([]);

  const [companyIndustry, setCompanyIndustry] = useState([]);
  const [companyCategory, setCompanyCategory] = useState([]);
  const [locations, setLocation] = useState([]);
  const [keySkill, setKeySkill] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [companyLocation, setcompanyLocation] = useState();
  const [locationOutput, setLocationOutput] = useState([]);

  const [pageNo, setPageNo] = useState("");
  const [pageSize, setPageSize] = useState("");

  const [show, setShow] = useState([]);
  const [fileNames, setFileNames] = useState([]);

  const getAllMasterData = async () => {
    const functions = await getMaster(MASTER_TYPE.CATEGORY);
    let funct = functions.map((el) => {
      return { name: el.masterValue, description: el.Code };
    });
    setFunctions(funct);

    // const location = await getMaster(MASTER_TYPE.LOCATION);
    const industries = await getMaster(MASTER_TYPE.INDUSTRIES);

    // let loc = location.map((el) => {
    //   return { name: el.masterValue, description: el.Code };
    // });
    // setLocation(loc);

    if (industries && industries.length > 0) {
      const industryValues = industries?.map(
        (item) => item.masterValue && { name: item.masterValue }
      );
      if (industryValues && industryValues.length > 0) {
        setIndustries(industryValues);
      }
    }
    getLocations().then((res) => {
      setLocation(locations);
      return res;
    });
  };

  const youtubePlayerContainerRef = useRef(null);
  const [youtubePlayerHeight, setYoutubePlayerHeight] = useState(0);
  const [youtubePlayerWidth, setYoutubePlayerWidth] = useState(0);

  useEffect(() => {
    // The 'current' property contains info of the reference:
    // align, title, ... , width, height, etc.
    if (youtubePlayerContainerRef?.current) {
      let containerWidth = youtubePlayerContainerRef?.current?.offsetWidth - 32;
      setYoutubePlayerHeight(containerWidth / 1.77);
      setYoutubePlayerWidth(containerWidth);
    }
  }, [youtubePlayerContainerRef, width]);

  // const [specializedOutput, setSpecializedOutput] = useState(["Travel"]);

  const [viewCompanyDetails, setViewCompanyDetails] = useState(null);
  // setViewCompanyDetails(companyData?.companyId);

  const [pictureData, setPictureData] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });

  const [emailData, setEmailData] = useState({
    officialEmail: {
      valueText: "",
      errorText: "",
      check: ["required", EMAIL_PATTERN],
    },
  });

  const [viewDetails, setViewDetails] = useState();
  const notAppliedCompanyDetails = useStoreState(
    (state) => state?.notAppliedCompanyDetails
  );
  const setNotAppliedCompanyDetails = useStoreActions(
    (action) => action?.setNotAppliedCompanyDetails
  );
  const fetchDetails = () => {
    setShowLoader(true);
    getCompanyDetails()
      .then((res) => {
        // setApiRefresh(true);
        if (res?.data?.status == STATUS_SUCCESS) {
          const userId = getLocalStorage(USER_ID);
          if (userId) {
            saveCandidateDetails(userId);
          }
          let companyData = res?.data?.data;
          setCompanyData(companyData);
          setViewDetails(res?.data?.data[0]);
          setNotAppliedCompanyDetails({
            ...notAppliedCompanyDetails,
            companyData,
          });
       const filteredData = res?.data?.data[0]?.geographiesOprn.filter(item => item !== '');
      
       if(filteredData!==""){
        setgeographicOp(filteredData)
       }
       const CategData = res?.data?.data[0]?.categories.filter(item => item !== '');               
          if (CategData) {
            setCategoriesOutput(CategData);
          }
        const IntData= res?.data?.data[0]?.industries.filter(item => item !== '');               
          if (IntData) {
            setIndustriesOutput(IntData);
          }
          setNews(res?.data?.data[0]?.newsLinks);
          setAllEmployeeBenefits(res?.data?.data[0]?.employeeBenefits?.map((item)=>{
            return {
              employeeBenefits:item
            }
          }))
          setCompnaypicture(res?.data?.data[0]?.potentialImagesUrl);
          setShowLoader(false);
        } else {
          toaster("error", "Company data is not available");
          // setApiRefresh(false);
        }
        
      })
      .catch((err) => {
        setShowLoader(false);
        // setApiRefresh(false);
        toaster("error", err);
      });
  };
  console.log(geographicOp,categoriesOutput,"geographicOp")
  useEffect(() => {
    fetchDetails();
  }, []);
  useEffect(() => {
    // if (apiRefresh === true) {
      fetchDetails();
      // setApiRefresh(false);
    // }
  }, [apiRefresh]);

  // const handleFormChange = (index, event) => {
  //   // event.preventDefault();
  //   const newAllNews = [...newsLinks];
  //   newAllNews[index] = event.target.value;
  //   setNewsLinks(newAllNews);

  // };

  // const handleFormChange = (index, event) => {
  //   let data=[...new  newsLinks]

  // }

  // const NewsAdd = newsLinks.map(
  //   (item) => item.NewsAdd
  // );

  const saveNews = {
    companyId: viewDetails?.companyId,
    newsLinks: [...news, newsLinks],
  };
  const companyDetails = () => {
    setApiRefresh(true);
    setShowLoader(true);
    create(saveNews)
      .then((res) => {
        fetchDetails();
        setShow(res.data);
        setNewsLinks("");
      })
      .catch((e) => {
        setApiRefresh(false);
      });
  };

  const removeNewsFields = (index) => {
    let newsFields = [...news];
    newsFields.splice(index, 1);
    setNews(newsFields);

    const saveNews = {
      companyId: viewDetails?.companyId,
      newsLinks: [...newsFields],
    };
    setApiRefresh(true);
    setShowLoader(true);
    create(saveNews)
      .then((res) => {
        fetchDetails();
        setShow(res.data);
      })
      .catch((e) => {
        setApiRefresh(false);
      });
  };

  const [employeeValue, setEmployeeValue] = useState();
  const [value, setValue] = useState();

  const [indexvalue, setIndexValue] = useState();

  const handleEmployeeChange = (index, event) => {
    // event.preventDefault();
    const newAllEmployee = [...allemployeeBenefits];
    // const newAllEmployee1 = [];
    newAllEmployee[index].employeeBenefits = event.target.value;
    // newAllEmployee1[index] = event.target.value;
    setValue(event.target.value);
    setIndexValue(index);
    setAllEmployeeBenefits(newAllEmployee);
  };

  // const initializeEmployeeData = () => {
  //   // const newAllEmployee = [];
  //   // newAllEmployee.push({ employeeBenefits: "" });
  //   // setAllEmployeeBenefits(newAllEmployee);
  //   if(empBenefits && empBenefits?.length > 0 ){
  //     empBenefits?.forEach((item,index)=>{
  //       return console.log(item,"item")
  //     })
  //   }
  // };
  // useEffect(() => {
  //   initializeEmployeeData();
  // }, []);

//   const removeInputFields  = (input,index) => {

//     const newAllEmployee = [...allemployeeBenefits];
//     // newAllEmployee.splice(index , 1);
//     // setAllEmployeeBenefits(newAllEmployee);

// //  console.log("test",allemployeeBenefits,index,input)
//     // const remove = newAllEmployee.filter((item,i)=>{
//     //   console.log("item",item,i);
//     //   return i !== index
//     // })
  
//     newAllEmployee.splice(index,1);
//     // list.splice(index, 1);
//     setAllEmployeeBenefits(newAllEmployee);
//   };
  
  
const removeInputFields = (data,index) => {
  const rows = [...allemployeeBenefits];
  let itemIndex = rows.findIndex(
    (item, index) => item?.employeeBenefits === data?.employeeBenefits
  );

  rows.splice(itemIndex, 1);
  setAllEmployeeBenefits(rows);
};
console.log("checkkk", allemployeeBenefits);

  const addFields = () => {
    //  if(value !=="" ){

    const newAllEmployee = [...allemployeeBenefits];
    newAllEmployee.push({ employeeBenefits: "" });
    setAllEmployeeBenefits(newAllEmployee);
  };

  const [isError, setIsError] = useState({ EmployeeBenefitsError: "" });

  const EmployeeBenefits = allemployeeBenefits.map(
    (item) => item.employeeBenefits
  );

  const saveEmployee = {
    companyId: viewDetails?.companyId,
    employeeBenefits: EmployeeBenefits,
  };
  const AddBenifits = () => {
    let isValid = true;

    allemployeeBenefits?.forEach((key) => {
      if (key?.employeeBenefits == "") {
        isValid = false;
      }
    });

    if (isValid) {
      setShowLoader(true);
      setApiRefresh(true);
      //  alert("else")
      // toaster("add value")
      create(saveEmployee)
        .then((res) => {
          if (res?.status === 200) {
            setApiRefresh(!apiRefresh);
            // } else {
            //   toaster("error",  "Error");
          }
          setShowLoader(false);
          setApiRefresh(false);
          // initializeEmployeeData();
          toaster("success", "Added successfully");
          //setShow(res.data);
        })
        .catch((e) => {
          setApiRefresh(false);
          setShowLoader(false);
        });
    } else {
      toaster("error", " Please add Values ");
    }
    // }
  };

  const [formData, setFormData] = useState({
    GeographiesOprn: {
      valueText: "",
      initial: "",
      errorText: "",
      check: [""],
    },
    Categories: {
      valueText: "",
      initial: "",
      errorText: "",
      check: [""],
    },
    Industries: {
      valueText: "",
      initial: "",
      errorText: "",
      check: [""],
    },
  });
  const selectedCategories = (value, name) => {
    let event = {
      target: {
        value: "",
        name: "",
      },
    };
    event.target.value = value;
    event.target.name = name;
    const categ = {
      companyId: viewDetails?.companyId,
      categories: value,
    };
    onFormFeildsChange(event, formData, setFormData);
    // const companyId = viewDetails?.companyId;
    // const geographiesOprn = formData?.Location?.valueText;
    setShowLoader(true);
    setApiRefresh(true);
    create(categ)
      .then((res) => {
        setShowLoader(false);
        setApiRefresh(true);
        // if (res?.data?.status == STATUS_SUCCESS) {
        //   toaster(
        //     "success",
        //     res?.data?.message ? res?.data?.message : STATUS_SUCCESS
        //   );
        // } else {
        //   // toaster("error", "Company data is available");
        // }
      })
      .catch((e) => {
        setApiRefresh(false);
        setShowLoader(false);
        toaster("error");
      });
  };

  const selectedIndustries = (value, name) => {
    let event = {
      target: {
        value: "",
        name: "",
      },
    };
    event.target.value = value;
    event.target.name = name;
    const categ = {
      companyId: viewDetails?.companyId,
      industries: value,
    };
    onFormFeildsChange(event, formData, setFormData);
    // const companyId = viewDetails?.companyId;
    // const geographiesOprn = formData?.Location?.valueText;
    setShowLoader(true);
    setApiRefresh(true);
    create(categ)
      .then((res) => {
        setShowLoader(false);
        setApiRefresh(true);
        // if (res?.data?.status == 200) {
        //   toaster(
        //     "success",
        //     res?.data?.message ? res?.data?.message : STATUS_SUCCESS
        //   );
        // } else {
        //   // toaster("error", "Company data is available");
        // }
      })
      .catch((e) => {
        setApiRefresh(false);
        setShowLoader(false);
        toaster("error");
      });
  };

  const selectedValue = (value, name) => {
    let event = {
      target: {
        value: "",
        name: "",
      },
    };
    event.target.value = value;
    event.target.name = name;
    setgeographies(value);

    // const location = {
    //   companyId: viewDetails?.companyId,
    //   // geographiesOprn: geographies,
    //   geographiesOprn: geographies,
    // };

    const categ = {
      companyId: viewDetails?.companyId,
      geographiesOprn: value,
    };
    onFormFeildsChange(event, formData, setFormData);
    setShowLoader(true);
    setApiRefresh(true);
    create(categ)
      .then((res) => {
        setShowLoader(false);
        setApiRefresh(true);
      })
      .catch((e) => {
        setApiRefresh(false);
        setShowLoader(false);
        toaster("error");
      });
  };

  const [compnaypicture, setCompnaypicture] = useState([]);

  const removepotentialImage = (id) => {
    setShowLoader(true);
    setApiRefresh(true);
    deleteImage(id)
      .then((res) => {
        setShowLoader(false);
        if (res?.data.status === STATUS_SUCCESS) {
          toaster(
            "success",
            res?.data?.message ? res?.data?.message : STATUS_SUCCESS
          );
          setApiRefresh(true);
        }
      })
      .catch((e) => {
        setApiRefresh(false);
        setShowLoader(false);
      });
  };

  const getProfilePicture = () => {
    {
      viewDetails?.potentialImagesUrl?.length &&
        viewDetails?.potentialImagesUrl.map((company, index) => {
          if (company !== (undefined || null)) {
            // setCompnaypicture(company);
            downloadPicture(company);
            // candidateDetails?.adminUserResponse?.profilePicDownloadUrl
          }
        });
    }

    // else {
    //   setProfileSrc(null);
    // }
  };
  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        const exitingProfileSrc = { ...profileSrc };
        exitingProfileSrc.push(res);
        setProfileSrc(exitingProfileSrc);
      } else {
        setProfileSrc(null);
      }
    });
  };

  useEffect(() => {
    getProfilePicture();
    downloadPicture();
    getAllMasterData();
  }, []);

  const checkProfileProgressPercentagePreferences = () => {
    if (viewDetails) {
      let totalParameters = 26;
      let percentage = {
        companyName: null,
        companyCode: null,
        companyDesc: null,
        officialEmail: null,
        // currentCompany: null,
        mobileNumber: null,
        // alternateNumber: null,
        streetAddress: null,
        locality: null,
        pinCode: null,
        state: null,
        country: null,
        geographiesOprn: null,
        categories: null,
        industries: null,
        presence: null,
        videoLink: null,
        headcount: null,
        funding: null,
        employeeBenefits: null,
        potentialLink: null,
        potentialDescription: null,
        potentialImagesUrls: null,
        newsLinks: null,
        glassdoorRating: null,
        glassdoorReviews: null,
        glassdoorUrl: null,
        companyLogo: null,
      };

      let errorMessage = "";

      //  percentage
      if (isCheckValue(viewDetails?.companyName)) {
        percentage.companyName = 1;
      } else {
        errorMessage = "Please add name";
      }

      if (isCheckValue(viewDetails?.companyCode)) {
        percentage.companyCode = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add company Code";
        }
      }

      if (isCheckValue(viewDetails?.companyDesc)) {
        percentage.companyDesc = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add  companyDesc";
        }
      }

      if (isCheckValue(viewDetails?.officialEmail)) {
        percentage.officialEmail = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add Email";
        }
      }

      // if (isCheckValue(viewDetails?.currentCompany)) {
      //   percentage.currentCompany = 1;
      // } else {
      //   if (errorMessage === "") {
      //     errorMessage = "Please add Current Company";
      //   }
      // }

      if (isCheckValue(viewDetails?.mobileNumber)) {
        percentage.mobileNumber = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add mobile Number";
        }
      }

      if (isCheckValue(viewDetails?.streetAddress)) {
        percentage.streetAddress = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add Address";
        }
      }

      if (isCheckValue(viewDetails?.locality)) {
        percentage.locality = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add locality";
        }
      }

      if (isCheckValue(viewDetails?.pinCode)) {
        percentage.pinCode = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add pin code";
        }
      }
      if (isCheckValue(viewDetails?.state)) {
        percentage.state = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add state";
        }
      }
      if (isCheckValue(viewDetails?.country)) {
        percentage.country = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add country";
        }
      }

      if (isCheckValue(viewDetails?.industries)) {
        percentage.industries = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add Industries";
        }
      }

      if (isCheckValue(viewDetails?.geographiesOprn)) {
        percentage.geographiesOprn = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add Location";
        }
      }
      if (isCheckValue(viewDetails?.categories)) {
        percentage.categories = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add categories ";
        }
      }
      if (isCheckValue(viewDetails?.presence)) {
        percentage.presence = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add presence link";
        }
      }
      if (isCheckValue(viewDetails?.videoLink)) {
        percentage.videoLink = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add video link";
        }
      }
      if (isCheckValue(viewDetails?.headcount)) {
        percentage.headcount = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add Headcount";
        }
      }
      if (isCheckValue(viewDetails?.funding)) {
        percentage.funding = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add Funding";
        }
      }

      if (isCheckValue(viewDetails?.employeeBenefits)) {
        percentage.employeeBenefits = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add Employee Benefits";
        }
      }
      if (isCheckValue(viewDetails?.potentialLink)) {
        percentage.potentialLink = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add potential Link";
        }
      }

      if (isCheckValue(viewDetails?.potentialDescription)) {
        percentage.potentialDescription = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add potential Description";
        }
      }

      if (isCheckValue(viewDetails?.potentialImagesUrls)) {
        percentage.potentialImagesUrls = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add potential Images";
        }
      }

      if (isCheckValue(viewDetails?.newsLinks)) {
        percentage.newsLinks = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add News Links";
        }
      }

      if (isCheckValue(viewDetails?.glassdoorRating)) {
        percentage.glassdoorRating = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add glassdoor Rating";
        }
      }

      if (isCheckValue(viewDetails?.glassdoorReviews)) {
        percentage.glassdoorReviews = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add glassdoor Reviews";
        }
      }

      if (isCheckValue(viewDetails?.glassdoorUrl)) {
        percentage.glassdoorUrl = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add glassdoor url";
        }
      }
      if (isCheckValue(viewDetails?.companyLogo)) {
        percentage.companyLogo = 1;
      } else {
        if (errorMessage === "") {
          errorMessage = "Please add company  Picture";
        }
      }

      let finalPercent = Math.round(
        ((percentage.companyName +
          percentage.companyCode +
          percentage.companyDesc +
          percentage.officialEmail +
          // percentage.currentCompany +
          percentage.mobileNumber +
          // alternateNumber+,
          percentage.streetAddress +
          percentage.locality +
          percentage.pinCode +
          percentage.state +
          percentage.country +
          percentage.geographiesOprn +
          percentage.categories +
          percentage.industries +
          percentage.presence +
          percentage.videoLink +
          percentage.headcount +
          percentage.funding +
          percentage.employeeBenefits +
          percentage.potentialLink +
          percentage.potentialDescription +
          percentage.potentialImagesUrls +
          percentage.newsLinks +
          percentage.glassdoorRating +
          percentage.glassdoorReviews +
          percentage.glassdoorUrl +
          percentage.companyLogo) /
          totalParameters) *
          100
      );
      // percentage.name +
      //   percentage.phone +
      //   percentage.email +
      //   percentage.currentRole +
      //   percentage.currentCompany +
      //   percentage.status +
      //   percentage.location +
      //   percentage.resume +
      //   percentage.professionalExp +
      //   percentage.education +
      //   percentage.keySkills +
      //   percentage.industries +
      //   percentage.functions +
      //   percentage.whyHireMe +
      //   percentage.links +
      //   percentage.profilePic)

      let finalRemain =
        percentage.companyName +
        percentage.companyCode +
        percentage.companyDesc +
        percentage.officialEmail +
        // percentage.currentCompany +
        percentage.mobileNumber +
        // alternateNumber+,
        percentage.streetAddress +
        percentage.locality +
        percentage.pinCode +
        percentage.state +
        percentage.country +
        percentage.geographiesOprn +
        percentage.categories +
        percentage.industries +
        percentage.presence +
        percentage.videoLink +
        percentage.headcount +
        percentage.funding +
        percentage.employeeBenefits +
        percentage.potentialLink +
        percentage.potentialDescription +
        percentage.potentialImagesUrls +
        percentage.newsLinks +
        percentage.glassdoorRating +
        percentage.glassdoorReviews +
        percentage.glassdoorUrl +
        percentage.companyLogo;
      return {
        percent: finalPercent,
        remain: finalRemain,
        error: errorMessage,
      };
    }
  };

  return (
    <div className="container p-4 ps-lg-5 ps-md-4 ps-3 mt-4 content-margin">
      {showLoader && <Loader />}
      <div className="d-flex justify-content-between">
        <div>
          <h3 className="fs-24 fw-700 color-primary">Company profile</h3>
        </div>
        <div className="color-primary fs-24 d-flex flex-column">
          <span> {checkProfileProgressPercentagePreferences()?.percent}%</span>
          <span className="color-tertiary fs-12">
            Profile Updated
            <ProgressBar
              now={checkProfileProgressPercentagePreferences()?.percent}
              style={{ height: "0.5rem", width: "5.5rem", marginTop: "0.3rem" }}
            />{" "}
          </span>
        </div>
      </div>

      {companyData &&
        companyData.map((company, index) => {
          return (
            <div className="d-flex" key={index}>
              <div className="col-lg-9 col-12 pt-3 pt-lg-0">
                <div className="profile-box-shadow d-flex justify-content-between mb-3">
                  <div className="d-flex gap-1 gap-sm-3">
                    <div className="logo-border-box position-relative">
                      {/* {
                        company?.companyLogo === null && company?.companyLogo === undefined ? (
                          <img src={ProfileImage} width="50px" alt="" />
                        ) : ( */}
                      {/* <img src={company?.companyLogo} width="50px" alt="" />
                        )
                      } */}
                      <img
                        src={`data:image/jpeg;base64 , ${company?.companyLogo}`}
                        width="50px"
                        alt=""
                      />
                      {/* <CompanyImage
                          src={company?.companyLogo}
                          width="50px"
                          initialsContainerClass="sidebar-default-profile-picture"
                        /> */}
                      {/* <ProfileImage
                          src={company?.companyLogo}
                          width="50px"
                          // name={candidateDetails?.userRegistrationDetails?.name}
                          initialsContainerClass="sidebar-default-profile-picture"
                        /> */}
                      <div
                        className="position-absolute edit-logo-small pointer"
                        onClick={() => {
                          setShowCompanyLogo(true);
                          setCompanyId(viewDetails?.companyId);
                          setCompanyLogo(viewDetails?.companyLogo);
                        }}
                      >
                        <img
                          src={icEditSmall}
                          alt="edit-icon"
                          width="8px"
                          className="m-0 p-0"
                        />
                      </div>
                    </div>
                    <div className="color-primary fw-700 fs-20 pt-3">
                      {company?.companyName}
                    </div>
                  </div>

                  <div
                    className="pointer"
                    // onClick={() => {setShowCompanyName(true);
                    //   setViewCompanyDetails(companyData);
                    // }}

                    onClick={() => {
                      setShowCompanyName(true);
                      setCompanyId(viewDetails?.companyId);
                      setCompanyName(viewDetails?.companyName);
                      setCompanyCode(viewDetails?.companyCode);
                    }}
                  >
                    <img
                      src={icEdit}
                      alt="edit-icon"
                      className="edit-btn-padding"
                    />
                  </div>
                </div>
                <div className="profile-box-shadow d-flex justify-content-between mt-4 mb-3">
                  <div className="ps-3 pt-3">
                    <h4>About</h4>
                    <p className="fs-12 color-tertiary text-break">
                      {company?.companyDesc}
                    </p>
                  </div>
                  <div
                    className="pointer"
                    onClick={() => {
                      setShowCompanyAbout(true);
                      setCompanyId(viewDetails?.companyId);
                      setCompanyDesc(viewDetails?.companyDesc);
                      // setCompanyCode(viewDetails?.companyCode);
                    }}
                  >
                    <img
                      src={icEdit}
                      alt="edit-icon"
                      className="edit-btn-padding"
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-3 mt-4 mb-3">
                  <div className="profile-box-shadow d-flex justify-content-between flex-1 ">
                    <div className="p-3 flex-fill">
                      <h4>Company Email</h4>
                      <div className="fs-14 color-tertiary flex-fill header-ellipse-1 company-pf-section-width">
                        {company?.officialEmail}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setShowCompanyEmail(true);
                        setCompanyId(viewDetails?.companyId);
                        setOfficialEmail(viewDetails?.officialEmail);
                      }}
                      className="pointer"
                    >
                      <img
                        src={icEdit}
                        alt="edit-icon"
                        className="edit-btn-padding"
                      />
                    </div>
                  </div>
                  <div className="profile-box-shadow d-flex justify-content-between flex-1">
                    <div className="p-3 flex-fill">
                      <h4>Phone Number</h4>
                      <div className="fs-14 color-tertiary flex-fill header-ellipse-1 company-pf-section-width">
                        {company?.mobileNumber}
                        {/* {company?.alternateNumber} */}
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setShowCompanyNumber(true);
                        setCompanyId(viewDetails?.companyId);
                        setAlternateNumber(viewDetails?.alternateNumber);
                        setMobileNumber(viewDetails?.mobileNumber);
                        setCompanyCode(viewDetails?.companyCode);
                      }}
                      className="pointer"
                    >
                      <img
                        src={icEdit}
                        alt="edit-icon"
                        className="edit-btn-padding"
                      />
                    </div>
                  </div>
                </div>
                <div className="profile-box-shadow d-flex justify-content-between mt-4 mb-3">
                  <div className="ps-3 pt-3">
                    <h4>Corporate Address</h4>
                    <p className="fs-14 color-tertiary">
                      {company?.streetAddress} {company?.locality}{" "}
                      {company?.pinCode} {company?.state} {company?.country}
                    </p>
                  </div>
                  <div
                    onClick={() => {
                      setShowCompanyAddress(true);
                      setCompanyId(viewDetails?.companyId);
                      setStreetAddress(viewDetails?.streetAddress);
                      setLocality(viewDetails?.locality);
                      setPincode(viewDetails?.pinCode);
                      setState(viewDetails?.state);
                      setCountry(viewDetails?.country);
                    }}
                    className="pointer"
                  >
                    <img
                      src={icEdit}
                      alt="edit-icon"
                      className="edit-btn-padding"
                    />
                  </div>
                </div>
                <div className="profile-box-shadow mt-4 mb-3 ">
                  <div className="p-3">
                    <h4>Geographies Operating in</h4>
                    <div>
                      <SearchComboBox
                        name="GeographiesOprn"
                        inputData={locations}
                        defaultValue={geographicOp}
                        isMultiSelect={true}
                        inputCssClass={"modal-input combo-search-box"}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        // onSelect={(event) => handleGeographic(event)}
                        onSelect={($event) => {
                          selectedValue($event, "GeographiesOprn");
                        }}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue"}
                        hideBadges={false}
                        isAllowUserDefined={true}
                      />
                    </div>
                    {/* <div className="d-flex flex-wrap  gap-2">
                      {company?.geographiesOprn &&
                        company?.geographiesOprn.map((output, index) => {
                          return (
                            <div
                              className="blue-badge  d-flex  height-28 gap-3"
                              key={index + 1}
                            >
                              <div>{output} </div>

                              <img
                                src={icCloseBlue}
                                alt="blue-close-icon"
                                className="pointer"
                                height="10px"
                              />
                            </div>
                          );
                        })}
                    </div> */}
                  </div>
                </div>
                <div className="profile-box-shadow mt-4 mb-3 ">
                  <div className="p-3">
                    <h4>Category</h4>
                    <div>
                      <SearchComboBox
                        name="Categories"
                        inputData={functions}
                        defaultValue={categoriesOutput}
                        isMultiSelect={true}
                        inputCssClass={"modal-input combo-search-box"}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        onSelect={($event) => {
                          selectedCategories($event, "Categories");
                        }}
                        // onSelect={(event) => setCategoriesOutput(event)}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue"}
                        isAllowUserDefined={true}
                      />
                    </div>
                    {/* <div className="d-flex flex-wrap  gap-2">
                      {company?.categories &&
                        company?.categories.map((output, index) => {
                          return (
                            <div
                              className="blue-badge  d-flex  height-28 gap-3"
                              key={index + 1}
                            >
                              <div>{output} </div>

                              <img
                                src={icCloseBlue}
                                alt="blue-close-icon"
                                className="pointer"
                                height="10px"
                              />
                            </div>
                          );
                        })}
                    </div> */}
                  </div>
                </div>
                <div className="profile-box-shadow mt-4 mb-3 ">
                  <div className="p-3">
                    <h4>Industries</h4>
                    <div>
                      <SearchComboBox
                        name="Industries"
                        inputData={industries}
                        defaultValue={industriesOutput}
                        isMultiSelect={true}
                        inputCssClass={"modal-input combo-search-box"}
                        wrapperCssClass={"form-group"}
                        placeholder={"Search or Select"}
                        onSelect={($event) => {
                          selectedIndustries($event, "Industries");
                        }}
                        // onSelect={(event) => setIndustriesOutput(event)}
                        searchListHeight={150}
                        badgeThemeCssClass={"blue"}
                        //hideBadges={true}
                        isAllowUserDefined={true}
                      />
                    </div>
                    {/* <div className="d-flex flex-wrap  gap-2">
                      {company?.industries &&
                        company?.industries.map((output, index) => {
                          return (
                            <div
                              className="blue-badge  d-flex  height-28 gap-3"
                              key={index + 1}
                            >
                              <div>{output} </div>

                              <img
                                src={icCloseBlue}
                                alt="blue-close-icon"
                                className="pointer"
                                height="10px"
                              />
                            </div>
                          );
                        })}
                    </div> */}
                  </div>
                </div>
                {/* about employer part */}
                <div className="profile-box-shadow mt-4 mb-3">
                  <div className="d-flex justify-content-between ps-3 ">
                    <h2 className="large-text-black fs-16 pt-3">Presence</h2>
                    <div
                      onClick={() => {
                        setShowCompanyPresence(true);
                        setCompanyId(viewDetails?.companyId);
                        setPrasence(viewDetails?.presence);
                      }}
                      className="pointer"
                    >
                      <img
                        src={icEdit}
                        alt="edit-icon"
                        className="edit-btn-padding"
                      />
                    </div>
                  </div>
                  {/* <div className="d-flex gap-2 ps-3">
                    <img src={globe} alt="globe-icon" />
                    <Link to="/" className="small-text-gray">
                      {" "}
                      {company?.prasence}
                    </Link>
                  </div> */}

                  <div className="d-flex flex-wrap gap-3 p-3">
                    {company?.presence?.WEBSITE && (
                      <a
                        href={`https://${company?.presence?.WEBSITE}`}
                        target="_blank"
                      >
                        <img src={globe} alt="globe-icon" />
                      </a>
                    )}
                    {company?.presence?.VICON && (
                      <a
                        href={`https://${company?.presence?.VICON}`}
                        target="_blank"
                      >
                        <img src={vicon} alt="vicon-icon" />
                      </a>
                    )}
                    {company?.presence?.APPLESTORE && (
                      <a
                        href={`https://${company?.presence?.APPLESTORE}`}
                        target="_blank"
                      >
                        <img src={aicon} alt="aicon-icon" />
                      </a>
                    )}
                    {company?.presence?.PLAYSTORE && (
                      <a
                        href={`https://${company?.presence?.PLAYSTORE}`}
                        target="_blank"
                      >
                        <img src={playstore} alt="playstore-icon" />
                      </a>
                    )}
                    {company?.presence?.TWITTER && (
                      <a
                        href={`https://${company?.presence?.TWITTER}`}
                        target="_blank"
                      >
                        <img src={twitter} alt="twitter-icon" />
                      </a>
                    )}
                    {/* <TwitterShareButton
                      title={"Here's a Job for you"}
                      source={window?.location?.host}
                      url={"${company?.presence?.TWITTER}"}
                    >
                      <TwitterIcon size={26} />
                    </TwitterShareButton> */}
                    {company?.presence?.FACEBOOK && (
                      <a
                        href={`https://${company?.presence?.FACEBOOK}`}
                        target="_blank"
                      >
                        <img src={facebook} alt="facebook-icon" />
                      </a>
                    )}
                    {company?.presence?.YOUTUBE && (
                      <a
                        href={`https://${company?.presence?.YOUTUBE}`}
                        target="_blank"
                      >
                        <img src={youtube} alt="youtube-icon" />
                      </a>
                    )}
                    {/* <YoutubeShareButton
                      title={"Here's a Job for you"}
                      source={window?.location?.host}
                      url={"${company?.presence?.YOUTUBE}"}
                      // url={`${window?.location?.host}/job/${props?.referJobId}?refereeId=${candidateDetails?.userRegistrationDetails?.userId}`}
                    >
                      <Yout size={26} />
                    </YoutubeShareButton> */}
                    {/* <a href={company?.presence?.VICON} target="_blank">
                      <img src={vicon} alt="vicon-icon" />
                    </a> */}

                    {/* <VKShareButton
                      title={"Hello connections, Here's a Job for you"}
                      source={window?.location?.host}
                      url={"${company?.presence?.VICON}"}
                      // {`${window?.location?.host}/job/${props?.referJobId}?refereeId=${candidateDetails?.userRegistrationDetails?.userId}`}
                    >
                      <VKIcon size={26} />
                    </VKShareButton> */}

                    {/* <LinkedinShareButton
                      title={"Hello connections, Here's a Job for you"}
                      source={window?.location?.host}
                      url={"${company?.presence?.LINKEDIN}"}
                      // {`${window?.location?.host}/job/${props?.referJobId}?refereeId=${candidateDetails?.userRegistrationDetails?.userId}`}
                    >
                      <LinkedinIcon size={26} />
                    </LinkedinShareButton> */}
                    {company?.presence?.LINKEDIN && (
                      <a
                        href={`https://${company?.presence?.LINKEDIN}`}
                        target="_blank"
                      >
                        <img src={linkedin} alt="linkedin-icon" />
                      </a>
                    )}
                  </div>
                  {/* );
                      })} */}
                </div>

                {/* company video */}
                <div className="profile-box-shadow mt-4 mb-3">
                  <div className="d-flex justify-content-between ps-3 ">
                    <h2 className="large-text-black fs-16 pt-3">
                      Company Video
                    </h2>
                    <div
                      // onClick={() => setShowCompanyVideo(true)}
                      onClick={() => {
                        setShowCompanyVideo(true);
                        setCompanyId(viewDetails?.companyId);
                        setvideoLink(viewDetails?.videoLink);
                      }}
                      className="pointer"
                    >
                      <img
                        src={icEdit}
                        alt="edit-icon"
                        className="edit-btn-padding"
                      />
                    </div>
                  </div>
                  <div className="ps-3 fs-14 color-tertiary pt-2">
                    Our Philosphy & Culture
                  </div>
                  <div className="d-flex">
                    <div
                      className="ps-3 pt-2 col-12 col-md-6"
                      ref={youtubePlayerContainerRef}
                    >
                      {/* <img src={aboutEmployer} alt="employer-img" width="100%" /> */}
                      <iframe
                        id="ytplayer"
                        type="text/html"
                        width={youtubePlayerWidth}
                        height={youtubePlayerHeight}
                        src={company?.videoLink}
                        frameborder="0"
                        webkitAllowFullScreen
                        mozallowfullscreen
                        allowFullScreen
                      ></iframe>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap gap-3 mt-4 mb-3">
                  <div className="profile-box-shadow d-flex justify-content-between flex-1 ">
                    <div className="p-3">
                      <h4>Headcount</h4>
                      <div className="fs-14 color-tertiary flex-fill header-ellipse-1 company-pf-section-width">
                        {company?.headcount}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setShowCompanyHeadcount(true);
                        setCompanyId(viewDetails?.companyId);
                        setHeadcount(viewDetails?.headcount);
                      }}
                      className="pointer"
                    >
                      <img
                        src={icEdit}
                        alt="edit-icon"
                        className="edit-btn-padding"
                      />
                    </div>
                  </div>
                  <div className="profile-box-shadow d-flex justify-content-between flex-1">
                    <div className="flex-fill p-3">
                      <h4>Funding Status</h4>
                      <div className="fs-14 color-tertiary flex-fill header-ellipse-1 company-pf-section-width">
                        {company?.funding}
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setShowCompanyFunding(true);
                        setCompanyId(viewDetails?.companyId);
                        setFunding(viewDetails?.funding);
                      }}
                      className="pointer"
                    >
                      <img
                        src={icEdit}
                        alt="edit-icon"
                        className="edit-btn-padding"
                      />
                    </div>
                  </div>
                </div>
                <div className="profile-box-shadow mt-4 mb-3">
                  <div className="d-flex justify-content-between ps-3 ">
                    <h2 className="large-text-black fs-16 pt-3">
                      Rating & Reviews (Glassdoor)
                    </h2>
                    <div
                      className="pointer"
                      onClick={() => {
                        setShowCompanyReviews(true);
                        setCompanyId(viewDetails?.companyId);
                        setGlassdoorRating(viewDetails?.glassdoorRating);
                        setGlassdoorReviews(viewDetails?.glassdoorReviews);
                        setGlassdoorUrl(viewDetails?.glassdoorUrl);
                      }}
                    >
                      <img
                        src={icEdit}
                        alt="edit-icon"
                        className="edit-btn-padding"
                      />
                    </div>
                  </div>
                  <div className="color-secondary fs-12 ps-3">
                    {company?.glassdoorReviews?.length > 0 &&
                      company?.glassdoorReviews.map((Reviews, index) => (
                        <div key={index + 1}>{Reviews}</div>
                      ))}
                  </div>
                  <div className="color-tertiary fs-12 ps-3 pt-2 d-flex">
                    <div className="me-1"> Overall</div>
                    <div className="me-1">{company?.glassdoorRating}</div>
                    <div>
                      <img src={icStar} className="mb-1" alt="Rating" />
                    </div>
                  </div>
                  <div className="ps-3 d-flex gap-2 pt-3">
                    <img src={icAttachnment} alt="attachment-icon" />
                    <div style={{ width: "100px" }} className="flex-fill">
                      <a
                        href={company?.glassdoorUrl}
                        className="color-tertiary fs-12 pointer ellipse-1"
                      >
                        {company?.glassdoorUrl}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="profile-box-shadow mt-4 mb-3">
                  <h2 className="large-text-black fs-16 pt-3 ps-3">
                    {""}
                    Employee Benefits {""}
                  </h2>
                  <div className="d-flex gap-2 pt-3 ps-3 pointer">
                    <img
                      src={icPlusIcon}
                      alt="plus-icon"
                      onClick={() => {
                        addFields();
                      }}
                    />
                    <div
                      className="fs-12 color-primary fw-700"
                      onClick={addFields}
                    >
                      Add Another
                    </div>
                  </div>

                  {/* <div className="pt-3 d-flex pb-3 ps-3"> */}
                  {/* <div className="modal-form-input color-secondary"> */}
                  {/* {company?.employeeBenefits
                          ? company?.employeeBenefits
                          : ""} */}
                  {/* </div> */}
                  {/* <img */}
                  {/* src={icDeleteRed} */}
                  {/* alt="delete-icon" */}
                  {/* className="p-2 pointer" */}
                  {/* /> */}
                  {/* </div> */}

                  {allemployeeBenefits?.map((input, index) => {
                    return (
                      
                      <div className="pt-3 d-flex pb-3 ps-3" >
                        <>
                          <input
                            id={index}
                            name="employeeBenefits"
                            type={input.type}
                            key={index}
                            value={
                             input.employeeBenefits
                              //  ?viewDetails?.employeeBenefits[index]
                              // input.employeeBenefits[index]
                            }
                            // defaultValue={viewDetails?.employeeBenefits[index]

                            // }
                            className="modal-form-input input-color-black"
                            placeholder="Write Here"
                            //defaultValue={company?.employeeBenefits[index]}
                            onChange={(event) => {
                              handleEmployeeChange(index, event);
                            }}
                            searchListHeight={150}
                            badgeThemeCssClass={"blue"}
                          />
                          <p className="field-error mt-1">
                            {isError.EmployeeBenefitsError}
                          </p>
                        </>

                        <img
                          src={icDeleteRed}
                          alt="delete-icon"
                          className="p-2 pointer"
                          onClick={() => {
                            // const newarr = allemployeeBenefits.filter((i,j)=>{
                            //   // console.log( index===j,"index newarra i j")
                            //  const arr= index !==j
                            // console.log( index,j,"index newarra")

                            //   // setAllEmployeeBenefits(arr)   

                            //   return index !==j
                            // })
                           
                           removeInputFields(input)
                          }}
                          
                        />
                      </div>
                      
                    );
                  })}

                  <button
                    className="btn-dialog-cancel ms-3 pointer"
                    onClick={() => {
                      AddBenifits();
                    }}
                  >
                    Save
                  </button>
                </div>
                {/* potential images */}
                <div className="profile-box-shadow mt-4 mb-3">
                  <div className="ps-3 pt-3">
                    <div className="d-flex justify-content-between ">
                      <span className="large-text-black fs-16 pt-3">
                        Potential Colleagues
                      </span>
                      <div
                        className="pointer"
                        onClick={() => {
                          setShowCompanyPotentialCollegues(true);
                          setCompanyId(viewDetails?.companyId);
                          setPotentialDescription(
                            viewDetails?.potentialDescription
                          );
                          setPotentialLink(viewDetails?.potentialLink);
                        }}
                      >
                        <img
                          src={icEdit}
                          alt="edit-icon"
                          className="edit-btn-padding"
                        />
                      </div>
                    </div>
                    <a
                      href={company?.potentialLink}
                      target="_blank"
                      className="text-black-medium pt-3 text-break"
                    >
                      {company?.potentialLink}
                    </a>
                    <p className="large-text-gray fs-12 pt-3 text-break">
                      {company?.potentialDescription}
                    </p>
                    <hr></hr>
                    <div className="row m-1">
                      <div className="col-xl-3 col-md-4 col-sm-6 col-12">
                        <div className="img-dotted-border mb-3 ">
                          <div
                            className="d-flex flex-column justify-content-center align-items-center h-100 pointer"
                            onClick={() => {
                              setShowCompanyImages(true);
                              setCompanyId(viewDetails?.companyId);
                              setPotentialImages(
                                viewDetails?.potentialImagesUrl
                              );
                            }}
                          >
                            <img src={icPlusIcon} alt="plus-icon" />
                            <div className="color-primary fs-14 fw-600">
                              Add Images
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* {images} */}
                      {/* Object.values( */}
                      {viewDetails?.potentialImagesUrls?.length > 0 &&
                        viewDetails?.potentialImagesUrls?.map(
                          (images, index) => {
                            return (
                              <div className="col-xl-3 col-md-4 col-sm-6 col-12 ">
                                <div className="potential-image-wrapper mb-3 ">
                                  <div
                                    className="w-100 position-relative"
                                    key={index}
                                  >
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_BASEURL +
                                        images.imageUrl
                                      }
                                      // src={
                                      //   process.env.REACT_APP_IMAGE_BASEURL +
                                      //   images
                                      // }
                                      // src={require(`${images[index]}`)}
                                      alt="plus-icon"
                                      width="130px"
                                      height="80px"
                                      key={index + 1}
                                    />
                                    <img
                                      src={icDeleteRed}
                                      className="position-absolute bottom-0 end-0 bg-white p-1 rounded-1 me-1 mb-1 pointer"
                                      alt="delete-icon"
                                      onClick={() =>
                                        removepotentialImage(images.id)
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                      {/* <div className="col-xl-3 col-md-4 col-sm-6 col-12 ">
                        <div className="potential-image-wrapper mb-3">
                          <div className="w-100 position-relative">
                            <img
                              src={icDeleteRed}
                              className="position-absolute bottom-0 end-0 bg-white p-1 rounded-1 me-1 mb-1"
                              alt="delete-icon"
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* news part */}
                <div className="profile-box-shadow mt-4 mb-3">
                  <div className="p-3 pt-0">
                    <div className="color-primary fw-600 fs-16 pt-3">
                      {" "}
                      In the news
                    </div>
                    <div className="pt-3 d-flex pb-3">
                      <input
                        type="text"
                        className="modal-form-input small-text-medium-gray"
                        placeholder="Paste link to an article"
                        // defaultValue={newsLinks}
                        value={newsLinks}
                        // onChange={(event) => handleFormChange(index, event)}
                        onChange={(e) => {
                          setNewsLinks(e.target.value);
                        }}
                      />
                      {/* {company?.newsLinks} */}
                      <button
                        className="btn-dialog-cancel ms-3 pointer"
                        onClick={() => {
                          companyDetails();
                        }}
                      >
                        Add
                      </button>
                    </div>
                    <hr></hr>

                    {news?.length > 0 &&
                      news?.map((data, index) => {
                        return (
                          <div
                            className="d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            {/* {news?.data ==="" ?( */}
                            <div className="flex-fill ps-0 ps-sm-3 d-flex gap-2 gap-sm-3 align-items-center">
                              <img
                                src={icAttachnment}
                                alt="attachment-icon"
                                width="17px"
                              />
                              <div
                                style={{ width: "100px" }}
                                className="flex-fill"
                              >
                                <a
                                  href={data}
                                  className="color-tertiary fs-12 pointer ellipse-1"
                                >
                                  {data}
                                </a>
                              </div>
                            </div>
                            {/* ):(
                              ""
                            )
                      } */}
                            {/* {news?.data !=="" ?( */}
                            <div className="ps-2 pb-3 pointer">
                              <img
                                src={icDeleteRed}
                                alt="delete-red"
                                width="14px"
                                onClick={() => {
                                  removeNewsFields(index);
                                }}
                              />
                            </div>
                            {/* ):(
""
                              )} */}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      <CompanyLogo
        show={showCompanyLogo}
        setCompanyLogo={setCompanyLogo}
        companyId={companyId}
        companyLogo={companyLogo}
        onLogoClose={() => setShowCompanyLogo(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyName
        show={showCompanyName}
        showCompany={setCompanyName}
        companyName={companyName}
        companyId={companyId}
        companyCode={companyCode}
        onNameClose={() => setShowCompanyName(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyEmail
        show={showCompanyEmail}
        setOfficialEmail={setOfficialEmail}
        officialEmail={officialEmail}
        companyId={companyId}
        emailData={emailData}
        setEmailData={setEmailData}
        onEmailClose={() => setShowCompanyEmail(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyNumber
        show={showCompanyNumber}
        setAlternateNumber={setAlternateNumber}
        companyId={companyId}
        alternateNumber={alternateNumber}
        setMobileNumber={setMobileNumber}
        mobileNumber={mobileNumber}
        onNumberClose={() => setShowCompanyNumber(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyAddress
        show={showCompanyAddress}
        setStreetAddress={setStreetAddress}
        companyId={companyId}
        streetAddress={streetAddress}
        locality={locality}
        pinCode={pinCode}
        state={state}
        country={country}
        onAddressClose={() => setShowCompanyAddress(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyVideo
        show={showCompanyVideo}
        companyId={companyId}
        videoLink={videoLink}
        onVideoClose={() => setShowCompanyVideo(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyHeadcount
        show={showCompanyHeadcount}
        companyId={companyId}
        headcount={headcount}
        onHeadcountClose={() => setShowCompanyHeadcount(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyFunding
        show={showCompanyFunding}
        // setFunding={setFunding}
        companyId={companyId}
        funding={Funding}
        onFundingClose={() => setShowCompanyFunding(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyPotentialCollegues
        show={showCompanyPotentialCollegues}
        setPotentialLink={setPotentialLink}
        setPotentialDescription={setPotentialDescription}
        companyId={companyId}
        potentialDescription={potentialDescription}
        potentialLink={potentialLink}
        onPotentialColleguesClose={() =>
          setShowCompanyPotentialCollegues(false)
        }
        setApiRefresh={setApiRefresh}
      />
      <CompanyPresence
        show={showCompanyPresence}
        // setPrasence={setPrasence}
        companyId={companyId}
        presence={presence}
        onPresenceClose={() => setShowCompanyPresence(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyAbout
        show={showCompanyAbout}
        // showAbout={setCompanyDesc}
        companyId={companyId}
        companyDesc={companyDesc}
        onAboutClose={() => setShowCompanyAbout(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyImages
        show={showCompanyImages}
        companyId={companyId}
        potentialImages={potentialImages}
        onImagesClose={() => setShowCompanyImages(false)}
        setApiRefresh={setApiRefresh}
      />
      <CompanyReviews
        show={showCompanyReviews}
        companyId={companyId}
        glassdoorRating={glassdoorRating}
        glassdoorReviews={glassdoorReviews}
        glassdoorUrl={glassdoorUrl}
        onReviewsClose={() => setShowCompanyReviews(false)}
        setApiRefresh={setApiRefresh}
      />
    </div>
  );
};

export default CompanyProfilePage;
