import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import ProgressBarGraph from "../../../components/graph/progress_bar_graph";
import threeDotsIcon from "../../../assests/icons/three-dots-icon.svg";
import useWindowDimensions from "../../../utils/use_window_dimension";
import rightArrow from "../../../assests/right-arrow.svg";
import AirbnbLogo from "../../../assests/images/airbnblogo.png";
import CompanyImage from "../../../components/company_image";
import Loader from "../../../components/common/loader";
import { useNavigate } from "react-router";
import {
  ALL_ORGS_SCREEN_ROUTE,
  ORG_PAGE_ROUTE,
} from "../../../config/page_routes_constants";
import {
  allOrgsPinOrUnpin,
  pinnedOrgs,
} from "../../../_services/serikoradmin_view.service";
import toaster from "../../../utils/toaster";

const CompanyCardComponent = () => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  // const [barData, setBarData] = useState([
  //   { entity: "Applied", entityValue: "584", color: "#1C1C1C" },
  //   { entity: "Shortlisted", entityValue: "312", color: "#3D81FF" },
  //   { entity: "Assessment", entityValue: "258", color: "#FFD027" },
  //   { entity: "Offered", entityValue: "95", color: "#12BD6B" },
  //   { entity: "Joined", entityValue: "68", color: "#2FFF9B" },
  //   { entity: "Dropout", entityValue: "26", color: "#EB1260" },
  //   { entity: "Rejected", entityValue: "200", color: "#721212" },
  // ]);

  const barData = [
    { entity: "Applied", entityValue: 584, color: "#1C1C1C" },
    { entity: "Shortlisted", entityValue: 312, color: "#3D81FF" },
    { entity: "Assessment", entityValue: 258, color: "#FFD027" },
    { entity: "Offered", entityValue: 95, color: "#12BD6B" },
    { entity: "Joined", entityValue: 68, color: "#2FFF9B" },
    { entity: "Dropout", entityValue: 26, color: "#EB1260" },
    { entity: "Rejected", entityValue: 200, color: "#721212" },
  ];

  console.log("CardBarData", barData);

  const [pinnedTeams, setPinnedTeams] = useState([
    {
      companyName: "Air Bnb india long name used for testing turncate",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      companyName: "Amazon",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      companyName: "Microsoft",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      companyName: "Advent technologies",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      companyName: "Tec Level 90+",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
  ]);

  const [pinOrgs, setPinOrgs] = useState([]);

  const overviewPinnedOrgs = (isPin) => {
    setShowLoader(true);
    pinnedOrgs(1, 10, isPin)
      .then((res) => {
        setPinOrgs(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };

  const overviewUnpinOrgs = (isPin, orgId) => {
    allOrgsPinOrUnpin(isPin, orgId).then((res) => {
      // setPinOrgs(res?.data?.data)
      overviewPinnedOrgs(true);
    });
  };

  useEffect(() => {
    overviewPinnedOrgs(true);
  }, []);

  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    // console.log("winWidth", winWidth);
    if (winWidth >= 1500 || winWidth >= 1300) {
      return 895 + "px";
    } else if (winWidth < 1300 && winWidth > 991) {
      return winWidth - 410 + "px";
    } else if (winWidth <= 991 || winWidth < 550) {
      return winWidth - 70 + "px";
    }
  };
  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  return (
    <div>
      {showLoader && <Loader />}
      {
        <div className="mt-3 mb-4">
          {/* Title row */}
          <div className="d-flex justify-content-between flex-wrap gap- referee-btn-focus ">
            <div className="fw-700 fs-20 color-primary referee-btn-focus">
              Pinned Organizations
            </div>
            <div
              className="fs-12 fw-700 color-primary button-border pointer referee-btn-focus"
              onClick={() => navigate(ALL_ORGS_SCREEN_ROUTE)}
            >
              View all Orgs{" "}
              <img src={rightArrow} alt="" className="ms-1 referee-btn-focus" />
            </div>
          </div>
          {/* Title row end*/}
          {/* Cards row */}
          <div
            className="horizontal-scroll-area mt-2 referee-btn-focus"
            style={{
              width: getHorizotalScrollWidth(hrSrlDiamension.width),
            }}
          >
            <div
              className="mt-2 d-flex justify-content-between"
              style={{
                width:
                  pinOrgs &&
                  pinOrgs.length > 0 &&
                  (pinOrgs.length + 1) * 177 + "px",
              }}
            >
              {pinOrgs?.map((team) => (
                //Card
                <div
                  className="border rounded p-3 me-3 "
                  style={{ width: "190px", float: "left" }}
                >
                  {/* Card title */}
                  <div className="d-flex justify-content-between fs-14 fw-600">
                    <div className="color-primary mb-1">
                      <div className="d-flex">
                        <div className="rounded border">
                          {/* <CompanyImage
                            // src={team?.orgLogo}
                            src={`data:image/jpeg;base64 , ${team?.orgLogo}`}
                            width="32px"
                            height="32px"
                            initialsContainerClass="initialsStyle2-xl"
                          /> */}
                          <img
                            src={`data:image/jpeg;base64 , ${team?.orgLogo}`}
                            width="32px"
                            height="32px"
                            style={{ borderRadius: "50px" }}
                          />
                        </div>
                      </div>

                      <div className="pt-2 ellipse">{team?.orgName}</div>
                    </div>
                    <div>
                      {/* <img src={threeDotsIcon} className="pointer" alt=":" /> */}
                      <Dropdown className="job-template-dropdown">
                        <Dropdown.Toggle
                          variant="none"
                          id="dropdown-basic"
                          className="d-flex p-0 m-0 border-0 bg-transparent job-template-icon referee-btn-focus"
                        >
                          <img src={threeDotsIcon} alt="retract-icon" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                          <Dropdown.Item
                            href="#/action-1"
                            className="d-flex pb-2 pt-2"
                            onClick={() => {
                              overviewUnpinOrgs(false, team?.id);
                            }}
                          >
                            <div className="d-flex gap-2 referee-btn-focus">
                              <span className="">Unpin Org</span>
                            </div>
                          </Dropdown.Item>
                          <hr className="m-0 p-0" />
                          <Dropdown.Item
                            href="#/action-2"
                            className="d-flex pb-2 pt-2"
                          >
                            <div
                              onClick={() =>
                                navigate(ORG_PAGE_ROUTE + `/${team?.id}`)
                              }
                              className="d-flex gap-2 referee-btn-focus"
                            >
                              <span className="">View Org</span>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  {/* Card title end*/}

                  {/* Card graph */}
                  <div className="mt-2 mb-2">
                    <ProgressBarGraph
                      // seikorAdminBarData={barData}
                      seikorAdminBarData={[
                        {
                          entity: "Applied",
                          entityValue:
                            team?.statusWiseData?.hasOwnProperty("APPLIED") &&
                            team?.statusWiseData?.APPLIED !== ""
                              ? team?.statusWiseData?.APPLIED
                              : 0,
                          color: "#1C1C1C",
                        },
                        {
                          entity: "Shortlisted",
                          entityValue:
                            team?.statusWiseData?.hasOwnProperty(
                              "SHORTLISTED"
                            ) && team?.statusWiseData?.SHORTLISTED !== ""
                              ? team?.statusWiseData?.SHORTLISTED
                              : 0,
                          color: "#3D81FF",
                        },
                        {
                          entity: "Assessment",
                          entityValue:
                            team?.statusWiseData?.hasOwnProperty(
                              "ASSESSMENT"
                            ) && team?.statusWiseData?.ASSESSMENT !== ""
                              ? team?.statusWiseData?.ASSESSMENT
                              : 0,
                          color: "#FFD027",
                        },
                        {
                          entity: "Offered",
                          entityValue:
                            team?.statusWiseData?.hasOwnProperty("OFFERED") &&
                            team?.statusWiseData?.OFFERED !== ""
                              ? team?.statusWiseData?.OFFERED
                              : 0,
                          color: "#12BD6B",
                        },
                        {
                          entity: "Joined",
                          entityValue:
                            team?.statusWiseData?.hasOwnProperty("JOINED") &&
                            team?.statusWiseData?.JOINED !== ""
                              ? team?.statusWiseData?.JOINED
                              : 0,
                          color: "#2FFF9B",
                        },
                        {
                          entity: "Dropout",
                          entityValue:
                            team?.statusWiseData?.hasOwnProperty("RETRACTED") &&
                            team?.statusWiseData?.RETRACTED !== ""
                              ? team?.statusWiseData?.RETRACTED
                              : 0,
                          color: "#EB1260",
                        },
                        {
                          entity: "Rejected",
                          entityValue:
                            team?.statusWiseData?.hasOwnProperty("REJECTED") &&
                            team?.statusWiseData?.REJECTED !== ""
                              ? team?.statusWiseData?.REJECTED
                              : 0,
                          color: "#721212",
                        },
                      ]}
                      height={"7"}
                    />
                  </div>

                  {/* Card graph end */}

                  {/* Card stats */}
                  <div className="mt-2 fs-11 fw-300 color-tertiary">
                    <div className="d-flex justify-content-between">
                      <div>Open Roles</div>
                      <div className="fs-12 fw-700 color-secondary">
                        {team.openRole}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div>Applied</div>
                      <div className="fs-12 fw-700 color-secondary">
                        {team.applications}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mt-2">
                      <div>
                        {/* Referrals ({team.referralsPercentage}) */}
                        Referrals (
                        {team?.referrals == 0 && team?.applications == 0
                          ? 0
                          : `${Math.round(
                              (team?.referrals / team?.applications) * 100
                            )}%`}
                        )
                      </div>
                      <div className="fs-12 fw-700 color-secondary">
                        {team.referrals}
                      </div>
                    </div>
                  </div>
                  {/* Card stats end */}
                </div>
                // Card end
              ))}
            </div>
          </div>
          {/* Cards row end*/}
        </div>
      }
    </div>
  );
};

export default CompanyCardComponent;
