import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { isEmpty } from "../../utils/form_validators";
import checkedLogo from "../../assests/ic_selected16.svg";
import { deleteTeam } from "../../_services/view.service";
import toaster from "../../utils/toaster";
import { useNavigate, useParams } from "react-router";

const DeleteTeamDialog = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [teamDelete, setTeamDelete] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const onDeleteTeam = () => {
    setShowLoader(true);
    deleteTeam(id)
      .then((res) => {
        props.close();

        setTeamDelete(res?.data?.data);
        toaster("success", "Team deleted successfully");
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };

  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        onHide={() => props.close()}
        centered
        backdrop="static"
        keyboard={false}
        className=""
        contentClassName="modal-content-delete"
      >
        <Modal.Header className=" border-0" bsPrefix="modal-header-custom">
          <div className="fs-24 color-primary fw-700 px-2 pt-2">
            Delete Team
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            onClick={() => props.close()}
            className="pointer mt-2 me-2"
            height="20px"
            width="20px"
          />
        </Modal.Header>
        <Modal.Body>
          <p className="color-tertiary fs-14 fw-400">
            Are you sure you want to delete this Team?
          </p>
        </Modal.Body>
        <Modal.Footer className="dialog-footer border-0">
          <button
            className="btn-dialog-save bg-body pointer text-dark fs-12 fw-700  border border-dark"
            onClick={() => props.close()}
          >
            Cancel
          </button>
          <button
            className="btn-dialog-save pointer fs-12 fw-700 yes-delete border-0"
            onClick={() => {
              onDeleteTeam(id);
              navigate(-1)
            }}
          >
            Yes Delete
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteTeamDialog;
