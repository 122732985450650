import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import checkedLogo from "../../../assests/ic_selected16.svg";
import { isEmpty } from "../../../utils/form_validators";
import Vectorleft from "../../../assests/Vectorleft.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import icDelete from "../../../assests/Vectordelete.svg";
import AddImageLogo from "../../../assests/icons/ic-add-filled.svg";
import { Table } from "react-bootstrap";
import { TEAMS_DETAIL_PAGE_ROUTE } from "../../../config/page_routes_constants";
import DeleteTeamDialog from "../../../dialogs/teams_and_members/delete_team_dialog";
import RemoveMemberDialog from "../../../dialogs/teams_and_members/remove_member_dialog";
import AddMemberToTeamDialog from "../../../dialogs/teams_and_members/add_member_dialog";
import {
  addForTeamMembers,
  getLoggedInUserMembers,
  getTeamMembers,
} from "../../../_services/view.service";
import toaster from "../../../utils/toaster";
import { viewDetails } from "../../../_services/view.service";

const TeamDetailPage = () => {
  const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState(false);
  const [showRemoveMemberDialog, setShowRemoveMemberDialog] = useState(false);
  const [showSelectedMemberId, setSelectedMemberId] = useState();
  const [showAddMemberToTeam, setShowAddMemberToTeam] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [teamDelete, setTeamDelete] = useState();
  const [teamDetails, setTeamDetails] = useState([]);
  const [addmembers, setaddMembers] = useState([]);
  const [apiRefresh, setApiRefresh] = useState(false);
  const [email, setEmail] = useState("");
  const [nameTeam, setNameTeam] = useState();

  const { id } = useParams();
  const [memberUserId, setMemberUserId] = useState();
  console.log("param ID--->", id);
  console.log("showSelectedMemberId--->", showSelectedMemberId);

  const [manageTeam, setManageTeam] = useState([]);

  const teamMembers = (id) => {
    setShowLoader(true);
    // console.log(id, "<--this is team id");
    let pageNo = 1;
    let pageSize = 10;
    getTeamMembers(id, pageNo, pageSize)
      .then((res) => {
        setManageTeam(res?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };
  console.log("MANGE TEAM-->", manageTeam);

  // teamMembers(id);

  useEffect(() => {
    teamMembers(id);
  }, [showRemoveMemberDialog]);

  let data = [
    // {
    //   id: 1,
    //   members: "Abhishek Singh",
    //   isDeleted: false,
    // },
    // {
    //   id: 2,
    //   members: "Bikramjit Dey",
    //   isDeleted: false,
    // },
    // {
    //   id: 3,
    //   members: "Deepak Dhiman",
    //   isDeleted: true,
    // },
    // {
    //   id: 4,
    //   members: "Garima Walia",
    //   isDeleted: false,
    // },
  ];

  const getteamdetails = (id) => {
    console.log("id", Number(id));
    viewDetails(id).then((res) => {
      setTeamDetails(res?.data?.data);
      toaster("success", "Fetching team member list successfully!!");
    });
  };

  useEffect(() => {
    getteamdetails(id);
  }, []);
  console.log("TEAMDETAIL-->", teamDetails);

  const getmembers = (id) => {
    setShowLoader(true);
    // console.log(id, "<--this is team id");
    let pageNo = 1;
    let pageSize = 10;
    addForTeamMembers(id, pageNo, pageSize)
      .then((res) => {
        setaddMembers(res?.data?.data);
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
    console.log("addForTeamMembers-->", addmembers);
  };

  // teamMembers(id);

  useEffect(() => {
    getmembers(id);
  }, []);
  return (
    <div className="container">
      <div className=" content-margin mt-4">
        <Link
          to={-1}
          className="backTitle d-flex align-items-center border-bottom pb-2"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img src={Vectorleft} alt="" className="pointer" height="12px" />
          <p className="fs-12 fw-400 m-0 ps-2 "> Go Back</p>
        </Link>

        <div className="d-flex justify-content-between flex-column flex-sm-row cards-graph-container">
          {/* {teamDetails?.length > 0 &&
            teamDetails?.map((output, index) => {
              return (
                <div className="d-flex flex-column my-4" key={index}>
                  <div className="fs-20 fw-700" style={{ color: "#262626" }}>
                    {output?.name}
                  </div>
                  <div className=" fs-16 fw-400 color-tertiary">5 Members</div>
                </div>
              );
            })} */}
          <div className="d-flex flex-column my-4">
            <div className="fs-20 fw-700" style={{ color: "#262626" }}>
              {teamDetails?.name}
            </div>
            <div className=" fs-16 fw-400 color-tertiary">
              {manageTeam?.totalRecord} Members
            </div>
          </div>
          <div className="d-flex flex-column flex-sm-row gap-1 gap-sm-3 me-2 ">
            <div
              onClick={() => setShowDeleteMemberDialog(true)}
              className="fs-12 my-2 my-sm-4 fw-700 border-danger radius8 border  d-flex pointer  w-fit h-fit d-flex p-2 align-items-center"
            >
              <img
                src={icDelete}
                alt=""
                className="ms-1 "
                height="13px"
                width="13px"
              />
              <h6
                className="fs-12 fw-700 color-red ps-2 p-0 m-0"
                style={{ minWidth: "100px" }}
              >
                Delete Team
              </h6>
            </div>
            <div className="new-job-template">
              <button
                style={{ minWidth: "150px" }}
                onClick={() => setShowAddMemberToTeam(true)}
                className="fs-12 my-2 my-sm-4 fw-700 bg-body radius8 border  d-flex pointer  w-fit h-fit d-flex p-2 align-items-center"
              >
                <img alt="" src={AddImageLogo} height="13px" width="13px" />
                <span className="ps-2">Add Member</span>
              </button>
            </div>
          </div>
        </div>

        <div className="table-responsive d-none d-md-block">
          <Table className=" justify-content-between border-0">
            <thead className="w-100 border-bottom">
              <tr>
                <th className="gap-2 text-center col">
                  <div className="fs-12 fw-700 text-start ps-4">Member</div>
                </th>
              </tr>
            </thead>
            <tbody className="border-0">
              {manageTeam?.data?.length > 0
                ? manageTeam?.data?.map((output, index) => {
                    return (
                      <>
                        <tr
                          key={index}
                          className="card-parent fs-14 position-relative border-bottom border-top my-2"
                        >
                          {output.isDeleted ? (
                            <td className="text-start position-relative justify-content-between">
                              <div
                                className="d-flex  align-items-center"
                                style={{ minWidth: "250px" }}
                              >
                                {output?.profileId === null ||
                                output?.profileId === undefined ? (
                                  <img
                                    src={profileImg}
                                    alt="profile"
                                    className="rounded-circle"
                                    width="32px"
                                    height="32px"
                                  />
                                ) : (
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_BASEURL +
                                      output?.profileId
                                    }
                                    alt="profile"
                                    className="rounded-circle"
                                    width="32px"
                                    height="32px"
                                  />
                                )}
                                <div className="mx-2 py-3 align-middle text-start">
                                  <div className="fs-14 fw-600 color-tertiary">
                                    {output?.email}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{ right: "10px" }}
                                className="onhover-show "
                              >
                                <div className="link ms-2 align-items-center">
                                  <Link
                                    to={
                                      TEAMS_DETAIL_PAGE_ROUTE + `/${output.id}`
                                    }
                                    className="btn fs-12 fw-700 dark-pink-text"
                                    style={{ color: "#EB1260" }}
                                  >
                                    Remove from team
                                  </Link>
                                </div>
                              </div>
                            </td>
                          ) : (
                            <td className="text-start position-relative justify-content-between">
                              <div
                                className="d-flex align-items-center"
                                style={{ minWidth: "250px" }}
                              >
                                {output?.profileId === null ||
                                output?.profileId === "" ? (
                                  <img
                                    src={profileImg}
                                    alt="profile"
                                    className="rounded-circle"
                                    width="32px"
                                    height="32px"
                                  />
                                ) : (
                                  <img
                                    src={
                                      process.env.REACT_APP_IMAGE_BASEURL +
                                      output?.profileId
                                    }
                                    alt="profile"
                                    className="rounded-circle"
                                    width="32px"
                                    height="32px"
                                  />
                                )}
                                <div className="mx-2 py-3 align-middle text-start">
                                  <div className="fs-14 fw-600">
                                    {output?.email}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{ right: "10px" }}
                                className="onhover-show "
                              >
                                <div className="link ms-2 align-items-center">
                                  <button
                                    className="bg-transparent border-0 text-decoration-none fs-12 fw-700 dark-pink-text"
                                    style={{ color: "#EB1260" }}
                                    onClick={() => {
                                      setShowRemoveMemberDialog(true);
                                      setSelectedMemberId(output?.memberUserId);
                                      // setMemberUserId(output?.email)
                                      setEmail(output.email);
                                      setNameTeam(teamDetails.name);
                                    }}
                                  >
                                    Remove from team
                                  </button>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      </>
                    );
                  })
                : "No Data Found"}
            </tbody>
          </Table>
        </div>

        <div className="d-md-none mt-4 ">
          {manageTeam?.data?.length > 0
            ? manageTeam?.data?.map((output, index) => {
                return (
                  <div
                    key={index}
                    style={{ minWidth: "350px" }}
                    className="small-referee-cards d-flex row justify-content-center align-items-center mt-3 py-2 mx-1"
                  >
                    <div className="d-flex justify-content-between mt-2">
                      <div className="fw-700 fs-12">Member</div>
                      <div className="fs-14 fw-600">{output?.email}</div>
                    </div>
                  </div>
                );
              })
            : "No Data Available"}
        </div>

        {/* Dialogs Section*/}
        <DeleteTeamDialog
          show={showDeleteMemberDialog}
          teamDelete={teamDelete}
          close={() => setShowDeleteMemberDialog(false)}
        />
        <RemoveMemberDialog
          show={showRemoveMemberDialog}
          teamId={id}
          memberId={showSelectedMemberId}
          close={() => setShowRemoveMemberDialog(false)}
          getTeamMembers={() => teamMembers(id, 1, 10)}
          setApiRefresh={setApiRefresh}
          email={email}
          nameTeam={nameTeam}
        />
        <AddMemberToTeamDialog
          show={showAddMemberToTeam}
          addmembers={addmembers}
          close={() => setShowAddMemberToTeam(false)}
          teamMembers={() => teamMembers(id)}
        />
      </div>
    </div>
  );
};

export default TeamDetailPage;
