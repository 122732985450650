import { useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router";
import { createSearchParams } from "react-router-dom";
import Header from "../../components/common/header";
import { TOKEN } from "../../config/localstorage_keys";
import { getLocalStorage } from "../../utils/storage";

const AuthGuard = () => {
  let token = getLocalStorage(TOKEN);
  const [windowUrl, setWindowUrl] = useState(window?.location?.href);
   const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      return;
    } else {
      navigate({
        pathname: "login",
        // search: `${windowUrl}`,
        search: createSearchParams({
          redirectTo: `${windowUrl}`,
        })?.toString(),
      });
    }
  }, [token]);
  return token ? (
    
    <div className="d-flex flex-column align-items-stretch flex-grow-1">
      <div className="">
        {/* {isLoading && <Loader />} */}
        <Header />
      </div>
      <div className="flex-grow-1 body-container">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={windowUrl} />
  );
};

export default AuthGuard;
