import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import closeIcon from "../../assests/icons/ic-invite-pg-close.svg";
import { STATUS_SUCCESS } from "../../config/localstorage_keys";
import { saveAdminMember } from "../../_services/serikoradmin_view.service";

function SeikorAdminAddSuccessDialog(props) {
  // const[userid,setUserid]=useState();
  // const[role,setRole]=useState();
  const [email, setEmail] = useState();
  // const [showLoader, setShowLoader] = useState(false);
  // const[allOrgsAccess,setAllOrgsAccess]=useState(false);
  // const [listOrgs,setListOrgs]=useState([]);




  useEffect(() => {
    setEmail(props?.alldata?.name);
  }, []);
//   const onSave = () => {
//     setShowLoader(true);
//     saveAdminMember(email,role,allOrgsAccess,[...listOrgs])
//     .then((res) => {
//       setShowLoader(false);
//       props?.setApiRefresh(true);
      
//       if (res?.data?.status == STATUS_SUCCESS) {
//         props?.setAdminManageModalShow(false);
//         // toaster(
//         //   "success",
//         //   res?.data?.message ? res?.data?.message : STATUS_SUCCESS
//         // );
//       }
//     })
//     .catch((err) => {
//       setShowLoader(false);
//       props?.setApiRefresh(false);
//       //toaster("error", err);
//     });
// };

// useEffect(() =>{
//   onSave();
// },[]);
  return (
    <Modal
      show={props?.adminAddSuccess}
      onHide={() => {
        props?.setAdminAddSuccess(false);
      }}
      className="p-0"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="dialog-header border-0">
        <Modal.Title className="dialog-title pt-3 ps-3 fw-700 fs-20">
          Add Member
        </Modal.Title>
        <img
          className="pointer"
          onClick={() => {
            props?.setAdminAddSuccess(false);
          }}
          style={{ widht: "30px" }}
          src={closeIcon}
        />
      </Modal.Header>
      <Modal.Body
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        className=" py-3 m-0 "
      >
        <div className="ps-3">
          <div className="small-text-gray fs-14 fw-400">
            "{props?.showemail} can now log in using password Seikor123, then set up a new password"
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="p-3 mt-3">
        <Button
          onClick={() => {props?.setAdminAddSuccess(false);

            props?.setApiRefresh(true);
          }}
          className="btn btn-light border border-dark btn-save px-5 fw-700 fs-12"
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default SeikorAdminAddSuccessDialog;
