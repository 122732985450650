import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import addIcon from "../../../assests/icons/ic-add-black-fill.svg";
import filterIcon from "../../../assests/icons/ic_sort.svg";
import funnelFilterIcon from "../../../assests/icons/ic-filter.svg";
import pinUnfilled from "../../../assests/icons/ic_pin.svg";
import pinFilled from "../../../assests/icons/ic_pin_filled.svg";
import searchIcon from "../../../assests/icons/ic-search-16.svg";
import downArrow from "../../../assests/downArrow.svg";
import blackdone from "../../../assests/icons/ic_blackdone.svg";
import companyLogo from "../../../assests/icons/ic_tropoGo.svg";
import "../admin_all_orgs/styles.scss";
import AdminAllOrgsFilterDialog from "../../../dialogs/admin_all_orgs_dialogs/admin_all_orgs_filter_dialog";
import AdminAllOrgsSortDialog from "../../../dialogs/admin_all_orgs_dialogs/admin_all_orgs_sort_dialog";
import { checkedItemsHandler } from "../../../utils/utils";
import useWindowDimensions from "../../../utils/use_window_dimension";
import AdminAddOrgDialog from "../../../dialogs/admin_all_orgs_dialogs/admin_add_org_dialog";
import { Link, useSearchParams } from "react-router-dom";
import { ORG_PAGE_ROUTE } from "../../../config/page_routes_constants";
import { allOrganization } from "../../../_services/company.service";
import toaster from "../../../utils/toaster";
import { getLocalStorage, setLocalStorage } from "../../../utils/storage";
import {
  LOG_TO_ORG,
  ORG_ID,
  TOKEN,
  USER_ID,
} from "../../../config/localstorage_keys";
import { useStoreActions } from "easy-peasy";
import { allOrgsPinOrUnpin } from "../../../_services/serikoradmin_view.service";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "../../../utils/form_validators";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import Loader from "../../../components/common/loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { GENERAL_ERROR_MESSAGE } from "../../../config/messages";

function AdminAllOrgs() {
  let isInfinite = false;
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const [arraySortIndex, setArraySortIndex] = useState(0);
  const [arrayAllOrgsIndex, setArrayAllOrgsIndex] = useState([]);
  const [sortTableShow, setSortTableShow] = useState(false);
  const [filterOrgsShow, setFilterOrgsShow] = useState(false);
  const [addOrgModalShow, setAddOrgModalShow] = useState(false);
  const [allOrg, setAllOrg] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [apireferesh, setApiRefresh] = useState(false);
  const userId = getLocalStorage(USER_ID);
  const role = localStorage.getItem("role");
  const navigate = useNavigate();
  const [currentPageset, setCurrentPageSet] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  const [allJobMessage, setAllJobMessage] = useState("");
  const [tempJobFilter, setTempJobFilter] = useState({});

  const [
    analyticsSortByDropdownRedDot,
    setAnalyticsSortByDropdownRedDot,
  ] = useState("d-none");

  const [
    analyticsFiltersDropdownRedDot,
    setAnalyticsFiltersDropdownRedDot,
  ] = useState("d-none");

  useEffect(() => {
    if (arraySortIndex == null) {
      setAnalyticsSortByDropdownRedDot("d-none");
    } else {
      setAnalyticsSortByDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  useEffect(() => {
if (arraySortIndex == null){
  setAnalyticsFiltersDropdownRedDot("d-none");
  
} else {
  setAnalyticsSortByDropdownRedDot("d-block");
}
  },[arraySortIndex])



  const [allOrgsDataParams, setAllOrgsDataParams] = useSearchParams({
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [allOrgsData, setAllOrgsData] = useState(initialFilterData);
  const [filterArray, setFilterArray] = useState([]);

  const fetchAllOrg = (data) => {
    setShowLoader(true);
    allOrganization(data).then(
      (res) => {
        if (res?.status === 200) {
          if (res && !isEmpty(res?.data?.data)) {
            setDataCount(res?.data?.totalRecord);

            let data;
            if (!isInfinite) {
              data = res?.data?.data;
            } else {
              data = [...allOrg, ...res?.data?.data];
            }
            setCurrentPageSet(data?.length);
            setAllOrg(data);

            setShowLoader(false);
          } else if (isEmpty(res) && res?.status === 200) {
            setShowLoader(false);
          } else {
            setShowLoader(false);
          }
        }
      },
      (error) => {
        toaster("error", GENERAL_ERROR_MESSAGE);
        setShowLoader(false);
      }
    ).catch(
      setShowLoader(false)
    );
  };

  const fetchMoreOrgs = () => {
    setCurrentPageSet(currentPageset + 10);
    isInfinite = true;
    fetchAllOrg({
      ...allOrgsData,
      pageNo: allOrgsData?.pageNo + 1,
    });
    setAllOrgsData({
      ...allOrgsData,
      pageNo: allOrgsData?.pageNo + 1,
    });
  };

  useEffect(() => {
    fetchAllOrg(allOrgsData);
  }, [arrayAllOrgsIndex]);

  const openToNewTab = (orgId,orgLogo) => {
    setLocalStorage(ORG_ID, orgId);
    setLocalStorage("orgLogo",orgLogo);
    localStorage.setItem("temp_role", role);
    localStorage.setItem("role", "BIZ_ADMIN");
    localStorage.setItem("log_to_org", "true");

    navigate("/");
   
  };
  

  useEffect(() => {
    if (apireferesh === true) {
      fetchAllOrg(allOrgsData);
      setApiRefresh(false);
    }
  }, [addOrgModalShow, apireferesh]);

  const pinOrUnpin = (isPin, orgId) => {
    setShowLoader(true);
    
    allOrgsPinOrUnpin(isPin, orgId)
      .then((res) => {
        if (isPin === true) {
          toaster("success", res?.data?.message);
          setApiRefresh(true);
        } else {
          toaster("success", "Unpinned organization Successfully");
        }
        fetchAllOrg(allOrgsData);
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
        toaster("error", err);
      });
  };

  const sortingArray = [
    { name: "New Applications First", value: "NEW_APPLICATION_FIRST" },
    { name: "New Referrals First", value: "NEW_REFERRALS_FIRST" },
    { name: "Pending Screening", value: "PENDING_SCREENING" },
    { name: "Open Roles", value: "OPEN_ROLES" },
    { name: "Application Count", value: "APPLICATION_COUNT" },
    { name: "Referral Count", value: "REFERRAL_COUNT" },
    { name: "Dropped Count", value: "DROPPED_COUNT" },
    { name: "Referral Bonus", value: "REFERRAL_BONUS" },
    { name: "A-Z", value: "A_Z" },
  ];

  const dropdownSortHandler = (index, value) => {
    setArraySortIndex(index);
    setAllOrgsData({
      ...allOrgsData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    fetchAllOrg({
      ...allOrgsData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const submitFormOnEnter = (e) => {
    console.log("search", e);
    if (e?.keyCode == 13) {
      setAllOrgsDataParams(allOrgsData);
      fetchAllOrg(allOrgsData);
    }
  };

  const dropdownAllOrgsArray = [
    { name: "All Orgs", value: "ALL_ORG" },
    { name: "Have open roles", value: "OPEN_ROLE" },
    { name: "Have new applications", value: "NEW_APPLICATION" },
  ];

  const dropdownFilterHandler = (condition,index ,value) => {
    checkedItemsHandler(index, arrayAllOrgsIndex, setArrayAllOrgsIndex);
    setFilterArray([...filterArray, value]);
   
    // setFilterArray([...filterArray, value]);
    // console.log(filterArray,"array if");
 
  
    // // let index1 = filterArray.indexOf(value);
    //   if (index !==1) {
       
    //     setFilterArray([
    //       ...filterArray.splice(0, index),
    //       ...filterArray.splice(index + 1),
    //     ]);
    //   }


      if (condition) {
        setFilterArray([...filterArray, value]);
       
      } 
      else {
        let index = filterArray.indexOf(value);
        if (index >= 0) {
          setFilterArray([
            ...filterArray.splice(0, index),
            ...filterArray.splice(index + 1),
          ]);
          
         
        }
        
      }
  };

  const checkFilterHandler = (condition, value) => {
    if (condition) {
      setFilterArray([...filterArray, value]);
      console.log("filterArray ",filterArray);
     
    } 
    else {
      let index = filterArray.indexOf(value);
      if (index >= 0) {
        setFilterArray([
          ...filterArray.splice(0, index),
          ...filterArray.splice(index + 1),
        ]);
        
       
      }
      
    }
  };
console.log("filter ",filterArray);

  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    // console.log("winWidth", winWidth);
    if (winWidth > 1500) {
      return 955 + "px";
    } else if (winWidth > 1300 && winWidth > 1499) {
      return winWidth - 420 + "px";
    } else if (winWidth < 1300 && winWidth > 991) {
      return winWidth - 420 + "px";
    } else if (winWidth < 990 && winWidth >= 768) {
      return winWidth - 70 + "px";
    } else if (winWidth < 767 && winWidth > 551) {
      return winWidth - 150 + "px";
    } else if (winWidth < 550) {
      return winWidth - 110 + "px";
    }
  };

  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin">
        <div className=" d-sm-flex justify-content-between mt-4 ">
          <div className="fw-700 fs-24 fs-sm-16">
            All Organizations ({dataCount})
          </div>
          <div>
            <button
              onClick={() => setAddOrgModalShow(true)}
              className="mt-3 mt-sm-0 btn border-dark btn-light color-dark add-org-btn d-flex justify-content-around fw-700 fs-14 align-items-center referee-btn-focus"
            >
              <img src={addIcon} />
              Add New Org
            </button>
          </div>
        </div>
        {/* Filter / Search Container */}
        <div className="filter-search-container  d-sm-flex gap-4  justify-content-between mt-4 align-items-center align-content-center">
          <div className="d-flex gap-3 mt-1">
            <Dropdown className="timeline-select-dropdown" autoClose="outside">
              <Dropdown.Toggle
                variant="none"
                // id="dropdown-basic"
                className="btn down-arrow referee-filter-button d-flex py-1 px-2 bg-transparent align-items-center d-flex justify-content-around h-28 referee-btn-focus"
              >
                <img src={funnelFilterIcon} alt="" className="" />
                <span className="fs-12 fw-400">Filters</span>
                <span className={`${analyticsFiltersDropdownRedDot} round-red-dot rounded-circle mb-2`}
                ></span>
                <img className="" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary all-orgs-box-shadow">
                {dropdownAllOrgsArray.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      // onClick={() => {
                      //   dropdownFilterHandler(index);
                      // }}
                      // onClick={(e) => {
                      //   checkFilterHandler(
                      //     e?.target?.checked,
                      //     sortItem.value
                      //   );
                      // }}
                    >
                      <div
                        className="d-flex py-2 align-items-center"
                        style={{ zIndex: 1000 }}
                        onClick={(e) => {
                          dropdownFilterHandler( e?.target?.checked, index, sortItem.value);
                          // checkFilterHandler(
                          // //   e?.target?.checked,
                          // //   sortItem.value
                          // // );
                        }}
                      >
                        <input
                          type="checkbox"
                          className="me-2"
                          id={index}
                          name={index}
                          checked={
                            arrayAllOrgsIndex.includes(index) ? true : false
                          }
                          onChange={(e) => {
                            checkFilterHandler(
                              e?.target?.checked,
                              sortItem.value
                            );
                          }}
                          
                        />{" "}
                        <label>{sortItem.name}</label>
                      </div>
                    </Dropdown.Item>
                  );
                })}

                {/* <div clasname="w-100 d-flex">
                    <button
                      style={{ width: "100px", marginLeft: "14px" }}
                      className="btn rounded fs-14 fw-700 all-orgs-red-btn me-1 mt-3"
                      onClick={() => setArrayAllOrgsIndex([])}
                    >
                      <span className="fs-12 fw-700">Clear</span>
                    </button>
                    <button
                      style={{ width: "100px" }}
                      className="btn rounded fs-14 fw-700 border border-dark ms-1 me-1 mt-3"
                    >
                      <span className="fs-12 fw-700">Apply</span>
                    </button>
                  </div> */}
                <div className=" d-flex justify-content-around w-100 mt-2">
                  <div style={{ width: "40%" }}>
                    <button
                      className="btn rounded fs-14 fw-700 all-orgs-red-btn w-100"
                      onClick={() => setArrayAllOrgsIndex([])}
                    >
                      <span className="fs-12 fw-700">Clear</span>
                    </button>
                  </div>
                  <div style={{ width: "40%" }}>
                    <button className="btn rounded fs-14 fw-700 border border-dark w-100">
                      <span
                        className="fs-12 fw-700"
                        onClick={() => {
                          fetchAllOrg({
                            ...allOrgsData,
                            organizationFilter: filterArray,
                            pageNo: 1,
                            pageSize: DEFAULT_PAGE_SIZE,
                          });
                        }}
                      >
                        Apply
                      </span>
                    </button>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown className="timeline-select-dropdown">
              <Dropdown.Toggle
                // tabIndex="0"
                variant="none"
                // id="dropdown-basic"
                className="btn down-arrow referee-filter-button d-flex py-1 px-2 bg-transparent align-items-center d-flex justify-content-around h-28 referee-btn-focus"
              >
                <img src={filterIcon} alt="" className="" />
                <span className="fs-12 fw-400">Sort By</span>
                <span
                      className={`${analyticsSortByDropdownRedDot} round-red-dot rounded-circle mb-2`}
                    ></span>
                <img className="" src={downArrow} alt="" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary text-black">
                {sortingArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex py-2 align-items-center"
                      onClick={() => {
                        if (arraySortIndex == index) {
                          dropdownSortHandler(null);
                        } else {
                          dropdownSortHandler(index, sortItem.value);
                        }
                      }}
                    >
                      <img
                        src={blackdone}
                        alt="blackdone-icon"
                        className={
                          arraySortIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          arraySortIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="page-search mt-4 mt-sm-0  col-md-5 col-lg-4 ">
            <input
              className="col-12 h-32 referee-btn-focus"
              type="text"
              placeholder="Search"
              defaultValue={allOrgsData?.searchString}
              onChange={(event) => {
                setAllOrgsData({
                  ...allOrgsData,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
            />
          </div>
        </div>
        {/* Table */}
        <div className="d-none d-md-block mb-5 ">
          <div
            style={{
              width: getHorizotalScrollWidth(hrSrlDiamension.width),
            }}
            className="table-responsive mt-4"
          >
            <InfiniteScroll
              dataLength={currentPageset}
              next={() => fetchMoreOrgs()}
              hasMore={dataCount > currentPageset}
              className="container-fluid"
            >
              <table className="table ">
                <thead className="">
                  <tr>
                    <th scope="col">
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <div className="fs-12 fw-700">Org Name</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex flex-column align-items-center">
                        <div className="w-100 fs-12 fw-700 text-left ">
                          Open
                        </div>
                        <div className="w-100 text-left fs-12 fw-700">
                          Roles
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex flex-column align-items-center">
                        <div className="w-100 fs-12 fw-700 text-left ">
                          Roles{" "}
                        </div>
                        <div className="w-100 text-left fs-12 fw-700">
                          Filled
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="fs-12 fw-700">Applications</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="fs-12 fw-700">Referrals</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex flex-column align-items-center">
                        <div className="w-100 fs-12 fw-700 text-left ">
                          Pending{" "}
                        </div>
                        <div className="w-100 text-left fs-12 fw-700">
                          Screening
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="fs-12 fw-700">Dropped</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="fs-12 fw-700">Members</div>
                      </div>
                    </th>
                    <th scope="col">
                      <div className="d-flex align-items-center">
                        <div className="fs-12 fw-700">Bonus</div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {allOrg && allOrg?.length > 0
                    ? allOrg.map((item, index) => {
                        return (
                          <>
                            <tr
                              key={index}
                              className="card-parent fs-14 position-relative"
                            >
                              <td className="py-3">
                                <div className="d-flex gap-2 align-items-center">
                                  <div>
                                    <img
                                      src={`data:image/jpeg;base64 , ${item?.orgLogo}`}
                                      alt="profile-image"
                                      className="rounded-circle"
                                      width="32px"
                                      height="32px"
                                    />
                                  </div>
                                  <div
                                    className="fs-14 fw-500"
                                    style={{ color: "#262626" }}
                                  >
                                    {item?.orgName}
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle fs-12 fw-500 py-3">
                                <div>{item?.openRole}</div>
                              </td>
                              <td className="align-middle fs-12 fw-500 py-3">
                                <div>{item?.roleField}</div>
                              </td>
                              <td className="align-middle py-3">
                                <div className="d-flex">
                                  <div className="fs-14 fw-500 color-light-gray">
                                    {" "}
                                    {item?.applications}
                                  </div>
                                  {item?.applicationNew !== 0 && item?.applicationNew !== null && (
                                  <div className="all-orgs-red-color ms-2 fs-12 fw-600 d-flex align-items-center">
                                    {item?.applicationNew} New
                                  </div>
                                  )}
                                </div>
                              </td>
                              <td className="align-middle py-3">
                                <div className="d-flex">
                                  <div className="fs-12 fw-500">
                                    {" "}
                                    {item?.referrals}
                                  </div>
                                  <div className="all-orgs-red-color ms-2 fs-12 fw-600 d-flex align-items-center">
                                    {item?.newReferrals}
                                  </div>
                                </div>
                              </td>
                              <td className="align-middle py-3">
                                <div className="d-flex">
                                  <div className="fs-12 fw-500">
                                    {" "}
                                    {item?.pendingScreening}
                                  </div>
                                </div>
                              </td>
                              {/* <td className="align-middle fs-12 fw-500 ">
                              <div> {item?.pendingScreening}</div>
                            </td> */}
                              {/* <td className="align-middle fs-12 fw-500 py-3">
                                <div> {item?.rolesPosted}</div>
                              </td> */}
                              <td className="align-middle fs-12 fw-500">
                                <div> {item?.dropped}</div>
                              </td>
                              <td className="align-middle fs-12 fw-500 py-3">
                                <div> {item?.members}</div>
                              </td>
                              <td className="align-middle fs-12 fw-500 py-3 position-relative">
                                <div> {item?.bonus}</div>
                                <div className="onhover-show3">
                                  <div className="d-flex align-items-center ">
                                    <div
                                      style={{ marginRight: "10px" }}
                                      className="pin-bg-all-orgs d-flex justify-content-center align-items-center pointer referee-btn-focus"
                                      onClick={() => {
                                        item?.isPinned
                                          ? pinOrUnpin(false, item?.id)
                                          : pinOrUnpin(true, item?.id);
                                      }}
                                    >
                                      <img
                                        style={{
                                          height: "14px",
                                          width: "10px",
                                        }}
                                        src={
                                          item?.isPinned
                                            ? pinFilled
                                            : pinUnfilled
                                        }

                                        // use "pinFilled" in src for filled icon
                                      />
                                    </div>
                                    <div className="link me-2">
                                      <Link
                                        to={`/${ORG_PAGE_ROUTE}/${item?.id}`}
                                        // to={ORG_PAGE_ROUTE+ `/${item?.id}`}

                                        className="text-decoration-none btn btn-outline-dark fs-12 fw-700 text-dark bg-light org-hover-btn referee-btn-focus"
                                      >
                                        View Details
                                      </Link>
                                    </div>
                                    <div className="link">
                                      <button
                                        className="text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark org-hover-btn referee-btn-focus"
                                        onClick={() => openToNewTab(item.id,item.orgLogo)}
                                      >
                                        Login to Org
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : "No Data Found"}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        </div>
        {/* Table end */}

        {/* Small Table start */}
        <div className="d-block d-md-none px-3 content-margin mt-3 ">
          {allOrg && allOrg?.length > 0
            ? allOrg?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="card-parent-bg-none d-flex row justify-content-center align-items-center pt-2 pb-3 border-bottom position-relative"
                  >
                    <div className="py-3 px-0">
                      <span>
                        <img
                          src={`data:image/jpeg;base64 , ${item?.orgLogo}`}
                          alt="profile-image"
                          className="rounded-circle"
                          width="32px"
                          height="32px"
                        />
                      </span>
                      <span className="ms-4 fs-14 fw-500">{item?.orgName}</span>
                    </div>
                    {/* <div className="d-flex justify-content-between pb-2  px-0">
                      <div className="fw-400 fs-12">Pending Screening</div>
                      <div className="fs-12 fw-500">{item?.pendingScreening}</div>
                    </div> */}
                    <div className="d-flex justify-content-between pb-2  px-0">
                      <div className="fw-400 fs-12">Open Roles</div>
                      <div className="fs-12 fw-500">{item?.openRole}</div>
                    </div>
                    <div className="d-flex justify-content-between pb-2  px-0">
                      <div className="fw-400 fs-12">Roles Filled</div>
                      <div className="fs-12 fw-500">{item?.roleField}</div>
                    </div>
                    <div className="d-flex justify-content-between  px-0">
                      <div className="fw-400 fs-12">Members</div>
                      <div className="fs-12 fw-500">{item?.members}</div>
                    </div>
                    <div
                      className="mt-3 align-items-end mx-0 px-0 all-org-card-pos"
                      // style={{ bottom: "15px" }}
                    >
                      <div className="gy-3 w-100 ">
                        <div className="onhover-show2 pb-2 row gy-3 hover-bg">
                          <div className="d-flex justify-content-between">
                            <div
                              style={{ marginRight: "10px" }}
                              className="pin-bg-all-orgs d-flex justify-content-center align-items-center pointer col-3"
                              onClick={() => {
                                item?.isPinned
                                  ? pinOrUnpin(false, item?.id)
                                  : pinOrUnpin(true, item?.id);
                              }}
                            >
                              <img
                                style={{
                                  height: "14px",
                                  width: "10px",
                                }}
                                src={item?.isPinned ? pinFilled : pinUnfilled}

                                // use "pinFilled" in src for filled icon
                              />
                            </div>
                            <Link
                              to={`/${ORG_PAGE_ROUTE}/${item?.id}`}
                              className="me-2 flex-1 btn all-org-btn-outline-dark text-decoration-none fs-12 fw-700 text-dark"
                            >
                              View Details
                            </Link>
                          </div>
                          <div className="row-12 link">
                            {/* <Link
                              onClick={() => openToNewTab(item.id ,item.orgLogo)}
                              className="   w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark col-12"
                            >
                              Login to Org
                            </Link> */}

                            <button
                                        className="   w-100 text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark col-12"
                                        onClick={() => openToNewTab(item.id,item.orgLogo)}
                                      >
                                        Login to Org
                                      </button>
                          </div>
                          
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : null}
        </div>
        {/* Small Table end */}

        {/* Mobile bottom sort */}

        {/* <div className="position-fixed bottom-0 d-block d-md-none referee-fixed-sort-div d-flex justify-content-center w-100 ">
        <div
          style={{ marginRight: "130px" }}
          className="d-flex w-50 justify-content-between"
        >
          <div
            // style={{ marginRight: "120px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="d-flex p-2" onClick={() => setFilterOrgsShow(true)}>
              <div>
                <img alt="sort-icon" src={funnelFilterIcon} />
              </div>
              <div className="color-primary fs-12 ms-1 d-flex align-items-center">
                Filter
              </div>
            </div>
          </div>
          <div
            // style={{ marginLeft: "50px" }}
            className="d-flex justify-content-center align-items-center"
          >
            <div className="d-flex p-2" onClick={() => setSortTableShow(true)}>
              <div>
                <img alt="sort-icon" src={filterIcon} />
              </div>
              <div className="color-primary fs-12 ms-1 d-flex align-items-center">
                Sort By
              </div>
            </div>
          </div>
        </div>
      </div> */}

        {/* Dialogs */}

        <AdminAllOrgsFilterDialog
          show={filterOrgsShow}
          onSortClose={() => setFilterOrgsShow(false)}
          sortArray={dropdownAllOrgsArray}
        />
        <AdminAllOrgsSortDialog
          show={sortTableShow}
          onSortClose={() => setSortTableShow(false)}
          sortArray={sortingArray}
        />
        <AdminAddOrgDialog
          addOrgModalShow={addOrgModalShow}
          setAddOrgModalShow={setAddOrgModalShow}
          closeModal={() => setAddOrgModalShow(false)}
          setApiRefresh={setApiRefresh}
        />
      </div>
    </div>
  );
}

export default AdminAllOrgs;
