import React from "react";
import icShock from "../../../../assests/icons/ic_shock.svg";

const JobCardSuperpowers = ({jobViewDetails}) => {
  const superpowers = ["Bug Basher", "Team Leader", "Critical Thinking"];
  return (
    <div>
      <div className="mt-4">
        <div className="d-flex gap-2 align-items-center">
          <div>
            <img src={icShock} alt="shock-icon" className="pe-2" />
          </div>
          <div className="fs-14 fw-600 color-primary">Your Superpowers</div>
        </div>

        {jobViewDetails.jobPower && jobViewDetails.jobPower?.length > 0 && (
          <div className="d-flex flex-wrap mt-2 gap-2">
            {jobViewDetails.jobPower && jobViewDetails.jobPower.map((superpower, index) => (
              <span className="gray-color-badge" key={index}>
                {superpower}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCardSuperpowers;
