import React from "react";
import NoDataFoundCard from "../../../components/common/no_data_found_card";
import { NO_EXPERIENCE_ADDED } from "../../../config/messages";

const ApplicationExperience = ({ applicationDetails }) => {
  const professionalExperienceDetails = [
    {
      startDate: "Jun 2020",
      endDate: "Present",
      jobRole: "UX Designer II",
      companyName: "Glowhomes Technologies",
      jobLocation: "New Delhi",
      jobType: "Full Time",
      jobFunction: "Design",
      jobSummary:
        "Worked on design explorations and execution for e-commerce flatform for construction industry. Worked on design explorations and execution for e-commerce flatform for construction industry",
    },
    {
      startDate: "Apr 2018",
      endDate: "Mar 2020",
      jobRole: "UX Designer",
      companyName: "Microsoft",
      jobLocation: "Gurgaon",
      jobType: "Full Time",
      jobFunction: "Design",
      jobSummary:
        "Worked on design explorations and execution for e-commerce flatform for construction industry. Worked on design explorations and execution for e-commerce flatform for construction industry",
    },
  ];

  return (
    <div className="mt-4">
      <div className="title-card my-4 fw-bold fs-16">
        Professional Experience
      </div>
      {(applicationDetails?.viewProfileResponse?.workExperienceResponse !==
      undefined
        ? applicationDetails?.viewProfileResponse?.workExperienceResponse
        : applicationDetails?.workExperienceResponse
      )?.length !== 0 ? (
        <div className="card-parent-container mb-5">
          {(applicationDetails?.viewProfileResponse?.workExperienceResponse
            ? applicationDetails?.viewProfileResponse?.workExperienceResponse
            : applicationDetails?.workExperienceResponse
          )?.map((edu) => {
            return (
              <>
                <div className="card-container p-3">
                  <div className="pt-3 ps-2">
                    <div>
                      <span className="gray-color-badge">
                        {edu?.startDate} - {edu?.endDate}
                      </span>
                    </div>
                    <div className="title-card py-3">{edu?.jobRole}</div>
                    <div className="card-subtitle">
                      {`${edu?.companyName} |
                      ${edu?.jobLocation} |
                      ${edu?.jobType} |
                      ${edu?.jobFunction}`}
                    </div>
                    <div className="card-content">{edu?.jobSummary}</div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <NoDataFoundCard text={NO_EXPERIENCE_ADDED} />
      )}
    </div>
  );
};

export default ApplicationExperience;
