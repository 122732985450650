import React, { useEffect } from "react";
import { useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import icThreeDots from "../../../assests/icons/ic_retract.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import Loader from "../../../components/common/loader";
import { STATUS_SUCCESS, USER_ROLE } from "../../../config/be_api_constants";
import { DEFAULT_PAGE_SIZE } from "../../../config/config";
import { GENERAL_ERROR_MESSAGE, JOB_FETCH_FAILED } from "../../../config/messages";
import { isEmpty } from "../../../utils/form_validators";
import { getLocalStorage } from "../../../utils/storage";
import toaster from "../../../utils/toaster";
import {
  newRequestsDeleteFeedback,
  newRequestsGetFeedback,
} from "../../../_services/serikoradmin_view.service";
const NewRequestPage = () => {
  let isInfinite = false;

  const role = getLocalStorage("role");
  const [showLoader, setShowLoader] = useState(false);
  const [newReqFeedback, setNewReqFeedback] = useState([]);
  const [allJobMessage, setAllJobMessage] = useState("");
  const [apiRefresh, setapiRefresh] = useState(false);



  let data = [
    {
      id: 1,
      name: "Ayushi Nagpal",
      number: "+91 8800 278211",
      email: "hradmin@microsoft.com",
      organization: "Microsoft",
      dateRecieved: "21 Aug 2022",
    },
    {
      id: 2,
      name: "Manogma Sai",
      number: "+91 8800 278211",
      email: "manogmasai@amazon.com",
      organization: "Microsoft",
      dateRecieved: "21 Aug 2022",
    },
    {
      id: 3,
      name: "Harsh Benewal",
      number: "+91 8800 278211",
      email: "harsh@swiggy.com",
      organization: "Microsoft",
      dateRecieved: "21 Aug 2022",
    },
  ];
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    // searchString: null,
    // sortBy: null,
  };

  const [dataCountRequest, setDataCountRequest] = useState(0);
  const [currentRequestPageset, setCurrentRequestPageset] = useState(0);
  const [allReqFilterData, setAllReqFilterData] = useState(initialFilterData);


  const getFeedback = (data) => {
    if (role === USER_ROLE.ADMIN || USER_ROLE.SUPER_ADMIN) {
      setShowLoader(true);
      newRequestsGetFeedback(data)?.then((res) => {
        console.log("temp", res?.data);

        if (res?.data?.status === STATUS_SUCCESS) {
          setShowLoader(false);
          setNewReqFeedback(res?.data?.data);
          setDataCountRequest(res?.data?.totalRecord);

          toaster("success", res?.data?.message);
          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...newReqFeedback, ...res?.data?.data];
          }
          setCurrentRequestPageset(data?.length);
          setNewReqFeedback(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          setShowLoader(false);
        }
      })
        .catch((error) => {
          toaster(
            "error",
            error?.message ? error?.message : GENERAL_ERROR_MESSAGE
          );
          setShowLoader(false);
        });
    }
  };

  const fetchMoreRequest = () => {
    // if (jobList.length < dataCount) {
    setCurrentRequestPageset(currentRequestPageset + 10);
    //   setPage_No(page_No + 1);
    // }
    isInfinite = true;

    getFeedback({
      ...allReqFilterData,
      pageNo: allReqFilterData?.pageNo + 1,
    });
    setAllReqFilterData({
      ...allReqFilterData,
      pageNo: allReqFilterData?.pageNo + 1,
    });
  };


  useEffect(() => {
    getFeedback(allReqFilterData);
  }, [apiRefresh]);

  useEffect(() => {
    if (apiRefresh === true) {

      setapiRefresh(false);
    }
  }, [apiRefresh]);



  const deleteFeedback = (id) => {
    if (role === USER_ROLE.ADMIN || USER_ROLE.SUPER_ADMIN) {
      setShowLoader(true);
      newRequestsDeleteFeedback(id)?.then((res) => {
        if (res?.data?.status === STATUS_SUCCESS) {
          setShowLoader(false);
          toaster("success", res?.data?.message);
          getFeedback(allReqFilterData);
        }
      });
      getFeedback(allReqFilterData);
    }
    setShowLoader(false);
  };


  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin mt-4">
        <div className="fs-24 fw-700 mb-3">Feedbacks</div>
        <InfiniteScroll
          dataLength={currentRequestPageset}
          next={() => fetchMoreRequest()}
          hasMore={dataCountRequest > currentRequestPageset}
          className="container-fluid"
        >
          <div className="d-none d-md-block">
            <Table className="fs-12 col-12">
              <thead className="">
                <tr>
                  <th>
                    <div className="py-2 fs-12 fw-700 ">Name</div>
                  </th>
                  <th>
                    <div className="py-2 fs-12 fw-700 ">Email</div>
                  </th>
                  <th>
                    <div className="py-2 fs-12 fw-700 ">Organization</div>
                  </th>
                  <th>
                    <div className="py-2 fs-12 fw-700 ">Message</div>
                  </th>
                  <th>
                    <div className="py-2 fs-12 fw-700 ">Date Received</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {newReqFeedback?.map((item, index) => {
                  return (
                    <tr key="index" className="align-items-center card-parent">
                      <td className="align-middle py-3">
                        <div className="color-primary fs-14 fw-400">
                          {item?.name}{" "}
                        </div>
                      </td>
                      <td className="color-primary align-middle py-3">
                        <div className=" fs-14 fw-400">{item?.mobile} </div>
                        <div className=" fs-14 fw-400">{item?.email} </div>
                      </td>
                      <td className="color-secondary align-middle py-3">
                        <div className=" fs-14 fw-400">
                          {item?.organizationName === "" ||
                            item?.organizationName === null
                            ? "N/A"
                            : item?.organizationName}
                        </div>
                      </td>

                      <td className="color-primary align-middle py-3">
                        <div className="color-primary fs-14 fw-400">
                          {item?.text}{" "}
                        </div>
                      </td>


                      <td className="color-secondary align-middle">
                        <div className="d-flex justify-content-between ">
                          <div className=" fs-14 fw-400  pt-1">
                            {item?.dateReceived}
                          </div>

                          <Dropdown className="down-btn-remove" align="end">
                            <Dropdown.Toggle
                              variant="none"
                              id="dropdown-basic"
                              className=" p-0 m-0 border-0 bg-transparent "
                            >
                              <img src={icThreeDots} alt="retract-icon" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="fs-12 text-secondary  border-dark ">
                              {/* <Dropdown.Item className="py-1 pe-5">
                              <div className="color-secondary pe-5">
                                Mark as joined
                              </div>
                            </Dropdown.Item>
                            <Dropdown.Divider className="" /> */}
                              <Dropdown.Item className="py-1 pe-5"
                                onClick={() => {
                                  deleteFeedback(item?.id);
                                }}>
                                <div className="dark-pink-text pe-5">
                                  Delete Request
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </InfiniteScroll>

        <div className="d-sm-block d-md-none " style={{ marginBottom: "3rem" }}>
          <div className=" d-flex flex-wrap justify-content-between gap-2 mt-4 col-12">
            {newReqFeedback?.length > 0
              ? newReqFeedback?.map((item, key) => {
                return (
                  <div className=" col-12 col-md-5 border-bottom pb-2">
                    <div className="card-body card-body-new-request">
                      <div className="d-flex justify-content-between mt-2 ">
                        <p className="fs-14 fw-400 m-0 py-1">{item?.name}</p>
                        <div className="d-flex justify-content-end">
                          <Dropdown className="down-btn-remove" align="end">
                            <Dropdown.Toggle
                              variant="none"
                              id="dropdown-basic"
                              className=" p-0 m-0 border-0 bg-transparent "
                            >
                              <img src={icThreeDots} alt="retract-icon" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="fs-12 text-secondary  border-dark ">
                              {/* <Dropdown.Item className="py-1 pe-5">
                                <div className="color-secondary pe-5">
                                  Mark as joined
                                </div>
                              </Dropdown.Item> */}
                              {/* <Dropdown.Divider className="" /> */}
                              <Dropdown.Item
                                className="py-1 pe-5"
                                onClick={() => {
                                  deleteFeedback(item?.id);
                                }}>
                                <div className="dark-pink-text pe-5">
                                  Delete Request
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                      <div className="">
                        <p className="fs-14 fw-400  py-1 m-0">
                          {item?.mobile}{" "}
                        </p>
                      </div>
                      <div className="">
                        <p className="fs-14 fw-400  py-1 m-0">
                          {item?.email}
                        </p>
                      </div>

                      {/* <div className="">
                          <p className="fs-14 fw-400  py-1 m-0">
                            {item?.organization}{" "}
                          </p>
                        </div> */}
                      <div className="">
                        <p className="fs-14 fw-400  py-1 m-0">
                          {item?.dateRecieved}{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })
              : "No Data Found"}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewRequestPage;
