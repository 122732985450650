import React from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";

const AllReviews = (props) => {
  const reviews = [
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "21 Aug 2022",
    },
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "22 Aug 2022",
    },
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "25 Aug 2022",
    },
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "21 Aug 2022",
    },
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "22 Aug 2022",
    },
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "25 Aug 2022",
    },
  ];
  return (
    <div>
      <Modal
        show={props.show}
        onHide={() => props.onReviewsClose()}
        backdrop="static"
        fullscreen="lg-down"
        size="lg"
        centered
        keyboard={false}
        className="lg-dialog-modal dialog-wrapper rounded-corner-none"
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100 ps-3">
            <div className="d-flex justify-content-between">
              <div className="fs-24 color-primary fw-700 pt-2">
                Notes on candidate
              </div>
              <div className="pe-2 pt-1">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onReviewsClose()}
                  className="pointer"
                  width="30px"
                  height="30px"
                />
              </div>
            </div>
            <div className="color-tertiary fs-12">{props?.getNotes?.length} notes</div>
            <hr className="me-3 mb-1"></hr>
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body mt-0 pt-0">
          <div className="p-3 pt-0">
            <div>
              {props?.getNotes?.map((review, index) => (
                <div key={index}>
                  <p className="color-tertiary fs-12 mb-2">
                    “ {review.note} “
                  </p>
                  <div className="fs-12 color-primary fw-700">
                    - {review.name}
                  </div>
                  <div className="color-tertiary fs-11 fw-300 pt-1">
                    {review.date}
                  </div>
                  {index === props?.getNotes?.length - 1 ? "" : <hr></hr>}
                </div>
              ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="dialog-footer border-0"></Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllReviews;
