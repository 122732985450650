import React, { useEffect, useState } from "react";
import AddImageLogo from "../../../assests/icons/ic-add-filled.svg";

import "../referee_screen/styles.scss";
import SwitchButton from "./../../../components/SwitchButton";
import "./teams_member_style.scss";
import InviteMemberDialog from "./../../../dialogs/teams_and_members/invite_member";
import MemberPage from "./members_page";
import TeamsPage from "./teams_page";
import AddTeamDialog from "../../../dialogs/teams_and_members/add_team_dialog";
import {
  getmember,
  getLoggedInUserMembers,
  getTeams,
} from "../../../_services/view.service";
import toaster from "../../../utils/toaster";
import { STATUS_SUCCESS } from "../../../config/be_api_constants";
import Loader from "../../../components/common/loader";
import { isEmpty } from "../../../utils/form_validators";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import {
  GENERAL_ERROR_MESSAGE,
  JOB_FETCH_FAILED,
} from "../../../config/messages";
import { setTextRange } from "typescript";

function TeamsMembersPage() {
  let isInfinite = false;

  const [showInviteMemberDialog, setShowInviteMemberDialog] = useState(false);
  const [showAddTeamDialog, setShowAddTeamDialog] = useState(false);
  const [memberlist, setmemberlist] = useState([]);
  const [teamsList, setTeamsList] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [loading, SetLoading] = useState(false);

  const [allJobMessage, setAllJobMessage] = useState("");

  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const [allMembersFilterData, setAllMembersFilterData] =
    useState(initialFilterData);
  const [allTeamssFilterData, setAllTeamsFilterData] =
    useState(initialFilterData);
  const [currentMemberPageset, setCurrentMemberPageSet] = useState(0);
  const [dataCountMembers, setDataCountMembers] = useState(0);
  const [dataCountTeams, setDataCountTeams] = useState(0);
  const [currentTeamsPageset, setCurrentTeamsPageSet] = useState(0);

  const getmemberlists = (data) => {
    SetLoading(true);
    getmember(data).then(
      (res) => {
        if (res && !isEmpty(res?.data?.data)) {
          setDataCountMembers(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...memberlist, ...res?.data?.data];
          }
          setCurrentMemberPageSet(data?.length);
          setmemberlist(data);
          SetLoading(false);
        } else if (isEmpty(res) && res?.status === 200) {
          SetLoading(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          SetLoading(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        setShowLoader(false);
      }
    );
  };

  const fetchMoreMembers = () => {
    // if (jobList.length < dataCount) {
    setCurrentMemberPageSet(currentMemberPageset + 5);
    //   setPage_No(page_No + 1);
    // }
    isInfinite = true;

    getmemberlists({
      ...allMembersFilterData,
      pageNo: allMembersFilterData?.pageNo + 1,
    });
    setAllMembersFilterData({
      ...allMembersFilterData,
      pageNo: allMembersFilterData?.pageNo + 1,
    });
  };
  useEffect(() => {
    getmemberlists(allMembersFilterData);
  }, []);
  useEffect(() => {
    let temp = { ...initialFilterData };
    setAllMembersFilterData(temp);
    getmemberlists(temp);
  }, []);

  const getTeamsList = (data) => {
    SetLoading(true);

    getTeams(data).then(
      (res) => {
        console.log("dataaaaa", res);
        // setTeamsList(res?.data);
        //     SetLoading(false);
        //   })
        //   .catch((err) => {
        //     toaster("Error", err);
        //   });
        // SetLoading(false);
        if (res && !isEmpty(res?.data?.data)) {
          setDataCountTeams(res?.data?.totalRecord);

          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...teamsList, ...res?.data?.data];
          }
          setCurrentTeamsPageSet(data?.length);
          setTeamsList(data);
          SetLoading(false);
        } else if (isEmpty(res) && res?.status === 200) {
          SetLoading(false);
          setAllJobMessage(res?.message ? res?.message : JOB_FETCH_FAILED);
        } else {
          SetLoading(false);
        }
      },
      (error) => {
        toaster(
          "error",
          error?.message ? error?.message : GENERAL_ERROR_MESSAGE
        );
        SetLoading(false);
      }
    );
  };

  const fetchMoreTeams = () => {
    // if (jobList.length < dataCount) {
    setCurrentTeamsPageSet(currentTeamsPageset + 5);
    //   setPage_No(page_No + 1);
    // }
    isInfinite = true;

    getTeamsList({
      ...allTeamssFilterData,
      pageNo: allTeamssFilterData?.pageNo + 1,
    });
    setAllTeamsFilterData({
      ...allTeamssFilterData,
      pageNo: allTeamssFilterData?.pageNo + 1,
    });
  };
  useEffect(() => {
    getTeamsList(allTeamssFilterData);
  }, []);
  useEffect(() => {
    let temp = { ...initialFilterData };
    setAllTeamsFilterData(temp);
    getTeamsList(temp);
  }, []);

  const [switchValue, setSwitchValue] = useState(
    `Members (${memberlist?.totalRecord})`
  );
  const [selectedState, setSelectedState] = useState(`Members`);
  console.log("switchValue", selectedState)

  const [apiRefresh, setApiRefresh] = useState(false);

  useEffect(() => {
    getTeamsList(allTeamssFilterData);
  }, [switchValue, selectedState]);
  useEffect(() => {
    if (apiRefresh === true) {
      getmemberlists(allMembersFilterData);
      getTeamsList(allTeamssFilterData);
      setApiRefresh(false);
    }
  }, [apiRefresh, selectedState, switchValue]);

  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin my-4">
        <div>
          <h1 className="fs-24 fw-700">Teams & Members</h1>
        </div>
        <div className="d-flex flex-wrap justify-content-between mt-3">
          <div>
            <SwitchButton
              data={[
                `Members (${dataCountMembers})`,
                `Teams (${dataCountTeams})`,
              ]}
              value={selectedState}
              setValue={setSelectedState}
              theme={"black"}
              // height="35px"
              // switcherHeight="29px"
            />
          </div>
          {selectedState === `Members (${dataCountMembers})` ||
          selectedState === "Members" ? (
            <div className="new-job-template">
              <button
                onClick={() => setShowInviteMemberDialog(true)}
                className="new-job-template btn m-0 px-2 gap-2 rounded-pill fs-14 fw-700 border border-dark d-flex"
              >
                <img alt="" src={AddImageLogo} />
                <span>Invite Member</span>
              </button>
            </div>
          ) : (
            <div className="new-job-template">
              <button
                onClick={() => setShowAddTeamDialog(true)}
                className="new-job-template btn m-0 px-2 gap-2 rounded-pill fs-14 fw-700 border border-dark d-flex"
              >
                <img alt="" src={AddImageLogo} />
                <span>Add Team</span>
              </button>
            </div>
          )}
        </div>

        {selectedState === `Members (${dataCountMembers})` ||
        selectedState === "Members" ? (
          <MemberPage
            memberlist={memberlist}
            showLoader={showLoader}
            loading={loading}
            setApiRefresh={setApiRefresh}
            fetchMoreMembers={fetchMoreMembers}
            currentMemberPageset={currentMemberPageset}
            dataCountMembers={dataCountMembers}
          />
        ) : (
          <TeamsPage
            teamsList={teamsList}
            fetchMoreTeams={fetchMoreTeams}
            dataCountTeams={dataCountTeams}
            currentTeamsPageset={currentTeamsPageset}
            showLoader={showLoader}
            loading={loading}

          />
        )}

        {/* Dialogs */}
        <InviteMemberDialog
          show={showInviteMemberDialog}
          close={() => setShowInviteMemberDialog(false)}
          memberlist={memberlist}
          setApiRefresh={setApiRefresh}
          teamsList={teamsList}
          fetchMoreTeams={fetchMoreTeams}
          dataCountTeams={dataCountTeams}
          currentTeamsPageset={currentTeamsPageset}
          setSelectedState={() => setSelectedState('Members')}
        />
        <AddTeamDialog
          show={showAddTeamDialog}
          close={() => setShowAddTeamDialog(false)}
          setApiRefresh={setApiRefresh}
          teamsList={teamsList}
          fetchMoreMembers={fetchMoreMembers}
          currentMemberPageset={currentMemberPageset}
          dataCountMembers={dataCountMembers}
          getTeamsList = {() => getTeamsList(allTeamssFilterData)}
          setSwitchValue = {() => setSelectedState('Teams')}
        />
      </div>
    </div>
  );
}

export default TeamsMembersPage;
