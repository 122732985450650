import React, { useEffect, useState } from "react";
import icRightArrow from "../../../assests/icons/ic-right-arrow.svg";
import icLeftArrow from "../../../assests/icons/ic-left-arrow.svg";
import { Carousel } from "react-bootstrap";
import AllReviews from "../../../dialogs/application_dialogs/allreviews";
import AddNotes from "../../../dialogs/application_dialogs/addnotes";
import { getUserApplicationAllNotes } from "../../../_services/view.service";
import Loader from "../../../components/common/loader";

const NotesOnCandidate = ({ applicationDetails }) => {
  const [index, setIndex] = useState(0);
  const [showReview, setShowReview] = useState(false);
  const [addNote, setAddNote] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [getNotes, setGetNotes] = useState([]);

  const applicationId =
    applicationDetails && applicationDetails.jobApplication?.applicationId;

  console.log("applicationId --->", applicationId);

  console.log("length", getNotes?.length)
  const length = getNotes?.length;

  const viewAllNotes = () => {
    setShowLoader(true);
    getUserApplicationAllNotes(1, 10,applicationId)?.then((res) => {
      setShowLoader(false);
      setGetNotes(res?.data?.data);
    });
  };
  console.log("getNotes---------->", getNotes);

  useEffect(() => {
    viewAllNotes();
  }, [applicationId, applicationDetails]);

  const prev = () => {
    if (index >= 1) setIndex(index - 1);
    else setIndex(getNotes?.length - 1);
  };
  const next = () => {
    if (index < getNotes?.length - 1) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  };
  const reviews = [
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "21 Aug 2022",
    },
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "22 Aug 2022",
    },
    {
      descrption:
        "IMO this guy would be a great fit for a managerial role. From my telephonic conversation, I get the feeling that he has good people and management skills.",
      name: "Sanjeev Sagar",
      date: "25 Aug 2022",
    },
  ];

  return (
    <div className="mt-4">
      {showLoader && <Loader />}
      <div className="shadow-box p-3">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="black-text fw-600 fs-16">Notes on candidate</div>
            <div className="color-tertiary fs-12">{getNotes?.length} notes</div>
          </div>
          <div onClick={() => setAddNote(true)} className="pointer">
            <button className="btn-dialog-save">Add Note</button>
          </div>
        </div>
        <hr></hr>

        <Carousel
          className="px-0"
          activeIndex={index}
          controls={false}
          indicators={false}
        >
          {getNotes?.map((review, i) => (
            <Carousel.Item key={i + 1}>
              <p className="color-tertiary fs-12 mb-2">“ {review.note} “</p>
              <div className="fs-12 color-primary fw-700">- {review.name}</div>
              <div className="color-tertiary fs-11 fw-300 pt-1">
                {review.date}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>

        <div className="d-flex justify-content-end align-items-center gap-3">
          <div className="d-flex gap-3 color-tertiary fs-11">
            <div>
              <img
                src={icLeftArrow}
                alt="left-arrow-icon"
                onClick={() => prev()}
              />
            </div>
            <div>
              {getNotes?.length < 1 ? 0 : index + 1}/{getNotes?.length}
            </div>
            <div className="pointer">
              <img
                src={icRightArrow}
                alt="left-arrow-icon"
                onClick={() => next()}
              />
            </div>
          </div>
          <div onClick={() => setShowReview(true)} className="pointer">
            <button className="btn-dialog-cancel">See All</button>
          </div>
        </div>
      </div>
      <AllReviews
        getNotes={getNotes}
        show={showReview}
        onReviewsClose={() => setShowReview(false)}
      />
      <AddNotes
        show={addNote}
        onNotesClose={() => setAddNote(false)}
        applicationDetails={applicationDetails}
        applicationId={applicationId}
        viewAllNotes = {() => viewAllNotes(1, 10, applicationId)}
      />
    </div>
  );
};

export default NotesOnCandidate;
