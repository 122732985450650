import { action, thunk } from "easy-peasy";
import { USER_ROLE } from "../config/be_api_constants";
import { getAccountDetails } from "./../_services/view.service";

export const candidateModel = {
  isLoading: false,
  // role: USER_ROLE.BUSINESS_ADMIN,
  role: localStorage.getItem("role"),
  candidateDetails: {},
  addIsLoading: action((state, payload) => {
    return (state.isLoading = payload);
  }),

  addCandidateDetails: action((state, payload) => {
    return {
      ...state,
      candidateDetails: payload,
    };
  }),
  saveCandidateDetails: thunk(async (actions, payload) => {
    actions.addIsLoading(true);
    const { data } = await getAccountDetails(payload);
    if (data.status === "SUCCESS") {
      
      actions.addCandidateDetails(data?.data);
    }
    actions.addIsLoading(false);
  }),
};
