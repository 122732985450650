export const GENERAL_ERROR_MESSAGE = "Something went wrong!";
export const REFERRED_JOB_EMPTY_MESSAGE =
  "You’ve not been referred for any roles";
export const SAVED_JOB_EMPTY_MESSAGE = "You’ve not saved any jobs";
export const REFEREE_REFERRAL_EMPTY_MESSAGE = "You have not made any referrals";
export const REFEREE_RECOMMENDED_EMPTY_MESSAGE =
  "Add your referral preferences to see recommendations";
export const REFEREE_SAVED_JOB_EMPTY_MESSAGE = "You’ve not saved any jobs";
export const REECOMMENDED_JOB_EMPTY_MESSAGE =
  "Add preferences to see recommended jobs";
export const APPLIED_JOB_EMPTY_MESSAGE = "You’ve not made any applications";
export const REFEREE_REFERRED_JOB_EMPTY_MESSAGE =
  " Add your referral preferences to see recommendations ";
export const INVALID_IMAGE_TYPE = "Invalid file! Please upload jpg or png file";
export const FROM_REFEREE_PAGE = "referring";
export const NOT_MENTIONED = "Not Mentioned";
export const BOTH_OTP_SENT = "OTPs have been sent to your mobile & email";
export const EMAIL_OTP_SENT = "OTP has been sent to your email";
export const MOBILE_OTP_SENT = "OTP has been sent to your mobile";
export const INVALID_EMAIL_OTP = "Email Verification Failed.";
export const VALID_EMAIL_OTP = "Email Verified Successfully.";
export const INVALID_PHONE_OTP = "Mobile Verification Failed.";
export const VALID_PHONE_OTP = "Mobile Number Verified Successfully.";
export const ENTER_VALID_EMAIL_OTP = "Please enter valid Email OTP";
export const ENTER_VALID_PHONE_OTP = "Please enter valid Mobile OTP";
export const NOTIFICATION_EMPTY_MESSAGE = "You don't have any notification";
export const ACCOUNT_NO_NOT_MATCHING = "Acccount Number does not match";
export const INVALID_INPUT = "Invalid input";
export const WAIT_FOR_TIMER = "Wait for timer to end";
export const SUCCESSFULLY_APPLIED_JOB = "Successfully Applied for Job.";
export const IMAGE_SIZE_EXCEEDING = "Image size cannot exceed 2MB";
export const IMAGE_DIMENSION = "Image dimensions should be around 164*164 px";
export const IMAGE_DIMENSION_ACTION =
  "Image dimensions should be around 652 X 428 px";
export const STATUS_FAILED = "FAILED";
export const SESSION_EXPIRED_MESSAGE =
  "Your session has been expired. Please Login again";
export const SUCCESSFULLY_MESSAGE_SENT =
  "Your Message has been sent successfully";
export const NO_DATA_VIEW_APPLICATION =
  "No data added when applying for this job";
export const DETAILS_UPDATED = "Details Updated successfully!";
export const NO_EDUCATION_ADDED = "No education is added";
export const NO_EXPERIENCE_ADDED = "No experience is added";
export const NO_SKILLS_EXPERIENCE_ADDED = "No skills & experience is added";
export const JOB_FETCH_FAILED = "Job fetch failed!";
export const NOTIFICATIONS_UPDATE_MESSAGE = "You have some new Notifications";

export const FORM_VALIDATION_MESSAGE = {
  REQUIRED: "Field is required",
  EMAIL: "Email address is invalid",
  PHONE: "Phone number is invalid",
  NAME: "Please enter alphabet only.",
};

export const INVALID_YEAR = "Invalid Year";

export const PASSWORD_VALIDATION_MESSAGE = {
  LENGTH: "Password must contain atleast 8 characters",
  LOWERCASE: "Password must include atleast one lowercase letter",
  UPPERCASE: "Password must include atleast one uppercase letter",
  NUMBER: "Password must include atleast one number",
  CHARACTER: "Password must include atleast one special character",
};
export const RESET_PASSWORD_LINK_SUCCESS =
  "Reset password link has been sent to your email / mobile number";

export const JOB_SAVED_SUCCESS = "Job saved successfully!";
export const JOB_UNSAVED_SUCCESS = "Job unsaved!";
export const NO_JOBS_PINNED = "No jobs have been pinned";
export const NO_TEAMS_PINNED = "No teams have been pinned";
