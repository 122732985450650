import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import BackButton from "../../../assests/icons/back-icon.svg";
import jobCompanyLogo from "../../../assests/images/job-company-logo.png";
import icUp from "../../../assests/icons/ic-chevron-up-16.svg";
import icDown from "../../../assests/icons/ic-chevron-down-16.svg";
import ProfileImage from "../../../components/profile_image";
import ApplicationAboutMe from "./application_about_me";
import aboutLogo from "../../../assests/icons/about.svg";
import NotesOnCandidate from "./notes_on_candidate";
import WhyShouldWeHire from "./why_should_we_hire";
import ApplicationResume from "./application_resume";
import ApplicationContactOnlinePresence from "./application_contact_online_presence";
import ApplicationSkills from "./application_skills";
import ApplicationExperience from "./application_experience";
import ApplicationEducationAndAcademics from "./application_education_and_academics";
import ApplicationQuestions from "./application_questions";
import ApplicationReferral from "./application_referral";
import "./applications.scss";
import {
  getApplicationDetails,
  getApplicationStatusHistory,
} from "../../../_services/view.service";
import CompanyImage from "../../../components/company_image";
import ApplicationReferralBonus from "./application_referral_bonus";
import { getLocalStorage } from "../../../utils/storage";
import { USER_ROLE } from "../../../config/be_api_constants";

const JobPostApplicationPage = () => {
  const role = getLocalStorage("role");
  const [viewDetails, setViewDetails] = useState(false);
  const [isReferralAvailable, setIsReferralAvailable] = useState(false);
  const [requestRecommended, setRequestRecommended] = useState(false);

  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );

  const [applicationDetails, setApplicationDetails] = useState([]);
  const [applicationStatusHistory, setApplicationStatusHistory] = useState({});
  const [applicationReferralBonus, setApplicationReferralBonus] = useState({});

  let { id } = useParams();
 

  const viewApplicationDetails = () => {
    getApplicationDetails(id) &&
      getApplicationDetails(id).then((res) => {
        setApplicationDetails(res?.data?.data);
        setApplicationReferralBonus(res?.data?.data?.redeemRewardPoints[0]);
      });
  };

  const viewApplicationStatusHistory = () => {
    getApplicationStatusHistory(id)?.then((res) => {
      setApplicationStatusHistory(res?.data?.data);
    });
  };

  // const getId = applicationDetails && applicationDetails?.jobQuestionAndAnswers[0]?.[1]?.jobApplicationId;
 
  // useEffect(() => {
  //   if(applicationDetails?.jobApplication?.referalId === null){
  //     setIsReferralAvailable(false);
  //   }
  // }, [isReferralAvailable])

  
  useEffect(() => {
    viewApplicationDetails();
    viewApplicationStatusHistory();
  }, []);

  return (
    <div className="d-flex flex-column align-items-stretch flex-grow-1 container mt-4">
      {/* <div className="">
        <Header candidateDetails={candidateDetails} />
      </div> */}
      <div className="bg-white w-100 content-margin mt-4">
        <div className="container p-4 p-lg-0 pb-3 pb-lg-3">
          <div className="d-flex justify-content-center">
            <div className="col-lg-6 col-12">
              <div className="d-flex mb-4 w-100">
                <Link to={-1} className="color-primary d-flex gap-2">
                  <img src={BackButton} alt={BackButton} />
                  <div className="color-primary fs-12 fw-400 back-btn-width">
                    Go Back
                  </div>
                </Link>
              </div>
              {/* job details component */}
              <div className="box-shadow p-3 feedback-background">
                <div className="company-details-wrapper d-flex justify-content-start align-items-center pb-2">
                  <div className="company-logo-wrapper">
                    {/* <CompanyImage
                      src={applicationDetails?.companyProfile?.companyLogo}
                      width="50px"
                      initialsContainerClass="initialsStyle2-xl"
                    /> */}
                    <img
                      // src={applicationDetails?.companyProfile?.companyLogo}
                      src={
                        applicationDetails?.companyProfile?.companyLogo === null
                          ? CompanyImage
                          : `data:image/jpeg;base64 ,${applicationDetails?.companyProfile?.companyLogo}`
                      }
                      alt="company name"
                      width="70px"
                    />
                  </div>
                  <div className="company-name">
                    <div className="color-tertiary fs-12">
                      {" "}
                      {applicationDetails?.companyProfile?.companyName}{" "}
                    </div>
                    <div className="fs-16 fw-600 color-secondary">
                      {/* Business Development Manager - Growth &amp; Strategy */}
                      {applicationDetails?.jobPosting?.jobTitle}
                    </div>
                  </div>
                </div>
                <div className="small-text-gray pt-3">
                  Applied : {applicationDetails?.jobApplication?.appliedDate}
                </div>
                <div className="fs-14 color-secondary pt-2">
                  {" "}
                  Application Status :{" "}
                  {applicationDetails?.jobApplication?.applicationStatus}
                </div>
                <hr></hr>
                <div className="color-tertiary fs-12">
                  {applicationStatusHistory?.hasOwnProperty("APPLIED") && (
                    <>
                      <span>Received {"> "}</span>
                    </>
                  )}
                  {/* <span>Received  </span> */}
                  {/* <span>Shortlisted  </span> */}
                  {applicationStatusHistory?.hasOwnProperty("SHORTLISTED") && (
                    <>
                      <span>Shortlisted {"> "}</span>
                    </>
                  )}
                  {/* <span>Flagged </span>
                  <span>Exported </span> */}
                  {applicationStatusHistory?.hasOwnProperty("ASSESSMENT") && (
                    <>
                      <span>Assessment {"> "}</span>
                    </>
                  )}
                  {/* <span>Assessment </span> */}
                  {applicationStatusHistory?.hasOwnProperty("OFFERED") && (
                    <>
                      <span className="text-green">Offer Accepted {"> "}</span>
                    </>
                  )}
                  {/* <span>Offered </span>
                  <span className="text-green">Offer Accepted </span>
                  <span className="text-green"> Joined </span>
                  <span className="dark-red-text"> Dropout </span> */}
                  {applicationStatusHistory?.hasOwnProperty("JOINED") && (
                    <>
                      <span className="text-green">Joined {"> "}</span>
                    </>
                  )}

                  {applicationStatusHistory?.hasOwnProperty("RETRACTED") && (
                    <>
                      <span className="dark-red-text">Dropout {"> "}</span>
                    </>
                  )}
                </div>
                <hr></hr>
                <div
                  className={
                    viewDetails === false
                      ? "d-flex justify-content-end d-block pointer"
                      : "d-none"
                  }
                  onClick={() => setViewDetails(true)}
                >
                  <div className="fs-12 fw-500 color-primary pe-3 text-decoration-underline">
                    View Details
                  </div>
                  <img src={icDown} alt="down-arrow-icon" />
                </div>
                <div className={viewDetails === true ? "d-block" : "d-none"}>
                  {applicationStatusHistory?.hasOwnProperty("APPLIED") && (
                    <>
                      <div className="color-tertiary fs-12 d-flex justify-content-between">
                        <div>Received</div>
                        <div>{applicationStatusHistory?.APPLIED}</div>
                      </div>
                      <hr></hr>
                    </>
                  )}

                  {applicationStatusHistory?.hasOwnProperty("SHORTLISTED") && (
                    <>
                      <div className="color-tertiary fs-12 d-flex justify-content-between">
                        <div>Shortlisted</div>
                        <div>{applicationStatusHistory?.SHORTLISTED}</div>
                      </div>
                      <hr></hr>
                    </>
                  )}

                  {applicationStatusHistory?.hasOwnProperty("ASSESSMENT") && (
                    <>
                      <div className="color-tertiary fs-12 d-flex justify-content-between">
                        <div>Assessment</div>
                        <div>{applicationStatusHistory?.ASSESSMENT}</div>
                      </div>
                      <hr></hr>
                    </>
                  )}

                  {applicationStatusHistory?.hasOwnProperty("OFFERED") && (
                    <>
                      <div className="color-tertiary fs-12 d-flex justify-content-between">
                        <div>Offered</div>
                        <div>{applicationStatusHistory?.OFFERED}</div>
                      </div>
                      <hr></hr>
                    </>
                  )}

                  {applicationStatusHistory?.hasOwnProperty("OFFERED") && (
                    <>
                      <div className="color-tertiary fs-12 d-flex justify-content-between">
                        <div className="text-green">Offer Accepted</div>
                        <div>{applicationStatusHistory?.OFFER_ACCEPTED}</div>
                      </div>
                      <hr></hr>
                    </>
                  )}

                  {applicationStatusHistory?.hasOwnProperty("JOINED") && (
                    <>
                      <div className="color-tertiary fs-12 d-flex justify-content-between">
                        <div className="text-green">Joined</div>
                        <div>{applicationStatusHistory?.JOINED}</div>
                      </div>
                      <hr></hr>
                    </>
                  )}

                  {applicationStatusHistory?.hasOwnProperty("RETRACTED") && (
                    <>
                      <div className="color-tertiary fs-12 d-flex justify-content-between">
                        <div className="dark-red-text">Dropout</div>
                        <div>{applicationStatusHistory?.RETRACTED}</div>
                      </div>
                      <hr></hr>
                    </>
                  )}
                  <div
                    className="d-flex justify-content-end pointer"
                    onClick={() => setViewDetails(false)}
                  >
                    <div className="fs-12 fw-500 color-primary pe-3 text-decoration-underline">
                      Hide Details
                    </div>
                    <img src={icUp} alt="up-arrow-icon" />
                  </div>
                </div>
              </div>

              {/* referral section */}
              {/* {isReferralAvailable === true ? <ApplicationReferral applicationDetails={applicationDetails}/> : ""} */}
              {applicationDetails?.jobApplication?.referalId !== null ? (
                <ApplicationReferral applicationDetails={applicationDetails} />
              ) : (
                ""
              )}

              {/* ApplicationReferralBonus */}
              {role === USER_ROLE.SUPER_ADMIN ? (
                applicationDetails?.redeemRewardPoints !== null ? (
                  <ApplicationReferralBonus
                    applicationDetails={applicationReferralBonus}
                    appDetails={applicationDetails}
                    viewApplicationDetails={() => viewApplicationDetails(id)}
                    id={id}
                  />
                ) : (
                  ""
                )
              ) : null}

              {/* candidate details starts */}
              <div>
                <ApplicationAboutMe applicationDetails={applicationDetails} />
                <NotesOnCandidate applicationDetails={applicationDetails} />
                <WhyShouldWeHire applicationDetails={applicationDetails} />
                <ApplicationResume applicationDetails={applicationDetails} />
                <ApplicationContactOnlinePresence
                  applicationDetails={applicationDetails}
                />
                <ApplicationSkills applicationDetails={applicationDetails} />
                <ApplicationExperience
                  applicationDetails={applicationDetails}
                />
                <ApplicationEducationAndAcademics
                  applicationDetails={applicationDetails}
                />
                <ApplicationQuestions applicationDetails={applicationDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default JobPostApplicationPage;
