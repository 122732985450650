import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import ProfileImage from "../../components/profile_image";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";
import icdelete from "../../assests/icons/ic_delete.svg";
import Dropzone from "react-dropzone";
import CompanyImage from "../../components/company_image";
import { SOMETHING_WENT_WRONG, STATUS_SUCCESS, USER_ID } from "../../config/localstorage_keys";
import Loader from "../../components/common/loader";
import deleteIcon from "../../assests/icons/delete.svg";
import {
  GENERAL_ERROR_MESSAGE,
  IMAGE_DIMENSION,
  IMAGE_SIZE_EXCEEDING,
  INVALID_IMAGE_TYPE,
} from "../../config/messages";
import { updateProfilePicture } from "../../_services/candidate.service";
import { getLocalStorage } from "../../utils/storage";
import { useStoreActions } from "easy-peasy";

const CompanyLogo = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [companyLogo, setCompanyLogo] = useState();
  const [previewRequested, setPreviewRequested] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [photoId, setPhotoId] = useState(null);
  const [profilePicId, setprofilePicId] = useState(null);
  const [profileSrc, setProfileSrc] = useState(null);

  const [companyUrl, setCompanyUrl] = useState();
  const [image, setimage] = useState("");
  const [files, setFiles] = useState({
    acceptedFiles: null,
    fileName: "",
    extension: "",
  });
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  // const [pictureData, setPictureData] = useState({
  //   acceptedFiles: null,
  //   fileName: "",
  //   extension: "",
  // });
  const imageUrl = "";
  const handleDrop = (acceptedFiles) => {
    // const canvas = document.getElementById("upload-image");
    // / setPreviewRequested(true);
    acceptedFiles.map(async (file) => {
      // imageUrl=file.name
      setimage(file.name);
      const base64 = await convertBase64(file);
      const base64Value = base64.split(",").pop();

      setCompanyLogo(base64Value);
      localStorage.setItem("orgLogo", base64Value);
      setPreviewRequested(false);
    });
    setFiles({
      ...files,
      acceptedFiles,
      // fileName,
      // extension,
    });
    // if (acceptedFiles && acceptedFiles?.length >= 1) {
    //   var image = new Image();
    //   let isValid = true;

    //   const fileSize = acceptedFiles[0]?.size;
    //   const fileName = acceptedFiles[0]?.name;
    //   const extensionIndex = fileName.lastIndexOf(".");
    //   const extension = fileName.slice(extensionIndex + 1);
    //   const isFileExtValid =
    //     fileName.includes(".png") ||
    //     fileName.includes(".jpg") ||
    //     fileName.includes(".jpeg");

    //   if (!isFileExtValid) {
    //     toaster("error", INVALID_IMAGE_TYPE);
    //     setPreviewRequested(false);
    //     return;
    //   }

    //   const isFileSizeValid = fileSize < 2e6;

    //   if (!isFileSizeValid) {
    //     toaster("error", IMAGE_SIZE_EXCEEDING);
    //     setPreviewRequested(false);
    //     return;
    //   }

    //   if (isFileExtValid && isFileSizeValid) {
    //     isValid = true;
    //   } else {
    //     isValid = false;
    //   }

    //   if (isValid) {
    //     let dimensionValid = false;

    //     setFiles({
    //       ...files,
    //       acceptedFiles,
    //       fileName,
    //       extension,
    //     });
    //     let previewImage = Object.assign(acceptedFiles[0], {
    //       preview: URL.createObjectURL(acceptedFiles[0]),
    //     });

    //     image.src = previewImage?.preview;
    //     image.onload = function () {
    //       if (image.width <= 200 && image.height <= 200) {
    //         dimensionValid = true;
    //       }
    //       if (isValid && dimensionValid) {
    //         setPreviewRequested(true);
    //         setPreviewSrc(previewImage?.preview);
    //          setDeleteFlag(false);
    //       } else {
    //         toaster("error", IMAGE_DIMENSION);
    //         setPreviewRequested(false);
    //       }
    //     };
    //   }
    // } else {
    //   toaster("error", GENERAL_ERROR_MESSAGE);
    //   setPreviewRequested(false);
    // }

    if (acceptedFiles && acceptedFiles?.length >= 1) {
      var image = new Image();
      let isValid = true;

      const fileSize = acceptedFiles[0]?.size;
      const fileName = acceptedFiles[0]?.name;
      const extensionIndex = fileName.lastIndexOf(".");
      const extension = fileName.slice(extensionIndex + 1);
      const isFileExtValid =
        fileName.includes(".png") ||
        fileName.includes(".jpg") ||
        fileName.includes(".jpeg");

      if (!isFileExtValid) {
        toaster("error", INVALID_IMAGE_TYPE);
        setPreviewRequested(false);
        return;
      }

      const isFileSizeValid = fileSize < 2e6;

      if (!isFileSizeValid) {
        toaster("error", IMAGE_SIZE_EXCEEDING);
        setPreviewRequested(false);
        return;
      }

      if (isFileExtValid && isFileSizeValid) {
        isValid = true;
      } else {
        isValid = false;
      }

      if (isValid) {
        let dimensionValid = false;

        setFiles({
          ...files,
          acceptedFiles,
          fileName,
          extension,
        });
        let previewImage = Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });

        image.src = previewImage?.preview;
        image.onload = function () {
          if (image.width <= 200 && image.height <= 200) {
            dimensionValid = true;
          }
          if (isValid && dimensionValid) {
            setPreviewRequested(true);
            setPreviewSrc(previewImage?.preview);
            setDeleteFlag(false);
          } else {
            toaster("error", IMAGE_DIMENSION);
            setPreviewRequested(false);
          }
        };
      }
    } else {
      toaster("error", GENERAL_ERROR_MESSAGE);
      setPreviewRequested(false);
    }
  };
  const convertBase64 = (companyLogo) => {
    return new Promise((resovle, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(companyLogo);
      fileReader.onload = () => {
        resovle(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const saveLogo = {
  
    companyId: props.companyId,
    companyLogo: companyLogo,
    
  };
  const saveLogo1 = {
    companyId: props.companyId,
    companyLogo: "",
  };
  // const updateProfilePictureApiCall = (response) => {
  //   setprofilePicId(response?.id);
  //   const profilePicUpdateObject = {
  //     companyLogo: response?.id,
  //   };
  // };

  
  const updatePictureApiCall = (response) => {
    setprofilePicId(response?.id);
    const profilePicUpdateObject = {
      profilePicId: response?.id,
    };

    create(saveLogo1)
      .then((data) => {
        setTimeout(() => {
          setShowLoader(false);
          props?.setApiRefresh(true);
          //toaster("success", "Company Logo updated successfully!");
        }, 1000);
        props.onLogoClose();
      })
      .catch((err) => {
        setShowLoader(false);
        // toaster(
        //   "error",
        //   "Couldn't update logo, please try again later"
        // );
      });
  };

  const onSaveCompanyLogo = () => {
    // setPreviewRequested(true);
    setPreviewRequested(false);
    if (deleteFlag) {
      let response = {
        id: "",
      };
      updatePictureApiCall(response);
    } else {

      if (previewRequested) {
        let formData = new FormData();
        const fileObjects = files?.acceptedFiles.map((file) => {
          formData.append("contentData", file);
          formData.append(
            "contentMetaData",
            JSON.stringify({
              contentType: "PROFILE",
              contentName: `${files?.fileName}`,
              contentExtention: `${files?.extension}`,
            })
          );
        });
        setShowLoader(true);
        create(saveLogo)
          .then((res) => {
            setShowLoader(false);
            props?.setApiRefresh(true);
            if (res?.data?.status == STATUS_SUCCESS) {
             
              toaster(
                "success",
                res?.data?.message ? res?.data?.message : STATUS_SUCCESS
              );
              
            }
            // if (response?.id) {
            //   updateProfilePictureApiCall(response);
            // } else {
            //   setShowLoader(false);
            //   toaster("error", SOMETHING_WENT_WRONG);
            // }
            props.onLogoClose();
      })
          .catch((err) => {
            setShowLoader(false);
            props?.setApiRefresh(false);
            toaster("error", err);
            setPreviewRequested(false);
          });
      }
    }
  };
  // const handleDeletePicture = () => {
  //   setPreviewRequested(false);
  //   setPreviewSrc(null);
  //   setDeleteFlag(true);
  //   setShowLoader(true);
  //   create(saveLogo1)
  //         .then((res) => {
  //           setShowLoader(false);
  //           props?.setApiRefresh(true);
  //           if (res?.data?.status == STATUS_SUCCESS) {
  //             toaster(
  //               "success",
  //               res?.data?.message ? res?.data?.message : STATUS_SUCCESS
  //             );
              
  //           }
  //           // if (response?.id) {
  //           //   updateProfilePictureApiCall(response);
  //           // } else {
  //           //   setShowLoader(false);
  //           //   toaster("error", SOMETHING_WENT_WRONG);
  //           // }
  //           props.onLogoClose();
  //     })
  //         .catch((err) => {
  //           setShowLoader(false);
  //           props?.setApiRefresh(false);
  //           toaster("error", err);
  //           setPreviewRequested(false);
  //         });
      
  //   // alert("hii")
  //   // setPreviewRequested(true);
   
  // };

  const handleDeletePicture = () => {
    setPreviewRequested(true);
    // alert("hiii")
    localStorage.setItem("orgLogo", "");
    setPreviewSrc(null);
    setDeleteFlag(true);
  };
  return (
    <>
      {showLoader && <Loader />}
      <div>
        <Modal
          show={props.show}
          // onHide={() => props.onLogoClose()}
          onHide={() => {
            props.onLogoClose();
            // setDeleteFlag(false);
            // setPreviewRequested(false);
          }}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onLogoClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">
                Company Logo
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center align-items-center">
            <div className="modal-profile-picture-div">
              <div
                className="position-absolute deleteIconStyle pointer"
                onClick={handleDeletePicture}
              >
                <img src={deleteIcon} alt="delete icon" />
              </div>
              {previewRequested ? (
                <img
                  src={`data:image/jpeg;base64 , ${companyLogo}`}
                  // src={profileSrc}
                  // width="70px"
                  // height="70px"
                  // name={candidateDetails?.userRegistrationDetails?.name}
                  initialsContainerClass="sidebar-default-profile-picture"
                />
              ) : (
                <ProfileImage
                  src={`data:image/jpeg;base64 , ${props.companyLogo}`}
                  // src={previewSrc}
                  // name={candidateDetails?.userRegistrationDetails?.name}
                  initialsContainerClass="sidebar-default-profile-picture"
                />
              )}
            </div>
          </Modal.Body>

          {/* <Modal.Footer className="dialog-footer">
            <button className="btn btn-cancel pointer">
              <Dropzone
                // onDrop={handleDrop}
                onDrop={(acceptedFiles) => {
                  handleDrop(acceptedFiles);
                }}
                id="upload-image"
                accept="image/*"

                // minSize={1024}
                // maxSize={3072000}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => {
                  // additional CSS depends on dragging status
                  const additionalClass = isDragAccept
                    ? "accept"
                    : isDragReject
                    ? "reject"
                    : "";

                  return (
                    <div
                      {...getRootProps({
                        className: `dropzone ${additionalClass}`,
                      })}
                    >
                      <input {...getInputProps()} />
                      <span>{isDragActive}</span>
                      Upload
                    </div>
                  );
                }}
              </Dropzone>
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSaveCompanyLogo();
                props.onLogoClose();
              }}
            >
              Save
            </button>
          </Modal.Footer> */}
          <Modal.Footer>
            <Dropzone
              onDrop={(acceptedFiles) => {
                handleDrop(acceptedFiles);
              }}
              multiple={false}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <>
                    <div className="label d-flex align-items-center justify-content-center">
                      <Button
                        variant="secondary"
                        style={{
                          background: "transparent",
                          color: "black",
                        }}
                      >
                        Upload
                      </Button>
                    </div>
                  </>
                </div>
              )}
            </Dropzone>
            <Button
              className="btn btn-dark"
              style={{ background: "black" }}
              onClick={() => {
            //      setDeleteFlag(false);
            // setPreviewRequested(false);
                onSaveCompanyLogo();
              }}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default CompanyLogo;
