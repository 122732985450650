import React from "react";

const WhyShouldWeHire = ({ applicationDetails }) => {
  return (
    <div className="mt-4">
      <div className="shadow-box p-3">
        <div className="black-text fw-600 fs-16 pb-3">
          Why you should hire me
        </div>
        <p className="color-tertiary fs-12">
          {/* I am a person who is positive about every aspect of life. There are
          many things I like to do, to see, and to experience. I like to read, I
          like to write; I like to think, I like to dream; I like to talk, I
          like to listen. I like to see the sunrise in the morning, I like to
          see the moonlight at night; I like to feel the music flowing on my
          face, I like to smell the wind coming from the ocean. I like to look
          at the clouds in the sky with a blank mind, I like to do thought
          experiment when I cannot sleep in the middle of the night. I like
          flowers in spring, rain in summer, leaves in autumn, and snow in
          winter.{" "} */}
          {applicationDetails?.viewProfileResponse
            ?.additionalInfoProfileResponse?.aboutMe ||
            applicationDetails?.additionalInfoProfileResponse?.aboutMe}
        </p>
      </div>
    </div>
  );
};

export default WhyShouldWeHire;
