import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { STATUS_SUCCESS, USER_ID } from "../../../../config/localstorage_keys";
import { GENERAL_ERROR_MESSAGE } from "../../../../config/messages";
import {
  initialiseFormData,
  onFormFeildsChange,
  validateField,
} from "../../../../utils/form_validators";
import { getLocalStorage } from "../../../../utils/storage";
import { updateCandidateName } from "../../../../_services/candidate.service";
import "../styles.scss";
import close from "../../../../assests/icons/ic-invite-pg-close.svg";
import toaster from "./../../../../utils/toaster";

function ChangeUsernameModal(props) {
  const [showLoader, setShowLoader] = useState(false);
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [usernameModalShow, setUsernameModalShow] = useState(false);
  const [initials, setInitials] = useState("");
  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );

  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );

  const [usernameData, setUsernameData] = useState({
    firstName: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    lastName: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
  });

  const getInitialsLetter = async () => {
    const name = candidateDetails?.adminUserResponse?.name;
    console.log(name);
    if (name?.split(" ").length > 1) {
      let InitName =
        name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
        name?.split(" ")[1]?.charAt(0)?.toUpperCase();
      setInitials(InitName);
    } else {
      let InitName = name?.split(" ")[0]?.charAt(0)?.toUpperCase();
      setInitials(InitName);
    }
  };

  useEffect(() => {
    getInitialsLetter();
  }, [candidateDetails]);

  const populatedUsername = () => {
    const candidateName = candidateDetails?.adminUserResponse?.name;
    let firstName = candidateName?.split(" ")[0];
    let lastName = candidateName?.split(" ")[1];
    let copyUsernameData = { ...usernameData };
    copyUsernameData.firstName.valueText = firstName;
    copyUsernameData.lastName.valueText = lastName;
    setUsernameData(copyUsernameData);
  };
  const onUsernameSave = () => {
    setShowLoader(true);

    let isValid = true;
    Object.keys(usernameData)?.forEach((key) => {
      if (!validateField(key, usernameData, setUsernameData)) {
        isValid = false;
      }
    });

    if (isValid) {
      let fullname =
        usernameData?.firstName?.valueText +
        " " +
        usernameData?.lastName?.valueText;
      let usernameObject = {
        name: fullname,
      };
      updateCandidateName(usernameObject)
        .then((res) => {
          if (res?.data?.status === STATUS_SUCCESS) {
            const userId = getLocalStorage(USER_ID);
            if (userId) {
              saveCandidateDetails(userId);
            }
            toaster("success", "Name updated successfully.");
            // props?.setShowAddNumberModal(false);
          } else {
            toaster(
              "error",
              res?.data?.message ? res?.data?.message : GENERAL_ERROR_MESSAGE
            );
          }
        })
        .catch((err) => {
          toaster(
            "error",
            err?.data?.message ? err?.data?.message : GENERAL_ERROR_MESSAGE
          );
        });
      props?.setUsernameModalShow(false);
    } else {
      toaster("error", "All fields required");
      setShowLoader(false);
    }
    setShowLoader(false);
  };

  return (
    <Modal
      show={props?.usernameModalShow}
      onHide={() => {
        props?.setUsernameModalShow(false);
        initialiseFormData(usernameData, setUsernameData);
        console.log(initialiseFormData);
      }}
      className="lg-dialog-modal"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ borderBottom: "none" }} className="dialog-header">
        <Modal.Title className="dialog-title pt-3 ps-3 d-flex w-100 justify-content-between">
          <div>Name</div>
          <div className="position-relative">
            <img
              src={close}
              alt="close-icon"
              className="p-1 pt-1 pb-1 username-dialog-close pointer"
              onClick={() => {
                populatedUsername();
                props?.setUsernameModalShow(false);
              }}
            />
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        className=" py-3 m-0 "
      >
        <div className="ps-3">
          <label className="modalLabel mt-3">First Name*</label>
          <input
            defaultValue={usernameData?.firstName?.valueText}
            className={
              (usernameData?.firstName?.errorText ? "error" : "") +
              " form-control mt-2 modal-input"
            }
            required
            onChange={($event) => {
              onFormFeildsChange($event, usernameData, setUsernameData);
            }}
            name="firstName"
            maxLength={16}
          />
          {usernameData.firstName.errorText && (
            <div className="field-error mt-1">
              {usernameData.firstName.errorText}
            </div>
          )}

          <label className="modalLabel mt-4">Last Name*</label>
          <input
            defaultValue={usernameData?.lastName?.valueText}
            className={
              (usernameData?.lastName?.errorText ? "error" : "") +
              " form-control mt-2 modal-input"
            }
            required
            onChange={($event) => {
              onFormFeildsChange($event, usernameData, setUsernameData);
            }}
            name="lastName"
            maxLength={16}
          />
          {usernameData.lastName.errorText && (
            <div className="field-error mt-1">
              {usernameData.lastName.errorText}
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        style={{ borderTop: "none" }}
        className="username-buttons p-4 mt-3"
      >
        <Button
          variant="secondary"
          className="btn btn-cancel"
          onClick={() => {
            props?.setUsernameModalShow(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="btn btn-dark btn-save"
          onClick={() => {
            onUsernameSave();
          }}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangeUsernameModal;
