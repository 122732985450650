import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ProfileImage from "../../components/profile_image";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";

import Loader from "../../components/common/loader";
import { FORM_VALIDATION_MESSAGE } from "../../config/messages";
import { onFormFeildsChange, validateField } from "../../utils/form_validators";
import { checkPhoneNumberValid } from "../../utils/utils";

const CompanyNumber = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [mobileNumber, setMobileNumber] = useState(0);
  const [alternateNumber, setAlternateNumber] = useState();
  const [isError, setIsError] = useState({ mobileNumberError: "" });

  const [formData, setFormData] = useState({
    mobileNumber: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    alternateNumber: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
  });
  // const [formError, setFormError] = useState({
  //   mobileNumber: "",
  // });
  const checkMobileNumberValid = () => {
    let isValid = true;

    if (mobileNumber.length > 12 || mobileNumber == 0) {
      setIsError({ mobileNumberError: "Enter validate mobile number" });

      isValid = false;
    } else {
      setIsError({
        mobileNumberError: "",
      });
      isValid = true;
    }
    //   setFormData(formData);
    return isValid;
  };

  const saveNumber = {
    companyId: props.companyId,
    mobileNumber: mobileNumber,
    alternateNumber: alternateNumber,
  };

  const onSaveNumber = () => {
    if (mobileNumber === "" || mobileNumber == 0) {
      toaster("error", "Please Add Mobile number");
    } else if (checkMobileNumberValid()) {
      setShowLoader(true);
      create(saveNumber)
        .then((res) => {
          setShowLoader(false);
          toaster("success", "Phone number updated successfully..");
          props?.setApiRefresh(true);
          props.onNumberClose();
        })
        .catch((e) => {
          setShowLoader(false);
          props?.setApiRefresh(false);
        });
    }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.onNumberClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onNumberClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">
                Company Number
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body ">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600">
                  {" "}
                  Contact Number*{" "}
                </div>
                <div className="pt-3 pb-5">
                  <input
                    // value={formData?.mobileNumber?.valueText}
                    type="number"
                    // className="modal-form-input input-color-black"

                    className={
                      (mobileNumber === "" && props.mobileNumber === ""
                        ? "error"
                        : "") + " form-control"
                    }
                    // placeholder="+91 8800278211"
                    defaultValue={props.mobileNumber}
                    onChange={(e) => {
                      setMobileNumber(e.target.value);
                      // if (e.target.value.length > 10) {
                      //   setIsError(true);
                      // }
                    }}
                    placeholder="Add Number"
                  />
                  <p className="field-error mt-1">
                    {isError.mobileNumberError}
                  </p>
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600">
                  {" "}
                  Alternative Number{" "}
                </div>
                <div className="pt-3 pb-5">
                  <input
                    type="number"
                    className="modal-form-input input-color-black"
                    // placeholder="+44 5899098955"
                    defaultValue={props.alternateNumber}
                    onChange={(e) => {
                      setAlternateNumber(e.target.value);
                    }}
                    placeholder="Add Number"
                  />
                  <p className="field-error mt-1">
                    {isError.mobileNumberError}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onNumberClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSaveNumber();
                // props.onNumberClose();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyNumber;
