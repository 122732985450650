/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import IcDoneWhite from "../../../assests/icons/ic_done_white.svg";
import { StarRating } from "./star_rating_component";
import { useState } from "react";
import ProgressBarGraph from "../../../components/graph/progress_bar_graph";
import useWindowDimensions from "../../../utils/use_window_dimension";
import FunnelGraphComponent from "../../../components/graph/funnel_graph";
import toaster from "../../../utils/toaster";
import { isEmpty } from "../../../utils/form_validators";
import { feedback_List } from "../../../_services/job.service";
import { useParams } from "react-router";
import { getApplicationJobListing , getApplicationStatusWiseCount } from "../../../_services/view.service";

const InsightsComponent = (props) => {
  const [selectedSort, setSelectedSort] = useState();
  const [aplication_Insights, setApplication_Insights] = useState();
  console.log(selectedSort, "sort");

  let { jobId } = useParams();
  console.log(jobId, "jobid....");
  const sortArray = [{ name: "Low to High", value: "ASC" }, { name: "High to low", value: "DESC" }];

  const get_Applications_Insights = () => {
  getApplicationStatusWiseCount(1, 10, jobId)?.then((res) => {
    setApplication_Insights(res?.data?.data);
  });
};

  useEffect(() => {
    get_Applications_Insights();
  }, []);
  // const seikorAdminFunnelData = [
  //   {
  //     status: "Applied",
  //     count:
  //       plication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
  //       plication_Insights?.statusCount?.APPLIED !== ""
  //         ? plication_Insights?.statusCount?.APPLIED
  //         : 0,
  //     color: "black",
  //     rejected:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
  //         : 0,
  //     dropped:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
  //         : 0,
  //   },
  //   {
  //     status: "Shortlisted",
  //     count:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.SHORTLISTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.SHORTLISTED
  //         : 0,
  //     color: "blue",
  //     rejected:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
  //         : 0,
  //     dropped:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
  //         : 0,
  //   },
  //   {
  //     status: "Assessement",
  //     count:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
  //       seikorAdminOrgSummary?.statusWiseData?.ASSESSMENT !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.ASSESSMENT
  //         : 0,
  //     color: "yellow",
  //     rejected:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
  //         : 0,
  //     dropped:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
  //         : 0,
  //   },
  //   {
  //     status: "Offered",
  //     count:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("OFFERED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.OFFERED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.OFFERED
  //         : 0,
  //     color: "green",
  //     rejected:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
  //         : 0,
  //     dropped:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
  //         : 0,
  //   },
  //   {
  //     status: "Joined",
  //     count:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("JOINED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.JOINED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.JOINED
  //         : 0,
  //     color: "light-green",
  //     rejected:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
  //         : 0,
  //     dropped:
  //       seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
  //       seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
  //         ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
  //         : 0,
  //   },
  // ];
  const barData = [
    {
      entity: "Applied",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
          aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      color: "#1C1C1C",
    },
    {
      entity: "Shortlisted",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("SHORTLISTED") &&
          aplication_Insights?.statusCount?.SHORTLISTED !== ""
          ? aplication_Insights?.statusCount?.SHORTLISTED
          : 0,
      color: "#3D81FF",
    },
    {
      entity: "Assessment",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("ASSESSMENT") &&
          aplication_Insights?.statusCount?.ASSESSMENT !== ""
          ? aplication_Insights?.statusCount?.ASSESSMENT
          : 0,
      color: "#FFD027",
    },
    {
      entity: "Offered",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("OFFERED") &&
          aplication_Insights?.statusCount?.OFFERED !== ""
          ? aplication_Insights?.statusCount?.OFFERED
          : 0,
      color: "#12BD6B",
    },
    {
      entity: "Joined",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("JOINED") &&
          aplication_Insights?.statusCount?.JOINED !== ""
          ? aplication_Insights?.statusCount?.JOINED
          : 0,
      color: "#2FFF9B",
    },
    {
      entity: "Dropout",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
          aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
      color: "#EB1260",
    },
    {
      entity: "Rejected",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
          aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
      color: "#721212",
    },
  ];

  const seikorAdminBarData = [
    {
      entity: "Applied",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      color: "#1C1C1C",
    },
    {
      entity: "Shortlisted",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("SHORTLISTED") &&
        aplication_Insights?.statusCount?.SHORTLISTED !== ""
          ? aplication_Insights?.statusCount?.SHORTLISTED
          : 0,
      color: "#3D81FF",
    },
    {
      entity: "Assessment",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("ASSESSMENT") &&
        aplication_Insights?.statusCount?.ASSESSMENT !== ""
          ? aplication_Insights?.statusCount?.ASSESSMENT
          : 0,
      color: "#FFD027",
    },
    {
      entity: "Offered",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("OFFERED") &&
        aplication_Insights?.statusCount?.OFFERED !== ""
          ? aplication_Insights?.statusCount?.OFFERED
          : 0,
      color: "#12BD6B",
    },
    {
      entity: "Joined",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("JOINED") &&
        aplication_Insights?.statusCount?.JOINED !== ""
          ? aplication_Insights?.statusCount?.JOINED
          : 0,
      color: "#2FFF9B",
    },
    {
      entity: "Dropout",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
        aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
      color: "#EB1260",
    },
    {
      entity: "Rejected",
      entityValue:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
        aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
      color: "#721212",
    },
  ];

  const rolesPerformance = [
    {
      title: "Applied",
      value:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
          aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
    },
    {
      title: "Shortlisted",
      value:
        aplication_Insights?.statusCount?.hasOwnProperty("SHORTLISTED") &&
          aplication_Insights?.statusCount?.SHORTLISTED !== ""
          ? aplication_Insights?.statusCount?.SHORTLISTED
          : 0,
    },
    {
      title: "Assessment",
      value:
        aplication_Insights?.statusCount?.hasOwnProperty("ASSESSMENT") &&
          aplication_Insights?.statusCount?.ASSESSMENT !== ""
          ? aplication_Insights?.statusCount?.ASSESSMENT
          : 0,
    },
    {
      title: "Offered",
      value:
        aplication_Insights?.statusCount?.hasOwnProperty("OFFERED") &&
          aplication_Insights?.statusCount?.OFFERED !== ""
          ? aplication_Insights?.statusCount?.OFFERED
          : 0,
    },
    {
      title: "Joined",
      value:
        aplication_Insights?.statusCount?.hasOwnProperty("JOINED") &&
          aplication_Insights?.statusCount?.JOINED !== ""
          ? aplication_Insights?.statusCount?.JOINED
          : 0,
    },
    {
      title: "Dropped",
      value:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
          aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
    },
    {
      title: "Rejected",
      value:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
          aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
    },
  ];
  
  const funnelData = [
    {
      status: "Applied",
      mainCount:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      color: "black",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
        aplication_Insights?.statusCount?.REJECTEDAFTERAPPLIED !== ""
          ? aplication_Insights?.statusCount?.REJECTEDAFTERAPPLIED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
        aplication_Insights?.statusCount?.RETRACTEDAFTERAPPLIED !== ""
          ? aplication_Insights?.statusCount?.RETRACTEDAFTERAPPLIED
          : 0,
    },
    {
      status: "Shortlisted",
      mainCount:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("SHORTLISTED") &&
        aplication_Insights?.statusCount?.SHORTLISTED !== ""
          ? aplication_Insights?.statusCount?.SHORTLISTED
          : 0,
      color: "blue",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
        aplication_Insights?.statusCount?.REJECTEDAFTERSHORTLISTED !== ""
          ? aplication_Insights?.statusCount?.REJECTEDAFTERSHORTLISTED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
        aplication_Insights?.statusCount?.RETRACTEDAFTERSHORTLISTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTEDAFTERSHORTLISTED
          : 0,
    },
    {
      status: "Assessement",
      mainCount:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("ASSESSMENT") &&
        aplication_Insights?.statusCount?.ASSESSMENT !== ""
          ? aplication_Insights?.statusCount?.ASSESSMENT
          : 0,
      color: "yellow",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
        aplication_Insights?.statusCount?.REJECTEDAFTERASSESSMENT !== ""
          ? aplication_Insights?.statusCount?.REJECTEDAFTERASSESSMENT
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
        aplication_Insights?.statusCount?.RETRACTEDAFTERASSESSMENT !== ""
          ? aplication_Insights?.statusCount?.RETRACTEDAFTERASSESSMENT
          : 0,
    },
    {
      status: "Offered",
      mainCount:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("OFFERED") &&
        aplication_Insights?.statusCount?.OFFERED !== ""
          ? aplication_Insights?.statusCount?.OFFERED
          : 0,
      color: "green",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
        aplication_Insights?.statusCount?.REJECTEDAFTEROFFERED !== ""
          ? aplication_Insights?.statusCount?.REJECTEDAFTEROFFERED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
        aplication_Insights?.statusCount?.RETRACTEDAFTEROFFERED !== ""
          ? aplication_Insights?.statusCount?.RETRACTEDAFTEROFFERED
          : 0,
    },
    {
      status: "Joined",
      mainCount:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("JOINED") &&
        aplication_Insights?.statusCount?.JOINED !== ""
          ? aplication_Insights?.statusCount?.JOINED
          : 0,
      color: "light-green",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
        aplication_Insights?.statusCount?.REJECTEDAFTERJOINED !== ""
          ? aplication_Insights?.statusCount?.REJECTEDAFTERJOINED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
        aplication_Insights?.statusCount?.RETRACTEDAFTERJOINED !== ""
          ? aplication_Insights?.statusCount?.RETRACTEDAFTERJOINED
          : 0,
    },
  ];
console.log("funnelData",funnelData)
  const seikorAdminFunnelData = [
     {
      status: "Applied",
      mainCount:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("APPLIED") &&
        aplication_Insights?.statusCount?.APPLIED !== ""
          ? aplication_Insights?.statusCount?.APPLIED
          : 0,
      color: "black",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
        aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
        aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
    },
    {
      status: "Shortlisted",
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("SHORTLISTED") &&
          aplication_Insights?.statusCount?.SHORTLISTED !== ""
          ? aplication_Insights?.statusCount?.SHORTLISTED
          : 0,
      color: "blue",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
          aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
          aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
    },
    {
      status: "Assessement",
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("ASSESSMENT") &&
          aplication_Insights?.statusCount?.ASSESSMENT !== ""
          ? aplication_Insights?.statusCount?.ASSESSMENT
          : 0,
      color: "yellow",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
          aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
          aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
    },
    {
      status: "Offered",
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("OFFERED") &&
          aplication_Insights?.statusCount?.OFFERED !== ""
          ? aplication_Insights?.statusCount?.OFFERED
          : 0,
      color: "green",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
          aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
          aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
    },
    {
      status: "Joined",
      count:
        aplication_Insights?.statusCount?.hasOwnProperty("JOINED") &&
          aplication_Insights?.statusCount?.JOINED !== ""
          ? aplication_Insights?.statusCount?.JOINED
          : 0,
      color: "light-green",
      rejected:
        aplication_Insights?.statusCount?.hasOwnProperty("REJECTED") &&
          aplication_Insights?.statusCount?.REJECTED !== ""
          ? aplication_Insights?.statusCount?.REJECTED
          : 0,
      dropped:
        aplication_Insights?.statusCount?.hasOwnProperty("RETRACTED") &&
          aplication_Insights?.statusCount?.RETRACTED !== ""
          ? aplication_Insights?.statusCount?.RETRACTED
          : 0,
    },
  ];
  console.log("seikorAdminFunnelData",seikorAdminFunnelData)
  const [feedbackData, setfeedbackData] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [sortBy, setSortBy] = useState("");

  const [pageSize, setpageSize] = useState(3);
  console.log("pagesize---->", pageSize);
  const addPage = () => {
    // alert("hiii")
    // setShowLoader(true);
    setpageSize(pageSize + 3);
    feedbackList();
    // setShowLoader(false);
  };
  // const sortList = () => {
  //   if (selectedSort === "Low to High") {
  //         setSelectedSort("ASC");
  //     feedbackList();
  //     } else {
  //      setSelectedSort("DSC");
  //     feedbackList();
  //   }
  //   setSelectedSort(selectedSort);
  // };

  console.log(sortBy, "selectedSort---->");
  const feedbackList = (sortBy) => {
    setShowLoader(true);
    feedback_List(1, pageSize, jobId, sortBy).then(
      (res) => {
        // setShowLoader(true);
        // setpageSize(pageSize );
        // setShowLoader(false);
        if (!isEmpty(res)) {
          setfeedbackData(res?.data);
          // setpageSize(pageSize );
          // addPage();
        } else {
          setfeedbackData([]);
          setShowLoader(false);
        }
      },
      (error) => {
        toaster("error", error);
        setShowLoader(false);
      }
    );
  };
  useEffect(() => {
    feedbackList();
    // addPage();
  }, []);

  return (
    <div className="pt-3">
      <div className="d-flex">
        <div className="col-lg-1"></div>
        <div className="col-lg-10 col-12">
          <div className="color-primary fw-700 fs-20">
            Feedback from applicants
          </div>
          <div className="pt-3">
            <div className="d-flex gap-2">
              {sortArray?.map((sort, index) => {
                return (
                  <div
                    className="insight-filter py-1 d-flex justify-content-center"
                    key={index}
                    // onClick={() => {
                    //sortList(sort)}
                    onClick={() => {
                      if (sort === "Low to High") {
                        feedbackList(sort.value);
                        setSelectedSort(sort.name);
                      } else {
                        feedbackList(sort.value);
                        setSelectedSort(sort.name);
                      }
                    }}
                  >
                    <div
                      className={
                        // selectedValue === selectedSort
                        selectedSort === sort.name
                          ? "px-1 pt-0 pb-0 bg-black rounded-circle me-1 d-block"
                          : "d-none"
                      }
                    >
                      <img src={IcDoneWhite} alt="whitedone-icon" />
                    </div>
                    <div className="color-primary">{sort.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="pt-3 w-100">
            {feedbackData.length > 0 &&
              feedbackData?.map((card, index) => {
                return (
                  <div
                    className={
                      index > 0
                        ? "insights-cards w-100 p-3 mt-3"
                        : "insights-cards w-100 p-3"
                    }
                    key={index}
                  >
                    <div className="d-flex gap-2">
                      <div className="p-1 pt-0">
                        {card?.candidateProfilePicUrl === "" ||
                          card?.candidateProfilePicUrl === null ? (
                          <img
                            src={profileImg}
                            alt="profile-image"
                            className="rounded-circle"
                            height="48px"
                            width="48px"
                          />
                        ) : (
                          <img
                            src={
                              process.env.REACT_APP_IMAGE_BASEURL +
                              card?.candidateProfilePicUrl
                            }
                            alt="profile-image"
                            className="rounded-circle"
                            height="48px"
                            width="48px"
                          />
                        )}
                      </div>
                      <div>
                        <div className="medium-black-text fs-14 fw-600 text-decoration-underline">
                          {card?.candidateName}
                        </div>
                        <div className="color-tertiary fs-11 fw-300 pt-0">
                          <div>{card?.candidateRole} </div>
                          <div>{card?.candidateCompany}</div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="d-flex gap-3">
                      <div className="medium-black-text fs-14 fw-600 ">
                        {card?.rating}/5
                      </div>
                      <div>
                        <StarRating
                          totalStars={5}
                          rating={card?.rating}
                          // rating={props?.editReferral ? props?.ratingStar : rating}
                          customClasses={"gap-2 mb-2"}
                        />
                      </div>
                    </div>
                    <div className="color-tertiary fs-11 fw-300 pt-2">
                      {card?.candidateFeedback}
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="d-flex justify-content-center mt-3">
            <div
              className="insight-filter py-1 d-flex justify-content-center pointer"
              onClick={() => {addPage()}}
            >
              Load more
            </div>
          </div>
          <div className="pt-3">
            <div className="color-primary fw-700 fs-20">
              Application Insights
            </div>
            <div className="insights-cards w-100 p-3 mt-3">
              <div className="p-2">
                <div className="d-flex justify-content-between flex-wrap mt-3 org-roles-performance">
                  {rolesPerformance.map((obj) => (
                    <>
                      <div className="ps-3 stats">
                        <div className="fw-400 fs-32">{obj.value}</div>
                        <div className="fw-400 fs-16 color-tertiary pt-3">
                          {obj.title}
                        </div>
                      </div>
                    </>
                  ))}
                </div>
                <ProgressBarGraph
                  data={barData}
                  seikorAdminBarData={seikorAdminBarData}
                  height={"22"}
                  showLegends={true}
                />
                <div className="mt-5 d-md-block d-none">
                  <div className="fs-16 fw-600 medium-black-text">
                    REJECTION & DROPOUT FUNNEL
                  </div>
                  <div>
                    {/* funnel part add here */}
                    <FunnelGraphComponent mainData={funnelData} seikorAdminFunnelData={seikorAdminFunnelData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>
    </div>
  );
};

export default InsightsComponent;
