import React, { useRef, useEffect, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";
import AddImageLogo from "../../../assests/icons/ic-add-filled.svg";
import SortBy from "../../../assests/ic_sort.svg";
import pinUnfilled from "../../../assests/icons/ic_pin.svg";
import pinFilled from "../../../assests/icons/ic_pin_filled.svg";
import RightArrow from "../../../assests/Vector.svg";
import JobPostsCard from "../../../components/JobPostsCard";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AddLogoWhite from "../../../assests/addLogoWhite.svg";
import dropdownArrow from "../../../assests/downArrow.svg";
import upArrow from "../../../assests/upArrow.svg";

import filterLogo from "../../../assests/filterLogo.svg";
import tick from "../../../assests/icons/ic-combo-tick.svg";
import {
  APPLICATIONS_MAIN_PAGE_ROUTE,
  JOB_CREATE_TEMPLATE_PAGE,
} from "../../../config/page_routes_constants";
import CreateNewJobPostModal from "../../../dialogs/jobs_dialogs/create-new-job-modal";
import CopyPreviousJobPostModal from "../../../dialogs/jobs_dialogs/copy-previous-job-modal";
import CreateFromTemplateModal from "../../../dialogs/jobs_dialogs/create-from-template-modal";
import { SORT } from "../../../config/filter_constants";
import SortByFilterModal from "../../../dialogs/jobs_dialogs/sort-by-filter-modal";
import AllFiltersMobileModal from "../../../dialogs/jobs_dialogs/all_filters_mobile";
import {
  getjobSummary,
  getJobTemplate,
  getTeams,
  pinIconJob,
} from "../../../_services/view.service";
import { checkedItemsHandler } from "../../../utils/utils";
import "./job-post-style.scss";
import useWindowDimensions from "../../../utils/use_window_dimension";
import { JOB_POST_CREATE_PAGE_ROUTE } from "./../../../config/page_routes_constants";

import Loader from "../../../components/common/loader";
import { DateRange } from "react-date-range";
import { STATUS_SUCCESS, USER_ROLE } from "../../../config/be_api_constants";
import pinIcon from "../../../assests/icons/ic_pin.svg";
import toaster from "../../../utils/toaster";
import DatePickerModal from "../../../dialogs/date_picker_modal";
import InfiniteScroll from "react-infinite-scroll-component";
import { DEFAULT_PAGE_SIZE } from "../../../config/be_seikorAdmin_api_constants";
import { isEmpty } from "../../../utils/form_validators";
import { useStoreActions } from "easy-peasy";

const JobPostPage = () => {
  const setIsShowScratchPreviewBtn = useStoreActions(
    (action) => action?.setIsShowScratchPreviewBtn
  );
  let isInfinite = false;
  const initialFilterData = {
    pageNo: 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };
  const [allJobsPostFilterDataParams, setAllJobsPostFilterDataParams] =
    useSearchParams({
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });

  const [allJobsPostFilterData, setAllJobsPostFilterData] =
    useState(initialFilterData);
  const [currentPageset, setCurrentPageSet] = useState(0);
  const [dataCount, setDataCount] = useState(0);
  // CURRENT DATE
  const current = new Date();
  // current.setMonth(current.getMonth() - 3);

  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const date = `${yyyy}-${mm}-${dd}`;
  // console.log("give me todays date", date);

  // PAST 1 MONTHS
  const past1Months = new Date();
  past1Months.setMonth(past1Months.getMonth() - 1);
  var dd = String(past1Months.getDate()).padStart(2, "0");
  var mm = String(past1Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past1Months.getFullYear();
  const month1 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month1);

  // PAST 3 MONTHS
  const past3Months = new Date();
  past3Months.setMonth(past3Months.getMonth() - 3);
  var dd = String(past3Months.getDate()).padStart(2, "0");
  var mm = String(past3Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past3Months.getFullYear();
  const month3 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month3);

  // PAST 6 MONTHS
  const past6Months = new Date();
  past6Months.setMonth(past6Months.getMonth() - 6);
  var dd = String(past6Months.getDate()).padStart(2, "0");
  var mm = String(past6Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past6Months.getFullYear();
  const month6 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month6);

  // PAST 12 MONTHS
  const past12Months = new Date();
  past12Months.setMonth(past12Months.getMonth() - 12);
  var dd = String(past12Months.getDate()).padStart(2, "0");
  var mm = String(past12Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past12Months.getFullYear();
  const month12 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month12);

  const [showCreateJobPostModal, setShowCreateJobPostModal] = useState(false);
  const [arraySortIndex, setArraySortIndex] = useState(null);
  const [arrayAllTeamsIndex, setArrayAllTeamsIndex] = useState([]);
  const [arrayAllStatusIndex, setArrayAllStatusIndex] = useState([]);
  const [arrayAllTimeIndex, setArrayAllTimeIndex] = useState(null);
  const [postByArrayIndex, setPostByArrayIndex] = useState(null);
  const [showSortByFilter, setShowSortByFilter] = useState(false);
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [showModalWithType, setShowModalWithType] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [pinJobs, setPinJobs] = useState(true);
  const navigate = useNavigate();
  const CUSTOM_DATE_OPTION = "Custom";

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const [currentDate, setCurrentDate] = useState();
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");

  const [displayDatePicker, setDisplayDatePicker] = useState("d-none");

  useEffect(() => {
    const todaysDate = new Date();
    setCurrentDate(todaysDate);
  }, []);

  const showSelectedDate = (startDt, endDt) => {
    if (startDt) {
      const startDate = startDt?.toString()?.slice(4, 16);
      setCustomStartDate(startDate);
    }
    if (endDt) {
      const endDate = endDt?.toString()?.slice(4, 16);
      setCustomEndDate(endDate);
    }
  };

  const handleDateRange = (event) => {
    setDateRange([event?.selection]);
    let startDate = event?.selection?.startDate;
    let endDate = event?.selection?.endDate;
    showSelectedDate(startDate, endDate);
  };

  const onCustomDateSelect = (name) => {
    if (name == CUSTOM_DATE_OPTION) {
      setDisplayDatePicker("d-block");
    } else {
      setDisplayDatePicker("d-none");
    }
  };

  const role = localStorage.getItem("role");
  const dropdownSortArray = [
    { name: "New Applications First", value: "NEW_APPLICATION_FIRST" },
    { name: "New Referrals First", value: "NEW_REFERRALS_FIRST" },
    { name: "Pending Screening", value: "PENDING_SCREENING" },
    { name: "Shortlisted", value: "SHORTLIST_COUNT" },
    { name: "Status", value: "JOB_STATUS" },
    { name: "Most Applications", value: "MOST_APPLICATION" },
    { name: "Most Views", value: "MOST_VIEWS" },
    { name: "Most Referrals", value: "MOST_REFERRALS" },
    { name: "A-Z", value: "A_Z" },
  ];
  const dropdownAllTimeArray = [
    { name: "All Time", value: " " },
    { name: "Last 1 Month", value: month1 },
    { name: "Last 3 Months", value: month3 },
    { name: "Last 6 Months", value: month6 },
    { name: "Last 12 Months", value: month12 },
    { name: CUSTOM_DATE_OPTION, value: CUSTOM_DATE_OPTION },
  ];
  const dropdownAllStatusArray = [
    // { name: "All Status", value: "ALL_STATUS" },
    { name: "All Status", value: "LIVE, PAUSED, FILLED,CLOSED" },
    { name: "Live", value: "LIVE" },
    // { name: "Paused", value: "PAUSED" },
    { name: "Filled", value: "FILLED" },
    { name: "Closed", value: "CLOSED" },
  ];
  const dropdownPostByArray = [
    "All",
    "Me",
    "Business Profile Name 1",
    "Business Profile Name 2",
    "Business Profile Name 3",
    "Business Profile Name 4",
  ];
  const [upDownArrowView, setUpDownArrowView] = useState(true);
  const [upDownArrowApplication, setUpDownArrowApplication] = useState(true);
  const [upDownArrowReferrals, setUpDownArrowReferrals] = useState(true);
  const [upDownArrowShort, setUpDownArrowShort] = useState(true);
  const [firstDate, setfirstDate] = useState();
  const [secondDate, setsecondDate] = useState();
  const [upDownArrowAssess, setUpDownArrowAssess] = useState(true);
  const [upDownArrowPendingScreeen, setUpDownArrowPendingScreeen] =
    useState(true);

  const dropdownSortHandler = (index, value) => {
    setArraySortIndex(index);
    setAllJobsPostFilterData({
      ...allJobsPostFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    fetchAllJobs({
      ...allJobsPostFilterData,
      sortBy: value,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };
  const Short_Des = () => {};
  const dropdownAllTimeHandler = (index, value) => {
    if (arraySortIndex == index) {
      setArrayAllTimeIndex(null);
      setAllStatusDropdownRedDot("d-none");
      fetchAllJobs({
        ...allJobsPostFilterData,
        fromDate: null,
        toDate: null,
        pageNo: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
    } else {
      if (value === CUSTOM_DATE_OPTION) {
        if (customStartDate == "" || customEndDate == "") {
          setAllStatusDropdownRedDot("d-none");
          
        }
        onCustomDateSelect(value);
      } else {
        setArraySortIndex(index);

        setAllStatusDropdownRedDot("d-block");
      }
    }
    if (arraySortIndex == index) {
      setArraySortIndex(null);
    } else {
      fetchAllJobs({
        ...allJobsPostFilterData,
        fromDate: value,
        toDate: date,
        pageNo: 1,
        pageSize: DEFAULT_PAGE_SIZE,
      });
    }
    setArraySortIndex(value);
    setAllJobsPostFilterData({
      ...allJobsPostFilterData,
      fromDate: value,
      toDate: date,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

  const [teamArray, setTeamArray] = useState([]);
  const [allStatus, setAllStatus] = useState([]);

  const dropdownAllTeamsHandler = (index) => {
    checkedItemsHandler(index, arrayAllTeamsIndex, setArrayAllTeamsIndex);
  };

  const checkAllTeamsHandler = (condition, item) => {
    if (condition) {
      setTeamArray([...teamArray, item?.id]);
    } else {
      let index = teamArray.indexOf(item?.id);
      if (index >= 0) {
        // let copyObj = [...teamArray];
        // delete copyObj[index];
        setTeamArray([
          ...teamArray.splice(0, index),
          ...teamArray.splice(index + 1),
        ]);

        // setTeamArray(copyObj);
      }
    }
  };

  const dropdownAllStatusHandler = (index) => {
    checkedItemsHandler(index, arrayAllStatusIndex, setArrayAllStatusIndex);
  };

  const checkAllStatusHandler = (condition, value) => {
    if (condition) {
      setAllStatus([...allStatus, value]);
    } else {
      let index = allStatus.indexOf(value);
      if (index >= 0) {
        // let copyObj = [...allStatus];
        // delete copyObj[index];
        setAllStatus([
          ...allStatus.splice(0, index),
          ...allStatus.splice(index + 1),
        ]);

        // setAllStatus(copyObj);
      }
    }
  };
  const [allTemplateDetails, setAllTemplateDetails] = useState([]);
  const [jobslist, setJobslist] = useState([]);

  const fetchAllJobs = (data) => {
    setShowLoader(true);
    getjobSummary(data).then(
      (res) => {
        if (res && !isEmpty(res?.data?.data)) {
          setDataCount(res?.data?.totalRecord);
          let data;
          if (!isInfinite) {
            data = res?.data?.data;
          } else {
            data = [...jobslist, ...res?.data?.data];
          }
          setCurrentPageSet(data?.length);
          setJobslist(data);
          setShowLoader(false);
        } else if (isEmpty(res) && res?.status === 200) {
          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      },
      (error) => {
        setShowLoader(false);
      }
    );
  };

  const teamStatus = () => {
    getTeams({ pageNo: 0, pageSize: 0 })
      .then((res) => {
        setTeamData(res?.data?.data);
      })
      .catch((err) => {
        toaster("Error", err);
      });
  };

  const fetchMoreJobs = () => {
    setCurrentPageSet(currentPageset + 10);
    isInfinite = true;

    fetchAllJobs({
      ...allJobsPostFilterData,
      pageNo: allJobsPostFilterData?.pageNo + 1,
    });
    setAllJobsPostFilterData({
      ...allJobsPostFilterData,
      pageNo: allJobsPostFilterData?.pageNo + 1,
    });
  };
  const getJobPostData = () => {
    let temp = { ...initialFilterData };
    for (let entry of allJobsPostFilterDataParams.entries()) {
      if (entry[0] === "searchString") {
        temp = {
          ...temp,
          searchString: entry[1],
        };
      }
    }
    setAllJobsPostFilterData(temp);
    fetchAllJobs(temp);
  };
  useEffect(() => {
    // setcurrentPageset(allJobFilterData?.pageNo);
    // fetchMoreData();
    getJobPostData();
  }, [teamArray]);

  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      setAllJobsPostFilterDataParams(allJobsPostFilterData);
      fetchAllJobs(allJobsPostFilterData);
    }
  };

  const [apireferesh, setApiRefresh] = useState(false);

  useEffect(() => {
    teamStatus();
  }, [arrayAllStatusIndex, arrayAllTeamsIndex]);

  const [sortByDropdownRedDot, setSortByDropdownRedDot] = useState("d-none");
  const [timeIntervalDropdownRedDot, setTimeIntervalDropdownRedDot] =
    useState("d-none");
  const [allTeamsDropdownRedDot, setAllTeamsDropdownRedDot] =
    useState("d-none");
  const [allStatusDropdownRedDot, setAllStatusDropdownRedDot] =
    useState("d-none");
  const [allFiltersDropdownRedDot, setAllFiltersDropdownRedDot] =
    useState(false);

  useEffect(() => {
    if (arraySortIndex == null) {
      setSortByDropdownRedDot("d-none");
    } else {
      setSortByDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  // useEffect(() => {
  //   if (arrayAllTimeIndex == null) {
  //     setTimeIntervalDropdownRedDot("d-none");
  //   } else {
  //     setTimeIntervalDropdownRedDot("d-block");
  //   }
  // }, [arrayAllTimeIndex]);

  useEffect(() => {
    if (arrayAllTeamsIndex?.length > 0) {
      setAllTeamsDropdownRedDot("d-block");
    } else {
      setAllTeamsDropdownRedDot("d-none");
    }
  }, [arrayAllTeamsIndex]);

  useEffect(() => {
    if (arrayAllStatusIndex?.length > 0) {
      setAllStatusDropdownRedDot("d-block");
    } else {
      setAllStatusDropdownRedDot("d-none");
    }
  }, [arrayAllStatusIndex]);

  useEffect(() => {
    if (
      arrayAllTimeIndex != null ||
      postByArrayIndex != null ||
      arrayAllStatusIndex?.length != 0 ||
      arrayAllTeamsIndex?.length != 0
    ) {
      setAllFiltersDropdownRedDot(true);
    } else {
      setAllFiltersDropdownRedDot(false);
    }
  }, [
    arrayAllTimeIndex,
    postByArrayIndex,
    arrayAllStatusIndex,
    arrayAllTeamsIndex,
  ]);

  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    if (winWidth > 1500) {
      return 955 + "px";
    } else if (winWidth > 1300 && winWidth > 1499) {
      return winWidth - 420 + "px";
    } else if (winWidth < 1300 && winWidth > 991) {
      return winWidth - 400 + "px";
    } else if (winWidth < 990 && winWidth > 768) {
      return winWidth - 80 + "px";
    } else if (winWidth < 767 && winWidth > 551) {
      return winWidth - 110 + "px";
    } else if (winWidth < 550) {
      return winWidth - 110 + "px";
    }
  };

  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  const editPreJobs = (value, PreJobId) => {
    localStorage.setItem("EditKeys", value);
    navigate("/" + JOB_POST_CREATE_PAGE_ROUTE + "/" + PreJobId);
  };

  const pinJobIcon = (jobId, pinJobs) => {
    setShowLoader(true);
    setApiRefresh(true);
    pinIconJob(jobId, pinJobs)
      .then((res) => {
        setShowLoader(false);
        if (res?.data?.status === STATUS_SUCCESS) {
          toaster("success", res.data.message);
          setShowLoader(false);
          setApiRefresh(true);
          getJobPostData();
        }
      })
      .catch((err) => {
        setShowLoader(false);
        setApiRefresh(false);
        toaster("error", err);
      });
  };

  return (
    <div className="py-4">
      {showLoader && <Loader />}

      <div className="pt-4 content-margin">
        <div className="justify-content-between align-items-center col-lg-12  d-flex flex-wrap">
          <p className="fs-20 fw-700   mt-sm-2 mb-2 ">Job Posts({dataCount})</p>
          <div className="button d-flex flex-wrap gap-2 ">
            <Link
              to={"/" + JOB_CREATE_TEMPLATE_PAGE}
              className=" text-decoration-none btn text-dark  h-40 align-items-center gap-2 rounded-pill fs-14 fw-700 border border-dark d-flex"
            >
              <img alt="" src={AddImageLogo} />
              <span>New Job Template</span>
            </Link>
            <button
              className="new-job-post mb-2 btn btn-primary rounded-pill h-40 align-items-center d-flex gap-2"
              onClick={() => {
                setShowCreateJobPostModal(true);
              }}
            >
              <img alt="" height="20px" width="20px" src={AddLogoWhite} />
              <span className="fs-14 fw-700">New Job Post</span>
            </button>
          </div>
        </div>
        <hr className="mt-2 mb-0" />
        <div className="d-flex flex-wrap justify-content-between gap-3 mt-md-3">
          <div className="d-flex flex-wrap gap-2 ">
            <Dropdown className="filter-button d-none d-md-flex h-28 ">
              <Dropdown.Toggle
                variant="none"
                id="dropdown-basic"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
              >
                <img src={SortBy} alt="" />
                <span className="fs-12 fw-400">Sort By</span>
                <span
                  className={`${sortByDropdownRedDot} round-red-dot rounded-circle ms-2 mb-2`}
                ></span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary ">
                {dropdownSortArray?.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex pb-3"
                      onClick={() => {
                        if (arraySortIndex == index) {
                          dropdownSortHandler(null);
                        } else {
                          dropdownSortHandler(index, sortItem.value);
                        }
                      }}
                    >
                      <img
                        src={tick}
                        alt="blackdone-icon"
                        className={
                          arraySortIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          arraySortIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>

            {role === USER_ROLE.BUSINESS_ADMIN && (
              <Dropdown
                className="filter-button d-none d-md-flex h-28"
                autoClose="outside"
              >
                <Dropdown.Toggle
                  variant="multiple"
                  id="dropdown-autoclose-false"
                  className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
                >
                  <img src={SortBy} alt="" />
                  <span className="fs-12 fw-400">All Teams</span>
                  <span
                    className={`${allTeamsDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                  ></span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="fs-12 text-secondary ">
                  <ul
                    className="m-0 p-0 overflow-auto"
                    style={{ maxHeight: "300px" }}
                  >
                    {teamData?.map((sortItem, index) => {
                      return (
                        <Dropdown.Item key={index}>
                          <div
                            className="d-flex pb-3"
                            style={{ zIndex: 1000 }}
                            onClick={() => {
                              dropdownAllTeamsHandler(index);
                            }}
                          >
                            <input
                              type="checkbox"
                              className="me-2"
                              id={index}
                              name={index}
                              checked={
                                arrayAllTeamsIndex.includes(index)
                                  ? true
                                  : false
                              }
                              // onClick={() => {
                              //   dropdownAllTeamsHandler(index, sortItem.id);
                              // }}
                              onChange={(e) => {
                                checkAllTeamsHandler(
                                  e?.target?.checked,
                                  sortItem
                                );
                              }}
                            />
                            <label htmlFor={index}>{sortItem.team}</label>
                          </div>
                        </Dropdown.Item>
                      );
                    })}
                  </ul>
                  <div clasname="mx-4">
                    <button
                      className="btn gap-2 rounded fs-14 fw-700 border border-dark mx-2 w-90"
                      onClick={() => {
                        fetchAllJobs({
                          ...allJobsPostFilterData,
                          teamId: teamArray,
                          pageNo: 1,
                          pageSize: DEFAULT_PAGE_SIZE,
                        });
                      }}
                    >
                      <span className="fs-12 fw-700 m-2">Apply</span>
                    </button>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Dropdown
              className="filter-button d-none d-md-flex h-28"
              autoClose="outside"
            >
              <Dropdown.Toggle
                variant="none"
                id="dropdown-autoclose-false"
                className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
              >
                <img src={SortBy} alt="" />
                <span className="fs-12 fw-400">All status</span>
                <span
                  className={`${allStatusDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                ></span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="fs-12 text-secondary ">
                {dropdownAllStatusArray.map((sortItem, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      className="d-flex pb-3"
                      onClick={() => {
                        dropdownAllStatusHandler(index);
                      }}
                    >
                      <div
                        className="d-flex pb-3"
                        style={{ zIndex: 1000 }}
                        onClick={() => {
                          dropdownAllStatusHandler(index);
                        }}
                      >
                        <input
                          type="checkbox"
                          className="me-2"
                          checked={
                            arrayAllStatusIndex.includes(index) ? true : false
                          }
                          onChange={(e) => {
                            checkAllStatusHandler(
                              e?.target?.checked,
                              sortItem.value
                            );
                          }}
                          // onChange={(e) => {
                          //   if (!isEmpty(allStatus)) {
                          //     const job_Status = [...allStatus];
                          //     let sortItem = sortItem.value;
                          //     if (job_Status.includes(sortItem)) {
                          //       let itemIndex = allStatus?.findIndex(
                          //         (im) => im === sortItem.value
                          //       );
                          //       job_Status.splice(itemIndex, 1);
                          //     } else {
                          //       job_Status.push(sortItem.value);
                          //     }
                          //     setAllStatus(job_Status);
                          //   } else {
                          //     setAllStatus([sortItem.value]);
                          //   }
                          // }}
                        />{" "}
                        <label>{sortItem.name}</label>
                      </div>
                    </Dropdown.Item>
                  );
                })}
                <div clasname="mx-4">
                  <button
                    className="btn gap-2 rounded fs-14 fw-700 border border-dark mx-2 w-90"
                    onClick={() => {
                      fetchAllJobs({
                        ...allJobsPostFilterData,
                        jobStatus: allStatus,
                        pageNo: 1,
                        pageSize: DEFAULT_PAGE_SIZE,
                      });
                    }}
                  >
                    <span className="fs-12 fw-700 m-2">Apply</span>
                  </button>
                </div>
              </Dropdown.Menu>
            </Dropdown>
            <div className="position-relative">
              <div
                className={`${displayDatePicker} custome-date-box custome-date-box-position p-3`}
              >
                <DateRange
                  ranges={dateRange}
                  onChange={(e) => {
                    handleDateRange(e);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  maxDate={currentDate}
                />
                <div className="d-flex justify-content-end">
                  <button
                    style={{ border: "1px solid black", width: "80px" }}
                    className="btn bnt-light color-dark fs-14 p-0"
                    onClick={() => {
                      setDisplayDatePicker("d-none");
                      dropdownAllTimeHandler(null);
                      setTimeIntervalDropdownRedDot("d-none");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ border: "1px solid black", width: "80px" }}
                    className="btn bnt-light color-dark fs-14 p-0 ms-2 "
                    onClick={() => {
                      setDisplayDatePicker("d-none");
                      setTimeIntervalDropdownRedDot("d-block");
                      dropdownAllTimeHandler(null);
                      setArraySortIndex(5);
                      // fetchAllJobs({
                      //   ...allJobsPostFilterData,
                      //   fromDate: firstDate,
                      //   toDate: secondDate,
                      //   pageNo: 1,
                      //   pageSize: DEFAULT_PAGE_SIZE,
                      // });
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>

              <Dropdown
                className="filter-button d-none d-md-flex h-28"
                style={{ width: "fit-content" }}
              >
                <Dropdown.Toggle
                  variant="none"
                  id="dropdown-basic"
                  className="d-flex p-0 m-0 border-0 bg-transparent down-arrow filter-button"
                >
                  <img src={SortBy} alt="" />
                  <span className="fs-12 fw-400">All Time</span>
                  <span
                    className={`${timeIntervalDropdownRedDot} round-red-dot rounded-circle ms-2 mb-2`}
                  ></span>
                </Dropdown.Toggle>

                <Dropdown.Menu className="fs-12 text-secondary ">
                  {dropdownAllTimeArray.map((sortItem, index) => {
                    return (
                      <Dropdown.Item
                        key={index}
                        className="d-flex pb-3"
                        onClick={() => {
                          if (arrayAllTimeIndex == index) {
                            dropdownAllTimeHandler(index, sortItem?.value);
                            setTimeIntervalDropdownRedDot("d-none");

                            if (sortItem == CUSTOM_DATE_OPTION) {
                              onCustomDateSelect(sortItem);
                            }
                          } else {
                            if (sortItem == CUSTOM_DATE_OPTION) {
                              if (
                                customStartDate == "" ||
                                customEndDate == ""
                              ) {
                                setTimeIntervalDropdownRedDot("d-none");
                              }
                              onCustomDateSelect(sortItem);
                            } else {
                              dropdownAllTimeHandler(index, sortItem.value);
                              setTimeIntervalDropdownRedDot("d-block");
                            }
                          }
                        }}
                      >
                        <div>
                          <div className="d-flex">
                            <img
                              src={tick}
                              alt="blackdone-icon"
                              className={
                                arrayAllTimeIndex === index
                                  ? "pe-2 d-block"
                                  : "d-none pe-1"
                              }
                            />
                            <span
                              className={
                                arrayAllTimeIndex === index
                                  ? "ps-0 color-primary fw-700"
                                  : "ps-4"
                              }
                            >
                              {sortItem.name}
                            </span>
                          </div>

                          {sortItem == CUSTOM_DATE_OPTION &&
                          arrayAllTimeIndex == 5 &&
                          customStartDate != "" &&
                          customEndDate != "" ? (
                            <div className="small-text-gray fs-12 mt-2">
                              {`${customStartDate} to ${customEndDate}`}
                            </div>
                          ) : null}
                        </div>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="page-search d-flex col-12 col-md-3 col-lg-3 h-32">
            <input
              className=" job-post-search col-12 h-32"
              type="text"
              placeholder="Search"
              defaultValue={allJobsPostFilterData?.searchString}
              onChange={(event) => {
                setAllJobsPostFilterData({
                  ...allJobsPostFilterData,
                  searchString: event.target.value,
                  pageNo: 1,
                  pageSize: DEFAULT_PAGE_SIZE,
                });
              }}
              onKeyDown={(e) => submitFormOnEnter(e)}
            />
          </div>
        </div>

        {/* Table start */}

        <div
          style={{
            width: getHorizotalScrollWidth(hrSrlDiamension.width),
          }}
          className="table-responsive"
        >
          <InfiniteScroll
            dataLength={currentPageset}
            next={() => fetchMoreJobs()}
            hasMore={dataCount > currentPageset}
            className="container-fluid"
          >
            <table className="table mt-4 justify-content-between col-lg-12 d-none d-lg-block">
              <thead className="border-bottom">
                <tr className="">
                  <th className="border-0 fs-12 fw-700 pb-4">Title</th>
                  <th className=" border-0 fs-12 fw-700 ">
                    <span
                      className=" m-0 d-flex fs-12 fw-700 pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Views
                      {upDownArrowView ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_VIEWS_ASC");
                            setUpDownArrowView(!upDownArrowView);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_VIEWS");
                            setUpDownArrowView(!upDownArrowView);
                          }}
                        ></img>
                      )}
                    </span>
                  </th>
                  <th className="border-0 fs-12 fw-700">
                    <span
                      className=" m-0 d-flex fs-12 fw-700 pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Applications
                      {upDownArrowApplication ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_APPLICATION_ASC");
                            setUpDownArrowApplication(!upDownArrowApplication);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_APPLICATION");
                            setUpDownArrowApplication(!upDownArrowApplication);
                          }}
                        ></img>
                      )}
                    </span>
                  </th>
                  <th className="border-0 fs-12 fw-700">
                    <span
                      className=" m-0 d-flex fs-12 fw-700  pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Referrals
                      {upDownArrowReferrals ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_REFERRALS_ASC");
                            setUpDownArrowReferrals(!upDownArrowReferrals);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "MOST_REFERRALS");
                            setUpDownArrowReferrals(!upDownArrowReferrals);
                          }}
                        ></img>
                      )}
                    </span>
                  </th>
                  <th className="border-0 fs-12 fw-700">
                    <span
                      className=" m-0 d-flex fs-12 fw-700  pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Shortlisted
                      {upDownArrowShort ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "SHORTLIST_COUNT_ASC");
                            setUpDownArrowShort(!upDownArrowShort);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "SHORTLIST_COUNT");
                            setUpDownArrowShort(!upDownArrowShort);
                          }}
                        ></img>
                      )}
                    </span>
                  </th>
                  <th className="border-0 fs-12 fw-700">
                    <span
                      className=" m-0 d-flex fs-12 fw-700  pb-3"
                      style={{ gap: "0.6rem" }}
                    >
                      Assessment
                      {upDownArrowAssess ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "ASSESSMENT_ASC");
                            setUpDownArrowAssess(!upDownArrowAssess);
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "ASSESSMENT");
                            setUpDownArrowAssess(!upDownArrowAssess);
                          }}
                        ></img>
                      )}
                    </span>
                  </th>
                  <th
                    // style={{ alignItems: "flex-start" }}
                    className="border-0 fs-12 fw-700 align-top"
                  >
                    <span
                      className=" m-0 d-flex fs-12 fw-700"
                      style={{ gap: "0.6rem" }}
                    >
                      Pending Screening
                      {upDownArrowPendingScreeen ? (
                        <img
                          className="pointer"
                          src={dropdownArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "PENDING_SCREENING_ASC");
                            setUpDownArrowPendingScreeen(
                              !upDownArrowPendingScreeen
                            );
                          }}
                        ></img>
                      ) : (
                        <img
                          className="pointer"
                          src={upArrow}
                          alt=""
                          onClick={() => {
                            dropdownSortHandler(null, "PENDING_SCREENING");
                            setUpDownArrowPendingScreeen(
                              !upDownArrowPendingScreeen
                            );
                          }}
                        ></img>
                      )}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody className="table_body mt-4">
                {jobslist && jobslist.length > 0 ? (
                  jobslist?.map((item, key) => {
                    return (
                      <>
                        <tr className="card-parent col-12 position-relative">
                          <td className="col-md-4">
                            <div className="p-0 m-0">
                              <p className="m-0 p-0 fs-14 fw-400 color-262626">
                                {item?.jobTitle}
                              </p>

                              <p
                                className="fs-11 fw-300 d-flex "
                                style={{ color: "#808080" }}
                              >
                                {item.jobStatus === "LIVE" ? (
                                  <>
                                    <span className="dot green"></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : item.jobStatus === "PAUSED" ? (
                                  <>
                                    <span className="dot yellow "></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : item.jobStatus === "FILLED" ? (
                                  <>
                                    <span className="dot gray "></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : item.jobStatus === "CLOSED" ? (
                                  <>
                                    <span className="dot gray "></span>{" "}
                                    {item.jobStatus}
                                  </>
                                ) : (
                                  ""
                                )}{" "}
                                | {item.numberOfPost} Roles |{" "}
                                {item.jobPostingDate}
                              </p>
                            </div>
                          </td>
                          <td className="fs-14 align-middle">
                            <p className="m-0">{item?.jobView}</p>
                          </td>
                          <td className="fs-14  align-middle">
                            <p className=" m-0">{item?.applicationCount}</p>
                            {item.newApplicationCount > 0 ? (
                              <span
                                className="fs-11 fw-700"
                                style={{ color: "#EB1260" }}
                              >
                                {item.newApplicationCount} New
                              </span>
                            ) : (
                              ""
                            )}
                          </td>
                          <td className="fs-14  align-middle">
                            <p className=" m-0">{item?.referralCount}</p>
                            {/* {item.referralCount > 0 ? (
                            <span
                              className="fs-11 fw-700"
                              style={{ color: "#EB1260" }}
                            >
                              {item.referralCount} New
                            </span>
                          ) : (
                            ""
                          )} */}
                          </td>
                          <td className="fs-14  align-middle">
                            <p className="m-0">{item?.shortlistedCount}</p>
                          </td>
                          <td className="fs-14  align-middle">
                            {" "}
                            <p className="m-0"> {item?.assessmentCount}</p>
                          </td>
                          <td className="align-middle align-items-center">
                            <div
                              style={{
                                display: "grid",
                                gridAutoFlow: "column",
                              }}
                            >
                              <p className="fs-14 m-0">
                                {item?.pendingScreening}
                              </p>

                              <div
                                style={{
                                  right: "10px",
                                }}
                                className="onhover-show "
                              >
                                <div className="d-flex align-items-center h-32 gap-3 ">
                                  <div className="pin-bg d-flex justify-content-center align-items-center pointer">
                                    <img
                                      style={{ height: "14px", width: "10px" }}
                                      src={
                                        item?.isPinned ? pinFilled : pinUnfilled
                                      }
                                      onClick={() => {
                                        if (item?.isPinned == true) {
                                          // setPinSrc(pinFilled);
                                          // setUnpinStatus("UnPin");
                                          setPinJobs(false);
                                          pinJobIcon(item?.jobId, false);
                                        } else {
                                          // setPinSrc(pinIcon);
                                          // setPinUnpinStatus("Pin");
                                          setPinJobs(true);
                                          pinJobIcon(item?.jobId, true);
                                        }
                                      }}
                                      // use "pinFilled" in src for filled icon

                                      // onClick={() => {pinJobIcon(item?.jobId,true)
                                      //   getjobSummary(item?.isPinned)}}
                                    />
                                  </div>

                                  <div
                                    // to={
                                    //   "/" +
                                    //   JOB_POST_CREATE_PAGE_ROUTE +
                                    //   "/" +
                                    //   item?.jobId
                                    // }
                                    onClick={() => {
                                      setIsShowScratchPreviewBtn(false);
                                      editPreJobs("", item?.jobId);
                                    }}
                                    id={key}
                                    type="button"
                                    className={`fs-12 btn bg-black  align-middle px-3 text-white down-arrow  align-items-center `}
                                    style={{ minWidth: "100px" }}
                                  >
                                    Edit Job
                                  </div>
                                  <Link
                                    to={
                                      "/" +
                                      APPLICATIONS_MAIN_PAGE_ROUTE +
                                      `/${item?.jobId}`
                                    }
                                    // onClick={() => navigate("/" + APPLICATIONS_MAIN_PAGE_ROUTE + "/"+item?.jobId)}
                                    id={key}
                                    type="button"
                                    className={`fs-12 btn bg-black  px-4 align-middle   text-white down-arrow  align-items-center gap-1 `}
                                  >
                                    Applications
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                ) : (
                  <center className="margin-auto">No Data Found</center>
                )}
              </tbody>
            </table>
          </InfiniteScroll>
        </div>

        <div className="d-sm-block d-md-block d-lg-none">
          <JobPostsCard
            jobslist={jobslist}
            pinJobIcon={pinJobIcon}
            setPinJobs={setPinJobs}
          />
        </div>

        <CreateNewJobPostModal
          setIsShowScratchPreviewBtn={setIsShowScratchPreviewBtn}
          show={showCreateJobPostModal}
          close={() => setShowCreateJobPostModal(false)}
          setShow={setShowCreateJobPostModal}
          setShowModalWithType={setShowModalWithType}
        />
        <CopyPreviousJobPostModal
          show={showModalWithType === 2 ? true : false}
          close={() => setShowModalWithType(null)}
          setShowCreateJobPostModal={setShowCreateJobPostModal}
        />
        <CreateFromTemplateModal
          show={showModalWithType === 3 ? true : false}
          close={() => setShowModalWithType(null)}
          setShowCreateJobPostModal={setShowCreateJobPostModal}
          allTemplateDetails={allTemplateDetails}
        />
        <SortByFilterModal
          dropdownSortHandler={dropdownSortHandler}
          arraySortIndex={arraySortIndex}
          setArraySortIndex={setArraySortIndex}
          dropdownSortArray={dropdownSortArray}
          show={showSortByFilter}
          close={() => setShowSortByFilter(false)}
          setShowCreateJobPostModal={setShowCreateJobPostModal}
        />

        <AllFiltersMobileModal
          dropdownAllStatusArray={dropdownAllStatusArray}
          dropdownAllTeamsArray={teamData}
          dropdownAllTimeArray={dropdownAllTimeArray}
          dropdownPostByArray={dropdownPostByArray}
          show={showAllFilters}
          close={() => setShowAllFilters(false)}
          setShowCreateJobPostModal={setShowCreateJobPostModal}
          setArrayAllTimeIndex={setArrayAllTimeIndex}
          setArrayAllTeamsIndex={setArrayAllTeamsIndex}
          setArrayAllStatusIndex={setArrayAllStatusIndex}
          arrayAllTeamsIndex={arrayAllTeamsIndex}
          arrayAllStatusIndex={arrayAllStatusIndex}
          arrayAllTimeIndex={arrayAllTimeIndex}
          postByArrayIndex={postByArrayIndex}
          setPostByArrayIndex={setPostByArrayIndex}
          showDatePickerModal={showDatePickerModal}
          setShowDatePickerModal={setShowDatePickerModal}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          setCustomStartDate={setCustomStartDate}
          setCustomEndDate={setCustomEndDate}
          displayDatePicker={displayDatePicker}
          setDisplayDatePicker={setDisplayDatePicker}
          checkAllStatusHandler={checkAllStatusHandler}
          checkAllTeamsHandler={checkAllTeamsHandler}
          setTimeDropdownRedDot={setAllFiltersDropdownRedDot}
          fetchAllJobs={fetchAllJobs}
          allStatus={allStatus}
          allJobsPostFilterData={allJobsPostFilterData}
          teamArray={teamArray}
          setTeamArray={setTeamArray}
          setAllStatus={setAllStatus}
          date={date}
        />

        <DatePickerModal
          showDatePickerModal={showDatePickerModal}
          setShowDatePickerModal={setShowDatePickerModal}
          customStartDate={customStartDate}
          customEndDate={customEndDate}
          setCustomStartDate={setCustomStartDate}
          setCustomEndDate={setCustomEndDate}
          dateRange={dateRange}
          setDateRange={setDateRange}
          currentDate={currentDate}
          displayDatePicker={displayDatePicker}
          setDisplayDatePicker={setDisplayDatePicker}
          dropdownAllTimeHandler={dropdownAllTimeHandler}
          handleDateRange={handleDateRange}
          setTimeDropdownRedDot={setAllFiltersDropdownRedDot}
        />
      </div>
      <div
        style={{ backgroundColor: "#F4F7FB", left: "0px" }}
        className="position-fixed bottom-0 d-sm-block d-md-none w-100 pb-2 "
      >
        <div
          className="btn down-arrow col-6 gap-2 btn-outline-none"
          to=""
          onClick={() => setShowSortByFilter(true)}
        >
          <div className="d-flex justify-content-center">
            <img
              alt=""
              style={{
                borderRadius: "50%",
                height: "0.9rem",
                width: "0.9rem",
              }}
              src={SortBy}
            />
            <span className="fs-12 fw-400 ps-1">Sort By</span>
            <span
              className={`${sortByDropdownRedDot} round-red-dot rounded-circle ms-1`}
            ></span>
          </div>
        </div>
        <div
          className="btn down-arrow col-5 gap-2 btn-outline-none border-start"
          to=""
          onClick={() => setShowAllFilters(true)}
        >
          <div className="d-flex justify-content-center">
            <img
              alt=""
              style={{
                borderRadius: "50%",
                height: "0.9rem",
                width: "0.9rem",
              }}
              src={filterLogo}
            />
            <span className="fs-12 fw-400 ps-1">Filter</span>
            {allFiltersDropdownRedDot && (
              <span className={`round-red-dot rounded-circle ms-1 mb-2`}></span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobPostPage;
