import { useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import aboutLogo from "../../../assests/icons/about.svg";
import ProfileImage from "../../../components/profile_image";
import { downloadFile } from "../../../_services/view.service";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg"

const ApplicationAboutMe = (props) => {
  console.log("props?.applicationDetails", props?.applicationDetails);
  const [initials, setInitials] = useState("");
  // const [profileSrc, setProfileSrc] = useState(null);

  // const downloadPicture = async (downloadedUrl) => {
  //   downloadFile(downloadedUrl).then((res) => {
  //     if (res) {
  //       setProfileSrc(res);
  //     } else {
  //       setProfileSrc(null);
  //     }
  //   });
  // };

  // const getProfilePicture = () => {
  //   if (
  //     process.env.REACT_APP_IMAGE_BASEURL + props?.applicationDetails?.viewProfileResponse?.basicDetailsResponse?.profilePicDownloadURL !==
  //     (undefined || null)
  //   ) {
  //     downloadPicture(
  //       process.env.REACT_APP_IMAGE_BASEURL + props?.applicationDetails?.viewProfileResponse?.basicDetailsResponse?.profilePicDownloadURL
  //     );
  //   } else {
  //     setProfileSrc(null);
  //   }
  // };

  // useEffect(() => {
  //   getProfilePicture();
  // }, []);
  console.log(
    props?.applicationDetails?.profilePic,
    " props?.applicationDetails?.viewProfileResponse?.basicDetailsResponse"
  );


  const getInitialsLetter = async () => {
    const name = props?.applicationDetails?.viewProfileResponse
    ?.userRegistrationDetails?.name ;

    if (name?.split(" ").length > 1) {
      let InitName =
        name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
        name?.split(" ")[1]?.charAt(0)?.toUpperCase();
      setInitials(InitName);
    } else {
      let InitName = name?.split(" ")[0]?.charAt(0)?.toUpperCase();
      setInitials(InitName);
    }
  };
  useEffect(() => {
  
    getInitialsLetter();
  }, [initials]);
  return (
    <div>
      <div className="fw-bold fs-24 mt-4 mb-4">
        {" "}
        <img src={aboutLogo} alt={aboutLogo} />
        &nbsp;About
        {/* {!profilePage && "Me"} */}
      </div>
      <div className="shadow-box">
        <div className="p-3">
          <div className="d-flex gap-3 ">
            <div className="sidebar-profile-pic ">
              {/* <img
                src={require("./../../../assests/images/profile.jpg")}
                alt="profile-img"
                width="70px"
                className="border-radius"
              /> */}
               {/* <div className="position-relative d-flex justify-content-center profileImageStyle border rounded-3 overflow-hidden"> */}
              { props?.applicationDetails?.profilePic !== null 
              // || props?.applicationDetails?.profilePic !==""
                 ? (


                  <img
                  src={
                    process.env.REACT_APP_IMAGE_BASEURL +
                    props?.applicationDetails?.profilePic
                    // ?.profilePicDownloadURL ||  props?.applicationDetails?.userRegistrationDetails?.basicDetailsResponse
                    // ?.profilePicDownloadURL
                  }
                  name="profile-img"
                  initialsContainerClass="initialsStyle2-xl"
                  width="70px"
                />
                
                        
              ) : (
              <p className=" modal-default-profile-picture d-flex justify-content-center align-items-center">
               {
                <>
              {props?.applicationDetails?.viewProfileResponse ?.userRegistrationDetails?.name!==null ? 
                (props?.applicationDetails?.viewProfileResponse
                   ?.userRegistrationDetails?.name?.split(" ")[0]?.charAt(0)?.toUpperCase() +
                   props?.applicationDetails?.viewProfileResponse?.userRegistrationDetails?.name?.split(" ")[1]?.charAt(0)?.toUpperCase()
                ):(
                  props?.applicationDetails?.viewProfileResponse
                  ?.userRegistrationDetails?.name?.split(" ")[0]?.charAt(0)?.toUpperCase()
                )
  


                }
    </>
    }
                        {/* {initials} */}
               </p>
                // <img
                //   src={
                //     process.env.REACT_APP_IMAGE_BASEURL +
                //     props?.applicationDetails?.profilePic
                //     // ?.profilePicDownloadURL ||  props?.applicationDetails?.userRegistrationDetails?.basicDetailsResponse
                //     // ?.profilePicDownloadURL
                //   }
                //   name="profile-img"
                //   initialsContainerClass="initialsStyle2-xl"
                //   width="70px"
                // />
              )}
            
               
            </div>
            <div className="pt-1">
              <h4 className="fs-16 fw-600 m-0">
                {props?.applicationDetails?.viewProfileResponse
                  ?.userRegistrationDetails?.name ||
                  props?.applicationDetails?.userRegistrationDetails?.name}
              </h4>
              <div className="d-flex flex-wrap gap-1">
                <span className="fs-16 fw-500">
                  {props?.applicationDetails?.viewProfileResponse
                    ?.additionalInfoProfileResponse?.currentDesignation ||
                    props?.applicationDetails?.additionalInfoProfileResponse
                      ?.currentDesignation}
                </span>
                <span className="fs-16 fw-400 color-tertiary">at</span>
                <span className="fs-16 fw-500">
                  {props?.applicationDetails?.viewProfileResponse
                    ?.additionalInfoProfileResponse?.company ||
                    props?.applicationDetails?.additionalInfoProfileResponse
                      ?.company}
                </span>
              </div>
              <span className="blue-badge">
                {props?.applicationDetails?.viewProfileResponse
                  ?.additionalInfoProfileResponse?.jobSearchStatus ||
                  props?.applicationDetails?.additionalInfoProfileResponse
                    ?.jobSearchStatus}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationAboutMe;
