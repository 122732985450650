import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import icPinkDot from "../../assests/icons/ic_pink_dot.svg";
import icBack from "../../assests/icons/back-icon.svg";
import icDown from "../../assests/icons/ic-chevron-down-16.svg";
import icRightArrow from "../../assests/icons/ic-right-arrow.svg";
import { Link, useLocation } from "react-router-dom";
import {
  ADMIN_JOB_POST_PAGE_ROUTE,
  BUSINESS_ROUTE,
  JOB_POSTS_PAGE_ROUTE,
} from "../../config/page_routes_constants";
import ApplicationOptions from "./applicationoptions";
import { USER_ROLE } from "../../config/be_api_constants";
import { getLocalStorage } from "../../utils/storage";

const ApplicationSubpart = (props) => {
  const [showOptions, setShowOptions] = useState(props?.optionsShow);
  const location = useLocation();

  if (location.hash === `#application/${props?.applicationStatus?.status}`) {
    props.onApplicationClose();
  }
  const role = getLocalStorage("role");

  return (
    <div className="d-block d-md-none">
      <Modal
        fullscreen="md-down"
        show={props.show}
        onHide={() => () => props.onApplicationClose()}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
        className="mt-5 application-modal d-md-none d-block h-100"
        backdrop={false}
        animation={false}
      >
        <Modal.Header className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex gap-3 color-primary align-items-center">
              <Link to={(role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) ? JOB_POSTS_PAGE_ROUTE : `/${ADMIN_JOB_POST_PAGE_ROUTE}`}>
                <img src={icBack} alt="back-icon" />
              </Link>
              <div className="fs-20 fw-700 medium-black-text">Applications</div>
            </div>
            <div
              className="d-flex gap-3"
              onClick={() => {
                setShowOptions(true);
                // props.onApplicationClose();
              }}
            >
              <img src={icDown} alt="down-icon" className="" width="20px" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ps-4 pb-5">
          <div>
            <div className=" ">
              {props?.applicationStatus?.map((status, index) => {
                return (
                  <>
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                      onClick={() => {
                        props?.setSelectedStatus(status.status);
                        props.onApplicationClose();
                        window.location.hash = `#application/${status.status}`;
                      }}
                    >
                      <div>
                        <div className="color-primary fs-14 fw-600 d-flex justify-content-start ps-2">
                          {status.status}
                        </div>
                        <div className="d-flex gap-2 align-items-center justify-content-start ps-2">
                          <div className="color-tertiary fs-11 fw-600">
                            {status.count}
                          </div>
                          {status.newCount > 0 && (
                            <div className="d-flex gap-1 align-items-center">
                              <div>
                                <img
                                  src={icPinkDot}
                                  alt="pink-dot icon"
                                  width="6px"
                                />
                              </div>
                              <div className="dark-pink-text fs-11 fw-600">
                                {status.newCount} New
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="pe-2">
                        <img src={icRightArrow} alt="right-arrow-icon" />
                      </div>
                    </div>
                    <hr></hr>
                  </>
                );
              })}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <ApplicationOptions
        show={showOptions}
        onOptionsClose={() => setShowOptions(false)}
        applicationClose={() => {
          props.onApplicationClose();
        }}
      />
    </div>
  );
};

export default ApplicationSubpart;
