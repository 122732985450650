import React, { useEffect } from "react";
import "./styles.scss";
import userProfilePic from "../../../assests/images/candidate-profile-pic.jpg";
import editIcon from "../../../assests/icons/edit2-icon.svg";
import passwordIcon from "../../../assests/icons/ic-password.svg";
import AddNumberModal from "./account_page_modals/add_number_modal";
import { useState } from "react";
import { Link } from "react-router-dom";
import PasswordChangeLoggedInModal from "./account_page_modals/password_change_logged_in";
import ResetPasswordLinkModal from "./account_page_modals/reset_password_link";
import ChangeUsernameModal from "./account_page_modals/change_username_modal";
import ChangeProfilePictureModal from "./account_page_modals/change_profile_picture_modal";

import { USER_ID } from "../../../config/localstorage_keys";
import { useStoreActions, useStoreState } from "easy-peasy";
import { updateNotificationPreference } from "../../../_services/member-profile.service";
import Loader from "../../../components/common/loader";
import ProfileImage from "../../../components/profile_image";
import { downloadFile } from "../../../_services/view.service";
import { USER_ROLE } from "../../../config/be_api_constants";
import toaster from "./../../../utils/toaster";
import { getLocalStorage } from "../../../utils/storage";

function AccountPage() {
  const [showAddNumberModal, setShowAddNumberModal] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [phonenumber, setPhoneNumber] = useState();

  const [usernameModalShow, setUsernameModalShow] = useState(false);
  const [profileModalShow, setProfileModalShow] = useState(false);
  const [passwordLengthArray, setPasswordLengthArray] = useState([
    1, 2, 3, 4, 5, 6, 7, 8,
  ]);

  // const role = useStoreState((state) => state?.candidate?.role);
  const role = localStorage.getItem("role");

  useEffect(() => {
    // user details from redux -> get password length -> use array.push to set "passwordLengthArray" dynamically
  }, []);
  const [showLoader, setShowLoader] = useState(false);
  const [showMobile, setshowMobile] = useState();
  const [ShowUsername, setShowUsername] = useState();
  const [showUpdateWhatsApp, setshowUpdateWhatsApp] = useState();
  const [showUpdateSms, setshowUpdateSms] = useState();
  const [url, setUrl] = useState();
  const [mobileNumber, setMobileNumber] = useState();
  const [apiRefresh, setApiRefresh] = useState(false);
  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );
  const [profileSrc, setProfileSrc] = useState(null);
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const accountNotificationPreferencesResponse = useStoreState(
    (state) =>
      state?.candidate?.candidateDetails?.accountNotificationPreferencesResponse
  );
  const adminUserResponse = useStoreState(
    (state) => state?.candidate?.candidateDetails?.adminUserResponse
  );

  const updateNotificationPreferenceSettings = (changefeild, changeValue) => {
    const temp = { ...accountNotificationPreferencesResponse };
    temp[changefeild] = changeValue;
    setShowLoader(true);
    updateNotificationPreference(
      temp?.updateOnEmail,
      temp?.updateOnWhatsApp,
      temp?.updateOnSms
    )
      .then((res) => {
        const userId = getLocalStorage(USER_ID);
        if (userId) {
          saveCandidateDetails(userId);
        }
        toaster("success", "Saved successfully!");
        setShowLoader(false);
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };
  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        setProfileSrc(res);
      } else {
        setProfileSrc(null);
      }
    });
    // alert(downloadedUrl);
  };
  const getProfilePicture = () => {
    if (
      candidateDetails?.adminUserResponse?.profilePicDownloadUrl !==
      (undefined || null)
    ) {
      downloadPicture(
        candidateDetails?.adminUserResponse?.profilePicDownloadUrl
      );
    } else {
      setProfileSrc(null);
    }
  };

  useEffect(() => {
    getProfilePicture();
    // getInitialsLetter();
  }, [candidateDetails]);

  return (
    <div className="container">
      <div className="content-margin mt-4">
        {showLoader && <Loader />}
        <p className="fs-24 fw-700">Account</p>
        {/* Account Info div start */}
        <div className="account-info-box p-3">
          <div className="d-flex">
            <div className="w-100 d-flex justify-content-between align-items-end">
              <div className="d-flex align-items-end">
                <div className="user-profile-picture rounded position-relative">
                  {/* <img
                  className=""
                  style={{ width: "100%" }}
                  src={userProfilePic}
                /> */}
                  <ProfileImage
                    src={profileSrc}
                    name={adminUserResponse?.name}
                    initialsContainerClass="sidebar-default-profile-picture"
                  />
                  <div
                    onClick={() => {
                      setProfileModalShow(true);
                      setUrl(adminUserResponse?.profilePicDownloadUrl);
                    }}
                    className="edit-icon-div rounded pointer acc-btn-focus"
                  >
                    <img src={editIcon} />
                  </div>
                </div>

                <div className="ms-2 fw-700 fs-16 referee-btn-focus ">
                  <div className="user-name account-name-ellipse-2 referee-btn-focus">
                    {adminUserResponse?.name}
                  </div>
                </div>
              </div>
              <div
                onClick={() => setUsernameModalShow(true)}
                className="px-1 rounded edit-name-icon mb-1 pointer acc-btn-focus referee-btn-focus"
              >
                <img src={editIcon} />
              </div>
            </div>
            <div></div>
          </div>
          {/* {(role === USER_ROLE.ADMIN ||
            role === USER_ROLE.BUSINESS_USER) && ( */}
          <div className="mt-3">
            <div className="small-text-gray">Organization</div>
            <div className="fw-600 fs-14 mt-1">
              {adminUserResponse?.organizations}
              {/* {adminUserResponse?.organizations.length > 0 &&
                  adminUserResponse?.organizations.map(
                    (Organization, index) => (
                      <div
                        className="header-ellipse-1"
                        style={{ maxWidth: "280px" }}
                        key={index + 1}
                      >
                        {Organization}
                      </div>
                    )
                  )} */}
            </div>
          </div>
          {/* )} */}
          <hr className="account-hr-color" />
          <div className="mt-2">
            <div className="small-text-gray">User Id / Work Email</div>
            <div
              style={{ maxWidth: "280px" }}
              className="fw-600 fs-14 mt-1 header-ellipse-1"
            >
              {adminUserResponse?.email}
            </div>
          </div>
          <hr className="account-hr-color" />
          <div className="mt-2">
            <div className="small-text-gray">Password</div>
            <div className="d-flex justify-content-between">
              <div className="fw-700 fs-14 mt-1 d-flex gap-1">
                {passwordLengthArray && passwordLengthArray?.length > 0
                  ? passwordLengthArray?.map((char, index) => {
                      return (
                        <img
                          key={index}
                          style={{ width: "8px" }}
                          src={passwordIcon}
                        />
                      );
                    })
                  : ""}
              </div>
              <div>
                <div
                  onClick={() => {
                    setShowPasswordChangeModal(true);
                    setShowUsername(adminUserResponse?.name);
                  }}
                  className="fs-12 text-underline link-primary pointer pass-btn-focus"
                >
                  Change Password
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Account Info div end */}
        {/* Updates div start */}
        <div className="w-100 mt-4">
          <div className="fw-700 fs-16">Get updates on WhatsApp & SMS</div>
          <div className="small-text-gray mt-3">Phone / WhatsApp Number</div>
          <div className="fs-14 fw-600 mt-2">{adminUserResponse?.mobile}</div>
          {/* {Data?.data?.mobile} */}
          <button
            onClick={() => {
              setShowAddNumberModal(true);
              setshowMobile(adminUserResponse?.mobile);
              setshowUpdateWhatsApp(
                accountNotificationPreferencesResponse?.updateOnWhatsApp
              );
              setshowUpdateSms(
                accountNotificationPreferencesResponse?.updateOnSms
              );
            }}
            className="btn fw-700 fs-12 btn-add-number mt-3 py-2 px-3 acc-btn-focus"
          >
            Change
          </button>
        </div>

        {/* Updates div end */}
        {/* Notification pref div start */}
        <div className="account-notification-pref mt-4">
          <hr className="account-hr-color" />
          <div className="fw-700 fs-16">Notification preferences</div>
          <div className="mt-2 d-flex justify-content-between">
            <span className="small-text-gray">Receive updates on Email</span>
            <span className="form-check form-switch">
              <div className="">
                <input
                  type="checkbox"
                  className="form-check-input switch-btn-small pointer"
                  checked={
                    accountNotificationPreferencesResponse?.updateOnEmail
                  }
                  onChange={(e) => {
                    updateNotificationPreferenceSettings(
                      "updateOnEmail",
                      e.target.checked
                    );
                  }}
                />
              </div>
            </span>
          </div>
          {/* After number is added */}
          <hr className="account-hr-color" />
          <div className="mt-2 d-flex justify-content-between">
            <span className="small-text-gray">Receive updates on WhatsApp</span>
            <span className="form-check form-switch">
              <div className="">
                <input
                  type="checkbox"
                  className="form-check-input switch-btn-small pointer"
                  checked={
                    accountNotificationPreferencesResponse?.updateOnWhatsApp
                  }
                  onChange={(e) => {
                    updateNotificationPreferenceSettings(
                      "updateOnWhatsApp",
                      e.target.checked
                    );
                  }}
                />
              </div>
            </span>
          </div>
          <hr className="account-hr-color" />
          <div className="mt-2 d-flex justify-content-between">
            <span className="small-text-gray">Receive updates on SMS</span>
            <span className="form-check form-switch">
              <div className="">
                <input
                  type="checkbox"
                  className="form-check-input switch-btn-small pointer"
                  checked={accountNotificationPreferencesResponse?.updateOnSms}
                  onChange={(e) => {
                    updateNotificationPreferenceSettings(
                      "updateOnSms",
                      e.target.checked
                    );
                  }}
                />
              </div>
            </span>
          </div>
        </div>
        {/* Notification pref div end */}

        {/* Add / Change number modal */}
        <AddNumberModal
          showAddNumberModal={showAddNumberModal}
          setShowAddNumberModal={setShowAddNumberModal}
          Mobile={showMobile}
          phonenumber={phonenumber}
          setPhoneNumber={setPhoneNumber}
          WhatsApp={showUpdateWhatsApp}
          SMS={showUpdateSms}
          setApiRefresh={setApiRefresh}
        />

        {/* Reset password link modal */}
        {/* <ResetPasswordLinkModal
        showResetPasswordModal={showResetPasswordModal}
        setShowResetPasswordModal={setShowResetPasswordModal}
        setShowPasswordChangeModal={setShowPasswordChangeModal}
      /> */}

        {/* Set new password modal */}
        <PasswordChangeLoggedInModal
          showPasswordChangeModal={showPasswordChangeModal}
          setShowPasswordChangeModal={setShowPasswordChangeModal}
        />

        {/* Change Username Modal */}

        <ChangeUsernameModal
          usernameModalShow={usernameModalShow}
          setUsernameModalShow={setUsernameModalShow}
          Username={ShowUsername}
        />

        {/* Change Profile Picture Modal */}

        <ChangeProfilePictureModal
          profileModalShow={profileModalShow}
          setProfileModalShow={setProfileModalShow}
          showurl={url}
        />
      </div>
    </div>
  );
}

export default AccountPage;
