import React, { useEffect } from "react";
import "./style.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { USER_ROLE } from "../../config/be_api_constants";

const ProgressBarGraph = ({
  data,
  height,
  showLegends,
  seikorAdminBarData,
}) => {
  const barHeight = height;
  const role = localStorage.getItem("role");

  const getBarWidth = (barData) => {
    if (barData && data && data.length > 0) {
      let total = data.reduce(
        (previousValue, currentValue) =>
          previousValue + parseInt(currentValue.entityValue),
        0
      );
      return Math.round((parseInt(barData.entityValue) * 100) / total) + "%";
    }
  };

  const getBarWidthSeikorAdmin = (barData) => {
    if (barData && seikorAdminBarData && seikorAdminBarData.length > 0) {
      let total = seikorAdminBarData.reduce(
        (previousValue, currentValue) =>
          previousValue + parseInt(currentValue.entityValue),
        0
      );
      return Math.round((parseInt(barData.entityValue) * 100) / total) + "%";
    }
  };

  const getLengedPercentage = (total, value) => {
    if (total === 0 || value === 0) {
      return 0 + "%";
    } else if (total && value) {
      return Math.round((parseInt(value) * 100) / parseInt(total)) + "%";
    }
  };

  return (
    <>
      {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
        <div
          className="d-flex"
          style={{ height: barHeight ? barHeight + "px" : "10px" }}
        >
          {seikorAdminBarData &&
            seikorAdminBarData?.length > 0 &&
            seikorAdminBarData?.map((bar, index) => (
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                key={index + 1}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${index + 1}`}>
                    <div className="">
                      <div className="tooltip-style">{bar?.entity}</div>
                      <div className="tooltip-number">{bar?.entityValue}</div>
                    </div>
                  </Tooltip>
                }
              >
                <div
                  // title={bar?.entity}
                  style={{
                    backgroundColor: `${bar?.color}`,
                    width: `${getBarWidthSeikorAdmin(bar)}`,
                  }}
                ></div>
              </OverlayTrigger>
            ))}
        </div>
      )}

      {(role === USER_ROLE.BUSINESS_ADMIN ||
        role === USER_ROLE.BUSINESS_USER) && (
        <div
          className="d-flex"
          style={{ height: barHeight ? barHeight + "px" : "10px" }}
        >
          {data &&
            data?.length > 0 &&
            data?.map((bar, index) => (
              <OverlayTrigger
                delay={{ show: 250, hide: 400 }}
                key={index + 1}
                placement="top"
                overlay={
                  <Tooltip id={`tooltip-${index + 1}`}>
                    <div className="">
                      <div className="tooltip-style">{bar?.entity}</div>
                      <div className="tooltip-number">{bar?.entityValue}</div>
                    </div>
                  </Tooltip>
                }
              >
                <div
                  // title={bar?.entity}
                  style={{
                    backgroundColor: `${bar?.color}`,
                    width: `${getBarWidth(bar)}`,
                  }}
                ></div>
              </OverlayTrigger>
            ))}
        </div>
      )}

      {role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER ? 
      (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER && role !== USER_ROLE.SUPER_ADMIN || role !== USER_ROLE.ADMIN
        ? showLegends &&
          data &&
          data?.length > 0 && (
            <div className="d-flex justify-content-start justify-content-sm-between mt-4 flex-wrap">
              {data?.map((item, index) => (
                <div className="d-flex mb-2 legends-width" key={index + 1}>
                  <div className="bullet">
                    <p
                      className="m-0 me-1"
                      style={{
                        fontSize: "40px",
                        height: "20px",
                        lineHeight: "18px",
                        color: item?.color,
                      }}
                    >
                      &bull;
                    </p>
                  </div>
                  <div>
                    <div className="fw-600 fs-14 color-secondary">
                      {index !== 0 &&
                        `${getLengedPercentage(
                          data[0]?.entityValue,
                          item?.entityValue === 0
                            ? "0"
                            : item?.entityValue === 0 ||
                              item?.entityValue === undefined
                            ? 0
                            : item?.entityValue
                        )}`}

                      {index === 0
                        ? item.entityValue
                        : `(${
                            item?.entityValue === 0 ||
                            item?.entityValue === undefined
                              ? 0
                              : item?.entityValue
                          })`}
                    </div>
                    <div className="fw-300 fs-11 color-tertiary">
                      {item.entity}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        : null)
        
        :
        
        ""}



      {role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN ? 
      (role === USER_ROLE.ADMIN || USER_ROLE.SUPER_ADMIN  && role !== USER_ROLE.BUSINESS_ADMIN || role !== USER_ROLE.BUSINESS_USER
        ? showLegends &&
          seikorAdminBarData &&
          seikorAdminBarData?.length > 0 && (
            <div className="d-flex justify-content-start justify-content-sm-between mt-4 flex-wrap">
              {seikorAdminBarData?.map((item, index) => (
                <div className="d-flex mb-2 legends-width" key={index + 1}>
                  <div className="bullet">
                    <p
                      className="m-0 me-1"
                      style={{
                        fontSize: "40px",
                        height: "20px",
                        lineHeight: "18px",
                        color: item?.color,
                      }}
                    >
                      &bull;
                    </p>
                  </div>
                  <div>
                    <div className="fw-600 fs-14 color-secondary">
                      {index !== 0 &&
                        `${getLengedPercentage(
                          seikorAdminBarData[0]?.entityValue,
                          item?.entityValue === 0
                            ? "0"
                            : item?.entityValue === 0 ||
                              item?.entityValue === undefined
                            ? 0
                            : item?.entityValue
                        )}`}

                      {index === 0
                        ? item.entityValue
                        : `(${
                            item?.entityValue === 0 ||
                            item?.entityValue === undefined
                              ? 0
                              : item?.entityValue
                          })`}
                    </div>
                    <div className="fw-300 fs-11 color-tertiary">
                      {item.entity}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )
        : null)
        :
        ""}

      
    </>
  );
};
export default ProgressBarGraph;
