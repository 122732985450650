import React from "react";
import emptyPin from "../assests/icons/empty_pin.svg";

function EmptyPinState(props) {
  return (
    <div className="empty-pin-container flex-wrap">
      <div className="me-3">
        <img style={{ width: "20px", height: "33px" }} src={emptyPin} />
      </div>
      <div>
        <div style={{ color: "#808080" }} className="fw-400 fs-20">
          No pinned {props?.text} yet
        </div>
        <div style={{ color: "#b9b9b9" }} className="fw-400 fs-14">
          Pin some {props?.text} for quick view
        </div>
      </div>
    </div>
  );
}

export default EmptyPinState;
