import React, { useEffect, useState } from "react";

import "../referee_screen/styles.scss";
import { Link, Navigate, unstable_HistoryRouter } from "react-router-dom";
import { Table } from "react-bootstrap";
import "./teams_member_style.scss";
import { TEAMS_DETAIL_PAGE_ROUTE } from "../../../config/page_routes_constants";
import { getTeams } from "../../../_services/view.service";
import toaster from "../../../utils/toaster";
import { STATUS_SUCCESS } from "../../../config/localstorage_keys";
import { GENERAL_ERROR_MESSAGE } from "../../../config/messages";
import Loader from "../../../components/common/loader";
import InfiniteScroll from "react-infinite-scroll-component";

const TeamsPage = (
  {
    teamsList,
    showLoader,
    currentTeamsPageset,
    fetchMoreTeams,
    dataCountTeams,
    loading,
  },
  props
) => {
  // const [showLoader, setShowLoader] = useState(false);
  // const [teamsList, setTeamData] = useState();

  console.log(teamsList, "team data visible");
  // const [pageNo,setPageNo] = useState();
  // const [pageSize,setPageSize] = useState();

  // const teamStatus = () => {
  //   setShowLoader(true);
  //   let pageNo = 1;
  //   let pageSize = 10;
  //   getTeams(pageNo, pageSize)
  //     .then((res) => {
  //       setTeamData(res?.data?.data);
  //     })
  //     .catch((err) => {
  //       setShowLoader(false);
  //       toaster("Error", err);
  //     });
  // };

  // useEffect(() => {
  //   teamStatus();
  // }, []);

  return (
    <div>
      <div className="table-responsive d-none d-md-block">
        <InfiniteScroll
          dataLength={currentTeamsPageset}
          next={() => fetchMoreTeams()}
          hasMore={dataCountTeams > currentTeamsPageset}
          className="container-fluid"
        >
          <Table className="mt-4 justify-content-between border-0">
            <thead className="w-100 border-bottom">
              <tr>
                <th className="gap-2 text-left col-4">
                  <div className="fs-12 fw-700 text-start">Team</div>
                </th>

                <th className="gap-2 text-center col">
                  <div className="fs-12 fw-700 text-start">Member</div>
                </th>
              </tr>
            </thead>
            <tbody className="border-0">
              {dataCountTeams && dataCountTeams > 0
                ? teamsList.map((output, index) => {
                    return (
                      <>
                        {showLoader && <Loader />}
                        <tr
                          key={index}
                          className="card-parent fs-14 position-relative border-bottom border-top my-2"
                        >
                          <td className="py-3  align-middle text-start">
                            <div className="fs-14 fw-600 color-primary">
                              {output?.team}
                            </div>
                          </td>

                          <td className="text-start position-relative justify-content-between  align-middle text-start">
                            <div className="color-secondary fs-14 fw-400">
                              {" "}
                              {output?.member}
                            </div>
                            <div className="w-100 onhover-show">
                              <div className="d-flex w-100 justify-content-end link ms-2 align-datas-center me-3">
                                <Link
                                  to={
                                    TEAMS_DETAIL_PAGE_ROUTE + `/${output?.id}`
                                  }
                                  className=" text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark"
                                >
                                  Manage
                                </Link>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })
                : "No Data Found"}
            </tbody>
          </Table>
        </InfiniteScroll>
      </div>

      <div className="d-md-none mt-4 ">
        {dataCountTeams && dataCountTeams > 0
          ? teamsList.map((output, index) => {
              return (
                <Link
                  to={TEAMS_DETAIL_PAGE_ROUTE + `/${output.id}`}
                  key={index}
                  style={{ minWidth: "350px" }}
                  className="text-decoration-none text-black small-referee-cards d-flex row justify-content-center align-items-center mt-3 py-2 mx-1"
                >
                  <div className="d-flex justify-content-between mt-2">
                    <div className="fw-700 fs-12">Team</div>
                    <div className="fs-14 fw-400">{output?.team}</div>
                  </div>
                  <div className="d-flex justify-content-between mt-2">
                    <div className="fw-700 fs-12">Member</div>
                    <div className="fs-14 fw-400">{output?.member}</div>
                  </div>
                </Link>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default TeamsPage;
