export const LOGIN_PAGE_ROUTE = "/login";
export const CANDIDATE_MY_VIEW_PAGE_ROUTE = "/candidate/";
export const RECOMMENDED_JOBS_PAGE_ROUTE = "/candidate/recommended-jobs";
export const ALL_JOBS_PAGE_ROUTE = "/candidate/all-jobs";
export const CANDIDATE_RECOMMENDED_JOBS_PAGE_ROUTE =
  "/candidate/recommended-jobs#application";
export const CANDIDATE_ACCOUNT_PAGE_ROUTE = "/candidate/profile/account";
export const REFEREE_RECOMMENDED_JOBS_PAGE_ROUTE =
  "/candidate/recommended-jobs#referal";
export const REFEREE_SAVED_JOBS_PAGE_ROUTE = "/candidate/referral-saved-jobs";
export const SAVED_JOBS_PAGE_ROUTE = "/candidate/saved-jobs";
export const CANDIDATE_ABOUT_ME_PAGE_ROUTE = "/candidate/profile/about-me";
export const JOB_SEARCH_PREFERENCES_PAGE_ROUTE =
  "/candidate/profile/job-search-preference";
export const REFEREE_PROFILE_HASH = "/candidate#referee";
export const JOB_DETAILS_PAGE_ROUTE = "job";
export const REFEREE_ID_SEARCH_PARAMS = "refereeId";
export const JOB_POSTS_PAGE_ROUTE = "/job-posts";
export const BUSINESS_UPDATE_PAGE = "company-profile";
export const ACCOUNT_PAGE_ROUTE = "account";
export const APPLICATION_ID_PATH_VARIABLE = "applicationId";
export const APPLICATION_PAGE_ROUTE = `application`;
export const APPLICATIONS_MAIN_PAGE_ROUTE = "applications";
export const JOB_POST_CREATE_PAGE_ROUTE = "create-job";
export const TEAM_ID_PATH_VARIABLE = "teamId";
export const INDIVIDUAL_TEAM_INDEX_ID = "individualTeam_Index_Id";
export const INDIVIDUAL_MEMBER_INDEX_ID = "individualMember_Index_Id";



export const TEAM_PAGE_ROUTE = "team";
export const BUSINESS_ID_PATH_VARIABLE = "businessId";
export const BUSINESS_USER_PROFILE_ROUTE = "business-user-profile";
export const JOB_TEMPLATES = "job-templates";
export const JOB_CREATE_TEMPLATE_PAGE = "job-template-page";
export const SUPPORT_AND_FEEDBACK = "support-and-feedback";
export const REFEREE_SCREEN_ROUTE = "referees";
export const INVITE_REFEREE_PAGE_ROUTE = "invite-referees";
export const COMPANY_PROFILE_PAGE_ROUTE = "company-profile";
export const NAVIGATION_SIDEBAR_ROUTE = "";
export const REFEREE_ID_PATH_VARIABLE = "refereeId";
export const REFEREE_DETAILS_PAGE_ROUTE = "/referee";
export const ALL_TEAMS_SCREEN_ROUTE = "all-teams";
// export const BUSINESS_ROUTE = "business/";
// export const REFEREE_DETAILS_PAGE_ROUTE = `/business/referee-details-page`;
export const TEAMS_MEMBERS_ROUTE = `teams-member`;
export const MEMBERS_DETAIL_PAGE_ROUTE = `/member-detail`;
export const TEAMS_DETAIL_PAGE_ROUTE = `/team-detail`;
export const DRAFT_JOB_POST_PAGE_ROUTE = `draft-job-post`;
export const JOIN_VIA_INVITE_ROUTE = "join-invite";
export const ALL_USERS_ROUTE = "all-users";
export const NEW_REQUEST_PAGE_ROUTE = "new-request";
export const REPORTS_PAGE_ROUTE = "reports";
export const ADMIN_JOB_POST_PAGE_ROUTE = "admin-all-jobs";
export const NOTIFICATION_TO_REFEREE_PAGE_ROUTE = "notification-referees";
export const NOTIFICATION_TO_CANDIDATE_PAGE_ROUTE = "notification-candidate";
export const NOTIFICATION_REFEREE_HASH_ROUTE = 
  "/notification-referees#send-notification";
export const NOTIFICATION_CANDIDATE_HASH_ROUTE =
  "/notification-candidate#send-notification";
export const ALL_ORGS_SCREEN_ROUTE = "all-orgs";
export const ADMIN_REFEREE_SCREEN_ROUTE = "admin-referees";
export const INDIVIDUAL_PROFILE_PAGE = "individual-profile";
export const ORG_ID_PATH_VARIABLE = "orgId";
export const ORG_PAGE_ROUTE = "org";
export const SEIKOR_ADMINS_SCREEN = "admins-screen";
export const APPLICATION_HASH_ROUTE = "applications/12063#applications";
export const APPLICATION_ACTION_HASH_ROUTE = "applications/12063#actions";
export const APPLICATION_INSIGHT_HASH_ROUTE = "applications/12063#insignts";
export const DATA_FIELDS_PAGE_ROUTE = "data-fields";
export const APPROVED_DETAIL_PAGE_ROUTE = `/approved`;
export const FOR_APPROVAL_DETAIL_PAGE_ROUTE = `/for-approval`;
export const PREVIEW_PAGE_ROUTE = `/preview`;
export const SCRATCH_PREVIEW_PAGE_ROUTE = `/create-job/scratch-preview`;
