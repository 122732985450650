import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import closeIcon from "../../assests/icons/ic-invite-pg-close.svg";
import addIcon from "../../assests/icons/ic_add.svg";
import IcDoneWhite from "../../assests/icons/ic_done_white.svg";
import Loader from "../../components/common/loader";
import { STATUS_SUCCESS } from "../../config/be_api_constants";
import {
  ALPHABET_CHECK,
  EMAIL_PATTERN,
  REQUIRED,
} from "../../config/validation_patterns_constants";
import {
  isEmpty,
  onFormFeildsChange,
  validateField,
} from "../../utils/form_validators";
import toaster from "../../utils/toaster";
import {
  getAllOrg,
  saveAdminMember,
} from "../../_services/serikoradmin_view.service";

function SeikorAdminAddMemberDialog(props) {
  const formRef = useRef(null);

  const [AllOrg, setAllOrg] = useState();
  const [role, setRole] = useState("SEIKOR_ADMIN");
  const [check, setchecked] = useState(false);
  const [listOrgs, setListOrgs] = useState([]);
  const [allOrgAccess, setAllOrgAccess] = useState(false);
  const [workEmail, setWorkEmail] = useState();

  const [formData, setFormData] = useState({
    workEmail: {
      valueText: "",
      errorText: "",
      check: [REQUIRED, EMAIL_PATTERN],
    },
  });

  const data = {
    workEmail: workEmail,
  };
  const [showLoader, setShowLoader] = useState(false);
  const [eamil, setEmail] = useState();

  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      if (formData?.workEmail?.errorText == "") {
        onSave();
      }
    }
  };

  const onSave = () => {
    let emailValid = true;
    if (ALPHABET_CHECK.test(formData?.workEmail?.valueText)) {
      emailValid = validateField("workEmail", formData, setFormData);

      // if (emailValid) {
      //   setShowLoader(true);

      saveAdminMember(formData?.workEmail?.valueText, role, check, [...listOrgs])
        .then((res) => {
          props?.setAddAdminMemberModalShow(false);
          props?.setAdminAddSuccess(true);
          setEmail(formData?.workEmail?.valueText);
          props?.setApiRefresh(true);

          if (res?.status === 200) {
            setShowLoader(false);
            if (
              EMAIL_PATTERN.test(formData?.workEmail?.valueText) &&
              res?.data?.data?.emailVerified == false
            ) {
              if (props?.verificationNeeded) {
                props?.setWorkEmail(formData?.workEmail?.valueText);
                props?.verificationNeeded();
              }
            } else {
              // navigate("/business/");
            }
           
            toaster("success", res?.data?.message);
            // formRef.current.reset(); // Reset the form
            // setFormData(prevFormData => ({
            //   ...prevFormData,
            //   workEmail: {
            //     valueText: ""
            //   }
            // }));
            setFormData({
              workEmail: {
                valueText: "",
                errorText: "",
                check: ["required"],
              }
             
            });
          }
          
        })
        .catch((err) => {
          setShowLoader(false);
          props?.setApiRefresh(false);
          toaster("error", err);
        });
    }
  };

  const AllOrgData = () => {
    if (props?.addAdminMemberModalShow === true) {
      getAllOrg({ pageNo: 0, pageSize: 0 })
        .then((res) => {
          setShowLoader(false);
          setAllOrg(res?.data?.data);
        })
        .catch((err) => {
          setShowLoader(false);
          toaster("error", err);
        });
    }

  };
  useEffect(() => {
    AllOrgData();
  }, [props?.addAdminMemberModalShow]);

  return (
    <>
      {showLoader && <Loader />}
      <Modal
        show={props?.addAdminMemberModalShow}
        onHide={() => {
          props?.setAddAdminMemberModalShow(false);
        }}
        fullscreen="lg-down"
        className="p-0"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header className="px-4 border-0">
          <Modal.Title className="dialog-title fw-700 fs-20 mt-4">
            Add Member
          </Modal.Title>
          <img
            className="pointer mt-0 pt-0"
            onClick={() => {
              props?.setAddAdminMemberModalShow(false);
            }}
            alt=""
            src={closeIcon}
          />
        </Modal.Header>
        <Modal.Body className=" py-3 px-4 m-0 ">
          <div className="m-0 p-0 overflow-auto" style={{ maxHeight: "450px" }}>
            <label className="modalLabel mt-3 fs-14 fw-600">Work Email</label>
            <input
              onKeyDown={(e) => {
                submitFormOnEnter(e);
              }}
              // className=" form-control modal-input"
              required
              type="email"
              name="workEmail"
              value={formData?.workEmail?.valueText}
              maxLength={50}
              ref={formRef}
              onChange={($event) => {
                onFormFeildsChange($event, formData, setFormData);
                setWorkEmail($event.target.value);
                // props?.setShowEmail($event.target.value);
              }}
              // className={
              //   (formData?.email?.errorText &&
              //   formData?.email?.shouldShowError
              //     ? "error"
              //     : "") + " form-control"
              // }
              className={
                (formData?.workEmail?.errorText
                  ? "error"
                  : "modal-form-input input-color-black") + " form-control"
              }
            />
            {formData.workEmail.errorText && (
              <div className="field-error mt-1">{formData.workEmail.errorText}</div>
            )}
            {/* <div className="field-error mt-1">
            {formData?.email?.shouldShowError
              ? formData?.email?.errorText
              : ""}
          </div> */}
            <div className="d-flex mt-3">
              <div className="fw-600 fs-14 me-3 d-flex align-items-center">
                Role
              </div>
              <div className="d-flex justify-content-center align-items-center me-3 admin-btns-border px-2 py-1 pointer">
                {role === "SUPER_ADMIN" ? (
                  <span
                    style={{ height: "18px" }}
                    className="py-0 bg-black px-1 rounded-circle me-1"
                  >
                    <img className="mb-1" src={IcDoneWhite} />
                  </span>
                ) : (
                  ""
                )}

                <span
                  className="fs-12"
                  onClick={() => {
                    setRole("SUPER_ADMIN");
                    setchecked(true);
                  }}
                >
                  Super Admin
                </span>
              </div>
              <div className="d-flex justify-content-center align-items-center me-3 admin-btns-border px-2 py-1 pointer">
                {role === "SEIKOR_ADMIN" ? (
                  <span
                    style={{ height: "18px" }}
                    className="py-0 px-1 bg-black rounded-circle me-1"
                  >
                    <img className="mb-1" src={IcDoneWhite} />
                  </span>
                ) : (
                  ""
                )}
                <span
                  className="fs-12"
                  onClick={() => {
                    setRole("SEIKOR_ADMIN");
                    setchecked(false);
                  }}
                >
                  Admin
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-between seikor-admin-access-bg mt-3 py-3">
              <div className="fs-14 fw-600 ms-3">Access to all orgs?</div>
              <div className="">
                <span className="form-check form-switch">
                  <div className="">
                    <input
                      type="checkbox"
                      className="form-check-input switch-btn-small pointer"
                      // defaultChecked={check}

                      onChange={(e) => setchecked(e.target.checked)}
                      checked={
                        check
                        // role==="SEIKOR_ADMIN"
                        //             ? true
                        //             : false
                      }
                    />
                  </div>
                </span>
              </div>
            </div>
            {/* <ul
                className="m-0 p-0 overflow-auto"
                style={{ maxHeight: "300px" }}
              > */}
            {AllOrg &&
              AllOrg.map((output, index) => {
                return (
                  <div
                    className="d-flex justify-content-between mt-3"
                    key={index}
                  >
                    <div className="fw-500 fs-14">{output?.name}</div>
                    <div>
                      <input
                        type="checkbox"
                        className="me-2 pointer"
                        onChange={() => {
                          if (!isEmpty(listOrgs)) {
                            const orgMember = [...listOrgs];
                            if (orgMember.includes(output.id)) {
                              let itemIndex = listOrgs?.findIndex(
                                (im) => im === output.id
                              );
                              orgMember.splice(itemIndex, 1);
                            } else {
                              orgMember.push(output.id);
                            }
                            setListOrgs(orgMember);
                          } else {
                            setListOrgs([output.id]);
                          }
                        }}
                        disabled={role === "SUPER_ADMIN" ? true : false}

                      //  checked={
                      //   check

                      // }
                      />

                    </div>
                  </div>
                );
              })}


            {/* <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Amazon</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Microsoft</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Uber</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
          <hr />
          <div className="d-flex justify-content-between">
            <div className="fw-500 fs-14">Cred</div>
            <div>
              <input type="checkbox" className="me-2 pointer" />
            </div>
          </div>
        </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{ background: "#f4f7fb" }}
          className="username-buttons p-3 mt-3"
        >
          <Button
            // onClick={() => {
            //   onSave();
            // }}
            onClick={() => {
              //props.close();
              //saveinvite();
              if (formData?.workEmail?.errorText == "") {
                onSave();
              }
            }}
            className="btn btn-dark btn-save px-5"
          >
            Add Member
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SeikorAdminAddMemberDialog;
