import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";
import { STATUS_SUCCESS } from "../../config/localstorage_keys";
import Loader from "../../components/common/loader";

const CompanyAddress = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [streetAddress, setStreetAddress] = useState();
  const [locality, setLocality] = useState();
  const [pinCode, setPincode] = useState();
  const [state, setState] = useState();
  const [country, setCountry] = useState();

  const [formData, setFormData] = useState({
    streetAddress: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    locality: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    pinCode: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    state: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
    country: {
      valueText: "",
      initial: "",
      errorText: "",
      check: ["required"],
    },
  });

  const saveAddress = {
    companyId: props.companyId,
    streetAddress: streetAddress,
    locality: locality,
    pinCode: pinCode,
    state: state,
    country: country,
  };

  const onSaveAddress = () => {
    if (streetAddress === "") {
      toaster("error", "Please Add Address");
    } else {
      setShowLoader(true);
      create(saveAddress)
        .then((res) => {
          setShowLoader(false);
          toaster("success", "Address updated succesfully..");
          props?.setApiRefresh(true);

          if (res?.data?.status == STATUS_SUCCESS) {
            setStreetAddress(streetAddress);
            setLocality(locality);
            setPincode(pinCode);
            setCountry(country);
          }
          props.onAddressClose();
        })
        .catch((e) => {
          props?.setApiRefresh(false);
          setShowLoader(false);
        });
    }
  };
  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.onAddressClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal dialog-wrapper"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onAddressClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">
                Corporate Address
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body pt- mt-0">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600">
                  Street Address*
                </div>
                <div className="pt-3 pb-5">
                  <input
                    type="text"
                    // className="modal-form-input input-color-black"
                    className={
                      (streetAddress === ""
                        ? "error"
                        : "modal-form-input input-color-black") +
                      " form-control"
                    }
                    // placeholder="21 ISB Road"
                    defaultValue={props.streetAddress}
                    onChange={(e) => {
                      setStreetAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600">Locality</div>
                <div className="pt-3 pb-5">
                  <input
                    type="text"
                    className="modal-form-input input-color-black"
                    // placeholder="Gachibowli"
                    defaultValue={props.locality}
                    onChange={(e) => {
                      setLocality(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="fs-14 color-secondary fw-600">Pincode</div>
                <div className="pt-3 pb-5">
                  <input
                    type="text"
                    className="modal-form-input input-color-black"
                    // placeholder="560068"
                    defaultValue={props.pinCode}
                    onChange={(e) => {
                      setPincode(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex flex-wrap">
                <div className="flex-1">
                  <div className="fs-14 color-secondary fw-600">State</div>
                  <div className="pt-3 pb-5">
                    <input
                      type="text"
                      className="modal-form-input input-color-black"
                      // placeholder="Telangana"
                      defaultValue={props.state}
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="flex-1 ms-2">
                  <div className="fs-14 color-secondary fw-600">Country</div>
                  <div className="pt-3 pb-2">
                    <input
                      type="text"
                      className="modal-form-input input-color-black"
                      // placeholder="India"
                      defaultValue={props.country}
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onAddressClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              onClick={() => {
                onSaveAddress();
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyAddress;
