import React, { useState, useEffect } from "react";
import thumbnailPdfIcon from "../assests/icons/thumbnail-pdf.svg";
import book from "../assests/icons/ic_book.svg";
import thumbnailWordIcon from "../assests/icons/thumbnail-word.svg";
import deleteIcon from "../assests/icons/ic-delete-red.svg";
import uploadIcon from "../assests/icons/ic-upload.svg";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import resumeAddIcon from "../assests/icons/ic-add-filled.svg";
import { useStoreState, useStoreActions } from "easy-peasy";
import toaster from "../utils/toaster";
import {
  downloadFile,
  updateResume,
  uploadFile,
} from "../_services/view.service";
import { getLocalStorage } from "../utils/storage";
import Dropzone from "react-dropzone";
import Loader from "./common/loader";
import { USER_ID } from "./../config/be_api_constants";

const SelectResume = (props) => {
  const [firstResumeSelected, setFirstResumeSelected] = useState(false);
  const [secondResumeSelected, setSecondResumeSelected] = useState(false);
  const candidateDetails = useStoreState(
    (state) => state.candidate.candidateDetails
  );
  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const [isCheck, setIsCheck] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [firstFileUploadedData, setFirstFileUploadedData] = useState();
  const [secondFileUploadedData, setSecondFileUploadedData] = useState();

  const onUploadResume = (acceptedFiles, resumeNumber, prevId = null) => {
    if (acceptedFiles.length > 1) {
      toaster("error", "Multiple Resumes cannot be selected");
      return;
    }
    const fileType = acceptedFiles[0]?.name;
    const isFileExtValid =
      fileType.includes(".doc") || fileType.includes(".pdf");
    if (!isFileExtValid) {
      toaster("error", "Invalid file!");
      return;
    }

    let formData = new FormData();
    const fileObjects = acceptedFiles.map((file) => {
      formData.append("contentData", file);
      formData.append(
        "contentMetaData",
        JSON.stringify({
          contentType: "RESUME",
          contentName: fileType,
          contentExtention: "",
        })
      );
    });
    setShowLoader(true);
    uploadFile(formData)
      .then(
        (response) => {
          if (response?.id) {
            const resumeUpdateObject = {
              fileId: response?.id,
              active: true,
              contentName: fileType,
              fileOrder: resumeNumber,
              previousFileId: prevId,
            };
            if (props?.isApplyForJobComponent && !isCheck) {
              props.setNewCandidateDetails({
                ...props.newCandidateDetails,
                userResumeResponse: [
                  ...props?.newCandidateDetails?.userResumeResponse,
                  { ...resumeUpdateObject, downloadURL: response?.downloadUrl },
                ],
              });
              setShowLoader(false);
            } else {
              updateResume([resumeUpdateObject]).then(
                (data) => {
                  setShowLoader(false);
                  toaster("success", "Resume uploaded successfully!");
                  const userId = getLocalStorage(USER_ID);
                  if (userId) {
                    saveCandidateDetails(userId);
                  }
                  if (props?.isApplyForJobComponent) {
                    props.setNewCandidateDetails({
                      ...props.newCandidateDetails,
                      userResumeResponse: [
                        ...candidateDetails?.userResumeResponse,
                        {
                          ...resumeUpdateObject,
                          downloadURL: response?.downloadUrl,
                        },
                      ],
                    });
                  }
                  // if (resumeNumber === "first") {
                  //   setFirstFileUploadedData(acceptedFiles);
                  // } else {
                  //   setSecondFileUploadedData(acceptedFiles);
                  // }
                },
                (error) => {
                  setShowLoader(false);
                  toaster("error", error);
                }
              );
            }
          }
        },
        (error) => {
          console.log(error);
          toaster("error", error);
          setShowLoader(false);
        }
      )
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  const downloadResume = async (downloadedUrl, filename) => {
    setShowLoader(true);
    downloadFile(downloadedUrl)
      .then((res) => {
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = res;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(res);
        a.parentNode.removeChild(a);
        setShowLoader(false);
      })
      .catch((err) => {
        toaster("error", "Unable to download file");
        setShowLoader(false);
      });
  };

  const selectedResume = (i) => {
    if (props.isApplyForJobComponent && !isCheck) {
      if (
        props.newCandidateDetails?.userResumeResponse &&
        props.newCandidateDetails?.userResumeResponse?.length > 0
      ) {
        // const { userResumeResponse } = candidateDetails;
        // props.applyForJob.resumeId =
        //   props.newCandidateDetails?.userResumeResponse[i - 1]?.fileId;

        let index = props.newCandidateDetails?.userResumeResponse
          .map((resume) => resume.fileOrder)
          .indexOf(i);

        props?.setApplyForJob({
          ...props?.applyForJob,
          resumeId:
            props.newCandidateDetails?.userResumeResponse[index]?.fileId,
        });
      }
    } else if (
      candidateDetails?.userResumeResponse &&
      candidateDetails?.userResumeResponse?.length > 0
    ) {
      // const { userResumeResponse } = candidateDetails;
      // props.applyForJob.resumeId =
      //   candidateDetails?.userResumeResponse[i - 1]?.fileId;
      let index = candidateDetails?.userResumeResponse
        .map((resume) => resume.fileOrder)
        .indexOf(i);

      props?.setApplyForJob({
        ...props?.applyForJob,
        resumeId: candidateDetails?.userResumeResponse[index]?.fileId,
      });
    }
  };

  const fileDetails = (url, fileName, fileOrder) => {
    let dotArray = url.split(".");
    let fileExtension = url.split(".")[dotArray.length - 1];
    // let slashArray = url.split("/");
    // let fileName = slashArray[slashArray.length - 1];
    return { fileName, fileExtension, url, fileOrder };
  };

  useEffect(() => {
    if (
      props.newCandidateDetails?.userResumeResponse &&
      props.newCandidateDetails?.userResumeResponse?.length > 0
    ) {
      const { userResumeResponse } = props.newCandidateDetails;
      let firstIndex = userResumeResponse
        .map((resume) => resume.fileOrder)
        .indexOf(1);
      if (props.newCandidateDetails?.userResumeResponse?.length === 1) {
        props.setIsResumeSelected(true);
        props?.setApplyForJob({
          ...props?.applyForJob,
          resumeId: candidateDetails?.userResumeResponse[0]?.fileId,
        });
        if (firstIndex >= 0) setFirstResumeSelected(true);
        else setSecondResumeSelected(true);
      }
      if (firstIndex >= 0) {
        setFirstFileUploadedData(
          fileDetails(
            userResumeResponse[firstIndex]?.downloadURL,
            userResumeResponse[firstIndex]?.contentName,
            1
          )
        );
      } else {
        setFirstFileUploadedData(null);
      }

      // For Second resume
      let secondIndex = userResumeResponse
        .map((resume) => resume.fileOrder)
        .indexOf(2);

      if (secondIndex >= 0) {
        setSecondFileUploadedData(
          fileDetails(
            userResumeResponse[secondIndex]?.downloadURL,
            userResumeResponse[secondIndex]?.contentName,
            2
          )
        );
      } else {
        setSecondFileUploadedData(null);
      }
    } else {
      setFirstFileUploadedData(null);
      setSecondFileUploadedData(null);
    }
  }, [props.newCandidateDetails]);

  useEffect(() => {
    if (
      candidateDetails?.userResumeResponse &&
      candidateDetails?.userResumeResponse?.length > 0
    ) {
      const { userResumeResponse } = candidateDetails;
      let firstIndex = userResumeResponse
        .map((resume) => resume.fileOrder)
        .indexOf(1);
      if (candidateDetails?.userResumeResponse?.length === 1) {
        props.setIsResumeSelected(true);
        props?.setApplyForJob({
          ...props?.applyForJob,
          resumeId: candidateDetails?.userResumeResponse[0]?.fileId,
        });
        if (firstIndex >= 0) setFirstResumeSelected(true);
        else setSecondResumeSelected(true);
      }

      if (firstIndex >= 0) {
        setFirstFileUploadedData(
          fileDetails(
            userResumeResponse[firstIndex]?.downloadURL,
            userResumeResponse[firstIndex]?.contentName,
            1
          )
        );
      } else {
        setFirstFileUploadedData(null);
      }

      // For Second resume
      let secondIndex = userResumeResponse
        .map((resume) => resume.fileOrder)
        .indexOf(2);

      if (secondIndex >= 0) {
        setSecondFileUploadedData(
          fileDetails(
            userResumeResponse[secondIndex]?.downloadURL,
            userResumeResponse[secondIndex]?.contentName,
            2
          )
        );
      } else {
        setSecondFileUploadedData(null);
      }
    } else {
      setFirstFileUploadedData(null);
      setSecondFileUploadedData(null);
    }
  }, [candidateDetails]);

  return (
    <>
      <div>
        {showLoader && <Loader />}
        {props.isApplyForJobComponent ? (
          <>
            {props.newCandidateDetails?.userResumeResponse?.length === 1 &&
            props?.forQuickApply ? (
              <></>
            ) : (
              <div>
                <div className="about-heading pb-3 pt-4">
                  <img src={book} alt="book-icon" />
                  &nbsp; Resume
                </div>
                <label>
                  <input
                    type="checkbox"
                    onChange={() => setIsCheck(!isCheck)}
                    defaultChecked={isCheck}
                    className="me-2"
                  />
                  Save this to profile
                </label>

                <div className="box-shadow mt-3 p-4">
                  <h5 className="dark-pink-text fs-16 fw-600 mb-3">
                    {props.newCandidateDetails?.userResumeResponse?.length ===
                      2 && !props.isResumeSelected
                      ? "Select 1 resume for your application"
                      : ""}
                  </h5>
                  <div className="upload-resume-wrapper mt-4">
                    <div className="row">
                      <div className="col-lg-6">
                        {!firstFileUploadedData && (
                          <div className="upload-resume d-flex align-items-center justify-content-center">
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                onUploadResume(acceptedFiles, 1);
                              }}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <>
                                    <div className="label d-flex align-items-center justify-content-center">
                                      <img src={resumeAddIcon} alt={"Resume"} />
                                      &nbsp; Upload Resume
                                    </div>
                                    <p className="small-text-gray text-muted text-center mt-2">
                                      PDF, Doc - Upto 5 MB
                                    </p>
                                  </>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        )}
                        {firstFileUploadedData && (
                          <div className="uploaded-resume">
                            <div className="file-wrapper d-flex justify-content-start align-items-center">
                              <div className="file-type">
                                <img
                                  src={
                                    firstFileUploadedData?.fileExtension ===
                                    "pdf"
                                      ? thumbnailPdfIcon
                                      : thumbnailWordIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="file-name">
                                {firstFileUploadedData?.fileName}
                              </div>
                            </div>
                            <div className="file-actions d-flex align-items-center">
                              <div className="link flex-1 small-text-gray">
                                <input
                                  type="radio"
                                  id="select-second"
                                  name="resume"
                                  className="yesNo"
                                  defaultChecked={firstResumeSelected === true}
                                  onClick={() => {
                                    selectedResume(1);
                                    props.setIsResumeSelected(true);
                                    setFirstResumeSelected(true);
                                    setSecondResumeSelected(false);
                                  }}
                                />
                                <label
                                  htmlFor="select-second"
                                  className="text-decoration-none btn rounded-pill border pe-5 ps-5 bg-white fs-12"
                                >
                                  {!firstResumeSelected
                                    ? "Select This"
                                    : "Selected"}
                                </label>
                              </div>
                              {/* <div className="delete text-end">
                            <img src={deleteIcon} alt={"delete"} />
                          </div>
                          <div className="upload text-end">
                            <img src={uploadIcon} alt={"upload"} />
                          </div> */}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-6">
                        {!secondFileUploadedData && (
                          <div className="upload-resume d-flex align-items-center justify-content-center">
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                onUploadResume(acceptedFiles, 2);
                              }}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <>
                                    <div className="label d-flex align-items-center justify-content-center">
                                      <img src={resumeAddIcon} alt={"Resume"} />
                                      &nbsp; Upload Resume
                                    </div>
                                    <p className="small-text-gray text-muted text-center mt-2">
                                      PDF, Doc - Upto 5 MB
                                    </p>
                                  </>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        )}
                        {secondFileUploadedData && (
                          <div className="uploaded-resume">
                            <div className="file-wrapper d-flex justify-content-start align-items-center">
                              <div className="file-type">
                                <img
                                  src={
                                    secondFileUploadedData?.fileExtension ===
                                    "pdf"
                                      ? thumbnailPdfIcon
                                      : thumbnailWordIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="file-name">
                                {secondFileUploadedData?.fileName}
                              </div>
                            </div>

                            <div className="file-actions d-flex align-items-center">
                              <div className="link flex-1 small-text-gray">
                                <input
                                  type="radio"
                                  name="resume"
                                  id="select-first"
                                  className="yesNo"
                                  defaultChecked={secondResumeSelected}
                                  onClick={() => {
                                    props.setIsResumeSelected(true);
                                    selectedResume(2);
                                    setFirstResumeSelected(false);
                                    setSecondResumeSelected(true);
                                  }}
                                />
                                <label
                                  htmlFor="select-first"
                                  className="text-decoration-none btn rounded-pill border pe-5 ps-5 bg-white fs-12"
                                >
                                  {!secondResumeSelected
                                    ? "Select This"
                                    : "Selected"}
                                </label>
                              </div>
                              {/* <div className="delete text-end">
                            <img src={deleteIcon} alt={"delete"} />
                          </div>
                          <div className="upload text-end">
                            <img src={uploadIcon} alt={"upload"} />
                          </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {candidateDetails?.userResumeResponse?.length === 1 &&
            props?.forQuickApply ? (
              <></>
            ) : (
              <div>
                <div className="about-heading pb-3 pt-4">
                  <img src={book} alt="book-icon" />
                  &nbsp; Resume
                </div>
                <div className="box-shadow mt-3 p-4">
                  <h5 className="dark-pink-text fs-16 fw-600 mb-3">
                    {candidateDetails?.userResumeResponse?.length === 2
                      ? "Select 1 resume for your application"
                      : ""}
                  </h5>
                  <div className="upload-resume-wrapper mt-4">
                    <div className="row">
                      <div className="col-lg-6">
                        {!firstFileUploadedData && (
                          <div className="upload-resume d-flex align-items-center justify-content-center">
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                onUploadResume(acceptedFiles, 1);
                              }}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <>
                                    <div className="label d-flex align-items-center justify-content-center">
                                      <img src={resumeAddIcon} alt={"Resume"} />
                                      &nbsp; Upload Resume
                                    </div>
                                    <p className="small-text-gray text-muted text-center mt-2">
                                      PDF, Doc - Upto 5 MB
                                    </p>
                                  </>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        )}
                        {firstFileUploadedData && (
                          <div className="uploaded-resume">
                            <div className="file-wrapper d-flex justify-content-start align-items-center">
                              <div className="file-type">
                                <img
                                  src={
                                    firstFileUploadedData?.fileExtension ===
                                    "pdf"
                                      ? thumbnailPdfIcon
                                      : thumbnailWordIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="file-name">
                                {firstFileUploadedData?.fileName}
                              </div>
                            </div>
                            <div className="file-actions d-flex align-items-center">
                              <div className="link flex-1 small-text-gray">
                                <input
                                  type="radio"
                                  id="select-second"
                                  name="resume"
                                  className="yesNo"
                                  defaultChecked={firstResumeSelected === true}
                                  onClick={() => {
                                    selectedResume(1);
                                    props.setIsResumeSelected(true);
                                    setFirstResumeSelected(true);
                                    setSecondResumeSelected(false);
                                  }}
                                />
                                <label
                                  htmlFor="select-second"
                                  className="text-decoration-none btn rounded-pill border pe-5 ps-5 bg-white fs-12"
                                >
                                  {!firstResumeSelected
                                    ? "Select This"
                                    : "Selected"}
                                </label>
                              </div>
                              {/* <div className="delete text-end">
                            <img src={deleteIcon} alt={"delete"} />
                          </div>
                          <div className="upload text-end">
                            <img src={uploadIcon} alt={"upload"} />
                          </div> */}
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="col-lg-6">
                        {!secondFileUploadedData && (
                          <div className="upload-resume d-flex align-items-center justify-content-center">
                            <Dropzone
                              onDrop={(acceptedFiles) => {
                                onUploadResume(acceptedFiles, 2);
                              }}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()}>
                                  <input {...getInputProps()} />
                                  <>
                                    <div className="label d-flex align-items-center justify-content-center">
                                      <img src={resumeAddIcon} alt={"Resume"} />
                                      &nbsp; Upload Resume
                                    </div>
                                    <p className="small-text-gray text-muted text-center mt-2">
                                      PDF, Doc - Upto 5 MB
                                    </p>
                                  </>
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        )}
                        {secondFileUploadedData && (
                          <div className="uploaded-resume">
                            <div className="file-wrapper d-flex justify-content-start align-items-center">
                              <div className="file-type">
                                <img
                                  src={
                                    secondFileUploadedData?.fileExtension ===
                                    "pdf"
                                      ? thumbnailPdfIcon
                                      : thumbnailWordIcon
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="file-name">
                                {secondFileUploadedData?.fileName}
                              </div>
                            </div>
                            <div className="file-actions d-flex align-items-center">
                              <div className="link flex-1 small-text-gray">
                                {secondResumeSelected}
                                <input
                                  type="radio"
                                  name="resume"
                                  id="select-first"
                                  className="yesNo"
                                  defaultChecked={secondResumeSelected}
                                  onClick={() => {
                                    props.setIsResumeSelected(true);
                                    selectedResume(2);
                                    setFirstResumeSelected(false);
                                    setSecondResumeSelected(true);
                                  }}
                                />
                                <label
                                  htmlFor="select-first"
                                  className="text-decoration-none btn rounded-pill border pe-5 ps-5 bg-white fs-12"
                                >
                                  {!secondResumeSelected
                                    ? "Select This"
                                    : "Selected"}
                                </label>
                              </div>
                              {/* <div className="delete text-end">
                            <img src={deleteIcon} alt={"delete"} />
                          </div>
                          <div className="upload text-end">
                            <img src={uploadIcon} alt={"upload"} />
                          </div> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default SelectResume;
