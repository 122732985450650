import { useStoreActions } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { USER_ID } from "../../config/localstorage_keys";
import { getLocalStorage } from "../../utils/storage";
import { saveUserApplicationNotes } from "../../_services/view.service";

const AddNotes = (props) => {
  const [noteText, setNoteText] = useState("");
  const Character_Limit = 250;
  const [descount, setDesCount] = useState();

  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );

  const saveNotes = () => {
    const userId = getLocalStorage(USER_ID);
    saveUserApplicationNotes(
      props?.applicationDetails?.jobApplication?.applicationId,
      userId,
      noteText
    )?.then((res) => {
      // setNotes([res?.data?.data, ...notes]);
      // const userId = getLocalStorage(USER_ID);
      if (userId) {
        saveCandidateDetails(userId);
      }
      props.viewAllNotes(1, 10, props.applicationId);
    });
    props.onNotesClose();
  };

  useEffect(() => {
    // saveNotes();
  }, [props?.applicationDetails?.jobApplication?.applicationId]);

  return (
    <div>
      <Modal
        show={props.show}
        onHide={() => props.onNotesClose()}
        backdrop="static"
        fullscreen="lg-down"
        size="lg"
        centered
        keyboard={false}
        className="lg-dialog-modal"
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100 ps-3">
            <div className="d-flex justify-content-end">
              <img
                src={icCloseRounded}
                alt="close-icon"
                onClick={() => props.onNotesClose()}
                className="pointer"
                width="30px"
                height="30px"
              />
            </div>
            <div className="fs-24 color-primary fw-700 pt-0 mt-0">
              Add a note
            </div>
            <hr className="me-3"></hr>
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body mt-0 ">
          <div className="p-3 pt-0">
            <div>
              <div className="pb-3">
                <textarea
                  maxLength={Character_Limit}
                  type="text"
                  className="modal-form-input small-text-medium-gray"
                  rows="10"
                  placeholder="Write Here"
                  onChange={(e) => {
                    setNoteText(e.target.value);
                    setDesCount(e.target.value.length);
                  }}
                />
                <h1 className="back-button fs-11 fw-400 mt-0 mx-3">
                  {/* {props.companyDesc}
                        description
                        {props.companyDesc?.length}- */}
                  {descount}-{Character_Limit} Characters Add
                </h1>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="dialog-footer p-4 border-0">
          <button
            className="btn-dialog-cancel pointer"
            onClick={() => props.onNotesClose()}
          >
            Cancel
          </button>
          <button className="btn-dialog-save pointer" onClick={saveNotes}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddNotes;
