import React, { useState } from "react";
import { useEffect } from "react";
import { MASTER_TYPE, STATUS_SUCCESS } from "../../../config/be_api_constants";
import {
  getMaster,
  getReport,
  reportMaster,
} from "../../../_services/serikoradmin_view.service";
import SearchComboBox from "./../../../components/SearchComboBox";
import toaster from "../../../utils/toaster";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Loader from "../../../components/common/loader";

const ReportsPage = () => {
  let initialState = {
    mtype: "",
    FormData: "",
    toDate: "",
  };
  const [reportcategory, setReportCategory] = useState([]);
  const [reportCategoryOutput, setReportCategoryOutput] = useState([]);
  const [reportOutput, setReportOutput] = useState([]);

  const [downloadData, setDownloadData] = useState([]);
  const [downloadReport, setDownloadReport] = useState(initialState);
  const [toDate, setToDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [select_Category, setSelect_Category] = useState();
  const [select_ReportType, setSelect_ReportType] = useState();
  const [dateError, SetDateError] = useState();
  const [to_dateError, SetToDateError] = useState();

  const [form_dateError, SetFromDateError] = useState();


  const getAllMasterData = async () => {
    const reportcategory = await getMaster(MASTER_TYPE.REPORT_CATEGORY);
    let repo = reportcategory.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setReportCategory(repo);
  };

  const noSelectCategory = async (mtype) => {
    if (mtype !== "") {
      reportcategory.map((check) => {
        if (check.name === mtype.toString()) {
          getReport_type(check.description);
        }
      });
    }
  };

  const getReport_type = async (mtype) => {
    const report_type = await getMaster(mtype);
    let report = report_type.map((el) => {
      return { name: el.masterCode, description: el.masterType };
    });
    setSelect_ReportType(report);
  };

  const onSelect_Report = (mtype) => {
    let masterType = mtype.toString();
    setDownloadReport(masterType);
  };

  const ClearFields = () => {
    setReportCategoryOutput([])
    setReportOutput([])
    setFromDate("")
    setToDate("")
  }


  const DownloadFile = (mtype) => {
    let isValid = true;
    if (fromDate == "") {
      SetFromDateError("Enter date");
      isValid = false;
    }
    else if (toDate == "") {
      SetToDateError("Enter date");

      isValid = false;
    }
    else if (fromDate >= toDate) {
      SetDateError("from date greater than to date")
      isValid = false;
    }
    else {
      SetFromDateError("")
      SetDateError("");
    }
    if (isValid) {
      setShowLoader(true);
      getReport(downloadReport, toDate, fromDate)
        .then((res) => {
          if (res !== null && res !== "") {
            var a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = res;
            a.download = downloadReport;
            a.click();
            window.URL.revokeObjectURL(res);
            a.parentNode.removeChild(a);
            setShowLoader(false);
            ClearFields()
          }
          else {
            toaster("error", "File Not Found!!")
            setShowLoader(false);
            ClearFields()
          }
        })
        .catch((err) => {
          toaster("error", "Unable to download file");
          setShowLoader(false);

        });
    }
    else {
      toaster("error", "Enter valid data")
    }
  };
  console.log("downloadData", downloadData);

  useEffect(() => {
    let temp = { ...initialState };
    temp = {
      ...temp,
      FormData: "",
      toDate: "",
    };
    getAllMasterData();
    noSelectCategory();
  }, [select_Category]);

  return (
    <div className="container ">
      {showLoader && <Loader />}
      <div className="content-margin mt-4 col-12 col-md-12 col-lg-8 col-xl-6">
        <div className=" fs-24 fw-700 mb-3">Reports</div>{" "}
        <div className="">
          <div className="fs-14 fw-400 pb-2"> Select Category</div>
          <div>
            <SearchComboBox
              name="report"
              inputData={reportcategory}
              defaultValue={reportCategoryOutput}
              isMultiSelect={false}
              inputCssClass={"modal-input combo-search-box"}
              wrapperCssClass={"form-group"}
              placeholder={"Search or Select"}
              // onSelect={(event) => setSpecializedOutput(event)}
              onSelect={(event) => {
                noSelectCategory(event);
              }}
              searchListHeight={150}
              badgeThemeCssClass={"blue"}
            // hideBadges={true}
            />
          </div>
        </div>
        <div className="">
          <div className="fs-14 fw-400 pb-2">Select report</div>
          <div>
            <SearchComboBox
              inputData={select_ReportType}
              defaultValue={reportOutput}
              isMultiSelect={false}
              inputCssClass={"modal-input combo-search-box"}
              wrapperCssClass={"form-group"}
              placeholder={"Search or Select"}
              onSelect={(event) => onSelect_Report(event)}
              searchListHeight={150}
              badgeThemeCssClass={"blue"}
            // hideBadges={true}
            />
          </div>
        </div>
        <div className="d-flex my-4 justify-content-between flex-wrap gap-4 ">
          <div className="col">
            <div className="fs-14 fw-400 pb-2">From</div>
            <div style={{ border: "1px solid #B9B9B9", borderRadius: "4px" }}>
              <input
                type="date"
                value={fromDate}
                name=""
                id=""
                onChange={(e) => setFromDate(e.target.value)}
                className="btn color-inactive fs-12 fw-400 w-100 h-40 text-start"
              />
            </div>
            <span className="field-error mt-1">
              {form_dateError}
            </span>
          </div>
          <div className="col">
            {" "}
            <div className="fs-14 fw-400 pb-2">To</div>
            <div style={{ border: "1px solid #B9B9B9", borderRadius: "4px" }}>
              <input
                type="date"
                name=""
                id=""
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                className="date btn color-inactive fs-12 fw-400 w-100 h-40  text-start"
              />

            </div>
            <span className="field-error mt-1">
              {dateError}{to_dateError}
            </span>
          </div>
        </div>
        <button
          className="fs-12 btn bg-black mt-2 d-flex h-40 text-white float-end justify-content-center align-items-center m-0 px-4"
          onClick={DownloadFile}
        >
          Download
        </button>
      </div>
    </div>
  );
};

export default ReportsPage;
