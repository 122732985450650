import React from "react";
import icPreview from "../../../../assests/icons/preview.svg";

const JobCardSkills = ({jobViewDetails}) => {
  const skillRequired = [
    "Web Development",
    "Market Research",
    "Leadership",
    "Presentation",
    "Critical Thinking",
    "Communication",
  ];
  return (
    <div>
      <div className="key-skills mt-4">
        <div className="d-flex gap-2 align-items-center">
          <div>
            <img src={icPreview} alt="preview-icon" className="pe-2" />
          </div>
          <div className="fs-14 fw-600 color-primary">Key Skills</div>
        </div>

        {jobViewDetails && jobViewDetails.skillsRequired?.length > 0 && (
          <div className="d-flex flex-wrap mt-2 gap-2">
            {jobViewDetails && jobViewDetails.skillsRequired?.map((skill, index) => (
              <span className="gray-color-badge" key={index}>
                {skill}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default JobCardSkills;
