/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import profileImg from "../../../../assests/images/candidate-profile-pic.jpg";
import icGreenDone from "../../../../assests/icons/ic-green-done.svg";
import DropdownCardShortlisted from "../dropdown_card_shortlisted";
import ApplicationHoverCard from "./application_hover_card";
import icFlag from "../../../../assests/icons/ic-flag.svg";
import icDownload from "../../../../assests/icons/ic-download.svg";
import { checkedItemsHandler } from "../../../../utils/utils";
import ProfileImage from "../../../../components/profile_image";
import { updateApplicationStatus } from "../../../../_services/view.service";
import toaster from "../../../../utils/toaster";

const ApplicationCardShortlisted = (props) => {
  const [selectForDownloadClicked, setSelectForDownloadClicked] =
    useState(false);
  const [cardClicked, setCardClicked] = useState(null);
  const [cardSelected, setCardSelected] = useState([]);

  const statusChange = (appStatus, id) => {
    console.log("buttonclick-->", appStatus);
    updateApplicationStatus(id, appStatus).then((res) => {
      console.log("update Application Status", res);
      // setShowLoader(false);
      props?.getApplicationCardDetails();

      if (res?.data?.message !== "Application Status Updated !!") {
        toaster("error", res?.data?.message, 4000);
      } else {
        toaster("success", res?.data?.message, 4000);
      }
    });
    // setShowLoader(true);
  };

  useEffect(() => {
    if (props?.selectManually) {
      setSelectForDownloadClicked(true);
    } else {
      setSelectForDownloadClicked(false);
    }
  }, [props?.selectManually]);

  console.log("getAppCardsList---------->", props?.applicationCardDetailsList);

  const cardSelectToggle = (index) => {
    checkedItemsHandler(index, cardSelected, setCardSelected);
  };

  return (
    <div className="mt-2">
      <div className="row">
        {props?.applicationCardDetailsList?.applicationList?.map(
          (details, index) => {
            return (
              <div
                className="col-xl-3 col-lg-4 col-sm-6 col-12 mt-3"
                key={index}
              >
                <div
                  className={
                    selectForDownloadClicked
                      ? "application-card p-3 pb-0"
                      : "application-card p-3 "
                  }
                  style={{
                    border:
                      cardClicked === index
                        ? "1px solid #808080 !important"
                        : "",
                  }}
                >
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2 pt-2 ps-2">
                      <div>
                        {/* <img
                        src={profileImg}
                        alt="profile-image"
                        className="rounded-circle"
                      /> */}
                        <ProfileImage
                          src={
                            process.env.REACT_APP_IMAGE_BASEURL +
                            details?.profilePicUrl
                          }
                          // name={sms.senderName}
                          width="40px"
                          height="40px"
                          initialsContainerClass="rounded-circle"
                        />
                      </div>
                      <div>
                        <div className="medium-black-text fs-14 fw-600 text-break">
                          {details?.candidateName}
                        </div>
                        <div className="fs-11 fw-3-- color-tertiary">
                          {details?.candidateDesignation}
                        </div>
                        <div className="fs-11 fw-3-- color-tertiary">
                          {details?.candidateCompany}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      {/* <img src={icThreeDots} alt="retract-icon" /> */}
                      <DropdownCardShortlisted
                        applicationId={details.applicationId}
                        getApplicationCardDetails={() =>
                          props?.getApplicationCardDetails()
                        }
                      />
                    </div>
                  </div>
                  {/* card header finished */}
                  <hr className="mb-1"></hr>
                  <div className="">
                    <div className="d-flex justify-content-between">
                      <div
                        className="dark-red-text fs-12 fw-600 p-2 ps-4 pointer"
                        onClick={() => {
                          statusChange("REJECTED", details.applicationId);
                        }}
                      >
                        Reject
                      </div>
                      <div className="h-1 border"></div>
                      <div
                        className="medium-black-text fs-12 fw-600  p-2 pe-4 pointer"
                        onClick={() => {
                          props.isAssessment
                            ? statusChange("OFFERED", details.applicationId)
                            : statusChange("ASSESSMENT", details.applicationId);
                          // props.isAssessment ? toaster("success", "Offered successfully")
                          //   : toaster("success", "Assessment successfully")
                        }}
                      >
                        {props.isAssessment ? "Offer" : "Assessment"}
                      </div>
                    </div>
                  </div>
                  <hr className="mt-1"></hr>
                  {/* referral section */}
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="fs-11 color-tertiary fw-300">
                        Referral
                      </div>
                      {details?.isRefferal === true ? (
                        <div className="d-flex gap-1 align-items-center">
                          <div>
                            <img src={icGreenDone} alt="green-done icon" />
                          </div>
                          <div className="dark-green-text fs-11 fw-600">
                            {/* {details?.referralStatus} */}Yes
                          </div>
                        </div>
                      ) : (
                        <div className="color-tertiary fs-11 fw-600">
                          {/* {details?.referralStatus} */}No
                        </div>
                      )}
                    </div>
                    <div className="text-end">
                      <div className="fs-11 color-tertiary fw-300">
                        Experience
                      </div>

                      <div className="fade-gray-text fs-11 fw-500">
                        {details?.experience} Years | {details?.roles} Roles
                      </div>
                    </div>
                  </div>
                  <hr className="mt-2"></hr>
                  {/* skills section */}
                  <div className="pb-3">
                    <div className="dark-gray-color fs-11 fw-600">SKILLS</div>
                    <div className="d-flex flex-wrap ">
                      {details?.skills?.map((skill, index) => {
                        return (
                          <div className="pink-color-badge mt-2 me-2">
                            {skill}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  {/* qualification section */}
                  {!props.isAssessment && (
                    <div>
                      <div className="dark-gray-color fs-11 fw-600">
                        QUALIFICATIONS (5)
                      </div>
                      <div className="d-flex flex-wrap ">
                        {details?.qualification?.map((qualification, index) => {
                          return (
                            <div className="gray-color-badge mt-2 me-2">
                              {qualification}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {selectForDownloadClicked && (
                    <div>
                      <hr className="mb-1"></hr>
                      <div className="d-flex mb-1 gap-1">
                        <div className="bg-green-icon d-flex align-item-center p-1">
                          <img src={icFlag} alt="flag-icon" />
                        </div>
                        <div className="bg-gray-icon d-flex align-item-center p-1">
                          <img src={icDownload} alt="download-icon" />
                        </div>
                      </div>
                      <div className="side-margin-width">
                        <hr className="m-0 p-0"></hr>
                        <div className="d-flex justify-content-between pt-2 select-for-export ps-3 pe-3">
                          <div className="fs-11 color-primary fw-600">
                            Select for Export
                          </div>
                          <div className="form-check form-switch">
                            <div
                              className=""
                              onClick={() => {
                                cardSelectToggle(index);
                              }}
                            >
                              <input
                                type="checkbox"
                                className="form-check-input switch-btn-small pointer color-primary"
                                checked={
                                  cardSelected.includes(index) ? true : false
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {selectForDownloadClicked === false && (
                    <div className="application-hover-card">
                      <ApplicationHoverCard
                        applicationId={details?.applicationId}
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default ApplicationCardShortlisted;
