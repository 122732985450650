import React, { useEffect, useState } from "react";
import profileImg from "./../../../assests/images/profile.jpg";
import icChat from "../../../assests/icons/ic-chat.svg";
import icSmallPinkDot from "./../../../assests/icons/ic-small-pink-dot.svg";
import ChatBox from "../../../dialogs/application_dialogs/chatbox";
import RefereeProfile from "../../../dialogs/application_dialogs/referee_profile";
import {
  downloadFile,
  getRefereeBarChart,
  getRefereeProfile,
} from "../../../_services/view.service";
import PaymentRequest from "../../../dialogs/application_dialogs/requestpayment";
import { useStoreState } from "easy-peasy";
import { STATUS_SUCCESS, USER_ROLE } from "../../../config/be_api_constants";
import ProfileImage from "../../../components/profile_image";
import { applicationReferralsNotifyForRecommendation } from "../../../_services/serikoradmin_view.service";
import toaster from "../../../utils/toaster";

const ApplicationReferral = ({ applicationDetails }, props) => {
  const [showMessages, setShowMessages] = useState(false);
  const [showRefereeProfile, setShowRefereeProfile] = useState(false);
  const [refereeProfile, setRefereeProfile] = useState([]);
  const [refereeBarChartData, setRefereeBarChartData] = useState({});
  const [profileSrc, setProfileSrc] = useState(null);

  const downloadPicture = async (downloadedUrl) => {
    downloadFile(downloadedUrl).then((res) => {
      if (res) {
        setProfileSrc(res);
      } else {
        setProfileSrc(null);
      }
    });
  };

  console.log("appDets --> :)", applicationDetails?.jobApplication?.jobId)

  const getProfilePicture = () => {
    if (
      refereeProfile?.basicDetailsResponse?.profilePicDownloadURL !==
      (undefined || null)
    ) {
      downloadPicture(
        refereeProfile?.basicDetailsResponse?.profilePicDownloadURL
      );
    } else {
      setProfileSrc(null);
    }
  };

  console.log("Referee Profile---------->", refereeProfile);
  const [referalId, setReferralId] = useState();
  const [userId, setUserId] = useState();

  useEffect(() => {
    getRefereeProfile(applicationDetails?.jobApplication?.referalId)?.then(
      (res) => {
        setRefereeProfile(res?.data?.data);
      }
    );
    setReferralId(applicationDetails?.jobApplication?.referalId);
    
  }, [showRefereeProfile, applicationDetails]);

  useEffect(() => {
    getProfilePicture();
  }, [refereeProfile]);

  useEffect(() => {
    getRefereeBarChart(applicationDetails?.jobApplication?.userId)?.then(
      (res) => {
        setRefereeBarChartData(res?.data?.data);
      } 
      
    );
    setUserId(applicationDetails?.jobApplication?.userId)
  }, [showRefereeProfile, applicationDetails]);

  const notifyForRecommendation = () => {
    applicationReferralsNotifyForRecommendation(applicationDetails?.jobApplication?.jobId, applicationDetails?.jobApplication?.userId, applicationDetails?.jobApplication?.referalId).then((res) => {
      if(res?.data?.status === STATUS_SUCCESS){
        toaster("success", res?.data?.message, 5000)
        console.log("success", res)
      }
    })
  }

  const [showRequestPayment, setShowRequestPayment] = useState(false);
  const [showRecommendationBtn, setShowRecommendationBtn] = useState(true);
  const role = useStoreState((state) => state?.candidate?.role);

  console.log("first avatar--->", applicationDetails?.referralRecommendationResponse?.candidateSkills === [""])

  const skills = [
    "Impressive Skills",
    "Presentation Skills",
    "Great in communication",
  ];
  return (
    <div>
      <div className="shadow-box mt-4">
        <div className="p-3">
          <div className="fs-24 color-primary fw-700">Referral</div>
          <div className="small-text-gray pt-2 "> Referred by </div>
          <div className="d-flex flex-wrap justify-content-between gap-2">
            <div className="d-flex gap-2 pt-3">
              <div>
                {/* <img
                  src={profileImg}
                  alt="referee-profile-img"
                  className="rounded-circle"
                  width="48px"
                  height="48px"
                /> */}
                <ProfileImage
                  src={profileSrc}
                  name="profile-img"
                  initialsContainerClass="rounded-circle"
                  width="40px"
                  height="40px"
                />
              </div>
              <div>
                <div className="medium-black-text fs-14 fw-600">
                  {refereeProfile?.userRegistrationDetails?.name}
                </div>
                <div className="color-tertiary fs-11 fw-300">
                  {
                    refereeProfile?.additionalInfoProfileResponse
                      ?.currentDesignation
                  }
                </div>
                <div className="color-tertiary fs-11 fw-300">
                  {" "}
                  {refereeProfile?.additionalInfoProfileResponse?.company}{" "}
                </div>
              </div>
            </div>
            <div className="d-flex gap-2 pt-3">
              <div className="position-relative">
                {/* <div
                  className="chat-box-border"
                  onClick={() => setShowMessages(true)}
                >
                  <img src={icChat} alt="chat-icon" />
                </div> */}
                <button
                  className="chat-box-border btn-dialog-cancel"
                  onClick={() => setShowMessages(true)}
                >
                  <img src={icChat} alt="chat-icon" />
                </button>
                <div className="position-absolute dot-icon-position">
                  <img src={icSmallPinkDot} alt="small-pink-dot" />
                </div>
              </div>
              <div>
                <button
                  className="btn-dialog-cancel pointer view-profile-width"
                  onClick={() => setShowRefereeProfile(true)}
                >
                  View Profile
                </button>
              </div>
            </div>
          </div>
          <hr></hr>
          <div>
            <div className="d-flex flex-wrap justify-content-between gap-2">
              <div className="color-primary fs-20 fw-700 pt-2">
                Recommendation
              </div>
              {
                // (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) &&
                applicationDetails?.referralRecommendationResponse
                  ?.candidateRating < 1 &&
                applicationDetails?.referralRecommendationResponse
                  ?.candidateSkills?.length > 0 &&
                applicationDetails?.referralRecommendationResponse
                  ?.commentCandidate === ""
                  ? showRecommendationBtn && (
                      <button
                        className="btn btn-dark fs-12 fw-700"
                        // onClick={() => setShowRequestPayment(true)}
                        onClick={() => notifyForRecommendation()}
                      >
                        Request Recommendation
                      </button>
                    )
                  : ""
              }
            </div>
            <div className="dark-black-text fw-700 fs-14 pt-4 pb-2">
              Rating of the candidate
            </div>
            <div className="fs-12 color-tertiary">
              On a scale of 1-5, how would you rate this candidate for this role
            </div>
            {applicationDetails?.referralRecommendationResponse
              ?.candidateRating > 0 ? (
              <div className="color-tertiary fs-32">
                {
                  applicationDetails?.referralRecommendationResponse
                    ?.candidateRating
                }
                /5
              </div>
            ) : (
              <div className="fs-12 dark-red-text">Not provided</div>
            )}
          </div>
          <hr></hr>
          <div className="pt-0 pb-3">
            <div className="dark-black-text fw-700 fs-14">
              Top 3 reasons to refer this candidate
            </div>
            <div className="d-flex flex-wrap gap-2 pt-2">
              {applicationDetails?.referralRecommendationResponse
                ?.candidateSkills.length > 0 ? (
                applicationDetails?.referralRecommendationResponse?.candidateSkills.map(
                  (skill, index) => (
                    <div className="blue-color-badge" key={index + 1}>
                      {skill}
                    </div>
                  )
                )
              ) : (
                <div className="fs-12 dark-red-text">Not provided</div>
              )}
            </div>
          </div>
          <hr></hr>
          <div className="pt-0 pb-3">
            <div className="dark-black-text fw-700 fs-14">
              Feedback on Candidate
            </div>
            {applicationDetails?.referralRecommendationResponse
              ?.commentsBusiness !== "" &&
            applicationDetails?.referralRecommendationResponse
              ?.commentsBusiness !== null ? (
              <div className="fs-12 color-tertiary">
                {/* I have personally worked with Amon for over 2 years and i would
              highly recommend him for this role based on his skills, his
              dedication to work and experience with such tasks. */}
                {
                  applicationDetails?.referralRecommendationResponse
                    ?.commentsBusiness
                }
              </div>
            ) : (
              <div className="fs-12 dark-red-text">Not provided</div>
            )}
          </div>
        </div>
      </div>
      <ChatBox
        show={showMessages}
        onMessagesClose={() => setShowMessages(false)}
        applicationDetails={applicationDetails}
      />
      <RefereeProfile
        refereeBarChartData={refereeBarChartData}
        refereeProfile={refereeProfile}
        userId={userId}
         referalId={referalId}
        applicationDetails={applicationDetails}
        show={showRefereeProfile}
        onRefereeProfileClose={() => setShowRefereeProfile(false)}
      />
      <PaymentRequest
        show={showRequestPayment}
        onRequestPaymentClose={() => setShowRequestPayment(false)}
        onShowReferralBonus={() => {
          props?.onShowReferralBonus();
          setShowRecommendationBtn(false);
        }}
      />
    </div>
  );
};

export default ApplicationReferral;
