import React from "react";
import experienceIcon from "./../../../../assests/icons/ic-experience.svg";

const JobCardExperience = ({ text, jobViewDetails }) => {
  return (
    <div className="experience d-flex align-items-center">
      <img src={experienceIcon} alt="experience" />
      {/* &nbsp;{`${text ? text : "2-5 Yrs"}`} */}
      {jobViewDetails && jobViewDetails?.minExpRequiredMonths}-{jobViewDetails && jobViewDetails?.maxExpRequiredMonths}Yrs
    </div>
  );
};
export default JobCardExperience;
