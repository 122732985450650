import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import "../../pages/auth_protected/jobs_post_page/job-post-style.scss";
import scratchStart from "../../assests/scratchStart.svg";
import RightArrow from "../../assests/Vector.svg";
import copyPrevious from "../../assests/copyPrevious.svg";
import orgTemplate from "../../assests/orgTemplate.svg";
import icCloseRounded from "../../assests/closebtn.svg";
import tick from "../../assests/icons/ic-combo-tick.svg";

import { useState } from "react";
import CopyPreviousJobPostModal from "./copy-previous-job-modal";
import { JOB_POST_CREATE_PAGE_ROUTE } from "../../config/page_routes_constants";
import { Link } from "react-router-dom";
import { checkedItemsHandler } from "../../utils/utils";
import { DEFAULT_PAGE_SIZE } from "../../config/be_seikorAdmin_api_constants";

const AllFiltersMobileModal = (props) => {
  const [activeClassIndex, setActiveClassIndex] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const CUSTOM_DATE_OPTION = "Custom";
  // const [arrayAllTeamsIndex, setArrayAllTeamsIndex] = useState([]);
  // const [arrayAllStatusIndex, setArrayAllStatusIndex] = useState([]);
  // const [arrayAllTimeIndex, setArraAllTimeIndex] = useState(null);

  const dropdownAllTimeHandler = (index, value) => {
    props?.setArrayAllTimeIndex(index);
    setFromDate(value);
  };
  const dropdownPostByArrayHandler = (index) => {
    props?.setPostByArrayIndex(index);
  };
  const dropdownAllTeamsHandler = (index) => {
    checkedItemsHandler(
      index,
      props?.arrayAllTeamsIndex,
      props?.setArrayAllTeamsIndex
    );
  };
  const dropdownAllStatusHandler = (index) => {
    checkedItemsHandler(
      index,
      props?.arrayAllStatusIndex,
      props?.setArrayAllStatusIndex
    );
  };

  const handleResetBtn = () => {
    props?.setArrayAllTeamsIndex([]);
    props?.setArrayAllStatusIndex([]);
    props?.setArrayAllTimeIndex(null);
    props?.setPostByArrayIndex(null);
  };
  const handleApply = () => {
    // props?.setArrayAllTimeIndex(props?.arrayAllTimeIndex);
    // props?.setArrayAllTeamsIndex(props?.arrayAllTeamsIndex);
    // props?.setArrayAllStatusIndex(props?.arrayAllStatusIndex);
    props?.fetchAllJobs({
      ...props?.allJobsPostFilterData,
      jobStatus: props?.allStatus,
      teamId: props?.teamArray,
      fromDate: fromDate,
      toDate: props?.date,
      pageNo: 1,
      pageSize: DEFAULT_PAGE_SIZE,
    });
    props?.close();
  };

  return (
    <div className="radius19">
      <Modal
        animation={false}
        dialogClassName="w-100 margin-0-imp"
        contentClassName="all-team-pos-bottom"
        show={props?.show}
        scrollable={true}
        onHide={() => {
          props?.close();
          setActiveClassIndex(0);
        }}
      >
        <Modal.Header className="dialog-header border-0  pt-3 pb-0">
          <div className="fs-12 color- fw-700 small-text-gray pb-2">
            FILTERS
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            height="14px"
            width="14px"
            onClick={() => {
              props?.close();
              setActiveClassIndex(0);
            }}
            className="pointer mb-2"
          />
        </Modal.Header>

        <Modal.Body className="dialog-body d-flex m-0 p-0">
          <nav
            className=""
            style={{
              width: "30%",

              minWidth: "100px",
            }}
          >
            <Link
              to=""
              className={`border-top  h-48 d-flex gap-3 filter-sidebar-div px-3 ${
                activeClassIndex === 0 ? "active" : ""
              }`}
              onClick={() => {
                setActiveClassIndex(0);
              }}
            >
              <span className="fs-12 fw-600">Status</span>
            </Link>
            <Link
              to=""
              className={`border-top   h-48 d-flex gap-3 filter-sidebar-div  px-3 ${
                activeClassIndex === 1 ? "active" : ""
              }`}
              onClick={() => {
                setActiveClassIndex(1);
              }}
            >
              <span className="fs-12 fw-600">Time</span>
            </Link>
            <Link
              to=""
              className={`border-top border-bottom h-48 d-flex gap-3 filter-sidebar-div  px-3 ${
                activeClassIndex === 2 ? "active" : ""
              }`}
              onClick={() => {
                setActiveClassIndex(2);
              }}
            >
              <span className="fs-12 fw-600">Teams</span>
            </Link>
            {props?.dropdownPostByArray && (
              <Link
                to=""
                className={`border-top border-bottom h-48 d-flex gap-3 filter-sidebar-div  px-3 ${
                  activeClassIndex === 3 ? "active" : ""
                }`}
                onClick={() => {
                  setActiveClassIndex(3);
                }}
              >
                <span className="fs-12 fw-600">Post By</span>
              </Link>
            )}
          </nav>
          <ul
            style={{
              minHeight: "300px",
              marginRight: "-1rem",
            }}
            className="border-top border-start w-100 pt-3  m-0 px-3"
          >
            {activeClassIndex === 0 &&
              props?.dropdownAllStatusArray.map((sortItem, index) => {
                return (
                  <li
                    key={index}
                    className="d-flex pb-3 fs-12 fw-400 pointer"
                    onClick={() => {
                      dropdownAllStatusHandler(index);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="me-2 pointer"
                      checked={
                        props?.arrayAllStatusIndex.includes(index)
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        props.checkAllStatusHandler(
                          e?.target?.checked,
                          sortItem.value
                        );
                      }}
                    />{" "}
                    <label className="pointer">{sortItem.name}</label>
                  </li>
                );
              })}
            {activeClassIndex === 1 &&
              props?.dropdownAllTimeArray.map((sortItem, index) => {
                return (
                  <li
                    key={index}
                    className="d-flex flex-column pb-3 fs-12 fw-400 pointer"
                    onClick={() => {
                      if (props?.arrayAllTimeIndex == index) {
                        dropdownAllTimeHandler(null);
                        props?.setTimeDropdownRedDot(false);
                      } else {
                        // props?.dropdownAllTimeHandler(index);
                        if (sortItem == CUSTOM_DATE_OPTION) {
                          if (
                            props?.customStartDate == "" ||
                            props?.customEndDate == ""
                          ) {
                            props?.setTimeDropdownRedDot(false);
                          }
                          props?.setShowDatePickerModal(true);
                        } else {
                          dropdownAllTimeHandler(index, sortItem.value);
                          props?.setShowDatePickerModal(false);
                          props?.setTimeDropdownRedDot(true);
                        }
                      }
                    }}
                  >
                    <div className="d-flex">
                      {" "}
                      <img
                        src={tick}
                        alt="blackdone-icon"
                        className={
                          props?.arrayAllTimeIndex === index
                            ? "pe-2 d-block"
                            : "d-none pe-1"
                        }
                      />
                      <span
                        className={
                          props?.arrayAllTimeIndex === index
                            ? "ps-0 color-primary fw-700"
                            : "ps-4"
                        }
                      >
                        {sortItem.name}
                      </span>
                    </div>

                    {sortItem == CUSTOM_DATE_OPTION &&
                    props?.arrayAllTimeIndex == 5 &&
                    props?.customStartDate != "" &&
                    props?.customEndDate != "" ? (
                      <div
                        style={{ paddingLeft: "20px" }}
                        className="small-text-gray fs-12 mt-2"
                      >
                        {`${props?.customStartDate} to ${props?.customEndDate}`}
                      </div>
                    ) : null}
                  </li>
                );
              })}

            {activeClassIndex === 2 &&
              props?.dropdownAllTeamsArray.map((sortItem, index) => {
                return (
                  <li
                    key={index}
                    className="d-flex pb-3 fs-12 fw-400 pointer"
                    onClick={(e) => {
                      dropdownAllTeamsHandler(index);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="me-2 pointer"
                      checked={
                        props?.arrayAllTeamsIndex.includes(index) ? true : false
                      }
                      onChange={(e) => {
                        props?.checkAllTeamsHandler(
                          e?.target?.checked,
                          sortItem
                        );
                      }}
                    />{" "}
                    <label className="pointer">{sortItem.team}</label>
                  </li>
                );
              })}
            {activeClassIndex === 3 &&
              props?.dropdownPostByArray.map((sortItem, index) => {
                return (
                  <li
                    key={index}
                    className="d-flex pb-3 fs-12 fw-400 pointer"
                    onClick={() => {
                      if (props?.postByArrayIndex == index) {
                        dropdownPostByArrayHandler(null);
                      } else {
                        dropdownPostByArrayHandler(index);
                      }
                    }}
                  >
                    <img
                      src={tick}
                      alt="blackdone-icon"
                      className={
                        props?.postByArrayIndex === index
                          ? "pe-2 d-block"
                          : "d-none pe-1"
                      }
                    />
                    <span
                      className={
                        props?.postByArrayIndex === index
                          ? "ps-0 color-primary fw-700"
                          : "ps-4"
                      }
                    >
                      {sortItem}
                    </span>
                  </li>
                );
              })}
          </ul>
        </Modal.Body>
        <Modal.Footer
          style={{
            backgroundColor: "#F4F7FB",
          }}
          className="position-absolute bottom-0 d-sm-block d-md-none p-0 pb-2 m-0 w-100"
        >
          <Link
            className="btn down-arrow col-6 gap-2 btn-outline-none  fs-14 fw-700"
            to=""
            onClick={handleResetBtn}
          >
            Reset
          </Link>

          <Link
            className="btn down-arrow col-5 gap-2 btn-outline-none fs-14 fw-700 border-start"
            to=""
            onClick={handleApply}
          >
            Apply
          </Link>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AllFiltersMobileModal;
