import React from "react";
import educationLogo from "../../../../assests/icons/ic_education.svg";
import NoDataFoundCard from "../../../../components/common/no_data_found_card";
import { NO_EDUCATION_ADDED } from "../../../../config/messages";

const RefereeEducationDetails = ({refereeProfile}) => {
  
  const education = [
    {
      startMonth: "Jun",
      startYear: "2020",
      endMonth: "Present",
      endYear: "",
      degreeName: "Doctorate",
      collegeName: "Kings University, Sydney",
      subjectName: "Doctor of Science (DSc) , Sales forecasting techniques.",
    },
    {
      startMonth: "Apr",
      startYear: "2018",
      endMonth: "Mar",
      endYear: "2020",
      degreeName: "Post-Graduation",
      collegeName: "ISB Hyderabad",
      subjectName:
        "Master of Business Administration (MBA) , Applied Statistics”",
    },
    {
      startMonth: "Apr",
      startYear: "2018",
      endMonth: "Mar",
      endYear: "2020",
      degreeName: "Graduation",
      collegeName: "NIT Hamirpur",
      subjectName: "Bachelor of technology (Btech) , Computer Science",
    },
  ];
  return (
    <div className="pt-0">
      <div className="my-3">
        <span className="fw-bold fs-24">
          <img
            style={{ marginRight: "14px" }}
            src={educationLogo}
            alt={educationLogo}
          />
          Education and Academics
        </span>
      </div>
      {refereeProfile?.educationalExperienceResponse?.length > 0 ? (
      <div className="shadow-box p-4">
        {refereeProfile?.educationalExperienceResponse?.map((edu, index) => {
          return (
            <>
              <div
                className={
                  index === refereeProfile?.educationalExperienceResponse?.length - 1 ? "" : "card-container"
                }
                key={index}
              >
                <div className={index === 0 ? "pt-1" : "pt-3"}>
                  <div>
                    <span className="gray-color-badge">
                      {edu?.courseStartDate} - {edu?.courseEndDate}
                    </span>
                  </div>
                  <div className="color-primary fw-600 fs-16 py-2">
                    {edu?.qualification}
                  </div>
                  <div className="color-secondary fs-14 ">
                    {edu?.institute}
                  </div>
                  <div className="color-tertiary fs-12">{edu.degreeName},{edu.specialization}</div>
                  <div className="fs-11 color-secondary fw-600 pt-2">
                    {edu?.outcome}
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
      ) : (
        <NoDataFoundCard text={NO_EDUCATION_ADDED} />
      )}
    </div>
  );
};

export default RefereeEducationDetails;
