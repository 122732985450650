import React from "react";
import icMail from "../../../assests/icons/mail_icon.svg";
import icMailLogo from "../../../assests/icons/ic_mail.svg";
import icPhone from "../../../assests/icons/ic_phone.svg";
import icLocation from "../../../assests/icons/ic_location12.svg";
import icLinkedin from "../../../assests/icons/ic_linkedin.svg";
import icAttachment from "../../../assests/icons/ic_attachment.svg";
import icGreenDone from "../../../assests/icons/ic-green-admin.svg";
import { useStoreState } from "easy-peasy";
import { USER_ROLE } from "../../../config/be_api_constants";
import { Link } from "react-router-dom";

const ApplicationContactOnlinePresence = ({
  contactAndOnlinePresenceDetails,
  applicationDetails,
  contactDetails,
  profilePage,
  isApplicationPage,
}) => {
  console.log(
    "check===>",
    applicationDetails?.viewProfileResponse?.socialLinksResponses
      ? "data"
      : applicationDetails?.socialLinksResponses
  );
  const role = useStoreState((state) => state?.candidate?.role);
  return (
    <div className="mt-5">
      <div className="fw-bold fs-24 fw-700 d-flex gap-2">
        <img src={icMail} alt="icon" />
        <span> Contact and Online presence </span>
      </div>

      <div className="mt-4 shadow-box border-radius p-3">
        <div className="fs-14 color-primary fw-600 d-flex gap-2">
          <img src={icMailLogo} alt="@svg" />
          <span>Email</span>
        </div>
        <div className="color-tertiary fw-400 fs-14 mt-2">
          {applicationDetails?.viewProfileResponse?.userRegistrationDetails
            ?.email || applicationDetails?.userRegistrationDetails?.email}
        </div>
      </div>

      <div className="d-flex flex-wrap flex-sm-row flex-column gap-3 mb-4">
        <div className="flex-1 shadow-box border-radius p-3 mt-3">
          <div className="fs-14 color-primary fw-600 d-flex gap-2 ">
            <img src={icPhone} alt="phone-icon" />
            <span>Phone</span>
          </div>
          <div className="color-tertiary fw-400 fs-14 mt-2">
            {applicationDetails?.viewProfileResponse?.userRegistrationDetails
              ?.mobile || applicationDetails?.userRegistrationDetails?.mobile}
            {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) &&
              profilePage && (
                <div className="d-flex align-items-center">
                  <div>
                    <img src={icGreenDone} />
                  </div>
                  <div className="text-green fs-11 fw-600"> Verified</div>
                </div>
              )}
          </div>
        </div>

        <div className="flex-1 shadow-box border-radius p-3 mt-sm-3 mt-2">
          <div className="fs-14 color-primary fw-600 d-flex gap-2">
            <img src={icLocation} alt="phone-icon" />
            <span>Current Location</span>
          </div>
          <div className="color-tertiary fw-400 fs-14 mt-2">
            {applicationDetails?.viewProfileResponse
              ?.additionalInfoProfileResponse?.currentLocation ||
              applicationDetails?.additionalInfoProfileResponse
                ?.currentLocation}
          </div>
        </div>
      </div>
      {/* Linkedin box */}
      <div className="shadow-box border-radius mt-3 pb-3 pl-3 pr-3 gap-2 pt-3 ">
        {(applicationDetails?.viewProfileResponse?.socialLinksResponses
          ? applicationDetails?.viewProfileResponse?.socialLinksResponses
          : applicationDetails?.socialLinksResponses
        )?.map((socialLinks, i) => {
          return (
            <div>
              <div className="d-flex mt-2">
                <div className="col-2 col-sm-1">
                  <img
                    src={icLinkedin}
                    alt="linkedin-icon"
                    className="ps-4 p-1"
                  />
                </div>
                <div className="col-10 col-sm-11">
                  <div className="fs-16 fw-600 color-primary">
                    {socialLinks?.linkTitle}
                  </div>
                  <div className="fs-14 color-tertiary fw-400 text-break">
                    <a href={socialLinks?.linkUrl} target="_blank">
                      {socialLinks?.linkUrl}
                    </a>
                  </div>
                </div>
              </div>
              {/* {i?.length === socialLinks?.length - 1 ? "" : <hr className="ms-4 me-4"></hr>} */}
              <hr className="ms-4 me-4"></hr>
            </div>
          );
        })}
        {/* <div className="d-flex mt-2">
          <div className="col-2 col-sm-1">
            <img src={icLinkedin} alt="linkedin-icon" className="ps-4 p-1" />
          </div>
          <div className="col-10 col-sm-11">
            <div className="fs-16 fw-600 color-primary">LinkedIn</div>
            <a
              href={contactDetails?.linkedInLink}
              className="color-tertiary fs-14 fw-400 text-break text-decoration-none"
            >
              {contactDetails?.linkedInLink}
            </a>
          </div>
        </div>
        <hr className="ms-4 me-4"></hr>
        <div className="d-flex">
          <div className="col-2 col-sm-1">
            <img src={icAttachment} alt="linkedin-icon" className="ps-4 p-1" />
          </div>
          <div className="col-10 col-sm-11">
            <div className="fs-16 fw-600 color-primary">
              {contactDetails?.link1name}
            </div>
            <a
              href={contactDetails?.link1link}
              className="color-tertiary fs-14 fw-400 text-break text-decoration-none"
            >
              {contactDetails?.link1link}
            </a>
          </div>
        </div>
        <hr className="ms-4 me-4"></hr>
        <div className="d-flex ">
          <div className="col-2 col-sm-1">
            <img src={icAttachment} alt="linkedin-icon" className="ps-4 p-1" />
          </div>
          <div className="col-10 col-sm-11">
            <div className="fs-16 fw-600 color-primary">
              {contactDetails?.link2link}
            </div>
            <a
              href={contactDetails?.link2link}
              className="color-tertiary fs-14 fw-400 text-break text-decoration-none"
            >
              {contactDetails?.link2link}
            </a>
          </div>
        </div>
        <hr className="ms-4 me-4"></hr>
        <div className="d-flex">
          <div className="col-2 col-sm-1">
            <img src={icAttachment} alt="linkedin-icon" className="ps-4 p-1" />
          </div>
          <div className="col-10 col-sm-11">
            <div className="fs-16 fw-600 color-primary">
              {contactDetails?.link3link}
            </div>
            <a
              href={contactDetails?.link3link}
              className="color-tertiary fs-14 fw-400 text-break text-decoration-none"
            >
              {contactDetails?.link3link}
            </a>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ApplicationContactOnlinePresence;
