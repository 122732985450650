import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icSort from "../../assests/icons/ic_sort.svg";
import icClose from "../../assests/icons/ic-close.svg";
import blackdone from "./../../assests/icons/ic_blackdone.svg";
import { Link } from "react-router-dom";
import {
  APPLICATION_ACTION_HASH_ROUTE,
  APPLICATION_HASH_ROUTE,
  APPLICATION_INSIGHT_HASH_ROUTE,
} from "../../config/page_routes_constants";

const ApplicationOptions = (props) => {
  const [arrayIndex, setArrayIndex] = useState(0);
  const dropdownSortArray = ["Applications", "Actions", "Insights"];
  return (
    <div>
      <Modal
        fullscreen="md-down"
        show={props.show}
        onHide={() => props.onOptionsClose()}
        aria-labelledby="example-custom-modal-styling-title"
        size="md"
        scrollable={true}
        className="margin-half rounded-corner-none application-dialog-shadow application-modal"
        animation={false}
        backdrop={false}
      >
        <Modal.Header className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="fs-12 fw-700 color-tertiary ps-4">SELECT</div>
            <div
              className="d-flex gap-3"
              onClick={() => props.onOptionsClose()}
            >
              <img src={icClose} alt="close-icon" className="" width="20px" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ps-4 pb-5 ">
          <div className="pb-5">
            {dropdownSortArray?.map((sort, index) => {
              return (
                <div
                  key={index}
                  className="d-flex pb-4 pt-2"
                  onClick={() => {
                    setArrayIndex(index);
                    if (index !== 0) {
                      props?.applicationClose();
                    }
                    props.onOptionsClose();
                  }}
                >
                  <img
                    src={blackdone}
                    alt="blackdone-icon"
                    className={
                      arrayIndex === index ? "pe-3 d-block" : "d-none pe-1"
                    }
                  />{" "}
                  <Link
                    to={
                      index === 2
                        ? "/" + APPLICATION_INSIGHT_HASH_ROUTE
                        : index === 0
                        ? "/" + APPLICATION_HASH_ROUTE
                        : "/" + APPLICATION_ACTION_HASH_ROUTE
                    }
                    className="text-decoration-none color-primary"
                  >
                    <span
                      className={
                        arrayIndex === index
                          ? "ps-0 color-primary fw-700 fs-12"
                          : "ps-4 fs-12"
                      }
                    >
                      {sort}
                    </span>
                  </Link>
                </div>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ApplicationOptions;
