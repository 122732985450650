import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import icGreenDone from "../../assests/icons/ic-green-tick.svg";
import toaster from "../../utils/toaster";
import { changePaymentStatus } from "../../_services/view.service";

const PaymentRequestProceed = (props) => {
  const [paymentMethod, setPaymentMethod] = useState("PAY PAL");
  console.log("props?.iddd",props?.appDetails?.jobApplication?.applicationId)

  let acNo = props?.applicationDetails?.accountNumber;
  let ifsc = props?.applicationDetails?.ifscCode;
  let accountHolder = props?.applicationDetails?.accountHolderName;

console.log(props?.appDetails?.jobApplication?.jobId,props?.appDetails?.jobApplication?.referalId,"ids")

  const applicationChangePaymentStatus = () => {
    changePaymentStatus(
      props?.appDetails?.jobApplication?.applicationId,
      "PAYMENT_PROCESSED",
      props?.appDetails?.jobApplication?.jobId,
      props?.appDetails?.jobApplication?.referalId,
    ).then((res) => {
      props.viewApplicationDetails(props?.id);
    });
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={() => props.onPaymentProceedClose()}
        backdrop="static"
        fullscreen="lg-down"
        centered
        keyboard={false}
        className="lg-dialog-modal"
      >
        <Modal.Header className="dialog-header border-0">
          <div className="w-100">
            <div className="d-flex justify-content-end">
              <img
                src={icCloseRounded}
                alt="close-icon"
                onClick={() => props.onPaymentProceedClose()}
                className="pointer"
              />
            </div>
            <div className="fs-24 color-primary fw-700 ps-3">
              Payment Request
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="dialog-body mt-0 ">
          <div className="p-3 pt-0">
            <div>

              <div className="fs-14 color-primary fw-400">Request Details</div>
              {props?.applicationDetails?.requestPaymentDate  && (
              <div className="color-tertiary fs-11 fw-300">
                Request Date - {props?.applicationDetails?.requestPaymentDate}
              </div>
              )}
              {/* {paymentMethod === "BHIM UPI" && (
                <div className="pt-3 medium-black-text d-flex justify-content-between">
                  <div>
                    <div className=" fs-12 fw-700">BHIM UPI</div>
                    <div className="fs-14 fw-400">8800278211@upi</div>
                  </div>
                  <button className="btn-copy-payment">Copy</button>
                </div>
              )}
              {paymentMethod === "GPAY VPA" && (
                <div className="pt-3 medium-black-text d-flex justify-content-between">
                  <div>
                    <div className=" fs-12 fw-700">GPAY VPA</div>
                    <div className="fs-14 fw-400">float.do@okicici</div>
                  </div>
                  <button className="btn-copy-payment">Copy</button>
                </div>
              )}
              {paymentMethod === "Bank Details" && (
                <div className="pt-3 medium-black-text">
                  <div className=" fs-12 fw-700">BANK DETAILS</div>
                  <div className="fs-14 fw-400">A/c No - 9883 8319 130</div>
                  <div className="fs-14 fw-400">IFSC - ICICI0000314</div>
                  <div className="fs-14 fw-400">
                    Account Holder - Roopnath Grandhi
                  </div>
                </div>
              )}
              {paymentMethod === "PAY PAL" && (
                <div className="pt-3 medium-black-text">
                  <div className=" fs-12 fw-700">PAY PAL</div>
                  <div className="fs-14 fw-400">A/c No - 9883 8319 130</div>
                  <div className="fs-14 fw-400">
                    Account Holder - Roopnath Grandhi
                  </div>
                </div>
              )} */}

              {props?.applicationDetails?.payMode === "UPI" && (
                <div className="pt-3 medium-black-text d-flex justify-content-between">
                  <div>
                    <div className=" fs-12 fw-700">BHIM UPI</div>
                    <div className="fs-14 fw-400">
                      {acNo !== "" ? acNo : "Not specified"}
                    </div>
                  </div>
                  <button
                    className="btn-copy-payment"
                    onClick={() => {
                      navigator.clipboard.writeText(acNo);
                      toaster("success", "Copied to Clipboard", 3000);
                    }}
                  >
                    Copy
                  </button>
                </div>
              )}
              {props?.applicationDetails?.payMode === "VPA" && (
                <div className="pt-3 medium-black-text d-flex justify-content-between">
                  <div>
                    <div className=" fs-12 fw-700">GPAY VPA</div>
                    <div className="fs-14 fw-400">
                      {acNo !== "" ? acNo : "Not specified"}
                    </div>
                  </div>
                  <button
                    className="btn-copy-payment"
                    onClick={() => {
                      navigator.clipboard.writeText(acNo);
                      toaster("success", "Copied to Clipboard", 3000);
                    }}
                  >
                    Copy
                  </button>
                </div>
              )}
              {props?.applicationDetails?.payMode === "BANK_ACCOUNT" && (
                <div className="pt-3 medium-black-text">
                  <div className=" fs-12 fw-700">BANK DETAILS</div>
                  <div className="fs-14 fw-400">
                    A/c No - {acNo !== "" ? acNo : "Not specified"}
                  </div>
                  <div className="fs-14 fw-400">
                    IFSC - {ifsc !== "" ? ifsc : "Not specified"}
                  </div>
                  <div className="fs-14 fw-400">
                    Account Holder -{" "}
                    {accountHolder !== "" ? accountHolder : "Not specified"}
                  </div>
                </div>
              )}
              {props?.applicationDetails?.payMode === "PAY_PAL" && (
                <div className="pt-3 medium-black-text">
                  <div className=" fs-12 fw-700">PAY PAL</div>
                  <div className="fs-14 fw-400">
                    A/c No - {acNo !== "" ? acNo : "Not specified"}
                  </div>
                  <div className="fs-14 fw-400">
                    Account Holder -{" "}
                    {accountHolder !== "" ? accountHolder : "Not specified"}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-start">
          {/* <div className="d-flex gap-2">
            {props?.applicationDetails?.paymentStatus !==
            "REQUEST_PAYMENT" ? (
              <div className="fs-14 fw-700 dark-green-text">Processed</div>
            ) : (
              <div className="fs-14 fw-700 dark-red-text">
                  Payment Requested
                </div>
            )}
          </div> */}
          <button
            className="btn-black-bg pointer border-0 fs-12 fw-700"
            onClick={() => {
              applicationChangePaymentStatus();
              props.onPaymentProceedClose();
            }}
          >
            Mark as processed
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PaymentRequestProceed;
