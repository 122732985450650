/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import backButton from "../assests/icons/back-icon.svg";
import forwardButton from "../assests/icons/forward-ic.svg";
import downArrow from "../assests/downArrow.svg";
import IcDoneWhite from "../assests/icons/ic_done.svg";
import profileImg from "../assests/images/candidate-profile-pic.jpg";
import rightArrow from "../assests/Vector.svg";
import filterIcon from "../assests/icons/ic-filter.svg";
import blackdone from "../assests/icons/ic_blackdone.svg";
import "../styles/individual_teams.scss";
import { Table, Dropdown } from "react-bootstrap";
import ProgressBarGraph from "../components/graph/progress_bar_graph";
import { DateRangePicker, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import TeamSortTableDialog from "../dialogs/team_filter_dialog/team_sort_table_dialog";
import TeamFilterTeamsDialog from "../dialogs/team_filter_dialog/team_filter_teams_dialog";
import useWindowDimensions from "../utils/use_window_dimension";
import { useNavigate, useParams } from "react-router";
import {
  getAllTeamsSummary,
  getAverageJoiningDays,
  viewteamMembers,
} from "../_services/view.service";
import { getreferralbonus } from "../_services/view.service";
import {
  BUSINESS_USER_PROFILE_ROUTE,
  TEAM_PAGE_ROUTE,
} from "../config/page_routes_constants";
import DatePickerModal from "../dialogs/date_picker_modal";
import { Link } from "react-router-dom";
import { STATUS_SUCCESS } from "../config/be_api_constants";
import Loader from "../components/common/loader";
import { DEFAULT_PAGE_SIZE } from "../config/be_seikorAdmin_api_constants";
import dropdownArrow from "../assests/downArrow.svg";
import upArrow from "../assests/upArrow.svg";

function IndividualTeamDetails() {
  const CUSTOM_DATE_OPTION = "Custom";
  const windowDimension = useWindowDimensions();
  const navigate = useNavigate();
  const { individualTeam_Index_Id, teamId } = useParams();
  const [rolesArraySortIndex, setRolesArraySortIndex] = useState(null);

  const [nextOrDropdown, setNextOrDropdown] = useState(false);

  const [upDownArrowMember, setUpDownArrowMember] = useState(true)
  const [upDownArrowRole, setUpDownArrowRole] = useState(true)
  const [upDownArrowRolesFilled, setUpDownArrowRolesFilled] = useState(true)
  const [upDownArrowOpenRoles, setUpDownArrowOpenRoles] = useState(true)
  const [upDownArrowApplication, setUpDownArrowApplication] = useState(true)
  const [upDownArrowReferrals, setUpDownArrowReferrals] = useState(true)
  const [upDownArrowShort, setUpDownArrowShort] = useState(true)
  const [upDownArrowRejected, setUpDownArrowRejected] = useState(true)
  const [upDownArrowDropped, setUpDownArrowDropped] = useState(true)

  const [currentPage, setcurrentPage] = useState("");
  const [nextPage, setnextPage] = useState("");
  const [previousPage, setpreviousPage] = useState("");
  const [nextPage_Id, setnextPage_Id] = useState("");

  useEffect(() => {
    let currentPageIndex = individualTeam_Index_Id - 1;
    let previousPageIndex = currentPageIndex - 1;
    let nextPageIndex = currentPageIndex + 1;
    setcurrentPage(currentPageIndex);
    setpreviousPage(previousPageIndex);
    setnextPage(nextPageIndex);
  }, [nextPage_Id]);

  console.log("currentPage", currentPage);
  console.log("prevPage", previousPage);
  console.log("nextPage", nextPage_Id);

  // CURRENT DATE
  const current = new Date();
  // current.setMonth(current.getMonth() - 3);
  const [showLoader, setShowLoader] = useState(false);
  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const date = `${yyyy}-${mm}-${dd}`;
  // console.log("give me todays date", date);

  // PAST 1 MONTHS
  const past1Months = new Date();
  past1Months.setMonth(past1Months.getMonth() - 1);
  var dd = String(past1Months.getDate()).padStart(2, "0");
  var mm = String(past1Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past1Months.getFullYear();
  const month1 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month1);

  // PAST 3 MONTHS
  const past3Months = new Date();
  past3Months.setMonth(past3Months.getMonth() - 3);
  var dd = String(past3Months.getDate()).padStart(2, "0");
  var mm = String(past3Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past3Months.getFullYear();
  const month3 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month3);

  // PAST 6 MONTHS
  const past6Months = new Date();
  past6Months.setMonth(past6Months.getMonth() - 6);
  var dd = String(past6Months.getDate()).padStart(2, "0");
  var mm = String(past6Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past6Months.getFullYear();
  const month6 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month6);

  // PAST 12 MONTHS
  const past12Months = new Date();
  past12Months.setMonth(past12Months.getMonth() - 12);
  var dd = String(past12Months.getDate()).padStart(2, "0");
  var mm = String(past12Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past12Months.getFullYear();
  const month12 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month12);

  const [arraySortIndex, setArraySortIndex] = useState(null);
  const [sortTableShow, setSortTableShow] = useState(false);
  const [filterTeamsShow, setFilterTeamsShow] = useState(false);
  const [timeDropdownRedDot, setTimeDropdownRedDot] = useState("d-none");
  const [customStartDate, setCustomStartDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [showDatePickerModal, setShowDatePickerModal] = useState(false);
  const [displayDatePicker, setDisplayDatePicker] = useState("d-none");

  const [viewTeam, setViewTeam] = useState([]);
  const [teamPerformance, setTeamPerformance] = useState([]);
  const [teamMemberCount, setTeamMemberCount] = useState();
  const [statusWiseData, setStatusWiseData] = useState({});

  const [allTeamDropdown, setAllTeamDropdown] = useState([]);
  const [teamDropdownChangeIndex, setTeamDropdownChangeIndex] = useState(0);

  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [currentDate, setCurrentDate] = useState();
  const [firstDate, setfirstDate] = useState()
  const [secondDate, setsecondDate] = useState()

  useEffect(() => {
    const todaysDate = new Date();
    setCurrentDate(todaysDate);
  }, []);

  const showSelectedDate = (startDt, endDt) => {
    if (startDt) {
      const startDate = startDt?.toString()?.slice(4, 16);
      setCustomStartDate(startDate);
    }
    if (endDt) {
      const endDate = endDt?.toString()?.slice(4, 16);
      setCustomEndDate(endDate);
    }
  };

  const handleDateRange = (event) => {
    setDateRange([event?.selection]);
    let startDate = event?.selection?.startDate;
    let endDate = event?.selection?.endDate;
    showSelectedDate(startDate, endDate);

    let convertStartDate = JSON.stringify(startDate).slice(1, 11)
    let convertEndDate = JSON.stringify(endDate).slice(1, 11)

    setfirstDate(convertStartDate);
    setsecondDate(convertEndDate);
  };
  console.log("firstDate", firstDate)
  console.log("secondDate", secondDate)

  const onCustomDateSelect = (name) => {
    if (name == CUSTOM_DATE_OPTION) {
      setDisplayDatePicker("d-block");
    } else {
      setDisplayDatePicker("d-none");
    }
  };

  const timelineArray = [
    "All Time",
    "1 Month",
    "3 Months",
    "6 Months",
    "12 Months",
    CUSTOM_DATE_OPTION,
  ];



  const dropdownSortHandler = (value) => {
    getAllTeamMember(
      value,
    );
  };

  const teamsArray = [
    "Tech Level 70-90",
    "Engg All Levels",
    "Design All",
    "Levels",
    "Leadership Scouting Global",
    "Marketing & Sales Asia",
    "Pacific",
    "Team",
    "Name",
  ];

  const barData = [
    {
      entity: "Applied",
      entityValue:
        ((!nextOrDropdown) ?
          ((teamPerformance?.statusWiseData?.hasOwnProperty("APPLIED") &&
            teamPerformance?.statusWiseData?.APPLIED !== "") ||
            teamPerformance?.statusWiseData?.APPLIED !== null
            ? teamPerformance?.statusWiseData?.APPLIED
            : 0)
          :
          ((teamDropdownChangeIndex?.statusWiseData?.hasOwnProperty("APPLIED") &&
            teamDropdownChangeIndex?.statusWiseData?.APPLIED !== "") ||
            teamDropdownChangeIndex?.statusWiseData?.APPLIED !== null
            ? teamDropdownChangeIndex?.statusWiseData?.APPLIED
            : 0)
        ),
      color: "#1C1C1C",
    },
    {
      entity: "Shortlisted",
      entityValue:
        ((!nextOrDropdown) ?
          ((teamPerformance?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
            teamPerformance?.statusWiseData?.SHORTLISTED !== "") ||
            teamPerformance?.statusWiseData?.SHORTLISTED !== null
            ? teamPerformance?.statusWiseData?.SHORTLISTED
            : 0)
          :
          ((teamDropdownChangeIndex?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
            teamDropdownChangeIndex?.statusWiseData?.SHORTLISTED !== "") ||
            teamDropdownChangeIndex?.statusWiseData?.SHORTLISTED !== null
            ? teamDropdownChangeIndex?.statusWiseData?.SHORTLISTED
            : 0)
        ),
      color: "#3D81FF",
    },
    {
      entity: "Assessment",
      entityValue:
        ((!nextOrDropdown) ?
          ((teamPerformance?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
            teamPerformance?.statusWiseData?.ASSESSMENT !== "") ||
            teamPerformance?.statusWiseData?.ASSESSMENT !== null
            ? teamPerformance?.statusWiseData?.ASSESSMENT
            : 0)
          :
          ((teamDropdownChangeIndex?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
            teamDropdownChangeIndex?.statusWiseData?.ASSESSMENT !== "") ||
            teamDropdownChangeIndex?.statusWiseData?.ASSESSMENT !== null
            ? teamDropdownChangeIndex?.statusWiseData?.ASSESSMENT
            : 0)
        ),
      color: "#FFD027",
    },
    {
      entity: "Offered",
      entityValue:
        ((!nextOrDropdown) ?
          ((teamPerformance?.statusWiseData?.hasOwnProperty("OFFERED") &&
            teamPerformance?.statusWiseData?.OFFERED !== "") ||
            teamPerformance?.statusWiseData?.OFFERED !== null
            ? teamPerformance?.statusWiseData?.OFFERED
            : 0)
          :
          ((teamDropdownChangeIndex?.statusWiseData?.hasOwnProperty("OFFERED") &&
            teamDropdownChangeIndex?.statusWiseData?.OFFERED !== "") ||
            teamDropdownChangeIndex?.statusWiseData?.OFFERED !== null
            ? teamDropdownChangeIndex?.statusWiseData?.OFFERED
            : 0)
        ),
      color: "#12BD6B",
    },
    {
      entity: "Joined",
      entityValue:
        ((!nextOrDropdown) ?
          ((teamPerformance?.statusWiseData?.hasOwnProperty("JOINED") &&
            teamPerformance?.statusWiseData?.JOINED !== "") ||
            teamPerformance?.statusWiseData?.JOINED !== null
            ? teamPerformance?.statusWiseData?.JOINED
            : 0)
          :
          ((teamDropdownChangeIndex?.statusWiseData?.hasOwnProperty("JOINED") &&
            teamDropdownChangeIndex?.statusWiseData?.JOINED !== "") ||
            teamDropdownChangeIndex?.statusWiseData?.JOINED !== null
            ? teamDropdownChangeIndex?.statusWiseData?.JOINED
            : 0)
        ),
      color: "#2FFF9B",
    },
    {
      entity: "Dropout",
      entityValue:
        ((!nextOrDropdown) ?
          ((teamPerformance?.statusWiseData?.hasOwnProperty("RETRACTED") &&
            teamPerformance?.statusWiseData?.RETRACTED !== "") ||
            teamPerformance?.statusWiseData?.RETRACTED !== null
            ? teamPerformance?.statusWiseData?.RETRACTED
            : 0)
          :
          ((teamDropdownChangeIndex?.statusWiseData?.hasOwnProperty("RETRACTED") &&
            teamDropdownChangeIndex?.statusWiseData?.RETRACTED !== "") ||
            teamDropdownChangeIndex?.statusWiseData?.RETRACTED !== null
            ? teamDropdownChangeIndex?.statusWiseData?.RETRACTED
            : 0)
        ),
      color: "#EB1260",
    },
    {
      entity: "Rejected",
      entityValue:
        ((!nextOrDropdown) ?
          ((teamPerformance?.statusWiseData?.hasOwnProperty("REJECTED") &&
            teamPerformance?.statusWiseData?.REJECTED !== "") ||
            teamPerformance?.statusWiseData?.REJECTED !== null
            ? teamPerformance?.statusWiseData?.REJECTED
            : 0)
          :
          ((teamDropdownChangeIndex?.statusWiseData?.hasOwnProperty("REJECTED") &&
            teamDropdownChangeIndex?.statusWiseData?.REJECTED !== "") ||
            teamDropdownChangeIndex?.statusWiseData?.REJECTED !== null
            ? teamDropdownChangeIndex?.statusWiseData?.REJECTED
            : 0)
        ),
      color: "#721212",
    },
  ];

  const getLengedPercentage = (total, value) => {
    if (total === 0 || value === 0) {
      return 0 + "%";
    } else if (total && value) {
      return Math.round((parseInt(value) * 100) / parseInt(total)) + "%";
    }
  };

  const rolesPerformance = [
    {
      title: "Roles Posted",
      value:
        ((!nextOrDropdown) ?
          (teamPerformance?.hasOwnProperty("rolesPosted") &&
            teamPerformance?.rolesPosted !== ""
            ? teamPerformance?.rolesPosted
            : 0)
          :
          (teamDropdownChangeIndex?.hasOwnProperty("rolesPosted") &&
            teamDropdownChangeIndex?.rolesPosted !== ""
            ? teamDropdownChangeIndex?.rolesPosted
            : 0)
        ),
    },
    {
      title: "Roles Filled",
      value:
        ((!nextOrDropdown) ?
          (teamPerformance?.hasOwnProperty("roleFiled") &&
            teamPerformance?.roleFiled !== ""
            ? teamPerformance?.roleFiled
            : 0)
          :
          (teamDropdownChangeIndex?.hasOwnProperty("roleFiled") &&
            teamDropdownChangeIndex?.roleFiled !== ""
            ? teamDropdownChangeIndex?.roleFiled
            : 0)
        ),
    },
    {
      title: "Roles Closed",
      value:
        ((!nextOrDropdown) ?
          (teamPerformance?.hasOwnProperty("dropped") &&
            teamPerformance?.dropped !== ""
            ? teamPerformance?.dropped
            : 0)
          :
          (teamDropdownChangeIndex?.hasOwnProperty("dropped") &&
            teamDropdownChangeIndex?.dropped !== ""
            ? teamDropdownChangeIndex?.dropped
            : 0)
        ),
    },
    {
      title: "Total Referrals",
      value:
        ((!nextOrDropdown) ?
          (teamPerformance?.hasOwnProperty("referralCount") &&
            teamPerformance?.referralCount !== ""
            ? teamPerformance?.referralCount
            : 0)
          :
          (teamDropdownChangeIndex?.hasOwnProperty("referralCount") &&
            teamDropdownChangeIndex?.referralCount !== ""
            ? teamDropdownChangeIndex?.referralCount
            : 0)
        ),
    },
    {
      title: "Offered to Joined Ratio",
      // value: "640/480(80%)",
      value: ((!nextOrDropdown) ? (`${teamPerformance?.hasOwnProperty("jobsOffered") &&
        teamPerformance?.jobsOffered !== ""
        ? teamPerformance?.jobsOffered
        : 0
        }/${teamPerformance?.hasOwnProperty("roleFiled") &&
          teamPerformance?.roleFiled !== ""
          ? teamPerformance?.roleFiled
          : 0
        }(${getLengedPercentage(
          teamPerformance?.rolesFilled,
          teamPerformance?.jobsOffered === 0 ? "0" : teamPerformance?.jobsOffered
        )}) `)

        :

        (`${teamDropdownChangeIndex?.hasOwnProperty("jobsOffered") &&
          teamDropdownChangeIndex?.jobsOffered !== ""
          ? teamDropdownChangeIndex?.jobsOffered
          : 0
          }/${teamDropdownChangeIndex?.hasOwnProperty("roleFiled") &&
            teamDropdownChangeIndex?.roleFiled !== ""
            ? teamDropdownChangeIndex?.roleFiled
            : 0
          }(${getLengedPercentage(
            teamDropdownChangeIndex?.rolesFilled,
            teamDropdownChangeIndex?.jobsOffered === 0 ? "0" : teamDropdownChangeIndex?.jobsOffered
          )}) `)
      )
    },
  ];

  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    if (winWidth > 1500) {
      return 955 + "px";
    } else if (winWidth > 1300 && winWidth > 1499) {
      return winWidth - 420 + "px";
    } else if (winWidth < 1300 && winWidth > 991) {
      return winWidth - 420 + "px";
    } else if (winWidth < 990 && winWidth >= 768) {
      return winWidth - 70 + "px";
    } else if (winWidth < 767 && winWidth > 551) {
      return winWidth - 150 + "px";
    } else if (winWidth < 550) {
      return winWidth - 110 + "px";
    }
  };

  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  const [averageJoiningDays, setAverageJoiningDays] = useState({});
  const [teamsSortIndex, setTeamsArraySortIndex] = useState(0);

  const getAvgJoiningDays = () => {
    getAverageJoiningDays(parseInt(teamId)).then((res) => {
      setAverageJoiningDays(res?.data?.data);
    });
  };

  useEffect(() => {
    getAvgJoiningDays();
  }, []);
  const [referallBonus, setReferallBonus] = useState();

  const gettotalBonus = () => {
    getreferralbonus(parseInt(teamId)).then((res) => {
      setReferallBonus(res?.data?.data);
    });
  };
  const [apiRefresh, setApiRefresh] = useState(false);
  useEffect(() => {
    getAllTeamsSummary({ pageNo: 0, pageSize: 0 })?.then((res) => {
      // console.log("resssssssssssssssss=>", res?.data?.data);
      setAllTeamDropdown(res?.data?.data);
      setnextPage_Id(res?.data?.data[nextPage + 1]?.teamId);
      for (const [index] of res?.data?.data.entries()) {
        if (index === nextPage) {
        }
        // console.log("index_sigle0", );
        // console.log(
        //   "========================================?",
        //   index,
        //   individualTeam_Index_Id - 1
        // );
        //   console.log(
        //     "index_sigle0",
        //     res?.data?.data[nextPage],
        //     res?.data?.data[nextPage].teamId
        //   );
        // }
      }
    });
    gettotalBonus(teamId);
  }, []);

  const getAllTeamMember = (value) => {
    setShowLoader(true);
    // setApiRefresh(true);
    viewteamMembers({
      teamId: teamId,
      sortBy: value
    })
      .then((res) => {
        setViewTeam(res?.data?.data);
        setShowLoader(false);
        // setApiRefresh(true);
        setTeamMemberCount(res?.totalRecord);
      })
      .catch((err) => {
        setShowLoader(false);
        // setApiRefresh(false);
        // toaster("error", err);
      });
  };

  console.log("viewteamMembers=>", viewTeam);


  const [allTeamsData, setAllTeamsData] = useState();

  const allTeams = (search, fromDate, date, SortBy) => {
    setShowLoader(true);
    getAllTeamsSummary({ pageNo: 1, pageSize: 10, teamId: teamId, search: search, fromDate: fromDate, date: date, SortBy: SortBy })
      ?.then((res) => {
        setShowLoader(false);
        setAllTeamsData(res?.data?.data)
        setTeamPerformance(res?.data?.data[0]);
      })
      .catch((err) => {
        setShowLoader(false);
        // setApiRefresh(false);
        // toaster("error", err);
      });
  };
  useEffect(() => {
    getAllTeamMember();
    allTeams();
    // goToNextTeam()
  }, []);

  console.log("AllTeamDropdown--", teamPerformance)


  const [team, setTeam] = useState()

  const goToNextTeam = (nextPage) => {
    // getAllTeamMember();
    // allTeams();
    allTeams();
    // console.log("nextPage", nextPage)
    if (nextPage !== currentPage) {
      getAllTeamsSummary({ pageNo: 0, pageSize: 0 })?.then((res) => {
        console.log("resssssssssssssssss=>", res?.data?.data);
        setAllTeamDropdown(res?.data?.data);

        for (const [index] of res?.data?.data.entries()) {
          if (index === nextPage + 1) {
            setnextPage_Id(res?.data?.data[index]?.teamId);
            console.log(
              "res?.data?.data[index]?.teamId",
              res?.data?.data[index]?.teamId
            );
          }
        }
        setNextOrDropdown(false)
        navigate(`/${TEAM_PAGE_ROUTE}/${nextPage_Id}/${nextPage + 1}`);
        getAllTeamMember();

        console.log("index_sigle");
      });
    }
  };
  console.log("====================?", nextPage_Id);
  useEffect(() => {
    if (apiRefresh === true) {
      allTeams();
      getAllTeamMember();
      setApiRefresh(false);
    }
  }, [apiRefresh]);


  const changeDropdown = (indx, teamName) => {
    setTeamsArraySortIndex(viewTeam.teamName)
    setNextOrDropdown(true)
    setTeam(teamName)
    // navigate(`/${TEAM_PAGE_ROUTE}/${teamPerformance?.teamId}/${indx + 1}`);
    setTeamDropdownChangeIndex(allTeamDropdown[indx])
    navigate(`/${TEAM_PAGE_ROUTE}/${allTeamDropdown[indx]?.teamId}/${indx + 1}`);

    getAllTeamMember()
  }

  console.log("setTeamDropdownChangeIndex", teamDropdownChangeIndex)

  return (
    <>
      {/* {showLoader && <Loader />} */}
      <div className="container">
        <div className=" content-margin mt-4 w-100">
          <div className="d-flex justify-content-center justify-content-sm-between mt-3">
            <div
              onClick={() => navigate(-1)}
              className="individual-back-btn-pos"
            >
              <img className="me-2 pointer" src={backButton} />
              <span className="text-underline pointer back-btn-display fs-12 fw-400">
                Back to All Teams
              </span>
            </div>
            <div className="text-center">
              <div>
                <Dropdown
                  onClick={() => {
                    if (windowDimension?.width <= 768) {
                      setFilterTeamsShow(true);
                    }
                  }}
                  className="team-selection-dropdown"
                >
                  <Dropdown.Toggle
                    variant="none"
                    // id="dropdown-basic"
                    className="d-flex p-0 m-0 border-0"
                  >
                    {(!nextOrDropdown) ?
                      <div className="fw-700 fs-20">
                        {teamPerformance?.teamName}
                      </div> :
                      <div className="fw-700 fs-20">
                        {team}
                      </div>
                    }
                    <img
                      src={downArrow}
                      alt="retract-icon"
                      className="drop-down-arrow"
                    />
                  </Dropdown.Toggle>
                  <div className="d-none d-md-block">
                    <Dropdown.Menu className="fs-12 text-secondary mt-2 border-dark ">
                      <ul
                        className="m-0 p-0 overflow-auto"
                        style={{ maxHeight: "300px" }}
                      >
                        {allTeamDropdown && allTeamDropdown?.length > 0
                          ? allTeamDropdown?.map((team, index) => {
                            return (
                              <Dropdown.Item
                                onClick={() =>
                                  // setTeamsArraySortIndex(team.teamName)
                                  changeDropdown(index, team?.teamName)
                                }
                                // href={`#/action-${index}`}
                                className="d-flex pb-2 pt-2"
                              >
                                <div className="d-flex gap-2 w-100">
                                  <span className="dropdown-icon-span">
                                    {teamsSortIndex === index ? (
                                      <img
                                        src={IcDoneWhite}
                                        alt="blackdone-icon"
                                        className=""
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </span>

                                  <span
                                    className={
                                      teamsSortIndex === index ? "fw-700" : ""
                                    }
                                  >
                                    {team.teamName}
                                  </span>
                                </div>
                              </Dropdown.Item>
                            );
                          })
                          : ""}
                      </ul>
                    </Dropdown.Menu>
                  </div>
                </Dropdown>
              </div>

              <div className="small-text-gray fs-11 fw-300">
                {teamMemberCount} members
              </div>
            </div>
            <div className="ms-3 back-btn-display">
              <span
                className="text-underline pointer me-2 fs-12 fw-400"
                onClick={() => goToNextTeam(nextPage)}
              >
                Next Team
              </span>{" "}
              <img
                className="pointer"
                src={forwardButton}
                onClick={() => goToNextTeam(nextPage)}
              />
            </div>
          </div>
          <hr />
          <div className="col-6 col-sm-12 d-sm-flex justify-content-between mt-3">
            <div className="mb-3 mb-sm-0 fs-20 fw-700">Team Performance</div>
            <div className="position-relative">
              <div
                className={`${displayDatePicker} custome-date-box custome-date-box-position p-3`}
              >
                <DateRange
                  ranges={dateRange}
                  onChange={(e) => {
                    handleDateRange(e);
                  }}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  maxDate={currentDate}
                />
                <div className="d-flex justify-content-end">
                  <button
                    style={{ border: "1px solid black", width: "80px" }}
                    className="btn bnt-light color-dark fs-14 p-0"
                    onClick={() => {
                      setDisplayDatePicker("d-none");
                      setArraySortIndex(null);
                      setTimeDropdownRedDot("d-none");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    style={{ border: "1px solid black", width: "80px" }}
                    className="btn bnt-light color-dark fs-14 p-0 ms-2 "
                    onClick={() => {
                      setDisplayDatePicker("d-none");
                      setTimeDropdownRedDot("d-block");
                      setArraySortIndex(5);
                      allTeams(null, firstDate, secondDate, null);
                    }}
                  >
                    OK
                  </button>
                </div>
              </div>
              <Dropdown
                onClick={() => {
                  if (windowDimension?.width <= 768) {
                    setSortTableShow(true);
                  }
                }}
                className="timeline-select-dropdown"
              >
                <Dropdown.Toggle
                  // disabled={true}
                  variant="none"
                  // id="dropdown-basic"
                  className="btn down-arrow timeline-select-button d-flex py-1 px-2 bg-transparent align-items-center justify-content-around"
                >
                  <img src={filterIcon} alt="" className="me-1" />
                  <span className="fs-12">Time Interval</span>
                  <span
                    className={`${timeDropdownRedDot} round-red-dot rounded-circle ms-1 mb-2`}
                  ></span>
                  <img className="ms-1" src={downArrow} alt="" />
                </Dropdown.Toggle>

                <div className="d-none d-md-block">
                  <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                    {timelineArray?.map((name, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            if (arraySortIndex == index) {
                              setArraySortIndex(null);
                              setTimeDropdownRedDot("d-none");
                              // if (name == CUSTOM_DATE_OPTION) {
                              //   setCustomStartDate("");
                              //   setCustomEndDate("");
                              // }
                            } else {
                              if (name == CUSTOM_DATE_OPTION) {
                                if (
                                  customStartDate == "" ||
                                  customEndDate == ""
                                ) {
                                  setTimeDropdownRedDot("d-none");
                                }
                                onCustomDateSelect(name);
                              } else {
                                setArraySortIndex(index);
                                setTimeDropdownRedDot("d-block");
                              }
                            }

                            if (name === "All Time") {
                              allTeams();
                            }
                            if (name === "1 Month") {
                              allTeams(null, month1, date, null);
                            }
                            if (name === "3 Months") {
                              allTeams(null, month3, date, null);
                            }
                            if (name === "6 Months") {
                              allTeams(null, month6, date, null);
                            }
                            if (name === "12 Months") {
                              allTeams(null, month12, date, null);
                            }

                            // if (rolesArraySortIndex == index) {
                            //   setRolesArraySortIndex(null);
                            // } else {
                            //   setRolesArraySortIndex(index);
                            //   allTeams({
                            //     // ...allTeamsFilterData,
                            //     fromDate: month1,
                            //     toDate: date,
                            //     pageNo: 1,
                            //     pageSize: DEFAULT_PAGE_SIZE,
                            //   });
                            // }
                            // setRolesArraySortIndex(name);
                            // setAllTeamsFilterData({
                            //   ...allTeamsFilterData,
                            //   fromDate: value,
                            //   toDate: date,
                            //   pageNo: 1,
                            //   pageSize: DEFAULT_PAGE_SIZE,
                            // });

                          }}
                          key={index}
                          className="d-flex pb-3"
                        >
                          <div>
                            <div className="d-flex">
                              <img
                                src={blackdone}
                                alt="blackdone-icon"
                                className={
                                  arraySortIndex === index
                                    ? "pe-2 d-block"
                                    : "d-none pe-1"
                                }
                              />
                              <span
                                className={
                                  arraySortIndex === index
                                    ? "ps-0 color-primary fw-700"
                                    : "ps-4"
                                }
                              >
                                {name}
                              </span>
                            </div>
                            {name == CUSTOM_DATE_OPTION &&
                              arraySortIndex == 5 &&
                              customStartDate != "" &&
                              customEndDate != "" ? (
                              <div className="small-text-gray fs-12 mt-2">
                                {`${customStartDate} to ${customEndDate}`}
                              </div>
                            ) : null}
                          </div>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </div>
              </Dropdown>

              {/* <div className="btn p-1 border-dark team-filter-btn bg-white text-center d-flex">
            <div className="p-1 pt-0 pb-0 me-1 d-block">
              <img className="mb-2" src={filterIcon} alt="filter icon" />
            </div>
            <div className="fs-12 text-center w-100">All Time</div>
            <div className="p-1 pt-0 pb-0 me-1 d-block">
              <img className="mb-2" src={downArrow} alt="filter icon" />
            </div>
          </div> */}
            </div>
          </div>
          {/* Overview */}
          <div>
            <div className="my-4 outer-border p-2 p-sm-3">
              <div className="d-flex justify-content-between flex-wrap mt-3 org-roles-performance">
                {rolesPerformance.map((obj) => (
                  <>
                    <div className="ps-2 stats">
                      <div className="fw-400 fs-32 text-break">{obj.value}</div>
                      <div
                        className="fw-400 fs-16 color-tertiary"
                        style={{ width: "85px" }}
                      >
                        {obj.title}
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <ProgressBarGraph
                data={barData}
                height={"22"}
                showLegends={true}
              />
            </div>
            {/* <h1 className="text-center">Overview</h1> */}
          </div>
          {/* Info Cards Section */}
          <div className="row align-items-stretch">
            <div className="col-12 col-sm-6 col-md-4">
              <div className="border border-gray p-3 rounded team-job-info mt-2">
                <div className="fs-20 fw-700">
                  {averageJoiningDays?.averageOrgsDays} Days
                </div>
                <div className="fs-16 color-gray fw-400">Average Joining</div>
                <div className="fs-12 fw-400 color-saffron">
                  {averageJoiningDays?.totalOfAverageOrgsDaysAndAverageTeamDays}{" "}
                  days slow than your org avg
                </div>
                {/* <div className="fs-12 fw-400 color-green">
                10 days faster than your org avg 
              </div> */}
              </div>
            </div>

            <div className="col-6 col-md-4">
              <div className="border border-gray p-3 rounded team-job-info mt-2">
                <div className="fs-20 fw-700">{referallBonus}</div>
                <div className="fs-16 color-gray fw-400">Referral Bonus</div>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="border border-gray p-3 rounded team-job-info mt-2">
                <div className="fs-20 fw-700">05</div>
                <div className="fs-16 color-gray fw-400">Open Roles</div>
              </div>
            </div>
          </div>
          {/* Table part */}
          <div className="fw-700 fs-20 mt-5">Team members</div>
          <div className="d-none d-md-block mb-5">
            <div
              style={{
                width: getHorizotalScrollWidth(hrSrlDiamension.width),
              }}
              className="table-responsive "
            >
              <table className="mt-4 justify-content-between table">
                <thead className="w-100">
                  <tr>
                    <th style={{ width: "250px" }}>
                      <div className="d-flex align-items-center justify-content-center gap-2">
                        <div className="fs-12 fw-700">Member</div>
                        {/* <div>
                          {upDownArrowMember ? (
                            <img className="pointer" src={dropdownArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MEMBER_ASC")
                                setUpDownArrowMember(!upDownArrowMember)

                              }}></img>) : (
                            <img className="pointer" src={upArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MEMBER")
                                setUpDownArrowMember(!upDownArrowMember)
                              }}></img>
                          )
                          }
                        </div> */}
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Roles</div>
                        <div>
                          {upDownArrowRole ? (
                            <img className="pointer" src={dropdownArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MOST_OPEN_ROLES_ASC")
                                setUpDownArrowRole(!upDownArrowRole)

                              }}></img>) : (
                            <img className="pointer" src={upArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MOST_ROLES")
                                setUpDownArrowRole(!upDownArrowRole)
                              }}></img>
                          )
                          }
                        </div>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Roles Filled</div>
                        <div>
                          {upDownArrowRolesFilled ? (
                            <img className="pointer" src={dropdownArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MOST_FILLED_ROLES_ASC")
                                setUpDownArrowRolesFilled(!upDownArrowRolesFilled)

                              }}></img>) : (
                            <img className="pointer" src={upArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MOST_FILLED_ROLES")
                                setUpDownArrowRolesFilled(!upDownArrowRolesFilled)
                              }}></img>
                          )
                          }
                        </div>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Open Roles</div>
                        <div>
                          {upDownArrowOpenRoles ? (
                            <img className="pointer" src={dropdownArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MOST_OPEN_ROLES_ASC")
                                setUpDownArrowOpenRoles(!upDownArrowOpenRoles)

                              }}></img>) : (
                            <img className="pointer" src={upArrow} alt=""
                              onClick={() => {
                                dropdownSortHandler("MOST_OPEN_ROLES")
                                setUpDownArrowOpenRoles(!upDownArrowOpenRoles)
                              }}></img>
                          )
                          }
                        </div>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Applications</div>
                         <div>
                        {upDownArrowApplication ? (
                        <img className="pointer" src={dropdownArrow} alt="" onClick={() => {
                          dropdownSortHandler("MOST_APPL_ASC")
                          setUpDownArrowApplication(!upDownArrowApplication)
                        }}></img>) : (
                        <img className="pointer" src={upArrow} alt=""
                          onClick={() => {
                            dropdownSortHandler("MOST_APPL")
                            setUpDownArrowApplication(!upDownArrowApplication)
                          }}></img>
                      )
                      }
                        </div>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Referrals</div>
                        <div>
                        {upDownArrowReferrals ? (
                        <img className="pointer" src={dropdownArrow} alt="" onClick={() => {
                          dropdownSortHandler("MOST_REFF_ASC")
                          setUpDownArrowReferrals(!upDownArrowReferrals)
                        }}></img>) : (
                        <img className="pointer" src={upArrow} alt=""
                          onClick={() => {
                            dropdownSortHandler("MOST_REFF")
                            setUpDownArrowReferrals(!upDownArrowReferrals)
                          }}></img>
                      )
                      }
                        </div>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Shortlisted</div>
                        <div>
                        {upDownArrowShort ? (
                        <img className="pointer" src={dropdownArrow} alt="" onClick={() => {
                          dropdownSortHandler("SHORT_ASC")
                          setUpDownArrowShort(!upDownArrowShort)
                        }}></img>) : (
                        <img className="pointer" src={upArrow} alt=""
                          onClick={() => {
                            dropdownSortHandler("SHORT")
                            setUpDownArrowShort(!upDownArrowShort)
                          }}></img>
                      )
                      }
                        </div>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Rejected</div>
                         <div>
                        {upDownArrowRejected ? (
                        <img className="pointer" src={dropdownArrow} alt="" onClick={() => {
                          dropdownSortHandler("REJECTED_ASC")
                          setUpDownArrowRejected(!upDownArrowRejected)
                        }}></img>) : (
                        <img className="pointer" src={upArrow} alt=""
                          onClick={() => {
                            dropdownSortHandler("REJECTED")
                            setUpDownArrowRejected(!upDownArrowRejected)
                          }}></img>
                      )
                      }
                        </div>
                      </div>
                    </th>
                    <th style={{ width: "100px" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div className="fs-12 fw-700">Dropped</div>
                         <div>
                        {upDownArrowDropped ? (
                        <img className="pointer" src={dropdownArrow} alt="" onClick={() => {
                          dropdownSortHandler("MOST_DROP_ASC")
                          setUpDownArrowDropped(!upDownArrowDropped)
                        }}></img>) : (
                        <img className="pointer" src={upArrow} alt=""
                          onClick={() => {
                            dropdownSortHandler("MOST_DROP")
                            setUpDownArrowDropped(!upDownArrowDropped)
                          }}></img>
                      )
                      }
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {viewTeam && viewTeam?.length > 0
                    ? viewTeam.map((item, index) => {
                      return (
                        <>
                          <tr
                            key={index}
                            className="card-parent fs-14 position-relative"
                          >
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <div>
                                  {/* { item.profilePicId === ""?  */}
                                  <img
                                        // src={`data:image/jpeg;base64 , ${item?.profilePicId}`}
                                        src={process.env.REACT_APP_IMAGE_BASEURL +
                                          item?.profilePicId}
                                        width="32px"
                                        height="32px"
                                        style={{ borderRadius: "50px" }}
                                         />
                                  {/* :
                                   <img
                                   src={process.env.REACT_APP_IMAGE_BASEURL + item?.profilePicId}
                                   alt="profile-image"
                                   className="rounded-circle"
                                   width="32px"
                                   height="32px"
                                 />
                                  } */}
                                </div>
                                <div
                                  className="fs-14 fw-400"
                                  style={{ color: "#262626" }}
                                >
                                  {item?.name === ""
                                    ? item.email
                                    : item?.name}
                                </div>
                              </div>
                              <div className="position-absolute action-card teams-action-card-position">
                                <div className="d-flex align-items-center ">
                                  <div className="link">
                                    <button
                                      onClick={() => {
                                        navigate(
                                          `/${BUSINESS_USER_PROFILE_ROUTE}/${teamId}/${item?.id
                                          }/${index + 1}`
                                        );
                                      }}
                                      className="text-decoration-none btn btn-outline-dark fs-12 fw-700 text-white bg-dark "
                                    >
                                      View Details
                                      <img
                                        className="ms-2"
                                        src={rightArrow}
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="align-middle">
                              <div>{item?.rolePosted}</div>
                            </td>
                            <td className="align-middle">
                              <div>{item?.roleFilled}</div>
                            </td>
                            <td className="align-middle">
                              <div> {item?.openRoles}</div>
                            </td>
                            <td className="align-middle">
                              <div> {item?.numOfApplication}</div>
                            </td>
                            <td className="align-middle">
                              <div> {item?.numOfReferences}</div>
                            </td>
                            <td className="align-middle">
                              <div> {item?.applicationShortlisted}</div>
                            </td>
                            <td className="align-middle">
                              <div> {item?.rejected}</div>
                            </td>
                            <td className="align-middle">
                              <div> {item?.applicationDropped}</div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                    : "No Data Found"}
                </tbody>
              </table>
            </div>
          </div>
          {/* Table end */}
          {/* Small Table start */}
          <div className="individual-teams-small-cards-div d-block d-md-none mt-4 mb-5">
            {viewTeam && viewTeam?.length > 0
              ? viewTeam?.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="small-cards d-flex row justify-content-center align-items-center mt-3"
                  >
                    <div>
                      <span>
                        <img className="rounded-circle" src={profileImg} />
                      </span>
                      <span className="ms-2 fs-14">
                        {item?.name === "" ? item.email : item?.name}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="fw-700 fs-12">Roles Posted</div>
                      <div className="fs-14 fw-400">{item?.rolePosted}</div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="fw-700 fs-12">Open Roles</div>
                      <div className="fs-14 fw-400">{item?.openRoles}</div>
                    </div>
                  </div>
                );
              })
              : null}
          </div>
          {/* Small Table end */}

          {/* Dialogs */}

          <TeamSortTableDialog
            show={sortTableShow}
            onSortClose={() => setSortTableShow(false)}
            sortArray={timelineArray}
            arraySortIndex={arraySortIndex}
            setArraySortIndex={setArraySortIndex}
            setTimeDropdownRedDot={setTimeDropdownRedDot}
            showDatePickerModal={showDatePickerModal}
            setShowDatePickerModal={setShowDatePickerModal}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            setCustomStartDate={setCustomStartDate}
            setCustomEndDate={setCustomEndDate}
            displayDatePicker={displayDatePicker}
            setDisplayDatePicker={setDisplayDatePicker}
          />

          <TeamFilterTeamsDialog
            userType={"Select Team"}
            show={filterTeamsShow}
            onSortClose={() => setFilterTeamsShow(false)}
            sortArray={teamsArray}
            allTeamDropdown={allTeamDropdown}
            teamsSortIndex={teamsSortIndex}
            setTeamsArraySortIndex={setTeamsArraySortIndex}
          />

          <DatePickerModal
            showDatePickerModal={showDatePickerModal}
            setShowDatePickerModal={setShowDatePickerModal}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            setCustomStartDate={setCustomStartDate}
            setCustomEndDate={setCustomEndDate}
            dateRange={dateRange}
            setDateRange={setDateRange}
            currentDate={currentDate}
            displayDatePicker={displayDatePicker}
            setDisplayDatePicker={setDisplayDatePicker}
            setArraySortIndex={setArraySortIndex}
            handleDateRange={handleDateRange}
            setTimeDropdownRedDot={setTimeDropdownRedDot}
          />
        </div>
      </div>
    </>
  );
}

export default IndividualTeamDetails;
