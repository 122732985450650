import React, { useEffect } from "react";
import { useState } from "react";
import { USER_ROLE } from "../../config/be_api_constants";

const FunnelGraphComponent = (props) => {
  const [error, setError] = useState([]);
  const role = localStorage.getItem("role");
  const getPercentage = (total, entityValue) => {
    return Math.round((entityValue * 100) / total);
  };

  const validationCheck = (data) => {
    if (data?.length > 0) {
      let tempArray = [];
      for (let i = 0; i < data?.length; i++) {
        // const parentCount = data[i - 1]?.count;
        const parentCount = data[0]?.count;
        const currentCount = data[i]?.count;
        if (currentCount > parentCount) {
          tempArray.push("Invalid Data Entered");
          setError(tempArray);
        }
      }
    } else {
      let tempArray = [];
      tempArray.push("Data Not Available");
      setError(tempArray);
    }
  };
  useEffect(() => {
    validationCheck(props?.mainData);
    validationCheck(props?.seikorAdminFunnelData);
  }, [props?.mainData, props?.seikorAdminFunnelData]);

  const firstCount = props?.mainData[0];
  const adminFirstCount = props?.seikorAdminFunnelData[0];

  const widthMultiply = 2;

  return (
    <div>
      {role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER ? (
        error?.length === 0 ? (
          props?.mainData.map((data, index) => {
            return (
              <div className="d-flex flex-wrap" key={index}>
                {/* rejected dropped div */}
                <div className="col-6">
                  {/* {(data?.rejected !== 0 || data?.dropped !== 0) && ( */}
                    <div
                      className={
                        index === 0
                          ? "mt-3 d-flex pt-4 justify-content-between  border-bottom pb-0 mb-0 gap-1 "
                          : "d-flex pt-4 justify-content-between border-bottom pb-0 mb-0 gap-1 "
                      }
                    >
                      <div className="fs-12 fw-400 color-tertiary">
                        {`${getPercentage(data?.mainCount, data?.rejected)}% `}{" "}
                        Rejected ({data?.rejected}),{" "}
                        {`${getPercentage(data?.mainCount, data?.dropped)}% `}{" "}
                        Dropped ({data?.dropped})
                      </div>
                      <div className="d-flex align-items-end justify-content-end">
                        <div
                          className="progress-bar-height"
                          style={{
                            background: "#721212",
                            
                            width: `${
                              getPercentage(data?.mainCount, data?.rejected) *
                              widthMultiply
                            }px`,
                            height: "26px",
                          }}
                        ></div>
                        <div
                          className="progress-bar-height"
                          style={{
                            background: "#EB1260" ,
                            
                            width: `${
                              getPercentage(data?.mainCount, data?.dropped) *
                              widthMultiply
                            }px`,
                            height: "26px",
                          }}
                        ></div>
                      </div>
                    </div>
                  {/* // )} */}
                </div>

                {/* main status div */}
                <div
                  className={
                    index === 4
                      ? "border-start col-6 pb-3"
                      : "border-start col-6"
                  }
                >
                  <div
                    className={
                      index === 0
                        ? "pt-1 d-flex justify-content-between align-items-end border-bottom pb-0 mb-0 flex-column flex-lg-row"
                        : "d-flex justify-content-between border-bottom pb-0 mb-0 flex-column flex-lg-row"
                    }
                  >
                    <div
                      className={`${data?.color} order-last order-lg-first me-lg-4 align-items-end`}
                      style={{
                        width: `${getPercentage(
                          firstCount?.count,
                          data?.count
                        )}%`,
                        height: "26px",
                      }}
                    ></div>
                    <div className="fs-12 fw-400 color-tertiary order-lg-last order-first ">
                      {index === 0
                        ? ""
                        : `${getPercentage(firstCount?.count, data?.count)}% `}
                      {data?.status} ({data?.count})
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="p-2 fs-14 color-tertiary funnel-error-box mt-3">
            <div>{error[0]}</div>
          </div>
        )
      ) : null}

      {role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN ? (
        error?.length === 0 ? (
          props?.seikorAdminFunnelData.map((data, index) => {
            return (
              <div className="d-flex flex-wrap" key={index}>
                {/* rejected dropped div */}
                <div className="col-6">
                  {(data?.rejected !== 0 || data?.dropped !== 0) && (
                    <div
                      className={
                        index === 0
                          ? "mt-3 d-flex pt-4 justify-content-between  border-bottom pb-0 mb-0 gap-1 "
                          : "d-flex pt-4 justify-content-between border-bottom pb-0 mb-0 gap-1 "
                      }
                    >
                      <div className="fs-12 fw-400 color-tertiary">
                        {`${getPercentage(data?.mainCount, data?.rejected)}% `}{" "}
                        Rejected ({data?.rejected}),{" "}
                        {`${getPercentage(data?.mainCount, data?.dropped)}% `}{" "}
                        Dropped ({data?.dropped})
                      </div>
                      <div className="d-flex align-items-end justify-content-end">
                        <div
                          className="progress-bar-height"
                          style={{
                            background: "#721212",
                            width: `${
                              getPercentage(data?.mainCount, data?.dropped) *
                              widthMultiply
                            }px`,
                            height: "26px",
                          }}
                        ></div>
                        <div
                          className="progress-bar-height"
                          style={{
                            background: "#EB1260",
                            width: `${
                              getPercentage(data?.mainCount, data?.rejected) *
                              widthMultiply
                            }px`,
                            height: "26px",
                          }}
                        ></div>
                      </div>
                    </div>
                  )}
                </div>

                {/* main status div */}
                <div
                  className={
                    index === 4
                      ? "border-start col-6 pb-3"
                      : "border-start col-6"
                  }
                >
                  <div
                    className={
                      index === 0
                        ? "pt-1 d-flex justify-content-between align-items-end border-bottom pb-0 mb-0 flex-column flex-lg-row"
                        : "d-flex justify-content-between border-bottom pb-0 mb-0 flex-column flex-lg-row"
                    }
                  >
                    <div
                      className={`${data?.color} order-last order-lg-first me-lg-4 align-items-end`}
                      style={{
                        width: `${getPercentage(
                          adminFirstCount?.count,
                          data?.count
                        )}%`,
                        height: "26px",
                      }}
                    ></div>
                    <div className="fs-12 fw-400 color-tertiary order-lg-last order-first ">
                      {index === 0
                        ? ""
                        : `${getPercentage(
                            adminFirstCount?.count,
                            data?.count
                          )}% `}
                      {data?.status} ({data?.count})
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="p-2 fs-14 color-tertiary funnel-error-box mt-3">
            <div>{error[0]}</div>
          </div>
        )
      ) : null}
    </div>
  );
};

export default FunnelGraphComponent;
