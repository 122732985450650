import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { isEmpty } from "../../utils/form_validators";
import checkedLogo from "../../assests/ic_selected16.svg";
import { checkedItemsHandler } from "../../utils/utils";
import profileImg from "../../assests/images/candidate-profile-pic.jpg";
import { addForTeamMembers, addteam } from "../../_services/view.service";
import { addmembertoteam } from "../../_services/view.service";
import toaster from "../../utils/toaster";
import { useParams } from "react-router";
import { addTeams } from "../../_services/view.service";

const AddMemberToTeamDialog = (props) => {
  const [teamId, setteamId] = useState();
  const [userIdList, setUserIdList] = useState([]);
  const [selectedMemberId, setSelectedMemberId] = useState([]);
  const [obj, setObj] = useState([]);

  const [isDeleted, setisDeleted] = useState(true);
  const checkHandler = (condition, item) => {
    if (condition) {
      setObj([...obj, item?.memberUserId]);
    } else {
      let index = obj.indexOf(item?.memberUserId);
      if (index >= 0) {
        setObj([...obj.splice(0, index), ...obj.splice(index + 1)]);
      }
    }
  };

  const [showLoader, setShowLoader] = useState(false);
  const { id } = useParams();
  const [selectedMemberList, setSelectedMemberList] = useState([]);
  const checkboxClickHandler = (index) => {
    checkedItemsHandler(index, selectedMemberList, setSelectedMemberList);
  };

  const saveMember = () => {
    setShowLoader(true);
    addTeams(id, selectedMemberId)
      ?.then((res) => {
        if (res.data.status === "SUCCESS") {
          toaster("success", "Members Added");
          setShowLoader(false);
          props?.teamMembers()
        } else {
          toaster("error", res.data.message);
        }
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
    props.close();
  };

  return (
    <div>
      {" "}
      <Modal
        show={props.show}
        onHide={() => props.close()}
        centered
        fullscreen="lg-down"
        backdrop="static"
        keyboard={false}
        className="p-0"
        // contentClassName="modal-content-custom"
      >
        <Modal.Header className=" border-0" bsPrefix="modal-header-custom">
          <div className="fs-20 color-primary fw-700 px-2 pt-2">
            Add member to team
          </div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            onClick={() => props.close()}
            className="pointer me-2 mt-2"
            height="25px"
            width="25px"
          />
        </Modal.Header>
        <Modal.Body className="p-0 mt-2 pb-2">
          <div className="" style={{ minHeight: "350px" }}>
            <ul className="m-0  p-0 overflow-auto"
            style={{ maxHeight: "300px" }}
             >
              {props?.addmembers?.length > 0 ? (
                props?.addmembers?.map((item, index) => {
                  return (
                    <>
                      {isDeleted ? (
                        <li
                          key={index}
                          className="px-3 d-flex fs-12 fw-400 border-bottom py-3 pointer align-items-center"
                          onClick={() => {
                            checkboxClickHandler(index);
                            // setSelectedMemberId(item.memberUserId);
                            if (selectedMemberId.includes(item.memberUserId)) {
                              let itemIndex = props?.addmembers?.findIndex(
                                (tm) => tm === item.memberUserId
                              );
                              selectedMemberId.splice(itemIndex, 1);
                            } else {
                              selectedMemberId.push(item.memberUserId);
                            }
                          }}
                        >
                          <input
                            type="checkbox"
                            className="pointer"
                            onChange={(e) => {
                              setUserIdList(e);
                            }}
                            onClick={(e) => {}}
                          />{" "}
                          {item?.profileId === null ||
                          item?.profileId === undefined ? (
                            <img
                              src={profileImg}
                              alt="profile"
                              className="rounded-circle ms-4"
                              width="32px"
                              height="32px"
                            />
                          ) : (
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_BASEURL +
                                item?.profileId
                              }
                              alt="profile"
                              className="rounded-circle ms-4"
                              width="32px"
                              height="32px"
                            />
                          )}
                          <label className="fs-14 fw-600 ps-3 color-tertiary pointer">
                            {item?.email}
                          </label>
                        </li>
                      ) : (
                        <li
                          key={index}
                          className="px-3 d-flex fs-12 fw-400 border-bottom py-3 pointer align-items-center"
                          onClick={() => {
                            checkboxClickHandler(index);
                            if (selectedMemberId.includes(item.memberUserId)) {
                              let itemIndex = props?.addmembers?.findIndex(
                                (tm) => tm === item.memberUserId
                              );
                              selectedMemberId.splice(itemIndex, 1);
                            } else {
                              selectedMemberId.push(item.memberUserId);
                            }
                          }}
                        >
                          <input
                            type="checkbox"
                            className="pointer"
                            checked={
                              selectedMemberList.includes(index) ? true : false
                            }
                            onChange={(e) => {
                              checkHandler(e?.target?.checked, item);
                            }}
                          />{" "}
                          {item?.profileId === null ||
                          item?.profileId === undefined ? (
                            <img
                              src={profileImg}
                              alt="profile"
                              className="rounded-circle ms-4"
                              width="32px"
                              height="32px"
                            />
                          ) : (
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_BASEURL +
                                item?.profileId
                              }
                              alt="profile"
                              className="rounded-circle ms-4"
                              width="32px"
                              height="32px"
                            />
                          )}
                          <label className="fs-14 fw-600 ps-3 pointer">
                            {item?.email}
                          </label>
                        </li>
                      )}
                    </>
                  );
                })
              ) : (
                <p className="color-tertiary">No members to add</p>
              )}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer className="dialog-footer ">
          {selectedMemberList?.length > 0 ? (
            <button
              className="btn-dialog-save pointer fs-12 fw-700"
              onClick={saveMember}
            >
              Add & Save
            </button>
          ) : (
            <button
              className="btn-dialog-save pointer fs-12 fw-700 text-light border-0 "
              style={{ backgroundColor: "#B9B9B9" }}
            >
              Add & Save
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddMemberToTeamDialog;
