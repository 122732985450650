import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import icSort from "../../assests/icons/ic_sort.svg";
import icClose from "../../assests/icons/ic-close-rounded.svg";
import { checkedItemsHandler } from "../../utils/utils";

function AdminAllOrgsFilterDialog(props) {
  const [arrayAllOrgsIndex, setArrayAllOrgsIndex] = useState([]);
  const dropdownAllTeamsHandler = (index) => {
    checkedItemsHandler(index, arrayAllOrgsIndex, setArrayAllOrgsIndex);
  };

  return (
    <div>
      <Modal
        animation={false}
        dialogClassName="sort-modal"
        contentClassName="sort-modal-content"
        bsPrefix="modal"
        show={props.show}
        onHide={() => () => props.onSortClose()}
        aria-labelledby="example-custom-modal-styling-title"
        scrollable={true}
      >
        <Modal.Header className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex gap-2 fs-16 fw-600 color-primary">
              <div>
                <img src={icSort} alt="" />
              </div>
              <div> Filter </div>
            </div>
            <div className="d-flex gap-3" onClick={() => props.onSortClose()}>
              <img src={icClose} alt="close-icon" className="" width="20px" />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ps-4 pb-5">
          <div>
            {props?.sortArray?.map((sort, index) => {
              return (
                <div
                  key={index}
                  className="d-flex pb-4 pt-2"
                  onClick={(e) => {
                    dropdownAllTeamsHandler(index);
                  }}
                >
                  <input
                    type="checkbox"
                    className="me-2"
                    id={index}
                    name={index}
                    checked={arrayAllOrgsIndex.includes(index) ? true : false}
                    onClick={(e) => {
                      dropdownAllTeamsHandler(index);
                    }}
                  />
                  <label>{sort.name}</label>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div
            style={{ backgroundColor: "#F4F7FB", left: "0px" }}
            className="position-absolute bottom-0 d-sm-block d-md-none w-100 pb-1 "
          >
            <button
              className="btn down-arrow col-6 gap-2 btn-outline-none  fs-14 fw-700"
              onClick={() => setArrayAllOrgsIndex([])}
            >
              Reset
            </button>
            <button
              className="btn down-arrow col-5 gap-2 btn-outline-none fs-14 fw-700 border-start"

              // onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AdminAllOrgsFilterDialog;
