/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import globe from "../assests/icons/ic_globe.svg";
import aicon from "../assests/icons/ic_aicon.svg";
import twitter from "../assests/icons/ic_twitter.svg";
import youtube from "../assests/icons/ic_youtube.svg";
import playstore from "../assests/icons/ic_playstore.svg";
import vicon from "../assests/icons/ic_vicon.svg";
import linkedin from "../assests/icons/ic_linkedin.svg";
import facebook from "../assests/icons/ic_facebook.svg";
import { Link } from "react-router-dom";
import ReviewCarousel from "./ReviewCarousel";
import Carousel from "react-bootstrap/Carousel";

const ScratchAboutEmployer = (props) => {
  const [isSeeMore, setIsSeeMore] = useState(false);
  const imageArray = [
    -"./../assests/images/aboutemployer1.png",
    "./../assests/images/aboutemployer2.png",
    "./../assests/images/aboutemployer3.png",
    "./../assests/images/aboutemployer4.png",
  ];
  return (
    <div className="pt-3 about-employer-container">
      <div className="d-flex justify-content-between">
        <h2 className="large-text-black">
          {" "}
          Life at {props?.companyData?.companyName}
        </h2>
        <img
          src={`data:image/jpeg;base64 , ${props?.companyData?.companyLogo}`}
          alt="airbnblogo"
          className="bg-white p-2 rounded shadow"
          width="40px"
          height="40px"
        />
      </div>
      <div className="d-flex gap-2">
        <a href={props?.companyData?.presence?.WEBSITE}>
          <img src={globe} alt="aicon-icon" />
        </a>
        <div className="d-flex gap-3 ps-3 flex-wrap">
          <a href={props?.companyData?.presence?.APPLESTORE}>
            <img src={aicon} alt="aicon-icon" />
          </a>
          <a href={props?.companyData?.presence?.PLAYSTORE}>
            <img src={playstore} alt="playstore-icon" />
          </a>
          <a href={props?.companyData?.presence?.TWITTER}>
            <img src={twitter} alt="twitter-icon" />
          </a>
          <a href={props?.companyData?.presence?.FACEBOOK}>
            <img src={facebook} alt="facebook-icon" />
          </a>
          <a href={props?.companyData?.presence?.YOUTUBE}>
            <img src={youtube} alt="youtube-icon" />
          </a>
          <a href={props?.companyData?.presence?.VIMEO}>
            <img src={vicon} alt="vicon-icon" />
          </a>
          <a href={props?.companyData?.presence?.LINKEDIN}>
            <img src={linkedin} alt="linkedin-icon" />
          </a>
        </div>
      </div>
      <hr></hr>
      <div className="row gap-2">
        <div className="col-12 pb-2 ps-3 ">
          <span className="small-text-dark-gray fw-400">
            Information Technology
          </span>
          <span className="ps-3 small-text-dark-gray fw-400">
            Hospitality & Tourism
          </span>
        </div>
        <div className="col-12">
          <p className="large-text-gray fs-12">
            {props?.companyData?.companyDesc}
          </p>
        </div>
        <div className="col-12 row">
          <div className="col-md-6 col-12 pt-4">
            <img
              src={props?.mainImage}
              className="w-100 rounded-3"
              alt="main-image"
            />
          </div>
          <div className="col-md-6 col-12 pt-4">
            <span className="font-color-black fw-600 fs-16">
              {" "}
              Employee Benefits{" "}
            </span>
            <br />
            <div className="pt-3 pb-5">
              {props?.companyData?.employeeBenefits?.length > 0
                ? props?.companyData?.employeeBenefits?.map((item, index) => {
                    return (
                      <span className="font-color-pink p-1 ps-2 pe-2">
                        {props?.companyData?.employeeBenefits}
                      </span>
                    );
                  })
                : ""}
            </div>
            <hr className="mt-lg-5"></hr>
            <div className="d-flex">
              <div className="col-6">
                <span className="font-color-black  fs-12 fw-700">
                  {" "}
                  Headcount{" "}
                </span>{" "}
                <br />
                <span className="large-text-gray fs-12">
                  {" "}
                  {props?.companyData?.headcount}{" "}
                </span>
                <br />
              </div>
              <div className="col-6">
                <span className="font-color-black  fs-12 fw-700">
                  {" "}
                  Funding{" "}
                </span>{" "}
                <br />
                <span className="large-text-gray fs-12">
                  {" "}
                  {props?.companyData?.funding}
                </span>
              </div>
            </div>
            <hr></hr>
          </div>
        </div>

        <ReviewCarousel
          isNonRegisteredJob={true}
          companyData={props.companyData}
        />

        <div className="col-12 pt-3">
          <div className="row">
            <div className="col-12 col-lg-6">
              <span className="fw-700 fs-12 color-primary">
                {" "}
                Your Potential Colleagues{" "}
              </span>
              <br></br>
              <a
                href={props?.companyData?.potentialLink}
                target="_blank"
                className="font-gray-link pt-3"
              >
                {" "}
                {/* airbnb.io/updatesfromourteams{" "} */}
                {props?.companyData?.potentialLink}
              </a>
              <p className="large-text-gray fs-12 pt-3">
                {/* Our teams go above and beyond the regular work. If you didnt
                know, our design team killed GIFs usage in web by introducing
                Lottie and then making it Opensource - Lottie is now the
                industry standard for animations on the web and apps. */}
                {props?.companyData?.potentialDescription}
              </p>
            </div>
            <div className="col-12 col-lg-6 pt-3">
              <div className="d-flex position-relative">
                {props?.companyData?.potentialImagesUrl?.length === 1 && (
                  <div className=" d-flex justify-content-end">
                    <img src={imageArray[0]} className="w-50 " alt="1st-img" />
                  </div>
                )}
                {props?.companyData?.potentialImagesUrl?.length === 2 && (
                  <div className="d-flex justify-content-end">
                    {imageArray?.map((image, index) => {
                      return (
                        <img
                          src={image}
                          className="w-50"
                          alt={`${index}img2`}
                          key={index}
                        />
                      );
                    })}
                  </div>
                )}
                {props?.companyData?.potentialImagesUrl?.length === 3 && (
                  <div className="w-100 d-flex justify-content-end">
                    {imageArray?.map((image, index) => {
                      return (
                        <img
                          src={image}
                          className={index === 0 ? "w-50" : "h-50 w-50"}
                          alt={`${index}img3`}
                          key={index}
                        />
                      );
                    })}
                  </div>
                )}
                {props?.companyData?.potentialImagesUrl?.length === 4 && (
                  <div className="w-100 d-flex justify-content-end">
                    {imageArray?.map((image, index) => {
                      return (
                        <img
                          src={image}
                          className="h-50 w-50"
                          alt={`${index}img4`}
                          key={index}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
              <div
                className={isSeeMore ? "position-absolute d-block" : "d-none"}
              >
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="holder.js/800x400?text=First slide&bg=373940"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="holder.js/800x400?text=Second slide&bg=282c34"
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      <h3>Second slide label</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="holder.js/800x400?text=Third slide&bg=20232a"
                      alt="Third slide"
                    />

                    <Carousel.Caption>
                      <h3>Third slide label</h3>
                      <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div
                className={isSeeMore ? "position-absolute d-block" : "d-none"}
              >
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="holder.js/800x400?text=First slide&bg=373940"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <h3>First slide label</h3>
                      <p>
                        Nulla vitae elit libero, a pharetra augue mollis
                        interdum.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="holder.js/800x400?text=Second slide&bg=282c34"
                      alt="Second slide"
                    />

                    <Carousel.Caption>
                      <h3>Second slide label</h3>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="holder.js/800x400?text=Third slide&bg=20232a"
                      alt="Third slide"
                    />

                    <Carousel.Caption>
                      <h3>Third slide label</h3>
                      <p>
                        Praesent commodo cursus magna, vel scelerisque nisl
                        consectetur.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
          <hr></hr>
          <div>
            <span className="font-color-black fs-12 fw-700"> In the News </span>
            <br />
            <ul className="style-icon p-2 ps-4">
              {props?.companyData?.newsLinks?.map((news, index) => {
                return (
                  <>
                    <li className="p-1" key={index}>
                      <a
                        href={news}
                        target="_blank"
                        className="small-text-dark-gray fw-400"
                      >
                        {news}
                      </a>{" "}
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default ScratchAboutEmployer;
