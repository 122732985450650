export const LOGIN_ENDPOINT = "/api/admin/signin";
export const NOTIFICATION_PREFERENCE_ENDPOINT =
  "/api/admin/account/addNotificationPreference";
export const ADD_MOBILE_ENDPOINT = "/api/admin/account/addMobileNumber";
export const VIWECANDIDATE = "/api/admin/account/getAccountDetails/";
export const UPDATE_CANDIDATE_NAME = "api/admin/account/updateName";
export const REFEREE_INVITE_ENDPOINT = "/api/referee/invite";
export const REFEREE_INVITEDLIST_ENDPOINT =
  "/api/referee/getInvitedRefereesList";
export const REFEREE_STATUS_ENDPOINT = "/api/referee/changeStatus";
export const TEAM_SUMMARY_ENDPOINT = "/api/teams/teamsSummary";
export const INVITE_TEAM_ENDPOINT = "/api/admin/saveMember";
export const ADD_TEAM_ENDPOINT = "/api/teams/addTeam";
export const GET_ALL_USERS_ENDPOINT = "/api/teams/getLoggedInUserMembers";
export const GET_REFREE_LIST_ENDPOINT = "/api/referee/getRefereesList";
export const REFEREE_DETAILS_ENDPOINT="/api/referee/getRefereesDetails";
export const REFEREE_SUMMARY_ENDPOINT="/api/referee/getRefereesSummary" 

export const REGISTRATION_ENDPOINT = "/api/signupcandidate";
export const GEN_OTP_ENDPOINT = "api/otp/genotp";
export const PROFILE_ABOUTME = "/api/candidate/aboutMe";
export const COMPANY_DROPDOWN = "/api/master/getCompanyMaster";
export const CURRENT_ROLE_DROPDOWN = "/api/master/getRoleMaster";
export const CURRENT_DESIGNATION = "/api/candidate/currentDesignation";
export const USEREXIST = "api/common/userExist";
// export const VIWECANDIDATE = "api/candidate/viewCandidate/";
export const VERIFY_OTP_ENDPOINT = "/api/otp/verifyotp";
export const UPDATE_LOCATION_ENDPOINT = "/api/candidate/updateLocation";
export const ADD_SOCIAL_MEDIA_ENDPOINT = "/api/candidate/socialLinks";
export const UPDATE_EMAIL_ENDPOINT = "/api/profile/updateEmail";
// export const UPDATE_PHONE_ENDPOINT = "/api/profile/updateMobile";
export const GET_ALL_MASTER_ENDPOINT = "/api/common/getMaster";
export const ADD_EDUCATION_ENDPOINT = "/api/candidate/addEducationalExperience";
export const GET_MASTER = "api/common/getMaster";
export const UPLOAD_FILE = "api/core/upload";
export const ADD_BASIC_DETAILS_ENDPOINT = "api/admin/account/updateProfilePic";
export const SUPPORT_AND_FEEDBACK_ENDPOINT = "/api/admin/feedback";
// export const UPDATE_PRIVACY = "api/profile/updatePrivacy";
export const RETRIEVE_PASSWORD = "api/admin/resetPassword";
export const NEW_PASSWORD = "api/admin/changePassword";
export const UPDATE_RESUME = "api/profile/updateResume";
export const ADD_SKILLS = "api/candidate/addSkills";
export const DOWNLOAD_FILE = "api/core/download/PROFILE/";
export const GET_WORK_EXPERIENCE = "api/candidate/getWorkExperience/";
export const ADD_WORK_EXPERIENCE = "api/candidate/addWorkExperience";
export const GET_APPLIED_JOBS = "api/jobs/getAppliedJobs";
export const GET_RECOMMENDED_JOBS = "api/common/getRecommendedJobs";
export const GET_JOB_DETAILS_ENDPOINT = "api/jobs/view";
export const APPLY_FOR_JOBS_ENDPOINT = "api/jobs/applyJob";
export const REFER_JOB_ENDPOINT = "api/jobs/referJobs";
export const GET_BY_EMAIL_ENDPOINT = "api/getbyemail";
export const GET_BY_NAME_ENDPOINT = "api/getbyname";
export const GET_BY_MOBILE_ENDPOINT = "api/getbymobile";
export const GET_SAVED_JOB_ENDPOINT = "api/common/getSavedJobs";
export const GET_APPLIED_JOBS_ENDPOINT = "api/jobs/getAppliedJobs";
export const GET_APPLIED_JOBS_BY_ID_ENDPOINT = "api/candidate/viewApplication/";
export const UPDATE_SAVED_AND_PIN_JOB_ENDPOINT = "api/common/saveAndPinJob";
export const GET_REFFERED_JOB_ENDPOINT = "api/jobs/fetchReferedJobs";
export const GET_ALL_JOBS = "api/jobs/getAllJobs";
export const FETCH_REFEREE_OVERVIEW_ENDPOINT =
  "api/referee/fetchRefereeOverview";
export const FETCH_REFEREE_RECOMMENDATION_ENDPOINT =
  "api/referee/fetchRefereeRecommendation";
export const FETCH_REFEREE_SAVED_JOBS_ENDPOINT =
  "api/referee/fetchRefereeSavedJobs";
export const FETCH_REFEREE_REFERED_JOBS_ENDPOINT =
  "api/referee/fetchRefereeReferedJobs";
export const FETCH_REFEREE_REFERED_JOBSDETAILS_ENDPOINT =
  "api/referee/fetchReferedJobDetails";
export const UPDATE_JOB_PREFERENCES_ENDPOINT = "/api/profile/updatePreferences";
export const UPDATE_REFEREE_PREFERENCES_ENDPOINT =
  "/api/profile/setRefereePreferences";
export const GET_CURRENCY_ENDPOINT = "/api/master/getCurrencyMaster";
export const GET_COMPANY_DETAILS="/api/company/getCompanyDetails"
export const CREATE="/api/company/create";
export const FEEDBACK_LIST_ENDPOINT="/api/jobs/getCandidateFeedbackList"
export const DELETE_POTENTIAL_ENDPOINT="api/company/deletePotentialImage/" 
export const CREATE_FEEDBACK_JOBS = "api/common/feedback";

export const DELETE_RESUME = "api/profile/deleteResume/";
export const DELETE_WORK_EXPERIENCE = "/api/candidate/deleteWorkExperience/";
export const DELETE_EDUCATION_EXPERIENCE =
  "api/candidate/deleteEducationalExperience/";
export const CHANGE_PASSWORD = "api/admin/account/adminChangePassword";
export const GET_COUNTRY_MASTER_ENDPOINT = "api/master/getCountryMaster";
export const GET_LOCATION_MASTER_ENDPOINT ="/api/master/getLocationMaster";
export const RETRACT_APPLICATION_ENDPOINT = "api/application/retract/";
export const FETCH_NOTIFICATIONS_ENDPOINT = "api/notification/fetch";
export const FETCH_READ_NOTIFICATIONS_ENDPOINT =
  "api/notification/readNotification";
export const FETCH_CLEAR_NOTIFICATIONS_ENDPOINT =
  "api/notification/clearNotification";
export const REQUEST_PAYMENT_ENDPOINT = "api/referrer/redeemRewardPoint";
export const FETCH_PAYMENT_DETAILS_ENDPOINT =
  "api/referrer/getUserAccountDetails";
export const FETCH_ALL_MESSAGES_ENDPOINT = "api/messages/fetchMessages";
export const SEND_MESSAGE_ENDPOINT = "api/messages/sendMessages";
export const JOBSUMMARY = "/api/jobs/jobSummary";
export const CREATENEWJOB = "/api/jobPost";
export const GET_AUTOSAVE_JOB_POST = "api/jobs/getAutoSave";
export const AUTO_SAVE_JOB_POST = "api/jobs/autoSave"
export const ADDTEAM = "api/teams/addTeam";
export const GET_TEAMS = "/api/teams/getTeams";
// export const GETMEMBER = "/api/teams/getMembers";
export const GETLOGGEDINUSERMEMBERS = "/api/teams/getLoggedInUserMembers";
export const GETMEMBER = "/api/teams/getMembers";
export const GETMEMBERDETAIL = "/api/teams/memberDetails/";
export const DELETEMEMBER = "/api/teams/deleteMember/";
export const COMPLETE_TEAM_ENDPOINT = "/api/teams/getTeams";
export const TEAMS_DETAIL_PAGE_ROUTE = `/team-detail`;
export const VIEW_TEAM_DETAILS = "/api/teams/teamDetails/";
export const ADD_FOR_TEAM_MEMBERS = "/api/teams/addMemberForTeam/";
export const GET_TEAM_MEMBERS = "/api/teams/getTeamMembers/";
export const DELETE_TEAM = "/api/teams/deleteTeam/";
export const REMOVE_MEMBERS_TEAM = "/api/teams/deleteFromTeam";

export const PREVIOUS_JOBS = "/api/jobs/view/";
export const PREVIOUS_JOB_TEMPLATE = "/api/job/template/getDetails/";

export const JOBTEMPLATE = "/api/job/template/getAll";

export const CREATETEMPLATE = "/api/job/template/create";
export const AllSAVEDRFT = "/api/job/template/getAllDraftJobPost";
export const GET_PIN_JOBS = "/api/jobs/jobSummary";
export const PIN_OR_UNPIN_JOBS = "/api/jobs/pinJob";
export const DOWNLOAD_JOBS = "/api/jobs/jobExport";











// ---------------- MASTER ---------------------
export const MASTERNEWJOB = "api/common/getMaster";
export const VIEWJOB = "api/jobs/view";
export const GET_APPLICATION_JOB_LISTING = "api/jobs/getJobApplications";
export const GET_APPLICATION_STATUS_WISE_COUNT = "api/jobs/getJobApplicationsStatusWiseData";
export const GET_APPLICATION_INSIGHTS = "/api/jobs/ApplicationInsights";

export const GET_APPLICATION_DETAILS = "api/jobs/application";
export const GET_APPLICATION_STATUS_HISTORY = "api/jobs/statusHistory";
export const GET_USER_APPLICATION_ALL_NOTES =
  "api/jobs/application/getAllNotes";
export const SAVE_APPLICATION_NOTES = "api/jobs/application/addNote";
export const GET_REFEREE_DETAILS = "api/candidate/viewCandidate";
export const GET_REFEREE_BAR_CHART = "api/referee/getRefereesSummary";
export const UPDATE_APPLICATION_STATUS = "api/jobs/updateApplicationStatus";
export const UPDATE_ACCEPTED_STATUS = "api/jobs/offeredAccept";
export const UPDATE_FLAG = "api/jobs/flagApplication";
export const GET_ALL_TEAMS_SUMMARY = "api/teams/teamsSummary";
export const GET_AVG_JOINING_DAYS = "api/teams/averageJoining";
export const DELETE_JOBTEMPLATE = "/api/job/template/delete/";
export const ORAGANIZATION_SUMMARY  = "/api/overview/organizationSummary";
export const VIEW_ORAGANIZATION_ENDPOINT="/api/org/viewDetails";
export const ALL_ORAGANIZATION_ENDPOINT="/api/org/allOrganization";
export const SEND_CANDIDATE_NOTIFICATION =
  "api/jobs/sendCandidatesNotification";
export const SAVE_POC_MEMBER_ENDPOINT ="/api/company/create"

export const SEND_REFEREES_NOTIFICATION = "api/jobs/sendRefereesNotification";
export const GET_ACTION_HISTORY = "api/jobs/getActionHistory";
export const CHANGE_JOB_STATUS = "api/jobs/changeStatus";

export const ORGS_TOP_REFEREES = "/api/overview/getOrgTopReferees";
export const VIEW_TEAM_MEMBERS = "/api/teams/getTeamsMembers";
export const REFERRAL_BONUS = "/api/teams/getReferralBonus";
export const PIN_UNPIN_TEAM = "/api/teams/pinTeam";
export const DOWNLOAD_APPLICATIONS_COUNT = "/api/jobs/downloadCount";

export const MORE_INSIGHTS = "/api/overview/moreInsights";
export const GET_PIN_TEAMS = "api/teams/teamsSummary";
export const PIN_OR_UNPIN_TEAMS = "api/teams/pinTeam";

export const PIN_ICON = "/api/jobs/pinJob";
export const ORGANIZATION_SUMMARY = "api/overview/organizationSummary";
export const MEMBER_WISE_TEAM_SUMMARY = "api/teams/teamsSummary";
export const MEMBER_WISE_REFERRAL_BONUS = "api/teams/getReferralBonus";
export const GET_ACTION_HISTORY_BY_ID = "api/jobs/getActionHistoryById";
export const MEMBER_DETAILS_CHANGE_ROLE = "api/seikorAdmin/changeRole";
export const CHANGE_PAYMENT_STATUS = "api/referrer/changePaymentStatus";



// -----------------Data constant --------------
export const LINKEDIN = "linkedin";
export const MASTER_TYPE = {
  JOBROLE: "JOBROLE",
  COMPANY: "COMPANY",
  JOBSTATUS: "JOBSTATUS",
  LOCATION: "LOCATION",
  KEYSKILLS: "KEYSKILLS",
  INDUSTRIES: "INDUSTRIES",
  FUNCTIONS: "FUNCTIONS",
  JOBTYPES: "JOBTYPES",
  QUALIFICATION: "QUALIFICATION",
  DEGREE: "DEGREE",
  SPECIALIZATION: "SPECIALIZATION",
  WORKMODE: "WORKMODE",
  WORKSTATUS: "WORKSTATUS",
  SALARYTYPE: "SALARYTYPE",
  REPORT_CATEGORY:"REPORT_CATEGORY",
  FUNDING:"FUNDING",
  HEADCOUNT:"HEADCOUNT",
  CATEGORY:"CATEGORY",
};

export const USER_ID = "userId";

// export const USER_ROLE = {
//   SUPER_ADMIN: "SUPER_ADMIN",
//   ADMIN: "ADMIN",
//   BUSINESS_ADMIN: "BUSINESS_ADMIN",
//   BUSINESS_USER: "BUSINESS_USER",
// };
export const USER_ROLE = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ADMIN: "SEIKOR_ADMIN",
  BUSINESS_ADMIN: "BIZ_ADMIN",
  BUSINESS_USER: "BIZ_USER",
};

export const CHOICE_QUESTION = "Y_N";
export const FREE_TEXT = "F_T";
export const YES = "YES";
export const NO = "NO";
export const FAILED_TO_LOAD = "Failed to Load";
export const STATUS_200 = 200;
export const WRONG_CREDENTIALS = "Wrong Credentials";
export const SOMETHING_WENT_WRONG = "Something Went Wrong";
export const STATUS_SUCCESS = "SUCCESS";
export const APPLIED = "APPLIED";

// export const PASSWROD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
export const pattern = (regExp, errorMsg = "Pattern not matched") => {
  return { pattern: regExp, errorMsg };
};
export const PASSWORD_PATTERN =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8}$/;
export const NAME_PATTERN = /^[a-zA-Z0-9-]+[a-z A-Z 0-9 -]*$/;
export const YEAR_PATTERN = /^(19|20)\d{2}$/;
export const PHONE_NUMBER_PATTERN_REGISTRATION = /^[0-9+]{5,13}$/;
export const PHONE_NUMBER_PATTERN_LOGIN = /^[0-9+]{9,19}$/;
export const PHONE_CHECK = "International Phone Check";
export const ALPHABET_CHECK = /[a-zA-Z]/;
export const NUMBER_PATTERN = /^[0-9]*$/;
// --------------------------------------------------------------------------------------
