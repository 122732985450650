import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import filterIcon from "./../../assests/icons/ic-filter-16.svg";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import IcDoneWhite from "../../assests/icons/ic_done_white.svg";
import "../../pages/auth_protected/applications_main_page/application.scss";
import minus from "../../assests/icons/ic_minus.svg";
import plus from "../../assests/icons/ic_plus.svg";
import SearchComboBox from "../../components/SearchComboBox";
import "../../pages/auth_protected/applications_main_page/filter.scss";
import { getLocations, getMaster } from "../../_services/view.service";
import { MASTER_TYPE } from "../../config/be_api_constants";

const FilterDialog = (props) => {
  const [selectedReferral, setSelectedReferral] = useState(null);
  const referrals = ["Show All", "With Referrals", "Without  Referrals"];
  const [selectedQualification, setSelectedQualification] = useState(null);
  const qualifications = ["Show All", "Graduate", "Post Graduate", "Doctorate"];
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [increment, setIncrement] = useState("");
  const [decrement, setDecrement] = useState("");

  const [referralStatus, setreferralStatus] = useState("");
  const status = [
    "All Status",
    "Looking for New Role",
    "Not actively looking",
    "On Notice Period",
    "Recently Moved ",
    "Recently Promoted ",
  ];
  const [count, setCount] = useState(0);
  const [value, setValue] = useState(0);
  const incrementMinimumYears = () => {
    if (count === "") {
      setCount(count);
    } else {
      setCount(count + 1);
      setIncrement(count + 1);
    }
  };

  const decrementMinimumYears = () => {
    if (count <= 0) {
      setCount(0);
    } else {
      setCount(count - 1);
      setDecrement(count - 1);
    }
  };

  const [count2, setCount2] = useState(0);
  const incrementMaximumYears = () => {
    setCount2(count2 + 1);
  };

  const decrementMaximumYears = () => {
    if (count === count2) {
    } else {
      setCount2(count2 - 1);
    }
  };
  const [locationOutput, setLocationOutput] = useState([]);

  const [loc_Output, setloc_Output] = useState("");
  useEffect(() => {
    let loc = locationOutput.toString();
    setloc_Output(loc);
    if (selectedReferral === "With Referrals") {
      setreferralStatus(true);
    }
    if (selectedReferral === "Without  Referrals") {
      setreferralStatus(false);
    }
    if (selectedReferral === "Show All") {
      setreferralStatus(null);
    }
  }, [locationOutput, selectedReferral]);

  const [locations, setLocations] = useState([
    {
      name: "India",
      description: "India",
    },
    {
      name: "USA",
      description: "USA",
    },
    {
      name: "Europe",
      description: "Europe",
    },
    {
      name: "Japan",
      description: "Japan",
    },
    {
      name: "Korea",
      description: "Korea",
    },
  ]);

  const filtersApplied = () => {
    if (
      selectedReferral != null ||
      selectedQualification != null ||
      selectedStatus != null
    ) {
      props?.setFiltersDropdownRedDot("d-block");
      props?.setAllFiltersDropdownRedDot(true);
      return;
    } else if (count2 > 0) {
      props?.setFiltersDropdownRedDot("d-block");
      props?.setAllFiltersDropdownRedDot(true);
      return;
    } else if (locationOutput?.length > 0) {
      props?.setFiltersDropdownRedDot("d-block");
      props?.setAllFiltersDropdownRedDot(true);
      return;
    } else {
      props?.setFiltersDropdownRedDot("d-none");
      props?.setAllFiltersDropdownRedDot(false);
    }
  };

  const [location, setLocation] = useState();
  const [jobStatuses, setJobStatuses] = useState();
  const [qualification, setqualification] = useState();

  const getAllMasterData = async () => {
    const jobStatus = await getMaster(MASTER_TYPE.JOBSTATUS);
    let status = jobStatus.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setJobStatuses(status);

    const qualification = await getMaster(MASTER_TYPE.QUALIFICATION);
    let jobQualification = qualification.map((el) => {
      return { name: el.masterValue, description: el.masterCode };
    });
    setqualification(jobQualification);
  };

  const getLocationMaster = () => {
    getLocations().then((res) => {
      setLocation(res?.data);
      // return res;
    });
  };
  useEffect(() => {
    getAllMasterData();
    getLocationMaster();
  }, []);

  function clearAll() {
    setSelectedReferral(null);
    setCount(0);
    setCount2(0);
    setSelectedQualification(null);
    setLocationOutput("");
    setSelectedStatus("");
  }

  return (
    <div>
      <Modal
        fullscreen="md-down"
        show={props.show}
        onHide={() => () => props.onFilterClose()}
        aria-labelledby="example-custom-modal-styling-title"
        size="md"
        scrollable={true}
        className="rounded-corner-none"
      >
        <Modal.Header className="p-4">
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex gap-2 fs-16 fw-600 color-primary">
              <div>
                <img src={filterIcon} alt="" />
              </div>
              <div> Filters </div>
            </div>
            <div
              className="d-flex gap-3 pointer"
              onClick={() => props.onFilterClose()}
            >
              <img
                src={icCloseRounded}
                alt="close-icon"
                className=""
                width="25px"
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body className="ps-4">
          <div className="p-2">
            <div className="d-flex flex-wrap justify-content-between">
              <span className="fs-14 color-primary fw-700"> Referrals </span>
              <span
                onClick={() => {
                  setSelectedReferral(null);
                }}
                className="dark-pink-text fs-12 pointer"
              >
                Clear
              </span>
            </div>
            <div className="d-flex flex-wrap gap-2 pt-3 mb-4">
              {referrals.map((referral, index) => {
                return (
                  <button
                    className="btn page-filter-button d-flex flex-wrap pe-2"
                    key={index}
                    onClick={() => setSelectedReferral(referral)}
                  >
                    <div
                      className={
                        selectedReferral === referral
                          ? "px-1 pt-0 pb-0 bg-black rounded-circle me-1 d-block"
                          : "d-none"
                      }
                    >
                      <img src={IcDoneWhite} alt="whitedone-icon" />
                    </div>
                    {referral}
                  </button>
                );
              })}
            </div>
            <hr></hr>
            <div className="d-flex flex-wrap justify-content-between mt-4">
              <span className="fs-14 color-primary fw-700"> Experience </span>
              <span
                className="dark-pink-text fs-12 pointer"
                onClick={() => {
                  setCount(0);
                  setCount2(0);
                }}
              >
                {" "}
                Clear{" "}
              </span>
            </div>
            <div className="d-flex flex-wrap mt-4 mb-4">
              <div className="col-md-6 col-12">
                <h6 className="small-text-dark-gray fw-400 color-secondary">
                  Minimum years
                </h6>
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <button
                    className="btn rounded border ps-3 pe-3"
                    onClick={decrementMinimumYears}
                  >
                    {" "}
                    <img src={minus} alt="minus-icon" />
                  </button>
                  <label
                    type="number"
                    className="ps-3 pe-3 btn rounded border w-25 text-center"
                  >
                    {count}
                  </label>
                  <button
                    className="btn rounded border ps-3 pe-3"
                    onClick={(count < count2 ? incrementMinimumYears : null)}
                  >
                    {" "}
                    <img src={plus} alt="plus-icon" />
                  </button>
                </div>
              </div>
              <div className="col-md-6 pt-3 pt-md-0 col-12">
                <h6 className="small-text-dark-gray fw-400 color-secondary">
                  Maximum years
                </h6>
                <div className="d-flex flex-wrap gap-3 mt-3">
                  <button
                    className="btn rounded border ps-3 pe-3"
                    onClick={decrementMaximumYears}
                  >
                    {" "}
                    <img src={minus} alt="minus-icon" />
                  </button>
                  <label
                    type="number"
                    className="ps-3 pe-3 btn rounded border w-25 text-center"
                  >
                    {count2}
                  </label>
                  <button
                    className="btn rounded border ps-3 pe-3"
                    onClick={incrementMaximumYears}
                  >
                    {" "}
                    <img src={plus} alt="plus-icon" />
                  </button>
                </div>
              </div>
            </div>
            {/* experience finished */}
            <hr></hr>
            <div className="d-flex flex-wrap justify-content-between mt-4">
              <span className="fs-14 color-primary fw-700">Qualification</span>
              <span
                onClick={() => setSelectedQualification(null)}
                className="dark-pink-text fs-12 pointer"
              >
                Clear
              </span>
            </div>
            <div className="d-flex flex-wrap gap-2 pt-3 mb-4">
              {qualification?.map((qualification, index) => {
                return (
                  <button
                    className="btn page-filter-button d-flex flex-wrap pe-2"
                    key={index}
                    onClick={() =>
                      setSelectedQualification(qualification?.name)
                    }
                  >
                    <span
                      className={
                        selectedQualification === qualification?.name
                          ? "p-1 pt-0 pb-0 bg-black rounded-circle me-1 d-block"
                          : "d-none"
                      }
                    >
                      <img src={IcDoneWhite} alt="whitedone-icon" />
                    </span>
                    {qualification?.name}
                  </button>
                );
              })}
            </div>
            {/* qualification section finished */}
            <hr></hr>
            <div className="d-flex flex-wrap justify-content-between mt-4">
              <div className="fs-14 color-primary fw-700">Locations</div>
              <div
                className="dark-pink-text fs-12 pointer"
                onClick={() => {
                  setLocationOutput("");
                }}
              >
                {" "}
                Clear{" "}
              </div>
            </div>
            <div>
              <div className="pt-3 mb-4">
                <SearchComboBox
                  inputData={location}
                  defaultValue={locationOutput}
                  isMultiSelect={false}
                  inputCssClass={"modal-input combo-search-box"}
                  wrapperCssClass={"form-group"}
                  placeholder={"Search or Select"}
                  onSelect={(event) => setLocationOutput(event)}
                  searchListHeight={150}
                  badgeThemeCssClass={"gray"}
                />
              </div>
            </div>
            <hr></hr>
            <div className="d-flex mt-4">
              <div className="fs-14 color-primary fw-700">Status</div>
            </div>
            <div className="d-flex flex-wrap gap-2 pt-3 mb-4">
              {jobStatuses?.map((status, index) => {
                return (
                  <button
                    className="btn page-filter-button d-flex flex-wrap pe-2"
                    key={index}
                    onClick={() => setSelectedStatus(status?.name)}
                  >
                    <span
                      className={
                        selectedStatus === status?.name
                          ? "p-1 pt-0 pb-0 bg-black rounded-circle me-1 d-block"
                          : "d-none"
                      }
                    >
                      <img src={IcDoneWhite} alt="whitedone-icon" />
                    </span>
                    {status.name}
                  </button>
                );
              })}
            </div>
            <hr></hr>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-dialog-footer d-flex flex-no-wrap justify-content-between ps-4">
          <div className="">
            <div
              className="fs-12 fw-400 dark-pink-text pointer"
              onClick={() => {
                clearAll();
              }}
            >
              Clear All
            </div>
          </div>
          <button
            onClick={() => {
              filtersApplied();
              props.getApplicationCardDetails(
                null,
                referralStatus,
                count,
                count2,
                selectedQualification,
                loc_Output,
                selectedStatus,
                null
              );
               props?.onFilterClose();
              // clearAll()
            }}
            className="btn-black flex-0"
          >
            Apply
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FilterDialog;
