export const TOKEN = "token";
export const USER_ID = "userId";

export const ORG_ID = "orgId";
export const ORG_LOGO = "orgLogo";

export const PREV_NOTIFICATION_COUNT = "prevNotificationCount";

export const LOG_TO_ORG = "log_to_org";
export const STATUS_SUCCESS = "SUCCESS";
export const SOMETHING_WENT_WRONG = "Something Went Wrong";
export const APPLY_STATUS = {
  APPLIED: "APPLIED",
  NOT_APPLIED: "NOT_APPLIED",
  WAITING_INTERVIEW: "WAITING_INTERVIEW",
  SELECTED: "SELECTED",
  PLACED: "PLACED",
  RETRACTED: "RETRACTED",
  JOINED: "JOINED",
};
