import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ProfileImage from "../../components/profile_image";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";
import { create } from "../../_services/company.service";
import toaster from "../../utils/toaster";
import { onFormFeildsChange, validateField } from "../../utils/form_validators";
import {
  REQUIRED,
  ALPHABET_CHECK,
  EMAIL_PATTERN,
} from "../../config/validation_patterns_constants";
import Loader from "../../components/common/loader";

const CompanyEmail = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [officialEmail, setOfficialEmail] = useState();

  const [formData, setFormData] = useState({
    officialEmail: {
      valueText: "",
      errorText: "",
      check: [REQUIRED, EMAIL_PATTERN],
    },
  });

  const submitFormOnEnter = (e) => {
    if (e?.keyCode == 13) {
      if (formData?.officialEmail?.errorText == "") {
        onSaveEmail();
      }
    }
  };

  const saveEmail = {
    companyId: props.companyId,
    officialEmail: officialEmail,
  };

  const onSaveEmail = () => {
    
    let emailValid = true;
    if(formData?.officialEmail?.valueText===""){
      toaster("error", "Please Add Email Id");
    }
    else{
    if (ALPHABET_CHECK.test(formData?.officialEmail?.valueText)) {
      emailValid = validateField("officialEmail", formData, setFormData);
      
      setShowLoader(true);
      create(saveEmail)
        .then((res) => {
          
          props?.setApiRefresh(true);
          if (res.status === 200) {
            if (
              EMAIL_PATTERN.test(formData?.officialEmail?.valueText) &&
              res?.data?.data?.emailVerified == false
            ) {
              if (props?.verificationNeeded) {
                props?.setOfficialEmail(formData?.officialEmail?.valueText);
                props?.verificationNeeded();
              }
            } else {
              // navigate("/business/");
            }
            setShowLoader(false);
            props.onEmailClose();
          } else {
            toaster("error", res.data.message);
            props?.setApiRefresh(false);
          }
        })
        .catch((err) => {
          setShowLoader(false);
          props?.setApiRefresh(false);
          toaster("error", err);
        });
    }
  }
  };

  return (
    <>
      {showLoader && <Loader />}
      <div>
        {" "}
        <Modal
          show={props.show}
          onHide={() => props.onEmailClose()}
          backdrop="static"
          fullscreen="lg-down"
          centered
          keyboard={false}
          className="lg-dialog-modal"
        >
          <Modal.Header className="dialog-header border-0">
            <div className="w-100">
              <div className="d-flex justify-content-end">
                <img
                  src={icCloseRounded}
                  alt="close-icon"
                  onClick={() => props.onEmailClose()}
                  className="pointer"
                />
              </div>
              <div className="fs-24 color-primary fw-700 ps-3">
                Company Email
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="dialog-body">
            <div className="p-3 pt-0">
              <div>
                <div className="fs-14 color-secondary fw-600"> Email* </div>
                <div className="pt-3 pb-5">
                  <input
                    onKeyDown={(e) => {
                      submitFormOnEnter(e);
                    }}
                    type="text"
                    className={
                      (formData?.officialEmail?.errorText ? "error" : "modal-form-input input-color-black") +
                      " form-control"
                    }
                    name="officialEmail"
                    // defaultValue={formData.officialEmail.valueText}
                    defaultValue={props.officialEmail}
                    // className="modal-form-input small-text-medium-gray"
                    // placeholder="support@airbnb.com"
                    //  onChange={(e) => {
                    //   setOfficialEmail(e.target.value);
                    // }}

                    onChange={($event) => {
                      onFormFeildsChange($event, formData, setFormData);
                      setOfficialEmail($event.target.value);
                    }}
                  />
                  {formData.officialEmail.errorText && (
                    <div className="field-error mt-1">
                      {formData.officialEmail.errorText}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="dialog-footer">
            <button
              className="btn-dialog-cancel pointer"
              onClick={() => props.onEmailClose()}
            >
              Cancel
            </button>
            <button
              className="btn-dialog-save pointer"
              // onClick={() => {
              //     onSaveEmail();
              //     props.onEmailClose()
              // }}
              onClick={() => {
                if (formData?.officialEmail?.errorText == "") {
                  onSaveEmail();
                }
                
              }}
            >
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default CompanyEmail;
