import { isEmpty } from "./form_validators";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import { NOT_MENTIONED } from "../config/messages";

export const convertInThousand = (data) => data / 1000;
export const monthConversion = (num) => {
  let years = Math.floor(num / 12);
  let months = num % 12;
  if (years > 1) {
    if (months > 1) return `${years} years ${months} months`;
    else return `${years} years ${months} month`;
  } else {
    if (years == 0) {
      if (months > 1) return `${months} months`;
      else return `${months} month`;
    }
    if (months > 1) return `${years} year ${months} months`;
    else return `${years} year ${months} month`;
  }
};

export const isCheckValue = (value) => {
  const valueType = typeof value;
  if (valueType === "string") {
    if (isEmpty(value)) {
      return false;
    } else {
      return true;
    }
  } else if (valueType === "object") {
    const condition = Array.isArray(value);
    if (condition && value?.length > 0) {
      if (value.length === 1) {
        if (isEmpty(value[0])) {
          return false;
        } else {
          return true;
        }
      } else {
        if (isEmpty(value[1])) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
};

export const checkPhoneNumberValid = (phoneNumber, countryCode = 0) => {
  let value;
  if (countryCode === 0) {
    value = phoneNumber;
  } else {
    value = countryCode + phoneNumber;
  }
  let result = isPossiblePhoneNumber(value);
  return result;
};

export const convertToInternationalCurrencySystem = (value, currency = 0) => {
  if (Math.abs(Number(value)) >= 1.0e9) {
    return Math.abs(Number(value)) / 1.0e9 + "B";
  } else if (Math.abs(Number(value)) >= 1.0e7) {
    if (currency == "INR") {
      return Math.abs(Number(value)) / 1.0e7 + "Cr";
    }
  } else if (Math.abs(Number(value)) >= 1.0e5) {
    if (currency == "INR") {
      return Math.abs(Number(value)) / 1.0e5 + "L";
    }
  } else if (Math.abs(Number(value)) >= 1.0e6) {
    return Math.abs(Number(value)) / 1.0e6 + "M";
  } else if (Math.abs(Number(value)) >= 1.0e3) {
    return Math.abs(Number(value)) / 1.0e3 + "K";
  } else {
    return Math.abs(Number(value));
  }
};

export const checkedItemsHandler = (index, array, setArr) => {
  let newArr = [...array];
  let findItemWithIndex = newArr.findIndex((item) => item === index);
  if (newArr.includes(index)) {
    newArr.splice(findItemWithIndex, 1);
  } else {
    newArr.push(index);
  }
  setArr(newArr);
};
export const yearConversion = (minExp, maxExp) => {
  if (!maxExp) {
    return `${minExp} Year${minExp > 1 ? "s" : ""}`;
  } else if (!isEmpty(minExp) && maxExp) {
    return `${minExp} to ${maxExp} Year${maxExp > 1 ? "s" : ""}`;
  } else {
    return NOT_MENTIONED;
  }
};
