import React from "react";
import { Modal } from "react-bootstrap";
import "../../pages/auth_protected/jobs_post_page/job-post-style.scss";
import scratchStart from "../../assests/scratchStart.svg";
import RightArrow from "../../assests/Vector.svg";
import copyPrevious from "../../assests/copyPrevious.svg";
import orgTemplate from "../../assests/orgTemplate.svg";
import icCloseRounded from "../../assests/icons/ic-close-rounded.svg";

import { useState } from "react";
import CopyPreviousJobPostModal from "./copy-previous-job-modal";
import { JOB_POST_CREATE_PAGE_ROUTE } from "../../config/page_routes_constants";
import { Link, useNavigate } from "react-router-dom";

const Card = ({
  data,
  action,
  setShowModalWithType,
  setShowCreateJobPostModal,
  setShowJobMainModal,
}) => {
  const navigate = useNavigate();
  return (
    <>
      {data && data.length > 0
        ? data.map((item, key) => {
            return (
              <div
                onClick={() => {
                  if (item.type === 1) {
                    navigate(
                      "/" + JOB_POST_CREATE_PAGE_ROUTE + "/" + "scratch"
                    );
                  } else {
                    setShowModalWithType(item.type);
                    setShowCreateJobPostModal(false);
                  }
                }}
                className="card border-1 m-3 radius19 col-11 pointer"
              >
                <div className="card-body m-2 position-relative d-flex align-items-center startScratch justify-content-between">
                  <img
                    className="col-2"
                    src={item.imgSrc}
                    alt=""
                    height="45px"
                    width="45px"
                  ></img>
                  <p className="fs-16 fw-700 col-8 m-0 p-0">{item.text}</p>
                  <div className="col-1">
                    {item.type === 1 ? (
                      <div
                        style={{
                          height: "37px",
                          width: "37px",
                        }}
                        className="fs-12 btn bg-black text-white scratch-btn justify-content-center  align-items-center"
                      >
                        <img
                          alt=""
                          style={{
                            height: "1rem",
                            width: "21px",
                          }}
                          src={RightArrow}
                          className=""
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          height: "37px",
                          width: "37px",
                        }}
                        className="fs-12 btn bg-black text-white scratch-btn justify-content-center align-items-center"
                      >
                        <img
                          alt=""
                          style={{
                            height: "1rem",
                            width: "21px",
                          }}
                          src={RightArrow}
                          className=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : ""}
    </>
  );
};
const CreateNewJobPostModal = (props) => {
  const data = [
    {
      id: 1,
      imgSrc: scratchStart,
      text: "Start from scratch",
      type: 1,
    },
    {
      id: 2,
      imgSrc: copyPrevious,
      text: "Copy from a previous posts",
      type: 2,
    },
    {
      id: 3,
      imgSrc: orgTemplate,
      text: "Create from your Org Templates",
      type: 3,
    },
  ];

  return (
    <div className="radius19">
      <Modal
        show={props.show}
        centered
        backdrop="static"
        fullscreen="lg-down"
        onHide={() => props.close()}
        keyboard={false}
        className=""
      >
        <Modal.Header className="dialog-header border-0 ms-3 me-2">
          <div className="fs-24 color-primary fw-700">Create New Job Post</div>
          <img
            src={icCloseRounded}
            alt="close-icon"
            height="30px"
            width="30px"
            onClick={() => props.close()}
            className="pointer"
          />
        </Modal.Header>
        <Modal.Body className="dialog-body ms-3 me-2 p-0 mt-2 mb-3">
          <Card
            data={data}
            setShowModalWithType={props.setShowModalWithType}
            setShowCreateJobPostModal={props.setShow}
            setShowJobMainModal={props.setShowJobMainModal}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CreateNewJobPostModal;
