import React from "react";
import { Dropdown } from "react-bootstrap";
import icBlackDone from "./../../../assests/icons/ic_blackdone.svg";
import icThreeDots from "../../../assests/icons/ic_retract.svg";
import { useState } from "react";
import { updateApplicationStatus } from "../../../_services/view.service";
import { APPLICATION_PAGE_ROUTE } from "../../../config/page_routes_constants";
import { useNavigate } from "react-router";
import toaster from "../../../utils/toaster";

const DropdownCardShortlisted = ({ applicationId, getApplicationCardDetails }) => {
  const [arrayIndex, setArrayIndex] = useState(null);
  const navigate = useNavigate();
  const dropdownStatusArray = [
    "View Application",
    "Move To:",
    "Received (Unassigned)",
    "Offered",
    "Joined",
    "Dropout",
    "Rejected",
  ];

  const statusChange = (appStatus, id) => {
    console.log("buttonclick-->", appStatus);
    updateApplicationStatus(id, appStatus).then((res) => {
      console.log("update Application Status", res);
      // setShowLoader(false);
      getApplicationCardDetails();

      if(res?.data?.message !== "Application Status Updated !!"){
        toaster("error", res?.data?.message, 3000)
      }
    });
    // setShowLoader(true);
  };

  return (
    <div>
      <Dropdown
        className="down-btn-remove"
        align="end"
        title="Dropdown end"
        id="dropdown-menu-align-end"
      >
        <Dropdown.Toggle
          variant="none"
          className="d-flex p-2 m-0 border-0 bg-transparent "
        >
          <img src={icThreeDots} alt="retract-icon" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="fs-12 text-secondary mt-2 border-box ps-3 pb-3 pt-0">
          {/* {dropdownStatusArray.map((sort, index) => {
            return (
              <Dropdown.Item
                key={index}
                className={
                  sort === "Move To:"
                    ? "d-flex border-0 pt-2 pb-2 ps-0"
                    : "d-flex border-bottom pt-2 pb-2 ps-0"
                }
                onClick={() => setArrayIndex(index)}
              >
                <div
                  className={
                    arrayIndex === index
                      ? "color-primary fw-700 fs-12"
                      : "color-primary fw-400 fs-12"
                  }
                >
                  {sort === "Dropout" || sort === "Rejected" ? (
                    <div className="dark-pink-text"> {sort}</div>
                  ) : (
                    <div
                      className={
                        sort === "View Application" ? "fw-700" : "fw-400"
                      }
                    >
                      {sort}
                    </div>
                  )}
                </div>
              </Dropdown.Item>
            );
          })} */}

          <Dropdown.Item
            className="d-flex border-bottom pt-2 pb-2 ps-0"
            onClick={() =>
              navigate("/" + APPLICATION_PAGE_ROUTE + `/${applicationId}`)
            }
          >
            <div className="color-primary fw-700 fs-12">View Application</div>
          </Dropdown.Item>
          {/* <Dropdown.Item className="d-flex border-0 pt-2 pb-2 ps-0">
            <div className="color-primary fw-400 fs-12">Move To</div>
          </Dropdown.Item> */}
          <Dropdown.Item
            className="d-flex border-bottom pt-2 pb-2 ps-0"
            onClick={() => statusChange("APPLIED", applicationId)}
          >
            <div className="color-primary fw-400 fs-12">
              Received (Unassigned)
            </div>
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex border-bottom pt-2 pb-2 ps-0"
            onClick={() => statusChange("OFFERED", applicationId)}
          >
            <div className="color-primary fw-400 fs-12">Offered</div>
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex border-bottom pt-2 pb-2 ps-0"
            onClick={() => statusChange("JOINED", applicationId)}
          >
            <div className="color-primary fw-400 fs-12">Joined</div>
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex border-bottom pt-2 pb-2 ps-0"
            onClick={() => statusChange("RETRACTED", applicationId)}
          >
            <div className="dark-pink-text">Dropout</div>
          </Dropdown.Item>
          <Dropdown.Item
            className="d-flex border-bottom pt-2 pb-2 ps-0"
            onClick={() => statusChange("REJECTED", applicationId)}
          >
            <div className="dark-pink-text">Rejected</div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default DropdownCardShortlisted;
