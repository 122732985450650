import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import checkedLogo from "../../../assests/ic_selected16.svg";
import { isEmpty } from "../../../utils/form_validators";
import Vectorleft from "../../../assests/Vectorleft.svg";
import profileImg from "../../../assests/images/candidate-profile-pic.jpg";
import icDelete from "../../../assests/Vectordelete.svg";
import DeleteMemberDialog from "../../../dialogs/teams_and_members/delete_member_dialog";
import {
  getmemberdetail,
  memberDetailsChangeRole,
} from "../../../_services/view.service";
import "./teams_member_style.scss";
import toaster from "../../../utils/toaster";
import Loader from "../../../components/common/loader";

const MemberDetailPage = () => {
  const { id } = useParams();
  const [teammember, setTeammember] = useState([]);
  const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState(false);
  const [roleOutput, setRoleOutput] = useState();
  const [roleOutput1, setRoleOutput1] = useState();
  const [deleteMember, setDeleteMember] = useState();
  const [showLoader, setShowLoader] = useState(false);

  console.log("role", roleOutput1);

  const getmemberdetails = (id) => {
    console.log("id", Number(id));
    setShowLoader(true);
    getmemberdetail(id)
      .then((res) => {
        // console.log("navin", res?.data?.data);
        setTeammember(res?.data?.data);
        setRoleOutput1(res?.data?.data?.role);
        setShowLoader(false);
        toaster("success", "Fetched member details successfully!!");
      })
      .catch((err) => {
        setShowLoader(false);
        toaster("error", err);
      });
  };
  console.log(roleOutput1, "res?.data?.data?.role");

  useEffect(() => {
    getmemberdetails(id);
  }, []);

  console.log("Tony-->Hello madam", teammember?.role);

  const [changedRole, setChangedRole] = useState(teammember?.role);
  useEffect(() => { setChangedRole(teammember?.role)}, [teammember?.role] )
  const changeRole = (roleParam) => {
    memberDetailsChangeRole(id, roleParam).then((res) => {
      console.log("change Role->", res?.data?.data);
      setChangedRole(res?.data?.data?.userRole?.code);
    });
  };
  console.log("changedRole--->", changedRole)

  const [role, setRole] = useState([
    {
      name: "BIZ_ADMIN",
      description: "Admin",
    },
    {
      name: "BIZ_USER",
      description: "Members",
    },
  ]);
  const [teams, setTeams] = useState([
    // {
    //   name: "Marketing All Levels",
    //   description: "Marketing All Levels",
    // },
    // {
    //   name: "Sales Hiring",
    //   description: "Sales Hiring",
    // },
    // {
    //   name: "Engineering 60-80",
    //   description: "Engineering 60-80",
    // },
    // {
    //   name: "Engineering 90+",
    //   description: "Engineering 90+",
    // },
    // {
    //   name: "Design All Level",
    //   description: "Design All Level",
    // },
    // {
    //   name: "Interns Only",
    //   description: "Interns Only",
    // },
    // {
    //   name: "Engineering 80-90",
    //   description: "Engineering 80-90",
    // },
    // {
    //   name: "Management Roles Hiring",
    //   description: "Management Roles Hiring",
    // },
    // {
    //   name: "Leadership Scouting",
    //   description: "Leadership Scouting",
    // },
  ]);
  const [teamsOutput, setTeamsOutput] = useState([]);
  return (
    <div className="container">
      {showLoader && <Loader />}
      <div className="content-margin my-4">
        <Link
          to={-1}
          className="backTitle d-flex align-items-center border-bottom pb-3 mt-3"
          style={{ textDecoration: "none", color: "black" }}
        >
          <img src={Vectorleft} alt="" className="pointer" height="12px" />
          <p className="fs-12 fw-400 m-0 ps-2"> Go Back</p>
        </Link>
        <div className="member-detail-main d-flex justify-content-lg-between flex-column flex-sm-row cards-graph-container  ">
          <div className="d-flex  flex-column w-100">
            <div className="d-flex flex-wrap align-items-start justify-content-between ">
              <div className="d-flex my-1 mt-4 ">
                {teammember?.profilePic === null ||
                teammember?.profilePic === undefined ? (
                  <img
                    src={profileImg}
                    alt="profile"
                    className="rounded-circle"
                    width="60px"
                    height="60px"
                  />
                ) : (
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_BASEURL +
                      teammember?.profilePic
                    }
                    alt="profile"
                    className="rounded-circle"
                    width="60px"
                    height="60px"
                  />
                )}

                <div className="mx-2">
                  <div className="fs-24 fw-700" style={{ color: "#262626" }}>
                    {teammember?.name == null
                      ? "Member name not specified"
                      : teammember?.name}
                  </div>
                  <div className=" fs-16 fw-400 color-tertiary pe-2 text-break">
                    {teammember?.memberEmail == null
                      ? "Not Mentioned"
                      : teammember?.memberEmail}
                  </div>
                </div>
              </div>
              <div
                onClick={() => setShowDeleteMemberDialog(true)}
                style={{ minWidth: "150px" }}
                className="fs-12 mt-4 justify-content-center fw-700 h-32 col-12 col-sm-3 border-danger radius8 border 
                 d-flex pointer p-2 align-items-center "
              >
                <img
                  src={icDelete}
                  alt=""
                  className="ms-1 float-end"
                  height="12px"
                  width="12px"
                />
                <div className="job-post-search fs-12 d-flex fw-700 color-red ps-2 p-0 m-0 w-fit">
                  Delete Member
                </div>
              </div>
            </div>

            <div className="profile-box-shadow p-4 mt-4">
              <div className="fs-16 fw-600">Role</div>
              <div className="d-flex flex-wrap gap-2 mt-2">
                {role?.map((output, index) => {
                  console.log(" output.name ", output.name, teammember.role);
                  console.log(
                    " roleOutput=== output.name ",
                    output.name === teammember.role,
                    roleOutput === teammember.role,
                    roleOutput === output.name
                  );
                  return (
                    <div
                      className="fs-12 h-28 d-flex gap-1 border px-2 py-1 rounded-pill pointer"
                      key={index}
                      onClick={() => {
                        if (output?.description === "Members") {
                          changeRole(output?.name);
                          getmemberdetails(id);
                        } else if (output?.description === "Admin") {
                          changeRole(output?.name);
                          getmemberdetails(id);
                        }
                      }}
                      // onClick={() => {
                      //   setRoleOutput(`${output.description}`);
                      // }}
                    >

                      {changedRole === output.name ? (
                        //  teammember.role===""
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                        />
                      ) : (
                        ""
                      )}

                      <div>{output.description} </div>
                    </div>
                  );
                })}
                {/* <div className="fs-12 h-28 d-flex gap-1 border px-2 py-1 rounded-pill pointer">
                  <img
                    height="16px"
                    width="16px"
                    src={checkedLogo}
                    alt=""
                  ></img>
                  <div>{teammember.role} </div>
                </div> */}
              </div>
            </div>
            <div className=" profile-box-shadow mt-4 p-4">
              <div className="fs-16 fw-600 ">Teams</div>
              <div className="d-flex flex-wrap gap-2 mt-2 mb-2">
                {teammember?.teams?.map((output, index) => {
                  return (
                    <div
                      className="fs-12 h-28 d-flex gap-1 border px-2 py-1 rounded-pill pointer"
                      key={index}
                      onClick={() => {
                        console.log("response", !isEmpty(teamsOutput));
                        if (!isEmpty(teamsOutput)) {
                          const newJobDepartment = [...teamsOutput];
                          if (newJobDepartment.includes(output.name)) {
                            let itemIndex = teamsOutput?.findIndex(
                              (tm) => tm === output.name
                            );
                            newJobDepartment.splice(itemIndex, 1);
                          } else {
                            newJobDepartment.push(output.name);
                          }
                          setTeamsOutput(newJobDepartment);
                        } else {
                          setTeamsOutput([output.name]);
                        }
                      }}
                    >
                      {teamsOutput.includes(output.name) ? (
                        <img
                          height="16px"
                          width="16px"
                          src={checkedLogo}
                          alt=""
                        ></img>
                      ) : (
                        ""
                      )}

                      <div>{output.name} </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* Dialogs Section*/}

        <DeleteMemberDialog
          show={showDeleteMemberDialog}
          deleteMember={deleteMember}
          close={() => setShowDeleteMemberDialog(false)}
        />
      </div>
    </div>
  );
};

export default MemberDetailPage;
