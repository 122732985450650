import {
  ADD_SKILLS,
  DOWNLOAD_FILE,
  ADD_WORK_EXPERIENCE,
  GET_MASTER,
  GET_WORK_EXPERIENCE,
  UPDATE_RESUME,
  UPLOAD_FILE,
  VIWECANDIDATE,
  GET_APPLIED_JOBS,
  GET_RECOMMENDED_JOBS,
  GET_ALL_JOBS,
  DELETE_EDUCATION_EXPERIENCE,
  DELETE_WORK_EXPERIENCE,
  FETCH_NOTIFICATIONS_ENDPOINT,
  FETCH_READ_NOTIFICATIONS_ENDPOINT,
  FETCH_CLEAR_NOTIFICATIONS_ENDPOINT,
  REQUEST_PAYMENT_ENDPOINT,
  FETCH_PAYMENT_DETAILS_ENDPOINT,
  JOBSUMMARY,
  CREATENEWJOB,
  MASTERNEWJOB,
  PREVIOUS_JOBS,
  TEAM_SUMMARY_ENDPOINT,
  INVITE_TEAM_ENDPOINT,
  ADD_TEAM_ENDPOINT,
  GET_ALL_USERS_ENDPOINT,
  JOBTEMPLATE,
  ADDTEAM,
  GET_TEAMS,
  GETMEMBER,
  GETMEMBERDETAIL,
  // GETTEAM,
  // GETMEMBER,
  GETLOGGEDINUSERMEMBERS,
  GET_TEAM_MEMBERS,
  DELETEMEMBER,
  VIEWJOB,
  GET_APPLICATION_JOB_LISTING,
  GET_APPLICATION_DETAILS,
  GET_USER_APPLICATION_ALL_NOTES,
  SAVE_APPLICATION_NOTES,
  GET_REFEREE_DETAILS,
  UPDATE_APPLICATION_STATUS,
  COMPLETE_TEAM_ENDPOINT,
  DELETE_TEAM,
  REMOVE_MEMBERS_TEAM,

  // TEAMS_DETAIL_PAGE_ROUTE,
  VIEW_TEAM_DETAILS,
  ADD_FOR_TEAM_MEMBERS,
  UPDATE_FLAG,
  GET_REFEREE_BAR_CHART,
  AllSAVEDRFT,
  CREATETEMPLATE,
  GET_ALL_TEAMS_SUMMARY,
  GET_AVG_JOINING_DAYS,
  DELETE_JOBTEMPLATE,
  DELETE_POTENTIAL_ENDPOINT,
  GET_LOCATION_MASTER_ENDPOINT,
  GET_CURRENCY_MASTER_ENDPOINT,
  GET_APPLICATION_STATUS_HISTORY,
  SEND_CANDIDATE_NOTIFICATION,
  SEND_REFEREES_NOTIFICATION,
  GET_ACTION_HISTORY,
  CHANGE_JOB_STATUS,
  ORGS_TOP_REFEREES,
  VIEW_TEAM_MEMBERS,
  MORE_INSIGHTS,
  REFERRAL_BONUS,
  PIN_UNPIN_TEAM,
  DOWNLOAD_APPLICATIONS,
  PREVIOUS_JOB_TEMPLATE,
  GET_PIN_TEAMS,
  PIN_OR_UNPIN_TEAMS,
  GET_PIN_JOBS,
  PIN_OR_UNPIN_JOBS,
  PIN_ICON,
  ORGANIZATION_SUMMARY,
  ORAGANIZATION_SUMMARY,
  MEMBER_WISE_TEAM_SUMMARY,
  MEMBER_WISE_REFERRAL_BONUS,
  GET_APPLICATION_INSIGHTS,
  DOWNLOAD_JOBS,
  DOWNLOAD_APPLICATIONS_COUNT,
  GET_ACTION_HISTORY_BY_ID,
  MEMBER_DETAILS_CHANGE_ROLE,
  CHANGE_PAYMENT_STATUS,
  GET_AUTOSAVE_JOB_POST,
  AUTO_SAVE_JOB_POST,
  UPDATE_ACCEPTED_STATUS, GET_APPLICATION_STATUS_WISE_COUNT,
} from "../config/be_api_constants";
import api from "./api";
import { GET_CURRENCY_ENDPOINT } from "../config/be_api_constants";
import { useState } from "react";
import toaster from "../utils/toaster";
import { SUCCESS } from "dropzone";

// const [getJobId, setgetJobId] = useState("");

const UserName = (id) => {
  return api
    .get(VIWECANDIDATE + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};
const deleteEducationalExperience = (id) => {
  return api
    .get(DELETE_EDUCATION_EXPERIENCE + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};

const deleteWorkExperience = (id) => {
  return api
    .get(DELETE_WORK_EXPERIENCE + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};
const Education = (id) => {
  return api
    .get(VIWECANDIDATE + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};

const getWorkExperience = (id) => {
  return api
    .get(GET_WORK_EXPERIENCE + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};

const getAccountDetails = (id) => {
  return api.get(VIWECANDIDATE + id).then((response) => {
    return response;
  });
};

const getCandidateDetails = (id) => {
  return api.get(VIWECANDIDATE + id).then((response) => {
    return response;
  });
};

const getMaster = (type) => {
  return api
    .post(GET_MASTER, {
      mType: type,
      pageNo: 0,
      pageSize: 0,
    })
    .then((response) => {
      if (
        response?.status === 200 &&
        response?.data?.data &&
        response?.data?.data?.length > 0
      ) {
        return response?.data?.data;
      } else {
        return [];
      }
    });
};

const uploadFile = (data) => {
  return api
    .post(UPLOAD_FILE, data, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((response) => {
      if (response?.status === 200 && response?.data) {
        return response?.data;
      } else {
        return null;
      }
    });
};

const updateResume = (data) => {
  return api.post(UPDATE_RESUME, data).then((response) => {
    if (response?.status === 200 && response?.data) {
      return response?.data;
    } else {
      return null;
    }
  });
};

const addCandidateSkills = (data) => {
  return api.post(ADD_SKILLS, data).then((response) => {
    if (response?.status === 200 && response?.data?.data) {
      return response?.data?.data;
    } else {
      return null;
    }
  });
};

const deleteImage = (id) => {
  return api.get(DELETE_POTENTIAL_ENDPOINT + id).then((response) => {
    return response;
  });
};
const downloadFile = async (url) => {
  return api
    .get(url, {
      responseType: "blob",
      // headers: {
      //   Accept: "application/pdf",
      //   "Content-Type": "application/pdf",
      // },
    })
    .then((response) => {
      if (response?.status === 200 && response?.data) {
        // let fileName = response["headers"]["content-disposition"];
        // fileName = fileName && fileName.replace("attachment; filename=", "");
        // fileName = fileName && JSON.parse(fileName);
        // const fileExtension =
        //   fileName && fileName.length > 0 && fileName.split(".")[1];
        // const obj = {
        //   fileName,
        //   fileExtension,
        //   blobData: response?.data,
        // };
        // console.log(obj);
        // console.log(response?.data);
        return URL.createObjectURL(response?.data);
        // return obj;
      } else {
        return null;
      }
    });
};
const download = async (url) => {
  // console.log("data", data);
  return api
    .get(DOWNLOAD_FILE, url, {
      responseType: "blob",
    })
    .then((response) => {
      if (response?.status === 200 && response?.data) {
        return URL.createObjectURL(response?.data);
      } else {
        return null;
      }
    });
};

const addCandidateWorkExperience = (data) => {
  return api.post(ADD_WORK_EXPERIENCE, data).then((response) => {
    if (response?.status === 200 && response?.data?.data) {
      return response;
    } else {
      return null;
    }
  });
};

const addteam = (teamName, userIdList) => {
  return api
    .post(ADDTEAM, {
      teamName,
      userIdList,
    })
    .then((response) => {
      return response;
    });
};

const getTeams = (data) => {
  return api.post(GET_TEAMS, data).then((response) => {
    return response;
  });
};

const getTeamMembers = (id, pageNo, pageSize) => {
  return api
    .post(GET_TEAM_MEMBERS + id, {
      pageNo: pageNo,
      pageSize: pageSize,
    })
    .then((response) => {
      return response;
    });
};

const getmember = (data) => {
  return api
    .post(GETMEMBER, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
const addForTeamMembers = (id, pageNo, pageSize) => {
  return api
    .post(ADD_FOR_TEAM_MEMBERS + id, {
      pageNo: pageNo,
      pageSize: pageSize,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

const getLoggedInUserMembers = (data) => {
  return api
    .post(GETLOGGEDINUSERMEMBERS, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

const getLocations = () => {
  return api.get(GET_LOCATION_MASTER_ENDPOINT).then((response) => {
    return response;
  });
};

const viewDetails = (id) => {
  return api
    .get(VIEW_TEAM_DETAILS + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};

const getmemberdetail = (id) => {
  return api
    .get(GETMEMBERDETAIL + id, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};

const deletemember = (id) => {
  return api
    .get(DELETEMEMBER + id, {
      // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    })
    .then((response) => {
      return response;
    });
};
const addmembertoteam = (teamId, userIdList) => {
  return api
    .post(ADDTEAM, {
      teamId: teamId,
      userIdList: userIdList,
    })
    .then((response) => {
      return response;
    });
};
const deleteMemberTeam = (teamId, userId) => {
  return api
    .post(REMOVE_MEMBERS_TEAM, {
      teamId: teamId,
      userId: userId,
    })
    .then((response) => {
      return response;
    });
};

const deleteTeam = (id) => {
  return api.get(DELETE_TEAM + id, {}).then((response) => {
    return response;
  });
};

const getAppliedJobs = (data) => {
  return api.post(GET_APPLIED_JOBS, data).then((response) => {
    if (response?.status === 200 && response?.data?.data) {
      return response?.data?.data;
    } else {
      return [];
    }
  });
};

const getRecommendedJobs = (data) => {
  return api.post(GET_RECOMMENDED_JOBS, data).then((response) => {
    if (response?.status === 200 && response?.data?.data) {
      return {
        data: response?.data?.data,
        totalCount: response?.data?.totalRecord,
        message: response?.data?.message,
        status: response?.status,
      };
    } else {
      return {
        data: [],
        message: response?.data?.message,
        status: response?.status,
      };
    }
  });
};

const getAllJobs = (data) => {
  return api.post(GET_ALL_JOBS, data).then((response) => {
    if (
      response?.status === 200 &&
      response?.data?.data &&
      response?.data?.data?.length > 0
    ) {
      return {
        data: response?.data?.data,
        totalCount: response?.data?.totalRecord,
        status: response?.status,
      };
    } else {
      return {
        data: [],
        message: response?.data?.message,
        status: response?.data?.status,
      };
    }
  });
};

const getCurrency = () => {
  return api.get(GET_CURRENCY_ENDPOINT).then((response) => {
    if (response?.status === 200 && response?.data) {
      return response?.data;
    } else {
      return [];
    }
  });
};

const getNotifications = (pageNo, pageSize) => {
  return api
    .post(FETCH_NOTIFICATIONS_ENDPOINT, {
      pageNo: pageNo,
      pageSize: pageSize,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log("err---->", err);
      return err;
    });
};
const getReadNotifications = (notificationId) => {
  return api
    .post(FETCH_READ_NOTIFICATIONS_ENDPOINT, {
      notificationId: notificationId,
    })
    .then((response) => {
      return response;
    });
};

const getClearNotifications = (notificationId) => {
  return api
    .post(FETCH_CLEAR_NOTIFICATIONS_ENDPOINT, {
      notificationId: notificationId,
    })
    .then((response) => {
      return response;
    });
};

const getRequestPayment = (data) => {
  return api.post(REQUEST_PAYMENT_ENDPOINT, data).then((response) => {
    return response;
  });
};

const getPaymentAccountDetails = () => {
  return api.get(FETCH_PAYMENT_DETAILS_ENDPOINT).then((response) => {
    return response;
  });
};
const inviteMembersTeamsList = (data) => {
  return api.post(COMPLETE_TEAM_ENDPOINT, data).then((response) => {
    return response;
  });
};

const inviteMembersAddTeams = (data) => {
  return api.post(INVITE_TEAM_ENDPOINT, data).then((response) => {
    return response;
  });
};

const addTeams = (teamId, userIdList) => {
  return api
    .post(ADD_TEAM_ENDPOINT, { teamId, userIdList })
    .then((response) => {
      return response;
    });
};

const getUsers = () => {
  return api.get(GET_ALL_USERS_ENDPOINT).then((response) => {
    return response;
  });
};
const getjobSummary = (data) => {
  let token = localStorage.getItem("token");
  return api.post(JOBSUMMARY, data).then((response) => {
    return response;
  });
};

const postNewJob = (data) => {
  let token = localStorage.getItem("token");
  return api
    .post(CREATENEWJOB, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "true",
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response;
    });
};

const getAutoSave = () => {
  let token = localStorage.getItem("token");
  return api.post(GET_AUTOSAVE_JOB_POST, {}).then((response) => {
    return response;
  });
};

const postAutoSave = (data) => {
  return api.post(AUTO_SAVE_JOB_POST, data).then((response) => {
    return response;
  });
};

export const jobMaster = (mType, pageNo, pageSize) => {
  return api
    .post(MASTERNEWJOB, {
      mType: mType,
      pageNo: pageNo,
      pageSize: pageSize,
    })
    .then((response) => {
      return response;
    });
};

const getJobTemplate = (data) => {
  return api.post(JOBTEMPLATE, data).then((response) => {
    return response;
  });
};

const createJobTemplate = (data) => {
  return api
    .post(CREATETEMPLATE, data, {
      // headers: {
      //   "Access-Control-Allow-Origin": "true",
      //   "Content-Type": "application/json",
      // },
    })
    .then((response) => {
      return response;
    });
};

const teamsSummary = (pageNo, pageSize, searchString, SortBy, teamId) => {
  return api
    .get(TEAM_SUMMARY_ENDPOINT, {
      pageNo,
      pageSize,
      searchString,
      SortBy,
      teamId,
    })
    .then((response) => {
      return response;
    });
};

const viewPreviousJob = (id) => {
  return api.get(PREVIOUS_JOBS + id).then((response) => {
    return response;
  });
};

const viewPreviousJobTemp = (id) => {
  return api.get(PREVIOUS_JOB_TEMPLATE + id).then((response) => {
    return response;
  });
};

const getJobDetails = (jobId) => {
  return api.get(VIEWJOB + `/${jobId}`, {}).then((response) => {
    return response;
  });
};

const getApplicationInsights = (pageNo, pageSize, jobId) => {
  return api
    .post(GET_APPLICATION_INSIGHTS, {
      pageNo,
      pageSize,
      jobId,
    })
    .then((response) => {
      return response;
    });
};

const getApplicationJobListing = (
  pageNo1,
  pageSize,
  jobId,
  applicationStatus,
  withReferrals,
  minExperience,
  maxExperience,
  qualification,
  location,
  jobSearchStatus,
  searchString,
  sortBy
) => {
  return api
    .post(GET_APPLICATION_JOB_LISTING, {
      pageNo1,
      pageSize,
      jobId,
      applicationStatus,
      withReferrals,
      minExperience,
      maxExperience,
      qualification,
      location,
      jobSearchStatus,
      searchString,
      sortBy,
    })
    .then((response) => {
      return response;
    });
};

const getApplicationStatusWiseCount = (
    pageNo1,
    pageSize,
    jobId,
    applicationStatus,
    withReferrals,
    minExperience,
    maxExperience,
    qualification,
    location,
    jobSearchStatus,
    searchString,
    sortBy
) => {
  return api
      .post(GET_APPLICATION_STATUS_WISE_COUNT, {
        pageNo1,
        pageSize,
        jobId,
        applicationStatus,
        withReferrals,
        minExperience,
        maxExperience,
        qualification,
        location,
        jobSearchStatus,
        searchString,
        sortBy,
      })
      .then((response) => {
        return response;
      });
};

const getApplicationDetails = (applicationId) => {
  // let token = localStorage.getItem("token");
  return api
    .get(GET_APPLICATION_DETAILS + `/${applicationId}`, {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      //   "Access-Control-Allow-Origin": "true",
      //   "Content-Type": "application/json",
      // },
    })
    .then((response) => {
      return response;
    });
};

const getApplicationStatusHistory = (applicationId) => {
  // let token = localStorage.getItem("token");
  return api
    .get(GET_APPLICATION_STATUS_HISTORY + `/${applicationId}`, {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      //   "Access-Control-Allow-Origin": "true",
      //   "Content-Type": "application/json",
      // },
    })
    .then((response) => {
      return response;
    });
};

const getUserApplicationAllNotes = (pageNo, pageSize, applicationId) => {
  return api
    .post(GET_USER_APPLICATION_ALL_NOTES, {
      pageNo,
      pageSize,
      applicationId,
    })
    .then((response) => {
      return response;
    });
};

const saveUserApplicationNotes = (applicationId, userId, note) => {
  return api
    .post(SAVE_APPLICATION_NOTES, {
      applicationId,
      userId,
      note,
    })
    .then((response) => {
      return response;
    });
};

const getRefereeProfile = (userId) => {
  return api.get(GET_REFEREE_DETAILS + `/${userId}`).then((response) => {
    return response;
  });
};

const getRefereeBarChart = (refererId) => {
  return api
    .post(GET_REFEREE_BAR_CHART, { refererId: refererId })
    .then((response) => {
      return response;
    });
};

const getRefereeProfileBarChart = (refererId) => {
  return api
    .post(GET_REFEREE_BAR_CHART, { refererId: refererId })
    .then((response) => {
      return response;
    });
};

const updateApplicationStatus = (applicationId, applicationStatus) => {
  // let token = localStorage.getItem("token");
  return api
    .post(
      UPDATE_APPLICATION_STATUS,
      { applicationId, applicationStatus }
      // {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     "Access-Control-Allow-Origin": "true",
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => {
      return response;
    });
};

const updateAcceptedStatus = (applicationId, isOfferedAccept) => {
  // let token = localStorage.getItem("token");
  return api
    .post(UPDATE_ACCEPTED_STATUS, { applicationId, isOfferedAccept })
    .then((response) => {
      return response;
    });
};

const updateFlag = (applicationId, isFlagged) => {
  // let token = localStorage.getItem("token");
  return api
    .post(
      UPDATE_FLAG,
      { applicationId, isFlagged }
      // {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     "Access-Control-Allow-Origin": "true",
      //     "Content-Type": "application/json",
      //   },
      // }
    )
    .then((response) => {
      return response;
    });
};

const getAllJobDraft = (data) => {
  return api.post(AllSAVEDRFT, data).then((response) => {
    return response;
  });
};

const getAllTeamsSummary = (data) => {
  return api
    .post(GET_ALL_TEAMS_SUMMARY, data)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

const getAverageJoiningDays = (teamId) => {
  return api
    .post(GET_AVG_JOINING_DAYS, {
      teamId: teamId,
    })
    .then((response) => {
      return response;
    });
};

const sendCandidateNotification = (
  locations,

  keySkills,

  industries,

  functions,
  jobTitles,
  jobSearchStatus,
  minExp,

  maxExp,

  jobId
) => {
  return api
    .post(SEND_CANDIDATE_NOTIFICATION, {
      locations,
      keySkills,
      industries,
      functions,
      jobTitles,
      jobSearchStatus,
      minExp,
      maxExp,
      jobId,
    })
    .then((response) => {
      return response;
    });
};

const sendRefereesNotification = (
  locations,

  keySkills,

  industries,

  functions,

  minExp,

  maxExp,

  jobId
) => {
  return api
    .post(SEND_REFEREES_NOTIFICATION, {
      locations,
      keySkills,
      industries,
      functions,
      minExp,
      maxExp,
      jobId,
    })
    .then((response) => {
      return response;
    });
};

const getActionHistory = (data) => {
  return api.post(GET_ACTION_HISTORY, data).then((response) => {
    return response;
  });
};

const changeJobStatus = (jobId, status) => {
  let token = localStorage.getItem("token");
  return api
    .post(
      CHANGE_JOB_STATUS,
      { jobId, status },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Access-Control-Allow-Origin": "true",
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response;
    });
};

const topRefereesOrgs = (companyId, pageNo, pageSize) => {
  return api
    .post(ORGS_TOP_REFEREES, {
      companyId: companyId,
      pageNo: pageNo,
      pageSize: pageSize,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err;
    });
};

const viewteamMembers = (data) => {
  return api.post(VIEW_TEAM_MEMBERS, data).then((response) => {
    return response;
  });
};

const moreInsights = (companyId, fromDate, toDate) => {
  return api
    .post(MORE_INSIGHTS, {
      companyId: companyId,
      fromDate: fromDate,
      toDate: toDate,
    })
    .then((response) => {
      return response;
    });
};

const getreferralbonus = (teamId) => {
  return api
    .post(REFERRAL_BONUS, {
      teamId: teamId,
    })
    .then((response) => {
      return response;
    });
};

const pinteams = (teamId, isPin) => {
  return api.post(PIN_UNPIN_TEAM, {
    teamId,
    isPin,
  });
  // .then((response) => {
  //   return response;
  // });
};

const downloadApplicationCount = (jobId, applicationStatus) => {
  return api
    .post(DOWNLOAD_APPLICATIONS_COUNT, {
      jobId: jobId,
      applicationStatus: applicationStatus,
    })
    .then((response) => {
      return response;
    });
};

const deleteJobTemplate = (id) => {
  return api.get(DELETE_JOBTEMPLATE + id).then((response) => {
    return response;
  });
};

const getPinTeams = (isPin, pageNo, pageSize) => {
  return api.post(GET_PIN_TEAMS, {
    isPin,
    pageNo,
    pageSize,
  });
};

const pinOrUnpin = (teamId, isPin) => {
  return api.post(PIN_OR_UNPIN_TEAMS, {
    teamId,
    isPin,
  });
};

const getPinJobs = (isPin, pageNo, pageSize) => {
  return api.post(GET_PIN_JOBS, {
    isPin,
    pageNo,
    pageSize,
  });
};

const pinOrUnpinjobs = (jobId, isPin) => {
  return api.post(PIN_OR_UNPIN_JOBS, {
    jobId,
    isPin,
  });
};

const getOrganizationSummary = (pageNo, pageSize, fromDate, toDate) => {
  return api.post(ORAGANIZATION_SUMMARY, {
    pageNo,
    pageSize,
    fromDate,
    toDate,
  });
};
const pinIconJob = (jobId, isPin) => {
  return api.post(PIN_ICON, {
    jobId,
    isPin,
  });
};

const organizationSummary = (pageNo, pageSize, userId) => {
  return api
    .post(ORGANIZATION_SUMMARY, { pageNo, pageSize, userId })
    .then((response) => {
      return response;
    });
};

const getMemberWiseTeamsSummary = (pageNo, pageSize, memberId, teamId) => {
  return api
    .post(MEMBER_WISE_TEAM_SUMMARY, { pageNo, pageSize, memberId, teamId })
    .then((response) => {
      return response;
    });
};

const getMemberWiseReferralBonus = (memberId) => {
  return api.post(MEMBER_WISE_REFERRAL_BONUS, { memberId }).then((response) => {
    return response;
  });
};

const downloadJobs = (downloadType, applicationIds, jobId) => {
  return api
    .post(
      DOWNLOAD_JOBS,
      {
        downloadType,
        applicationIds,
        jobId,
      },
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      if (response?.status === 200 && response?.data) {
        return URL.createObjectURL(response?.data);
      } else {
        return null;
      }
    });
};

const getActionHistoryById = (pageNo, pageSize, id) => {
  return api
    .post(GET_ACTION_HISTORY_BY_ID, { pageNo, pageSize, id })
    .then((response) => {
      return response;
    });
};

const memberDetailsChangeRole = (userId, role) => {
  return api
    .post(MEMBER_DETAILS_CHANGE_ROLE, { userId, role })
    .then((response) => {
      return response;
    });
};

const changePaymentStatus = (applicationId, paymentStatus, jobId , refereeId) => {
  return api
    .post(CHANGE_PAYMENT_STATUS, { applicationId, paymentStatus ,jobId,refereeId})
    .then((response) => {
      return response;
    });
};

export {
  downloadJobs,
  downloadApplicationCount,
  getApplicationInsights,
  getOrganizationSummary,
  getLocations,
  deleteJobTemplate,
  deleteImage,
  getAllJobDraft,
  createJobTemplate,
  getAutoSave,
  postAutoSave,
  viewPreviousJob,
  viewPreviousJobTemp,
  getJobTemplate,
  UserName,
  Education,
  inviteMembersAddTeams,
  getAccountDetails,
  getMaster,
  getCandidateDetails,
  uploadFile,
  updateResume,
  addCandidateSkills,
  downloadFile,
  download,
  getWorkExperience,
  addCandidateWorkExperience,
  getAppliedJobs,
  getRecommendedJobs,
  deleteEducationalExperience,
  deleteWorkExperience,
  getAllJobs,
  getCurrency,
  getNotifications,
  getReadNotifications,
  getRequestPayment,
  getClearNotifications,
  getPaymentAccountDetails,
  getjobSummary,
  postNewJob,
  addTeams,
  getUsers,
  addteam,
  getTeams,
  getmember,
  getLoggedInUserMembers,
  getmemberdetail,
  getTeamMembers,
  deletemember,
  deleteTeam,
  getJobDetails,
  getApplicationJobListing,
  getApplicationStatusWiseCount,
  getApplicationDetails,
  getApplicationStatusHistory,
  getUserApplicationAllNotes,
  saveUserApplicationNotes,
  getRefereeProfile,
  updateApplicationStatus,
  updateAcceptedStatus,
  inviteMembersTeamsList,
  viewDetails,
  addForTeamMembers,
  addmembertoteam,
  deleteMemberTeam,
  updateFlag,
  getRefereeBarChart,
  getAllTeamsSummary,
  getAverageJoiningDays,
  sendCandidateNotification,
  sendRefereesNotification,
  getActionHistory,
  changeJobStatus,
  topRefereesOrgs,
  viewteamMembers,
  moreInsights,
  getreferralbonus,
  pinteams,
  getPinTeams,
  pinOrUnpin,
  getPinJobs,
  pinOrUnpinjobs,
  pinIconJob,
  organizationSummary,
  getMemberWiseTeamsSummary,
  getMemberWiseReferralBonus,
  getActionHistoryById,
  memberDetailsChangeRole,
  changePaymentStatus,
  getRefereeProfileBarChart,
};
