/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import icWhiteDone from "../../../assests/icons/ic_done_white.svg";

const ApplicationQuestions = ({ applicationDetails }) => {
  return (
    <div className="mt-5 mb-5">
      <div className="fs-24 fw-700 color-primary"> Additional Questions</div>

      {applicationDetails?.jobQuestionAndAnswers?.map((data, index) => {
        // data && data?.map((ques, i) => {
        //   console.log("Questions--->", ques)
        //   console.log("Questions--->", ques?.question)
        //   return (
        //     <div>
        //       <div className="fw-500 fs-16 pt-3">{ques?.question}</div>
        //       <div className="small-text-medium-gray pb-2 pt-2">
        //         Mandatory to answer
        //       </div>

        //       <hr></hr>

        //       <div className="py-3">
        //         <button className="btn rounded-pill border pe-2 ps-2 p-0 d-flex">
        //           <img
        //             src={icWhiteDone}
        //             className="bg-black p-1 rounded-circle me-1 mt-1"
        //           />
        //           <span> {data?.answer} </span>
        //         </button>
        //       </div>
        //     </div>
        //   );
        // })
        // console.log(data[0]?.question, "<--QNA");
        return (
          <div>
            <div className="fw-500 fs-16 pt-3 ">{data[0].question}</div>
            <div className="small-text-medium-gray pb-2 pt-2 ">
              Mandatory to answer
            </div>
            {/* <p className="color-tertiary fs-12">
                The employee hiring process is lengthy and detailed. If you do
                it right, you’ll find and hire high-quality candidates who stick
                around and represent your business the way you want. As a small
                business owner, you might not know where to begin with hiring if
                you don’t have experience in HR, as many small business owners
                don’t. Keep reading for expert advice on hiring employees.
              </p> */}

            <hr></hr>

            <div className="py-3">
              <button className="btn rounded-pill border pe-2 ps-2 p-0 d-flex">
                <img
                  src={icWhiteDone}
                  className="bg-black p-1 rounded-circle me-1 mt-1"
                />
                <span> {data[1].answer} </span>
              </button>
            </div>
          </div>
        );
      })}
      {/* <div>
        <div className="fw-500 fs-16 pt-3">
          Give an example where you have advocated for User’s Experience over
          business or revenue recommendations
        </div>
        <div className="small-text-medium-gray pb-2 pt-2">
          Mandatory to answer
        </div>
        <p className="color-tertiary fs-12">
          The employee hiring process is lengthy and detailed. If you do it
          right, you’ll find and hire high-quality candidates who stick around
          and represent your business the way you want. As a small business
          owner, you might not know where to begin with hiring if you don’t have
          experience in HR, as many small business owners don’t. Keep reading
          for expert advice on hiring employees.
        </p>
      </div>
      <hr></hr>
      <div>
        <div className="fw-500 fs-16 pt-3">
          Are you willing to travel within India or Abroad for your work,
          confrences, expos or similar events?
        </div>
        <div className="small-text-medium-gray pb-2 pt-2">
          Mandatory to answer
        </div>
        <div className="py-3">
          <button className="btn rounded-pill border pe-2 ps-2 p-0 d-flex">
            <img
              src={icWhiteDone}
              className="bg-black p-1 rounded-circle me-1 mt-1"
            />
            <span> YES </span>
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default ApplicationQuestions;
