import { useState, useEffect } from "react";
import "./style.scss";
import threeDotsIcon from "../../../assests/icons/three-dots-icon.svg";
import mostView from "../../../assests/icons/ic_mostviews.svg";
import mostApplication from "../../../assests/icons/ic_mostapplications.svg";
import mostReferrals from "../../../assests/icons/ic_mostreferrals.svg";
import mostLeast from "../../../assests/icons/ic_least.svg";
import joiningTime from "../../../assests/icons/ic_joiningtime.svg";
import mostRejects from "../../../assests/icons/ic_mostrejects.svg";
import mostDropouts from "../../../assests/icons/ic_mostdropouts.svg";
import profile1 from "../../../assests/icons/profile.svg";
import dropdown from "../../../assests/downArrow.svg";
import rightArrow from "../../../assests/right-arrow.svg";
import ProgressBarGraph from "../../../components/graph/progress_bar_graph";
import useWindowDimensions from "../../../utils/use_window_dimension";
import SwitchButton from "../../../components/SwitchButton";
import { Dropdown } from "react-bootstrap";
import filterIcon from "../../../assests/icons/ic-filter.svg";
import blackdone from "../../../assests/icons/ic_blackdone.svg";
import downArrow from "../../../assests/downArrow.svg";
import FunnelGraphComponent from "../../../components/graph/funnel_graph";
import CompanyCardComponent from "./companyCard";
import { useStoreActions, useStoreState } from "easy-peasy";
import { NO, USER_ROLE } from "../../../config/be_api_constants";
import CompanyImage from "../../../components/company_image";
import {
  moreInsights,
  getAverageJoiningDays,
  getPinTeams,
  pinOrUnpin,
  getPinJobs,
  pinOrUnpinjobs,
  organizationSummary,
  getOrganizationSummary,
} from "../../../_services/view.service";
import { Link, useNavigate } from "react-router-dom";
import {
  ADMIN_JOB_POST_PAGE_ROUTE,
  ALL_TEAMS_SCREEN_ROUTE,
  APPLICATIONS_MAIN_PAGE_ROUTE,
  JOB_POSTS_PAGE_ROUTE,
  PREVIEW_PAGE_ROUTE,
  REFEREE_DETAILS_PAGE_ROUTE,
  TEAM_PAGE_ROUTE,
} from "./../../../config/page_routes_constants";
import { downloadFile, topRefereesOrgs } from "../../../_services/view.service";
import ProfileImage from "../../../components/profile_image";
import { getLocalStorage } from "../../../utils/storage";
import {
  ORG_ID,
  STATUS_SUCCESS,
  TOKEN,
  USER_ID,
} from "../../../config/localstorage_keys";
import Loader from "../../../components/common/loader";
import { isEmpty } from "../../../utils/form_validators";
import toaster from "../../../utils/toaster";
import {
  orgAdminSummary,
  overviewMoreInsights,
  overviewOrgTopReferees,
  overviewPinnedJobs,
  overviewUnpinJobs,
} from "../../../_services/serikoradmin_view.service";
import NoDataFoundCard from "../../../components/common/no_data_found_card";
import { NO_JOBS_PINNED, NO_TEAMS_PINNED } from "../../../config/messages";
import EmptyPinState from "../../../components/empty_pin_state";

const DashboardPage = () => {
  const [topnewreferees, setTopNewReferees] = useState([]);
  const [adminOrgTopReferees, setAdminOrgTopReferees] = useState([]);

  const [referralFilter, setReferralFilter] = useState(3);
  const [pinTeams, setPinTeams] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [apiRefresh, setApiRefresh] = useState(false);

  const role = localStorage.getItem("role");

  const [switchValue, setSwitchValue] = useState("Org Analytics");

  const userid = getLocalStorage(USER_ID);

  const navigate = useNavigate();

  const saveCandidateDetails = useStoreActions(
    (actions) => actions.candidate.saveCandidateDetails
  );
  const adminUserResponse = useStoreState(
    (state) => state?.candidate?.candidateDetails?.adminUserResponse
  );

  const getTopreferees = () => {
    if (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) {
      setShowLoader(true);
      topRefereesOrgs(null, 1, referralFilter)
        .then((res) => {
          setTopNewReferees(res?.data?.data);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  const seikorAdminOrgTopReferees = () => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      setShowLoader(true);
      overviewOrgTopReferees(1, referralFilter)
        .then((res) => {
          setAdminOrgTopReferees(res?.data?.data);

          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  const overviewPinTeams = (isPin, pageNo, pageSize) => {
    if (role === USER_ROLE.BUSINESS_ADMIN) {
      setShowLoader(true);
      getPinTeams(true, 1, 10)
        ?.then((res) => {
          if (!isEmpty(res)) {
            setPinTeams(res?.data?.data);
          } else {
            setPinTeams([]);
            setShowLoader(false);
          }
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    // setShowLoader(false);
  };

  const overviewUnpinTeams = (teamId, isPin) => {
    if (role === USER_ROLE.BUSINESS_ADMIN) {
      setShowLoader(true);
      pinOrUnpin(teamId, isPin)
        ?.then((res) => {
          if (res?.data?.status === STATUS_SUCCESS) {
            setShowLoader(false);
            toaster("success", "Unpin team successfully!!");
          }
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };
  //  const [pinnedTeamsFilter,setPinnedTeamsFilter]= useState([]);
  //  setPinnedTeamsFilter(pinTeams?.filter(e => e?.isPinned));
  let pinnedTeamsFilter =
    pinTeams && pinTeams.length > 0 && pinTeams?.filter((e) => e?.isPinned);

  const [jobPin, setJobPin] = useState([]);
  const [jobPinSeikorAdmin, setJobPinSeikorAdmin] = useState([]);
  const [orgSummary, setOrgSummary] = useState({});
  const [seikorAdminOrgSummary, setSeikorAdminOrgSummary] = useState({});

  useEffect(() => {
    console.log("seikorAdminOrgSummary", seikorAdminOrgSummary);
  }, [seikorAdminOrgSummary]);

  useEffect(() => {
    console.log("orgSummary", orgSummary);
  }, [orgSummary]);

  const overViewJobs = (isPin, pageNo, pageSize) => {
    if (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) {
      setShowLoader(true);
      getPinJobs(true, 1, 10)
        ?.then((res) => {
          setJobPin(res?.data?.data);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  const overviewAdminPinnedJobs = () => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      overviewPinnedJobs(1, 10, true)?.then((res) => {
        setJobPinSeikorAdmin(res?.data?.data);
      });
    }
  };

  const overviewUnpinjobs = (jobId, isPin) => {
    setShowLoader(true);
    if (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) {
      pinOrUnpinjobs(jobId, isPin)
        ?.then((res) => {
          if (res?.data?.status === STATUS_SUCCESS) {
            setShowLoader(false);
            toaster("success", "Unpin job successfully!!");
          }
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    // setShowLoader(false);
  };
  let pinnedJobsFilter =
    jobPin?.length > 0 && jobPin?.filter((e) => e?.isPinned);

  const overviewOrganizationSummary = () => {
    if (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) {
      if (switchValue === "Org Analytics") {
        setShowLoader(true);
        organizationSummary(1, 10, null)
          ?.then((res) => {
            if (res?.data?.status === STATUS_SUCCESS) {
              setShowLoader(false);
              setOrgSummary(res?.data?.data);
            }
          })
          .catch((error) => {
            setShowLoader(false);
          });
      }
      if (switchValue === "My Analytics") {
        organizationSummary(1, 10, userid)
          ?.then((res) => {
            if (res?.data?.status === STATUS_SUCCESS) {
              setShowLoader(false);
              setOrgSummary(res?.data?.data);
            }
          })
          .catch((error) => {
            setShowLoader(false);
          });
      }
    }
  };

  const overviewSeikorAdminOrganizationSummary = (fromDate, toDate) => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      orgAdminSummary(1, 10, fromDate, toDate)?.then((res) => {
        // if (res?.data?.status === STATUS_SUCCESS) {
        setShowLoader(false);
        setSeikorAdminOrgSummary(res?.data?.data);
        console.log(
          "overviewSeikorAdminOrganizationSummary",
          seikorAdminOrgSummary
        );
        // }
      });
    }
  };

  const seikorAdminUnpinJobs = (jobId, isPin) => {
    setShowLoader(true);
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      overviewUnpinJobs(jobId, isPin)
        ?.then((res) => {
          if (res?.data?.status === STATUS_SUCCESS) {
            setShowLoader(false);
            overviewAdminPinnedJobs();
            toaster("success", "Unpin job successfully!!");
          }
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
    setShowLoader(false);
  };

  useEffect(() => {
    getTopreferees();
    seikorAdminOrgTopReferees();
    overviewPinTeams();
    overViewJobs();
    overviewAdminPinnedJobs();
    overviewOrganizationSummary();
    overviewSeikorAdminOrganizationSummary();
  }, [referralFilter, switchValue]);

  const hrSrlDiamension = useWindowDimensions();
  const getHorizotalScrollWidth = (winWidth) => {
    if (winWidth >= 1500 || winWidth >= 1300) {
      return 895 + "px";
    } else if (winWidth < 1300 && winWidth > 991) {
      return winWidth - 410 + "px";
    } else if (winWidth <= 991 || winWidth < 550) {
      return winWidth - 70 + "px";
    }
  };
  useEffect(() => {
    getHorizotalScrollWidth(hrSrlDiamension.width);
  }, [hrSrlDiamension]);

  // const downloadPicture = async (downloadedUrl) => {
  //   downloadFile(downloadedUrl).then((res) => {
  //     if (res) {
  //       setProfileSrc(res);
  //     } else {
  //       setProfileSrc(null);
  //     }
  //   });
  // };

  const [userRole, setUserRole] = useState("Admin");
  const [pinnedTeams, setPinnedTeams] = useState([
    {
      title: "Marketing All level roles",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      title: "Tec Level 90+",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      title: "Design All Levels Long Na..",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      title: "Tec Level 90+",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
    {
      title: "Tec Level 90+",
      openRoles: 131,
      applied: 30,
      referrals: 20,
      referralsPercentage: 13,
    },
  ]);

  const [pinnedJobs, setPinnedJobs] = useState([
    {
      companyName: "Google",
      title: "UX Design Manager, Selling Partner Experience",
      views: 272,
      newApplication: 32,
      newReferrals: 32,
    },
    {
      companyName: "Google",
      title: "Engineering Lead",
      views: 272,
      newApplication: 32,
      newReferrals: "00",
    },

    {
      companyName: "Google",
      title: "UX Design Manager, Selling Partner Experience long name..",
      views: 272,
      newApplication: 32,
      newReferrals: "00",
    },
    {
      companyName: "Google",
      title: "UX Design Manager, Selling Partner Experience",
      views: 272,
      newApplication: 32,
      newReferrals: 32,
    },
    {
      companyName: "Google",
      title: "Engineering Lead",
      views: 272,
      newApplication: 32,
      newReferrals: "00",
    },

    {
      companyName: "Google",
      title: "UX Design Manager, Selling Partner Experience long name..",
      views: 272,
      newApplication: 32,
      newReferrals: "00",
    },
  ]);

  const [topReferee, setTopReferee] = useState([
    {
      name: "Anna Martin",
      referral: 76,
      conversion: "67%",
      profile: profile1,
      joined: 56,
    },
    {
      name: "Jose Soej",
      referral: 76,
      conversion: "67%",
      profile: profile1,
      joined: 56,
    },
    {
      name: "Anna Martin",
      referral: 76,
      conversion: "67%",
      profile: profile1,
      joined: 56,
    },
  ]);

  // const getLengedPercentage = (total, value) => {
  //   if (total === 0 || value === 0) {
  //     return 0 + "%";
  //   } else if (total && value) {
  //     return Math.round((parseInt(value) * 100) / parseInt(total)) + "%";
  //   }
  // };
  const getLengedPercentage = (total, value) => {
    if (total === 0 || value === 0) {
      return "0%";
    } else if (total && value) {
      return `${Math.round((value * 100) / total)}%`;
    } else {
      return "0%";
    }
  };
  const rolesPerformance = [
    {
      title: "Roles Posted",
      value:
        orgSummary?.hasOwnProperty("rolesPosted") &&
        orgSummary?.rolesPosted !== ""
          ? orgSummary?.rolesPosted
          : 0,
    },
    {
      title: "Roles Filled",
      value:
        orgSummary?.hasOwnProperty("roleFiled") && orgSummary?.roleFiled !== ""
          ? orgSummary?.roleFiled
          : 0,
    },
    {
      title: "Roles Closed",
      value:
        orgSummary?.hasOwnProperty("dropped") && orgSummary?.dropped !== ""
          ? orgSummary?.dropped
          : 0,
    },
    {
      title: "Total Referrals",
      value:
        orgSummary?.hasOwnProperty("referralCount") &&
        orgSummary?.referralCount !== ""
          ? orgSummary?.referralCount
          : 0,
    },
    {
      title: "Offered to Joined Ratio",
      // value: "640/480(80%)",
      value: `${
        orgSummary?.hasOwnProperty("jobsOffered") &&
        orgSummary?.jobsOffered !== ""
          ? orgSummary?.jobsOffered
          : 0
      }/${
        orgSummary?.hasOwnProperty("roleFiled") && orgSummary?.roleFiled !== ""
          ? orgSummary?.roleFiled
          : 0
      }(${getLengedPercentage(
        orgSummary?.roleFiled,
        orgSummary?.jobsOffered
        //  === 0 ? "0" : orgSummary?.jobsOffered
      )}) `,
    },
  ];
  // const jobsOffered = seikorAdminOrgSummary?.hasOwnProperty("jobsOffered") && seikorAdminOrgSummary?.jobsOffered !== "" ? seikorAdminOrgSummary?.jobsOffered : 0;
  // const roleFiled = seikorAdminOrgSummary?.hasOwnProperty("roleFiled") && seikorAdminOrgSummary?.roleFiled !== "" ? seikorAdminOrgSummary?.roleFiled : 0;
  // const offeredToJoinedRatio = `${jobsOffered}/${roleFiled} (${getLengedPercentage(roleFiled, jobsOffered)})`;

  const seikorAdminRolesPerformance = [
    {
      title: "Roles Posted",
      value:
        seikorAdminOrgSummary?.hasOwnProperty("rolesPosted") &&
        seikorAdminOrgSummary?.rolesPosted !== ""
          ? seikorAdminOrgSummary?.rolesPosted
          : 0,
    },
    {
      title: "Roles Filled",
      value:
        seikorAdminOrgSummary?.hasOwnProperty("roleFiled") &&
        seikorAdminOrgSummary?.roleFiled !== ""
          ? seikorAdminOrgSummary?.roleFiled
          : 0,
    },
    {
      title: "Roles Closed",
      value:
        seikorAdminOrgSummary?.hasOwnProperty("dropped") &&
        seikorAdminOrgSummary?.dropped !== ""
          ? seikorAdminOrgSummary?.dropped
          : 0,
    },
    {
      title: "Total Referrals",
      value:
        seikorAdminOrgSummary?.hasOwnProperty("referralCount") &&
        seikorAdminOrgSummary?.referralCount !== ""
          ? seikorAdminOrgSummary?.referralCount
          : 0,
    },
    {
      title: "Offered to Joined Ratio",
      // value: "640/480(80%)",
      value: `${
        seikorAdminOrgSummary?.hasOwnProperty("jobsOffered") &&
        seikorAdminOrgSummary?.jobsOffered !== ""
          ? seikorAdminOrgSummary?.jobsOffered
          : 0
      }/${
        seikorAdminOrgSummary?.hasOwnProperty("roleFiled") &&
        seikorAdminOrgSummary?.roleFiled !== ""
          ? seikorAdminOrgSummary?.roleFiled
          : 0
      }(${getLengedPercentage(
        seikorAdminOrgSummary?.roleFiled,
        seikorAdminOrgSummary?.jobsOffered
        // === 0 ? "0" : orgSummary?.jobsOffered
      )}) `,
    },
  ];

  console.log("seikorAdminRolesPerformance-->", seikorAdminRolesPerformance);

  const barData = [
    {
      entity: "Applied",
      entityValue:
        orgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        orgSummary?.statusWiseData?.APPLIED !== ""
          ? orgSummary?.statusWiseData?.APPLIED
          : 0,
      color: "#1C1C1C",
    },
    {
      entity: "Shortlisted",
      entityValue:
        orgSummary?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
        orgSummary?.statusWiseData?.SHORTLISTED !== ""
          ? orgSummary?.statusWiseData?.SHORTLISTED
          : 0,
      color: "#3D81FF",
    },
    {
      entity: "Assessment",
      entityValue:
        orgSummary?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
        orgSummary?.statusWiseData?.ASSESSMENT !== ""
          ? orgSummary?.statusWiseData?.ASSESSMENT
          : 0,
      color: "#FFD027",
    },
    {
      entity: "Offered",
      entityValue:
        orgSummary?.statusWiseData?.hasOwnProperty("OFFERED") &&
        orgSummary?.statusWiseData?.OFFERED !== ""
          ? orgSummary?.statusWiseData?.OFFERED
          : 0,
      color: "#12BD6B",
    },
    {
      entity: "Joined",
      entityValue:
        orgSummary?.statusWiseData?.hasOwnProperty("JOINED") &&
        orgSummary?.statusWiseData?.JOINED !== ""
          ? orgSummary?.statusWiseData?.JOINED
          : 0,
      color: "#2FFF9B",
    },
    {
      entity: "Dropout",
      entityValue:
        orgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        orgSummary?.statusWiseData?.RETRACTED !== ""
          ? orgSummary?.statusWiseData?.RETRACTED
          : 0,
      color: "#EB1260",
    },
    {
      entity: "Rejected",
      entityValue:
        orgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        orgSummary?.statusWiseData?.REJECTED !== ""
          ? orgSummary?.statusWiseData?.REJECTED
          : 0,
      color: "#721212",
    },
  ];

  const seikorAdminBarData = [
    {
      entity: "Applied",
      entityValue:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        seikorAdminOrgSummary?.statusWiseData?.APPLIED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.APPLIED
          : 0,
      color: "#1C1C1C",
    },
    {
      entity: "Shortlisted",
      entityValue:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
        seikorAdminOrgSummary?.statusWiseData?.SHORTLISTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.SHORTLISTED
          : 0,
      color: "#3D81FF",
    },
    {
      entity: "Assessment",
      entityValue:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
        seikorAdminOrgSummary?.statusWiseData?.ASSESSMENT !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.ASSESSMENT
          : 0,
      color: "#FFD027",
    },
    {
      entity: "Offered",
      entityValue:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("OFFERED") &&
        seikorAdminOrgSummary?.statusWiseData?.OFFERED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.OFFERED
          : 0,
      color: "#12BD6B",
    },
    {
      entity: "Joined",
      entityValue:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("JOINED") &&
        seikorAdminOrgSummary?.statusWiseData?.JOINED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.JOINED
          : 0,
      color: "#2FFF9B",
    },
    {
      entity: "Dropout",
      entityValue:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
          : 0,
      color: "#EB1260",
    },
    {
      entity: "Rejected",
      entityValue:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED**") &&
        seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
          : 0,
      color: "#721212",
    },
  ];

  console.log("seikorAdminBarData", seikorAdminBarData);

  const [toDate, setToDate] = useState();

  // CURRENT DATE
  const current = new Date();

  var hrs = current.getHours();
  var greet;

  if (hrs < 12) greet = "Good Morning";
  else if (hrs >= 12 && hrs <= 17) greet = "Good Afternoon";
  else if (hrs >= 17 && hrs <= 24) greet = "Good Evening";

  var dd = String(current.getDate()).padStart(2, "0");
  var mm = String(current.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = current.getFullYear();
  // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;
  const date = `${yyyy}-${mm}-${dd}`;

  // PAST 7 DAYS
  const past7days = new Date();
  past7days.setDate(past7days.getDate() - 7);
  var dd = String(past7days.getDate()).padStart(2, "0");
  var mm = String(past7days.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past7days.getFullYear();
  const date7 = `${yyyy}-${mm}-${dd}`;

  // PAST 31 DAYS
  const past31days = new Date();
  past31days.setDate(past31days.getDate() - 31);
  var dd = String(past31days.getDate()).padStart(2, "0");
  var mm = String(past31days.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past31days.getFullYear();
  const date31 = `${yyyy}-${mm}-${dd}`;

  // PAST 90 DAYS
  const past90days = new Date();
  past90days.setDate(past90days.getDate() - 90);
  var dd = String(past90days.getDate()).padStart(2, "0");
  var mm = String(past90days.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past90days.getFullYear();
  const date90 = `${yyyy}-${mm}-${dd}`;

  const [fromDate, setFromDate] = useState();

  // PAST 1 MONTHS
  const past1Months = new Date();
  past1Months.setMonth(past1Months.getMonth() - 1);
  var dd = String(past1Months.getDate()).padStart(2, "0");
  var mm = String(past1Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past1Months.getFullYear();
  const month1 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month1);

  // PAST 3 MONTHS
  const past3Months = new Date();
  past3Months.setMonth(past3Months.getMonth() - 3);
  var dd = String(past3Months.getDate()).padStart(2, "0");
  var mm = String(past3Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past3Months.getFullYear();
  const month3 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month3);

  // PAST 6 MONTHS
  const past6Months = new Date();
  past6Months.setMonth(past6Months.getMonth() - 6);
  var dd = String(past6Months.getDate()).padStart(2, "0");
  var mm = String(past6Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past6Months.getFullYear();
  const month6 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month6);

  // PAST 12 MONTHS
  const past12Months = new Date();
  past12Months.setMonth(past12Months.getMonth() - 12);
  var dd = String(past12Months.getDate()).padStart(2, "0");
  var mm = String(past12Months.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = past12Months.getFullYear();
  const month12 = `${yyyy}-${mm}-${dd}`;
  // console.log("give me month", month12);

  const [insights, setInsights] = useState([
    {
      icon: mostView,
      title: "Most Views",
      // value:insightMore?.mostView,
    },
    {
      icon: mostApplication,
      title: "Most Applications",
      // value:insightMore?.mostApplication,
      // requestType:"max_applications"
    },
    {
      icon: mostReferrals,
      title: "Most Referrals",
      // value:insightMore?.mostApplication,
      // jobTitle:insightMore[2]?.jobTitle
      // requestType:"max_referral"
    },
    {
      icon: mostRejects,
      title: "Most Offer Rejects",
      // jobTitle:insightMore[3]?.jobTitle
      // requestType:"rejected"
    },
    {
      icon: mostDropouts,
      title: "Most Dropouts",
      // jobTitle:insightMore[4]?.jobTitle
      // requestType:"min_applications"
    },
    {
      icon: mostLeast,
      title: "Least Views",
      // jobTitle:insightMore[5]?.jobTitle
      // requestType:"min_applications"
    },
    {
      icon: mostLeast,
      title: "Least Application",
      // jobTitle:insightMore[6]?.jobTitle
      // requestType:"min_applications"
    },
    {
      icon: mostLeast,
      title: "Least Referrals",
      // jobTitle:insightMore[7]?.jobTitle
      // requestType:"min_applications"
    },
    {
      avg: true,
      title: "Average Joining Time",
      days: 23,
    },
  ]);

  const companyId = getLocalStorage(ORG_ID);

  const [insightMore, setInsightMore] = useState([]);
  const [admininsightMore, setAdminInsightMore] = useState([]);
  const [newInsight, setNewInsight] = useState([]);
  const [avgDaysOrg, setAvgDaysOrg] = useState([]);
  const [orgRoleperformance, setOrgRolesPerformance] = useState();
  const [statusWiseData, setStatusWiseData] = useState({});

  // const getMoreInsigts = () => {
  //   if (role === USER_ROLE.BUSINESS_ADMIN) {
  //     if (fromDate !== undefined || fromDate !== null) {
  //       moreInsights(companyId, fromDate, date).then((res) => {
  //         setInsightMore(res?.data?.data);
  //       });
  //     }
  //     if (fromDate === undefined || fromDate === null) {
  //       moreInsights().then((res) => {
  //         setInsightMore(res?.data?.data);
  //       });
  //     }
  //   }
  // };

  const getMoreInsigts = (from, to) => {
    if (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) {
      // if (fromDate !== undefined || fromDate !== null) {
      setShowLoader(true);
      moreInsights(companyId, from, to)
        .then((res) => {
          setInsightMore(res?.data?.data);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
      // }
      // if (fromDate === undefined || fromDate === null) {
      //   moreInsights().then((res) => {
      //     setInsightMore(res?.data?.data);
      //   });
      // }
    }
  };

  // const seikorAdminMoreInsigts = () => {
  //   if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
  //     if (fromDate !== undefined || fromDate !== null) {
  //       overviewMoreInsights(fromDate, date).then((res) => {
  //         setAdminInsightMore(res?.data?.data);
  //       });
  //     }
  //     if (fromDate === undefined || fromDate === null) {
  //       overviewMoreInsights().then((res) => {
  //         setAdminInsightMore(res?.data?.data);
  //       });
  //     }
  //   }
  // };

  const seikorAdminMoreInsigts = (from, to) => {
    if (role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) {
      // if (fromDate !== undefined || fromDate !== null) {
      setShowLoader(true);
      overviewMoreInsights(from, to)
        .then((res) => {
          setAdminInsightMore(res?.data?.data);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
      // }
      // if (fromDate === undefined || fromDate === null) {
      //   overviewMoreInsights().then((res) => {
      //     setAdminInsightMore(res?.data?.data);
      //   });
      // }
    }
  };

  const getAvgDaysOrgs = (teamId) => {
    // if (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) {
    setShowLoader(true);
    getAverageJoiningDays(teamId)
      .then((res) => {
        setAvgDaysOrg(res?.data?.data);
        setShowLoader(false);
      })
      .catch((error) => {
        setShowLoader(false);
      });
    // }
  };
  const orgRoleperform = (fromDate, toDate) => {
    if (role === USER_ROLE.BUSINESS_ADMIN || role === USER_ROLE.BUSINESS_USER) {
      setShowLoader(true);
      getOrganizationSummary(1, 10, fromDate, toDate)
        .then((res) => {
          setOrgRolesPerformance(res?.data?.data);
          setStatusWiseData(res?.data?.data?.statusWiseData);
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };

  useEffect(() => {
    orgRoleperform();
    getMoreInsigts();
    seikorAdminMoreInsigts();
    getAvgDaysOrgs();
    // repeatDiv();
  }, [fromDate]);

  useEffect(() => {
    let copyData = [...rolesPerformance];
    copyData[0].value = orgRoleperformance?.rolesPosted;
    copyData[1].value = orgRoleperformance?.roleFiled;
    copyData[2].value = orgRoleperformance?.dropped;
    copyData[3].value = orgRoleperformance?.referralCount;
    copyData[4].value =
      orgRoleperformance?.jobsOffered / orgRoleperformance?.jobsOffered;
  }, [getMoreInsigts]);

  useEffect(() => {
    let copyData = [...barData];
    copyData[0].entityValue = statusWiseData?.APPLIED;
    copyData[1].entityValue = orgRoleperformance?.applicationShortlisted;
    copyData[2].entityValue = orgRoleperformance?.appliedCount;
    copyData[3].entityValue = orgRoleperformance?.jobsOffered;
    copyData[4].entityValue = statusWiseData?.JOINED;
    copyData[5].entityValue = orgRoleperformance?.dropped;
    copyData[6].entityValue = statusWiseData?.RETRACTED;
  }, [getMoreInsigts]);

  // const [switchValue, setSwitchValue] = useState("Org Analytics");

  const timelineArray = [
    "All Time",
    "Last 1 Month",
    "Last 3 Months",
    "Last 6 Months",
    "Last 12 Months",
  ];
  const [arraySortIndex, setArraySortIndex] = useState(null);

  const topArray = [
    "Top 3",
    "Top 5",
    "Top 10",
    // "Custom",
  ];
  const [topArraySortIndex, setTopArraySortIndex] = useState(null);

  const daysFilterArray = [
    "Filter",
    "Last 7 days",
    "Last 31 days",
    "Last 90 days",
  ];
  const [daysArraySortIndex, setDaysArraySortIndex] = useState(null);

  // const funnelData = [
  //   {
  //     status: "Applied",
  //     count: 584,
  //     color: "black",
  //     rejected: 200,
  //     dropped: 12,
  //   },
  //   {
  //     status: "Shortlisted",
  //     count: 312,
  //     color: "blue",
  //     rejected: 60,
  //     dropped: 7,
  //   },
  //   {
  //     status: "Assessement",
  //     count: 150,
  //     color: "yellow",
  //     rejected: 143,
  //     dropped: 0,
  //   },
  //   {
  //     status: "Offered",
  //     count: 95,
  //     color: "green",
  //     rejected: 0,
  //     dropped: 7,
  //   },
  //   {
  //     status: "Joined",
  //     count: 10,
  //     color: "light-green",
  //     rejected: 0,
  //     dropped: 0,
  //   },
  // ];
  // console.log(RETRACTEDAFTERAPPLIED,"RETRACTEDAFTERAPPLIED")
  const funnelData = [
    {
      status: "Applied",
      mainCount:
        orgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        orgSummary?.statusWiseData?.APPLIED !== ""
          ? orgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        orgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        orgSummary?.statusWiseData?.APPLIED !== ""
          ? orgSummary?.statusWiseData?.APPLIED
          : 0,
      color: "black",
      rejected:
        orgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        orgSummary?.statusWiseData?.REJECTEDAFTERAPPLIED !== ""
          ? orgSummary?.statusWiseData?.REJECTEDAFTERAPPLIED
          : 0,
      dropped:
        orgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        orgSummary?.statusWiseData?.RETRACTEDAFTERAPPLIED !== ""
          ? orgSummary?.statusWiseData?.RETRACTEDAFTERAPPLIED
          : 0,
    },
    {
      status: "Shortlisted",
      mainCount:
        orgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        orgSummary?.statusWiseData?.APPLIED !== ""
          ? orgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        orgSummary?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
        orgSummary?.statusWiseData?.SHORTLISTED !== ""
          ? orgSummary?.statusWiseData?.SHORTLISTED
          : 0,
      color: "blue",
      rejected:
        orgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        orgSummary?.statusWiseData?.REJECTEDAFTERSHORTLISTED !== ""
          ? orgSummary?.statusWiseData?.REJECTEDAFTERSHORTLISTED
          : 0,
      dropped:
        orgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        orgSummary?.statusWiseData?.RETRACTEDAFTERSHORTLISTED !== ""
          ? orgSummary?.statusWiseData?.RETRACTEDAFTERSHORTLISTED
          : 0,
    },
    {
      status: "Assessement",
      mainCount:
        orgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        orgSummary?.statusWiseData?.APPLIED !== ""
          ? orgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        orgSummary?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
        orgSummary?.statusWiseData?.ASSESSMENT !== ""
          ? orgSummary?.statusWiseData?.ASSESSMENT
          : 0,
      color: "yellow",
      rejected:
        orgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        orgSummary?.statusWiseData?.REJECTEDAFTERASSESSMENT !== ""
          ? orgSummary?.statusWiseData?.REJECTEDAFTERASSESSMENT
          : 0,
      dropped:
        orgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        orgSummary?.statusWiseData?.RETRACTEDAFTERASSESSMENT !== ""
          ? orgSummary?.statusWiseData?.RETRACTEDAFTERASSESSMENT
          : 0,
    },
    {
      status: "Offered",
      mainCount:
        orgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        orgSummary?.statusWiseData?.APPLIED !== ""
          ? orgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        orgSummary?.statusWiseData?.hasOwnProperty("OFFERED") &&
        orgSummary?.statusWiseData?.OFFERED !== ""
          ? orgSummary?.statusWiseData?.OFFERED
          : 0,
      color: "green",
      rejected:
        orgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        orgSummary?.statusWiseData?.REJECTEDAFTEROFFERED !== ""
          ? orgSummary?.statusWiseData?.REJECTEDAFTEROFFERED
          : 0,
      dropped:
        orgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        orgSummary?.statusWiseData?.RETRACTEDAFTEROFFERED !== ""
          ? orgSummary?.statusWiseData?.RETRACTEDAFTEROFFERED
          : 0,
    },
    {
      status: "Joined",
      mainCount:
        orgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        orgSummary?.statusWiseData?.APPLIED !== ""
          ? orgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        orgSummary?.statusWiseData?.hasOwnProperty("JOINED") &&
        orgSummary?.statusWiseData?.JOINED !== ""
          ? orgSummary?.statusWiseData?.JOINED
          : 0,
      color: "light-green",
      rejected:
        orgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        orgSummary?.statusWiseData?.REJECTEDAFTERJOINED !== ""
          ? orgSummary?.statusWiseData?.REJECTEDAFTERJOINED
          : 0,
      dropped:
        orgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        orgSummary?.statusWiseData?.RETRACTEDAFTERJOINED !== ""
          ? orgSummary?.statusWiseData?.RETRACTEDAFTERJOINED
          : 0,
    },
  ];

  console.log("funnelData", funnelData);

  const seikorAdminFunnelData = [
    {
      status: "Applied",
      mainCount:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        seikorAdminOrgSummary?.statusWiseData?.APPLIED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        seikorAdminOrgSummary?.statusWiseData?.APPLIED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.APPLIED
          : 0,
      color: "black",
      rejected:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
          : 0,
      dropped:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
          : 0,
    },
    {
      status: "Shortlisted",
      mainCount:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        seikorAdminOrgSummary?.statusWiseData?.APPLIED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("SHORTLISTED") &&
        seikorAdminOrgSummary?.statusWiseData?.SHORTLISTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.SHORTLISTED
          : 0,
      color: "blue",
      rejected:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
          : 0,
      dropped:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
          : 0,
    },
    {
      status: "Assessement",
      mainCount:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        seikorAdminOrgSummary?.statusWiseData?.APPLIED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("ASSESSMENT") &&
        seikorAdminOrgSummary?.statusWiseData?.ASSESSMENT !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.ASSESSMENT
          : 0,
      color: "yellow",
      rejected:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
          : 0,
      dropped:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
          : 0,
    },
    {
      status: "Offered",
      mainCount:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        seikorAdminOrgSummary?.statusWiseData?.APPLIED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("OFFERED") &&
        seikorAdminOrgSummary?.statusWiseData?.OFFERED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.OFFERED
          : 0,
      color: "green",
      rejected:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
          : 0,
      dropped:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
          : 0,
    },
    {
      status: "Joined",
      mainCount:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("APPLIED") &&
        seikorAdminOrgSummary?.statusWiseData?.APPLIED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.APPLIED
          : 0,
      count:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("JOINED") &&
        seikorAdminOrgSummary?.statusWiseData?.JOINED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.JOINED
          : 0,
      color: "light-green",
      rejected:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("REJECTED") &&
        seikorAdminOrgSummary?.statusWiseData?.REJECTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.REJECTED
          : 0,
      dropped:
        seikorAdminOrgSummary?.statusWiseData?.hasOwnProperty("RETRACTED") &&
        seikorAdminOrgSummary?.statusWiseData?.RETRACTED !== ""
          ? seikorAdminOrgSummary?.statusWiseData?.RETRACTED
          : 0,
    },
  ];

  console.log("seikorAdminFunnelData", seikorAdminFunnelData);

  const [
    analyticsTimeIntervalDropdownRedDot,
    setAnalyticsTimeIntervalDropdownRedDot,
  ] = useState("d-none");
  const [referralsDropdownRedDot, setReferralsDropdownRedDot] =
    useState("d-none");
  const [
    insightsTimeIntervalDropdownRedDot,
    setInsightsTimeIntervalDropdownRedDot,
  ] = useState("d-none");

  useEffect(() => {
    if (arraySortIndex == null) {
      setAnalyticsTimeIntervalDropdownRedDot("d-none");
    } else {
      setAnalyticsTimeIntervalDropdownRedDot("d-block");
    }
  }, [arraySortIndex]);

  useEffect(() => {
    if (topArraySortIndex == null) {
      setReferralsDropdownRedDot("d-none");
    } else {
      setReferralsDropdownRedDot("d-block");
    }
  }, [topArraySortIndex]);

  useEffect(() => {
    if (daysArraySortIndex == null) {
      setInsightsTimeIntervalDropdownRedDot("d-none");
    } else {
      setInsightsTimeIntervalDropdownRedDot("d-block");
    }
  }, [daysArraySortIndex]);

  const Greeting = new Date();
  let hours = Greeting.getHours();
  let status =
    hours < 12
      ? "Good Morning!"
      : hours <= 17 && hours >= 12
      ? "Good Afternoon!"
      : "Good Evening!";

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    var currentLocation = window.location.pathname;

    window.history?.push(`${currentLocation}/`);
  };
  console.log(window.location.pathname, "window.location.pathname");

  useEffect(() => {
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);
  const token = getLocalStorage(TOKEN);
  // const handleEvent = (e) => {
  //   e.preventDefault();
  //   var currentLocation = "/";

  //   window.history?.push(`${currentLocation}/`);
  // };

  // useEffect(() => {
  //   window.addEventListener("popstate", handleEvent);
  //   return () => window.removeEventListener("popstate", handleEvent);
  // });

  return (
    <div
    // className="mt-4 mobile-margin"
    // style={{ margin: "0rem 1rem 0rem 2rem" }}
    >
      {showLoader && <Loader />}
      <div className="container ">
        <div className="content-margin mt-4">
          {/* Greeetings row */}
          <div>
            <div className="salutation">{status}</div>
            <div className="overview-name fw-400 fs-24 mt-2 mb-4 header-ellipse-1">
              {/* Saina */}
              {adminUserResponse?.name}
            </div>
          </div>
          {/* Greeetings row end */}
          {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
            <CompanyCardComponent />
          )}
          {/* Pinned teams */}
          {role === USER_ROLE.BUSINESS_ADMIN && (
            // role === USER_ROLE.BUSINESS_USER
            <div className="mt-3 mb-4">
              {/* Title row */}
              <div className="d-flex justify-content-between">
                <div className="fw-700 fs-20 color-primary">Pinned Teams</div>
                {pinnedTeamsFilter?.length > 0 && (
                  <div
                    onClick={() => navigate(ALL_TEAMS_SCREEN_ROUTE)}
                    className="d-flex justify-content-between align-items-center border-8 dashboard-teams-btn fs-12 fw-700 border border-dark pointer ps-3 pe-2"
                  >
                    View All Teams
                    <img src={rightArrow} alt="" className="" />
                  </div>
                )}
              </div>
              {/* Title row end*/}
              {/* Cards row */}
              {/* {(role === USER_ROLE.BUSINESS_ADMIN 
            // role === USER_ROLE.BUSINESS_USER
                     )&&( */}
              {pinnedTeamsFilter?.length > 0 ? (
                <div
                  className="horizontal-scroll-area mt-2"
                  style={{
                    width: getHorizotalScrollWidth(hrSrlDiamension.width),
                  }}
                >
                  {role === USER_ROLE.BUSINESS_ADMIN && (
                    // role === USER_ROLE.BUSINESS_USER
                    <div
                      className="mt-2 d-flex"
                      // style={{
                      //   width:
                      //     pinnedTeamsFilter &&
                      //     pinnedTeamsFilter?.length > 0 &&
                      //     (pinnedTeamsFilter?.length + 1) * 177 + "px",
                      // }}
                    >
                      {pinnedTeamsFilter &&
                        pinnedTeamsFilter?.length > 0 &&
                        pinnedTeamsFilter?.map((team, index) => (
                          //Card
                          <div
                            className="flex-grow-0 flex-shrink-0 border rounded p-3 me-3 d-flex flex-column justify-content-between"
                            style={{ width: "190px", float: "left" }}
                          >
                            {/* Card title */}
                            <div className="d-flex justify-content-between fs-14 fw-600">
                              <div className="color-primary mb-1">
                                {team?.teamName}
                              </div>
                              <div>
                                {/* <img src={threeDotsIcon} className="pointer" alt=":" /> */}
                                <Dropdown className="job-template-dropdown">
                                  <Dropdown.Toggle
                                    variant="none"
                                    id="dropdown-basic"
                                    className="border-0  job-template-icon d-flex justify-items-center align-items-center ps-2 pe-1 h-20 referee-btn-focus"
                                  >
                                    <img
                                      src={threeDotsIcon}
                                      alt="retract-icon"
                                      className="ps-1"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="fs-12 text-secondary border-dark ">
                                    <Dropdown.Item className="d-flex pb-2 pt-2">
                                      <div className="d-flex gap-2">
                                        <span
                                          className=""
                                          onClick={() => {
                                            overviewUnpinTeams(
                                              team?.teamId,
                                              false
                                            );
                                            overviewPinTeams(
                                              team?.isPinned,
                                              1,
                                              10
                                            );
                                          }}
                                        >
                                          Unpin Team
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                    <hr className="m-0 p-0" />
                                    <Dropdown.Item
                                      href="#/action-2"
                                      className="d-flex pb-2 pt-2"
                                    >
                                      {/* <Link
                                      to={`/${TEAM_PAGE_ROUTE}/${team?.teamId}`}
                                      className="link text-dark text-decoration-none"
                                    >
                                      <span className="">View Team</span>
                                    </Link> */}

                                      <div
                                        onClick={() =>
                                          navigate(
                                            TEAM_PAGE_ROUTE +
                                              `/${team?.teamId}/${index + 1}`
                                          )
                                        }
                                        className="d-flex gap-2"
                                      >
                                        <span className="">View Team</span>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {/* Card title end*/}

                            <div>
                              {/* Card graph */}
                              <div className="mt-2 mb-2">
                                <ProgressBarGraph
                                  data={[
                                    {
                                      entity: "Applied",
                                      entityValue:
                                        team?.statusWiseData?.hasOwnProperty(
                                          "APPLIED"
                                        ) &&
                                        team?.statusWiseData?.APPLIED !== ""
                                          ? team?.statusWiseData?.APPLIED
                                          : 0,
                                      color: "#1C1C1C",
                                    },
                                    {
                                      entity: "Shortlisted",
                                      entityValue:
                                        team?.statusWiseData?.hasOwnProperty(
                                          "SHORTLISTED"
                                        ) &&
                                        team?.statusWiseData?.SHORTLISTED !== ""
                                          ? team?.statusWiseData?.SHORTLISTED
                                          : 0,
                                      color: "#3D81FF",
                                    },
                                    {
                                      entity: "Assessment",
                                      entityValue:
                                        team?.statusWiseData?.hasOwnProperty(
                                          "ASSESSMENT"
                                        ) &&
                                        team?.statusWiseData?.ASSESSMENT !== ""
                                          ? team?.statusWiseData?.ASSESSMENT
                                          : 0,
                                      color: "#FFD027",
                                    },
                                    {
                                      entity: "Offered",
                                      entityValue:
                                        team?.statusWiseData?.hasOwnProperty(
                                          "OFFERED"
                                        ) &&
                                        team?.statusWiseData?.OFFERED !== ""
                                          ? team?.statusWiseData?.OFFERED
                                          : 0,
                                      color: "#12BD6B",
                                    },
                                    {
                                      entity: "Joined",
                                      entityValue:
                                        team?.statusWiseData?.hasOwnProperty(
                                          "JOINED"
                                        ) && team?.statusWiseData?.JOINED !== ""
                                          ? team?.statusWiseData?.JOINED
                                          : 0,
                                      color: "#2FFF9B",
                                    },
                                    {
                                      entity: "Dropout",
                                      entityValue:
                                        team?.statusWiseData?.hasOwnProperty(
                                          "RETRACTED"
                                        ) &&
                                        team?.statusWiseData?.RETRACTED !== ""
                                          ? team?.statusWiseData?.RETRACTED
                                          : 0,
                                      color: "#EB1260",
                                    },
                                    {
                                      entity: "Rejected",
                                      entityValue:
                                        team?.statusWiseData?.hasOwnProperty(
                                          "REJECTED"
                                        ) &&
                                        team?.statusWiseData?.REJECTED !== ""
                                          ? team?.statusWiseData?.REJECTED
                                          : 0,
                                      color: "#721212",
                                    },
                                  ]}
                                  height={"7"}
                                />
                              </div>

                              {/* Card graph end */}

                              {/* Card stats */}
                              <div className="mt-3 fs-11 fw-300 color-tertiary me-1">
                                <div className="d-flex justify-content-between">
                                  <div>Open Roles</div>
                                  <div className="fs-12 fw-700 color-secondary">
                                    {team?.rolesOpen}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1">
                                  <div>Applied</div>
                                  <div className="fs-12 fw-700 color-secondary">
                                    {team?.appliedCount}
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between mt-1">
                                  <div>
                                    {/* Referrals ({team.referralsPercentage}) */}
                                    Referrals (
                                    {`${Math.round(
                                      (team?.referralsCount /
                                        team?.appliedCount) *
                                        100
                                    )}%`}
                                    )
                                  </div>
                                  <div className="fs-12 fw-700 color-secondary">
                                    {team.referralsCount}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* Card stats end */}
                          </div>
                          // Card end
                        ))}
                    </div>
                  )}
                </div>
              ) : (
                <EmptyPinState text={"teams"} />
              )}
              {/* )} */}
              {/* Cards row end*/}
            </div>
          )}
          {/* Pinned teams end */}

          {/* Pinned Jobs */}
          {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
            <div className="mt-5">
              {/* Title row */}
              <div className="d-flex justify-content-between ">
                <div className="fw-700 fs-20 color-primary ">Pinned Jobs</div>
                {jobPinSeikorAdmin?.length > 0 && (
                  <div
                    onClick={() => navigate(ADMIN_JOB_POST_PAGE_ROUTE)}
                    className="d-flex justify-content-between align-items-center border-8 dashboard-teams-btn fs-12 fw-700 border border-dark pointer ps-3 pe-2"
                  >
                    View All Jobs
                    <img src={rightArrow} alt="" className="" />
                  </div>
                )}
              </div>
              {/* Title row end*/}
              {/* Cards row */}
              {/* {hrSrlDiamension.width} */}
              {jobPinSeikorAdmin?.length > 0 ? (
                <div
                  className="horizontal-scroll-area mt-2"
                  style={{
                    width: getHorizotalScrollWidth(hrSrlDiamension.width),
                  }}
                >
                  <div
                    className=" mt-2 d-flex"
                    // style={{
                    //   width:
                    //     jobPin &&
                    //     jobPin?.length > 0 &&
                    //     (jobPin?.length + 1) * 255 + "px",
                    // }}
                  >
                    {jobPinSeikorAdmin?.length > 0 &&
                      jobPinSeikorAdmin?.map((job) => (
                        //Card
                        <div
                          className="flex-grow-0 flex-shrink-0 border rounded p-3 d-flex flex-column justify-content-between me-3"
                          style={{ width: "280px", float: "left" }}
                        >
                          {/* Card title */}
                          <div>
                            <div className="d-flex justify-content-between fs-14 fw-600">
                              <div className="color-primary">
                                {(role === USER_ROLE.ADMIN ||
                                  role === USER_ROLE.SUPER_ADMIN) && (
                                  <div className="d-flex">
                                    <div className="rounded border">
                                      {/* <CompanyImage
                                          // src={AirbnbLogo}
                                          name={job?.companyName}
                                          width="32px"
                                          height="32px"
                                          initialsContainerClass="initialsStyle2-xl"
                                        /> */}
                                      <img
                                        src={`data:image/jpeg;base64 , ${job?.organizationLogo}`}
                                        width="32px"
                                        height="32px"
                                        style={{ borderRadius: "50px" }}
                                      />
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="pt-2 ellipse "
                                  style={{ height: "3.2rem" }}
                                >
                                  {job?.jobTitle}
                                </div>
                              </div>
                              <div>
                                {/* <img src={threeDotsIcon} className="pointer" alt=":" /> */}
                                <Dropdown className="job-template-dropdown  pointer">
                                  <Dropdown.Toggle
                                    variant="none"
                                    id="dropdown-basic"
                                    className="border-0  job-template-icon d-flex justify-items-center align-items-center ps-2 pe-1 h-20 referee-btn-focus"
                                  >
                                    <img
                                      src={threeDotsIcon}
                                      alt="retract-icon"
                                      className="ps-1"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="fs-12 text-secondary mt-1 border-dark ">
                                    <Dropdown.Item
                                      href="#/action-1"
                                      className="d-flex pb-2 pt-2"
                                    >
                                      <div className="d-flex gap-2">
                                        <span
                                          className=""
                                          onClick={() => {
                                            seikorAdminUnpinJobs(
                                              job?.jobId,
                                              false
                                            );
                                          }}
                                        >
                                          Unpin Job
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                    <hr className="m-0 p-0" />
                                    <Dropdown.Item
                                      href="#/action-2"
                                      className="d-flex pb-2 pt-2"
                                    >
                                      <div
                                        onClick={() =>
                                          navigate(
                                            PREVIEW_PAGE_ROUTE +
                                              `/${job?.jobId}`
                                          )
                                        }
                                        className="d-flex gap-2"
                                      >
                                        <span className="">View Job</span>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {/* Card title end*/}

                            {/* Card stats */}
                            <div className="d-flex justify-content-between color-tertiary fw-400 fs-12 mb-3">
                              <div className="">{job.views} views</div>
                            </div>
                          </div>
                          <div className="mt-2 fs-11 fw-300 color-tertiary">
                            <div className="row">
                              <div className="col-3 mb-2">
                                <div className="numbersOfView fw-600 fs-11">
                                  {job.application}
                                </div>
                              </div>
                              <div className="col-9 fw-400 fs-14">
                                New Application
                                {job?.application > 1 ? "s" : ""}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 mb-2">
                                <div
                                  className={`${
                                    job.newReferrals != "00"
                                      ? "numbersOfView"
                                      : "zeroView"
                                  } fw-600 fs-11`}
                                >
                                  {job.referral}
                                </div>
                              </div>
                              <div className="col-9 fw-400 fs-14 ">
                                New Referral{job?.referral > 1 ? "s" : ""}
                              </div>
                            </div>
                          </div>
                          {/* Card stats end */}
                        </div>
                        // Card end
                      ))}
                  </div>
                </div>
              ) : (
                <EmptyPinState text={"jobs"} />
              )}
              {/* Cards row end*/}
            </div>
          )}

          {(role === USER_ROLE.BUSINESS_ADMIN ||
            role === USER_ROLE.BUSINESS_USER) && (
            <div className="mt-5">
              {/* Title row */}
              <div className="d-flex justify-content-between ">
                <div className="fw-700 fs-20 color-primary ">Pinned Jobs</div>
                {jobPin?.length > 0 && (
                  <div
                    onClick={() => navigate(JOB_POSTS_PAGE_ROUTE)}
                    className="d-flex justify-content-between align-items-center border-8 dashboard-teams-btn fs-12 fw-700 border border-dark pointer ps-3 pe-2"
                  >
                    View All Jobs
                    <img src={rightArrow} alt="" className="" />
                  </div>
                )}
              </div>

              {jobPin?.length > 0 ? (
                <div
                  className="horizontal-scroll-area mt-2"
                  style={{
                    width: getHorizotalScrollWidth(hrSrlDiamension.width),
                  }}
                >
                  <div
                    className=" mt-2 d-flex"
                    // style={{
                    //   width:
                    //     jobPin &&
                    //     jobPin?.length > 0 &&
                    //     (jobPin?.length + 1) * 255 + "px",
                    // }}
                  >
                    {jobPin?.length > 0 &&
                      jobPin?.map((job) => (
                        //Card
                        <div
                          className="flex-grow-0 flex-shrink-0 border rounded p-3 d-flex flex-column justify-content-between me-3"
                          style={{ width: "280px", float: "left" }}
                        >
                          {/* Card title */}
                          <div>
                            <div className="d-flex justify-content-between fs-14 fw-600">
                              <div className="color-primary">
                                {(role === USER_ROLE.ADMIN ||
                                  role === USER_ROLE.SUPER_ADMIN) && (
                                  <div className="d-flex">
                                    <div className="rounded border">
                                      <CompanyImage
                                        // src={AirbnbLogo}
                                        name={job?.companyName}
                                        width="32px"
                                        height="32px"
                                        initialsContainerClass="initialsStyle2-xl"
                                      />
                                    </div>
                                  </div>
                                )}
                                <div
                                  className="pt-2 ellipse "
                                  style={{ height: "3.2rem" }}
                                >
                                  {job?.jobTitle}
                                </div>
                              </div>
                              <div>
                                {/* <img src={threeDotsIcon} className="pointer" alt=":" /> */}
                                <Dropdown className="job-template-dropdown  pointer">
                                  <Dropdown.Toggle
                                    variant="none"
                                    id="dropdown-basic"
                                    className="border-0  job-template-icon d-flex justify-items-center align-items-center ps-2 pe-1 h-20"
                                  >
                                    <img
                                      src={threeDotsIcon}
                                      alt="retract-icon"
                                      className="ps-1"
                                    />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="fs-12 text-secondary mt-1 border-dark ">
                                    <Dropdown.Item
                                      href="#/action-1"
                                      className="d-flex pb-2 pt-2"
                                    >
                                      <div className="d-flex gap-2">
                                        <span
                                          className=""
                                          onClick={() => {
                                            overviewUnpinjobs(
                                              job?.jobId,
                                              false
                                            );
                                            overViewJobs(job?.isPinned, 1, 10);
                                          }}
                                        >
                                          Unpin Job
                                        </span>
                                      </div>
                                    </Dropdown.Item>
                                    <hr className="m-0 p-0" />
                                    <Dropdown.Item
                                      href="#/action-2"
                                      className="d-flex pb-2 pt-2"
                                    >
                                      <div
                                        onClick={() =>
                                          navigate(
                                            PREVIEW_PAGE_ROUTE +
                                              `/${job?.jobId}`
                                          )
                                        }
                                        className="d-flex gap-2"
                                      >
                                        <span className="">View Job</span>
                                      </div>
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                            {/* Card title end*/}

                            {/* Card stats */}
                            <div className="d-flex justify-content-between color-tertiary fw-400 fs-12 mb-3">
                              <div className="">{job.jobView} views</div>
                            </div>
                          </div>
                          <div className="mt-2 fs-11 fw-300 color-tertiary">
                            <div className="row">
                              <div className="col-3 mb-2">
                                <div className="numbersOfView fw-600 fs-11">
                                  {job.applicationCount}
                                </div>
                              </div>
                              <div className="col-9 fw-400 fs-14">
                                New Application
                                {job?.applicationCount > 1 ? "s" : ""}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-3 mb-2">
                                <div
                                  className={`${
                                    job.newReferrals != "00"
                                      ? "numbersOfView"
                                      : "zeroView"
                                  } fw-600 fs-11`}
                                >
                                  {job.referralCount}
                                </div>
                              </div>
                              <div className="col-9 fw-400 fs-14 ">
                                New Referral{job?.referralCount > 1 ? "s" : ""}
                              </div>
                            </div>
                          </div>
                          {/* Card stats end */}
                        </div>
                        // Card end
                      ))}
                  </div>
                </div>
              ) : (
                <EmptyPinState text={"jobs"} />
              )}
              {/* Cards row end*/}
            </div>
          )}
          {/* Pinned Jobs end */}
          <div className="mt-5">
            {/* Another component Start  */}
            <div className="d-flex flex-wrap justify-content-between align-items-center gap-3">
              {role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN ? (
                <div className="fw-700 fs-20 color-primary ">
                  Platform Analytics
                </div>
              ) : (
                <div className="org-switchbutton-wrapper">
                  <SwitchButton
                    data={["Org Analytics", "My Analytics"]}
                    value={switchValue}
                    setValue={setSwitchValue}
                    theme={"black"}
                  />
                </div>
              )}
              <div
                className="d-flex flex-wrap justify-content-between align-items-center fw-400 fs-12"
                style={{ height: "35px" }}
              >
                <Dropdown className="timeline-select-dropdown">
                  <Dropdown.Toggle
                    variant="none"
                    // id="dropdown-basic"
                    className="btn down-arrow timeline-select-button dropdown-button d-flex py-2 px-2 bg-transparent align-items-center d-flex justify-content-around referee-btn-focus"
                    style={{ width: "130px" }}
                  >
                    {/* <img src={filterIcon} alt="" className="" /> */}
                    <span className="fs-12">Time Interval</span>
                    <span
                      className={`${analyticsTimeIntervalDropdownRedDot} round-red-dot rounded-circle mb-2`}
                    ></span>
                    <img className="" src={downArrow} alt="" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="fs-12 text-secondary text-black">
                    {timelineArray?.map((name, index) => {
                      return (
                        <Dropdown.Item
                          onClick={() => {
                            if (arraySortIndex == index) {
                              setArraySortIndex(null);
                            } else {
                              setArraySortIndex(index);
                            }
                            if (name === "All Time") {
                              orgRoleperform();
                              overviewSeikorAdminOrganizationSummary();
                            }
                            if (name === "Last 1 Month") {
                              orgRoleperform(month1, date);
                              overviewSeikorAdminOrganizationSummary(
                                month1,
                                date
                              );
                            }
                            if (name === "Last 3 Months") {
                              orgRoleperform(month3, date);
                              overviewSeikorAdminOrganizationSummary(
                                month3,
                                date
                              );
                            }
                            if (name === "Last 6 Months") {
                              orgRoleperform(month6, date);
                              overviewSeikorAdminOrganizationSummary(
                                month6,
                                date
                              );
                            }
                            if (name === "Last 12 Months") {
                              orgRoleperform(month12, date);
                              overviewSeikorAdminOrganizationSummary(
                                month12,
                                date
                              );
                            }
                          }}
                          key={index}
                          className="d-flex pb-3"
                        >
                          <img
                            src={blackdone}
                            alt="blackdone-icon"
                            className={
                              arraySortIndex === index
                                ? "pe-2 d-block"
                                : "d-none pe-1"
                            }
                          />
                          <span
                            className={
                              arraySortIndex === index
                                ? "ps-0 color-primary fw-700"
                                : "ps-4"
                            }
                          >
                            {name}
                          </span>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* Another component  */}
          <div className=" my-4 outer-border p-4">
            <div className="fw-700 fs-20 color-primary ">
              {role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN
                ? ""
                : "Roles Performance"}
            </div>
            <div className="d-flex justify-content-between flex-wrap mt-3 org-roles-performance">
              {role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN
                ? seikorAdminRolesPerformance.map((obj) => {
                    return (
                      <>
                        <div className="ps-2 stats">
                          <div className="fw-400 fs-32 text-break">
                            {obj?.value}
                          </div>
                          <div
                            className="fw-400 fs-16 color-tertiary"
                            style={{ width: "85px" }}
                          >
                            {obj.title}
                          </div>
                        </div>
                      </>
                    );
                  })
                : rolesPerformance.map((obj) => {
                    return (
                      <>
                        <div className="ps-2 stats">
                          <div className="fw-400 fs-32 text-break">
                            {obj?.value}
                          </div>
                          <div
                            className="fw-400 fs-16 color-tertiary"
                            style={{ width: "85px" }}
                          >
                            {obj.title}
                          </div>
                        </div>
                      </>
                    );
                  })}
            </div>
            <ProgressBarGraph
              data={barData}
              seikorAdminBarData={seikorAdminBarData}
              height={"22"}
              showLegends={true}
            />
            {/* <div className="d-flex justify-content-between my-3 flex-wrap">
            {subRoles.map((subRole) => (
              <>
                <div>
                  <div className="fw-600 fs-14 color-secondary">
                    {subRole.entityValue}
                  </div>
                  <div className="fw-300 fs-11 color-tertiary">
                    {subRole.entity}
                  </div>
                </div>
              </>
            ))}
          </div> */}
            <div className="mt-5 d-md-block d-none">
              <div className=" fs-20 fw-700 ">Hiring Funnel</div>
              <div>
                {/* funnel part add here */}
                <FunnelGraphComponent
                  mainData={funnelData}
                  seikorAdminFunnelData={seikorAdminFunnelData}
                />
              </div>
            </div>
          </div>

          {/* Org Top Referees */}
          {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
            <div className="fluid-container mt-4">
              <div className="d-flex justify-content-between flex-wrap gap-2">
                <div className="fw-700 fs-20">Top Referees</div>
                <div>
                  {/* <span className="dropdown-button">Top 3</span> */}
                  <Dropdown className="timeline-select-dropdown">
                    <Dropdown.Toggle
                      variant="none"
                      // id="dropdown-basic"
                      className="btn down-arrow timeline-select-button dropdown-button d-flex py-2 px-2 bg-transparent align-items-center d-flex justify-content-around referee-btn-focus"
                      style={{ width: "130px" }}
                    >
                      {/* <img src={filterIcon} alt="" className="" /> */}
                      <span className="fs-12">Referrals</span>
                      <span
                        className={`${referralsDropdownRedDot} round-red-dot rounded-circle mb-2`}
                      ></span>
                      <img className="" src={downArrow} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black referee-btn-focus">
                      {topArray?.map((name, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              if (topArraySortIndex == index) {
                                setTopArraySortIndex(null);
                              } else {
                                setTopArraySortIndex(index);
                              }

                              if (name === "Top 3") {
                                setReferralFilter(3);
                              }
                              if (name === "Top 5") {
                                setReferralFilter(5);
                              }
                              if (name === "Top 10") {
                                setReferralFilter(10);
                              }
                            }}
                            key={index}
                            className="d-flex pb-3"
                          >
                            <img
                              src={blackdone}
                              alt="blackdone-icon"
                              className={
                                topArraySortIndex === index
                                  ? "pe-2 d-block"
                                  : "d-none pe-1"
                              }
                            />
                            <span
                              className={
                                topArraySortIndex === index
                                  ? "ps-0 color-primary fw-700"
                                  : "ps-4"
                              }
                            >
                              {name}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="mt-3">
                <div className="w-100">
                  <div className="row">
                    {/* {topnewreferees && topnewreferees?.length > 0
                  ? topnewreferees.map((data) => { */}
                    {adminOrgTopReferees &&
                      adminOrgTopReferees?.length > 0 &&
                      adminOrgTopReferees.map((data) => (
                        <div className="col-12 col-sm-6 col-xl-4">
                          <Link
                            className="text-decoration-none text-dark"
                            to={REFEREE_DETAILS_PAGE_ROUTE + `/${data.userId}`}
                          >
                            <div className="w-100 border-8 p-3 my-2">
                              <div className="d-flex">
                                <div>
                                  {/* <img src={data?.profilePic} alt="profile" /> */}
                                  <ProfileImage
                                    src={
                                      process.env.REACT_APP_IMAGE_BASEURL +
                                      data?.profilePic
                                    }
                                    height={"66px"}
                                    width={"72px"}
                                  />
                                </div>

                                <div className="ms-3">
                                  <div className="fw-600 fs-14 py-1 org-referee-nm-width header-ellipse-1">
                                    {data.name}
                                  </div>
                                  <div className="fw-400 fs-14 color-secondary mb-1">
                                    <span>{data.referral} Referrals</span>
                                    <span className="mx-2">|</span>
                                    <span>{data.joins} Joined</span>
                                  </div>
                                  <div className="fs-12 fw-400 color-tertiary">
                                    {data.conversion}% conversion
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {(role === USER_ROLE.BUSINESS_ADMIN ||
            role === USER_ROLE.BUSINESS_USER) && (
            <div className="fluid-container mt-4">
              <div className="d-flex justify-content-between flex-wrap gap-2">
                <div className="fw-700 fs-20">Top Referees</div>
                <div>
                  {/* <span className="dropdown-button">Top 3</span> */}
                  <Dropdown className="timeline-select-dropdown">
                    <Dropdown.Toggle
                      variant="none"
                      // id="dropdown-basic"
                      className="btn down-arrow timeline-select-button dropdown-button d-flex py-2 px-2 bg-transparent align-items-center d-flex justify-content-around referee-btn-focus "
                      style={{ width: "130px" }}
                    >
                      {/* <img src={filterIcon} alt="" className="" /> */}
                      <span className="fs-12">Referrals</span>
                      <span
                        className={`${referralsDropdownRedDot} round-red-dot rounded-circle mb-2`}
                      ></span>
                      <img className="" src={downArrow} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                      {topArray?.map((name, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              if (topArraySortIndex == index) {
                                setTopArraySortIndex(null);
                              } else {
                                setTopArraySortIndex(index);
                              }

                              if (name === "Top 3") {
                                setReferralFilter(3);
                              }
                              if (name === "Top 5") {
                                setReferralFilter(5);
                              }
                              if (name === "Top 10") {
                                setReferralFilter(10);
                              }
                            }}
                            key={index}
                            className="d-flex pb-3"
                          >
                            <img
                              src={blackdone}
                              alt="blackdone-icon"
                              className={
                                topArraySortIndex === index
                                  ? "pe-2 d-block"
                                  : "d-none pe-1"
                              }
                            />
                            <span
                              className={
                                topArraySortIndex === index
                                  ? "ps-0 color-primary fw-700"
                                  : "ps-4"
                              }
                            >
                              {name}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className="mt-3">
                <div className="w-100">
                  <div className="row">
                    {/* {topnewreferees && topnewreferees?.length > 0
                  ? topnewreferees.map((data) => { */}
                    {topnewreferees?.length > 0 &&
                      topnewreferees.map((data) => (
                        <div className="col-12 col-sm-6 col-xl-4">
                          <Link
                            className="text-decoration-none text-dark"
                            to={REFEREE_DETAILS_PAGE_ROUTE + `/${data.userId}`}
                          >
                            <div className="w-100 border-8 p-3 my-2">
                              <div className="d-flex">
                                <div>
                                  {/* <img src={data?.profilePic} alt="profile" /> */}
                                  <ProfileImage
                                    src={
                                      process.env.REACT_APP_IMAGE_BASEURL +
                                      data?.profilePic
                                    }
                                    height={"66px"}
                                    width={"72px"}
                                  />
                                </div>

                                <div className="ms-3">
                                  <div className="fw-600 fs-14 py-1 org-referee-nm-width header-ellipse-1">
                                    {data.name}
                                  </div>
                                  <div className="fw-400 fs-14 color-secondary mb-1">
                                    <span>{data.referral} Referrals</span>
                                    <span className="mx-2">|</span>
                                    <span>{data.joins} Joined</span>
                                  </div>
                                  <div className="fs-12 fw-400 color-tertiary">
                                    {data.conversion}% conversion
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* Org Top Referees End */}

          {/* Last Component  */}
          {(role === USER_ROLE.ADMIN || role === USER_ROLE.SUPER_ADMIN) && (
            <div className="more-insights">
              <div className="d-flex flex-wrap justify-content-between mt-2 pb-3">
                <div className=" d-flex justify-content-between ">
                  <span className="pe-4 py-2 fw-700 fs-20">More Insights</span>
                </div>
                <div
                  className="d-flex flex-wrap justify-content-between fw-400 fs-12"
                  style={{ height: "35px" }}
                >
                  <Dropdown className="timeline-select-dropdown">
                    <Dropdown.Toggle
                      variant="none"
                      // id="dropdown-basic"
                      className="btn down-arrow timeline-select-button dropdown-button d-flex py-2 px-2 bg-transparent align-items-center d-flex justify-content-around referee-btn-focus"
                      style={{ width: "130px" }}
                    >
                      {/* <img src={filterIcon} alt="" className="" /> */}
                      <span className="fs-12">Time Interval</span>
                      <span
                        className={`${insightsTimeIntervalDropdownRedDot} round-red-dot rounded-circle mb-2`}
                      ></span>
                      <img className="" src={downArrow} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black referee-btn-focus">
                      {daysFilterArray?.map((name, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              if (daysArraySortIndex == index) {
                                setDaysArraySortIndex(null);
                              } else {
                                setDaysArraySortIndex(index);
                              }

                              if (
                                role === USER_ROLE.BUSINESS_ADMIN ||
                                role === USER_ROLE.BUSINESS_USER
                              ) {
                                if (name === "Filter") {
                                  // setFromDate(null);
                                  getMoreInsigts();
                                }
                                if (name === "Last 7 days") {
                                  // setFromDate(date7);
                                  getMoreInsigts(date7, date);
                                }
                                if (name === "Last 31 days") {
                                  // setFromDate(date31);
                                  getMoreInsigts(date31, date);
                                }
                                if (name === "Last 90 days") {
                                  // setFromDate(date90);
                                  getMoreInsigts(date90, date);
                                }
                              }

                              if (
                                role === USER_ROLE.ADMIN ||
                                USER_ROLE.SUPER_ADMIN
                              ) {
                                if (name === "Filter") {
                                  // setFromDate(null);
                                  seikorAdminMoreInsigts();
                                }
                                if (name === "Last 7 days") {
                                  // setFromDate(date7);
                                  seikorAdminMoreInsigts(date7, date);
                                }
                                if (name === "Last 31 days") {
                                  // setFromDate(date31);
                                  seikorAdminMoreInsigts(date31, date);
                                }
                                if (name === "Last 90 days") {
                                  // setFromDate(date90);
                                  seikorAdminMoreInsigts(date90, date);
                                }
                              }
                            }}
                            key={index}
                            className="d-flex pb-3"
                          >
                            <img
                              src={blackdone}
                              alt="blackdone-icon"
                              className={
                                daysArraySortIndex === index
                                  ? "pe-2 d-block"
                                  : "d-none pe-1"
                              }
                            />
                            <span
                              className={
                                daysArraySortIndex === index
                                  ? "ps-0 color-primary fw-700"
                                  : "ps-4"
                              }
                            >
                              {name}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <span className="dropdown-button ms-1 py-2 d-none d-lg-block">
                  Last 7 days
                </span>
                <span className="dropdown-button ms-1 py-2 d-none d-lg-block">
                  Last 31 days
                </span>
                <span className="dropdown-button ms-1 py-2 d-none d-lg-block">
                  Last 90 days
                </span> */}
                </div>
              </div>
              {/* cards start  */}
              <div>
                <div className="fluid-container">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 referee-btn-focus">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.max_views?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostView} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">Most Views</div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {admininsightMore?.max_views?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {/* {admininsightMore?.max_views?.jobTitle}
                                {admininsightMore?.max_views?.reportingRole} */}
                                {admininsightMore?.max_views?.jobTitle}{" "}
                                {admininsightMore?.max_views?.reportingRole &&
                                  `, ${admininsightMore?.max_views?.reportingRole}`}
                              </div>

                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {admininsightMore?.max_views?.locationName}
                                &nbsp;|&nbsp;
                                {admininsightMore?.max_views?.jobType}
                                &nbsp; |&nbsp;
                                {admininsightMore?.max_views?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.max_applications?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostApplication} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Applications
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {
                                    admininsightMore?.max_applications
                                      ?.numberOfView
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {/* {admininsightMore?.max_applications?.jobTitle}
                                {
                                  admininsightMore?.max_applications
                                    ?.reportingRole
                                } */}
                                {admininsightMore?.max_applications?.jobTitle}{" "}
                                {admininsightMore?.max_applications
                                  ?.reportingRole &&
                                  `, ${admininsightMore?.max_applications?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {
                                  admininsightMore?.max_applications
                                    ?.locationName
                                }
                                &nbsp;|&nbsp;
                                {admininsightMore?.max_applications?.jobType}
                                &nbsp; |&nbsp;
                                {
                                  admininsightMore?.max_applications
                                    ?.reportingType
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.max_referral?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostReferrals} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Referrals
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {admininsightMore?.max_referral?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {admininsightMore?.max_referral?.jobTitle}{" "}
                                {admininsightMore?.max_referral
                                  ?.reportingRole &&
                                  `, ${admininsightMore?.max_referral?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {admininsightMore?.max_referral?.locationName}
                                &nbsp;|&nbsp;
                                {admininsightMore?.max_referral?.jobType}
                                &nbsp; |&nbsp;
                                {admininsightMore?.max_referral?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>{" "}
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.rejected?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostRejects} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Offer Rejects
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {admininsightMore?.rejected?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {admininsightMore?.rejected?.jobTitle}{" "}
                                {admininsightMore?.rejected?.reportingRole &&
                                  `, ${admininsightMore?.rejected?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {admininsightMore?.rejected?.locationName}
                                &nbsp;|&nbsp;
                                {admininsightMore?.rejected?.jobType}
                                &nbsp; |&nbsp;
                                {admininsightMore?.rejected?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>{" "}
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.retracted?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostDropouts} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Dropouts
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {admininsightMore?.retracted?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {admininsightMore?.retracted?.jobTitle}{" "}
                                {admininsightMore?.retracted?.reportingRole &&
                                  `, ${admininsightMore?.retracted?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {admininsightMore?.retracted?.locationName}
                                &nbsp;|&nbsp;
                                {admininsightMore?.retracted?.jobType}
                                &nbsp; |&nbsp;
                                {admininsightMore?.retracted?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.min_views?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostLeast} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">Least Views</div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {admininsightMore?.min_views?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {admininsightMore?.min_views?.jobTitle}{" "}
                                {admininsightMore?.min_views?.reportingRole &&
                                  `, ${admininsightMore?.min_views?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {admininsightMore?.min_views?.locationName}
                                &nbsp;|&nbsp;
                                {admininsightMore?.min_views?.jobType}
                                &nbsp; |&nbsp;
                                {admininsightMore?.min_views?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>{" "}
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.min_applications?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostLeast} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Least Application
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {
                                    admininsightMore?.min_applications
                                      ?.numberOfView
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {admininsightMore?.min_applications?.jobTitle}{" "}
                                {admininsightMore?.min_applications
                                  ?.reportingRole &&
                                  `, ${admininsightMore?.min_applications?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {
                                  admininsightMore?.min_applications
                                    ?.locationName
                                }
                                &nbsp;|&nbsp;
                                {admininsightMore?.min_applications?.jobType}
                                &nbsp; |&nbsp;
                                {
                                  admininsightMore?.min_applications
                                    ?.reportingType
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${admininsightMore?.min_referral?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostLeast} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Least Referrals
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {admininsightMore?.min_referral?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {admininsightMore?.min_referral?.jobTitle}{" "}
                                {admininsightMore?.min_referral
                                  ?.reportingRole &&
                                  `, ${admininsightMore?.min_referral?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {admininsightMore?.min_referral?.locationName}
                                &nbsp;|&nbsp;
                                {admininsightMore?.min_referral?.jobType}
                                &nbsp; |&nbsp;
                                {admininsightMore?.min_referral?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      {/* <Link
                      to={"/" + APPLICATIONS_MAIN_PAGE_ROUTE + `/${admininsightMore?.max_applications?.jobId}`}
                      className="text-decoration-none text-dark"
                    > */}
                      <div className="mb-4 outer-border p-3 insight-avg-joining-height d-flex flex-column justify-content-between">
                        <div className="row">
                          <div className="col-2 pt-2">
                            <div className="col-lg-12 col-md-12">
                              <img src={joiningTime} alt={mostDropouts} />
                            </div>
                          </div>
                          <div className="col-10 ps-3"></div>
                        </div>
                        <div className="col-lg-12 col-md-12 d-flex gap-2">
                          <div>
                            <div className="fw-400 fs-32 back-button">
                              {avgDaysOrg?.averageOrgsDays} Days
                            </div>
                            <div className="fw-700 fs-14 py-1">
                              Average Joining Time
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* cards ended  */}
              <></>
            </div>
          )}

          {(role === USER_ROLE.BUSINESS_ADMIN ||
            role === USER_ROLE.BUSINESS_USER) && (
            <div className="more-insights">
              <div className="d-flex flex-wrap justify-content-between mt-2 pb-3">
                <div className=" d-flex justify-content-between ">
                  <span className="pe-4 py-2 fw-700 fs-20">More Insights</span>
                </div>
                <div
                  className="d-flex flex-wrap justify-content-between fw-400 fs-12"
                  style={{ height: "35px" }}
                >
                  <Dropdown className="timeline-select-dropdown">
                    <Dropdown.Toggle
                      variant="none"
                      // id="dropdown-basic"
                      className="btn down-arrow timeline-select-button dropdown-button d-flex py-2 px-2 bg-transparent align-items-center d-flex justify-content-around referee-btn-focus"
                      style={{ width: "130px" }}
                    >
                      {/* <img src={filterIcon} alt="" className="" /> */}
                      <span className="fs-12">Time Interval</span>
                      <span
                        className={`${insightsTimeIntervalDropdownRedDot} round-red-dot rounded-circle mb-2`}
                      ></span>
                      <img className="" src={downArrow} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="fs-12 text-secondary mt-2 text-black">
                      {daysFilterArray?.map((name, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              if (daysArraySortIndex == index) {
                                setDaysArraySortIndex(null);
                              } else {
                                setDaysArraySortIndex(index);
                              }

                              // if (name === "Filter") {
                              //   setFromDate(null);
                              // }
                              // if (name === "Last 7 days") {
                              //   setFromDate(date7);
                              // }
                              // if (name === "Last 31 days") {
                              //   setFromDate(date31);
                              // }
                              // if (name === "Last 90 days") {
                              //   setFromDate(date90);
                              // }

                              if (
                                role === USER_ROLE.BUSINESS_ADMIN ||
                                role === USER_ROLE.BUSINESS_USER
                              ) {
                                if (name === "Filter") {
                                  // setFromDate(null);
                                  getMoreInsigts();
                                }
                                if (name === "Last 7 days") {
                                  // setFromDate(date7);
                                  getMoreInsigts(date7, date);
                                }
                                if (name === "Last 31 days") {
                                  // setFromDate(date31);
                                  getMoreInsigts(date31, date);
                                }
                                if (name === "Last 90 days") {
                                  // setFromDate(date90);
                                  getMoreInsigts(date90, date);
                                }
                              }

                              if (
                                role === USER_ROLE.ADMIN ||
                                USER_ROLE.SUPER_ADMIN
                              ) {
                                if (name === "Filter") {
                                  // setFromDate(null);
                                  seikorAdminMoreInsigts();
                                }
                                if (name === "Last 7 days") {
                                  // setFromDate(date7);
                                  seikorAdminMoreInsigts(date7, date);
                                }
                                if (name === "Last 31 days") {
                                  // setFromDate(date31);
                                  seikorAdminMoreInsigts(date31, date);
                                }
                                if (name === "Last 90 days") {
                                  // setFromDate(date90);
                                  seikorAdminMoreInsigts(date90, date);
                                }
                              }
                            }}
                            key={index}
                            className="d-flex pb-3"
                          >
                            <img
                              src={blackdone}
                              alt="blackdone-icon"
                              className={
                                daysArraySortIndex === index
                                  ? "pe-2 d-block"
                                  : "d-none pe-1"
                              }
                            />
                            <span
                              className={
                                daysArraySortIndex === index
                                  ? "ps-0 color-primary fw-700"
                                  : "ps-4"
                              }
                            >
                              {name}
                            </span>
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <span className="dropdown-button ms-1 py-2 d-none d-lg-block">
                  Last 7 days
                </span>
                <span className="dropdown-button ms-1 py-2 d-none d-lg-block">
                  Last 31 days
                </span>
                <span className="dropdown-button ms-1 py-2 d-none d-lg-block">
                  Last 90 days
                </span> */}
                </div>
              </div>
              {/* cards start  */}
              <div>
                <div className="fluid-container">
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.max_views?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostView} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">Most Views</div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.max_views?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {insightMore?.max_views?.jobTitle}{" "}
                                {insightMore?.max_views?.reportingRole &&
                                  `, ${insightMore?.max_views?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.max_views?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.max_views?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.max_views?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.max_applications?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostApplication} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Applications
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.max_applications?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {insightMore?.max_applications?.jobTitle}{" "}
                                {insightMore?.max_applications?.reportingRole &&
                                  `, ${insightMore?.max_applications?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.max_applications?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.max_applications?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.max_applications?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.max_referral?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostReferrals} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Referrals
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.max_referral?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {insightMore?.max_referral?.jobTitle}{" "}
                                {insightMore?.max_referral?.reportingRole &&
                                  `, ${insightMore?.max_referral?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.max_referral?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.max_referral?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.max_referral?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>{" "}
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.rejected?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostRejects} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Offer Rejects
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.rejected?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {insightMore?.rejected?.jobTitle}{" "}
                                {insightMore?.rejected?.reportingRole &&
                                  `, ${insightMore?.rejected?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.rejected?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.rejected?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.rejected?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>{" "}
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.retracted?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostDropouts} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Most Dropouts
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.retracted?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {insightMore?.retracted?.jobTitle}{" "}
                                {insightMore?.retracted?.reportingRole &&
                                  `, ${insightMore?.retracted?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.retracted?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.retracted?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.retracted?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.min_views?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostLeast} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">Least Views</div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.min_views?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {insightMore?.min_views?.jobTitle}{" "}
                                {insightMore?.min_views?.reportingRole &&
                                  `, ${insightMore?.min_views?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.min_views?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.min_views?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.min_views?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>{" "}
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.min_applications?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostLeast} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Least Application
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.min_applications?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {insightMore?.min_applications?.jobTitle}{" "}
                                {insightMore?.min_applications?.reportingRole &&
                                  `, ${insightMore?.min_applications?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.min_applications?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.min_applications?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.min_applications?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      <Link
                        to={
                          "/" +
                          APPLICATIONS_MAIN_PAGE_ROUTE +
                          `/${insightMore?.min_referral?.jobId}`
                        }
                        className="text-decoration-none text-dark"
                      >
                        <div className="outer-border p-3 mb-4 insight-cards-height ">
                          <div className="row">
                            <div className="col-2 pt-2">
                              <div className="col-lg-12 col-md-12">
                                <img src={mostLeast} />
                              </div>
                            </div>
                            <div className="col-10 ps-3">
                              <div className="col-lg-12 col-md-12">
                                <div className="fw-600 fs-14 ">
                                  Least Referrals
                                </div>
                                <div className="fw-400 fs-14 color-tertiary">
                                  {insightMore?.min_referral?.numberOfView}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* ))} */}
                          <hr />
                          <div className="col-lg-12 col-md-12 d-flex gap-2">
                            {(role === USER_ROLE.ADMIN ||
                              role === USER_ROLE.SUPER_ADMIN) && (
                              <div>
                                <div className="d-flex">
                                  <div className="rounded border">
                                    <CompanyImage
                                      // src={AirbnbLogo}
                                      width="23px"
                                      height="23px"
                                      initialsContainerClass="initialsStyle2-xl"
                                    />
                                  </div>
                                </div>
                                {/* );
                                  ))} */}
                              </div>
                            )}

                            <div>
                              <div
                                className="fw-400 fs-14 color-secondary ellipse"
                                style={{ height: "40px" }}
                              >
                                {/* {insightMore?.min_referral?.jobTitle},
                                {insightMore?.min_referral?.reportingRole} */}
                                {insightMore?.min_referral?.jobTitle}{" "}
                                {admininsightMore?.max_views?.reportingRole &&
                                  `, ${admininsightMore?.max_views?.reportingRole}`}
                              </div>
                              <div className="fw-400 fs-12 color-tertiary py-1">
                                {insightMore?.min_referral?.locationName}
                                &nbsp;|&nbsp;
                                {insightMore?.min_referral?.jobType}
                                &nbsp; |&nbsp;
                                {insightMore?.min_referral?.reportingType}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6 col-xl-4 ">
                      {/* <Link
                      to={"/" + APPLICATIONS_MAIN_PAGE_ROUTE + `/${insightMore?.max_applications?.jobId}`}
                      className="text-decoration-none text-dark"
                    > */}
                      <div className="mb-4 outer-border p-3 insight-avg-joining-height d-flex flex-column justify-content-between">
                        <div className="row">
                          <div className="col-2 pt-2">
                            <div className="col-lg-12 col-md-12">
                              <img src={joiningTime} alt={mostDropouts} />
                            </div>
                          </div>
                          <div className="col-10 ps-3"></div>
                        </div>
                        <div className="col-lg-12 col-md-12 d-flex gap-2">
                          <div>
                            <div className="fw-400 fs-32 back-button">
                              {avgDaysOrg?.averageOrgsDays} Days
                            </div>
                            <div className="fw-700 fs-14 py-1">
                              Average Joining Time
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* </Link> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* cards ended  */}
              <></>
            </div>
          )}
          {/* Last Component End */}
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
